<template>
  <div>
    <DataTable :value="workspaces" :loading="loading" :paginator="true" :rows="10" dataKey="id" :rowHover="true" selectionMode="single" @rowSelect="onWorkspaceSelect">
      <template #header v-if="$store.state.precedenceLevel <= 2">
        <div class="flex justify-content-end flex-column sm:flex-row">
          <Button
            type="button"
            label="Add Workspace"
            class="p-button mb-2"
            @click="addNewWorkspace"
          />
        </div>
      </template>
      <template #empty>
        No workspaces found.
      </template>
      <template #loading>
        Loading workspaces... Please wait.
      </template>
      <Column field="description" header="Name" :bodyStyle="getBodyStyle()" />
      <Column header="Projects" :bodyStyle="getBodyStyle()">
        <template #body="{ data }">
          <!-- {{ data.projects.items.length }} -->
          <img alt="BJSpinner" class="bjSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png" style="width:30px;height:30px" v-if="data.projects.nextToken !== null" />
          <div v-else>
            {{data.projects.items.length}}
          </div>
        </template>
      </Column>
      <Column header="Created" :bodyStyle="getBodyStyle()">
        <template #body="{ data }">
          {{ $filters.formatDate(data.created) }}
        </template>
      </Column>
      <!-- <Column header="Users">
      </Column> -->
      <Column headerStyle="width:4rem">
        <template #body="{ data }">
          <Button v-if="canEditWorkspace(data)" icon="pi pi-pencil" @click="editWorkspace(data)" />
        </template>
      </Column>
    </DataTable>
    <WorkspaceDialog :selectedWorkspace="selectedWorkspace" />
  </div>
</template>

<script>
import * as queries from '@/graphql/queries';
import { listItems } from '@/utils';
import WorkspaceDialog from '@/components/Organization/WorkspaceDialog.vue';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import * as customQueries from '@/graphql/custom_queries';

export default {
  name: 'Workspaces',
  components: {
    WorkspaceDialog,
  },
  data() {
    return {
      loading: false,
      workspaces: [],
      selectedWorkspace: null,
      limit: 50,
      userGroups: [],
    };
  },
  async mounted() {
    document.title = 'Workspaces';
    this.userGroups = await this.loadUserGroups();
    await this.loadWorkspaces();
  },
  methods: {
    getBodyStyle() {
      return 'text-align: center';
    },
    addNewWorkspace() {
      this.$store.dispatch('showNewWorkspace');
    },
    editWorkspace(workspace) {
      this.selectedWorkspace = workspace;
      this.$store.dispatch('showNewWorkspace');
    },
    async loadWorkspaces() {
      this.loading = true;
      this.workspaces = await listItems(queries.workspacesByOrganization, {
        organizationId: this.$route.params.id,
        limit: this.limit,
      });
      this.pullWorkspaceNumbers();
      this.loading = false;
    },
    async loadUserGroups() {
      return (await Auth.currentAuthenticatedUser()).signInUserSession.accessToken.payload['cognito:groups'];
    },
    async pullWorkspaceNumbers() {
      this.workspaces.forEach(async (workspace) => {
        if (workspace.projects.nextToken !== null && workspace.projects.nextToken !== undefined) {
          console.log('workspace :>> ', workspace);
          const allProjects = workspace.projects.items;
          const response1 = await API.graphql(graphqlOperation(customQueries.getWorkspaceForProjects, {
            id: workspace.id,
            projectsLimit: 1000,
            projectsNextToken: workspace.projects.nextToken,
          }));
          allProjects.push(...response1.data.getWorkspace.projects.items);
          if (response1.data.getWorkspace.projects.nextToken) {
            let projectToken = response1.data.getWorkspace.projects.nextToken;
            while (projectToken) {
              const response2 = await API.graphql(graphqlOperation(customQueries.getWorkspaceForProjects, {
                id: workspace.id, projectsLimit: 1000, projectsNextToken: projectToken,
              }));
              projectToken = response2.data.getWorkspace.projects.nextToken;
              allProjects.push(...response2.data.getWorkspace.projects.items);
            }
          }
          // eslint-disable-next-line no-param-reassign
          workspace.projects.items = allProjects;
          // eslint-disable-next-line no-param-reassign
          workspace.projects.nextToken = null; // To stop the loader and show the value
        }
      });
    },
    onWorkspaceSelect(event) {
      try {
        if (event.data.id !== null && event.data.id !== undefined && this.canEditWorkspace(event.data)) this.$router.push({ path: `workspace/${event.data.id}/users` });
      } catch (error) {
        console.error(error);
      }
    },
    canEditWorkspace(workspace) {
      return this.userGroups.some((group) => group === `WS/${workspace.id}/Admin` || group === `ORG/${workspace.organizationId}/Admin` || group === 'BaseJumperAdmin');
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingNewWorkspace': async function () {
      if (this.$store.state.showingNewWorkspace === false) {
        this.selectedWorkspace = null;
        await this.loadWorkspaces();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
