<template>
  <div>
    <DataTable :value="biosamples" :loading="loading" :paginator="true" :rows="10" dataKey="Sample" :rowHover="true">
      <template #empty>
        No results found. You are probably seeing this because your pipeline run hasn't completed yet. Please come back later.
      </template>
      <template #loading>
        Loading Results... Please wait.
      </template>
      <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field" :bodyStyle="getBodyStyle()" />
    </DataTable>
  </div>
</template>

<script>
// import { Auth } from 'aws-amplify';
import * as AWS from 'aws-sdk';
import { largeuint8ArrToString } from '@/utils';

export default {
  name: 'PFTable',
  props: ['pipeline'],
  data() {
    return {
      loading: false,
      columns: [],
      biosamples: null,
    };
  },
  methods: {
    async loadPFTable() {
      // console.log('pipeline data - ', this.pipeline);

      const s3 = new AWS.S3();
      const params = {
        Bucket: process.env.VUE_APP_BUCKET,
        Key: `${this.pipeline.project.workspace.organization.id}/${this.pipeline.project.workspace.id}/${this.pipeline.project.id}/pipeline/${this.pipeline.pipelineOutputS3Path}/${this.pipeline.pipelineName.toUpperCase()}_WF_CUSTOM_REPORT_WF_CUSTOM_REPORT/nf-preseq-pipeline_pf_metrics_summary.txt`,
      };
      // console.log('params :>> ', params);
      try {
        s3.getObject(params, (err, data) => {
          if (data) {
            largeuint8ArrToString(data.Body, (text) => {
              this.parsePassFailTableString(text);
            });
          }
          if (err) {
            this.loading = false;
            console.log(err);
          }
        });
      } catch (error) {
        console.log('Error with S3.getObject');
        console.log(error);
      }
    },
    parsePassFailTableString(text) {
      const fullRows = text.split('\n');
      this.header = fullRows[0].split('\t');
      const dataRows = [];
      for (let i = 1; i < fullRows.length; i += 1) {
        if (fullRows[i] === '') ;
        else {
          const tmp = fullRows[i].split('\t');
          const row = {};
          for (let j = 0; j < tmp.length; j += 1) {
            row[this.header[j]] = tmp[j];
          }
          dataRows.push(row);
        }
      }
      this.columns = Object.keys(this.header).map((key) => ({
        header: this.header[key].replaceAll('_', ' '),
        field: this.header[key],
      }));
      this.biosamples = dataRows;
      this.loading = false;
    },
    getBodyStyle() {
      return 'text-align: center';
    },
  },
  async mounted() {
    this.loading = true;
    await this.loadPFTable();
  },
};
</script>
