<template>
  <Dialog v-model:visible="showing" :style="{ width: '400px' }" header="Add Organization Name" :modal="true" class="p-fluid" :closable="false">
    <div class="field pt-5">
      <span class="p-float-label">
        <InputText id="orgNameId" type='text' v-model.trim="organizationName" autofocus />
        <label for="orgNameId">Organization name</label>
      </span>
    </div>
    <span>Please add the name of your organization. This will help our administrators sort your account.</span>
    <template #footer>
      <Button label="Save" class="p-button" @click="save()" />
    </template>
  </Dialog>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '@/graphql/mutations';

export default {
  data() {
    return {
      showing: false,
      organizationName: '',
    };
  },
  methods: {
    hideDialog() {
      this.organizationName = '';
      this.$store.dispatch('setShowingAddCustomOrg', false);
      this.showing = false;
    },
    async save() {
      try {
        if (this.organizationName === null || this.organizationName === undefined || this.organizationName === '') {
          this.$toast.add({
            severity: 'error',
            summary: 'Organization name error',
            detail: 'You must enter an organization name. It does not have to be exact.',
            life: 7000,
          });
          return;
        }
        const userVariables = {
          id: this.$store.state.newUserUsername,
          organization: this.organizationName,
        };
        API.graphql(graphqlOperation(mutations.updateUser, { input: userVariables }));
        this.hideDialog();
      } catch (error) {
        console.error('Error in adding new users organization name');
        console.error(error);
      }
    },
  },
  mounted() {
    if (this.$store.state.showingAddCustomOrg !== false) {
      this.showing = true;
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.setShowingAddCustomOrg': async function () {
      if (this.$store.state.showingAddCustomOrg !== false) {
        this.showing = true;
      } else {
        this.showing = false;
      }
    },
  },
};
</script>
