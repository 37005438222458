import { createStore } from 'vuex';

export default createStore({
  state: {
    paymentSystemDisabled: false,
    showingProfile: false,
    showingNewProject: false,
    showingImmediatePipelineLaunch: false,
    showingTransferProject: false,
    showingDeleteProject: false,
    showingLaunchPipeline: false,
    showingNewOrganization: false,
    showingNewWorkspace: false,
    showingNewTransaction: false,
    showingChangeGroup: false,
    showingChangePassword: false,
    showingLinkProjects: false,
    showingUpdateProject: false,
    showingUpdateBiosampleMetadataColumns: false,
    showingBiosampleMetadataImportDialog: false,
    showingResetBSSHBiosampleCache: false,
    showingLookForBSSHBiosamples: false,
    showingResetProjectAndBiosampleStatuses: false,
    showingLaunchablePipelineCRUDDialog: false,
    showingFixOrgGroupsDialog: false,
    showingAcceptTAndC: false,
    showingAddCustomOrg: false,
    showingExport: false,
    showingTermsAndConditionsView: false,
    runningTertiaryAnalysis: false,
    unlinkProjects: false,
    orgForTransaction: null,
    futurePipelines: false,
    checkForJobs: false,
    addingNewBiosamples: false,
    pipelinesForVisualization: [],
    missingRequiredColumns: null,
    showingMapRequiredMetadataColumns: false,
    visualizationToOpen: null,
    selectedProject: null,
    newUserUsername: null,
    activeWorkspace: null,
    projectIdForTertiaryAnalysis: '',
    activeOrganization: null,
    accountNumber: null,
    bioskrybProduct: null, // DNA, OME ... Made to be string so in the future we can add whatever product we want
    allWorkspaces: [],
    precedenceLevel: 10, // This will determine what privalages our user will have in our application. At the start, there will be 4 levels of privilage. Level 1 will be bioskryb administrator which will have access to everything. Level 2 will be organization administrators. Level 3 are workspaces adminstrator and level 4 are worskapce users. Level 10 has been put simply as a placeholder, the value will be updated every time a workspace is changed.
    // Promises
    activeWorkspaceIdPromise: null,
    activeOrganizationIdPromise: null,
    accountNumberPromise: null,
  },
  mutations: {
    showProfile(state) {
      state.showingProfile = !state.showingProfile;
    },
    showNewProject(state) {
      state.showingNewProject = !state.showingNewProject;
    },
    setActiveWorkspaceIdPromise(state, value) {
      state.activeWorkspaceIdPromise = value;
    },
    setShowingResetProjectAndBiosampleStatuses(state, value) {
      state.showingResetProjectAndBiosampleStatuses = value;
    },
    setShowingLaunchablePipelineCRUDDialog(state, value) {
      state.showingLaunchablePipelineCRUDDialog = value;
    },
    setActiveOrganizationIdPromise(state, value) {
      state.activeOrganizationIdPromise = value;
    },
    setVisualizationToOpen(state, value) {
      state.visualizationToOpen = value;
    },
    setShowingTermsAndConditionsView(state, value) {
      state.showingTermsAndConditionsView = value;
    },
    setAccountNumberPromise(state, value) {
      state.accountNumberPromise = value;
    },
    setShowNewProject(state, value) {
      state.showingNewProject = value;
    },
    setShowingAddCustomOrg(state, value) {
      state.showingAddCustomOrg = value;
    },
    setMissingRequiredColumns(state, value) {
      state.missingRequiredColumns = value;
    },
    setPipelinesForVisualization(state, value) {
      state.pipelinesForVisualization = value;
    },
    setShowingBiosampleMetadataImportDialog(state, value) {
      state.showingBiosampleMetadataImportDialog = value;
    },
    setOrgForTransaction(state, value) {
      state.orgForTransaction = value;
    },
    setShowingMapRequiredMetadataColumns(state, value) {
      state.showingMapRequiredMetadataColumns = value;
    },
    showImmediatePipelineLaunch(state) {
      state.showingImmediatePipelineLaunch = !state.showingImmediatePipelineLaunch;
    },
    setShowingExport(state, value) {
      state.showingExport = value;
    },
    setShowingUpdateProject(state, value) {
      state.showingUpdateProject = value;
    },
    setShowingUpdateBiosampleMetadataColumns(state, value) {
      state.showingUpdateBiosampleMetadataColumns = value;
    },
    setRunningTertiaryAnalysis(state, value) {
      state.runningTertiaryAnalysis = value;
    },
    setShowingResetBSSHBiosampleCache(state, value) {
      state.showingResetBSSHBiosampleCache = value;
    },
    setShowingLookForBSSHBiosamples(state, value) {
      state.showingLookForBSSHBiosamples = value;
    },
    setShowingAcceptTAndC(state, value) {
      state.showingAcceptTAndC = value;
    },
    setShowImmediatePipelineLaunch(state, value) {
      state.showingImmediatePipelineLaunch = value;
    },
    setNewUserUsername(state, value) {
      state.newUserUsername = value;
    },
    setProjectIdForTertiaryAnalysis(state, value) {
      state.projectIdForTertiaryAnalysis = value;
    },
    showTransferProject(state) {
      state.showingTransferProject = !state.showingTransferProject;
    },
    showDeleteProject(state) {
      state.showingDeleteProject = !state.showingDeleteProject;
    },
    showLaunchPipeline(state) {
      state.showingLaunchPipeline = !state.showingLaunchPipeline;
    },
    setShowingFixOrgGroupsDialog(state, value) {
      state.showingFixOrgGroupsDialog = value;
    },
    setShowLaunchPipeline(state, value) {
      state.showingLaunchPipeline = value;
    },
    setSelectedProject(state, value) {
      state.selectedProject = value;
    },
    setAddingNewBiosamples(state, value) {
      state.addingNewBiosamples = value;
    },
    showChangePassword(state) {
      state.showingChangePassword = !state.showingChangePassword;
    },
    showLinkProjects(state) {
      state.showingLinkProjects = !state.showingLinkProjects;
    },
    setActiveWorkspace(state, activeWorkspace) {
      state.activeWorkspace = activeWorkspace;
    },
    setActiveOrganization(state, activeOrganization) {
      state.activeOrganization = activeOrganization;
    },
    setAllWorkspaces(state, allWorkspaces) {
      state.allWorkspaces = allWorkspaces;
    },
    showNewOrganization(state) {
      state.showingNewOrganization = !state.showingNewOrganization;
    },
    showNewWorkspace(state) {
      state.showingNewWorkspace = !state.showingNewWorkspace;
    },
    showNewTransaction(state) {
      state.showingNewTransaction = !state.showingNewTransaction;
    },
    showChangeGroup(state) {
      state.showingChangeGroup = !state.showingChangeGroup;
    },
    toggleFuturePipelines(state) {
      state.futurePipelines = !state.futurePipelines;
    },
    setToggleFuturePipelines(state, value) {
      state.futurePipelines = value;
    },
    toggleUnlinkProjects(state) {
      state.unlinkProjects = !state.unlinkProjects;
    },
    setAccountNumber(state, accountNumber) {
      state.accountNumber = accountNumber;
    },
    setBioskrybProduct(state, bioskrybProduct) {
      state.bioskrybProduct = bioskrybProduct;
    },
    setCheckForJobs(state, check) {
      state.checkForJobs = check;
    },
    setPrecedenceLevel(state, level) {
      state.precedenceLevel = level;
    },
    setPaymentSystemDisabled(state, value) {
      state.paymentSystemDisabled = value;
    },
  },
  actions: {
    showProfile({ commit }) {
      commit('showProfile');
    },
    showNewProject({ commit }) {
      commit('showNewProject');
    },
    setShowNewProject({ commit }, value) {
      commit('setShowNewProject', value);
    },
    setShowingResetProjectAndBiosampleStatuses({ commit }, value) {
      commit('setShowingResetProjectAndBiosampleStatuses', value);
    },
    setRunningTertiaryAnalysis({ commit }, value) {
      commit('setRunningTertiaryAnalysis', value);
    },
    setShowingExport({ commit }, value) {
      commit('setShowingExport', value);
    },
    setShowingMapRequiredMetadataColumns({ commit }, value) {
      commit('setShowingMapRequiredMetadataColumns', value);
    },
    setShowingLaunchablePipelineCRUDDialog({ commit }, value) {
      commit('setShowingLaunchablePipelineCRUDDialog', value);
    },
    setMissingRequiredColumns({ commit }, value) {
      commit('setMissingRequiredColumns', value);
    },
    setShowingTermsAndConditionsView({ commit }, value) {
      commit('setShowingTermsAndConditionsView', value);
    },
    setShowingAcceptTAndC({ commit }, value) {
      commit('setShowingAcceptTAndC', value);
    },
    setShowingUpdateBiosampleMetadataColumns({ commit }, value) {
      commit('setShowingUpdateBiosampleMetadataColumns', value);
    },
    setProjectIdForTertiaryAnalysis({ commit }, value) {
      commit('setProjectIdForTertiaryAnalysis', value);
    },
    setOrgForTransaction({ commit }, value) {
      commit('setOrgForTransaction', value);
    },
    setShowingBiosampleMetadataImportDialog({ commit }, value) {
      commit('setShowingBiosampleMetadataImportDialog', value);
    },
    setVisualizationToOpen({ commit }, value) {
      commit('setVisualizationToOpen', value);
    },
    setShowingUpdateProject({ commit }, value) {
      commit('setShowingUpdateProject', value);
    },
    showImmediatePipelineLaunch({ commit }) {
      commit('showImmediatePipelineLaunch');
    },
    setPipelinesForVisualization({ commit }, value) {
      commit('setPipelinesForVisualization', value);
    },
    setShowImmediatePipelineLaunch({ commit }, value) {
      commit('setShowImmediatePipelineLaunch', value);
    },
    setShowingAddCustomOrg({ commit }, value) {
      commit('setShowingAddCustomOrg', value);
    },
    setNewUserUsername({ commit }, value) {
      commit('setNewUserUsername', value);
    },
    setShowingResetBSSHBiosampleCache({ commit }, value) {
      commit('setShowingResetBSSHBiosampleCache', value);
    },
    setShowingLookForBSSHBiosamples({ commit }, value) {
      commit('setShowingLookForBSSHBiosamples', value);
    },
    setShowingFixOrgGroupsDialog({ commit }, value) {
      commit('setShowingFixOrgGroupsDialog', value);
    },
    showTransferProject({ commit }) {
      commit('showTransferProject');
    },
    showDeleteProject({ commit }) {
      commit('showDeleteProject');
    },
    showLaunchPipeline({ commit }) {
      commit('showLaunchPipeline');
    },
    setShowLaunchPipeline({ commit }, value) {
      commit('setShowLaunchPipeline', value);
    },
    showChangePassword({ commit }) {
      commit('showChangePassword');
    },
    toggleFuturePipelines({ commit }) {
      commit('toggleFuturePipelines');
    },
    setToggleFuturePipelines({ commit }, value) {
      commit('setToggleFuturePipelines', value);
    },
    setAddingNewBiosamples({ commit }, value) {
      commit('setAddingNewBiosamples', value);
    },
    setSelectedProject({ commit }, selectedProject) {
      commit('setSelectedProject', selectedProject);
    },
    showLinkProjects({ commit }) {
      commit('showLinkProjects');
    },
    toggleUnlinkProjects({ commit }) {
      commit('toggleUnlinkProjects');
    },
    setActiveWorkspace({ commit }, activeWorkspace) {
      commit('setActiveWorkspace', activeWorkspace);
    },
    setActiveOrganization({ commit }, activeOrganization) {
      commit('setActiveOrganization', activeOrganization);
    },
    setAllWorkspaces({ commit }, allWorkspaces) {
      commit('setAllWorkspaces', allWorkspaces);
    },
    setBioskrybProduct({ commit }, bioskrybProduct) {
      commit('setBioskrybProduct', bioskrybProduct);
    },
    showNewOrganization({ commit }) {
      commit('showNewOrganization');
    },
    showNewWorkspace({ commit }) {
      commit('showNewWorkspace');
    },
    showNewTransaction({ commit }) {
      commit('showNewTransaction');
    },
    showChangeGroup({ commit }) {
      commit('showChangeGroup');
    },
    setPrecedenceLevel({ commit }, level) {
      commit('setPrecedenceLevel', level);
    },
    setAccountNumber({ commit }, accountNumber) {
      commit('setAccountNumber', accountNumber);
    },
    setActiveWorkspaceIdPromise({ commit }, value) {
      commit('setActiveWorkspaceIdPromise', value);
    },
    setActiveOrganizationIdPromise({ commit }, value) {
      commit('setActiveOrganizationIdPromise', value);
    },
    setAccountNumberPromise({ commit }, value) {
      commit('setAccountNumberPromise', value);
    },
    setPaymentSystemDisabled({ commit }, value) {
      commit('setPaymentSystemDisabled', value);
    },
  },
  modules: {
  },
  getters: {
    getAllWorkspacesLenght: (state) => state.allWorkspaces.length,
    getCheckProjectsLenght: (state) => state.checkedPrjects.length,
  },
});
