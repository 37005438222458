<template>
  <div>
    <div class="card">
      <Steps :model="items" :readonly="true" />
    </div>
    <router-view v-slot="{ Component }" :formData="formData" @prevPage="prevPage($event)" @nextPage="nextPage($event)" @complete="complete" @removeTertiary="removeTertiary">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>

<script>
import { Auth, API, graphqlOperation } from 'aws-amplify';
import * as AWS from 'aws-sdk';
import * as queries from '@/graphql/queries';
import * as customQueries from '@/graphql/custom_queries';
import * as mutations from '@/graphql/mutations';
import {
  setPrecedence, sendEBMessage, listItems, filterPrivateLaunchablePipelinesForWorkspaceAndOrganizationGroup,
} from '@/utils';

export default {
  data() {
    return {
      projectPromise: null,
      items: [{
        label: 'Secondary Pipelines',
        to: `/workspace/${this.$store.state.activeWorkspace}/tertiaryAnalysis/${this.$route.params.id}/secondarySelection`,
      },
      {
        label: 'Tertiary Pipelines',
        to: `/workspace/${this.$store.state.activeWorkspace}/tertiaryAnalysis/${this.$route.params.id}/tertiarySelection`,
      },
      {
        label: 'File Types',
        to: `/workspace/${this.$store.state.activeWorkspace}/tertiaryAnalysis/${this.$route.params.id}/fileTypeSelection`,
      },
      {
        label: 'Biosamples',
        to: `/workspace/${this.$store.state.activeWorkspace}/tertiaryAnalysis/${this.$route.params.id}/biosampleselection`,
      },
      {
        label: 'Parameters',
        to: `/workspace/${this.$store.state.activeWorkspace}/tertiaryAnalysis/${this.$route.params.id}/parameterSelection`,
      },
      {
        label: 'Check',
        to: `/workspace/${this.$store.state.activeWorkspace}/tertiaryAnalysis/${this.$route.params.id}/check`,
      }],
      formData: {},
    };
  },
  methods: {
    nextPage(event) {
      console.log('Next page called');
      console.log('event :>> ', event);
      // eslint-disable-next-line guard-for-in
      for (const field in event.formData) {
        this.formData[field] = event.formData[field];
      }
      console.log('formData :>> ', this.formData);
      this.$router.push(this.items[event.pageIndex + 1].to);
    },
    prevPage(event) {
      this.$router.push(this.items[event.pageIndex - 1].to);
    },
    async complete(amount, currentCreditBalance) {
      console.log('this.formData :>> ', this.formData);
      const project = (await this.projectPromise).data.getProject;
      const date = new Date();
      const currCreds = await setPrecedence();
      if (currCreds === null) {
        console.error('Credentials are null');
      }
      const credentials = new AWS.Credentials(currCreds);
      let initiator = (await Auth.currentAuthenticatedUser()).attributes.name;
      if (initiator === undefined || initiator === null) {
        initiator = 'Unknown';
      }
      const pipelineStartDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}T${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}.${date.getMilliseconds()}Z`;
      this.formData.selectedTertiaryPipelines.forEach((pipeline) => {
        if (pipeline.id in this.formData.selectedBiosamples && this.formData?.selectedBiosamples[pipeline.id].length > 0) {
          this.launchPipeline(credentials, date, pipelineStartDate, initiator, pipeline.pipelineName, pipeline.pipelineVersion, project.id, project.clientProjectName, this.formData.pipelineParameters[pipeline.id], project.workspaceId, project.workspace.description, project.workspace.organizationId, project.workspace.organization.organizationName, this.formData.selectedBiosamples[pipeline.id], this.formData.selectedFilesObject[pipeline.id], pipeline.id, Auth.currentAuthenticatedUser(), this.formData.pipelineBiosampleUseMap, amount, currentCreditBalance);
        }
      });
      this.$toast.add({
        severity: 'success',
        summary: 'Success',
        detail: `${(this.formData.selectedTertiaryPipelines.length > 1) ? 'Pipelines' : 'Pipeline'} launched successfully!`,
        life: 3000,
      });
      this.$store.dispatch('setRunningTertiaryAnalysis', false);
      this.$router.push(`/workspace/${this.$store.state.activeWorkspace}/project/${this.$route.params.id}/biosamples`);
    },
    removeTertiary(event) {
      try {
        const tmp = [];
        for (const [, value] of Object.entries(this.formData.selectedTertiaryPipelines)) {
          console.log(`${value.id}: ${value}`);
          if (!event.deleteTertiary.includes(value.id)) {
            tmp.push(value);
          }
        }
        this.formData.selectedTertiaryPipelines = tmp;
      } catch (error) {
        console.error('Error in remove tertiary');
        console.error(error);
      }
    },
    // eslint-disable-next-line consistent-return
    async launchPipeline(credentials, date, pipelineStartDate, initiator, pipelineName, pipelineVersion, projectId, projectName, pipelineParams, workspaceId, workspaceDescription, organizationId, organizationName, selectedBiosamples, fileTypes, launchablePipelineId, userPromise, pipelineBiosampleUseMap, amount, currentCreditBalance) {
      try {
        const tmpName = `${pipelineName.toLowerCase()}_${pipelineVersion}_${date.getFullYear() % 100}${date.getMonth() + 1}${date.getDate()}_${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;
        const pipelineVariables = {
          pipelineName,
          projectId,
          projectPipelinesId: projectId,
          status: 'Submitted',
          pipelineVersion,
          estimatedCredits: 10,
          pipelineStarted: pipelineStartDate,
          pipelineOutputS3Path: tmpName,
          parameters: JSON.stringify(pipelineParams),
          initiator,
          analysisLevel: 'tertiary',
          adminGroups: [`WS/${workspaceId}/Admin`, `ORG/${organizationId}/Admin`],
          writeGroups: [`WS/${workspaceId}/Admin`, `ORG/${organizationId}/Admin`],
          readGroups: [`WS/${workspaceId}/User`, `ORG/${organizationId}/User`],
        };
        let pipeline = null;
        try {
          pipeline = await API.graphql(graphqlOperation(mutations.createPipeline, { input: pipelineVariables }));
        } catch (error) {
          console.error('Pipeline creation failed');
          console.error(error);
        }
        if (pipeline === null) {
          console.error('Pipeline is null');
          return;
        }
        try {
          sendEBMessage(await this.getDetails(projectId, projectName, pipelineName, pipeline.data.createPipeline.id, pipelineVersion, tmpName, selectedBiosamples, organizationId, organizationName, workspaceId, workspaceDescription, pipelineParams, fileTypes, launchablePipelineId, userPromise, pipelineBiosampleUseMap, amount, currentCreditBalance), organizationId, workspaceId, projectId, 'pipeline');
          this.$toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'You have successfully launched the pipeline!',
            life: 3000,
          });
        } catch (error) {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Launching pipeline failed!',
            life: 3000,
          });
          console.log('Error in creating pipeline');
          console.log(error);
        }
      } catch (error) {
        console.error('Error in launchPipeline in SeptsMain');
        console.error(error);
      }
    },
    async getDetails(projectId, projectName, pipelineName, newPipelineId, pipelineVersion, tmpName, selectedBiosamples, organizationId, organizationName, workspaceId, workspaceDescription, pipelineParams, fileTypes, launchablePipelineId, userPromise, pipelineBiosampleUseMap, amount, currentCreditBalance) {
      const details = {
        bucket: process.env.VUE_APP_BUCKET,
        step: 'pipeline_trigger',
        status: 'KickoffPipelines',
        user_uuid: (await userPromise).username,
        application_environment: process.env.VUE_APP_SLS_STAGE,
        analysis: {
          level: 'tertiary',
          type_input_files: fileTypes,
        },
        launchbale_pipeline_uuid: launchablePipelineId,
        pipeline: pipelineName.toLowerCase(),
        pipeline_version: pipelineVersion,
        organization_uuid: organizationId,
        organization_name: organizationName,
        workspace_uuid: workspaceId,
        workspace_name: workspaceDescription,
        project_name_uuid: projectId,
        project_name: projectName,
        credited_spent: (this.$store.state.paymentSystemDisabled) ? 0 : amount,
        credit_before: (this.$store.state.paymentSystemDisabled) ? 0 : currentCreditBalance,
        credit_after: (this.$store.state.paymentSystemDisabled) ? 0 : currentCreditBalance - amount,
        user_name: (await userPromise).attributes.email,
        pipeline_uuid: newPipelineId,
        pipeline_run_s3: tmpName,
        nf_parameters: this.formatNFParamsForRunner(pipelineParams),
      };
      if ((selectedBiosamples !== undefined) && (selectedBiosamples.length !== 0) && (selectedBiosamples.length > 0)) {
        const biosampleNames = [];
        selectedBiosamples.forEach((b) => {
          biosampleNames.push(b.biosampleName);
        });
        details.biosample_names = biosampleNames;
        details.biosamples = this.makeBiosamplesObj(selectedBiosamples, pipelineBiosampleUseMap);
      }
      return details;
    },
    formatNFParamsForRunner(params) {
      // eslint-disable-next-line no-unused-vars
      const formatedParams = Object.keys(params).map((key) => ({
        nf_parameter: key,
        value: params[key],
      }));
      return formatedParams;
    },
    makeBiosamplesObj(biosamplesArr, pipelineBiosampleUseMap) {
      try {
        const mappedArray = [];
        biosamplesArr.forEach((bs) => {
          const pipelineUuids = [];
          for (const [key, value] of Object.entries(pipelineBiosampleUseMap)) {
            if (value.includes(bs.id)) {
              // pipelineUuid = key;
              pipelineUuids.push(key);
            }
          }
          pipelineUuids.forEach((pipelineUuid) => {
            const o = {
              biosample_name: bs.biosampleName,
              biosample_uuid: bs.id,
              pipeline_uuid: pipelineUuid,
            };
            mappedArray.push(o);
          });
          // return o;
        });
        return mappedArray;
      } catch (error) {
        console.error('Error in makeBiosamples');
        console.error(error);
        return null;
      }
    },
    async getLaunchablePipelines() {
      try {
        // const allLaunchablePipelines = (await Promise.all([
        //   listItems(queries.listLaunchablePipelines, { limit: 300 }),
        //   listItems(customQueries.customListPrivateLaunchablePipelines, { limit: 300 }),
        // ])).flat();
        const privateLaunchablePipelinesPromise = listItems(customQueries.customListPrivateLaunchablePipelines, { limit: 300 });
        const allLaunchablePipelines = await listItems(queries.listLaunchablePipelines, { limit: 300 });
        allLaunchablePipelines.push(...filterPrivateLaunchablePipelinesForWorkspaceAndOrganizationGroup(await privateLaunchablePipelinesPromise, this.$route.params.workspaceId, this.$store.state.activeOrganization));
        return allLaunchablePipelines;
      } catch (error) {
        console.error('Error getting launchable pipelines');
        console.error(error);
        return null;
      }
    },
    async getBiosamples() {
      try {
        const biosamples = await listItems(customQueries.biosamplesByProjectForTertiaryAnalyses, { projectId: this.$route.params.id, filter: { fastqValidationStatus: { eq: 'Pass' } } });
        return biosamples;
      } catch (error) {
        console.error('Error in getBiosamples');
        console.error(error);
        return null;
      }
    },
    async getProject() {
      try {
        return API.graphql(graphqlOperation(queries.getProject, { id: this.$route.params.id }));
      } catch (error) {
        console.error('Error getting project');
        console.error(error);
        return null;
      }
    },
  },
  mounted() {
    this.projectPromise = this.getProject();
    this.formData.launchablePipelines = this.getLaunchablePipelines();
    this.formData.biosamples = this.getBiosamples();
  },
};
</script>

<style scoped lang="scss">
::v-deep(b) {
    display: block;
}

::v-deep(.p-card-body) {
    padding: 2rem;
}
</style>
