<template>
  <Dialog v-model:visible="showing" :style="{ width: '450px' }" :header="isNew ? 'Create Workspace' : 'Edit Workspace'" :modal="true" class="p-fluid" :closable="false">
    <Message severity="error" v-if="this.inputFailed">Invalid input!</Message>
    <div class="field pt-4">
      <span class="p-float-label">
        <InputText id="organizationName" :class="(validateWorkspaceDescription() === false) ? 'p-invalid' : ''" v-model.trim="organizationName" disabled required="true" autofocus />
        <label for="organizationName">Organization Name</label>
      </span>
      <InlineMessage :style="(validateWorkspaceName() === false) ? '' : 'display: none;'">Workspace name can only contain letters and numbers!</InlineMessage>
    </div>
    <div class="field pt-4">
      <span class="p-float-label">
        <InputText id="workspaceDescription" :class="(validateWorkspaceDescription() === false) ? 'p-invalid' : ''" v-model.trim="workspaceDescription" required="true" autofocus />
        <label for="workspaceDescription">Workspace Description</label>
      </span>
      <InlineMessage :style="(validateWorkspaceDescription() === false) ? '' : 'display: none;'">Workspace description can only contain letters and numbers!</InlineMessage>
    </div>
    <div class="field pt-4">
      <span class="p-float-label">
        <InputText id="basespaceAccessToken" :class="(validateBSAccessToken() === false) ? 'p-invalid' : ''" v-model.trim="basespaceAccessToken" required="true" autofocus />
        <label for="basespaceAccessToken">Illumina BaseSpace Access Token</label>
      </span>
      <InlineMessage :style="(validateBSAccessToken() === false) ? '' : 'display: none;'">BaseSpace Access Token can only contain letters and numbers!</InlineMessage>
    </div>
    <div class="field pt-4">
      <span class="p-float-label">
        <Chips id="organizationDataKay" v-model="sharedS3Paths" required="false" />
        <label for="organizationDataKay">Workspace Data Keys</label>
      </span>
    </div>
    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-secondary" :disabled="loading" @click="hideDialog" />
      <Button v-if="isNew" label="Create Workspace" class="p-button-outline" :disabled="loading || (this.validateWorkspaceDescription() === false) || (this.validateWorkspaceDescription() === false) || (this.validateBSAccessToken() === false)" @click="createWorkspace" />
      <Button v-if="!isNew" label="Save" class="p-button-outline" :disabled="loading || (this.validateWorkspaceDescription() === false) || (this.validateWorkspaceDescription() === false) || (this.validateBSAccessToken() === false)" @click="editWorkspace" />
      <!-- <Button label="updateOrganizationGroups" @click="this.updateOrganizationGroups()"></Button> -->
    </template>
  </Dialog>
</template>

<script>
// Two in one. Both create and edit workspace.

import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '@/graphql/queries';
import * as mutations from '@/graphql/mutations';

export default {
  props: ['selectedWorkspace'],
  data() {
    return {
      loading: false,
      showing: false,
      isNew: true,
      submitted: false,
      organization: null,
      organizationName: null,
      workspaceDescription: null,
      basespaceAccessToken: null,
      inputFailed: false,
      sharedS3Paths: [],
    };
  },
  async mounted() {
    const response = await API.graphql(graphqlOperation(queries.getOrganization, {
      id: this.$route.params.id,
    }));
    this.organization = response.data.getOrganization;
    this.organizationName = this.organization.organizationName;
  },
  methods: {
    getSelectedWorkspace(workspace) {
      console.log('watching selected workspace - ', workspace);
      if (workspace) {
        this.isNew = false;
        this.workspaceDescription = workspace.description;
        this.basespaceAccessToken = workspace.basespaceAccessToken;
        this.sharedS3Paths = workspace.sharedS3Paths;
      }
    },
    async createWorkspace() {
      if ((this.validateWorkspaceName() === false) || (this.validateWorkspaceDescription() === false) || (this.validateBSAccessToken() === false)) {
        this.inputFailed = true;
        return;
      }

      this.loading = true;
      const workspace = {
        organizationId: this.organization.id,
        organizationWorkspacesId: this.organization.id,
        description: this.workspaceDescription,
        basespaceAccessToken: this.basespaceAccessToken,
        sharedS3Paths: this.sharedS3Paths,
        adminGroups: [`WS/${this.$store.state.activeWorkspace}/Admin`, `ORG/${this.$store.state.activeOrganization}/Admin`],
        writeGroups: [`WS/${this.$store.state.activeWorkspace}/Admin`, `ORG/${this.$store.state.activeOrganization}/Admin`],
        readGroups: [`WS/${this.$store.state.activeWorkspace}/User`, `ORG/${this.$store.state.activeOrganization}/User`],
      };
      console.log('create workspace - ', workspace);

      try {
        const { data } = await API.graphql(graphqlOperation(mutations.createWorkspace, {
          input: workspace,
        }));
        console.log('workspace data - ', data);
        this.updateWorkspaceGroups(data.createWorkspace.id, this.organization.id);
        this.updateOrganizationGroups(data.createWorkspace.id);
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Workspace created successfully!',
          life: 3000,
        });
        this.loading = false;
        this.$store.dispatch('showNewWorkspace');
      } catch (error) {
        console.log('workspace creation - ', error);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Workspace creation failed! Please try again.',
          life: 3000,
        });
        this.loading = false;
      }
    },
    async updateOrganizationGroups(wsId) {
      if ((this.validateWorkspaceName() === false) || (this.validateWorkspaceDescription() === false) || (this.validateBSAccessToken() === false)) {
        this.inputFailed = true;
        return;
      }

      const userGrp = `WS/${wsId}/User`;
      const adminGrp = `WS/${wsId}/Admin`;
      const newReadGroups = this.organization.readGroups;
      newReadGroups.push(userGrp, adminGrp);
      try {
        const orgUpdateParams = {
          id: this.organization.id,
          // writeGroups: [`ORG/${organization.id}/Admin`],
          // readGroups: [`ORG/${organization.id}/User`],
          readGroups: newReadGroups,
        };
          // eslint-disable-next-line no-unused-vars
        const { updatedData } = await API.graphql(graphqlOperation(mutations.updateOrganization, {
          input: orgUpdateParams,
        }));
      } catch (error) {
        console.log('Problem with updating organization groups');
        console.log(error);
      }
    },
    async updateWorkspaceGroups(workspaceId, organizationId) {
      const wsGroupsParams = {
        id: workspaceId,
        adminGroups: [`WS/${workspaceId}/Admin`, `ORG/${organizationId}/Admin`],
        writeGroups: [`WS/${workspaceId}/Admin`, `ORG/${organizationId}/Admin`],
        readGroups: [`WS/${workspaceId}/User`, `ORG/${organizationId}/User`],
      };
      try {
        await API.graphql(graphqlOperation(mutations.updateWorkspace, {
          input: wsGroupsParams,
        }));
      } catch (error) {
        console.log('Error updateing workspace groups');
        console.log(error);
      }
    },
    async editWorkspace() {
      if ((this.validateWorkspaceName() === false) || (this.validateWorkspaceDescription() === false) || (this.validateBSAccessToken() === false)) {
        this.inputFailed = true;
        return;
      }

      this.loading = true;
      const workspace = {
        id: this.selectedWorkspace.id,
        organizationId: this.organization.id,
        description: this.workspaceDescription,
        basespaceAccessToken: this.basespaceAccessToken,
        sharedS3Paths: this.sharedS3Paths,
      };
      console.log('update workspace - ', workspace);

      try {
        const { data } = await API.graphql(graphqlOperation(mutations.updateWorkspace, {
          input: workspace,
        }));
        console.log('workspace data - ', data);
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Workspace updated successfully!',
          life: 3000,
        });
        this.loading = false;
        this.$store.dispatch('showNewWorkspace');
      } catch (error) {
        console.log('workspace update error - ', error);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Workspace updating failed! Please try again.',
          life: 3000,
        });
        this.loading = false;
      }
    },
    validateWorkspaceName() {
      if (!(/^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$/.test(this.organizationName)) && this.organizationName !== '') {
        return false;
      }
      return true;
    },
    validateWorkspaceDescription() {
      if (!(/^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$/.test(this.workspaceDescription)) && this.workspaceDescription !== '') {
        return false;
      }
      return true;
    },
    validateBSAccessToken() {
      if (!(/^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$/.test(this.basespaceAccessToken)) && this.basespaceAccessToken !== '') {
        return false;
      }
      return true;
    },
    hideDialog() {
      this.loading = false;
      this.showing = false;
      this.isNew = true;
      this.submitted = false;
      this.workspaceDescription = null;
      this.basespaceAccessToken = null;
      this.sharedS3Paths = [];
      this.$store.dispatch('showNewWorkspace');
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingNewWorkspace': async function () {
      this.showing = this.$store.state.showingNewWorkspace;
    },
    selectedWorkspace: [{
      handler: 'getSelectedWorkspace',
    }],
    sharedS3Paths() {
      if (this.sharedS3Paths === null) {
        this.sharedS3Paths = [];
      }
      if (this.sharedS3Paths.length > 0) {
        if (!this.sharedS3Paths[this.sharedS3Paths.length - 1].startsWith('s3://')) {
          this.sharedS3Paths.pop();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.p-chips-multiple-container) {
  overflow: auto;
}
</style>
