<template>
  <div id="app" class="app">
    <router-view />
    <Toast />
    <ConfirmDialog />
  </div>
</template>

<style lang="scss">
</style>
