<template>
  <div>
    <DataTable
      :value="biosamples"
      v-model:selection="selectedBiosamples"
      :loading="loading"
      :paginator="true"
      v-model:rows="rows"
      dataKey="id"
      :rowHover="true"
      v-model:filters="filters"
      ref="dt"
      editMode="cell"
      @cell-edit-complete="onCellEditComplete"
      filterDisplay="menu"
      :currentPageReportTemplate=getCurrentPageReportTemplate
      :globalFilterFields="globalFilterFields"
      responsiveLayout="scroll"
      lazy
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
      :totalRecords="totalRecords"
      :selectAll="selectAll"
      @select-all-change="onSelectAllChange"
      @row-select="onRowSelect"
      @row-unselect="onRowUnselect"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 20, 50]">
      <template #header>
        <div class="header-div pt-2">
          <div class="flex">
            <div style="text-align:left" class="mr-2">
              <span class="p-float-label">
                <MultiSelect
                  id="ms-column-selector"
                  class="column-multi-select"
                  :modelValue="selectedColumns"
                  :options="columns"
                  optionLabel="name"
                  display="chip"
                  @update:modelValue="onToggleColumnMS"
                  placeholder="Select Columns" />
                <label for="ms-column-selector">Column Selector</label>
              </span>
            </div>
            <Button class="p-button export-btn" icon="pi pi-external-link" label="Export" @click="exportCSV($event)" />
            <FileUpload class="ml-2" :loading="processingImport" mode="basic" name="renameUpload[]" :maxFileSize="1000000" customUpload @uploader="onUpload" :auto="true" chooseLabel="Import" />
            <Button
              type="button"
              label="Update Columns"
              class="p-button ml-2 mb-2 mr-2"
              @click="openUpdateMetadataColumnsDialog()"
            />
            <Button
              type="button"
              label="Save changes"
              :class="'p-button ml-1 mb-2 mr-2 ' + (changedSampleIds.length === 0 ? '' : getPulsateClassFromTheme())"
              icon="pi pi-save"
              @click="saveChanges"
              :loading="savingChanges"
              :disabled="changedSampleIds.length === 0"
            />
          </div>
          <div class="flex justify-content-end flex-column sm:flex-row">
            <Button
              v-if="$store.state.precedenceLevel < 4"
              type="button"
              label="Delete"
              class="p-button mb-2 mr-2"
              @click="deleteBatchBiosamples"
              :disabled="selectedBiosamples.length === 0"
            />
            <Button
              type="button"
              label="Launch Secondary Analysis"
              class="p-button mb-2 mr-2"
              @click="openLaunchPipeline"
              :loading="loadingProject"
              :disabled="getProjectStatus !== 'Job Complete' && getProjectStatus !== 'Add biosamples Job Complete' && getProjectStatus !== 'Transfer Complete'"
            />
            <Button
              type="button"
              label="Launch Tertiary Analysis"
              class="p-button mb-2 mr-2"
              @click="openLaunchTertiaryAnalysis"
              :loading="loadingProject"
              :disabled="(getProjectStatus !== 'Job Complete' && getProjectStatus !== 'Add biosamples Job Complete' && getProjectStatus !== 'Transfer Complete') || getProjectNumberOfPipelines === 0"
            />
          </div>
        </div>
      </template>
      <template #empty>
        No biosamples found.
      </template>
      <template #loading>
        Loading biosamples... Please wait.
      </template>
      <Column selectionMode="multiple" />
      <Column v-for="col of selectedColumns" :key="col.name" :header="col.name" :dataType="col.dataType" :bodyStyle="getBodyStyle()" :headerStyle="headerStyle()" :field="col.inferredName" sortable>
        <template #body="{ data }">
          <div :class="(col.editable) ? 'editable-field' : '' ">
            <i v-if="isColumnTypeTF(col) && col.getFormattedOutput(data) === true" class="pi pi-check-circle true-check" />
            <i v-else-if="isColumnTypeTF(col) && col.getFormattedOutput(data) === false" class="pi pi-times-circle false-check" />
            <span v-else :class="col.getClass(data)">{{ col.getFormattedOutput(data) }}</span>
          </div>
        </template>
        <template #editor="{ data }" v-if="col.editable">
          <Component :is="col.getComponentName()" v-model="data.metadata[col.inferredName]" autofocus dateFormat="mm/dd/yy" inputId="minmaxfraction" :minFractionDigits="1" :maxFractionDigits="10" locale="en-US" />
        </template>
        <template #filter="{ filterModel }">
          <Dropdown v-if="isColumnTypeSize(col)" v-model="filterModel.size" :options="['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']" placeholder="Select size type" class="mb-1" />
          <Component :is="col.getComponentName()" v-model="filterModel.value" class="p-column-filter" placeholder="Search" autofocus dateFormat="mm/dd/yy" inputId="minmaxfraction" :minFractionDigits="1" :maxFractionDigits="10" locale="en-US" :options="availableBiosampleStatuses" />
          <span class="tf-filter-val-span" v-if="isColumnTypeTF(col)">{{ (valueIsNullOrUndefined(filterModel.value)) ? 'Undefined' : capitalizeFirstLetter(filterModel?.value?.toString()) }}</span>
        </template>
      </Column>
      <Column :bodyStyle="getBodyStyle()" :headerStyle="headerStyle()" v-if="$store.state.precedenceLevel < 4">
        <template #body="{ data }">
          <Button icon="pi pi-ellipsis-v" @click="actionClick(data, $event)" />
          <Menu :ref="'biosample-menu-' + data.id" :model="actionItems(data)" :popup="true" />
        </template>
      </Column>
    </DataTable>
    <DataTable
      id="export-dt"
      ref="exportDt"
      dataKey="id"
      :value="dataForExport"
      :exportFilename="getExportFileName"
      style="display: none;"
    >
      <Column v-for="col of selectedColumns" :key="col.name" :header="col.name" :field="col.name" />
    </DataTable>
    <DynamicLaunchPipelineDialog :project="projectForPipeline" :selectedBiosamples="selectedBiosamples" @triggerBiosampleLoadingAgain="main" />
    <BiosampleMetadataColumnsUpdateDialog :project="project" />
    <BiosampleMetadataImportDialog :header="header" :notFoundColumns="notFoundColumns" :columns="columns" :projectId="this.projectId" :projectColumns="projectBiosamplesColumns" @doneFixingHeader="doneFixingHeader" @setColumns="setColumns" />
  </div>
</template>

<script>
import { Auth, API, graphqlOperation } from 'aws-amplify';
import _ from 'lodash';
import * as queries from '@/graphql/queries';
import * as mutations from '@/graphql/mutations';
import * as customQueries from '@/graphql/custom_queries';
import DynamicLaunchPipelineDialog from '@/components/Pipeline/DynamicLaunchPipelineDialog.vue';
import {
  // eslint-disable-next-line no-unused-vars
  listItems, getTodayDateOnlyNormal, inferField, reformatFilterForValueInConstraints, checkIfFiltersAreEmpty, sortObj, valueIsNullOrUndefined, makeColumnsFromFactory, isValueNA, textColumnTypeChecks, numericColumnTypeChecks, getEmptyMetadataObj, capitalizeFirstLetter, makeBiosampleMetadataColumnsJson, sendNotificationEmail, removeDuplicatesByField,
} from '@/utils';
import { TF, Size } from '@/components/Biosamples/biosampleTableColumnFactory.js';
import BiosampleMetadataColumnsUpdateDialog from '@/components/Biosamples/BiosampleMetadataColumnsUpdateDialog.vue';
import BiosampleMetadataImportDialog from '@/components/Biosamples/BiosampleMetadataImportDialog.vue';

// eslint-disable-next-line no-unused-vars
const asyncBatch = require('async-batch').default;

export default {
  name: 'Biosamples',
  props: ['projectId'],
  components: {
    DynamicLaunchPipelineDialog,
    BiosampleMetadataColumnsUpdateDialog,
    BiosampleMetadataImportDialog,
  },
  data() {
    return {
      loading: false,
      loadingProject: false,
      savingChanges: false,
      processingImport: false,
      biosamples: [],
      allBiosamples: [],
      filteredBiosamples: [],
      noLimitOnPullingBiosamples: false,
      firstLoadToken: null,
      selectAll: false,
      totalRecords: 0,
      numberOfBiosamples: 0,
      filteredNumberOfBiosamples: 0,
      numberOfCheckedBiosamples: 0,
      totalNumberOfBiosamplesForFilter: 0,
      useFilteredTotalRecords: false,
      filterRemainingBiosamples: true, // When deleting biosamples, weather to filter after each delete
      changedSampleIds: [],
      projectForPipeline: null,
      dataForExport: [],
      project: null,
      rows: 10,
      selectedBiosamples: [],
      globalFilterFields: ['col1', 'col2', 'col3', 'col4', 'col5'],
      availableBiosampleStatuses: ['Pass', 'Fail', 'Missing FASTQs', 'Corrupted FASTQs', 'Empty FASTQs', 'PetaSuite fail', 'Insufficient number of reads'],
      columns: [],
      projectBiosamplesColumns: [],
      selectedColumns: this.columns,
      filters: {},
      mappedBiosamplesByName: {},
      positionOfBiosampleColumnInData: -1,
      header: [],
      notFoundColumns: [],
      validFilters: [],
      importedFile: null,
    };
  },
  async mounted() {
    this.main();
  },
  methods: {
    async main() {
      try {
        document.title = 'Biosamples';
        this.setInitialValues();
        this.loading = true;
        this.loadingProject = true;
        if (!valueIsNullOrUndefined(this.projectId)) {
          await this.loadProjectAndColumns(this.projectId);
          if (await this.checkIfMissingRequiredColumnsForPipeline()) return;
          await this.lazyLoadBiosamples();
          // await Promise.all([this.loadProjectAndColumns(this.projectId), this.lazyLoadBiosamples()]);
        }
        this.makeDescriptionElements(this.columns);
        this.loadingProject = false;
      } catch (error) {
        console.error(error);
      }
    },
    // Since this component can launch a pipeline, we need to get the current project. It can also be parsed from the parent as a prop but that lead to aditional coupling.
    async loadProjectAndColumns(projectId) {
      const response = await API.graphql(graphqlOperation(queries.getProject, {
        id: projectId,
      }));
      this.project = response.data.getProject;
      if (valueIsNullOrUndefined(this.project) || valueIsNullOrUndefined(this.project.biosampleMetadataColumns)) this.project = this.fixCaseWhereMetadataIsNull(this.project);
      this.columns.push(...this.getBiosampleMetadataColumns(this.project));
      this.columns = makeColumnsFromFactory(this.columns);
      this.filters = this.setFilters(this.columns);
      this.selectedColumns = this.columns;
      this.loadingProject = false;
    },
    fixCaseWhereMetadataIsNull(project) {
      // eslint-disable-next-line no-param-reassign
      project.biosampleMetadataColumns = JSON.stringify({ columns: [getEmptyMetadataObj()] });
      return project;
    },
    getBiosampleMetadataColumns(project) {
      try {
        if (valueIsNullOrUndefined(project) || valueIsNullOrUndefined(project.biosampleMetadataColumns)) return [];
        return JSON.parse(project.biosampleMetadataColumns).columns.filter((col) => col.name !== '' && col.type !== '');
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    setFilters(columns) {
      try {
        const filters = {};
        if (valueIsNullOrUndefined(columns)) return {};
        columns.forEach((col) => {
          filters[col.inferredName] = col.getFilterValues();
        });
        return filters;
      } catch (error) {
        console.error(error);
        return {};
      }
    },
    async lazyLoadBiosamples(desiredStart = 0, desiredEnd = this.rows, finishLoading = true) {
      this.loading = true;
      if (!(this.totalRecords === this.allBiosamples.length && this.totalRecords !== 0)) {
        if (!checkIfFiltersAreEmpty(this.filters) || this.totalRecords === 0) this.getTotalNumberOfBiosamples();
        do {
          if (!this.noLimitOnPullingBiosamples && this.allBiosamples.slice(desiredStart, desiredEnd).length >= this.rows) break;
          // eslint-disable-next-line prefer-const
          let biosamples = await this.getBiosamples(this.firstLoadToken);
          this.allBiosamples.push(...biosamples);
          if (valueIsNullOrUndefined(this.firstLoadToken)) break;
        } while (!valueIsNullOrUndefined(this.firstLoadToken));
      }
      this.noLimitOnPullingBiosamples = false;
      let biosamplesToReturn = this.allBiosamples.slice(desiredStart, desiredEnd);
      biosamplesToReturn = this.remapMetadataFromBiosamples(biosamplesToReturn);
      this.biosamples = biosamplesToReturn;
      if (finishLoading) this.loading = false;
      // eslint-disable-next-line consistent-return
      return biosamplesToReturn;
    },
    async lazyLoadWithFilter(desiredStart = 0, desiredEnd = this.rows, finishLoading = true) {
      this.loading = true;
      if (this.noLimitOnPullingBiosamples) {
        do {
          await this.getRemapAndFilterBiosamples();
        } while (!valueIsNullOrUndefined(this.firstLoadToken));
      } else if (((this.allBiosamples.slice(desiredStart, desiredEnd).length < this.rows) || this.noLimitOnPullingBiosamples) && this.numberOfCheckedBiosamples < this.totalNumberOfBiosamplesForFilter) {
        do {
          await this.getRemapAndFilterBiosamples();
        } while (!valueIsNullOrUndefined(this.firstLoadToken) && (this.allBiosamples.slice(desiredStart, desiredEnd).length < this.rows));
      }

      // this.numberOfBiosamples += this.rows;
      this.noLimitOnPullingBiosamples = false;
      // this.numberOfBiosamples = this.allBiosamples.length; // Was potential error. Test more
      this.numberOfBiosamples = this.totalNumberOfBiosamplesForFilter;
      if (valueIsNullOrUndefined(this.firstLoadToken)) this.numberOfBiosamples = this.allBiosamples.length;
      this.biosamples = this.remapMetadataFromBiosamples(this.allBiosamples.slice(desiredStart, desiredEnd));
      if (finishLoading) this.loading = false;
    },
    async getRemapAndFilterBiosamples() {
      let biosamples = await this.getBiosamples(this.firstLoadToken, 1000);
      this.numberOfCheckedBiosamples += biosamples.length;
      biosamples = this.remapMetadataFromBiosamples(biosamples);
      biosamples = await this.filterBiosamplesUsingBatch(biosamples);
      this.allBiosamples.push(...biosamples);
      return biosamples;
    },
    async getBiosamples(passedNextToken, passedLimit = null) {
      let limit = (this.noLimitOnPullingBiosamples) ? 1000 : this.rows + 10;
      if (!valueIsNullOrUndefined(passedLimit)) limit = passedLimit;
      // const params = {
      //   limit, ...((!valueIsNullOrUndefined(passedNextToken)) && { nextToken: passedNextToken }), projectId: this.projectId, filter: reformatFilterForValueInConstraints(this.filters, this.columns), sortDirection: 'DESC',
      // };
      // Added filter variable and updated params to fix syntax error when no filters are present as it causes a syntax error which prevents biosamples from loading
      const filter = reformatFilterForValueInConstraints(this.filters, this.columns);
      const params = {
        limit,
        ...((!valueIsNullOrUndefined(passedNextToken)) && { nextToken: passedNextToken }),
        projectId: this.projectId,
        ...(Object.keys(filter).length > 0 && { filter }),
        sortDirection: 'DESC',
      };
      console.log('params :>> ', params);
      const res = await API.graphql(graphqlOperation(customQueries.biosamplesByProjectForBiosamplesTable, params));
      const biosamples = res.data.biosamplesByProject.items;
      const nextToken = res.data.biosamplesByProject.nextToken;
      this.firstLoadToken = nextToken;
      return biosamples;
    },
    async getTotalNumberOfBiosamples() {
      // const params = {
      //   projectId: this.projectId, filter: reformatFilterForValueInConstraints(this.filters, this.columns), sortDirection: 'DESC',
      // };
      const params = {
        projectId: this.projectId, sortDirection: 'DESC',
      };
      const res = await listItems(customQueries.biosamplesByProjectSmall, params);
      this.numberOfBiosamples = res.length;
      this.totalNumberOfBiosamplesForFilter = res.length;
    },
    remapMetadataFromBiosamples(biosamples) {
      if (valueIsNullOrUndefined(this.project.biosampleMetadataColumns)) return biosamples;
      const remappedBiosamples = biosamples.map((bs) => this.remapBiosampleMetadata(bs));
      console.log('remappedBiosamples :>> ', remappedBiosamples);
      return remappedBiosamples;
    },
    remapBiosampleMetadata(bs) {
      const biosample = bs;
      if (valueIsNullOrUndefined(biosample.metadata)) {
        biosample.metadata = this.populateBiosampleMetadataObjectWithColumnKeys(this.project.biosampleMetadataColumns);
      } else {
        try {
          biosample.metadata = (typeof (biosample.metadata) === 'string') ? JSON.parse(biosample.metadata) : biosample.metadata;
        } catch (error) {
          console.error(error);
        }
      }
      return biosample;
    },
    populateBiosampleMetadataObjectWithColumnKeys(projectBiosampleColumnsJSON) {
      const metadataObj = {};
      const projectColumns = JSON.parse(projectBiosampleColumnsJSON).columns;
      projectColumns.forEach((col) => {
        metadataObj[inferField({ name: col.name })] = null;
      });
      return metadataObj;
    },
    setInitialValues() {
      this.columns = [
        {
          name: 'Biosample Name',
          type: 'Text',
          description: 'Biosample Name',
          editable: false,
        },
        {
          name: 'FASTQ Validation Status',
          type: 'Status',
          description: 'FASTQ Validation Status',
          editable: false,
        },
        {
          name: 'Size',
          type: 'Size',
          description: 'Size of biosample',
          editable: false,
        },
        {
          name: 'Total Number of Reads',
          type: 'TotalNumberOfReads',
          description: 'Total Number of Reads',
          editable: false,
        },
        {
          name: 'Read Length',
          type: 'ReadLength',
          description: 'Read Length',
          editable: false,
        },
        {
          name: 'Upload Date',
          type: 'UploadDate',
          description: 'Upload Date',
          editable: false,
        },
        {
          name: 'Bioskryb Product Lot ID',
          type: 'BioskrybProductLotID',
          description: 'Bioskryb Product Lot ID',
          editable: false,
        },
      ];
      this.selectedColumns = this.columns;
      this.filters = {};
      this.biosamples = [];
    },
    async checkIfMissingRequiredColumnsForPipeline() {
      if (!valueIsNullOrUndefined(this.$store.state.missingRequiredColumns)) {
        console.log('There are missing required columns');
        const missingRequiredColumnsForPipeline = this.$store.state.missingRequiredColumns;
        console.log('missingRequiredColumnsForPipeline :>> ', missingRequiredColumnsForPipeline);
        console.log('this.project :>> ', this.project);
        console.log('this.projectBiosamplesColumns :>> ', this.projectBiosamplesColumns);
        const projectColumns = JSON.parse(this.project.biosampleMetadataColumns).columns;
        await API.graphql(graphqlOperation(mutations.updateProject, {
          input: {
            id: this.project.id,
            biosampleMetadataColumns: makeBiosampleMetadataColumnsJson([...projectColumns, ...missingRequiredColumnsForPipeline]),
          },
        }));
        this.$store.dispatch('setMissingRequiredColumns', null);
        this.main();
        return true;
      }
      return false;
    },
    async makeDescriptionElements(columns) {
      try {
        if (!this.$route.fullPath.endsWith('/biosamples')) return;
        const classElements = document.getElementsByClassName('p-column-title');
        for (const element of classElements) {
          const col = columns.find((colIter) => colIter.name === element.innerText);
          const descriptionElement = document.createElement('i');
          descriptionElement.classList.add('description-icon', 'pi', 'pi-question-circle');
          const description = this.makeColumnDescription(col);
          descriptionElement.setAttribute('title', description);
          element.after(descriptionElement);
        }
      } catch (error) {
        console.error(error);
      }
    },
    makeColumnDescription(col) {
      try {
        return `Column Type: ${col.constructor.name}\nDescription: ${col.description}`;
      } catch (error) {
        console.error(error);
        return '';
      }
    },
    async onPage(event) {
      if (this.validFilters.length > 0) {
        this.lazyLoadWithFilter(event.first, event.first + this.rows);
      } else this.lazyLoadBiosamples(event.first, event.first + this.rows);
    },
    async onSort(event) {
      const col = this.columns.find((colIter) => colIter.inferredName === event.sortField);
      this.noLimitOnPullingBiosamples = true;

      if (this.validFilters.length === 0) await this.lazyLoadBiosamples(0, this.totalRecords, false);
      else {
        if (this.totalNumberOfBiosamplesForFilter === this.numberOfCheckedBiosamples) this.noLimitOnPullingBiosamples = false;
        await this.lazyLoadWithFilter(0, this.totalRecords, false);
      }
      this.biosamples = sortObj(this.biosamples, event.sortField, col.customCompare);
      if (event.sortOrder === -1) this.biosamples = this.biosamples.reverse();
      this.allBiosamples = this.biosamples;
      this.loading = false;
    },
    // eslint-disable-next-line no-unused-vars
    async onFilter(event) {
      try {
        console.log('event :>> ', event);
        const prevValidFilter = _.cloneDeep(this.validFilters);
        // eslint-disable-next-line no-unused-vars
        this.validFilters = Object.entries(this.filters).filter(([colName, filter]) => !valueIsNullOrUndefined(filter.constraints[0].value) && filter.constraints[0].value !== '');
        if (this.allFiltersRemoved(this.validFilters)) {
          console.log('All filters removed');
          this.handleAllFiltersRemoved();
        } else if (this.firstFilterAdded(prevValidFilter, this.validFilters)) {
          console.log('First filter added');
          this.handleFirstFilterAdded();
        } else if (this.newFilterWasAdded(prevValidFilter, this.validFilters)) {
          console.log('A new filter was added');
          this.handleNewFilterAdded();
        } else if (this.filterRemovedOrChanged(prevValidFilter, this.validFilters)) {
          console.log('A filter was removed or a filter was changed');
          this.handleFilterRemovedOrChanged();
        }
      } catch (error) {
        console.error(error);
      }
    },
    allFiltersRemoved(validFilters) {
      return validFilters.length === 0;
    },
    handleAllFiltersRemoved() {
      this.allBiosamples = [];
      this.firstLoadToken = null;
      this.numberOfCheckedBiosamples = 0;
      this.numberOfBiosamples = this.totalNumberOfBiosamplesForFilter;
      this.lazyLoadBiosamples();
    },
    firstFilterAdded(prevValidFilter, validFilters) {
      return prevValidFilter.length === 0 && validFilters.length > 0;
    },
    async handleFirstFilterAdded() {
      this.numberOfCheckedBiosamples = this.allBiosamples.length;
      this.allBiosamples = await this.filterBiosamplesUsingBatch(this.allBiosamples);
      this.numberOfBiosamples = this.allBiosamples.length + this.rows;
      this.lazyLoadWithFilter();
    },
    newFilterWasAdded(prevValidFilter, validFilters) {
      return prevValidFilter.length < validFilters.length;
    },
    async handleNewFilterAdded() {
      this.allBiosamples = await this.filterBiosamplesUsingBatch(this.allBiosamples);
      if (valueIsNullOrUndefined(this.firstLoadToken)) {
        this.numberOfBiosamples = this.allBiosamples.length;
        this.biosamples = this.allBiosamples.slice(0, this.rows);
      } else {
        this.numberOfBiosamples = this.allBiosamples.length + this.rows;
        this.lazyLoadWithFilter();
      }
    },
    filterRemovedOrChanged(prevValidFilter, validFilters) {
      return prevValidFilter.length >= validFilters.length;
    },
    handleFilterRemovedOrChanged() {
      this.allBiosamples = [];
      this.firstLoadToken = null;
      this.numberOfCheckedBiosamples = 0;
      this.numberOfBiosamples = this.allBiosamples.length + this.rows; // = this.rows
      this.lazyLoadWithFilter();
    },
    filterBiosamplesAllAtOnce() {
      return this.allBiosamples.filter((bs) => this.checkFilter(bs));
    },
    async filterBiosamplesUsingBatch(biosamples = []) {
      const chunkSize = 10;
      const allBiosampleChunks = [];
      let biosamplesToFilter = biosamples;
      if (biosamplesToFilter.length === 0) biosamplesToFilter = this.allBiosamples;
      for (let i = 0; i < biosamplesToFilter.length; i += chunkSize) {
        const chunk = biosamplesToFilter.slice(i, i + chunkSize);
        allBiosampleChunks.push(chunk);
      }
      let filteredBiosamples = await asyncBatch(allBiosampleChunks, this.filterBiosamplesBatch, 1000);
      filteredBiosamples = filteredBiosamples.flat();
      return filteredBiosamples;
    },
    filterBiosamplesBatch(biosamples) {
      return biosamples.filter((bs) => this.checkFilter(bs));
    },
    checkFilter(bs) {
      for (const [colName, filter] of this.validFilters) {
        const col = this.columns.find((colIter) => colIter.inferredName === colName);
        const matchMode = filter.constraints[0].matchMode;
        const valueFromFilter = filter.constraints[0].value;
        const sizeType = filter.constraints[0].size;
        try {
          if (filter.constraints.length === 2) {
            const matchMode2 = filter.constraints[1].matchMode;
            const valueFromFilter2 = filter.constraints[1].value;
            if (filter.operator === 'or' && !(col.compareForFilter(matchMode, bs, valueFromFilter, sizeType) || col.compareForFilter(matchMode2, bs, valueFromFilter2, sizeType))) return false;
            if (filter.operator === 'and' && !(col.compareForFilter(matchMode, bs, valueFromFilter, sizeType) && col.compareForFilter(matchMode2, bs, valueFromFilter2, sizeType))) return false;
          } else if (!col.compareForFilter(matchMode, bs, valueFromFilter, sizeType)) return false;
        } catch (error) {
          console.error(error);
        }
      }
      return true;
    },
    async onSelectAllChange(event) {
      const selectAll = event.checked;

      if (selectAll) {
        if (this.biosamples.length < this.totalRecords) {
          await this.getRestOfTheBiosamples(this.projectId, this.firstLoadToken);
        }
        this.selectedBiosamples = this.biosamples;
        this.selectAll = true;
      } else {
        this.selectAll = false;
        this.selectedBiosamples = [];
      }
    },
    // eslint-disable-next-line no-unused-vars
    async getRestOfTheBiosamples(projectId, token) {
      this.loading = true;
      let restOfBiosamples = [];
      if (this.validFilters.length > 0) {
        await this.lazyLoadBiosamples(0, this.totalRecords, false);
      } else {
        const params = {
          projectId, nextToken: token, sortDirection: 'DESC',
        };
        restOfBiosamples = await listItems(customQueries.biosamplesByProjectForBiosamplesTable, params);
        restOfBiosamples = this.remapMetadataFromBiosamples(restOfBiosamples);
        this.biosamples = [...this.allBiosamples, ...restOfBiosamples];
        this.allBiosamples.push(...restOfBiosamples);
      }
      this.loading = false;
    },
    onRowSelect(event) {
      this.selectAll = this.selectedBiosamples.length === this.totalRecords;
      if (window.event.shiftKey && this.selectAll === false) this.selectBetween(event);
    },
    selectBetween(event) {
      let startIndex = this.biosamples.map((biosample) => biosample.id).indexOf(this.selectedBiosamples.at(-2).id);
      let endIndex = event.index;
      if (startIndex > endIndex) {
        const tmp = startIndex;
        startIndex = endIndex;
        endIndex = tmp;
      }
      for (let i = startIndex; i <= endIndex; i += 1) {
        if (!this.selectedBiosamples.some((bs) => bs.id === this.biosamples[i].id)) this.selectedBiosamples.push(this.biosamples[i]);
      }
    },
    onRowUnselect() {
      this.selectAll = false;
    },
    inferField(col) {
      return inferField(col);
    },
    headerStyle() {
      return 'width: 17%';
    },
    getBodyStyle() {
      return 'text-align: center';
    },
    onCellEditComplete(event) {
      if (!this.changedSampleIds.includes(event.newData.id)) this.changedSampleIds.push(event.newData.id);
    },
    openLaunchPipeline() {
      this.projectForPipeline = this.project;
      this.$store.dispatch('showLaunchPipeline');
    },
    openLaunchTertiaryAnalysis() {
      this.$store.dispatch('setRunningTertiaryAnalysis', true);
      this.$router.push({ path: `/workspace/${this.$store.state.activeWorkspace}/tertiaryAnalysis/${this.$route.params.id}` });
    },
    async saveChanges() {
      try {
        this.savingChanges = true;
        const promises = [];
        this.changedSampleIds.forEach((biosampleId) => {
          const biosample = this.biosamples.find((bs) => bs.id === biosampleId);
          if (biosample !== null && biosample !== undefined) {
            const updateObject = {
              id: biosample.id,
              metadata: JSON.stringify(this.filterMetadataFoldWrongValues(biosample.metadata)),
            };
            promises.push(API.graphql(graphqlOperation(mutations.updateBiosample, { input: updateObject })));
          }
        });
        await Promise.all(promises);
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Biosamples updated successfully!',
          life: 3000,
        });
        this.savingChanges = false;
        this.changedSampleIds = [];
      } catch (error) {
        console.error(error);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Biosamples update failed!',
          life: 3000,
        });
        this.savingChanges = false;
        this.changedSampleIds = [];
      }
    },
    filterMetadataFoldWrongValues(metadata) {
      const filteredMetadata = {};
      for (const [key, value] of Object.entries(metadata)) {
        if (this.metadataValueIsValid(key, value)) filteredMetadata[key] = value;
      }
      return filteredMetadata;
    },
    metadataValueIsValid(key, value) {
      const column = this.columns.find((col) => col.inferredName === key);
      if (valueIsNullOrUndefined(column)) return false;
      return column.checkIfValueIsGood(value);
    },
    isColumnTypeTF(col) {
      return col instanceof TF;
    },
    isColumnTypeSize(col) {
      return col instanceof Size;
    },
    getTodayDateOnlyNormal() {
      return getTodayDateOnlyNormal();
    },
    actionClick(biosample, event) {
      this.$refs[`biosample-menu-${biosample.id}`].toggle(event);
    },
    actionItems(biosample) {
      const options = [];
      const deleteBiosample = {
        label: 'Delete',
        command: () => {
          this.$confirm.require({
            message: 'Are you sure you want to delete the selected biosample?',
            header: 'Confirmation',
            icon: 'pi pi-info-circle',
            accept: async () => {
              console.log('biosample :>> ', biosample);
              this.deleteBiosample(biosample);
              this.sendBiosamplesDeletedEmail([biosample]);
            },
            reject: () => {
            },
          });
        },
      };
      if (this.$store.state.precedenceLevel < 4) options.push(deleteBiosample);
      return options;
    },
    async deleteBatchBiosamples() {
      this.loading = true;
      try {
        this.filterRemainingBiosamples = false;
        await asyncBatch(this.selectedBiosamples, this.deleteBiosample, 100);
        this.sendBiosamplesDeletedEmail(this.selectedBiosamples);
        let biosamplesForFilter = this.biosamples;
        this.selectedBiosamples.forEach((selectedBiosample) => {
          try {
            biosamplesForFilter = biosamplesForFilter.filter((bs) => bs.id !== selectedBiosample.id);
          } catch (error) {
            console.error(error);
          }
        });
        this.totalRecords -= this.selectedBiosamples.length;
        this.selectedBiosamples = [];
        this.biosamples = biosamplesForFilter;
        this.filterRemainingBiosamples = true;
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    async deleteBiosample(biosample) {
      try {
        // this.createBiosampleDeletionLog(biosample);
        if (this.filterRemainingBiosamples) {
          const filtered = _.cloneDeep(this.biosamples.filter((bs) => biosample.id !== bs.id));
          this.biosamples = filtered;
        }
        await API.graphql(graphqlOperation(mutations.deleteBiosample, { input: { id: biosample.id } }));
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Biosample deleted successfully!',
          life: 3000,
        });
      } catch (error) {
        console.error(error);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Biosample deletion failed!',
          life: 3000,
        });
      }
    },
    async createBiosampleDeletionLog(biosample) {
      try {
        if (biosample === null || biosample === undefined) {
          throw new Error('Biosample is null in createDeletionLog');
        }
        const user = await Auth.currentAuthenticatedUser();
        const deleteLogObj = {
          organizationId: biosample.project.workspace.organizationId,
          organizationName: biosample.project.workspace.organization.organizationName,
          workspaceId: biosample.project.workspace.id,
          workspaceName: biosample.project.workspace.description,
          projectName: biosample.project.clientProjectName,
          userUuidCognito: user.username,
          userNameCognito: user.attributes.email,
          uuidOfDeletedEntity: biosample.id,
          deletedInParent: false,
          typeOfDeletedEntity: 'biosample',
          status: 'GraphQlDeletion',
          affectedBiosamples: JSON.stringify([{ id: biosample.id, name: biosample.biosampleName }]),
          dateOfCreationOfDeletedEntity: biosample.created,
          emailInfo: JSON.stringify(this.makeEmailInfoFromBiosample(biosample)),
          readGroups: biosample.readGroups,
          writeGroups: biosample.writeGroups,
          adminGroups: biosample.adminGroups,
        };
        await API.graphql(graphqlOperation(mutations.createDeletedEntitiesLog, { input: deleteLogObj }));
      } catch (error) {
        console.error(error);
      }
    },
    sendBiosamplesDeletedEmail(selectedBiosamples) {
      const firstSample = selectedBiosamples[0];
      const initiator = firstSample.project.initiator;
      const message = `Dear ${initiator},\\n\\n We would like to inform you that your Basejumper biosample${(selectedBiosamples.length === 1) ? '' : 's'}, ${(selectedBiosamples.length === 1) ? 'has' : 'have'} been deleted. Kindly review the following details:\\n\\n Organization: ${firstSample.project.workspace.organization.organizationName}\\n\\n Organization Workspace: ${firstSample.project.workspace.description}\\n\\n Project Name: ${firstSample.project.clientProjectName}\\n\\n Affected Biosamples: ${selectedBiosamples.map((sample) => sample.biosampleName).join(', ')}\\n\\n Initiator: ${initiator}\\n\\n Creation Date: ${firstSample.created}\\n\\n Deletion Date: ${new Date().toISOString()}\\n\\n Should you have inquiries or believe this was executed in error, please reach out to our support team at [basejumper@bioskryb.com]. We appreciate your understanding.\\n\\n Best regards,\\n\\n BaseJumper Support Team`;
      try {
        sendNotificationEmail(message, [initiator]);
      } catch (error) {
        console.error(error);
      }
    },
    makeEmailInfoFromBiosample(biosample) {
      return {
        application_environment: process.env.VUE_APP_SLS_STAGE,
        project_name: biosample.project.clientProjectName,
        project_uuid: biosample.project.id,
        initiator: biosample.project.initiator,
        workspace_uuid: biosample.project.workspace.id,
        workspace_name: biosample.project.workspace.description,
        organization_uuid: biosample.project.workspace.organization.id,
        organization_name: biosample.project.workspace.organization.organizationName,
        commit_id: process.env.VUE_APP_AWS_COMMIT_ID,
      };
    },
    onToggleColumnMS(val) {
      this.selectedColumns = this.columns.filter((col) => val.includes(col));
    },
    async exportCSV() {
      try {
        if (this.biosamples.length < this.totalRecords) {
          await this.getRestOfTheBiosamples(this.projectId, this.firstLoadToken);
        }
        const seen = new Set();
        this.biosamples.forEach((biosample) => {
          if (!seen.has(biosample.id)) {
            const exportBsObj = {};
            this.selectedColumns.forEach((selectedCol) => {
              exportBsObj[selectedCol.name] = selectedCol.getExportOutput(biosample);
            });
            this.dataForExport.push(exportBsObj);
          }
          seen.add(biosample.id);
        });
        this.$refs.exportDt.exportCSV();
        this.dataForExport = [];
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Biosamples table exported successfully!',
          life: 3000,
        });
      } catch (error) {
        console.error(error);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Exporting biosamples table failed!',
          life: 5000,
        });
      }
    },
    async onUpload(event) {
      this.loading = true;
      this.processingImport = true;
      try {
        if (valueIsNullOrUndefined(event.files)) throw new Error('Event files is bad in upload');
        const file = event.files[0];
        if (valueIsNullOrUndefined(file)) throw new Error('File is bad in upload');
        this.handleImportedFile(file);
      } catch (error) {
        console.error(error);
      }
      this.processingImport = false;
      this.loading = false;
    },
    async handleImportedFile(file) {
      if (this.checkIfUnsavedChangesExist()) {
        this.$confirm.require({
          message: 'There are unsaved changes. Continuing with the import will overwrite all unsaved fields. Continue?',
          header: 'Confirmation',
          icon: 'pi pi-info-circle',
          accept: async () => {
            this.proceedWithImport(file);
          },
          reject: () => {
          },
        });
      } else {
        this.proceedWithImport(file);
      }
    },
    async proceedWithImport(file) {
      try {
        this.importedFile = file;
        await this.getAllBiosamples(this.projectId);
        this.populateBiosamplesNameMap(this.biosamples);
        this.checkIfFileIsTSVOrCSV(file);
        this.readMetadataFileHeader(file);
      } catch (error) {
        console.error(error);
      }
    },
    checkIfUnsavedChangesExist() {
      return this.changedSampleIds.length > 0;
    },
    async getAllBiosamples(projectId) {
      const params = {
        projectId, sortDirection: 'DESC',
      };
      let allBiosamples = await listItems(customQueries.biosamplesByProjectForBiosamplesTable, params);
      allBiosamples = this.remapMetadataFromBiosamples(allBiosamples);
      this.allBiosamples = allBiosamples;
      this.biosamples = allBiosamples;
    },
    populateBiosamplesNameMap(biosamples) {
      // eslint-disable-next-line no-return-assign
      biosamples.forEach((bs) => this.mappedBiosamplesByName[bs.biosampleName] = bs);
    },
    checkIfFileIsTSVOrCSV(file) {
      if (!file.name.endsWith('.csv') && !file.name.endsWith('.tsv')) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Only .tsv and .csv files are accepted!',
          life: 5000,
        });
      }
    },
    readMetadataFileHeader(file) {
      const reader = new FileReader();
      reader.readAsText(file);
      // eslint-disable-next-line no-unused-vars
      let header = [];
      reader.onload = (evt) => {
        let rows = evt.target.result.split('\n');
        // const rows = evt.target.result.split('\r');
        rows = rows.map((row) => row.replace(/,?\r/g, ''));
        const headerRow = rows[0];
        const fixedHederRow = this.fixRowNewline(headerRow);
        const split = this.splitRow(file.name, fixedHederRow);
        header = this.handleHeaderRow(split);
        if (header === null) { // Can be null in case of empty header column
          console.error('Header null');
          return;
        }
        if (header.length > 0) {
          this.header = header;
          this.readMetadataFileData(this.importedFile);
        }
      };
      reader.onerror = (evt) => {
        console.error('Error reading file');
        console.log('evt :>> ', evt);
      };
    },
    readMetadataFileData(file) {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (evt) => {
        const rows = evt.target.result.split('\r');
        // eslint-disable-next-line no-unused-vars
        for (const [rowIndex, row] of rows.entries()) {
          if (row === '\n') return;
          const fixedRow = this.fixRowNewline(row);
          const split = this.splitRow(file.name, fixedRow);
          this.handleDataRow(this.header, split, this.positionOfBiosampleColumnInData);
        }
      };
      // eslint-disable-next-line no-unused-vars
      reader.onerror = (evt) => {
        console.error('Error reading file');
      };
      console.log('Done');
      this.$toast.add({
        severity: 'success',
        summary: 'Import Complete',
        detail: 'You table has been imported successfully. You can now make additional changes to the table. Don\'t forget to click "Save Changes" when you are done.',
        life: 7000,
      });
    },
    fixRowNewline(row) {
      let fixedRow = row;
      if (fixedRow.startsWith('\n')) fixedRow = fixedRow.split('\n')[1];
      return fixedRow;
    },
    splitRow(fileName, row) {
      let split = [];
      if (fileName.endsWith('.csv')) {
        split = row.split(',');
      } else if (fileName.endsWith('.tsv')) {
        split = row.split('\t');
      }
      return split;
    },
    rowIsHeader(rowIndex) {
      return rowIndex === 0;
    },
    handleHeaderRow(split) {
      const headerSplit = split.map((headerString) => headerString.replace(/\\/g, '').replace(/"/g, ''));
      const header = [];
      const notFoundColumns = [];
      this.positionOfBiosampleColumnInData = this.indexOfBiosampleColumn(headerSplit);
      if (this.positionOfBiosampleColumnInData === -1) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'The Biosample Name column is missing.',
          life: 5000,
        });
        return [];
      }
      let error = false;
      // eslint-disable-next-line consistent-return
      headerSplit.forEach((columnString) => {
        if (columnString !== '') {
          const found = this.columns.find((col) => col.name === columnString);
          if (found) {
            header.push(found);
          } else {
            notFoundColumns.push({
              name: columnString,
              index: headerSplit.indexOf(columnString),
            });
          }
        } else {
          this.$toast.add({
            severity: 'error',
            summary: 'Empty Header',
            detail: 'You left and empty header in your table. Please fix it and try again',
            life: 5000,
          });
          error = true;
        }
      });
      if (error) return null;
      if (notFoundColumns.length > 0) {
        this.header = header;
        this.notFoundColumns = notFoundColumns;
        this.$store.dispatch('setShowingBiosampleMetadataImportDialog', true);
        return [];
      }
      return header;
    },
    indexOfBiosampleColumn(header) {
      return header.indexOf('Biosample Name');
    },
    doneFixingHeader(fixedHeader) {
      this.header = fixedHeader;
      this.readMetadataFileData(this.importedFile);
    },
    setColumns(columns) {
      this.columns = [...this.columns, ...columns];
      this.selectedColumns.push(...columns);
    },
    handleDataRow(header, split, positionOfBiosampleColumnInData) {
      const biosampleName = split[positionOfBiosampleColumnInData];
      if (biosampleName in this.mappedBiosamplesByName) {
        const biosample = this.mappedBiosamplesByName[biosampleName];
        for (const [columnIndex, data] of split.entries()) {
          // eslint-disable-next-line no-continue
          if (columnIndex === 0 || data === '') continue;
          const column = header[columnIndex];
          if (this.tryToSetData(column, data, biosample)) this.changedSampleIds.push(biosample.id);
        }
      }
    },
    tryToSetData(parsedInferredColumn, data, biosample) {
      const inferredColumn = parsedInferredColumn;
      if (!this.editableAndCorrectType(inferredColumn, data)) return false;
      try {
        if (inferredColumn.inferredName in biosample) {
          if (biosample[inferredColumn.inferredName] !== data) {
            // eslint-disable-next-line no-param-reassign
            biosample[inferredColumn.inferredName] = inferredColumn.formatValueForImport(data);
            return true;
          }
        } else if (biosample.metadata[inferredColumn.inferredName] !== inferredColumn.formatValueForImport(data)) {
          // eslint-disable-next-line no-param-reassign
          biosample.metadata[inferredColumn.inferredName] = inferredColumn.formatValueForImport(data);
          return true;
        }
      } catch (error) {
        console.error(error);
      }
      return false;
    },
    editableAndCorrectType(inferredColumn, data) {
      try {
        return inferredColumn.editable && inferredColumn.checkCorrectDataType(data);
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    openUpdateMetadataColumnsDialog() {
      this.$store.dispatch('setShowingUpdateBiosampleMetadataColumns', true);
    },
    handleTFData(data) {
      if (!valueIsNullOrUndefined(data)) {
        const lower = data.toLowerCase();
        if (lower === 'true') return true;
        if (lower === 'false') return false;
      }
      return data;
    },
    allExistingBiosamplesAreLoaded() {
      return this.numberOfBiosamples === this.allBiosamples.length;
    },
    valueIsNullOrUndefined(value) {
      return valueIsNullOrUndefined(value);
    },
    capitalizeFirstLetter(string) {
      return capitalizeFirstLetter(string);
    },
    getPulsateClassFromTheme() {
      if (localStorage.getItem('theme') === 'dark') return 'pulsate-dark';
      return 'pulsate-light';
    },
  },
  computed: {
    getExportFileName() {
      if (valueIsNullOrUndefined(this.project)) return `Biosamples_${getTodayDateOnlyNormal()}`;
      return `${this.project.clientProjectName}_Biosamples_${getTodayDateOnlyNormal()}`;
    },
    getCurrentPageReportTemplate() {
      try {
        if (this.validFilters.length > 0) return 'Showing {first} to {last}';
        return 'Showing {first} to {last} of {totalRecords}';
      } catch (error) {
        console.error(error);
        return '';
      }
    },
    getProjectStatus() {
      try {
        if (this.project === null || this.project === undefined) return null;
        return this.project.status;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    getProjectNumberOfPipelines() {
      try {
        if (this.project === null || this.project === undefined) return null;
        return this.project.pipelines.items.length;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingLaunchPipeline': async function () {
      if (this.$store.state.showingLaunchPipeline === false) {
        this.projectForPipeline = null;
      }
    },
    // eslint-disable-next-line func-names
    '$store.state.showingUpdateBiosampleMetadataColumns': async function () {
      if (!this.$store.state.showingUpdateBiosampleMetadataColumns) {
        this.main();
      }
    },
    numberOfBiosamples() {
      this.totalRecords = this.numberOfBiosamples;
    },
    project() {
      if (valueIsNullOrUndefined(this.project) || valueIsNullOrUndefined(this.project.biosampleMetadataColumns)) return;
      this.projectBiosamplesColumns = this.project.biosampleMetadataColumns;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/sass/_dataTable.scss";

::v-deep(.p-paginator) {
    border-radius: $border_rounded_bottom;
}

::v-deep(.p-selection-column) {
  width: 5%;
}

.header-div {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.column-multi-select {
  max-width: 20vh;
}

.true-check {
  color: green;
  font-size: 2rem;
}

.false-check {
  color: red;
  font-size: 2rem;
}

::v-deep(.p-editable-column) {
  width: 100px;
}

.export-btn {
  height: 85%;
}

::v-deep(.description-icon) {
  padding-left: 15px;
  padding-right: 5px;
  &:hover {
    filter: brightness(0.30);
  }
}

.light {
  .editable-field {
    border: 3px solid rgba(105,174,205,0.5) !important;
    padding: 10px;
    border-radius: 15px;
  }
}

.dark {

  .editable-field {
  border: 3px solid rgba(160, 204, 44,0.5) !important;
  padding: 10px;
  border-radius: 15px;
  }
}

.tf-filter-val-span {
  position: absolute;
  padding-top: 5px;
  padding-left: 15px;
}

@keyframes pulsateLight {
  0% {
    box-shadow: 0 0 0 0 #19396d;
  }
  70% {
    box-shadow: 0 0 0 10px rgba(237, 49, 49, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(237, 49, 49, 0);
  }
}

@keyframes pulsateDark {
  0% {
    box-shadow: 0 0 0 0 #A0CC2C;
  }
  70% {
    box-shadow: 0 0 0 10px rgba(237, 49, 49, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(237, 49, 49, 0);
  }
}

.pulsate-dark {
  animation: pulsateDark 2s infinite;
}

.pulsate-light {
  animation: pulsateLight 2s infinite;
}
</style>
