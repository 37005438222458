export const onUpdateProject = /* GraphQL */ `
  subscription OnUpdateProject {
    onUpdateProject {
      id
      status
    }
  }
`;

export const onUpdateWorkspace = /* GraphQL */ `
  subscription OnUpdateWorkspace {
    onUpdateWorkspace {
      id
      size
      lastSizeScan
    }
  }
`;
