<template>
  <div class="main">
    <div v-if="!vis && !loading">
      <ScrollPanel class="custom-scroller">
        <div class="grid flex justify-content-evenly">
          <Card v-for="vis in allLaunchableVisualizations" :key="vis.id">
            <template #title>
              {{ vis.visualizationName }}
            </template>
            <template #content>
              {{ vis.description }}
            </template>
            <template #footer>
              <Button class="card-btn" label="Open" @click="selectVisualization(vis)" />
            </template>
          </Card>
        </div>
      </ScrollPanel>
    </div>
    <div v-if="loading" class="flex justify-content-center">
      <!-- <ProgressSpinner style="width:30%;height:30%"></ProgressSpinner> -->
      <img alt="BJSpinner" class="bjSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png" />
    </div>
    <div v-if="vis && pipeline && !loading" class="mt-2 vp">
      <VisualizationWrapper :passedPipeline="pipeline" :passedVisualization="vis" />
    </div>
  </div>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify';
import * as AWS from 'aws-sdk';
import * as customQueries from '@/graphql/custom_queries';
import { validateAllVisualizations } from '@/utils';
import VisualizationWrapper from './VisualizationWrapper.vue';

export default {
  name: 'VisualizationCardSelector',
  props: ['pipeline', 'passedVisualization'],
  components: { VisualizationWrapper },
  data() {
    return {
      allLaunchableVisualizations: [],
      vis: null,
      loading: true,
      key: null,
      s3Contents: [],
      gettingFilesDone: null,
    };
  },
  async mounted() {
    this.key = `${this.pipeline.project.workspace.organization.id}/${this.pipeline.project.workspace.id}/${this.pipeline.project.id}/pipeline/${this.pipeline.pipelineOutputS3Path}`;
    this.gettingFilesDone = this.getFilePathsToSendToPlot();
    await this.loadAllVisualizationsNew(this.pipeline);
  },
  methods: {
    selectVisualization(vis) {
      this.vis = vis;
    },
    async loadAllVisualizationsNew(pipeline) {
      try {
        this.loading = true;
        const visualizations = (await API.graphql(graphqlOperation(customQueries.listVisualizations, { limit: 300 }))).data.listVisualizations.items;
        this.allLaunchableVisualizations = await validateAllVisualizations(visualizations, pipeline, this.gettingFilesDone, this.s3Contents);
        this.loading = false;
      } catch (error) {
        console.error('Error in loading all visualizations');
        console.error(error);
        this.loading = false;
      }
    },
    async getFilePathsToSendToPlot(token = null) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        const s3 = new AWS.S3();
        let params = {};
        if (token == null) {
          params = {
            Bucket: process.env.VUE_APP_BUCKET,
            Prefix: this.key,
          };
        } else {
          params = {
            Bucket: process.env.VUE_APP_BUCKET,
            Prefix: this.key,
            ContinuationToken: token,
          };
        }
        try {
          s3.listObjectsV2(params, (err, data) => {
            if (data) {
              this.s3Contents.push(...data.Contents);
              if (!data.IsTruncated) {
                resolve();
              } else {
                this.getFilePathsToSendToPlot(data.NextContinuationToken).then(resolve);
              }
            }
            if (err) {
              console.error(err);
            }
          });
        } catch (error) {
          console.log('Error with getting files for plot');
          console.log(error);
        }
      });
    },
    back() {
      this.sharedData = {};
      this.vis = null;
      this.readyToSend = false;
    },
  },
};
</script>

  <style lang="scss">

  .card-btn {
    width: 100%;
  }
  .vc-image {
    border-radius: 15px !important;
  }

  .grid {
    position: relative;
    display: grid;
    left: 8px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center
  }

  .spinner-wrapper {
    overflow: hidden;
    position: relative;
    // height: 70vh;
    width: 100%;
    overflow: hidden;
  }

  .p-progress-spinner {
    top: 30%;
  }

  .bjSpinner {
    height: 30%;
    width: 30%;
  }
  </style>
