<template>
  <Pipelines v-model="selectedPipelines" @updateData="updateData" />
  <div class="flex justify-content-between pt-3">
    <Button class="mt-2 mb-2 p-button-raised p-button-secondary" label="Back" icon="pi pi-arrow-left" @click="back()" />
    <Button class="mt-2 mb-2 p-button-raised" label="Next" icon="pi pi-arrow-right" @click="nextPage" :disabled="selectedPipelines.length === 0" />
  </div>
  <div class="flex justify-content-end" />
</template>
<script>
// import Pipelines from '@/views/Project/Pipelines.vue';
import Pipelines from '../../../views/Project/Pipelines.vue';

export default {
  components: { Pipelines },
  props: ['formData'],
  data() {
    return {
      selectedPipelines: [],
    };
  },
  methods: {
    back() {
      this.$store.dispatch('setRunningTertiaryAnalysis', false);
      this.$router.push(`/workspace/${this.$store.state.activeWorkspace}/project/${this.$route.params.id}/biosamples`);
    },
    nextPage() {
      this.$emit('nextPage', { formData: { selectedSecondaryPipelines: this.selectedPipelines }, pageIndex: 0 });
    },
    updateData(value) {
      this.selectedPipelines = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/sass/_dataTable.scss";
</style>
