<template>
  <Dialog v-model:visible="showing" :style="{ width: '600px' }" header="Fix Groups" :modal="true" class="p-fluid launchablePipelineDialog" :closable="false">
    <!-- <img alt="BJSpinner" class="bjSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png"/> -->
  </Dialog>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify';
import { listItems } from '@/utils';
import * as queries from '@/graphql/queries';
import * as mutations from '@/graphql/mutations';

export default {
  data() {
    return {
      showing: false,
    };
  },
  methods: {
    async fixWorkspaceGroups() {
      console.log('FIXING');
      const workspaces = await listItems(queries.listWorkspaces, {});
      console.log('workspaces :>> ', workspaces);
      for (let i = 0; i <= workspaces.length; i += 1) {
        const workspace = workspaces[i];
        // console.log('workspace :>> ', workspace);
        if (workspace === null || workspace === undefined) {
          console.log('workspace with null:>> ', workspace);
          // eslint-disable-next-line no-continue
          continue;
        }
        if (workspace.organizationId === null || workspace.organizationId === undefined) {
          console.log('workspace with orgId null:>> ', workspace);
          // eslint-disable-next-line no-continue
          continue;
        }
        // eslint-disable-next-line no-continue
        // if (workspace.organizationId === 'a34ea85b-ba79-49b9-a32e-a824f1cbea14') continue;
        const indexOfInReadGroupsUser = workspace.readGroups.indexOf(`ORG/${workspace.organizationId}/User`);
        const indexOfInWriteGroupsAdmin = workspace.writeGroups.indexOf(`ORG/${workspace.organizationId}/Admin`);
        const indexOfInAdminGroupsAdmin = workspace.adminGroups.indexOf(`ORG/${workspace.organizationId}/Admin`);
        console.log('indexOfInReadGroupsUser :>> ', indexOfInReadGroupsUser);
        console.log('indexOfInWriteGroupsAdmin :>> ', indexOfInWriteGroupsAdmin);
        console.log('indexOfInAdminGroupsAdmin :>> ', indexOfInAdminGroupsAdmin);
        const input = {
          id: workspace.id,
        };
        if (indexOfInReadGroupsUser === -1) {
          const readGroups = JSON.parse(JSON.stringify(workspace.readGroups));
          readGroups[1] = `ORG/${workspace.organizationId}/User`;
          input.readGroups = readGroups;
        }
        if (indexOfInWriteGroupsAdmin === -1) {
          const writeGroups = JSON.parse(JSON.stringify(workspace.writeGroups));
          writeGroups[1] = `ORG/${workspace.organizationId}/Admin`;
          input.writeGroups = writeGroups;
        }
        if (indexOfInAdminGroupsAdmin === -1) {
          const adminGroups = JSON.parse(JSON.stringify(workspace.adminGroups));
          adminGroups[1] = `ORG/${workspace.organizationId}/Admin`;
          input.adminGroups = adminGroups;
        }
        console.log('workspace :>> ', workspace);
        console.log('input :>> ', input);
        if (Object.keys(input).length > 1) {
          console.log('UPDATING WS');
          await API.graphql(graphqlOperation(mutations.updateWorkspace, {
            input,
          }));
        }
      }
      this.$store.dispatch('setShowingFixOrgGroupsDialog', false);
    },
    async fixOrgGroups() {
      try {
        console.log('FIXING');
        const organizations = await listItems(queries.listOrganizations, {});
        for (let i = 0; i < organizations.length; i += 1) {
          const organization = organizations[i];
          if (organization !== null && organization !== undefined) {
            // const indexOfInReadGroupsUser = organization.readGroups.indexOf(`ORG/${organization.id}/User`);
            // const indexOfInWriteGroupsAdmin = organization.writeGroups.indexOf(`ORG/${organization.id}/Admin`);
            const input = {
              id: organization.id,
            };

            // if (indexOfInReadGroupsUser === -1) {
            //   const readGroups = JSON.parse(JSON.stringify(organization.readGroups));
            //   readGroups[0] = `ORG/${organization.id}/User`;
            //   input.readGroups = readGroups;
            // }
            // if (indexOfInWriteGroupsAdmin === -1) {
            //   const writeGroups = JSON.parse(JSON.stringify(organization.writeGroups));
            //   writeGroups[0] = `ORG/${organization.id}/Admin`;
            //   input.writeGroups = writeGroups;
            // }
            const updatedReadGroups = JSON.parse(JSON.stringify(organization.readGroups));
            organization.workspaces.items.forEach((ws) => {
              if (organization.readGroups.indexOf(`WS/${ws.id}/Admin`) === -1) updatedReadGroups.push(`WS/${ws.id}/Admin`);
              if (organization.readGroups.indexOf(`WS/${ws.id}/User`) === -1) updatedReadGroups.push(`WS/${ws.id}/User`);
            });
            input.readGroups = updatedReadGroups;
            console.log('input :>> ', input);
            if (Object.keys(input).length > 1) {
              console.log('UPDATING ORG');
              await API.graphql(graphqlOperation(mutations.updateOrganization, {
                input,
              }));
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingFixOrgGroupsDialog': async function () {
      this.showing = this.$store.state.showingFixOrgGroupsDialog;
      if (this.showing) {
        this.fixWorkspaceGroups();
        this.fixOrgGroups();
      }
    },
  },
};
</script>
