/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
/* eslint-disable no-param-reassign */
import { API, Auth, graphqlOperation } from 'aws-amplify';
import * as AWS from 'aws-sdk';
import * as AWSEventBridge from '@aws-sdk/client-eventbridge';
import * as queries from '@/graphql/queries';
import * as customQueries from '@/graphql/custom_queries';
import store from '@/store';
import * as biosampleMetadataColumnsFactory from '@/components/Biosamples/biosampleTableColumnFactory.js';
/*
The whole role system uses Cognito groups. These groups are created by a lambda function on AWS which creates these groups. The FE job is to use these groups and have the ability to change the users in these groups. This is how BaseJumper handles assigning users to different organizations/workspaces. If we whant to assign a user to a workspace we need to add him to that workspaces group (user or admin). This might be expanded in the future. The main docs for this can be found on confulence. A part of the docs: https://bioskryb.atlassian.net/wiki/spaces/B/pages/359858182/Organization+Workspace-Cognito+Group+Mapping+Function+Requirements

The main BaseJumperAdmin group has access to everything. The rest of the groups follow a pattern:

Organization groups : ORG/org_id/Admin or ORG/org_id/User
Since organizations are the parents of workspaces, they need to be able to modify everything about the workspaces. Meaning the standard CRUD operations as well as assigning users to specific groups.

Worokspace groups : WS/ws_id/Admin or WS/ws_id/User

Each entitiy in the databse contains admin, read and write groups (sometimes only two), which dictate which groups can access these entities. For example if a project has a WS/111/User it means that users with/in that group can only see this project but cannot modify it. The limitations are done both on the forntend (disable the button) and on the "backend"(on cognito, it simply will not let you do it).

The organization has higher precedence than the workspace meaning if the user is a user in a workspace but is also an organization admin of the organization which contains that workspace, he will be able to view and change everything in that workspace.

Comment to force build
*/

const ADMIN_GROUP = 'BaseJumperAdmin';

export const isAdmin = async () => {
  const user = await Auth.currentAuthenticatedUser();
  const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
  if (!groups) {
    return null;
  }
  // console.log('groups.indexOf(ADMIN_GROUP) > -1 :>> ', groups.indexOf(ADMIN_GROUP) > -1);
  return groups.indexOf(ADMIN_GROUP) > -1;
};

export const isUser = async () => {
  const user = await Auth.currentAuthenticatedUser();
  const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
  if ((groups === undefined)) return false;
  if (groups.some((grp) => grp === 'BaseJumperAdmin')) return false;
  const expected = `WS/${store.state.activeWorkspace}/User`;
  const res = groups.filter((grp) => grp === expected);
  return res.length > 0;
};

export const isWorkspaceAdmin = async () => {
  const user = await Auth.currentAuthenticatedUser();
  const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
  if ((groups === undefined)) return false;
  const expected = `WS/${store.state.activeWorkspace}/Admin`;
  const res = groups.filter((grp) => grp === expected);
  return res.length > 0;
};

export const isOrganizationAdmin = async () => {
  const user = await Auth.currentAuthenticatedUser();
  const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
  if ((groups === undefined)) return false;
  const res = groups.filter((grp) => grp === `ORG/${store.state.activeOrganization}/Admin`);
  return res.length > 0;
};

export function objectIsEmpty(obj) {
  try {
    if (obj === null || obj === undefined) return true;
    if (Object.keys(obj).length === 0) return true;
    return false;
  } catch (error) {
    console.error(error);
    return true;
  }
}

export function arrayIsEmpty(arr) {
  try {
    if (arr === null || arr === undefined) return true;
    if (arr.length === 0) return true;
    return false;
  } catch (error) {
    console.error(error);
    return true;
  }
}

export const getNotificationEmails = async () => {
  if (localStorage.getItem('notificationEmails')) {
    try {
      const localEmails = localStorage.getItem('notificationEmails').split(',');
      if (localEmails !== null && localEmails !== undefined && !arrayIsEmpty(localEmails)) {
        return localEmails;
      }
    } catch (error) {
      console.error(error);
      const emailResponse = await Auth.currentUserInfo();
      return [emailResponse.attributes.email];
    }
  }
  const emailResponse = await Auth.currentUserInfo();
  return [emailResponse.attributes.email];
};

// Used to test performace
export const pt = (fn, ...args) => {
  console.time(`${fn.name}`);
  fn(...args);
  console.timeEnd(`${fn.name}`);
};

export function largeuint8ArrToString(uint8arr, callback) {
  const bb = new Blob([uint8arr]);
  const f = new FileReader();
  // eslint-disable-next-line func-names
  f.onload = function (e) {
    callback(e.target.result);
  };
  f.readAsText(bb);
}

// eslint-disable-next-line no-unused-vars, consistent-return
export async function setCredentials(role) {
  try {
    console.log('Setting credentials for role: ', role);
    const promises = [];
    promises.push(Auth.currentUserPoolUser());
    promises.push(Auth.currentCredentials());
    const [user, authCredentials] = await Promise.all(promises);
    if (user === null || user === undefined || authCredentials === null || authCredentials === undefined) {
      console.error('Error getting user or credentials');
      return null;
    }
    const accountNumber = await store.state.accountNumberPromise;
    if (accountNumber === null) {
      console.error('Error getting account number');
      return null;
    }
    if (role === 'BJAdmin') {
      AWS.config.update({
        accessKeyId: authCredentials.accessKeyId,
        secretAccessKey: authCredentials.secretAccessKey,
        sessionToken: authCredentials.sessionToken,
        region: 'us-east-1',
      });
      return {
        accessKeyId: authCredentials.accessKeyId,
        secretAccessKey: authCredentials.secretAccessKey,
        sessionToken: authCredentials.sessionToken,
      };
    }
    const cognitoArn = `cognito-idp.us-east-1.amazonaws.com/${user.pool.userPoolId}`;
    const roleArn = `arn:aws:iam::${accountNumber}:role/Role_${role.replaceAll('/', '_')}`;
    const identityId = authCredentials.identityId;
    const params = {
      CustomRoleArn: roleArn,
      IdentityId: identityId,
      Logins: {
        [cognitoArn]: user
          .getSignInUserSession()
          .getIdToken()
          .getJwtToken(),
      },
    };
    const cognitoidentity = new AWS.CognitoIdentity({ region: 'us-east-1' });
    const credPromise = new Promise((resolve) => {
      // eslint-disable-next-line consistent-return
      cognitoidentity.getCredentialsForIdentity(params, (err, data) => {
        if (err) {
          console.log(err, err.stack);
          console.log('Null in getCredentialsForIdentity');
          return null;
        }
        try {
          AWS.config.update({
            accessKeyId: data.Credentials.AccessKeyId,
            secretAccessKey: data.Credentials.SecretKey,
            sessionToken: data.Credentials.SessionToken,
            region: 'us-east-1',
          });
          resolve({
            accessKeyId: data.Credentials.AccessKeyId,
            secretAccessKey: data.Credentials.SecretKey,
            sessionToken: data.Credentials.SessionToken,
          });
        } catch (error) {
          console.error('Error in AWS.config.update');
          console.error(error);
          return null;
        }
      });
    });
    const res = await credPromise;
    return res;
  } catch (error) {
    console.error('Error setting credentials');
    console.error(error);
    return null;
  }
}

// Set precedence level
export async function setPrecedence() {
  let credentials = null;
  if (await isAdmin()) {
    store.dispatch('setPrecedenceLevel', 1);
    credentials = await setCredentials('BJAdmin');
  } else if (await isOrganizationAdmin()) {
    store.dispatch('setPrecedenceLevel', 2);
    credentials = await setCredentials(`ORG/${store.state.activeOrganization}/Admin`);
  } else if (await isWorkspaceAdmin()) {
    store.dispatch('setPrecedenceLevel', 3);
    credentials = await setCredentials(`WS/${store.state.activeWorkspace}/Admin`);
  } else if (await isUser()) {
    store.dispatch('setPrecedenceLevel', 4);
    credentials = await setCredentials(`WS/${store.state.activeWorkspace}/User`);
  } else {
    store.dispatch('setPrecedenceLevel', 10);
  }
  return credentials;
}

// Wait time in milliseconds
export function wait(time) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, time));
}

export const sendNotificationEmail = async (content, emails = null) => {
  let localEmails = emails;
  if (emails === null || emails === undefined || arrayIsEmpty(emails)) {
    localEmails = ['victor.weigman@bioskryb.com', 'corey.culler@bioskryb.com', 'roger.rosas@bioskryb.com'];
  }
  localEmails = Array.from(new Set(localEmails));
  localEmails = localEmails.filter((email) => email !== null && email !== undefined && email !== '');
  const currCreds = await setPrecedence();
  let credentials = null;
  if (currCreds === null) {
    console.error('Credentials are null');
    const authCredentials = await Auth.currentCredentials();
    credentials = new AWS.Credentials({
      accessKeyId: authCredentials.accessKeyId, secretAccessKey: authCredentials.secretAccessKey, sessionToken: authCredentials.sessionToken,
    });
  } else {
    credentials = new AWS.Credentials(currCreds);
  }
  const eventbridge = new AWSEventBridge.EventBridge({ region: 'us-east-1', credentials });
  let retry = 0;
  let accountNumber = await store.state.accountNumberPromise;
  while ((accountNumber === null || accountNumber === undefined) && retry <= 5) {
    await wait(1000);
    retry += 1;
    console.log('Retry');
  }
  if (accountNumber === null || accountNumber === undefined) {
    if (store.state.accountNumber !== null && store.state.accountNumber !== undefined) {
      accountNumber = store.state.accountNumber;
    } else {
      console.error('Failed to send notification email. Account number was null');
    }
  }
  const detail = {
    status: 'KickoffPipelines',
    pipeline: 'send_email_with_custom_content',
    step: 'pipeline_trigger',
    application_environment: process.env.VUE_APP_SLS_STAGE,
    user_email: localEmails,
    content,
  };
  const params = {
    Entries: [ /* required */
      {
        Detail: JSON.stringify(detail),
        DetailType: 'KickoffPipelines',
        EventBusName: `arn:aws:events:us-east-1:${accountNumber}:event-bus/basejumper`,
        Resources: [
          `arn:aws:events:us-east-1:${accountNumber}:event-bus/basejumper`,
        ],
        Source: 'basejumper.frontend',
        Time: new Date(),
      },
    ],
  };
  console.log(params);
  eventbridge.putEvents(params, (err, data) => {
    if (err) console.log(err, err.stack); // an error occurred
    else console.log(data); // successful response
  });
};

export function removeDuplicatesById(array) {
  const seen = new Set();
  const filterArr = array.filter((el) => {
    const duplicate = seen.has(el.id);
    seen.add(el.id);
    return !duplicate;
  });
  return filterArr;
}

export function removeDuplicatesByField(array, field) {
  const seen = new Set();
  const filterArr = array.filter((el) => {
    const duplicate = seen.has(el[field]);
    seen.add(el[field]);
    return !duplicate;
  });
  return filterArr;
}

export function parseDateOnly(date) {
  const formated = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  return formated;
}

export function parseDateAndTime(date) {
  const formated = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
  return formated;
}

export function getToday() {
  const date = new Date();
  const formated = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}T${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}.${date.getMilliseconds()}Z`;
  return formated;
}

export function getTodayDateOnlyNormal() {
  const date = new Date();
  const formated = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  return formated;
}

export function formatDateToAWS(date) {
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}T${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}.${date.getMilliseconds()}Z`;
}

export async function listItems(query, passedParams) {
  try {
    const allItems = [];
    const params = passedParams;
    if (!('limit' in params)) {
      params.limit = 1000;
    }
    const responseData = (await API.graphql(graphqlOperation(query, params))).data;
    // console.log('responseData :>> ', responseData);
    // console.log('responseData[Object.keys(response.data)[0]] :>> ', responseData[Object.keys(responseData)[0]]);
    const responseItems = responseData[Object.keys(responseData)[0]];
    allItems.push(...responseItems.items);
    let nextToken = responseItems.nextToken;
    while (nextToken !== null && nextToken !== undefined) {
      const nextParams = params;
      nextParams.nextToken = nextToken;
      const nextResponseData = (await API.graphql(graphqlOperation(query, nextParams))).data;
      const nextResponseItems = nextResponseData[Object.keys(nextResponseData)[0]];
      allItems.push(...nextResponseItems.items);
      nextToken = nextResponseItems.nextToken;
    }
    return allItems;
  } catch (error) {
    console.error('Error in listing items');
    console.error(error);
    return [];
  }
}

export async function mutateGQLObject(mutation, params) {
  try {
    return await API.graphql(graphqlOperation(mutation, { input: params }));
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function groupBy(array, key) {
  return array.reduce((rv, x) => {
    // eslint-disable-next-line no-param-reassign
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export async function groupLaunchablePipelinesForListBox(allLaunchablePipelines) {
  try {
    // eslint-disable-next-line prefer-const
    let groupedLaunchablePipelines = groupBy([...allLaunchablePipelines], 'pipelineName');
    const keys = Object.keys(groupedLaunchablePipelines);
    const mappedGroupedLaunchablePipelines = keys.map((key) => {
      const o = {
        label: key,
        items: groupedLaunchablePipelines[key],
        selectedVersion: null,
      };
      return o;
    });
    mappedGroupedLaunchablePipelines.forEach((group) => {
      group.items.sort((a, b) => {
        if (a.pipelineVersion === 'develop' && b.pipelineVersion === 'develop') return 0;
        if (a.pipelineVersion === 'develop') return 1;
        if (b.pipelineVersion === 'develop') return -1;
        const aSplit = a.pipelineVersion.split('.');
        const bSplit = b.pipelineVersion.split('.');
        for (let i = 0; i <= 3; i += 1) {
          if (parseInt(aSplit[i], 10) < parseInt(bSplit[i], 10)) return 1;
          if (parseInt(aSplit[i], 10) > parseInt(bSplit[i], 10)) return -1;
        }
        return 0;
      });
      // eslint-disable-next-line no-param-reassign
      group.selectedVersion = group.items[0];
    });
    return mappedGroupedLaunchablePipelines;
  } catch (error) {
    console.error('Error in grouping launchable pipelines for ListBox');
    console.error(error);
    return null;
  }
}

export function validateInputName(name) {
  if (!(/^[a-zA-Z0-9_\-\\.]+( [a-zA-Z0-9_\-\\.]+)*$/.test(name)) && name !== '' && name !== null) {
    return false;
  }
  return true;
}

export function validateDigit(digit) {
  if (!(/^[0-9]+( [0-9]+)*$/.test(digit)) && digit !== '' && digit !== null) {
    return false;
  }
  return true;
}
export function validateURL(url) {
  if (!(/^(http(s)?:\/\/.)[-a-zA-Z0-9@:%._\\+~#=]{2,256}.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)$/.test(url)) && url !== '' && url !== null) {
    return false;
  }
  return true;
}
export function validateEmail(email) {
  if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{3,4})+$/.test(email)) && email !== '' && email !== null) {
    return false;
  }
  return true;
}
export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export async function getBioSkrybOrgId() {
  try {
    const bioskrybOrgId = process.env.VUE_APP_BIOSKRYB_ORG_ID;
    console.log('bioskrybOrgId :>> ', bioskrybOrgId);
    if (bioskrybOrgId !== null && bioskrybOrgId !== undefined) return bioskrybOrgId;
    const response = await API.graphql(graphqlOperation(queries.listOrganizations)).then((res) => res.data.listOrganizations.items.find((x) => x.organizationName === 'BioSkryb Genomics'));
    return response.id;
  } catch (err) {
    console.error('Failed to get bioskryb id');
    console.error(err);
    return null;
  }
}

export async function createCreditsData(launchablePipeline, selectedBiosamples = null, projectId = null) {
  try {
    const creditsObject = {
      pipelineName: launchablePipeline.pipelineName,
      tokensPerBiosample: launchablePipeline.tokensPerBiosample,
    };
    let numberOfBiosamples = null;
    if (selectedBiosamples === null || selectedBiosamples === undefined || selectedBiosamples.length === 0) {
      if (projectId !== null && projectId !== undefined) {
        const biosamplesInProject = await listItems(customQueries.biosamplesByProjectSmall, { projectId });
        numberOfBiosamples = biosamplesInProject.length;
      }
    } else {
      numberOfBiosamples = selectedBiosamples.length;
    }
    const amount = launchablePipeline.tokensPerBiosample * numberOfBiosamples;
    creditsObject.amount = amount;
    creditsObject.numberOfBiosamples = numberOfBiosamples;
    console.log('creditsObject :>> ', creditsObject);
    return creditsObject;
  } catch (error) {
    console.error('Error in createCreditsData');
    console.error(error);
    return null;
  }
}

// eslint-disable-next-line no-unused-vars
export function iterateOverTransactions(transactions, bioskrybId) {
  try {
    // eslint-disable-next-line no-unused-vars
    let total = 0;
    let creditsUsed = 0;
    for (const transaction of transactions) {
      // if (transaction.type === 'Pipeline') {
      // if (transaction.type === 'Addition') {
      //   total += transaction.amount;
      // } else {
      //   if (transaction.debitor === bioskrybId) {
      //     total -= transaction.amount;
      //     creditsUsed += transaction.amount;
      //   } if (transaction.creditor === bioskrybId) {
      //     total += transaction.amount;
      //   } if ((transaction.creditor === transaction.debitor) && (transaction.debitor === bioskrybId)) {
      //     total -= transaction.amount;
      //     creditsUsed += transaction.amount;
      //   }
      // }
      // }
      if (transaction.type === 'Storage' || transaction.type === 'Pipeline') {
        total -= transaction.amount;
        creditsUsed += transaction.amount;
      } else if (transaction.type === 'CreditPurchase') {
        total += transaction.amount;
      }
    }
    console.log('total :>> ', total);
    console.log('creditsUsed :>> ', creditsUsed);
    return { total, creditsUsed };
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getCurrentWorkspaceBalance(workspaceId) {
  const [bioskrybId, creditsLog] = await Promise.all([getBioSkrybOrgId(), listItems(customQueries.transactionsByWorkspaceForAllUsers, { workspaceId })]);
  const { total } = iterateOverTransactions(creditsLog, bioskrybId);
  return total;
}

export async function creditCheck(workspaceId, newAmount) {
  try {
    console.log('workspaceId :>> ', workspaceId);
    const total = await getCurrentWorkspaceBalance(workspaceId);
    console.log('total :>> ', total);
    console.log('newAmount :>> ', newAmount);
    let canLaunch = false;
    let diff = null;
    if ((total - newAmount) < 0) {
      console.log('NOT ENOUGH CREDITS');
      diff = Math.abs(total - newAmount);
    } else {
      canLaunch = true;
      console.log('Enough credits, launch');
      console.log('total - newAmount :>> ', total - newAmount);
    }
    return { canLaunch, diff, total };
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getCurrentUsersEmail() {
  const user = await Auth.currentAuthenticatedUser();
  return user.attributes.email;
}

export async function getCurrentUsersName() {
  const user = await Auth.currentAuthenticatedUser();
  return user.attributes.name;
}

export async function getCurrentUserId() {
  const user = await Auth.currentAuthenticatedUser();
  console.log('user :>> ', user);
  return user.username;
}

async function getFilesFromS3Promise(params, s3) {
  return new Promise((resolve) => {
    s3.listObjectsV2(params, (err, data) => {
      if (data) {
        console.log('data :>> ', data);
        let currentSize = 0;
        let contToken = null;
        // eslint-disable-next-line no-loop-func, no-return-assign, no-unused-expressions
        data?.Contents?.forEach((obj) => (currentSize += obj.Size || 0));
        contToken = data.NextContinuationToken;
        resolve({ currentSize, contToken });
      }
      if (err) {
        console.error(err);
      }
    });
  });
}

export function getSizeIndex(type) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  return sizes.indexOf(type);
}

export function getHumanReadableSize(size, decimals = 2) {
  if ((size === 0) || (size === null)) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(size) / Math.log(k));

  return `${parseFloat((size / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export function formatSizeToSpecificType(size, sizeIndex, decimals = 2) {
  if ((size === 0) || (size === null)) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;

  return parseFloat((size / k ** sizeIndex).toFixed(dm));
}

export async function getS3FolderSize(folderPath) {
  const s3 = new AWS.S3();
  let nextToken = null;
  let totalSize = 0;
  try {
    do {
      const params = {
        Bucket: process.env.VUE_APP_BUCKET,
        Prefix: folderPath,
      };
      const { currentSize, contToken } = await getFilesFromS3Promise(params, s3);
      totalSize += currentSize;
      nextToken = contToken;
    } while (nextToken);
    console.log('totalSize :>> ', totalSize);
    return totalSize;
  } catch (err) {
    console.log(`error getting size of ${folderPath}`, err);
    return null;
  }
}

export async function loadUsersFromGroupCall(groupname, nextToken) {
  const apiName = 'AdminQueries';
  const path = '/listUsersInGroup';
  const myInit = {
    queryStringParameters: {
      limit: 60, // Max limit is 60
      token: nextToken,
      groupname,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  const { NextToken, ...groups } = await API.get(apiName, path, myInit);
  return { groups, NextToken };
}

export async function loadUsersFromGroup(groupname) {
  try {
    // eslint-disable-next-line prefer-const
    let allUsers = [];
    let nextToken = null;
    do {
      const { groups, NextToken } = await loadUsersFromGroupCall(groupname);
      allUsers.push(groups);
      nextToken = NextToken;
    } while (nextToken);
    allUsers = allUsers.flatMap((users) => [...users.Users]);
    const sortedUsers = allUsers.sort((a, b) => new Date(b.UserCreateDate) - new Date(a.UserCreateDate));
    return sortedUsers;
  } catch (error) {
    console.error('Loading users failed');
    console.error(error);
    return null;
  }
}

export async function getUserEmailsForGroup(groupName) {
  try {
    const allUserEmailsInGroup = [];
    const allUsers = await loadUsersFromGroup(groupName);
    for (const user of allUsers) {
      allUserEmailsInGroup.push(user.Attributes.find((attribute) => attribute.Name === 'email').Value);
    }
    return allUserEmailsInGroup;
  } catch (error) {
    console.error(error);
    return null;
  }
}

// function toUTF8Array(str) {
//   const utf8 = [];
//   for (let i = 0; i < str.length; i += 1) {
//     let charcode = str.charCodeAt(i);
//     if (charcode < 0x80) utf8.push(charcode);
//     else if (charcode < 0x800) {
//       utf8.push(
//         0xc0 | (charcode >> 6),
//         0x80 | (charcode & 0x3f),
//       );
//     } else if (charcode < 0xd800 || charcode >= 0xe000) {
//       utf8.push(
//         0xe0 | (charcode >> 12),
//         0x80 | ((charcode >> 6) & 0x3f),
//         0x80 | (charcode & 0x3f),
//       );
//     // eslint-disable-next-line brace-style
//     }
//     // surrogate pair
//     else {
//       i += 1;
//       // UTF-16 encodes 0x10000-0x10FFFF by
//       // subtracting 0x10000 and splitting the
//       // 20 bits of 0x0-0xFFFFF into two halves
//       charcode = 0x10000 + (((charcode & 0x3ff) << 10)
//                     // eslint-disable-next-line no-bitwise
//                     | (str.charCodeAt(i) & 0x3ff));
//       utf8.push(
//         0xf0 | (charcode >> 18),
//         0x80 | ((charcode >> 12) & 0x3f),
//         0x80 | ((charcode >> 6) & 0x3f),
//         0x80 | (charcode & 0x3f),
//       );
//     }
//   }
//   return utf8;
// }

// export function getSize(passedEntry) {
//   console.log('passedEntry :>> ', passedEntry);
//   const entry = passedEntry.Entries[0];
//   let size = 0;
//   console.log('entry :>> ', entry);
//   if (entry.Time != null) {
//     size += 14;
//   }
//   size += toUTF8Array(entry.Source).length;
//   size += toUTF8Array(entry.DetailType).length;
//   if (entry.Detail != null) {
//     size += toUTF8Array(entry.Detail).length;
//   }
//   if (entry.Resources != null) {
//     for (const resource of entry.Resources) {
//       if (resource != null) {
//         size += toUTF8Array(resource).length;
//       }
//     }
//   }
//   return size;
// }

export function getSize(size, decimals = 2) {
  if ((size === 0) || (size === null)) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(Number(size)) / Math.log(k));

  return `${parseFloat((Number(size) / k ** i).toFixed(dm))} ${sizes[i]}`;
}

// eslint-disable-next-line no-unused-vars
export async function sendEBMessage(detail, orgId, wsId, projectId, type) {
  try {
    const currCreds = await setPrecedence();
    if (currCreds === null) {
      console.error('Credentials are null');
    }
    [detail.user_uuid, detail.user_email, detail.user_name] = await Promise.all([getCurrentUserId(), getNotificationEmails(), getCurrentUsersEmail()]);
    detail.paymentSystemDisabled = store.state.paymentSystemDisabled;
    const commitId = process.env.VUE_APP_AWS_COMMIT_ID;
    if (commitId !== undefined && commitId !== null) {
      detail.commit_id = commitId.substring(0, 6);
    }
    const environment = process.env.VUE_APP_SLS_STAGE;
    if (environment !== undefined && environment !== null) {
      detail.application_environment = environment;
    }
    const credentials = new AWS.Credentials(currCreds);
    // eslint-disable-next-line no-unused-vars
    const eventbridge = new AWSEventBridge.EventBridge({ region: 'us-east-1', credentials });
    console.log('detail :>> ', detail);
    let ebEventDetail = null;
    if (orgId !== null && orgId !== undefined) {
      try {
        const timeStamp = getToday();
        const s3EventPath = `${orgId}/${wsId}/${projectId}/eb-events/${type}/event_${timeStamp}.json`;
        const s3 = new AWS.S3({ region: 'us-east-1', credentials });
        const s3PutObjectParams = {
          Bucket: process.env.VUE_APP_BUCKET,
          Key: s3EventPath,
          Body: JSON.stringify({ detail }),
          ContentType: 'text/json',
        };
        const putObjectRes = await s3.putObject(s3PutObjectParams).promise();
        if (putObjectRes.error !== null && putObjectRes.error !== undefined) {
          console.error(putObjectRes.error);
        }
        ebEventDetail = {
          eb_event_s3_path: `s3://${process.env.VUE_APP_BUCKET}/${s3EventPath}`,
        };
      } catch (error) {
        console.error(error);
      }
    }
    const params = {
      Entries: [ /* required */
        {
          // Detail: JSON.stringify(detail),
          Detail: JSON.stringify(ebEventDetail),
          DetailType: 'KickoffPipelines',
          EventBusName: `arn:aws:events:us-east-1:${await store.state.accountNumberPromise}:event-bus/basejumper`,
          Resources: [
            `arn:aws:events:us-east-1:${await store.state.accountNumberPromise}:event-bus/basejumper`,
          ],
          Source: 'basejumper.frontend',
          Time: new Date(),
        },
      ],
    };
    console.log('Params: >>', params);
    API.graphql(graphqlOperation(queries.queryParseEbEventFileAndSentItAsEmail, { s3PathEbEvent: ebEventDetail.eb_event_s3_path }));
    eventbridge.putEvents(params, async (err, data) => {
      if (err) console.log(err, err.stack); // an error occurred
      else {
        console.log('data :>> ', data);
      } // successful response
    });
  } catch (error) {
    console.error(error);
  }
}

export async function renameSampleMetadata(orgId, wsId, projectId, biosampleIds, columnsMapping) {
  try {
    const currCreds = await setPrecedence();
    if (currCreds === null) {
      console.error('Credentials are null');
      return null;
    }
    const credentials = new AWS.Credentials(currCreds);
    const timeStamp = getToday();
    const s3EventPath = `${orgId}/${wsId}/${projectId}/eb-events/renameSampleMetadata/event_${timeStamp}.json`;
    const s3 = new AWS.S3({ region: 'us-east-1', credentials });
    const s3PutObjectParams = {
      Bucket: process.env.VUE_APP_BUCKET,
      Key: s3EventPath,
      Body: JSON.stringify({
        projectId,
        biosampleIds,
        columnsMapping,
      }),
      ContentType: 'text/json',
    };
    const putObjectRes = await s3.putObject(s3PutObjectParams).promise();
    if (putObjectRes.error !== null && putObjectRes.error !== undefined) {
      console.error(putObjectRes.error);
    }
    const lambdaParams = {
      eventS3Path: `s3://${process.env.VUE_APP_BUCKET}/${s3EventPath}`,
    };
    console.log('lambdaParams :>> ', lambdaParams);
    API.graphql(graphqlOperation(queries.renameSamplesMetadata, lambdaParams));
    return lambdaParams.eventS3Path;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function sendEBMessageRecalculateFolderSize(detail) {
  try {
    const currCreds = await setPrecedence();
    if (currCreds === null) {
      console.error('Credentials are null');
    }
    const credentials = new AWS.Credentials(currCreds);
    const eventbridge = new AWSEventBridge.EventBridge({ region: 'us-east-1', credentials });
    console.log('detail :>> ', detail);
    const params = {
      Entries: [ /* required */
        {
          Detail: JSON.stringify(detail),
          DetailType: 'Recalculate-Folder-Sizes',
          EventBusName: 'basejumper',
          Source: 'basejumper',
          Time: new Date(),
        },
      ],
    };
    console.log('Params: >>', params);
    eventbridge.putEvents(params, async (err, data) => {
      if (err) console.log(err, err.stack); // an error occurred
      else {
        console.log('data :>> ', data);
      } // successful response
    });
  } catch (error) {
    console.error(error);
  }
}

// Return for which groups we should get the users for
export async function getGroups(user) {
  try {
    let groups = null;
    if (user !== null && user !== undefined) {
      groups = user.signInUserSession.accessToken.payload['cognito:groups'];
    } else {
      groups = (await Auth.currentAuthenticatedUser()).signInUserSession.accessToken.payload['cognito:groups'];
    }
    const usersGroups = [];
    if (groups.some((grp) => grp === 'BaseJumperAdmin')) {
      usersGroups.push('BaseJumperAdmin');
      return usersGroups;
    }
    const orgGroups = groups.filter((grp) => grp.startsWith('ORG/'));
    const workspaceGroups = groups.filter((ws) => (ws.startsWith('WS/') && (ws.endsWith('/Admin'))));
    if (this.$store.state.precedenceLevel === 2) {
      return orgGroups.concat(workspaceGroups);
    }
    if (this.$store.state.precedenceLevel === 3) {
      return workspaceGroups;
    }
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
}

// Appsync and primevue have different filter names so they need to be mapped.
export function mapMatchModesForFilter(matchMode) {
  console.log('matchMode :>> ', matchMode);
  switch (matchMode) {
    case 'contains':
      return 'contains';
    case 'startsWith':
      return 'beginsWith';
    case 'notContains':
      return 'notContains';
    case 'endsWith': // Problem. Appsync doesn't have 'endsWith'
      return 'contains';
    case 'equals':
      // return 'eq'; // Only contain works
      return 'contains';
    case 'notEquals':
      return 'notContains';
    case 'lt':
      return 'lt';
    default:
      return 'contains';
  }
}

// Format primevue filter to AppSync filter
export function reformatFilter(filters, initialFilter = {}) {
  const filterObj = initialFilter;
  for (const [key, value] of Object.entries(filters)) {
    if (value.value !== null && value.value !== undefined && value.value !== '') {
      const matchMode = mapMatchModesForFilter(value.matchMode);
      filterObj[key] = { [matchMode]: value.value };
    }
  }
  return filterObj;
}

export function reformatFilterForValueInConstraints(filters, columns, initialFilter = {}) {
  return {};
  // eslint-disable-next-line no-unreachable
  const filterObj = initialFilter;
  console.log('filters in reformatFilterForValueInConstraints:>> ', filters);
  for (const [key, filter] of Object.entries(filters)) {
    if (filter.constraints[0].value !== null && filter.constraints[0].value !== undefined && filter.constraints[0].value !== '') {
      const column = columns.find((col) => col.inferredName === key);
      console.log('column :>> ', column);
      console.log('filter :>> ', filter);
      console.log('filterObj :>> ', filterObj);
      if (column.editable) {
        let stringBuild = column.formatValueForFilterQuery(filter.constraints[0].value);
        const matchMode = mapMatchModesForFilter(filter.constraints[0].matchMode);
        if (matchMode === 'notContains') stringBuild += '"';
        if ('metadata' in filterObj) {
          // filterObj.and = { metadata: { contains: stringBuild } };
          filterObj.and = {
            metadata: {
              [`${mapMatchModesForFilter(filter.constraints[0]
                .matchMode)}`]: stringBuild,
            },
          };
        } else if ('and' in filterObj) {
          let iterObj = filterObj;
          while ('and' in iterObj) iterObj = iterObj.and;
          console.log('iterObj :>> ', iterObj);
          // iterObj.and = { metadata: { contains: stringBuild } };
          iterObj.and = {
            metadata: {
              [`${mapMatchModesForFilter(filter.constraints[0]
                .matchMode)}`]: stringBuild,
            },
          };
        } else {
          filterObj.metadata = {
            [`${mapMatchModesForFilter(filter.constraints[0]
              .matchMode)}`]: stringBuild,
          };
        }
      } else {
        const matchMode = mapMatchModesForFilter(filter.constraints[0]
          .matchMode);
        console.log('matchMode :>> ', matchMode);
        filterObj[key] = { [matchMode]: filter.constraints[0].value };
      }
    }
  }
  return filterObj;
}

export function valueIsNullOrUndefined(value) {
  return value === null || value === undefined;
}

export function sortObj(list, key, customCompare) {
  function compare(a, b) {
    a = a[key];
    b = b[key];
    if (a === null || a === undefined) return 1;
    if (b === null || b === undefined) return -1;
    const type = (typeof (a) === 'string'
                  || typeof (b) === 'string') ? 'string' : 'number';
    let result;
    if (type === 'string') result = a.localeCompare(b, 'en');
    else result = a - b;
    return result;
  }
  if (!valueIsNullOrUndefined(customCompare)) {
    biosampleMetadataColumnsFactory.setSortKey(key);
    return list.sort(customCompare);
  }
  return list.sort(compare);
}

export function remapBiosamplesAndMakeFinalObj(biosamplesObj) {
  const biosamplesIdsArr = biosamplesObj.map((o) => ({
    id: o.id,
    name: o.biosampleName,
  }));
  const finalBiosamplesObj = biosamplesIdsArr;
  return finalBiosamplesObj;
}

export function validateString(string) {
  if (!(/^[a-zA-Z0-9_-]+( [a-zA-Z0-9_-]+)*$/.test(string)) && string !== '') {
    return false;
  }
  return true;
}

export function validateLotId(lotId) {
  if (typeof (lotId) === 'object') lotId = lotId.label;
  if (!(/[xM]?[0-2][0-9](\d{4}|\d{5})$/.test(lotId)) && lotId !== '') {
    return false;
  }
  return true;
}

function isObject(object) {
  return object != null && typeof object === 'object';
}

export function isEqual(obj1, obj2) {
  const props1 = Object.getOwnPropertyNames(obj1);
  const props2 = Object.getOwnPropertyNames(obj2);
  if (props1.length !== props2.length) {
    return false;
  }
  for (let i = 0; i < props1.length; i += 1) {
    const val1 = obj1[props1[i]];
    const val2 = obj2[props1[i]];
    const isObjects = isObject(val1) && isObject(val2);
    // eslint-disable-next-line no-mixed-operators
    if (isObjects && !isEqual(val1, val2) || !isObjects && val1 !== val2) {
      return false;
    }
  }
  return true;
}

export function toCamelCase(string) {
  try {
    const ans = string.toLowerCase();
    return ans.split(' ').reduce((s, c) => s
   + (c.charAt(0).toUpperCase() + c.slice(1)));
  } catch (error) {
    console.error(error);
    return string;
  }
}

export function inferField(col) {
  return toCamelCase(col.name);
}

export function checkIfFiltersAreEmpty(filtersObj) {
  for (const value of Object.values(filtersObj)) {
    if (value.value !== '') return false;
  }
  return true;
}

export function calculateEditDistance(s, t) {
  const n = s.length;
  const m = t.length;

  const prev = new Array(m + 1).fill(0);
  const curr = new Array(m + 1).fill(0);

  for (let j = 0; j <= m; j++) {
    prev[j] = j;
  }

  for (let i = 1; i <= n; i++) {
    curr[0] = i;
    for (let j = 1; j <= m; j++) {
      if (s[i - 1] === t[j - 1]) {
        curr[j] = prev[j - 1];
      } else {
        const mn = Math.min(1 + prev[j], 1 + curr[j - 1]);
        curr[j] = Math.min(mn, 1 + prev[j - 1]);
      }
    }
    prev.splice(0, m + 1, ...curr);
  }

  return prev[m];
}

export function makeColumnsFromFactory(columns) {
  const columnsFromFactory = [];
  columns.forEach((col) => {
    columnsFromFactory.push(biosampleMetadataColumnsFactory.createColumn(col.type, { name: col.name, description: col.description, editable: col.editable }));
  });
  return columnsFromFactory;
}

export function makeBiosampleMetadataColumnsJson(biosampleMetadataColumns) {
  const filteredColumns = biosampleMetadataColumns.filter((columnOBject) => columnOBject.name !== '' && columnOBject.type !== '');
  const jsonObject = { columns: filteredColumns };
  return JSON.stringify(jsonObject);
}

export function duplicatesByKeyInArray(arr, key) {
  const uniqueValues = new Set(arr.map((a) => a[key]));
  return (uniqueValues.size < arr.length);
}

export function remapEnvNamesForNFTowerURL(envName) {
  switch (envName) {
    case 'develop':
      return 'Development';
    case 'staging':
      return 'Staging';
    case 'master':
      return 'Production';
    default:
      return '';
  }
}

// return '2023-09-3T22:00:00.000Z';  Doesent work
// return '2023-09-03T22:00:00.000Z'; Works
export function addZeroToDayInISODateStandardString(dateString) {
  const matchRegex = /(-\dT)/g;
  const match = dateString.match(matchRegex);
  if (!valueIsNullOrUndefined(match)) {
    const addedZero = match[0].replace('-', '-0');
    dateString = dateString.replace(matchRegex, addedZero);
  }
  return dateString;
}

export function isValueNA(value) {
  if (valueIsNullOrUndefined(value)) return true;
  if (value instanceof Date) return false;
  const lower = value.toLowerCase();
  return lower === 'na' || lower === 'n/a' || lower === 'nan';
}

export function getEmptyMetadataObj() {
  return {
    name: '',
    type: '',
    description: '',
    editable: true,
  };
}

export function textColumnTypeChecks(matchMode, data, valueFromFilter) {
  if (matchMode === 'contains') {
    if (!data.includes(valueFromFilter)) return false;
  } else if (matchMode === 'startsWith') {
    if (!data.startsWith(valueFromFilter)) return false;
  } else if (matchMode === 'notContains') {
    if (data.includes(valueFromFilter)) return false;
  } else if (matchMode === 'endsWith') {
    if (!data.endsWith(valueFromFilter)) return false;
  } else if (matchMode === 'equals') {
    if (data !== valueFromFilter) return false;
  } else if (matchMode === 'notEquals') {
    if (data === valueFromFilter) return false;
  }
  return true;
}

export function numericColumnTypeChecks(matchMode, data, valueFromFilter) {
  if (matchMode === 'equals') {
    if (data !== valueFromFilter) return false;
  } else if (matchMode === 'notEquals') {
    if (data === valueFromFilter) return false;
  } else if (matchMode === 'lt') {
    if (data >= valueFromFilter) return false;
  } else if (matchMode === 'gt') {
    if (data <= valueFromFilter) return false;
  } else if (matchMode === 'lte') {
    if (data > valueFromFilter) return false;
  } else if (matchMode === 'gte') {
    if (data < valueFromFilter) return false;
  }
  return true;
}

export function booleanColumnTypeChecks(matchMode, data, valueFromFilter) {
  if (matchMode === 'equals') {
    if (data !== valueFromFilter) return false;
  }
  return true;
}
export function dateColumnTypeChecks(matchMode, data, valueFromFilter) {
  data = new Date(new Date(data).setHours(0, 0, 0, 0)).getTime();
  valueFromFilter = new Date(valueFromFilter).getTime();
  if (matchMode === 'dateIs') {
    if (data !== valueFromFilter) return false;
  } else if (matchMode === 'dateIsNot') {
    if (data === valueFromFilter) return false;
  } else if (matchMode === 'dateBefore') {
    if (data > valueFromFilter) return false;
  } else if (matchMode === 'dateAfter') {
    if (data < valueFromFilter) return false;
  }
  return true;
}

export function isNumber(char) {
  return /^\d$/.test(char);
}

export function isDateValid(dateStr) {
  return !isNaN(new Date(dateStr));
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function filterPrivateLaunchablePipelinesForWorkspaceAndOrganizationGroup(privateLaunchablePipelines, workspaceId, organizationId) {
  return privateLaunchablePipelines.filter((plp) => plp.readGroups.includes(`WS/${workspaceId}/Admin`) || plp.readGroups.includes(`ORG/${organizationId}/Admin`) || store.state.precedenceLevel === 1);
}

// Check if the current pipeline name is/isn't equal to a specified name;
async function validatePipelineName(check, pipeline) {
  try {
    if (check.equality === 'eq') {
      return pipeline.pipelineName === check.expectedPipelineName;
    }
    if (check.equality === 'ne') {
      return pipeline.pipelineName !== check.expectedPipelineName;
    }
    if (check.equality === 'any') {
      return check.expectedPipelineName.some((name) => name === pipeline.pipelineName);
    }
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function validateParameter(check, pipeline) {
  try {
    if (pipeline.parameters === null || pipeline.parameters === undefined) return null;
    const pipelineParams = JSON.parse(pipeline.parameters);
    if (check.parameterName in pipelineParams) {
      return pipelineParams[check.parameterName] === check.expectedValue;
    }
    return check.onParamMissing; // What to do in case the parameter is missing from the pipeline params
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function validateFileExisting(check, gettingFilesDone, s3Contents) {
  try {
    await gettingFilesDone;
    return s3Contents.some((obj) => obj.Key.endsWith(check.fileSuffix));
  } catch (error) {
    console.error(error);
    return null;
  }
}

// Validate single visualization. Various checks
export async function validateVisualization(viz, pipeline, allLaunchableVisualizations, gettingFilesDone, s3Contents, runFileExistsCheck = true) {
  try {
    const validations = [];
    let validationSchema = JSON.parse(viz.validationSchema);
    if (validationSchema !== null && validationSchema !== undefined) {
      validationSchema = validationSchema.checks;
      validationSchema.forEach((check) => {
        // Add different checks here
        switch (check.checkType) {
          case 'PipelineNameCheck':
            validations.push(validatePipelineName(check, pipeline));
            break;
          case 'ParameterCheck':
            validations.push(validateParameter(check, pipeline));
            break;
          case 'FileExistsCheck':
            if (runFileExistsCheck) validations.push(validateFileExisting(check, gettingFilesDone, s3Contents));
            break;
          default:
        }
      });
      // Check if all validations passed
      const results = await Promise.all(validations);
      const pass = results.every(Boolean); // Check if all booleans in the array are true
      if (pass) {
        allLaunchableVisualizations.push(viz);
        return true;
      }
      return false;
    }
    allLaunchableVisualizations.push(viz);
    return true;
  } catch (error) {
    console.error('Error validating visualization: ', viz);
    console.error(error);
    return false;
  }
}

export async function validateAllVisualizations(visualizations, pipeline, gettingFilesDone, s3Contents) {
  const allLaunchableVisualizations = [];
  const promises = [];
  visualizations.forEach((viz) => {
    promises.push(validateVisualization(viz, pipeline, allLaunchableVisualizations, gettingFilesDone, s3Contents));
  });
  await Promise.all(promises);
  return allLaunchableVisualizations;
}

export async function checkIfVisualizationShouldBeDisabled(visualization, pipelines) {
  const validationResults = await Promise.all(pipelines.map((pipeline) => validateVisualization(visualization, pipeline, [], null, null, false)));
  if (validationResults.some((res) => res)) return false;
  return true;
}

export async function filterValidPipelinesForViz(pipelines, visualization) {
  const filteredPipelines = [];
  for (let i = 0; i < pipelines.length; i += 1) {
    const res = await validateVisualization(visualization, pipelines[i], [], null, null, false);
    if (res) filteredPipelines.push(pipelines[i]);
  }
  return filteredPipelines;
}

function switchTheme(theme) {
  const themeElement = document.getElementById('theme-link');
  if (themeElement) {
    themeElement.setAttribute('href', theme);
  }
  console.log('themeElement :>> ', themeElement);
}

export function switchToDarkTheme() {
  const newTheme = '/themes/lara-dark-green/theme.css';
  switchTheme(newTheme);
}

export function switchToLightTheme() {
  const newTheme = '/themes/lara-light-indigo/theme.css';
  switchTheme(newTheme);
}

export function getSpinnerSrc() {
  // eslint-disable-next-line global-require
  if (!localStorage.getItem('theme') || localStorage.getItem('theme') === 'dark') return require('@/assets/BioSkrybElements/BJSpinnerWhite.png');
  // eslint-disable-next-line global-require
  if (localStorage.getItem('theme') === 'light') return require('@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png');
  // eslint-disable-next-line global-require
  return require('@/assets/BioSkrybElements/BJSpinnerWhite.png');
}
