<template>
  <Dialog v-model:visible="showing" :style="{ width: '700px' }" header="Missing metadata" :modal="true" class="p-fluid" :closable="false">
    <div v-if="loading">
      <div class="flex justify-content-center">
        <img alt="BJSpinner" class="bjSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png" />
      </div>
      <div class="flex justify-content-center mt-4">
        <span>Renaming metadata. Please stand by...</span>
      </div>
    </div>
    <div v-if="!loading">
      <div>
        <span>The <b>{{pipelineName}}</b> pipeline requires specific metadata to run. Do you want to add the metadata now? The required metadata columns will be added automatically you just have to fill in the data</span>
      </div>
      <div v-if="projectMetadataColumns.length > 0">
        <div class="wrapper-div pt-3" v-for="(col, index) in missingRequiredColumns" :key="index">
          <div class="flex pt-4 wrapper-div">
            <span class="p-float-label mr-3 elem-div">
              <InputText id="unk-col-name" class="name-field mr-2" v-model.trim="col.name" disabled />
              <label for="unk-col-name">Required Column Name</label>
            </span>
            <span v-if="!col.addAsNewColumn" class="p-float-label elem-div">
              <Dropdown id="actual-col-name" class="type-dd mr-2" showClear v-model="col.usersColName" :options="projectMetadataColumns" optionLabel="name" />
              <label for="actual-col-name" class="mb-3">Current Column Name</label>
            </span>
            <Button v-if="!col.addAsNewColumn" class="add-col-btn pl-2" label="Add as new column" @click="col.addAsNewColumn = true" />
            <Button v-if="col.addAsNewColumn" class="add-col-btn ml-2" label="Map to an existing column" @click="col.addAsNewColumn = false" />
          </div>
        </div>
      </div>

    </div>

    <template #footer>
      <div v-if="!loading">
        <Button label="No" icon="pi pi-times" class="p-button-secondary" @click="hideDialog" />
        <Button label="Yes" class="p-button-outline" @click="setMissingRequiredColumns" />
      </div>
    </template>
  </Dialog>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { API, graphqlOperation } from 'aws-amplify';
// eslint-disable-next-line no-unused-vars
import * as mutations from '@/graphql/mutations';
// eslint-disable-next-line no-unused-vars
import {
  // eslint-disable-next-line no-unused-vars
  calculateEditDistance, valueIsNullOrUndefined, duplicatesByKeyInArray, renameSampleMetadata,
} from '@/utils';
import * as customSubscriptions from '@/graphql/customSubscriptions';

export default {
  props: ['missingRequiredColumns', 'pipelineName', 'project'],
  emits: ['resetMissingRequiredColumns', 'hideLaunchPipelineDialog', 'triggerBiosampleLoadingAgain'],
  data() {
    return {
      showing: false,
      loading: false,
      selectedWorkspace: null,
      projectMetadataColumns: [],
      renamingDoneSubscription: null,
      columnsToAdd: [], // Same as columnsToAdd, populated only when running renaming as a reference
    };
  },
  mounted() {
    console.log('REqMETADATA MOUNTED');
    this.showing = this.$store.state.showingMapRequiredMetadataColumns;
    console.log('this.missingRequiredColumns in mounted :>> ', this.missingRequiredColumns);
    if (!valueIsNullOrUndefined(this.missingRequiredColumns) && this.missingRequiredColumns.length > 0) this.main();
  },
  methods: {
    main() {
      try {
        console.log('this.missingRequiredColumns :>> ', this.missingRequiredColumns);
        console.log('this.pipelineName :>> ', this.pipelineName);
        console.log('this.project in main :>> ', this.project);
        if (valueIsNullOrUndefined(this.project) || valueIsNullOrUndefined(this.project.biosampleMetadataColumns)) return;
        const projectMetadata = JSON.parse(this.project.biosampleMetadataColumns);
        let projectMetadataColumns = [];
        if (valueIsNullOrUndefined(projectMetadata) || valueIsNullOrUndefined(projectMetadata.columns) || projectMetadata.columns.length === 0) {
          projectMetadataColumns = [];
        } else {
          projectMetadataColumns = projectMetadata.columns;
        }
        console.log('projectMetadataColumns :>> ', projectMetadataColumns);
        this.projectMetadataColumns = projectMetadataColumns;
        this.addPossibleNames(this.missingRequiredColumns, projectMetadataColumns);
      } catch (error) {
        console.error(error);
      }
    },
    addPossibleNames(missingRequiredColumns, projectColumns) {
      // const missingRequiredColumnsWithPossibleNames = missingRequiredColumns.map((col) => );
      missingRequiredColumns.forEach((missingCol) => {
        let smallestDist = 1000;
        let closestChoice = null;
        projectColumns.forEach((projectCol) => {
          const editDistance = calculateEditDistance(projectCol.name.toLowerCase(), missingCol.name.toLowerCase());
          console.log('editDistance :>> ', editDistance);
          if (editDistance < smallestDist && editDistance < 3) {
            smallestDist = editDistance;
            closestChoice = projectCol;
          }
        });
        // eslint-disable-next-line no-param-reassign
        missingCol.usersColName = closestChoice;
        // eslint-disable-next-line no-param-reassign
        missingCol.addAsNewColumn = true; // Added here, didn't want to make a new function for it
      });
      console.log('missingRequiredColumns with possible names :>> ', missingRequiredColumns);
    },
    setMissingRequiredColumns() {
      console.log('this.missingRequiredColumns :>> ', this.missingRequiredColumns);
      const columnsToRename = this.missingRequiredColumns.filter((col) => !col.addAsNewColumn && !valueIsNullOrUndefined(col.usersColName));
      const columnsToAdd = this.missingRequiredColumns.filter((col) => col.addAsNewColumn || (!col.addAsNewColumn && valueIsNullOrUndefined(col.usersColName)));
      console.log('columnsToRename :>> ', columnsToRename);
      console.log('columnsToAdd :>> ', columnsToAdd);
      if (duplicatesByKeyInArray(columnsToRename, 'usersColName')) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'You cannot use the same column multiple times!',
          life: 5000,
        });
        return;
      }
      if (columnsToRename.length > 0) {
        this.columnsToAdd = columnsToAdd;
        this.startRenamingProcess(columnsToRename, this.project);
      } else {
        this.startColumnAddingProcess(columnsToAdd);
      }
    },
    startColumnAddingProcess(columnsToAdd) {
      this.$store.dispatch('setMissingRequiredColumns', columnsToAdd);
      if (this.$route.fullPath.endsWith('/biosamples')) {
        this.$emit('triggerBiosampleLoadingAgain');
        this.hideDialog();
      } else {
        this.$router.push({ path: `/workspace/${this.project.workspace.id}/project/${this.project.id}/biosamples` });
      }
    },
    async startRenamingProcess(columnsToRename, project) {
      this.loading = true;
      // eslint-disable-next-line no-unused-vars
      const biosampleIds = project.biosamples.items.map((bs) => bs.id);
      // eslint-disable-next-line no-unused-vars
      const columnsMapping = columnsToRename.map((col) => ({ oldColumnName: col.usersColName.name.toLowerCase(), newColumnName: col.name.toLowerCase() }));
      const s3Path = await renameSampleMetadata(project.workspace.organization.id, project.workspace.id, project.id, biosampleIds, columnsMapping);
      console.log('s3Path :>> ', s3Path);
      this.startRenamingSubscription(project.id);
    },
    startRenamingSubscription(projectId) {
      console.log('Starting renaming subscription');
      this.renamingDoneSubscription = API.graphql(
        graphqlOperation(customSubscriptions.onUpdateProject),
      ).subscribe({
        next: ({ value }) => {
          console.log('{ value } :>> ', { value });
          const project = value.data.onUpdateProject;
          if (project.id === projectId && project.status === 'Job Complete') {
            this.renamingDone();
          }
        },
        error: (error) => console.warn(error),
      });
    },
    unsubscribeFromProjectUpdate() {
      try {
        this.renamingDoneSubscription.unsubscribe();
      } catch (error) {
        console.log('Project unsubscribe failed');
        console.log(error);
      }
    },
    renamingDone() {
      this.loading = false;
      this.unsubscribeFromProjectUpdate();
      this.startColumnAddingProcess(this.columnsToAdd);
    },
    hideDialog() {
      this.selectedWorkspace = null;
      this.showing = false;
      this.columnsToAdd = [];
      this.projectMetadataColumns = [];
      this.$emit('resetMissingRequiredColumns');
      this.$store.dispatch('setShowingMapRequiredMetadataColumns', false);
      this.$emit('hideLaunchPipelineDialog');
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingMapRequiredMetadataColumns': async function () {
      console.log('this.$store.state.showingMapRequiredMetadataColumns in watcher :>> ', this.$store.state.showingMapRequiredMetadataColumns);
      this.showing = this.$store.state.showingMapRequiredMetadataColumns;
    },
    missingRequiredColumns() {
      console.log('this.missingRequiredColumns in required watcher:>> ', this.missingRequiredColumns);
      if (!valueIsNullOrUndefined(this.missingRequiredColumns)) this.main();
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper-div {
  width: 100%;
}

.w {
  display: inline-block;
}

.elem-div {
  width: 30%;
}

.add-col-btn {
  width: 33%;
}
</style>
