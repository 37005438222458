/* eslint-disable */

export const listVisualizations = `
query ListVisualizations(
  $id: ID
  $filter: ModelVisualizationsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listVisualizations(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      visualizationName
      description
      version
      deploymentPath
      iconPath
      validationSchema
      allowedExtensions
      created
      updated
    }
    nextToken
  }
}
`;

export const getWorkspaceProjectToken = /* GraphQL */ `
  query GetWorkspace(
    $id: ID!
    $nextProjectToken: String) {
    getWorkspace(id: $id) {
      id
      organizationId
      organization {
        id
        bioskrybClientId
        organizationName
        active
        storageCreditRate
        pipelineCreditRate
        zohoId
        organizationDataKay
        workspaces {
          items {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            bsshBiosampleCounter
            projects {
              nextToken
            }
            bsshBiosamples {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            amount
            type
            notes
            groups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              projectOutputS3Path
              size
              assayName
              status
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              bsshBiosampleCounter
              readGroups
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        readGroups
        sharedS3Paths
        writeGroups
        created
        updated
      }
      description
      basespaceAccessToken
      bsshBiosampleCounter
      projects(nextToken: $nextProjectToken) {
        items {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            bsshBiosampleCounter
            projects {
              nextToken
            }
            bsshBiosamples {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              bsshBiosampleCounter
              readGroups
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              projectOutputS3Path
              size
              assayName
              status
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            projectOutputS3Path
            size
            assayName
            status
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          projectOutputS3Path
          size
          assayName
          status
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              r1FastqName
              r2FastqName
              size
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              pipelineName
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              amount
              type
              notes
              groups
              projectId
              workspaceId
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        nextToken
      }
      bsshBiosamples {
        items {
          id
          workspaceID
          bSSHBiosampleID
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            bsshBiosampleCounter
            projects {
              nextToken
            }
            bsshBiosamples {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          bSSHBiosample {
            id
            workspaceId
            workspaces {
              nextToken
            }
            bioSampleName
            defaultProject
            bsshDateModified
            bsshDateCreated
            readGroups
            writeGroups
            adminGroups
            created
            updated
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      creditTransactions {
        items {
          id
          debitor
          debitorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          creditor
          creditorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          amount
          type
          notes
          groups
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              bsshBiosampleCounter
              readGroups
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              projectOutputS3Path
              size
              assayName
              status
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            projectOutputS3Path
            size
            assayName
            status
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            bsshBiosampleCounter
            projects {
              nextToken
            }
            bsshBiosamples {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          created
          updated
          organizationCreditTransactionsId
          workspaceCreditTransactionsId
          projectCreditTransactionsId
        }
        nextToken
      }
      readGroups
      writeGroups
      adminGroups
      created
      updated
      organizationWorkspacesId
    }
  }
`;
export const getWorkspaceBSSHBiosamplesToken = /* GraphQL */ `
  query GetWorkspace(
    $id: ID!
    $nextBSShBatchToken: String
    $limit: Int) {
    getWorkspace(id: $id) {
      id
      organizationId
      description
      basespaceAccessToken
      bsshBiosampleCounter
      bsshBiosamples(
        nextToken: $nextBSShBatchToken
        limit: $limit) {
        items {
          id
          workspaceID
          bSSHBiosampleID
          bSSHBiosample {
            id
            workspaceId
            workspaces {
              nextToken
            }
            bioSampleName
            defaultProject
            bsshDateModified
            bsshDateCreated
            readGroups
            writeGroups
            adminGroups
            created
            updated
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      readGroups
      writeGroups
      adminGroups
      created
      updated
      organizationWorkspacesId
    }
  }
`;

export const listWorkspaceBiosamplesJustIds = /* GraphQL */ `
  query ListWorkspaceBiosamples(
    $filter: ModelWorkspaceBiosamplesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkspaceBiosamples(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        workspaceID
        bSSHBiosampleID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listWorkspacesWithoutBSSHCache = /* GraphQL */ `
  query ListWorkspaces(
    $id: ID
    $filter: ModelWorkspaceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWorkspaces(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        organizationId
        organization {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              bsshBiosampleCounter
              readGroups
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              amount
              type
              notes
              groups
              projectId
              workspaceId
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        description
        basespaceAccessToken
        bsshBiosampleCounter
        projects {
          items {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              bsshBiosampleCounter
              readGroups
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              projectOutputS3Path
              size
              assayName
              status
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            projectOutputS3Path
            size
            assayName
            status
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            amount
            type
            notes
            groups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              projectOutputS3Path
              size
              assayName
              status
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              bsshBiosampleCounter
              readGroups
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationWorkspacesId
      }
      nextToken
    }
  }
`;

export const listWorkspaceBiosamplesNoWS = /* GraphQL */ `
  query ListWorkspaceBiosamples(
    $filter: ModelWorkspaceBiosamplesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkspaceBiosamples(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        workspaceID
        bSSHBiosampleID
        bSSHBiosample {
          id
          workspaceId
          bioSampleName
          defaultProject
          bsshDateModified
          bsshDateCreated
          readGroups
          writeGroups
          adminGroups
          created
          updated
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const biosamplesByProjectSmall = /* GraphQL */ `
  query BiosamplesByProject(
    $projectId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBiosampleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    biosamplesByProject(
      projectId: $projectId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;

export const biosamplesByProjectIdsAndName = /* GraphQL */ `
  query BiosamplesByProject(
    $projectId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBiosampleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    biosamplesByProject(
      projectId: $projectId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        biosampleName
      }
      nextToken
    }
  }
`;

// List workspaces by organization. Return the ws small and org small
export const onlyWorkspacesByOrganization = /* GraphQL */ `
  query WorkspacesByOrganization(
    $organizationId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWorkspaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    workspacesByOrganization(
      organizationId: $organizationId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        organization {
          id
          organizationName
        }
        description
        organizationWorkspacesId
      }
      nextToken
    }
  }
`;

export const getWorkspaceJustName = /* GraphQL */ `
  query GetWorkspace($id: ID!) {
    getWorkspace(id: $id) {
      id
      organizationId
      organization {
        id
        organizationName
      }
      description
      organizationWorkspacesId
    }
  }
`;


export const listWorkspacesForMain = /* GraphQL */ `
  query ListWorkspaces(
    $id: ID
    $filter: ModelWorkspaceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWorkspaces(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        organizationId
        organization {
          id
          organizationName
        }
        description
      }
      nextToken
    }
  }
`;


export const projectsByWorkspaceForProjectTable = /* GraphQL */ `
  query ProjectsByWorkspace(
    $workspaceId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectsByWorkspace(
      workspaceId: $workspaceId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            organizationName
            sharedS3Paths
          }
          description
          basespaceAccessToken
          lastCached
          organizationWorkspacesId
        }
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              organizationName
              sharedS3Paths
            }
            description
            basespaceAccessToken
            lastCached
            organizationWorkspacesId
          }
          associatedProjectId
          clientProjectName
          lotId
          analysisType
          projectType
          size
          assayName
          status
          initiator
          biosamples {
            items {
              id
              biosampleName
            }
            nextToken
          }
          pipelines {
            items {
              id
              pipelineName
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        created
        clientProjectName
        lotId
        analysisType
        projectType
        projectOutputS3Path
        size
        assayName
        status
        initiator
        readGroups
        writeGroups
        adminGroups
        biosamples {
          items {
            id
            biosampleName
          }
          nextToken
        }
        pipelines {
          items {
            id
            pipelineName
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const projectsByWorkspaceJustIds = /* GraphQL */ `
  query ProjectsByWorkspace(
    $workspaceId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectsByWorkspace(
      workspaceId: $workspaceId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;

export const projectsByWorkspaceForProjectTableBiosamplesPagination = /* GraphQL */ `
  query ProjectsByWorkspace(
    $workspaceId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
    $biosamplesLimit: Int
  ) {
    projectsByWorkspace(
      workspaceId: $workspaceId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            organizationName
            sharedS3Paths
          }
          description
          basespaceAccessToken
          lastCached
          organizationWorkspacesId
        }
        description
        biosampleMetadataColumns
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              organizationName
              sharedS3Paths
            }
            description
            basespaceAccessToken
            lastCached
            organizationWorkspacesId
          }
          associatedProjectId
          clientProjectName
          lotId
          analysisType
          projectType
          size
          assayName
          status
          initiator
          biosamples {
            items {
              id
              biosampleName
            }
            nextToken
          }
          pipelines {
            items {
              id
              pipelineName
              status
              parameters
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        created
        clientProjectName
        lotId
        analysisType
        projectType
        projectOutputS3Path
        size
        assayName
        status
        initiator
        biosamples(limit: $biosamplesLimit) {
          items {
            id
            biosampleName
          }
          nextToken
        }
        pipelines {
          items {
            id
            pipelineName
            status
            parameters
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const listBSSHBiosamplesSmall = /* GraphQL */ `
  query ListBSSHBiosamples(
    $workspaceId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelBSSHBiosampleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBSSHBiosamples(
      workspaceId: $workspaceId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        workspaceId
        id
        bioSampleName
        defaultProject
      }
      nextToken
    }
  }
`;

export const listOrganizationsForOrgs = /* GraphQL */ `
  query ListOrganizations(
    $id: ID
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrganizations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        bioskrybClientId
        organizationName
        active
        storageCreditRate
        pipelineCreditRate
        zohoId
        salesforceId
        salesforceUrl
        salesEmail
        fasEmail
        poNumber
        organizationDataKay
        created
        workspaces {
          items {
            id
            organizationId
            description
          }
          nextToken
        }
        sharedS3Paths
      }
      nextToken
    }
  }
`;

export const customListLaunchablePipelines = /* GraphQL */ `
  query ListLaunchablePipelines(
    $id: ID
    $filter: ModelLaunchablePipelinesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLaunchablePipelines(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        pipelineName
        pipelineVersion
        parameters
        description
        analysisLevel
        supportedTertiaryAnalyses
        supportedInputFileTypes
        gitRepo
        tokensPerBiosample
        requiredMetadataColumns
      }
      nextToken
    }
  }
`;

export const customListPrivateLaunchablePipelines = /* GraphQL */ `
  query ListPrivateLaunchablePipelines(
    $id: ID
    $filter: ModelPrivateLaunchablePipelinesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPrivateLaunchablePipelines(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        pipelineName
        pipelineVersion
        parameters
        description
        analysisLevel
        supportedTertiaryAnalyses
        supportedInputFileTypes
        gitRepo
        tokensPerBiosample
        readGroups
        requiredMetadataColumns
      }
      nextToken
    }
  }
`;

export const getSharedS3PathsFromWorkspace = /* GraphQL */ `
query GetWorkspace($id: ID!) {
  getWorkspace(id: $id) {
    id
    sharedS3Paths
  }
}
`;

export const transactionsByWorkspaceForAllUsers = /* GraphQL */ `
  query TransactionsByWorkspace(
    $workspaceId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCreditTransactionLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByWorkspace(
      workspaceId: $workspaceId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        debitor
        creditor
        user
        userName
        creditPackName
        timesPackPurchased
        amount
        type
        notes
        processed
        projectId
        project {
          id
          clientProjectName
        }
        workspaceId
        pipelineId
        pipeline {
          id
          projectId
          pipelineName
          passFailBiosamples
          initiator
          projectPipelinesId
        }
        isSpent
        created
        organizationCreditTransactionsId
        workspaceCreditTransactionsId
        projectCreditTransactionsId
      }
      nextToken
    }
  }
`;

export const transactionsByCreditorAndDebitorForCreditTransactionsTable = /* GraphQL */ `
  query TransactionsByCreditorAndDebitor(
    $creditor: ID!
    $debitor: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCreditTransactionLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByCreditorAndDebitor(
      creditor: $creditor
      debitor: $debitor
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        debitor
        creditor
        user
        amount
        type
        notes
        readGroups
        processed
        projectId
        project {
          id
          clientProjectName
        }
        workspaceId
        workspace {
          id
          description
        }
        pipelineId
        pipeline {
          id
          pipelineName
        }
        created
        updated
      }
      nextToken
    }
  }
`;

export const transactionsByDebitorAndCreditorForCreditTransactionsTable = /* GraphQL */ `
  query TransactionsByDebitorAndCreditor(
    $debitor: ID!
    $creditor: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCreditTransactionLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByDebitorAndCreditor(
      debitor: $debitor
      creditor: $creditor
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        debitor
        creditor
        user
        amount
        type
        notes
        readGroups
        processed
        projectId
        project {
          id
          clientProjectName
        }
        workspaceId
        workspace {
          id
          description
        }
        pipelineId
        pipeline {
          id
          pipelineName
        }
        created
        updated
      }
      nextToken
    }
  }
`;

export const biosamplesByProjectForBiosamplesTable= /* GraphQL */ `
  query BiosamplesByProject(
    $projectId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBiosampleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    biosamplesByProject(
      projectId: $projectId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              organizationName
            }
            description
            organizationWorkspacesId
          }
          clientProjectName
          status
          initiator
          workspaceProjectsId
        }
        biosampleName
        bsshId
        analysisFiles
        metadata
        r1FastqName
        r2FastqName
        r1FastqMb5Sum
        r2FastqMb5Sum
        r1FastqLength
        r2FastqLength
        r1FastqTotalReads
        r2FastqTotalReads
        petaSuiteVersion
        petaSuiteSpecies
        petaSuiteReferenceDir
        fastqValidationStatus
        size
        fastqs
        r1FastqS3Uri
        r2FastqS3Uri
        lotId
        created
        updated
        readGroups
        writeGroups
        adminGroups
        projectBiosamplesId
      }
      nextToken
    }
  }
`;

export const getProjectForBiosamples = /* GraphQL */ `
  query GetProject(
    $id: ID!
    $biosamplesLimit: Int
    $biosamplesNextToken: String
    ) {
    getProject(
      id: $id
    ) {
      id
      biosamples(limit: $biosamplesLimit, nextToken: $biosamplesNextToken) {
        items {
          id
          biosampleName
          }
        nextToken
      }
    }
  }
`;

export const getWorkspaceForProjects = /* GraphQL */ `
  query GetWorkspace(
    $id: ID!
    $projectsLimit: Int
    $projectsNextToken: String) {
    getWorkspace(id: $id) {
      id
      projects(limit: $projectsLimit, nextToken: $projectsNextToken) {
        items {
          id
        }
        nextToken
      }
    }
  }
`;

export const getWorkspaceForCreditView = /* GraphQL */ `
  query GetWorkspace($id: ID!) {
    getWorkspace(id: $id) {
      id
      description
      lastSizeScan
      size
      created
    }
  }
`;

export const biosamplesByProjectForTertiaryAnalyses = /* GraphQL */ `
  query BiosamplesByProject(
    $projectId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBiosampleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    biosamplesByProject(
      projectId: $projectId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              organizationName
            }
          }
        }
        biosampleName
        bsshId
        analysisFiles
        fastqValidationStatus
        fastqs
        created
        projectBiosamplesId
      }
      nextToken
    }
  }
`;

export const getProjectForProjectView = /* GraphQL */ `
  query GetProject(
    $id: ID!
    ) {
    getProject(
      id: $id
    ) {
      id
      clientProjectName
      workspaceId
      workspace {
        id
        organizationId
      }
    }
  }
`;

export const getProjectGroups = /* GraphQL */ `
  query GetProject(
    $id: ID!
    ) {
    getProject(
      id: $id
    ) {
      readGroups
      writeGroups
      adminGroups
    }
  }
`;

export const getPipelineGroups = /* GraphQL */ `
  query GetPipeline(
    $id: ID!
    ) {
    getPipeline(
      id: $id
    ) {
      readGroups
      writeGroups
      adminGroups
    }
  }
`;

export const getBiosampleGroups = /* GraphQL */ `
  query GetBiosample(
    $id: ID!
    ) {
    getBiosample(
      id: $id
    ) {
      readGroups
      writeGroups
      adminGroups
    }
  }
`;

export const projectsByWorkspaceNames = /* GraphQL */ `
  query ProjectsByWorkspace(
    $workspaceId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectsByWorkspace(
      workspaceId: $workspaceId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        clientProjectName
      }
      nextToken
    }
  }
`;

export const biosamplesForProjectDeletion= /* GraphQL */ `
  query BiosamplesByProject(
    $projectId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBiosampleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    biosamplesByProject(
      projectId: $projectId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organization {
              id
              organizationName
            }
            description
          }
          clientProjectName
        }
        biosampleName
        created
        readGroups
        writeGroups
        adminGroups
      }
      nextToken
    }
  }
`;

export const listWorkspacesIdName = /* GraphQL */ `
  query ListWorkspaces(
    $id: ID
    $filter: ModelWorkspaceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWorkspaces(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        description
      }
      nextToken
    }
  }
`;

export const listOrganizationsIdName = /* GraphQL */ `
  query ListOrganizations(
    $id: ID
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrganizations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        organizationName
      }
      nextToken
    }
  }
`;

export const getWorkspaceIdAndName = /* GraphQL */ `
  query GetWorkspace($id: ID!) {
    getWorkspace(id: $id) {
      id
      description
    }
  }
`;

export const getOrganizationIdAndName = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      organizationName
    }
  }
`;

export const projectForMiniProjectTable = /* GraphQL */ `
  query ProjectsByWorkspace(
    $workspaceId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
    $biosamplesLimit: Int
  ) {
    projectsByWorkspace(
      workspaceId: $workspaceId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            organizationName
            sharedS3Paths
          }
          description
          basespaceAccessToken
          lastCached
          organizationWorkspacesId
        }
        description
        biosampleMetadataColumns
        created
        clientProjectName
        lotId
        analysisType
        projectType
        projectOutputS3Path
        size
        assayName
        status
        initiator
        biosamples(limit: $biosamplesLimit) {
          items {
            id
            biosampleName
          }
          nextToken
        }
        pipelines {
          items {
            id
            pipelineName
            status
            parameters
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const listCreditTransactionLogsForAdminDownloadLogs = /* GraphQL */ `
  query ListCreditTransactionLogs(
    $id: ID
    $filter: ModelCreditTransactionLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCreditTransactionLogs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        debitor
        debitorOrg {
          id
          organizationName
        }
        creditor
        creditorOrg {
          id
          organizationName
        }
        user
        userName
        amount
        creditPackName
        timesPackPurchased
        type
        notes
        processed
        readGroups
        writeGroups
        projectId
        project {
          id
          clientProjectName
        }
        workspaceId
        workspace {
          id
          description
        }
        pipelineId
        pipeline {
          id
          pipelineName
        }
        isSpent
        expirationInMonths
        billingStart
        created
      }
      nextToken
    }
  }
`;