<template>
  <Dialog v-model:visible="showing" :style="{ width: '600px' }" header="Delete Project" :modal="true" class="p-fluid" :closable="false">
    <div class="delete-spinner-div flex justify-content-center flex-column" v-if="deleting">
      <h3 class="pb-3">Deleting project...</h3>
      <!-- <ProgressSpinner style="width:150px;height:150px"/> -->
      <img alt="BJSpinner" class="bjSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png" />
      <span class="pt-5">Please wait...</span>
    </div>
    <div v-if="!deleting" class="field mb-4">
      <DataTable :value="projectsForDelete">
        <Column field="clientProjectName" header="Project Name" />
        <Column header="FASTQ Size">
          <template #body="{ data }">
            {{ getSize(getBiosamplesSize(data)) }}
          </template>
        </Column>
        <Column header="Pipeline Size">
          <template #body="{ data }">
            {{ getSize(getPipelineSize(data)) }}
          </template>
        </Column>
        <Column header="Project Size">
          <template #body="{ data }">
            {{ getSize(data.size) }}
          </template>
        </Column>
      </DataTable>
    </div>
    <div v-if="!deleting">
      <div class="field-checkbox mb-4">
        <Checkbox id="deleteFASTQ" name="deleteFASTQ" value="FASTQ" v-model="deleteOptions" />
        <label for="deleteFASTQ">Delete FASTQ files associated with project</label>
      </div>
      <div class="field-checkbox mb-4">
        <Checkbox id="deleteBAM" name="deleteBAM" value="BAM" v-model="deleteOptions" />
        <label for="deleteBAM">Delete BAM files associated with project</label>
      </div>
      <div class="field-checkbox mb-4">
        <Checkbox id="deleteAll" name="deleteAll" value="ALL" v-model="deleteOptions" />
        <label for="deleteAll">Delete entire project (This moves everything associated with a project including pipeline results.)</label>
      </div>
    </div>
    <template v-if="!deleting" #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-secondary" @click="hideDialog" />
      <Button label="Delete Projects" class="p-button-outline" @click="deleteProjects" />
    </template>
  </Dialog>
</template>

<script>
import { Auth, API, graphqlOperation } from 'aws-amplify';
import * as mutations from '@/graphql/mutations';
import * as customQueries from '@/graphql/custom_queries';
import { getSize, listItems, remapBiosamplesAndMakeFinalObj } from '@/utils';

const asyncBatch = require('async-batch').default;

export default {
  props: ['projectsForDelete'],
  data() {
    return {
      showing: false,
      deleteOptions: [],
      deleting: false,
    };
  },
  methods: {
    getSize(size) {
      return getSize(size);
    },
    getBiosamplesSize(project) {
      if (project.biosamples.items.length === 0) return 0;
      const sum = project.biosamples.items.reduce((a, b) => ({ size: a.size + b.size }));
      return sum.size;
    },
    getPipelineSize(project) {
      if (project.pipelines.items.length === 0) return 0;
      const sum = project.pipelines.items.reduce((a, b) => ({ size: a.size + b.size }));
      return sum.size;
    },
    async deleteProjects() {
      this.deleting = true;
      for (let i = 0; i < this.projectsForDelete.length; i += 1) {
        await this.deleteSingleProject(this.projectsForDelete[i]);
      }
      this.deleteOptions = [];
      this.deleting = false;
      this.$store.dispatch('showDeleteProject');
    },
    async deleteSingleProject(project) {
      const projectVariables = {
        id: project.id,
      };
      try {
        this.createDeletionLog(project);
        const projectBiosamples = await listItems(customQueries.biosamplesForProjectDeletion, { projectId: project.id });
        await API.graphql(graphqlOperation(mutations.deleteProject, { input: projectVariables }));
        await asyncBatch(projectBiosamples, this.deleteBiosample, 500);
      } catch (error) {
        console.log('project delete error - ', error);
      }
    },
    async deleteBiosample(biosampleObj) {
      try {
        // this.createBiosampleDeletionLog(biosampleObj);
        await API.graphql(graphqlOperation(mutations.deleteBiosample, { input: { id: biosampleObj.id } }));
      } catch (error) {
        console.error(error);
      }
    },
    async createBiosampleDeletionLog(biosample) {
      try {
        if (biosample === null || biosample === undefined) {
          throw new Error('Biosample is null in createDeletionLog');
        }
        const user = await Auth.currentAuthenticatedUser();
        const deleteLogObj = {
          organizationId: biosample.project.workspace.organization.id,
          organizationName: biosample.project.workspace.organization.organizationName,
          workspaceId: biosample.project.workspace.id,
          workspaceName: biosample.project.workspace.description,
          projectName: biosample.project.clientProjectName,
          userUuidCognito: user.username,
          userNameCognito: user.attributes.email,
          uuidOfDeletedEntity: biosample.id,
          deletedInParent: true,
          typeOfDeletedEntity: 'biosample',
          status: 'GraphQlDeletion',
          affectedBiosamples: JSON.stringify([{ id: biosample.id, name: biosample.biosampleName }]),
          dateOfCreationOfDeletedEntity: biosample.created,
          emailInfo: JSON.stringify(this.makeEmailInfoFromBiosample(biosample)),
          readGroups: biosample.readGroups,
          writeGroups: biosample.writeGroups,
          adminGroups: biosample.adminGroups,
        };
        console.log('deleteLogObj :>> ', deleteLogObj);
        await API.graphql(graphqlOperation(mutations.createDeletedEntitiesLog, { input: deleteLogObj }));
      } catch (error) {
        console.error(error);
      }
    },
    makeEmailInfoFromBiosample(biosample) {
      return {
        application_environment: process.env.VUE_APP_SLS_STAGE,
        project_name: biosample.project.clientProjectName,
        project_uuid: biosample.project.id,
        initiator: biosample.project.initiator,
        workspace_uuid: biosample.project.workspace.id,
        workspace_name: biosample.project.workspace.description,
        organization_uuid: biosample.project.workspace.organization.id,
        organization_name: biosample.project.workspace.organization.organizationName,
        commit_id: process.env.VUE_APP_AWS_COMMIT_ID,
      };
    },
    async createDeletionLog(project) {
      try {
        console.log('project :>> ', project);
        if (project === null || project === undefined) {
          throw new Error('Project is null in createDeletionLog');
        }
        const promises = [Auth.currentAuthenticatedUser(), this.getProjectGroups(project.id), this.makeAffectedBiosamples(project)];
        const [user, projectGroups, affectedBiosamples] = await Promise.all(promises);
        console.log('projectGroups :>> ', projectGroups);
        const deleteLogObj = {
          organizationId: project.workspace.organizationId,
          organizationName: project.workspace.organization.organizationName,
          workspaceId: project.workspace.id,
          workspaceName: project.workspace.description,
          projectName: project.clientProjectName,
          userUuidCognito: user.username,
          userNameCognito: user.attributes.email,
          uuidOfDeletedEntity: project.id,
          deletedInParent: false,
          typeOfDeletedEntity: 'project',
          status: 'GraphQlDeletion',
          affectedBiosamples: JSON.stringify(affectedBiosamples),
          dateOfCreationOfDeletedEntity: project.created,
          emailInfo: JSON.stringify(this.makeEmailInfoFromProject(project)),
          readGroups: projectGroups.readGroups,
          writeGroups: projectGroups.writeGroups,
          adminGroups: projectGroups.adminGroups,
        };
        await API.graphql(graphqlOperation(mutations.createDeletedEntitiesLog, { input: deleteLogObj }));
      } catch (error) {
        console.error(error);
      }
    },
    async getProjectGroups(projectId) {
      return (await API.graphql(graphqlOperation(customQueries.getProjectGroups, { id: projectId }))).data.getProject;
    },
    async makeAffectedBiosamples(project) {
      let biosampleIdsObj = project.biosamples.items;
      if (project.biosamples.length === 100) {
        biosampleIdsObj = await listItems(customQueries.biosamplesByProjectIdsAndName, { projectId: project.id });
      }
      return remapBiosamplesAndMakeFinalObj(biosampleIdsObj);
    },
    makeEmailInfoFromProject(project) {
      return {
        application_environment: process.env.VUE_APP_SLS_STAGE,
        project_name: project.clientProjectName,
        project_uuid: project.id,
        initiator: project.initiator,
        workspace_uuid: project.workspace.id,
        workspace_name: project.workspace.description,
        organization_uuid: project.workspace.organization.id,
        organization_name: project.workspace.organization.organizationName,
        commit_id: process.env.VUE_APP_AWS_COMMIT_ID,
      };
    },
    hideDialog() {
      this.deleteOptions = [];
      this.$store.dispatch('showDeleteProject');
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingDeleteProject': async function () {
      this.showing = this.$store.state.showingDeleteProject;
    },
  },
};
</script>

<style lang="scss">
.delete-spinner-div{
  text-align: center;
}
.bjSpinner {
  width:150px;
  height:150px;
}
</style>
