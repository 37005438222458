<template>
  <Dialog v-model:visible="showing" :style="{ width: '450px' }" header="Change User Group" :modal="true" class="p-fluid" :closable="false">
    <div v-if="!this.loading">
      <div class="field">
        <span>User: {{ getUsername(this.user.UserAttributes) }}</span>
      </div>
      <div class="field">
        <div v-if="bioskrybAdmin || organizationAdmin" class="pb-5">
          <label for="organizationGroup">Organization Groups</label>
          <div id="organizationGroup" class="p-inputgroup pt-2">
            <Button v-if="this.selectedOrganizationGroups.length > 0" icon="pi pi-angle-double-down" class="p-button-info" @click="addAllWorkspacesForOrganization()" v-tooltip.left="'Add all workspaces from selected organizations'" />
            <AutoComplete
              :multiple="true"
              v-model="selectedOrganizationGroups"
              :suggestions="filteredOrganizationGroups"
              @complete="searchOrganizations($event)"
              field="name"
              :placeholder="(this.selectedOrganizationGroups.length === 0) ? 'Organizations' : ''"
              :virtualScrollerOptions="{ itemSize: 31 }"
              dropdown
              forceSelection
            />
          </div>
        </div>
        <div>
          <label for="workspaceGroup">Workspace Groups</label>
          <div id="workspaceGroup" class="p-inputgroup pt-2">
            <Button v-if="this.selectedWorkspaceGroups.length > 0" icon="pi pi-trash" class="p-button-info" @click="clearAllSelectedWorkspaces()" v-tooltip.left="'Clear'" />
            <AutoComplete
              :multiple="true"
              v-model="selectedWorkspaceGroups"
              field="name"
              :suggestions="filteredWorkspaceGroups"
              @complete="searchWorkspaces($event)"
              :placeholder="(this.selectedWorkspaceGroups.length === 0) ? 'Workspaces' : ''"
              :virtualScrollerOptions="{ itemSize: 31 }"
              dropdown
              forceSelection
            />
          </div>
        </div>
      </div>
      <div v-if="bioskrybAdmin" class="field-checkbox pt-2">
        <InputSwitch id="baseJumperAdmin" v-model="baseJumperAdmin" />
        <label for="baseJumperAdmin">Bioskryb Admin</label>
      </div>
    </div>
    <template #footer v-if="!this.loading">
      <Button label="Cancel" icon="pi pi-times" class="p-button-secondary" @click="hideDialog" />
      <Button label="Save Changes" @click="autocompleteChanges()" />
    </template>
    <div class="field flex justify-content-center" v-if="loading">
      <!-- <ProgressSpinner /> -->
      <img alt="BJSpinner" class="bjSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png" />
    </div>
  </Dialog>
</template>

<script>
// TODO: REFACTOR EVERYTHING. This works but it works bad. Too many compicated and unnececary lists and loops.
// Once the username prop is changed, the loadUser method is called.

import { Auth, API, graphqlOperation } from 'aws-amplify';
// Used for forcing sign out
// import AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import * as queries from '@/graphql/queries';
import { sendNotificationEmail } from '@/utils';

export default {
  name: 'ChangeGroup',
  props: ['username'],
  emits: ['resetUsernameForChangeGroup'],
  data() {
    return {
      showing: false,
      baseJumperAdmin: false, // To check if the selected user is biskryb admin
      baseJumperAdminOnLoad: false,
      user: null,
      loading: false,
      allGroups: [],
      hiddenGroups: ['us-east-1_Yel7A3Ogx_Google'],
      organizationMap: new Map(),
      workspaceMap: new Map(),
      organizationGroups: [],
      selectedOrganizationGroups: [],
      filteredOrganizationGroups: [],
      usersOriginalOrganizations: [],
      workspaceGroups: [],
      selectedWorkspaceGroups: [],
      filteredWorkspaceGroups: [],
      usersOriginalWorkspaces: [],
      saveWorkspaceGroups: [],
      organizations: [],
      orgsWorkspaces: [],
      allGroupsNextToken: '',
      nextToken: '',
      bioskrybAdmin: false, // To check if the current user is biskryb admin
      organizationAdmin: false,
      workspaceAdmin: false,
    };
  },
  methods: {
    async loadUser() {
      if ((this.username === undefined) || (!this.username)) return;
      this.loading = true;
      this.bioskrybAdmin = (this.$store.state.precedenceLevel === 1);
      this.organizationAdmin = (this.$store.state.precedenceLevel === 2);
      this.workspaceAdmin = (this.$store.state.precedenceLevel === 3);
      const apiName = 'AdminQueries';
      const path = '/getUser';
      const myInit = {
        queryStringParameters: {
          username: `${this.username}`,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        },
      };
      try {
        const { ...user } = await API.get(apiName, path, myInit);
        this.user = user;
        console.log('user :>> ', user);
        if (this.organizationAdmin) {
          const response = await API.graphql(graphqlOperation(queries.workspacesByOrganization, {
            organizationId: this.$store.state.activeOrganization,
          }));
          const wsIds = response.data.workspacesByOrganization.items.map((ws) => ws.id);
          console.log('response :>> ', response);
          console.log('wsIds :>> ', wsIds);
          this.orgsWorkspaces = wsIds;
          this.hiddenGroups.push(...wsIds);
        }
        // Parallel
        // The listGroup functions require the maps so thats why we first need to wait for the maps to be populated before we start to load the groups.
        let promises = [];
        promises.push(this.loadOrganizations());
        promises.push(this.populateNameMap());
        await Promise.all(promises);
        promises = [];
        promises.push(this.listGroupsForUser());
        promises.push(this.listGroups());
        await Promise.all(promises);
      } catch (error) {
        console.log('Load user failed');
        console.log(error);
      }
      this.loading = false;
    },
    async loadOrganizations() {
      const result = await API.graphql(graphqlOperation(queries.listOrganizations, { limit: 300 }));
      this.organizations = result.data.listOrganizations.items;
    },
    async populateNameMap() {
      const workspacesResponse = await API.graphql(graphqlOperation(queries.listWorkspaces, { limit: 300 }));
      const workspaces = workspacesResponse.data.listWorkspaces.items;
      this.populateWorkspaceMap(workspaces);
      this.populateOrganizationMap(this.organizations);
    },
    async populateOrganizationMap(organizations) {
      organizations.forEach((org) => {
        this.organizationMap.set(org.id, org.organizationName);
      });
    },
    async populateWorkspaceMap(workspaces) {
      workspaces.forEach((workspace) => {
        this.workspaceMap.set(workspace.id, workspace.description);
      });
    },
    searchWorkspaces(event) {
      // const options = new Set();
      // this.workspaceGroups.forEach((ws) => {
      //   if (ws.name.includes(event.query)) {
      //     options.add(ws);
      //   }
      // });
      // this.filteredWorkspaceGroups = Array.from(options);
      const options = [];
      this.workspaceGroups.forEach((ws) => {
        if (ws.name.includes(event.query)) {
          options.push(ws);
        }
      });
      if (this.containsDuplicates(options)) {
        this.filteredWorkspaceGroups = Array.from(new Set(options));
      } else {
        this.filteredWorkspaceGroups = options;
      }
    },
    searchOrganizations(event) {
      const options = [];
      this.organizationGroups.forEach((org) => {
        if (org.name.includes(event.query)) {
          options.push(org);
        }
      });
      if (this.containsDuplicates(options)) {
        this.filteredOrganizationGroups = Array.from(new Set(options));
      } else {
        this.filteredOrganizationGroups = options;
      }
    },
    containsDuplicates(arr) {
      return new Set(arr).size !== arr.length;
    },
    async autocompleteChanges() {
      const addList = [];
      const removeList = [];
      // BaseJumperAdmin
      if (!(this.baseJumperAdminOnLoad === this.baseJumperAdmin)) {
        if (this.baseJumperAdmin && !this.baseJumperAdminOnLoad) {
          addList.push('BaseJumperAdmin');
          this.addUserToGroup('BaseJumperAdmin');
        } else if (!this.baseJumperAdmin && this.baseJumperAdminOnLoad) {
          removeList.push('BaseJumperAdmin');
          this.removeUserFromGroup('BaseJumperAdmin');
        }
      }
      // Organizations
      this.selectedOrganizationGroups.forEach(async (org) => {
        if (!this.usersOriginalOrganizations.some((originalOrg) => (originalOrg.id === org.id))) {
          addList.push(org.name);
          this.addUserToGroup(org.id);
        }
      });
      this.usersOriginalOrganizations.forEach(async (originalOrg) => {
        if (!this.selectedOrganizationGroups.some((org) => (originalOrg.id === org.id))) {
          removeList.push(originalOrg.name);
          this.removeUserFromGroup(originalOrg.id);
        }
      });
      // Workspaces
      this.usersOriginalWorkspaces.forEach(async (originalWs) => {
        if (!this.selectedWorkspaceGroups.some((ws) => (originalWs.id === ws.id))) {
          removeList.push(originalWs.name);
          this.removeUserFromGroup(originalWs.id);
        }
      });
      this.selectedWorkspaceGroups.forEach(async (ws) => {
        if (!this.usersOriginalWorkspaces.some((originalWs) => (originalWs.id === ws.id))) {
          addList.push(ws.name);
          this.addUserToGroup(ws.id);
        }
      });
      this.$toast.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Changes saved successfully!',
        life: 3000,
      });
      // eslint-disable-next-line prefer-const
      let message = `The following changes were made for user: ${this.getUsername(this.user.UserAttributes)}:\\n`;
      console.log('Username :>> ', this.getUsername(this.user.UserAttributes));
      removeList.forEach((grp) => {
        message = message.concat(`Removed from ${grp}.\\n`);
      });
      addList.forEach((grp) => {
        message = message.concat(`Added to ${grp}.\\n`);
      });
      sendNotificationEmail(message, ['basejumper@bioskryb.com', 'fas@bioskryb.com']);
      this.hideDialog();
    },
    async addUserToGroup(groupname) {
      const apiName = 'AdminQueries';
      const path = '/addUserToGroup';
      const myInit = {
        body: {
          username: `${this.username}`,
          groupname,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        },
      };
      return API.post(apiName, path, myInit);
    },
    async removeUserFromGroup(groupname) {
      const apiName = 'AdminQueries';
      const path = '/removeUserFromGroup';
      const myInit = {
        body: {
          username: `${this.username}`,
          groupname,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        },
      };
      return API.post(apiName, path, myInit);
    },
    // async isThisOrgsAdmin() {
    //   const userWithGroups = await Auth.currentAuthenticatedUser();
    //   const groups = userWithGroups.signInUserSession.accessToken.payload['cognito:groups'];
    //   if (groups.some((grp) => grp === `ORG/${this.$store.state.activeOrganization}/Admin`) && this.$store.state.activeOrganization === activeOrganization) return true;
    //   return false;
    // },
    async listGroups() {
      let allGroups = [];
      while (this.allGroupsNextToken !== undefined) {
        const res = await this.listGroupCall();
        allGroups.push(res);
      }
      allGroups = allGroups.flatMap((users) => [...users.Groups]);
      // console.log('allGroups :>> ', allGroups);
      // Filter all those groups we dont whant to show
      let filtered = [];
      allGroups.forEach((grp) => {
        if (!this.hiddenGroups.includes(grp.GroupName)) {
          filtered.push(grp);
        }
      });
      if ((this.orgsWorkspaces.length > 0) && !this.bioskrybAdmin) {
        filtered = filtered.filter((grp) => ((this.orgsWorkspaces.includes(grp.GroupName.split('/')[1]) || grp.GroupName.split('/')[0] === 'ORG')));
      }
      this.allGroups = filtered;
      this.allGroups.forEach((grp) => {
        const prefix = grp.GroupName.split('/')[0];
        switch (prefix) {
          case 'ORG':
            // eslint-disable-next-line no-case-declarations
            const o = { id: `${grp.GroupName}`, name: `${this.organizationMap.get(grp.GroupName.split('/')[1])}-${grp.GroupName.split('/')[2]}` };
            if (this.organizationMap.get(grp.GroupName.split('/')[1]) === undefined) break;
            if (this.bioskrybAdmin || (this.organizationAdmin && grp.GroupName.split('/')[1] === this.$store.state.activeOrganization)) {
              this.organizationGroups.push(o);
            }
            break;
          case 'WS':
            // eslint-disable-next-line no-case-declarations
            const o1 = { id: `${grp.GroupName}`, name: `${this.workspaceMap.get(grp.GroupName.split('/')[1])}-${grp.GroupName.split('/')[2]}` };
            if (this.workspaceMap.get(grp.GroupName.split('/')[1]) === undefined) break;
            if ((!this.bioskrybAdmin && !this.organizationAdmin && this.workspaceAdmin && (grp.GroupName.split('/')[1] === this.$store.state.activeWorkspace)) || (this.bioskrybAdmin || this.organizationAdmin)) {
              this.workspaceGroups.push(o1);
              this.saveWorkspaceGroups.push(o1);
            }
            break;
          default:
            break;
        }
      });
    },
    async listGroupCall() {
      const apiName = 'AdminQueries';
      const path = '/listGroups';
      let myInit;
      if (this.allGroupsNextToken !== undefined && this.allGroupsNextToken !== null && this.allGroupsNextToken !== '') {
        myInit = {
          queryStringParameters: {
            limit: 60, // Max limit is 60
            token: this.allGroupsNextToken,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
          },
        };
      } else {
        myInit = {
          queryStringParameters: {
            limit: 60, // Max limit is 60
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
          },
        };
      }
      const { NextToken, ...groups } = await API.get(apiName, path, myInit);
      this.allGroupsNextToken = NextToken;
      return groups;
    },
    // Expand to support tokens
    async listGroupsForUser() {
      const apiName = 'AdminQueries';
      const path = '/listGroupsForUser';
      const myInit = {
        queryStringParameters: {
          username: this.user.Username,
          limit: 60,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        },
      };
      try {
        let { ...groups } = await API.get(apiName, path, myInit);
        groups = groups.Groups;
        // If the user is an organization admin filter out all the workspace the user is in that are not in the selected organization. Removing this would show workspace the user is in even if they are not in any of the selected organizations.
        if ((this.orgsWorkspaces.length > 0) && (this.$store.state.precedenceLevel > 1)) {
          groups = groups.filter((grp) => (this.orgsWorkspaces.includes(grp.GroupName.split('/')[1]) || grp.GroupName.split('/')[0] === 'ORG'));
        }
        groups.forEach((grp) => {
          if (grp.GroupName === 'BaseJumperAdmin') {
            this.baseJumperAdmin = true;
            this.baseJumperAdminOnLoad = true;
          }
          const prefix = grp.GroupName.split('/')[0];
          switch (prefix) {
            case 'ORG':
            // eslint-disable-next-line no-case-declarations
              const o = { id: `${grp.GroupName}`, name: `${this.organizationMap.get(grp.GroupName.split('/')[1])}-${grp.GroupName.split('/')[2]}` };
              if (this.organizationMap.get(grp.GroupName.split('/')[1]) === undefined) break;
              if (this.bioskrybAdmin || (this.organizationAdmin && grp.GroupName.split('/')[1] === this.$store.state.activeOrganization)) {
                this.selectedOrganizationGroups.push(o);
                this.usersOriginalOrganizations.push(o);
              }
              break;
            case 'WS':
            // eslint-disable-next-line no-case-declarations
              const o1 = { id: `${grp.GroupName}`, name: `${this.workspaceMap.get(grp.GroupName.split('/')[1])}-${grp.GroupName.split('/')[2]}` };
              if (this.workspaceMap.get(grp.GroupName.split('/')[1]) === undefined) break;
              if ((!this.bioskrybAdmin && !this.organizationAdmin && this.workspaceAdmin && (grp.GroupName.split('/')[1] === this.$store.state.activeWorkspace)) || (this.bioskrybAdmin || this.organizationAdmin)) {
                this.selectedWorkspaceGroups.push(o1);
                this.usersOriginalWorkspaces.push(o1);
              }
              break;
            default:
              break;
          }
        });
        console.log('this.baseJumperAdmin :>> ', this.baseJumperAdmin);
        console.log('this.baseJumperAdminOnLoad :>> ', this.baseJumperAdminOnLoad);
      } catch (error) {
        console.log('List groups for user failed');
        console.log(error);
      }
    },
    addAllWorkspacesForOrganization() {
      this.selectedWorkspaceGroups = [...new Set([...this.selectedWorkspaceGroups, ...this.filteredWorkspaceGroups])];
    },
    clearAllSelectedWorkspaces() {
      this.selectedWorkspaceGroups = [];
    },
    hideDialog() {
      this.allGroups = [];
      this.hiddenGroups = ['us-east-1_Yel7A3Ogx_Google'];
      this.organizationMap = new Map();
      this.workspaceMap = new Map();
      this.organizationGroups = [];
      this.selectedOrganizationGroups = [];
      this.filteredOrganizationGroups = [];
      this.usersOriginalOrganizations = [];
      this.workspaceGroups = [];
      this.selectedWorkspaceGroups = [];
      this.filteredWorkspaceGroups = [];
      this.usersOriginalWorkspaces = [];
      this.saveWorkspaceGroups = [];
      this.organizations = [];
      this.allGroupsNextToken = '';
      this.nextToken = '';
      this.baseJumperAdmin = false;
      this.baseJumperAdminOnLoad = false;
      this.$emit('resetUsernameForChangeGroup');
      this.$store.dispatch('showChangeGroup');
    },
    getUsername(attributes) {
      const name = attributes.filter((a) => a.Name === 'name');
      return name[0].Value;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingChangeGroup': async function () {
      this.showing = this.$store.state.showingChangeGroup;
    },
    username: [{
      handler: 'loadUser',
    }],
    // This might not be nececary, this watcher filters the available workspaces groups so that only the ones in the selected organization
    selectedOrganizationGroups(value) {
      if (value.length === 0) {
        this.workspaceGroups = this.saveWorkspaceGroups;
      } else {
        const localWorkspaceGroups = [];
        value.forEach((orgGrp) => {
          const orgObject = this.organizations.filter((realOrg) => realOrg.id === orgGrp.id.split('/')[1])[0];
          const foundWorkspaces = this.saveWorkspaceGroups.filter((wsGroup) => orgObject.workspaces.items.some((org) => org.id === wsGroup.id.split('/')[1]));
          localWorkspaceGroups.push(...foundWorkspaces);
        });
        this.workspaceGroups = localWorkspaceGroups;
        this.filteredWorkspaceGroups = localWorkspaceGroups;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bjSpinner {
  height: 40%;
  width: 40%;
}
</style>
