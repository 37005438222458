<template>
  <Dialog v-model:visible="showing" :style="dialogWidth" header="Launch Pipeline" :modal="true" class="p-fluid" :closable="false">
    <div class="d-flex justify-content-center mb-5">
      <div v-if="step === 1">
        <div v-if="loadingListBox" class="flex justify-content-center">
          <!-- <ProgressSpinner/> -->
          <img alt="BJSpinner" class="bjSpinner" :src="getSpinnerSrc()" />
        </div>
        <div v-else>
          <div class="LbLabelContainer mb-2">
            <div class="tooltipMark" v-tooltip.right="tooltipMessage">?</div>
          </div>
          <Listbox @click="listBoxClick" v-model="selectedLaunchablePipelineType" :options="mappedGroupedLaunchablePipelines" optionLabel="label" class="" listStyle="max-height:85vh">
            <template #option="slotProps">
              <div class="flex align-items-center justify-content-between">
                <div>{{ slotProps.option.label }}</div>
                <Dropdown class="versionDD" @click.stop="" @change="ddChange" v-model="slotProps.option.selectedVersion" :options="slotProps.option.items" optionLabel="pipelineVersion" placeholder="Select a version">
                  <template #option="slotProps">
                    <div v-tooltip.right="slotProps.option.description">{{ slotProps.option.pipelineVersion }}</div>
                  </template>
                </Dropdown>
              </div>
            </template>
          </Listbox>
        </div>
      </div>
      <div v-else-if="step === 2">
        <div class="field pt-4">
          <span class="p-float-label">
            <InputText id="pipelineDescription" type="text" :class="(validateString(runDescription) === false) ? 'p-invalid' : ''" v-model.trim="runDescription" />
            <label for="pipelineDescription">Pipeline Description</label>
          </span>
        </div>
        <DynamicRenderer
          :selectedLaunchablePipelineParameters="selectedLaunchablePipelineParameters"
          :selectedLaunchablePipelineModules="selectedLaunchablePipelineModules"
          :data="data"
          @input="updateData($event)"
        />
      </div>
      <!-- Final check -->
      <div v-else-if="step === 3">
        <TabMenu class="pt-5 my-tab-menu" :model="tabMenuItems" v-model:activeIndex="activeIndex" />
        <div v-if="activeIndex === 0 && !$store.state.paymentSystemDisabled">
          <div v-if="loadingCreditsData" class="flex justify-content-center">
            <img alt="BJSpinner" class="bjSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png" />
          </div>
          <div v-if="!loadingCreditsData" class="flex flex-column creditsBg">
            <span class="pt-3">Pipeline name: <b>{{ (creditsData.pipelineName) ? creditsData.pipelineName : 0 }}</b></span>
            <span class="pt-2">Num. of biosamples: <b>{{ (creditsData.numberOfBiosamples) ? creditsData.numberOfBiosamples : 0 }}</b></span>
            <span class="pt-2">Credits per biosample: <b>{{ (creditsData.tokensPerBiosample) ? creditsData.tokensPerBiosample : 0 }}</b></span>
            <span class="pt-2">Current credit balance: <b>{{ getCurrentCreditBalance() }}</b></span>
            <span class="pt-2">Balance after payment: <b>{{ (currentCreditBalance - creditsData.amount) ? currentCreditBalance - creditsData.amount : '' }}</b></span>
            <Divider />
            <span>Total amount to pay: <b>{{ (creditsData.amount) ? creditsData.amount : '' }}</b> credits</span>
            <span v-if="canLaunch === false" class="pt-2 noCreditsMsg">You do not have enough credits! Would you like to add more credits and continue with the pipeline?</span>
          </div>
        </div>
        <div v-if="(!$store.state.paymentSystemDisabled && activeIndex === 1) || ($store.state.paymentSystemDisabled && activeIndex === 0)">
          <DataTable :value="formatedData">
            <Column field="header" :bodyStyle="getBodyStyle()" header='Parameter Name' />
            <Column field="field" :bodyStyle="getBodyStyle()" header='Parameter Value' />
          </DataTable>
        </div>
        <div v-if="(!$store.state.paymentSystemDisabled && activeIndex === 2) || ($store.state.paymentSystemDisabled && activeIndex === 1)">
          <DataTable :value="listBiosamplesForTable">
            <Column field="name" :bodyStyle="getBodyStyle()" header='Biosample Name' />
          </DataTable>
        </div>
      </div>
      <div v-if="creating">
        <h3 class="field flex justify-content-center pb-3">Launching your pipeline. Please wait.</h3>
      </div>
    </div>
    <template #footer v-if="!creating">
      <div class="d-flex justify-content-between pt-1">
        <Button label="Cancel" icon="pi pi-times" class="p-button-secondary align-self-start" @click="hideDialog()" />
        <Button v-if="step > 1" label="Back" class="p-button-outline ml-2" icon="pi pi-arrow-left" @click="resetSelectedLaunchablePipeline()" />
        <Button v-if="step == 2" label="Check" class="p-button-outline ml-2" @click="step = 3" />
        <div v-if="step === 3" class="finalBtnDiv ml-2">
          <Button v-if="canLaunch === true" label="Launch Pipeline" class="p-button-outline" @click="preLaunchCheck" />
          <Button v-if="canLaunch === false" label="Add Credits" class="p-button-outline" @click="addCredits" />
        </div>
      </div>
    </template>
    <TransactionDialog v-if="!$store.state.paymentSystemDisabled" :organizationId="transactionDialogOrgId" :workspace="getProjectProp.workspace" @transactionClosed="createCreditsData(selectedLaunchablePipeline)" />
    <RequiredMetadataColumnMapping :missingRequiredColumns="missingRequiredColumns" :project="projectForMappingMissingRequiredColumns" :pipelineName="pipelineNameForLaunch" @resetMissingRequiredColumns="resetMissingRequiredColumns" @hideLaunchPipelineDialog="hideDialog" @triggerBiosampleLoadingAgain="this.$emit('triggerBiosampleLoadingAgain')" />
  </Dialog>
</template>

<script>
import { Auth, API, graphqlOperation } from 'aws-amplify';
import DynamicRenderer from '@/components/Pipeline/DynamicParameterComponents/DynamicRenderer.vue';
import * as queries from '@/graphql/queries';
import * as customQueries from '@/graphql/custom_queries';
import * as mutations from '@/graphql/mutations';
import TransactionDialog from '@/components/Organization/TransactionDialog.vue';
import RequiredMetadataColumnMapping from '@/components/Project/RequiredMetadataColumnMapping';
import {
  // eslint-disable-next-line no-unused-vars
  setPrecedence, createCreditsData, creditCheck, sendEBMessage, listItems, getNotificationEmails, getCurrentUserId, getCurrentUsersEmail, filterPrivateLaunchablePipelinesForWorkspaceAndOrganizationGroup, valueIsNullOrUndefined, validateString, getSpinnerSrc,
} from '@/utils';

export default {
  name: 'DynamicLaunchPipeline',
  emits: ['addedPipelineForFutureLaunch', 'dontLaunchPipelines', 'createdPipeline', 'resetLaunchPipelineData', 'triggerBiosampleLoadingAgain'],
  props: ['project', 'selectedBiosamples', 'passedLaunchablePipeline', 'startingStep'],
  components: {
    DynamicRenderer,
    TransactionDialog,
    RequiredMetadataColumnMapping,
  },
  data() {
    return {
      showing: false,
      loadingListBox: false,
      dialogWidth: { width: '20%' },
      allLaunchablePipelines: [],
      limit: 300,
      transactionDialogOrgId: null,
      creditsData: null,
      loadingCreditsData: false,
      creating: false,
      canLaunch: false,
      diff: null,
      currentCreditBalance: null,
      selectedLaunchablePipeline: null,
      selectedLaunchablePipelineType: null,
      lastSelectedLaunchablePipelineType: null,
      selectedLaunchablePipelineParameters: null,
      selectedLaunchablePipelineModules: null,
      mappedGroupedLaunchablePipelines: null,
      missingRequiredColumns: [],
      projectForMappingMissingRequiredColumns: null,
      runDescription: null,
      pipelineNameForLaunch: null,
      data: {}, // NF parameters
      step: 1,
      tabMenuItems: [
        // {
        //   label: 'Credits',
        // },
        {
          label: 'Parameters',
        },
        {
          label: 'Biosamples',
        },
      ],
      activeIndex: 0,
      tooltipMessage: 'The pipelines are grouped by their names. Select the desired pipeline version you wish to run. Hovering over a version gives a quick description.',
    };
  },
  async mounted() {
    if (!this.$store.state.paymentSystemDisabled) {
      this.tabMenuItems.unshift({
        label: 'Credits',
      });
    }
    this.handleWidth();
  },
  methods: {
    handleWidth() {
      window.addEventListener('resize', this.onResize);
      if (window.innerWidth <= 1850) {
        this.changeDialogWidth(50);
      }
    },
    async preLaunchCheck() {
      if (this.project === null) {
        console.log('Project is null in preLaunchCheck');
        return;
      }
      this.launchPipeline();
    },
    async launchPipeline(project = this.project) {
      if (!project) {
        console.log('Project is null in launchPipeline');
        return;
      }
      console.log('Launching pipeline with project: ', project);
      this.creating = true;
      this.step = null;
      const currCreds = await setPrecedence();
      if (currCreds === null) {
        console.error('Credentials are null');
      }
      console.log('this.selectedLaunchablePipeline :>> ', this.selectedLaunchablePipeline);
      const date = new Date();
      const userPromise = Auth.currentAuthenticatedUser();
      const tmpPreseqName = `${this.selectedLaunchablePipeline.pipelineName.toLowerCase()}_${this.selectedLaunchablePipeline.pipelineVersion}_${date.getFullYear() % 100}${date.getMonth() + 1}${date.getDate()}_${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;
      const pipelineStartDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}T${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}.${date.getMilliseconds()}Z`;
      let initiator = (await Auth.currentAuthenticatedUser()).attributes.email;
      if (initiator === undefined || initiator === null) {
        initiator = 'Unknown';
      }
      const pipelineVariables = {
        pipelineName: this.selectedLaunchablePipeline.pipelineName,
        projectId: project.id,
        projectPipelinesId: project.id,
        status: 'Submitted',
        pipelineVersion: this.selectedLaunchablePipeline.pipelineVersion,
        pipelineStarted: pipelineStartDate,
        pipelineOutputS3Path: tmpPreseqName,
        parameters: JSON.stringify(this.data),
        initiator,
        runDescription: this.runDescription,
        analysisLevel: 'secondary',
        adminGroups: [`WS/${this.$store.state.activeWorkspace}/Admin`, `ORG/${this.$store.state.activeOrganization}/Admin`],
        writeGroups: [`WS/${this.$store.state.activeWorkspace}/Admin`, `ORG/${this.$store.state.activeOrganization}/Admin`],
        readGroups: [`WS/${this.$store.state.activeWorkspace}/User`, `ORG/${this.$store.state.activeOrganization}/User`],
      };
      const pipeline = await API.graphql(graphqlOperation(mutations.createPipeline, { input: pipelineVariables }));
      this.$emit('createdPipeline', {
        id: project.id,
        pipeline: {
          id: pipeline.data.createPipeline.id,
          pipelineName: this.selectedLaunchablePipeline.pipelineName,
          status: 'Job Started',
        },
      });
      const requiredMetadataColumns = this.getRequiredMetadataColumnsIfExist(this.selectedLaunchablePipeline);
      const details = await this.getDetails(project, pipeline, tmpPreseqName, userPromise, requiredMetadataColumns);
      console.log('details :>> ', details);
      if (this.$store.state.futurePipelines) {
        this.$emit('addedPipelineForFutureLaunch', { Detail: details });
        this.$toast.add({
          severity: 'success',
          summary: 'Pipeline Added for Future Launch',
          detail: 'You have successfully added the pipeline for future launch. All of the pipelines will be launched when the project finishes downloading.',
          life: 3000,
        });
        this.hideDialog();
        this.$store.dispatch('toggleFuturePipelines');
        this.$store.dispatch('showImmediatePipelineLaunch');
      } else {
        try {
          sendEBMessage(details, details.organization_uuid, details.workspace_uuid, details.project_name_uuid, 'pipeline');
          this.$toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'You have successfully launched the pipeline!',
            life: 3000,
          });
        } catch (error) {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Launching pipeline failed!',
            life: 3000,
          });
          console.log('Error in creating pipeline');
          console.log(error);
        }
        console.log('Pipeline has been created and lauched successfully');
        // this.$store.dispatch('showLaunchPipeline');
        this.hideDialog();
      }
    },
    async getDetails(project, pipeline, tmpPreseqName, userPromise, requiredMetadataColumns) {
      const details = {
        bucket: process.env.VUE_APP_BUCKET,
        step: 'pipeline_trigger',
        status: 'KickoffPipelines',
        application_environment: process.env.VUE_APP_SLS_STAGE,
        analysis: { level: 'secondary', type_input_files: ['fastq'] },
        launchbale_pipeline_uuid: this.selectedLaunchablePipeline.id,
        pipeline: this.selectedLaunchablePipeline.pipelineName.toLowerCase(),
        pipeline_version: this.selectedLaunchablePipeline.pipelineVersion,
        organization_uuid: project.workspace.organization.id,
        organization_name: project.workspace.organization.organizationName,
        workspace_uuid: project.workspaceId,
        workspace_name: project.workspace.description,
        project_name_uuid: project.id,
        project_name: project.clientProjectName,
        credited_spent: (this.$store.state.paymentSystemDisabled) ? 0 : this.creditsData.amount,
        credit_before: (this.$store.state.paymentSystemDisabled) ? 0 : this.currentCreditBalance,
        credit_after: (this.$store.state.paymentSystemDisabled) ? 0 : this.currentCreditBalance - this.creditsData.amount,
        pipeline_uuid: pipeline.data.createPipeline.id,
        pipeline_run_s3: tmpPreseqName,
        nf_parameters: this.formatNFParamsForRunner(),
        paymentSystemDisabled: this.$store.state.paymentSystemDisabled,
      };
      [details.user_uuid, details.user_email, details.user_name] = await Promise.all([getCurrentUserId(), getNotificationEmails(), getCurrentUsersEmail()]);
      if (!valueIsNullOrUndefined(requiredMetadataColumns) && requiredMetadataColumns.length > 0) details.required_biosample_metadata_columns = requiredMetadataColumns;
      if ((this.selectedBiosamples !== undefined) && (this.selectedBiosamples.length !== 0) && (this.selectedBiosamples.length > 0)) {
        // eslint-disable-next-line consistent-return, array-callback-return
        const biosampleNames = [];
        this.selectedBiosamples.forEach((b) => {
          if (this.canAddBiosample(b)) {
            biosampleNames.push(b.biosampleName);
          }
        });
        details.biosample_names = biosampleNames;
        details.biosamples = this.makeBiosamplesObj(this.selectedBiosamples, project.biosampleMetadataColumns);
      } else {
        const biosampleNames = [];
        const biosamplesForProject = await this.getBiosamplesForProject();
        biosamplesForProject.forEach((b) => {
          if (this.canAddBiosample(b)) {
            biosampleNames.push(b.biosampleName);
          }
        });
        details.biosample_names = biosampleNames;
        details.biosamples = this.makeBiosamplesObj(biosamplesForProject, project.biosampleMetadataColumns);
      }
      return details;
    },
    async getBiosamplesForProject() {
      try {
        return await listItems(queries.biosamplesByProject, { projectId: this.project.id, limit: 300 });
      } catch (error) {
        console.error('Error in getBiosamplesForProject');
        console.error(error);
        return null;
      }
    },
    makeBiosamplesObj(passedBiosamplesArr, biosampleMetadataColumns) {
      try {
        const biosamplesArr = this.checkForMissingOrInvalidMetadataValuesAndRemap(passedBiosamplesArr, biosampleMetadataColumns);
        const mappedArray = [];
        biosamplesArr.forEach((bs) => {
          if (this.canAddBiosample(bs)) {
            const o = {
              biosample_name: bs.biosampleName,
              biosample_uuid: bs.id,
            };
            if (!valueIsNullOrUndefined(bs.metadata)) o.biosample_metadata = bs.metadata;
            mappedArray.push(o);
          }
        });
        return mappedArray;
      } catch (error) {
        console.error('Error in makeBiosamples');
        console.error(error);
        return null;
      }
    },
    // Optimization: Make each biosample mapping async and check/remap all of the in parallel
    checkForMissingOrInvalidMetadataValuesAndRemap(biosamplesArr, biosampleMetadataColumnsString) {
      try {
        if (valueIsNullOrUndefined(biosampleMetadataColumnsString)) return biosamplesArr;
        const biosampleMetadataColumnsObj = JSON.parse(biosampleMetadataColumnsString).columns;
        if (biosampleMetadataColumnsObj.length === 0) return biosamplesArr;
        const remappedBiosamples = biosamplesArr.map((biosample) => {
          const biosampleToChange = biosample;
          let biosampleMetadata = {};
          if (!valueIsNullOrUndefined(biosample.metadata)) biosampleMetadata = JSON.parse(biosample.metadata);
          biosampleMetadataColumnsObj.forEach((projectMetadataColumn) => {
            if (projectMetadataColumn.type === 'True/False' && !(projectMetadataColumn.name.toLowerCase() in biosampleMetadata)) biosampleMetadata[projectMetadataColumn.name.toLowerCase()] = false;
          });
          if (Object.keys(biosampleMetadata).length > 0) biosampleToChange.metadata = biosampleMetadata;
          return biosample;
        });
        return remappedBiosamples;
      } catch (error) {
        console.error(error);
        return biosamplesArr;
      }
    },
    getRequiredMetadataColumnsIfExist(selectedLaunchablePipeline) {
      try {
        if (valueIsNullOrUndefined(selectedLaunchablePipeline.requiredMetadataColumns)) return [];
        return JSON.parse(selectedLaunchablePipeline.requiredMetadataColumns).columns;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    getBodyStyle() {
      return 'text-align: center';
    },
    canAddBiosample(biosample) {
      return biosample.fastqValidationStatus === 'Pass' || biosample.fastqValidationStatus === 'Job Complete' || biosample.fastqValidationStatus === 'Empty FASTQs' || this.$store.state.futurePipelines;
    },
    formatNFParamsForRunner() {
      // eslint-disable-next-line no-unused-vars
      const formatedParams = Object.keys(this.data).map((key) => ({
        nf_parameter: key,
        value: this.data[key],
      }));
      return formatedParams;
      // return '[]';
    },
    async loadAllLaunchablePipelines() {
      try {
        this.loadingListBox = true;
        const privateLaunchablePipelinesPromise = listItems(customQueries.customListPrivateLaunchablePipelines, { limit: this.limit, filter: { analysisLevel: { eq: 'secondary' } } });
        const allLaunchablePipelines = await listItems(queries.listLaunchablePipelines, { limit: this.limit, filter: { analysisLevel: { eq: 'secondary' } } });
        allLaunchablePipelines.push(...filterPrivateLaunchablePipelinesForWorkspaceAndOrganizationGroup(await privateLaunchablePipelinesPromise, this.$route.params.workspaceId, this.$store.state.activeOrganization));
        const filteredLaunchablePipelines = this.filterLaunchablePipelines(allLaunchablePipelines);
        this.allLaunchablePipelines = [...filteredLaunchablePipelines];
        this.groupLaunchablePipelinesForListBox();
      } catch (error) {
        console.log('Error in loading all launchable pipelines');
        console.error(error);
      }
    },
    async createCreditsData(launchablePipeline) {
      this.loadingCreditsData = true;
      console.log('createCreditsData called!!!');
      this.creditsData = await createCreditsData(launchablePipeline, this.selectedBiosamples, this.project.id);
      const { canLaunch, diff, total } = await creditCheck(this.project.workspaceId, this.creditsData.amount);
      this.canLaunch = canLaunch;
      this.diff = diff;
      console.log('total in createCreditsData :>> ', total);
      if (this.$store.state.paymentSystemDisabled) {
        this.currentCreditBalance = 0;
      } else {
        this.currentCreditBalance = total;
      }
      this.loadingCreditsData = false;
    },
    getCurrentCreditBalance() {
      try {
        return this.currentCreditBalance;
      } catch (error) {
        console.error(error);
        return '';
      }
    },
    // Start of ListBoxFunctions
    groupLaunchablePipelinesForListBox() {
      try {
        // eslint-disable-next-line prefer-const
        let groupedLaunchablePipelines = this.groupBy([...this.allLaunchablePipelines], 'pipelineName');
        const keys = Object.keys(groupedLaunchablePipelines);
        const mappedGroupedLaunchablePipelines = keys.map((key) => {
          const o = {
            label: key,
            items: groupedLaunchablePipelines[key],
            selectedVersion: null,
          };
          return o;
        });
        mappedGroupedLaunchablePipelines.forEach((group) => {
          group.items.sort((a, b) => {
            if (a.pipelineVersion === 'develop' && b.pipelineVersion === 'develop') return 0;
            if (a.pipelineVersion === 'develop') return 1;
            if (b.pipelineVersion === 'develop') return -1;
            const aSplit = a.pipelineVersion.split('.');
            const bSplit = b.pipelineVersion.split('.');
            for (let i = 0; i <= 3; i += 1) {
              if (parseInt(aSplit[i], 10) < parseInt(bSplit[i], 10)) return 1;
              if (parseInt(aSplit[i], 10) > parseInt(bSplit[i], 10)) return -1;
            }
            return 0;
          });
          // eslint-disable-next-line no-param-reassign
          group.selectedVersion = group.items[0];
        });
        console.log('mappedGroupedLaunchablePipelines :>> ', mappedGroupedLaunchablePipelines);
        this.mappedGroupedLaunchablePipelines = mappedGroupedLaunchablePipelines;
        this.loadingListBox = false;
      } catch (error) {
        console.error('Error in grouping launchable pipelines for ListBox');
        console.error(error);
        if (this.loadingListBox) this.loadingListBox = false;
      }
    },
    // There are some invalid pipelines in the DB at the moment
    filterLaunchablePipelines(pipelines) {
      return pipelines.filter((pipeline) => (pipeline.parameters !== null) && (pipeline.parameters !== 'None') && (pipeline.parameters !== 'false'));
    },
    groupBy(array, key) {
      return array.reduce((rv, x) => {
        // eslint-disable-next-line no-param-reassign
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    // End of ListBoxFunctions
    generateDynamicParams() {
      try {
        if (this.selectedLaunchablePipeline) {
          const params = JSON.parse(this.selectedLaunchablePipeline.parameters);
          this.selectedLaunchablePipelineParameters = params.parameters;
          this.selectedLaunchablePipelineModules = params.modules;
          console.log('this.selectedLaunchablePipelineParameters :>> ', this.selectedLaunchablePipelineParameters);
          console.log('this.selectedLaunchablePipelineModules :>> ', this.selectedLaunchablePipelineModules);
          this.step = Math.max(2, this.step);
        } else {
          console.error('No selected launchable pipeline');
        }
      } catch (error) {
        console.error('Error generating dynamic parameters');
        console.error(error);
      }
    },
    // eslint-disable-next-line camelcase
    updateData({ nf_command, value }) {
      // eslint-disable-next-line camelcase
      this.data[nf_command] = value;
    },
    fixParamType(pipelines) {
      console.log('Fixing pipelines');
      pipelines.forEach((pipeline) => {
        const params = JSON.parse(pipeline.parameters);
        params.parameters = params.parameters.map((param) => {
          if (param.type === 'Boolean') {
            // eslint-disable-next-line no-param-reassign
            param.type = 'Check';
            return param;
          }
          if (param.options.length > 0) {
            // eslint-disable-next-line no-param-reassign
            param.type = 'Select';
            return param;
          }
          if (param.options.length === 0) {
            // eslint-disable-next-line no-param-reassign
            param.type = 'Input';
            return param;
          }
          return 'Error';
        });
        params.modules.map((module) => {
          if (module.skipping_options.type === 'Boolean') {
            // eslint-disable-next-line no-param-reassign
            module.skipping_options.type = 'Check';
            return module;
          }
          return 'Error';
        });
        const jsonParams = JSON.stringify(params);
        // eslint-disable-next-line no-param-reassign
        pipeline.parameters = jsonParams;
      });
      return pipelines;
    },
    resetSelectedLaunchablePipeline() {
      switch (this.step) {
        case 2:
          this.data = {};
          this.selectedLaunchablePipeline = null;
          this.step = 1;
          this.changeDialogWidth(20);
          break;
        case 3:
          this.step = 2;
          break;
        default:
          break;
      }
    },
    addCredits() {
      try {
        this.transactionDialogOrgId = this.project.workspace.organizationId;
        this.$store.dispatch('showNewTransaction');
      } catch (error) {
        console.error(error);
      }
    },
    changeDialogWidth(width) {
      this.dialogWidth.width = `${width}%`;
    },
    listBoxClick() {
      try {
        if (valueIsNullOrUndefined(this.selectedLaunchablePipelineType)) {
          this.selectedLaunchablePipelineType = this.lastSelectedLaunchablePipelineType;
        } else {
          this.lastSelectedLaunchablePipelineType = this.selectedLaunchablePipelineType;
        }
        this.selectedLaunchablePipeline = this.selectedLaunchablePipelineType.selectedVersion;
      } catch (error) {
        console.error(error);
      }
    },
    ddChange(event) {
      try {
        if (valueIsNullOrUndefined(this.selectedLaunchablePipelineType)) {
          this.selectedLaunchablePipeline = event.value;
        } else {
          this.selectedLaunchablePipeline = this.selectedLaunchablePipelineType.selectedVersion;
        }
      } catch (error) {
        console.error(error);
      }
    },
    hideDialog() {
      console.log('Hiding launch dialog');
      this.data = {};
      this.step = 1;
      this.selectedLaunchablePipeline = null;
      this.selectedLaunchablePipelineType = null;
      this.lastSelectedLaunchablePipelineType = null;
      this.transactionDialogOrgId = null;
      this.creditsData = null;
      this.loadingCreditsData = false;
      this.runDescription = null;
      this.canLaunch = false;
      this.diff = null;
      this.currentCreditBalance = null;
      this.$store.dispatch('setShowLaunchPipeline', false);
      this.$emit('resetLaunchPipelineData');
      this.creating = false;
      this.showing = false;
    },
    validateString(string) {
      return validateString(string);
    },
    checkForMissingRequiredMetadata(launchablePipeline, project) {
      const missingRequiredColumns = this.checkForRequiredParameters(launchablePipeline, project);
      if (missingRequiredColumns.length > 0) {
        this.pipelineNameForLaunch = launchablePipeline.pipelineName;
        this.missingRequiredColumns = missingRequiredColumns;
        this.projectForMappingMissingRequiredColumns = project;
        this.$store.dispatch('setShowingMapRequiredMetadataColumns', true);
      }
    },
    checkForRequiredParameters(selectedLaunchablePipeline, project) {
      try {
        if (valueIsNullOrUndefined(selectedLaunchablePipeline.requiredMetadataColumns)) return [];
        const requiredColumns = JSON.parse(selectedLaunchablePipeline.requiredMetadataColumns).columns;
        const projectMetadataColumnsObj = JSON.parse(project.biosampleMetadataColumns);
        if (valueIsNullOrUndefined(projectMetadataColumnsObj) || !('columns' in projectMetadataColumnsObj) || valueIsNullOrUndefined(projectMetadataColumnsObj.columns)) return requiredColumns;
        const projectMetadataColumns = projectMetadataColumnsObj.columns;
        return this.requiredMetadataCheck(requiredColumns, projectMetadataColumns);
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    requiredMetadataCheck(requiredColumns, projectMetadataColumns) {
      return requiredColumns.filter((requiredColumn) => !projectMetadataColumns.some((projectMetadataCol) => projectMetadataCol.name.toLowerCase() === requiredColumn.name.toLowerCase()));
    },
    resetMissingRequiredColumns() {
      this.missingRequiredColumns = [];
      this.pipelineNameForLaunch = '';
      this.projectForMappingMissingRequiredColumns = null;
    },
    getSpinnerSrc() {
      return getSpinnerSrc();
    },
    onResize() {
      if (window.innerWidth <= 1850) {
        this.changeDialogWidth(40);
      } else {
        this.changeDialogWidth(20);
      }
    },
  },
  computed: {
    formatedData() {
      return Object.keys(this.data).map((key) => ({ header: key, field: this.data[key] }));
    },
    getProjectProp() {
      return this.project;
    },
    listBiosamplesForTable() {
      // No biosamples selected, get them all from the project
      if ((this.selectedBiosamples === null || this.selectedBiosamples === undefined) || this.selectedBiosamples.length === 0) {
        if (this.project !== null) {
          if ('items' in this.project.biosamples) {
            const biosampleNames = this.project.biosamples.items.map((biosample) => ({ name: biosample.biosampleName }));
            return biosampleNames;
          }
          const biosampleNames = this.project.biosamples.map((biosample) => ({ name: biosample.biosampleName }));
          return biosampleNames;
        }
        console.error('Project is null in listBiosamplesFroTable');
        return [];
      }
      // There are some selected biosamples
      const biosampleNames = this.selectedBiosamples.map((biosample) => ({ name: biosample.biosampleName }));
      return biosampleNames;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingLaunchPipeline': async function () {
      console.log('Change');
      if (this.showing) {
        console.log('Launch pipeline was already up');
        return;
      }
      this.showing = this.$store.state.showingLaunchPipeline;
      if (this.showing) {
        await this.loadAllLaunchablePipelines();
      }
    },
    project: {
      async handler() {
        console.log('Project changed');
        console.log('this.project :>> ', this.project);
        if (this.showing) {
          console.log('Launch pipeline was already up');
          return;
        }
        if (!valueIsNullOrUndefined(this.project)) {
          this.showing = true;
          console.log('selectedLaunchablePipeline in project watcher :>> ', this.selectedLaunchablePipeline);
          if (valueIsNullOrUndefined(this.passedLaunchablePipeline)) {
            await this.loadAllLaunchablePipelines();
          } else {
            this.selectedLaunchablePipeline = this.passedLaunchablePipeline;
          }
        }
      },
    },
    passedLaunchablePipeline() {
      console.log('passedLaunchablePipeline watcher triggered');
      console.log('this.passedLaunchablePipeline :>> ', this.passedLaunchablePipeline);
      if (!valueIsNullOrUndefined(this.passedLaunchablePipeline)) {
        this.selectedLaunchablePipeline = this.passedLaunchablePipeline;
      }
    },
    startingStep() {
      if (!valueIsNullOrUndefined(this.passedLaunchablePipeline)) {
        console.log('this.startingStep :>> ', this.startingStep);
        this.step = this.startingStep;
      }
    },
    selectedLaunchablePipeline(value) {
      if (value) {
        console.log('selectedLaunchablePipeline changed in watcher');
        console.log('selectedLaunchablePipeline :>> ', value);
        if (!valueIsNullOrUndefined(this.project) && !valueIsNullOrUndefined(this.selectedLaunchablePipeline)) this.showing = true;
        this.changeDialogWidth(35);
        this.checkForMissingRequiredMetadata(value, this.project);
        this.generateDynamicParams();
        if (!this.$store.state.paymentSystemDisabled) {
          this.createCreditsData(value);
        } else {
          this.canLaunch = true;
        }
      }
    },
    // step: {
    //   handler() {
    //     if (this.step === 3) {
    //       console.log('Third step caught');
    //       if (!this.canLaunch) {
    //         console.log('Not enough money. Missing ', this.diff);
    //         this.transactionDialogOrgId = this.project.workspace.organizationId;
    //         console.log('this.transactionDialogOrgId :>> ', this.transactionDialogOrgId);
    //         this.$store.dispatch('showNewTransaction');
    //       }
    //     }
    //   },
    // },
  },
};
</script>

<style lang="scss" scoped>
// .p-listbox{
//   background-color: red;
//   justify-content: center;
// }
.p-listbox-list-wrapper .p-listbox .p-component {
  justify-content: center;
}

::v-deep(.p-listbox .p-listbox-list .p-listbox-item-group) {
  // color: red;
  background: rgba(168, 167, 171,0.5);
}

.LbLabelContainer {
  position: relative;
  display: flex;
  justify-content: right;
}

::v-deep(.p-tabmenu .p-tabmenu-nav) {
  background: rgba(121, 186, 252, 0.4) !important;
}

::v-deep(.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link) {
  border: rgba(121, 186, 252, 0.4);
}

.creditsBg {
  height: 100%;
  width: 100%;
}

.noCreditsMsg {
  color: red;
}

.finalBtnDiv {
  display:inline-block;
}

.versionDD {
  width: 110px;
}
</style>
