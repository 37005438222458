<template>
  <!-- <img alt="BJSpinner" class="bjSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png"/> -->
  <!-- <Button label="test" @click="test" /> -->
  <!-- <div class="dial-in-projects">
    <BJDial />
  </div> -->
  <div v-if="this.$store.state.activeWorkspace">
    <DataTable
      :value="projects"
      v-model:selection="checkedProjects"
      paginator
      :totalRecords="totalRecords[$store.state.bioskrybProduct]"
      v-model:rows="rows"
      styleClass="myTable"
      v-model:filters="filters"
      ref="dt"
      filterDisplay="row"
      :globalFilterFields="['clientProjectName']"
      responsiveLayout="scroll"
      paginatorTemplate="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50]"
      dataKey="id"
      :rowHover="true"
      :loading="loading"
      selectionMode="single"
      @rowSelect="onProjectSelect"
      :rowClass="rowClass"
      lazy
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
    >
      <template #header>
        <div class="flex justify-content-between flex-column sm:flex-row">
          <div>
            <i class="ml-3 pi pi-question-circle table-legend-circle" v-tooltip.right="getLegendTooltip()" />
          </div>
          <Button
            type="button"
            label="New Project"
            class="p-button mb-2"
            @click="openNewProject"
            v-if="$store.state.precedenceLevel < 4"
          />
        </div>
      </template>
      <template #empty>
        No projects found.
      </template>
      <template #loading>
        Loading projects... Please wait.
      </template>
      <!-- <Column selectionMode="single"></Column> -->
      <Column
        field="clientProjectName"
        header="Project"
        headerClass="custom-header"
        :bodyStyle="getBodyStyle()"
        filterMatchMode="startsWith"
        ref="clientProjectName"
        :sortable="true">
        <template #filter="{ filterModel, filterCallback }">
          <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="Search by project name" />
        </template>
      </Column>
      <Column header="Description" :bodyStyle="getDescriptionBodyStyle()">
        <template #body="{ data }">
          {{ getDescription(data.description) }}
        </template>
      </Column>
      <Column header="Size" field="size" :bodyStyle="getBodyStyle()" :sortable="true">
        <template #body="{ data }">
          {{ getSize(data.size) }}
        </template>
      </Column>
      <Column header="Number of Biosamples" headerClass="custom-header" :bodyStyle="getBodyStyle()">
        <template #body="{ data }">
          <!-- <ProgressSpinner style="width:30px;height:30px" v-if="(data.biosamples.items.length === 100) && data.biosamples.nextToken"/> -->
          <img alt="BJSpinner" class="bjSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png" style="width:30px;height:30px" v-if="data.biosamples.nextToken !== null" />
          <div v-else>
            {{data.biosamples.items.length}}
          </div>
        </template>
      </Column>
      <!-- <Column header="Status" headerClass="custom-header" :bodyStyle="getBodyStyle()">
        <template #body="{ data }">
          <span :class="'customer-badge ' + statusClass(data.status)">{{data.status}}</span>
        </template>
      </Column> -->
      <Column headerStyle="width:4rem">
        <template #body="{ data }">
          <BJDial v-if="shouldCreateSpeedDial(data.status)" :label="'Visualize'" :options="getVisualizationOptions(data)" :disabled="shouldVizButtonBeDisabled(data)" />
        </template>
      </Column>
      <Column headerStyle="width:4rem">
        <template #body="{ data }">
          <div v-if="shouldCreateSpeedDial(data.status)" class="launch-pipeline-speed-dial-div">
            <BJDial :label="'Launch Pipeline'" :options="getLaunchablePipelineOptions(data)" :disabled="shouldPipelineButtonBeDisabled(data)" :passedInitialRadius="24" :passedBGCircleOffset="4.5" />
          </div>
        </template>
      </Column>
      <Column header="Pipelines" :bodyStyle="getBodyStyle()">
        <template #body="{ data }">
          <Tag v-for="(item, index) of [...new Set(data.pipelines.items.map(item => item.pipelineName))]" :key="index" class="tag-col m-1" :value="item" />
        </template>
      </Column>
      <Column v-if="this.$store.state.bioskrybProduct === 'ResolveOME'" field="projectType" header="Project Type" headerClass="custom-header" :bodyStyle="getBodyStyle()" :sortable="true" />
      <Column field="lotId" header="BioSkryb Product Lot ID" headerClass="custom-header" :bodyStyle="getBodyStyle()" filterMatchMode="startsWith" ref="lotId" :sortable="true">
        <template #filter="{ filterModel, filterCallback }">
          <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="Search by LotId" />
        </template>
      </Column>
      <Column field="initiator" header="Initiator" headerClass="custom-header" :bodyStyle="getBodyStyle()" filterMatchMode="startsWith" ref="initiator" :sortable="true">
        <template #filter="{ filterModel, filterCallback }">
          <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="Search by initiator" />
        </template>
      </Column>
      <Column v-if="this.$store.state.bioskrybProduct === 'ResolveOME'" header="Associated Project" :bodyStyle="getBodyStyle()" :sortable="true">
        <template #body="{ data }">
          <Tag v-if="data.associatedProjectId !== null" class="bg-cyan-400 m-1" :value="data.associatedProject.clientProjectName" />
        </template>
      </Column>
      <Column header="Date Created" field="created" :bodyStyle="getBodyStyle()" :sortable="true">
        <template #body="{ data }">
          {{ $filters.formatDate(data.created) }}
        </template>
      </Column>
      <Column headerStyle="width:4rem">
        <template #body="{ data }">
          <Button icon="pi pi-ellipsis-v" @click="projectActionClick(data, $event)" :disabled="(data.status === 'Job Started') && ($store.state.precedenceLevel > 3)" />
          <Menu :ref="'project-menu-' + data.id" :model="projectActionItems(data)" :popup="true" />
        </template>
      </Column>
    </DataTable>
    <Dialog v-model:visible="showingProjectDescriptionDialog" modal header="Description" :style="{ width: '50vw' }">
      <p class="description-p">
        {{ projectDescriptionForShowing }}
      </p>
    </Dialog>
    <NewProjectDialog @addProjectToTable="addProjectToTable" />
    <TransferProjectDialog :projectForTransfer="projectForTransfer" />
    <DeleteProjectDialog :projectsForDelete="projectsForDelete" />
    <DynamicLaunchPipelineDialog v-if="!$store.state.futurePipelines" :project="projectForPipeline" :passedLaunchablePipeline="pipelineTypeToLaunch" :startingStep="startingLaunchPipelineStep" @createdPipeline="updateProjectInProjectTable" @resetLaunchPipelineData="resetLaunchPipelineData" />
    <LinkProjectsDialog
      :projectForLinking="projectForLinking"
      :allProjects="projects" />
    <UpdateProjectDialog v-model:projectForUpdate="projectForUpdate" @updateProjectInProjectTable="updateProjectInProjectTable" />
    <ExportDialog v-if="projectForExport !== null" :project="projectForExport" />
  </div>
</template>

<script>
// When an entity changes, that change should be picked up by the graphQL subscription.
import { API, graphqlOperation } from 'aws-amplify';
import * as customQueries from '@/graphql/custom_queries';
import NewProjectDialog from '@/components/Project/NewProjectDialog.vue';
import TransferProjectDialog from '@/components/Project/TransferProjectDialog.vue';
import DeleteProjectDialog from '@/components/Project/DeleteProjectDialog.vue';
import DynamicLaunchPipelineDialog from '@/components/Pipeline/DynamicLaunchPipelineDialog.vue';
import LinkProjectsDialog from '@/components/Project/LinkProjectsDialog.vue';
import ExportDialog from '@/components/Utils/ExportDialog.vue';
import UpdateProjectDialog from '@/components/Project/UpdateProjectDialog.vue';
import BJDial from '@/components/Utils/BJDial';
import RequiredMetadataColumnMapping from '@/components/Project/RequiredMetadataColumnMapping';
import * as customSubscriptions from '@/graphql/customSubscriptions';
import {
  sendEBMessage, getSize, listItems, reformatFilter, sortObj, groupLaunchablePipelinesForListBox,
} from '@/utils';
import { valueIsNullOrUndefined, checkIfVisualizationShouldBeDisabled } from '../../utils';

export default {
  components: {
    NewProjectDialog,
    TransferProjectDialog,
    DeleteProjectDialog,
    DynamicLaunchPipelineDialog,
    LinkProjectsDialog,
    ExportDialog,
    UpdateProjectDialog,
    BJDial,
    // eslint-disable-next-line vue/no-unused-components
    RequiredMetadataColumnMapping,
  },
  data() {
    return {
      loading: false,
      projects: [],
      visualizations: [],
      launchablePipelineOptionsForSpinner: [],
      checkedProjects: [], // Left over from the time multiple projects could be selected for deletion
      limit: 300,
      paginatedBiosamplesLimit: 1000,
      startingLaunchPipelineStep: 1,
      pipelineTypeToLaunch: null,
      showingProjectDescriptionDialog: false,
      noLimitOnPullingBiosamples: false,
      projectDescriptionForShowing: '',
      projectForTransfer: null,
      projectsForDelete: null,
      projectForPipeline: null,
      projectUpdateSubscription: null,
      projectForLinking: null,
      projectForExport: null,
      projectForUpdate: null,
      projectForMappingMissingRequiredColumns: null,
      missingRequiredColumns: [],
      pipelineNameForLaunch: '',
      totalRecords: {
        ResolveDNA: 0,
        ResolveOME: 0,
      },
      i: 0,
      user: false,
      bioskrybAdmin: false,
      projectToken: '',
      rows: 25,
      filters: {
        clientProjectName: { value: '', matchMode: 'contains' },
        lotId: { value: '', matchMode: 'contains' },
        initiator: { value: '', matchMode: 'contains' },
      },
      sortMarker: {
        field: 'created',
        direction: 'DESC',
      },
      allProjects: { // When adding new type, don't forget to add a new array for projects of that type
        ResolveDNA: [],
        ResolveOME: [],
      },
      firstLoadTokens: {
        ResolveDNA: null,
        ResolveOME: null,
      },
      dialogVisible: false,
    };
  },
  async mounted() {
    this.projectStatusSubscription();
    this.loadVisualizations();
    this.loadLaunchablePipelines();
    if (this.$store.state.activeWorkspace && (this.$store.state.bioskrybProduct === null)) {
      if (this.$router.currentRoute.value.path.endsWith('/OME')) {
        this.$store.dispatch('setBioskrybProduct', 'ResolveOME');
        return;
      }
      this.$store.dispatch('setBioskrybProduct', 'ResolveDNA');
    }
    if (!this.loading && this.projects.length === 0) {
      await this.loadFirst();
    }
  },
  unmounted() {
    // Do not remove. Part of subscription
    try {
      this.projectUpdateSubscription.unsubscribe();
    } catch (error) {
      console.log('Project unsubscribe failed');
      console.log(error);
    }
  },
  methods: {
    // eslint-disable-next-line consistent-return
    async test() {
      console.log('---------TEST---------');
      console.log('this.projects :>> ', this.projects);
      console.log('---------END TEST---------');
    },
    async loadFirst() {
      try {
        this.loading = true;
        console.log('Load first called');
        console.log('this.$store.state.bioskrybProduct :>> ', this.$store.state.bioskrybProduct);
        await this.lazyLoadProjects();
        this.loading = false;
      } catch (error) {
        console.error('Error in LoadFirst');
        console.error(error);
      }
    },
    async lazyLoadProjects(desiredStart = 0, desiredEnd = this.rows, finishLoading = true) {
      this.loading = true;
      if (!this.checkIfFiltersAreEmpty(this.filters) || this.totalRecords[this.$store.state.bioskrybProduct] === 0) await this.getTotalNumberOfProjects();
      while (this.allProjects[this.$store.state.bioskrybProduct].slice(desiredStart, desiredEnd).length < ((this.noLimitOnPullingBiosamples) ? this.totalRecords[this.$store.state.bioskrybProduct] : this.rows)) {
        if (this.allProjects[this.$store.state.bioskrybProduct].length === this.totalRecords[this.$store.state.bioskrybProduct]) break;
        const projects = await this.getProjects(this.firstLoadTokens[this.$store.state.bioskrybProduct]);
        this.allProjects[this.$store.state.bioskrybProduct].push(...projects);
        if (this.firstLoadTokens[this.$store.state.bioskrybProduct] === null || this.firstLoadTokens[this.$store.state.bioskrybProduct] === undefined) break;
      }
      this.projects = this.allProjects[this.$store.state.bioskrybProduct].slice(desiredStart, desiredEnd);
      console.log('this.projects :>> ', this.projects);
      this.noLimitOnPullingBiosamples = false;
      this.pullBiosampleNumbers();
      if (finishLoading) this.loading = false;
    },
    checkIfFiltersAreEmpty(filtersObj) {
      for (const value of Object.values(filtersObj)) {
        if (value.value !== '') return false;
      }
      return true;
    },
    async getProjects(passedNextToken) {
      const params = {
        limit: (this.noLimitOnPullingBiosamples) ? 1000 : this.rows + 10, ...((passedNextToken !== null && passedNextToken !== undefined) && { nextToken: passedNextToken }), biosamplesLimit: this.paginatedBiosamplesLimit, workspaceId: this.$store.state.activeWorkspace, filter: reformatFilter(this.filters, { assayName: { eq: this.$store.state.bioskrybProduct } }), sortDirection: 'DESC',
      };
      const res = await API.graphql(graphqlOperation(customQueries.projectsByWorkspaceForProjectTableBiosamplesPagination, params));
      const projects = res.data.projectsByWorkspace.items;
      const nextToken = res.data.projectsByWorkspace.nextToken;
      this.firstLoadTokens[this.$store.state.bioskrybProduct] = nextToken;
      return projects;
    },
    // Paginator requires that the total number of biosamples is known beforehand. Just fetch the ids of each project and count them
    async getTotalNumberOfProjects() {
      const params = {
        workspaceId: this.$store.state.activeWorkspace, filter: reformatFilter(this.filters, { assayName: { eq: this.$store.state.bioskrybProduct } }), sortDirection: 'DESC',
      };
      const res = await listItems(customQueries.projectsByWorkspaceJustIds, params);
      this.totalRecords[this.$store.state.bioskrybProduct] = res.length;
    },
    async pullBiosampleNumbers() {
      this.projects.forEach(async (project) => {
        if (project.biosamples.nextToken !== null && project.biosamples.nextToken !== undefined) {
          const allBiosamples = project.biosamples.items;
          const response1 = await API.graphql(graphqlOperation(customQueries.getProjectForBiosamples, {
            id: project.id,
            biosamplesLimit: this.paginatedBiosamplesLimit,
            biosamplesNextToken: project.biosamples.nextToken,
          }));
          allBiosamples.push(...response1.data.getProject.biosamples.items);
          if (response1.data.getProject.biosamples.nextToken) {
            let biosampleToken = response1.data.getProject.biosamples.nextToken;
            while (biosampleToken) {
              const response2 = await API.graphql(graphqlOperation(customQueries.getProjectForBiosamples, {
                id: project.id, biosamplesLimit: this.paginatedBiosamplesLimit, biosamplesNextToken: biosampleToken,
              }));
              biosampleToken = response2.data.getProject.biosamples.nextToken;
              allBiosamples.push(...response2.data.getProject.biosamples.items);
            }
          }
          // eslint-disable-next-line no-param-reassign
          project.biosamples.items = allBiosamples;
          // eslint-disable-next-line no-param-reassign
          project.biosamples.nextToken = null; // To stop the loader and show the value
        }
      });
    },
    async onPage(event) {
      await this.lazyLoadProjects(event.first, event.first + this.rows);
    },
    async onSort(event) {
      this.noLimitOnPullingBiosamples = true;
      this.firstLoadTokens[this.$store.state.bioskrybProduct] = null;
      this.allProjects[this.$store.state.bioskrybProduct] = [];
      this.projects = [];
      await this.lazyLoadProjects(0, this.totalRecords[this.$store.state.bioskrybProduct], false);
      this.projects = sortObj(this.projects, event.sortField);
      if (event.sortOrder === -1) this.projects = this.projects.reverse();
      this.loading = false;
    },
    // eslint-disable-next-line no-unused-vars
    onFilter(event) {
      this.allProjects[this.$store.state.bioskrybProduct] = [];
      for (const value of Object.values(this.filters)) {
        if (value.value !== null && value.value !== undefined && value.value !== '') {
          this.noLimitOnPullingBiosamples = true;
          break;
        }
      }
      this.firstLoadTokens[this.$store.state.bioskrybProduct] = null;
      // this.totalRecords[this.$store.state.bioskrybProduct] = 0;
      this.lazyLoadProjects();
    },
    onProjectSelect(event) {
      if (event.data.status !== 'Job Started' && event.data.status !== 'Transfer Started') {
        this.$router.push({ path: `/workspace/${this.$store.state.activeWorkspace}/project/${event.data.id}` });
      }
    },
    addProjectToTable(project) {
      this.allProjects[this.$store.state.bioskrybProduct].unshift(project);
      this.projects.unshift(project);
    },
    getSize(size) {
      return getSize(size);
    },
    getDescription(description) {
      return this.getFirstNCharacters(description, 150);
    },
    getFirstNCharacters(string, n) {
      try {
        if (string === null || string === undefined) return '';
        if (string.length > n) return `${string.substring(0, n)}...`;
        return string;
      } catch (error) {
        console.error(error);
        return '';
      }
    },
    async reloadTable() {
      await this.loadFirst();
    },
    getBodyStyle() {
      return 'text-align: center';
    },
    getDescriptionBodyStyle() {
      return `${this.getBodyStyle()}; word-wrap: break-word; width: 20vw; max-width: 20vw; `;
    },
    projectActionClick(project, event) {
      this.$refs[`project-menu-${project.id}`].toggle(event);
    },
    rowClass(data) {
      switch (data.status) {
        case 'Job Started': case 'Add Biosamples Job Started': case 'Transfer Started': case 'Submitted': case 'Renaming':
          return 'row-grayout';
        case 'Job Failed': case 'Add Biosamples Job Failed': case 'Add Biosamples Job Canceling Failed': case 'Transfer Failed':
          return 'row-red';
        case 'Job Canceled': case 'Add Biosamples Job Canceled':
          return 'row-yellow';
        default:
          return null;
      }
    },
    // Filter the available options based on the project status. Returns a list of objects which represent available options
    projectActionItems(project) {
      const detail = {
        label: 'View Detail',
        command: () => {
          this.$router.push({ path: `/workspace/${this.$store.state.activeWorkspace}/project/${project.id}` });
        },
      };
      const addBiosamples = {
        label: 'Add Biosamples',
        command: () => {
          this.$store.dispatch('setAddingNewBiosamples', true);
          this.$store.dispatch('showNewProject');
          this.$store.dispatch('setSelectedProject', project);
        },
      };
      const launch = {
        label: 'Launch Pipeline',
        command: () => {
          this.projectForPipeline = project;
          this.$store.dispatch('setShowLaunchPipeline', true);
        },
      };
      const openInNewTab = {
        label: 'Open in new tab',
        command: () => {
          const route = `${window.location.origin}/#/workspace/${this.$store.state.activeWorkspace}/project/${project.id}/biosamples`;
          window.open(route, '_blank');
        },
      };
      const link = {
        label: 'Link project',
        command: () => {
          if (project.projectType === null) {
            this.$toast.add({
              severity: 'warn',
              summary: 'No project type',
              detail: 'The project you want to link does not have a type(DNA/RNA)',
              life: 5000,
            });
            return;
          }
          this.projectForLinking = project;
          this.$store.dispatch('showLinkProjects');
        },
      };
      const unlink = {
        label: 'Unlink project',
        command: () => {
          if (project.associatedProjectId === null) {
            this.$toast.add({
              severity: 'warn',
              summary: 'No associated project type',
              detail: 'The project you want to unlink is not linked',
              life: 5000,
            });
            return;
          }
          this.projectForLinking = project;
          this.$store.dispatch('toggleUnlinkProjects');
          this.$store.dispatch('showLinkProjects');
        },
      };
      // eslint-disable-next-line no-unused-vars
      const transfer = {
        label: 'Transfer Project',
        command: () => {
          console.log('transfering project - ', project.id);
          this.projectForTransfer = project;
          this.$store.dispatch('showTransferProject');
        },
      };
      const deleteProject = {
        label: 'Delete',
        command: () => {
          console.log('delete - ', project.id);
          this.projectsForDelete = [project];
          this.$store.dispatch('showDeleteProject');
        },
      };
      const showDescription = {
        label: 'Show Description',
        command: () => {
          if (project.description !== null && project.description !== undefined) {
            this.projectDescriptionForShowing = project.description;
          } else {
            this.projectDescriptionForShowing = 'No description!';
          }
          this.showingProjectDescriptionDialog = true;
        },
      };
      const cancelProject = {
        label: 'Cancel',
        command: () => {
          console.log('cancel - ', project.id);
          this.cancel(project.id, project.workspaceId, project.workspace.organizationId);
        },
      };
      const exportProject = {
        label: 'Export',
        command: () => {
          console.log('Exporting project: ', project);
          this.projectForExport = project;
          this.$store.dispatch('setShowingExport', true);
        },
      };
      const updateProject = {
        label: 'Update',
        command: () => {
          this.projectForUpdate = project;
          this.$store.dispatch('setShowingUpdateProject', true);
        },
      };

      // Project status and precedence level to make the code nicer
      switch (`${project.status}-${this.$store.state.precedenceLevel}`) {
        case 'Job Complete-1': case 'Job Complete-2': case 'Job Complete-3': case 'Add biosamples Job Complete-1': case 'Add biosamples Job Complete-2': case 'Add biosamples Job Complete-3': case 'Transfer Complete-1': case 'Transfer Complete-2': case 'Transfer Complete-3':
          if (this.$store.state.bioskrybProduct === 'ResolveDNA') {
            return [detail, showDescription, openInNewTab, launch, addBiosamples, transfer, updateProject, exportProject, deleteProject];
          }
          return [detail, showDescription, openInNewTab, launch, addBiosamples, transfer, (project.associatedProjectId === null) ? link : unlink, updateProject, exportProject, deleteProject];
        case 'Job Complete-4': case 'Transfer Complete-4':
          if (this.$store.state.bioskrybProduct === 'ResolveDNA') {
            return [detail, showDescription, openInNewTab, launch];
          }
          return [detail, showDescription, openInNewTab, (project.associatedProjectId === null) ? link : unlink, launch];
        case 'Job Failed-1': case 'Job Failed-2': case 'Job Failed-3': case 'Transfer Failed-1': case 'Transfer Failed-2': case 'Transfer Failed-3':
          return [detail, showDescription, openInNewTab, updateProject, deleteProject];
        case 'Job Failed-4': case 'Transfer Failed-4':
          return [detail, showDescription];
        case 'No Data-1': case 'No Data-2': case 'No Data-3':
          return [detail, showDescription, openInNewTab, addBiosamples, updateProject, deleteProject];
        case 'No Data-4':
          return [detail, showDescription, openInNewTab];
        case 'Job Started-1': case 'Transfer Started-1': case 'Renaming-1':
          return [deleteProject, updateProject, cancelProject];
        case 'Job Started-2': case 'Job Started-3': case 'Transfer Started-2': case 'Transfer Started-3': case 'Renaming-2': case 'Renaming-3':
          return [cancelProject];
        default:
          if (this.$store.state.bioskrybProduct === 'ResolveDNA') {
            return [detail, showDescription, openInNewTab, updateProject, deleteProject];
          }
          return [detail, showDescription, openInNewTab, (project.associatedProjectId === null) ? link : unlink, updateProject, deleteProject];
      }
    },
    statusClass(status) {
      return status.toLowerCase().replaceAll(' ', '-');
    },
    openNewProject() {
      this.$store.dispatch('showNewProject');
    },
    deleteProjects() {
      this.projectsForDelete = this.checkedProjects;
      this.$store.dispatch('showDeleteProject');
    },
    isEqual(...objects) {
      return objects.every((obj) => JSON.stringify(obj) === JSON.stringify(objects[0]));
    },
    projectStatusSubscription() {
      // Do not remove. Part of subscription
      this.projectUpdateSubscription = API.graphql(
        graphqlOperation(customSubscriptions.onUpdateProject),
      ).subscribe({
        next: ({ value }) => {
          console.log({ value });
          const changedProjectIndex = this.projects.findIndex((project) => project.id === value.data.onUpdateProject.id);
          if (changedProjectIndex > -1) {
            this.projects[changedProjectIndex].id = null;
            // this.projects[changedProjectIndex] = value.data.onUpdateProject;
            if (value.data.onUpdateProject.status != null) this.projects[changedProjectIndex].status = value.data.onUpdateProject.status;
            if (value.data.onUpdateProject.size != null) this.projects[changedProjectIndex].size = value.data.onUpdateProject.size;
            if (value.data.onUpdateProject.pipelineStarted != null) this.projects[changedProjectIndex].pipelineStarted = value.data.onUpdateProject.pipelineStarted;
            if (value.data.onUpdateProject.pipelineCompleted != null) this.projects[changedProjectIndex].pipelineCompleted = value.data.onUpdateProject.pipelineCompleted;
            this.projects[changedProjectIndex].id = value.data.onUpdateProject.id;
          }
        },
        error: (error) => console.warn(error),
      });
    },
    cancel(projectID, workspaceId, organizationId) {
      this.$confirm.require({
        // eslint-disable-next-line quotes
        message: `Are you sure you want to cancel?`,
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        accept: async () => {
          // callback to execute when user confirms the action
          if (this.dialogVisible) this.dialogVisible = false;
          const detail = {
            step: 'stop_workflow',
            pipeline: 'move_biosamples_from_bssh_to_s3',
            project_name_uuid: projectID,
          };
          sendEBMessage(detail, organizationId, workspaceId, projectID, 'cancel');
        },
        reject: () => {
          if (this.dialogVisible) this.dialogVisible = false;
          // callback to execute when user rejects the action
        },
      });
    },
    updateProjectInProjectTable(updateProjectObj) {
      try {
        const index = this.projects.findIndex((project) => project.id === updateProjectObj.id);
        if (index !== -1) {
          const updatedProject = this.updateProjectFromTable(index, updateProjectObj);
          this.replaceProject(index, updatedProject);
        }
      } catch (error) {
        console.error(error);
      }
    },
    updateProjectFromTable(index, updateProjectObj) {
      try {
        const projectToUpdate = this.projects[index];
        for (const [key, value] of Object.entries(updateProjectObj)) {
          console.log(`${key}: ${value}`);
          if (key === 'pipeline') {
            if (valueIsNullOrUndefined(projectToUpdate.pipelines.items)) projectToUpdate.pipelines.items = [updateProjectObj.pipeline];
            projectToUpdate.pipelines.items.push(updateProjectObj.pipeline);
          } else if (key !== 'id') {
            projectToUpdate[key] = value;
          }
        }
        return projectToUpdate;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    replaceProject(index, updatedProject) {
      try {
        this.projects[index] = updatedProject;
      } catch (error) {
        console.error(error);
      }
    },
    async loadLaunchablePipelines() {
      try {
        const launchablePipelines = await listItems(customQueries.customListLaunchablePipelines, { limit: 300 });
        const gr = await groupLaunchablePipelinesForListBox(launchablePipelines);
        const mappedOptions = gr.map((o) => ({
          label: o.selectedVersion.pipelineName,
          disabled: (o.selectedVersion.analysisLevel === 'tertiary'), // Disabled tertiary pipelines for now
          tooltip: o.selectedVersion.description,
          launchablePipelineObj: o.selectedVersion,
          initialCommand: () => {
            this.startingLaunchPipelineStep = 2;
            // this.pipelineTypeToLaunch = o.selectedVersion;
          },
        }));
        this.launchablePipelineOptionsForSpinner = mappedOptions;
      } catch (error) {
        console.error(error);
      }
    },
    // eslint-disable-next-line no-unused-vars
    getLaunchablePipelineOptions(project) {
      return this.remapLaunchablePipelinesAddClosures(this.launchablePipelineOptionsForSpinner, project);
    },
    // eslint-disable-next-line no-unused-vars
    remapLaunchablePipelinesAddClosures(launchablePipelines, project) {
      return launchablePipelines.map((lpOption) => {
        const mappedLP = { ...lpOption };
        mappedLP.command = () => {
          lpOption.initialCommand();
          this.pipelineTypeToLaunch = lpOption.launchablePipelineObj;
          this.projectForPipeline = project;
          console.log('this.pipelineTypeToLaunch in project table :>> ', this.pipelineTypeToLaunch);
        };
        return mappedLP;
      });
    },
    resetLaunchPipelineData() {
      this.pipelineTypeToLaunch = null;
      this.projectForPipeline = null;
    },
    async loadVisualizations() {
      try {
        const visualizations = await listItems(customQueries.listVisualizations, { limit: 300 });
        this.visualizations = visualizations.map((viz) => {
          const newViz = {
            label: viz.visualizationName,
            disabled: false,
            validationSchema: viz.validationSchema,
            tooltip: viz.description,
            initialCommand: () => {
              this.$store.dispatch('setVisualizationToOpen', viz);
            },
          };
          return newViz;
        });
      } catch (error) {
        console.error(error);
      }
    },
    getVisualizationOptions(project) {
      return this.remapVisualizationsAddClosures(this.visualizations, project);
    },
    remapVisualizationsAddClosures(visualizations, project) {
      return visualizations.map((viz) => {
        const mappedViz = { ...viz };
        mappedViz.path = `/workspace/${this.$route.params.workspaceId}/project/${project.id}/pipelines/all`;
        mappedViz.command = () => {
          viz.initialCommand();
          this.$router.push({ path: `/workspace/${this.$route.params.workspaceId}/project/${project.id}/pipelines/all` });
        };
        // eslint-disable-next-line no-return-assign
        if (!this.shouldVizButtonBeDisabled(project) && !valueIsNullOrUndefined(mappedViz.validationSchema)) checkIfVisualizationShouldBeDisabled(viz, project.pipelines.items).then((disabled) => mappedViz.disabled = disabled);
        return mappedViz;
      });
    },
    shouldVizButtonBeDisabled(project) {
      try {
        return (project.status !== 'Job Complete' && project.status !== 'Transfer Complete' && project.status !== 'Add Biosamples Job Complete') || valueIsNullOrUndefined(project.pipelines) || valueIsNullOrUndefined(project.pipelines.items) || project.pipelines.items.length === 0;
      } catch (error) {
        console.error(error);
        return true;
      }
    },
    shouldPipelineButtonBeDisabled(project) {
      try {
        return (project.status !== 'Job Complete' && project.status !== 'Transfer Complete' && project.status !== 'Add Biosamples Job Complete');
      } catch (error) {
        console.error(error);
        return true;
      }
    },
    getLegendTooltip() {
      return 'White - Project created successfully\nGrey - Project creation in progress\nRed - Project creation failed\nYellow - Project creation canceled';
    },
    shouldCreateSpeedDial(projectStatus) {
      try {
        return projectStatus === 'Job Complete' || projectStatus === 'Add Biosamples Job Complete' || projectStatus === 'Transfer Complete';
      } catch (error) {
        console.error(error);
        return false;
      }
    },
  },
  computed: {
    getActiveWorkspace() {
      return this.$store.state.activeWorkspace;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.activeWorkspace': async function () {
      if (this.$store.state.activeWorkspace !== null) {
        console.log('Calling load first from workspace change');
        this.allProjects = {
          ResolveDNA: [],
          ResolveOME: [],
        };
        this.firstLoadTokens = {
          ResolveDNA: null,
          ResolveOME: null,
        };
        this.totalRecords = {
          ResolveDNA: 0,
          ResolveOME: 0,
        };
        await this.loadFirst();
      }
    },
    // eslint-disable-next-line func-names
    '$store.state.showingTransferProject': async function () {
      if (this.$store.state.showingTransferProject === false) {
        this.projectForTransfer = null;
        await this.loadFirst();
      }
    },
    // eslint-disable-next-line func-names
    '$store.state.showingDeleteProject': async function () {
      if (this.$store.state.showingDeleteProject === false) {
        this.projectsForDelete = null;
        await this.loadFirst();
      }
    },
    // eslint-disable-next-line func-names
    '$store.state.showingLaunchPipeline': async function () {
      if (this.$store.state.showingLaunchPipeline === false) {
        this.projectForPipeline = null;
        await this.loadFirst();
      }
    },
    // eslint-disable-next-line func-names
    '$store.state.bioskrybProduct': async function () {
      if (this.$store.state.bioskrybProduct !== null) {
        console.log('Calling load first from bioskrybProduct');
        if (!this.loading) await this.loadFirst();
      }
    },
    // eslint-disable-next-line func-names
    '$store.state.showingLinkProjects': async function () {
      if (this.$store.state.showingLinkProjects === false) {
        this.projectForLinking = null;
        await this.loadFirst();
      }
    },
    // eslint-disable-next-line func-names
    '$store.state.showingRenameProject': async function () {
      if (this.$store.state.showingRenameProject === false) {
        await this.loadFirst();
      }
    },
    // eslint-disable-next-line func-names
    '$store.state.showingExport': async function () {
      if (!this.$store.state.showingExport) {
        this.projectForExport = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
 @import "@/assets/styles/sass/_dataTable.scss";

 ::v-deep(.p-datatable > .p-datatable-wrapper) {
  overflow: visible;
}

::v-deep(.row-grayout) {
    opacity: 0.6 !important; /* Real browsers */
    filter: alpha(opacity = 60) !important; /* MSIE */
}

::v-deep(.row-red) {
    opacity: 0.6 !important; /* Real browsers */
    filter: alpha(opacity = 60) !important; /* MSIE */
    background: rgb(235, 88, 88) !important;
    color: white !important;
}

::v-deep(.row-yellow) {
    opacity: 0.6 !important; /* Real browsers */
    filter: alpha(opacity = 60) !important; /* MSIE */
    background: #fac045 !important;
    color: white !important;
}

.launch-pipeline-speed-dial-div {
  width: 11rem;
  &::v-deep(.circle), &::v-deep(.circle-disabled) {
    width: 140px;
  }
}

.p-datatable .p-component .p-datatable-hoverable-rows .p-datatable-responsive-scroll{
    border-radius: 25px !important;
    background-color: red !important;
}

::v-deep(.p-datatable .p-component .p-datatable-hoverable-rows .p-datatable-responsive-scroll) {
    border-radius: 25px !important;
    background-color: red !important;
}

::v-deep(.p-datatable-header) {
    border-radius: $border_rounded_top;
}

.table-legend-circle {
  font-size: 3rem;
  color: grey
}

.description-p {
  white-space: pre-wrap;
}
// .dial-in-projects {
//   position: relative;
//   right: -50%;
//   padding-top: 100px;
// }

</style>
