<template>
  <Dialog v-model:visible="showing" :style="{ width: '90%' }" header="Terms And Conditions" :modal="true" id="terms-conditions-dialog" class="p-fluid" :closable="false">
    {{ htmlPath }}
    <div class="text">
      <iframe title="tAndC" id="textIFrame" class="textIFrame" :srcdoc="TaS" />
    </div>
    <template #footer>
      <div class="pt-3">
        <Button label="Cancel" icon="pi pi-times" class="p-button-secondary mt-2" @click="hideDialog" />
        <Button label="I agree with the Terms & Conditions" class="p-button-outline mt-2" @click="agree()" />
      </div>
    </template>
  </Dialog>
</template>

<script>
import TaS from '@/assets/BioSkryb - BaseJumper Terms and Conditions - WSGR 1-19-23 - HTML.htm';

export default {
  data() {
    return {
      showing: false,
    };
  },
  methods: {
    agree() {
      this.$emit('agree');
      this.hideDialog();
    },
    hideDialog() {
      this.$store.dispatch('setShowingTermsAndConditionsView', false);
    },
  },
  computed: {
    TaS() {
      let tmp = TaS;
      tmp = tmp.replace('“|”|’', '"');
      return tmp;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingTermsAndConditionsView': async function () {
      this.showing = this.$store.state.showingTermsAndConditionsView;
    },
  },
};
</script>
<style lang="scss" scoped>
.textIFrame {
  height: 70vh;
  width: 100%;
  border:none;
}
</style>
