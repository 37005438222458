<template>
  <div>
    <div class="header">
      <Breadcrumb class="breadcrumb" :home="breadcrumbHome" :model="breadcrumbItems" />
    </div>
    <div>
      <div v-if="loading">
        <img alt="BJSpinner" class="bjSpinner localSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png" />
      </div>
      <div v-if="!loading">
        <h5 class="projectName" v-if="project">{{ project.clientProjectName }}</h5>
        <TabMenu :model="tabMenuItems" v-model:activeIndex="activeIndex" />
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
// Wraper for the project view
import { API, graphqlOperation } from 'aws-amplify';
import * as customQueries from '@/graphql/custom_queries';
import { setPrecedence, valueIsNullOrUndefined } from '@/utils';

export default {
  name: 'Project',
  data() {
    return {
      project: null,
      loading: false,
      breadcrumbHome: { icon: 'pi pi-home', to: `/workspace/${this.$store.state.activeWorkspace}/projects/${(this.$store.state.bioskrybProduct === 'ResolveOME') ? 'OME' : 'DNA'}` },
      activeIndex: 0,
      breadcrumbItems: [
        { label: 'Project', to: `/workspace/${this.$store.state.activeWorkspace}/project/${this.$route.params.id}/biosamples` },
      ],
      tabMenuItems: [
        {
          label: 'Biosamples',
        },
        {
          label: 'Secondary Pipelines',
        },
        {
          label: 'Tertiary Pipelines',
        },
      ],
    };
  },
  async mounted() {
    this.loading = true;
    this.getCurrentActiveTab();
    this.checkIfShowingAllPipelinesFotVizSelection();
    const response = await API.graphql(graphqlOperation(customQueries.getProjectForProjectView, {
      id: this.$route.params.id,
    }));
    this.project = response.data.getProject;
    if (this.project.workspaceId !== this.$store.state.activeWorkspace) {
      this.$store.dispatch('setActiveWorkspace', this.project.workspaceId);
      this.$store.dispatch('setActiveOrganization', this.project.workspace.organizationId);
      console.log('WORKSPACE DIFFERENT');
      console.log('this.project.workspaceId :>> ', this.project.workspaceId);
      setPrecedence();
    }
    if (!valueIsNullOrUndefined(this.project.id)) {
      this.tabMenuItems[0].url = `/#/workspace/${this.$store.state.activeWorkspace}/project/${this.project.id}/biosamples`;
      this.tabMenuItems[1].url = `/#/workspace/${this.$store.state.activeWorkspace}/project/${this.project.id}/pipelines/secondary`;
      this.tabMenuItems[2].url = `/#/workspace/${this.$store.state.activeWorkspace}/project/${this.project.id}/pipelines/tertiary`;
    }
    if (this.shouldRemoveTabMenu(this.$route.params.analysisLevel)) {
      this.tabMenuItems = [];
    }
    this.loading = false;
  },
  methods: {
    checkIfShowingAllPipelinesFotVizSelection() {
      if ('analysisLevel' in this.$route.params && this.$route.params.analysisLevel === 'all') this.breadcrumbItems.push({ label: 'Pipelines', to: this.$route.fullPath });
    },
    shouldRemoveTabMenu(analysisLevel) {
      return !valueIsNullOrUndefined(analysisLevel) && analysisLevel !== 'secondary' && analysisLevel !== 'tertiary';
    },
    getCurrentActiveTab() {
      try {
        if (this.$route.name === 'Biosamples') {
          this.activeIndex = 0;
        } else if (this.$route.name === 'Pipelines') {
          if (this.$route.params.analysisLevel === 'secondary') {
            this.activeIndex = 1;
          } else if (this.$route.params.analysisLevel === 'tertiary') {
            this.activeIndex = 2;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.header {
  margin-bottom: 1.5rem;
}
.projectName {
  color: white;
}

.localSpinner {
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
}
</style>
