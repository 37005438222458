<template>
  <DataTable
    :value="projects"
    paginator
    :totalRecords="projects.length"
    v-model:rows="rows"
    styleClass="myTable"
    v-model:filters="filters"
    ref="dt"
    filterDisplay="row"
    :globalFilterFields="['clientProjectName']"
    responsiveLayout="scroll"
    paginatorTemplate="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
    :rowsPerPageOptions="[10, 25, 50]"
    dataKey="id"
    :rowHover="true"
    :loading="loading"
  >
    <template #empty>
      No projects found.
    </template>
    <template #loading>
      Loading projects... Please wait.
    </template>
    <Column
      field="clientProjectName"
      header="Project"
      headerClass="custom-header"
      :bodyStyle="getBodyStyle()"
      filterMatchMode="startsWith"
      ref="clientProjectName"
      :sortable="true">
      <template #filter="{ filterModel, filterCallback }">
        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="Search by project name" />
      </template>
    </Column>
    <Column header="Description" field="description" :bodyStyle="getDescriptionBodyStyle()">
      <template #body="{ data }">
        {{ getDescription(data.description) }}
      </template>
    </Column>
    <Column header="Size" field="size" :bodyStyle="getBodyStyle()" :sortable="true">
      <template #body="{ data }">
        {{ getSize(data.size) }}
      </template>
    </Column>
    <Column header="Number of Biosamples" headerClass="custom-header" :bodyStyle="getBodyStyle()">
      <template #body="{ data }">
        <img alt="BJSpinner" class="bjSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png" style="width:30px;height:30px" v-if="data.biosamples.nextToken !== null" />
        <div v-else>
          {{data.biosamples.items.length}}
        </div>
      </template>
    </Column>
    <Column headerStyle="width:4rem">
      <template #body="{ data }">
        <BJDial v-if="shouldCreateSpeedDial(data.status)" :label="'Visualize'" :options="getVisualizationOptions(data)" :disabled="shouldVizButtonBeDisabled(data)" />
      </template>
    </Column>
    <!-- <Column header="Number of Biosamples" headerClass="custom-header" :bodyStyle="getBodyStyle()">
      <template #body="{ data }">
        <img alt="BJSpinner" class="bjSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png" style="width:30px;height:30px" v-if="data.biosamples.nextToken !== null" />
        <div v-else>
          {{data.biosamples.items.length}}
        </div>
      </template>
    </Column>
    <Column headerStyle="width:4rem">
      <template #body="{ data }">
        <BJDial v-if="shouldCreateSpeedDial(data.status)" :label="'Visualize'" :options="getVisualizationOptions(data)" :disabled="shouldVizButtonBeDisabled(data)" />
      </template>
    </Column>
    <Column headerStyle="width:4rem">
      <template #body="{ data }">
        <div v-if="shouldCreateSpeedDial(data.status)" class="launch-pipeline-speed-dial-div">
          <BJDial :label="'Launch Pipeline'" :options="getLaunchablePipelineOptions(data.id)" :disabled="shouldPipelineButtonBeDisabled(data)" :passedInitialRadius="24" :passedBGCircleOffset="4.5" />
        </div>
      </template>
    </Column>
    <Column header="Pipelines" :bodyStyle="getBodyStyle()">
      <template #body="{ data }">
        <Tag v-for="(item, index) of [...new Set(data.pipelines.items.map(item => item.pipelineName))]" :key="index" class="tag-col m-1" :value="item" />
      </template>
    </Column>
    <Column v-if="this.$store.state.bioskrybProduct === 'ResolveOME'" field="projectType" header="Project Type" headerClass="custom-header" :bodyStyle="getBodyStyle()" :sortable="true" />
    <Column field="lotId" header="BioSkryb Product Lot ID" headerClass="custom-header" :bodyStyle="getBodyStyle()" filterMatchMode="startsWith" ref="lotId" :sortable="true">
      <template #filter="{ filterModel, filterCallback }">
        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="Search by LotId" />
      </template>
    </Column>
    <Column field="initiator" header="Initiator" headerClass="custom-header" :bodyStyle="getBodyStyle()" filterMatchMode="startsWith" ref="initiator" :sortable="true">
      <template #filter="{ filterModel, filterCallback }">
        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="Search by initiator" />
      </template>
    </Column>
    <Column v-if="this.$store.state.bioskrybProduct === 'ResolveOME'" header="Associated Project" :bodyStyle="getBodyStyle()" :sortable="true">
      <template #body="{ data }">
        <Tag v-if="data.associatedProjectId !== null" class="bg-cyan-400 m-1" :value="data.associatedProject.clientProjectName" />
      </template>
    </Column>
    <Column header="Date Created" field="created" :bodyStyle="getBodyStyle()" :sortable="true">
      <template #body="{ data }">
        {{ $filters.formatDate(data.created) }}
      </template>
    </Column>
    <Column headerStyle="width:4rem">
      <template #body="{ data }">
        <Button icon="pi pi-ellipsis-v" @click="projectActionClick(data, $event)" :disabled="(data.status === 'Job Started') && ($store.state.precedenceLevel > 3)" />
        <Menu :ref="'project-menu-' + data.id" :model="projectActionItems(data)" :popup="true" />
      </template>
    </Column> -->
    <Column header="Pipelines" :bodyStyle="getBodyStyle()">
      <template #body="{ data }">
        <Tag v-for="(item, index) of [...new Set(data.pipelines.items.map(item => item.pipelineName))]" :key="index" class="tag-col m-1" :value="item" />
      </template>
    </Column>
    <Column header="Date Created" field="created" :bodyStyle="getBodyStyle()" :sortable="true">
      <template #body="{ data }">
        {{ $filters.formatDate(data.created) }}
      </template>
    </Column>
  </DataTable>
</template>
<script>
import * as customQueries from '@/graphql/custom_queries';
import {
  getSize, listItems, valueIsNullOrUndefined, validateVisualization,
} from '@/utils';
import { FilterMatchMode } from 'primevue/api';
import { API, graphqlOperation } from 'aws-amplify';
import BJDial from '@/components/Utils/BJDial';

export default {
  components: { BJDial },
  emits: ['setProjectForMiniPipelineTable'],
  data() {
    return {
      loading: false,
      projects: [],
      rows: 10,
      visualizations: [],
      filters: {
        clientProjectName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        description: { value: null, matchMode: FilterMatchMode.CONTAINS },
        size: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  async mounted() {
    this.loadVisualizations();
    if (!this.loading && this.projects.length === 0) {
      await this.loadFirst();
    }
  },
  methods: {
    async loadFirst() {
      try {
        this.loading = true;
        await this.loadProjects();
        this.loading = false;
      } catch (error) {
        console.error('Error in LoadFirst');
        console.error(error);
      }
    },
    async loadProjects() {
      try {
        console.log('Load all projects');
        this.projects = await this.getProjects();
        this.pullBiosampleNumbers();
      } catch (error) {
        console.error(error);
      }
    },
    async getProjects() {
      const params = {
        limit: 1000,
        biosamplesLimit: 10000,
        workspaceId: this.$route.params.workspaceId,
        filter: { or: [{ status: { eq: 'Job Complete' } }, { status: { eq: 'Transfer Complete' } }, { status: { eq: 'Add Biosamples Job Complete' } }] },
        sortDirection: 'DESC',
      };
      const projects = await listItems(customQueries.projectForMiniProjectTable, params);
      console.log('projects :>> ', projects);
      return projects;
    },
    shouldCreateSpeedDial(projectStatus) {
      try {
        return projectStatus === 'Job Complete' || projectStatus === 'Add Biosamples Job Complete' || projectStatus === 'Transfer Complete';
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    shouldVizButtonBeDisabled(project) {
      try {
        return (project.status !== 'Job Complete' && project.status !== 'Transfer Complete' && project.status !== 'Add Biosamples Job Complete') || valueIsNullOrUndefined(project.pipelines) || valueIsNullOrUndefined(project.pipelines.items) || project.pipelines.items.length === 0;
      } catch (error) {
        console.error(error);
        return true;
      }
    },
    async loadVisualizations() {
      try {
        const visualizations = await listItems(customQueries.listVisualizations, { limit: 300 });
        this.visualizations = visualizations.map((viz) => {
          const newViz = {
            label: viz.visualizationName,
            disabled: false,
            validationSchema: viz.validationSchema,
            tooltip: viz.description,
            initialCommand: () => {
              this.$store.dispatch('setVisualizationToOpen', viz);
            },
          };
          return newViz;
        });
      } catch (error) {
        console.error(error);
      }
    },
    getVisualizationOptions(project) {
      return this.remapVisualizationsAddClosures(this.visualizations, project);
    },
    remapVisualizationsAddClosures(visualizations, project) {
      return visualizations.map((viz) => {
        const mappedViz = { ...viz };
        mappedViz.path = `/workspace/${this.$route.params.workspaceId}/project/${project.id}/pipelines/all`;
        mappedViz.command = () => {
          viz.initialCommand();
          this.$emit('setProjectForMiniPipelineTable', project);
        };
        // eslint-disable-next-line no-return-assign
        if (!this.shouldVizButtonBeDisabled(project) && !valueIsNullOrUndefined(mappedViz.validationSchema)) this.checkIfVisualizationShouldBeDisabled(viz, project.pipelines.items).then((disabled) => mappedViz.disabled = disabled);
        return mappedViz;
      });
    },
    async checkIfVisualizationShouldBeDisabled(visualization, pipelines) {
      const validationResults = await Promise.all(pipelines.map((pipeline) => validateVisualization(visualization, pipeline, [], null, null, false)));
      if (validationResults.some((res) => res)) return false;
      return true;
    },
    async pullBiosampleNumbers() {
      this.projects.forEach(async (project) => {
        if (project.biosamples.nextToken !== null && project.biosamples.nextToken !== undefined) {
          const allBiosamples = project.biosamples.items;
          const response1 = await API.graphql(graphqlOperation(customQueries.getProjectForBiosamples, {
            id: project.id,
            biosamplesLimit: this.paginatedBiosamplesLimit,
            biosamplesNextToken: project.biosamples.nextToken,
          }));
          allBiosamples.push(...response1.data.getProject.biosamples.items);
          if (response1.data.getProject.biosamples.nextToken) {
            let biosampleToken = response1.data.getProject.biosamples.nextToken;
            while (biosampleToken) {
              const response2 = await API.graphql(graphqlOperation(customQueries.getProjectForBiosamples, {
                id: project.id, biosamplesLimit: this.paginatedBiosamplesLimit, biosamplesNextToken: biosampleToken,
              }));
              biosampleToken = response2.data.getProject.biosamples.nextToken;
              allBiosamples.push(...response2.data.getProject.biosamples.items);
            }
          }
          // eslint-disable-next-line no-param-reassign
          project.biosamples.items = allBiosamples;
          // eslint-disable-next-line no-param-reassign
          project.biosamples.nextToken = null; // To stop the loader and show the value
        }
      });
    },
    getSize(size) {
      return getSize(size);
    },
    getDescription(description) {
      return this.getFirstNCharacters(description, 150);
    },
    getFirstNCharacters(string, n) {
      try {
        if (string === null || string === undefined) return '';
        if (string.length > n) return `${string.substring(0, n)}...`;
        return string;
      } catch (error) {
        console.error(error);
        return '';
      }
    },
    getBodyStyle() {
      return 'text-align: center';
    },
    getDescriptionBodyStyle() {
      return `${this.getBodyStyle()}; word-wrap: break-word; width: 20vw; max-width: 20vw; `;
    },
  },
  computed: {
    getActiveWorkspace() {
      return this.$store.state.activeWorkspace;
    },
  },
};
</script>
