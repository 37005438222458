<template>
  <Dialog v-model:visible="showing" :style="{ width: '400px' }" header="Accept Terms and Conditions" :modal="true" class="p-fluid" :closable="false">
    <Button label="Read terms and conditions" @click="showTermsAndConditionsText()" />
    <template #footer>
      <div class="flex justify-content-center footer-wrapper">
        <Button label="SignOut" class="p-button p-button-secondary" @click="signOut()" />
        <Button label="I agree with the Terms and Conditions" class="p-button" @click="agree()" />
      </div>
    </template>
  </Dialog>
  <TermsAndConditionsView @agree="agree" />
</template>
<script>
// TODO: Expand. Right now only deletes the project entity in the database.

// eslint-disable-next-line no-unused-vars
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as mutations from '@/graphql/mutations';
import TermsAndConditionsView from '@/views/Auth/TermsAndConditionsView.vue';

export default {
  components: {
    TermsAndConditionsView,
  },
  data() {
    return {
      showing: false,
    };
  },
  methods: {
    hideDialog() {
      this.$store.dispatch('setShowingAcceptTAndC', false);
      this.showing = false;
    },
    async agree() {
      try {
        let username = this.$store.state.newUserUsername;
        if (username === null || username === undefined) {
          const user = await Auth.currentAuthenticatedUser();
          username = user.username;
        }
        if (username !== null && username !== undefined) {
          const userVariables = {
            id: username,
            tAndCAccepted: true,
          };
          API.graphql(graphqlOperation(mutations.updateUser, { input: userVariables }));
          this.hideDialog();
        } else {
          console.error('newUserUsername is null');
          this.$toast.add({
            severity: 'error',
            summary: 'Username error',
            detail: 'There was an error with the username. Please contact an administrator',
            life: 7000,
          });
          return;
        }
      } catch (error) {
        console.error(error);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'There was an error. Please contact an administrator',
          life: 7000,
        });
      }
    },
    signOut() {
      Auth.signOut().then(() => {
        this.$router.push('/auth/login');
        this.$store.dispatch('setActiveWorkspace', '');
      }).catch((e) => {
        console.log('error signing out: ', e);
      });
    },
    showTermsAndConditionsText() {
      this.$store.dispatch('setShowingTermsAndConditionsView', true);
    },
  },
  mounted() {
    if (this.$store.state.showingAcceptTAndC !== false) {
      this.showing = true;
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingAcceptTAndC': async function () {
      if (this.$store.state.showingAcceptTAndC !== false) {
        this.showing = true;
      } else {
        this.showing = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.footer-wrapper {
  height: 4vh;
}
</style>
