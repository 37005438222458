<template>
  <Dialog v-model:visible="showing" :style="dialogWidth" header="Pipeline Parameters" :modal="true" class="p-fluid" :closable="false">
    <DataTable :value="params">
      <Column field="header" :bodyStyle="getBodyStyle()" header='Parameter Name' />
      <Column field="field" :bodyStyle="getBodyStyle()" header='Parameter Value' />
    </DataTable>
    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-secondary align-self-start" @click="hideDialog()" />
    </template>
  </Dialog>
</template>

<script>
export default {
  props: ['paramString'],
  emits: ['clear'],
  data() {
    return {
      showing: false,
      params: null,
      dialogWidth: { width: '35%' },
    };
  },
  methods: {
    loadParams() {
      if (this.paramString === null || this.paramString === undefined) return;
      const data = JSON.parse(this.paramString);
      this.params = Object.keys(data).map((key) => ({ header: key, field: data[key] }));
    },
    getBodyStyle() {
      return 'text-align: center';
    },
    hideDialog() {
      this.$emit('clear');
      this.showing = false;
    },
  },
  watch: {
    paramString(value) {
      if (value === null || value === undefined) {
        this.showing = false;
        return;
      }
      this.showing = true;
      this.loadParams();
    },
  },
};
</script>
