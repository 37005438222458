<template>
  <Dialog v-model:visible="showing" :style="{ width: '800px' }" header="Update Project" :modal="true" class="p-fluid" :closable="false">
    <div v-if="updating" class="flex justify-content-center">
      <img alt="BJSpinner" class="bjSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png" />
    </div>
    <div v-if="!updating">
      <div id="projectName-div" class="field pt-4">
        <span class="p-float-label">
          <InputText id="projectName" type="text" :class="(validateString(currentProjectName) === false) ? 'p-invalid' : ''" v-model.trim="currentProjectName" required="true" autofocus />
          <label for="currentProjectName">Project Name</label>
        </span>
        <InlineMessage :style="(validateString(currentProjectName) === false) ? '' : 'display: none;'">Project name can only contain letters and numbers!</InlineMessage>
        <InlineMessage :style="projectNameAlreadyExists ? '' : 'display: none;'">That project name already exists!</InlineMessage>
      </div>
      <div id="lotId-div" class="field pt-4">
        <div class="p-inputgroup">
          <span class="p-float-label">
            <!-- <Dropdown id="lotId" v-model="lotId" :options="this.getLotIds" optionLabel="label" optionGroupLabel="label" optionGroupChildren="items" :filter="true" /> -->
            <InputText id="lotId" type="text" :class="((validateLotId(currentLotId) === false) ? 'p-invalid' : '') + ' lot-id-input' " v-model.trim="currentLotId" required="true" />
            <Button icon="pi pi-image" class="p-button-info" @click="toggleLotIdImage()" v-tooltip.right="'Show lot id location'" />
            <label for="lotId">BioSkryb Product Lot ID</label>
          </span>
        </div>
        <InlineMessage :style="(validateLotId(currentLotId) === false) ? '' : 'display: none;'">This is not a valid Lot Id!</InlineMessage>
        <div class="text-center pt-3" v-if="showLotIdImage">
          <Image
            :src="require('@/assets/LotId.png')"
            alt="LotId image"
            width="250"
            preview />
        </div>
      </div>
      <div id="description-div" class="field pt-4">
        <span class="p-float-label">
          <Textarea id="description" v-model="currentDescription" :style="{ overflow: 'auto' }" :autoResize="true" rows="5" cols="30" />
          <label for="description" class="mb-3">Description</label>
        </span>
      </div>
      <div>
        <BiosampleMetadataColumnsDynamicRenderer v-model:biosampleMetadataColumns="currentMetadataColumns" />
      </div>
    </div>
    <template #footer v-if="!updating">
      <Button label="Cancel" icon="pi pi-times" class="p-button-secondary" @click="hideDialog" />
      <Button label="Save" class="p-button-outline" @click="updateProject()" />
    </template>
  </Dialog>
</template>
<script>
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '@/graphql/mutations';
import {
  // eslint-disable-next-line no-unused-vars
  validateString, validateLotId, listItems, isEqual, objectIsEmpty, valueIsNullOrUndefined, getEmptyMetadataObj,
} from '@/utils';
import BiosampleMetadataColumnsDynamicRenderer from '@/components/Project/BiosampleMetadataColumnsDynamicRenderer.vue';
import * as customQueries from '@/graphql/custom_queries';

export default {
  name: 'UpdateProject',
  props: ['projectForUpdate'],
  emits: ['updateProjectInProjectTable'],
  components: {
    BiosampleMetadataColumnsDynamicRenderer,
  },
  data() {
    return {
      showing: false,
      updating: false,
      currentProjectName: null,
      projectNameAlreadyExists: false,
      currentLotId: null,
      currentDescription: null,
      currentMetadataColumns: [
        getEmptyMetadataObj(),
      ],
      showLotIdImage: false,
      projects: null,
    };
  },
  methods: {
    async updateProject() {
      try {
        this.updating = true;
        if (!(await this.checksPass())) {
          this.updating = false;
          return;
        }
        const updateObject = this.makeUpdateObject();
        if (objectIsEmpty(updateObject) || updateObject === null) {
          this.updating = false;
          return;
        }
        await API.graphql(graphqlOperation(mutations.updateProject, { input: updateObject }));
        this.$emit('updateProjectInProjectTable', updateObject);
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Project updated successfully!',
          life: 3000,
        });
        this.hideDialog();
      } catch (error) {
        console.error(error);
        this.hideDialog();
      }
    },
    async checksPass() {
      try {
        const projects = await this.projects;
        for (let i = 0; i < projects.length; i += 1) {
          if (projects[i].clientProjectName !== this.projectForUpdate.clientProjectName && projects[i].clientProjectName === this.currentProjectName) {
            this.projectNameAlreadyExists = true;
            return false;
          }
        }
        if ((this.validateString(this.currentProjectName) === false)) {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Update failed. Invalid project name.',
            life: 3000,
          });
          return false;
        }
        if ((this.validateLotId(this.currentLotId) === false)) {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Update failed. Invalid lotId.',
            life: 3000,
          });
          return false;
        }
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    makeUpdateObject() {
      try {
        const updateObject = {};
        if (this.projectForUpdate.clientProjectName !== this.currentProjectName) updateObject.clientProjectName = this.currentProjectName;
        if (this.projectForUpdate.lotId !== this.currentLotId) updateObject.lotId = this.currentLotId;
        if (this.projectForUpdate.description !== this.currentDescription) updateObject.description = this.currentDescription;
        if (this.projectForUpdate.biosampleMetadataColumns !== JSON.stringify({ columns: this.currentMetadataColumns })) updateObject.biosampleMetadataColumns = JSON.stringify({ columns: this.currentMetadataColumns.filter((col) => col.name !== '') });
        if (!objectIsEmpty(updateObject)) updateObject.id = this.projectForUpdate.id;
        return updateObject;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async loadProjects(workspaceId) {
      const projects = await listItems(customQueries.projectsByWorkspaceNames, {
        limit: 5000,
        workspaceId,
        filter: {
          assayName: { eq: this.$store.state.bioskrybProduct },
        },
      });
      return projects;
    },
    setProjectInitialValues(project) {
      if (valueIsNullOrUndefined(project)) {
        console.error('Project is null');
        return;
      }
      if (!valueIsNullOrUndefined(project.clientProjectName)) this.currentProjectName = project.clientProjectName;
      if (!valueIsNullOrUndefined(project.lotId)) this.currentLotId = project.lotId;
      if (!valueIsNullOrUndefined(project.description)) this.currentDescription = project.description;
      if (!valueIsNullOrUndefined(project.biosampleMetadataColumns)) this.currentMetadataColumns = JSON.parse(project.biosampleMetadataColumns).columns;
      if (valueIsNullOrUndefined(this.currentMetadataColumns) || this.currentMetadataColumns.length === 0) {
        this.currentMetadataColumns = [
          getEmptyMetadataObj(),
        ];
      }
    },
    toggleLotIdImage() {
      this.showLotIdImage = !this.showLotIdImage;
    },
    validateString(string) {
      return validateString(string);
    },
    validateLotId(lotId) {
      return validateLotId(lotId);
    },
    hideDialog() {
      this.currentProjectName = null;
      this.projectNameAlreadyExists = false;
      this.currentLotId = null;
      this.currentDescription = null;
      this.currentMetadataColumns = null;
      this.showLotIdImage = false;
      this.projects = null;
      this.updating = false;
      this.$store.dispatch('setShowingUpdateProject', false);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingUpdateProject': async function () {
      this.showing = this.$store.state.showingUpdateProject;
      if (this.showing) {
        this.setProjectInitialValues(this.projectForUpdate);
        this.projects = this.loadProjects(this.projectForUpdate.workspaceId);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
