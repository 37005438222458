<template>
  <Dialog v-model:visible="showing" :style="{ width: '450px' }" header="Profile" :modal="true" class="p-fluid" :closable="false">
    <div class="field">
      <label for="fullName">Full Name</label>
      <InputText id="fullName" v-model="fullName" required="true" autofocus />
    </div>
    <div class="field">
      <label for="email">Email</label>
      <InputText id="email" v-model="email" required="true" autofocus />
    </div>
    <div class="field">
      <Button class="p-button-text" label="Change Password" @click="toggleChangePassword()" />
    </div>
    <div class="field">
      <label for="defaultWorkspace">Default Workspace</label>
      <Dropdown
        id="defaultWorkspace"
        v-model="defaultWorkspace"
        :options="allWorkspaces"
        optionLabel="description"
        placeholder="Select new default workspace"
      />
    </div>
    <div class="field">
      <div class="pb-1" style="width: 100%; display: flex;">
        <label for="notficiationEmails">Notification Emails</label>
        <div class="tooltipMark" v-tooltip.right="tooltipMessage">?</div>
      </div>
      <Chips id="notficiationEmails" v-model="notificationEmails" required="false" />
    </div>
    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-secondary" @click="hide" />
      <Button label="Save" class="p-button-outline" @click="save" />
    </template>
  </Dialog>
</template>

<script>
import { Auth } from 'aws-amplify';

export default {
  props: ['allWorkspaces'],
  data() {
    return {
      showing: false,
      fullName: '',
      email: '',
      defaultWorkspace: null,
      notificationEmails: [], // List of emails where the user whants to receive notiifcations
      tooltipMessage: 'Enter the email you wish to receive notifications on and press enter.',
    };
  },
  methods: {
    hide() {
      this.$store.dispatch('showProfile');
    },
    toggleChangePassword() {
      this.$store.dispatch('showProfile');
      this.$store.dispatch('showChangePassword');
    },
    async save() {
      // Set default workspace
      try {
        if (this.defaultWorkspace !== null && (localStorage.getItem('defaultWorkspace') !== this.defaultWorkspace)) {
          localStorage.setItem('defaultWorkspace', this.defaultWorkspace.id);
        }
      } catch (error) {
        console.log('Error in setting default workspace');
        console.log(error);
      }

      // Set notification emails
      // try {
      //   const user = await Auth.currentAuthenticatedUser();
      //   console.log('Auth :>> ', Auth);
      //   console.log('emails :>> ', [...this.notificationEmails]);
      //   // const attributes = {
      //   //   'custom:notificationEmails': [...this.notificationEmails],
      //   // };
      //   const attributes = {
      //     'custom:notificationEmails': this.notificationEmails[0],
      //   };
      //   console.log('attributes :>> ', attributes);
      //   await Auth.updateUserAttributes(user, attributes);
      // } catch (error) {
      //   console.error('Error updateing users notification emails');
      //   console.error(error);
      // }
      try {
        // Right now, they are stored in local storage. Cognito has the option to se custom attributes so this might be changed
        localStorage.setItem('notificationEmails', [...this.notificationEmails]);
      } catch (error) {
        console.error('Error setting notification emails');
        console.error(error);
      }
      this.$toast.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Saved successfully!',
        life: 3000,
      });
      this.$store.dispatch('showProfile');
    },
  },
  async created() {
    const userInfo = await Auth.currentUserInfo();
    this.email = userInfo.attributes.email;
    this.fullName = userInfo.attributes.name;
    try {
      if (localStorage.getItem('defaultWorkspace')) {
        try {
          this.defaultWorkspace = this.allWorkspaces.find((ws) => ws.id === localStorage.getItem('defaultWorkspace'));
        } catch (error) {
          console.error(error);
        }
      }
      if (localStorage.getItem('notificationEmails')) {
        this.notificationEmails = localStorage.getItem('notificationEmails').split(',');
      }
    } catch (error) {
      console.log('No default workspace');
      console.log(error);
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingProfile': async function () {
      this.showing = this.$store.state.showingProfile;
    },

    notificationEmails(value) {
      if (value.length > 0) {
        const email = value.at(-1);
        const regex = /[a-zA-Z0-9_-]+@[a-zA-Z0-9]+.[a-zA-Z0-9]+/g;
        if (!email.match(regex)) {
          value.pop();
        }
      }
    },
  },
};
</script>
