<template>
  <Dialog v-model:visible="showing" :style="{ width: '550px' }" header="Launch a pipeline" :modal="true" class="p-fluid" :closable="false">
    <h3>Do you want to launch a pipeline immediately after the FASTQ files are downloaded?</h3>
    <span>*You will not be able to launch a pipeline untill the project download is finished.</span>
    <div v-if="allFuturePipelineParams.length > 0" class="pt-4 pb-4">
      <DataTable :value="allFuturePipelinesForTable">
        <Column
          field="pipelineName"
          header="Pipeline"
          headerClass="custom-header"
          :bodyStyle="getBodyStyle()"
        />
        <Column
          field="pipelineVersion"
          header="Pipeline Version"
          headerClass="custom-header"
          :bodyStyle="getBodyStyle()"
        />
      </DataTable>
    </div>
    <template #footer>
      <div class="pt-4">
        <Button label="No" class="p-button-outline p-button-secondary" @click="dontLaunchPipelines" />
        <Button :label="(allFuturePipelineParams.length === 0) ? 'Yes' : 'Add another pipeline for launch'" class="p-button-outline" @click="openLaunchPipelineDialog" />
        <Button v-if="allFuturePipelineParams.length !== 0" label="Launch" @click="launch" />
        <!-- <Button @click="test">Test</Button> -->
      </div>
    </template>
  </Dialog>
  <DynamicLaunchPipelineDialog
    v-if="projectForPipeline !== null"
    @dontLaunchPipelines="dontLaunchPipelines"
    :project="project"
    @addedPipelineForFutureLaunch="addedPipelineForFutureLaunch"
  />
</template>

<script>
import DynamicLaunchPipelineDialog from '@/components/Pipeline/DynamicLaunchPipelineDialog.vue';

export default {
  emits: ['launchPipelines'],
  props: ['projectForPipeline'],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    DynamicLaunchPipelineDialog,
  },
  data() {
    return {
      showing: false,
      project: null,
      allFuturePipelineParams: [],
      allFuturePipelinesForTable: [],
    };
  },
  methods: {
    refreshTable() {
      this.allFuturePipelinesForTable = this.allFuturePipelineParams.map((param) => {
        const detail = param.Detail;
        const o = {
          pipelineName: detail.pipeline,
          pipelineVersion: detail.pipeline_version,
        };
        return o;
      });
    },
    launch() {
      this.$emit('launchPipelines', this.allFuturePipelineParams);
      this.hideDialog();
    },
    dontLaunchPipelines() {
      this.$emit('launchPipelines', []);
      if (this.$store.state.showingImmediatePipelineLaunch) {
        this.hideDialog();
      } else {
        this.allFuturePipelineParams = [];
      }
    },
    openLaunchPipelineDialog() {
      console.log('openLaunchPipelineDialog');
      console.log('this.project :>> ', this.project);
      console.log('this.projectForPipeline :>> ', this.projectForPipeline);
      // this.$store.dispatch('showLaunchPipeline');
      // this.$store.dispatch('setShowLaunchPipeline', true);
      // this.$store.dispatch('toggleFuturePipelines');
      this.$store.dispatch('setToggleFuturePipelines', true);
      if (this.project !== null) {
        console.log('Project was not null');
        this.$store.dispatch('setShowLaunchPipeline', true);
      } else {
        console.log('Project was null');
        this.project = null;
        this.project = this.projectForPipeline;
      }
      this.project = null;
      this.project = this.projectForPipeline;
      // this.$store.dispatch('showImmediatePipelineLaunch');
      this.$store.dispatch('setShowImmediatePipelineLaunch', false);
      // this.project = null;
      // this.project = this.projectForPipeline;
    },
    addedPipelineForFutureLaunch(params) {
      this.allFuturePipelineParams.push(params);
    },
    hideDialog() {
      this.allFuturePipelineParams = [];
      // this.$store.dispatch('showImmediatePipelineLaunch');
      this.$store.dispatch('setShowImmediatePipelineLaunch', false);
    },
    getBodyStyle() {
      return 'text-align: center';
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingImmediatePipelineLaunch': async function () {
      this.showing = this.$store.state.showingImmediatePipelineLaunch;
    },
    allFuturePipelineParams: {
      handler() {
        this.refreshTable();
      },
      deep: true,
    },
  },
};
</script>
