<template>
  <div class="flex flex-row bd-highligh">
    <div class="me-auto p-2 bd-highlight first" v-if="!loading">
      <Button class="mt-3 mb-3 mr-1 expand-btn" type="button" icon="pi pi-plus" label="Expand All" @click="expandAll" />
      <Button class="mt-3 mb-3" type="button" icon="pi pi-minus" label="Collapse All" @click="collapseAll" />
      <Button v-if="$store.state.precedenceLevel === 1" class="mt-3 mb-3 ml-2" type="button" label="Open in S3" @click="openPipelineFolderInS3" />
    </div>
    <div class="p-2 bd-highlight flex justify-content-end second" v-if="!loading">
      <div>
        <Button class="mt-2 mb-2 mr-3 p-button-raised" type="button" icon="pi pi-external-link" label="Expanded View" @click="dialogVisible = true" />
      </div>
      <div>
        <Button :disabled="Object.keys(selectedItems).length === 0" class="mt-2 mb-2 mr-3 p-button-raised" type="button" icon="pi pi-download" label="Download" @click="download" />
      </div>
      <slot name="toggleTopUI" />
    </div>
  </div>
  <div v-if="!loading" class="my-tree-wrapper">
    <Tree :value="treeNodes" scrollHeight="50vh" :filter="true" filterMode="lenient" selectionMode="checkbox" v-model:selectionKeys="selectedItems" :expandedKeys="expandedKeys">
      <template #default="slotProps">
        <span>{{slotProps.node.label}}</span><span v-if="$store.state.precedenceLevel === 1"> - {{slotProps.node.data}}</span>
        <div v-if="$store.state.precedenceLevel === 1" class="flex justify-content-end">
          <Button class="mt-2 p-button-sm p-button-raised p-button-rounded adminCopyPathButton" @click="copyFilePath(slotProps.node.data)">Copy Path</Button>
        </div>
      </template>
    </Tree>
  </div>
  <div v-if="loading">
    <div class="flex justify-content-center spinner-wrapper">
      <div class="spinner-vis-bg" />
      <!-- <ProgressSpinner style="width:30%;height:30%"/> -->
      <img alt="BJSpinner" class="bjSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png" />
    </div>
  </div>
  <div>
    <Dialog
      :modal="true"
      header="Flex Scroll"
      v-model:visible="dialogVisible"
      :style="{ width: '50vw' }"
      maximizable
      :contentStyle="{ height: '50vh' }"
      class="p-fluid">
      <!-- <Tree :value="treeNodes" scrollHeight="flex" :filter="true" filterMode="lenient" @nodeSelect="onNodeSelect" @nodeUnselect="onNodeUnselect" selectionMode="multiple" :expandedKeys="expandedKeys" :metaKeySelection="false" :selectionKeys="selectedItems"></Tree> -->
      <Tree :value="treeNodes" scrollHeight="flex" :filter="true" filterMode="lenient" selectionMode="checkbox" v-model:selectionKeys="selectedItems" :expandedKeys="expandedKeys">
        <template #default="slotProps">
          <span>{{slotProps.node.label}}</span><span v-if="$store.state.precedenceLevel === 1"> - {{slotProps.node.data}}</span>
          <div v-if="$store.state.precedenceLevel === 1" class="flex justify-content-end">
            <Button class="mt-2 p-button-sm p-button-raised p-button-rounded adminCopyPathButton" @click="copyFilePath(slotProps.node.data)">Copy Path</Button>
          </div>
        </template>
      </Tree>
      <template #footer>
        <Button :disabled="selectedItem === null" class="mt-3 mb-3" type="button" icon="pi pi-download" label="Download" @click="download" />
      </template>
    </Dialog>
  </div>
</template>

<script>
// import { Auth } from 'aws-amplify';
import * as AWS from 'aws-sdk';
import axios from 'axios';

export default {
  /* eslint-disable no-param-reassign */
  props: ['pipeline'],
  data() {
    return {
      loading: false,
      treeNodes: null,
      dialogVisible: false,
      selectedItem: null,
      expandedKeys: {},
      selectedItems: {},
      selectedFiles: [],
      checkedItems: [],
      s3Contents: [],
    };
  },
  methods: {
    collapseAll() {
      this.expandedKeys = {};
    },
    expandAll() {
      for (const node of this.treeNodes) {
        this.expandNode(node);
      }
      this.expandedKeys = { ...this.expandedKeys };
    },
    expandNode(node) {
      this.expandedKeys[node.key] = true;
      if (node.children && node.children.length) {
        for (const child of node.children) {
          this.expandNode(child);
        }
      }
    },
    copyFilePath(textToCopy) {
      navigator.clipboard.writeText(textToCopy).then(() => {
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Path copied successfully!',
          life: 3000,
        });
      }, (err) => {
        console.error(err);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to copy path!',
          life: 3000,
        });
      });
    },
    download() {
      this.$confirm.require({
        // eslint-disable-next-line quotes
        message: `Are you sure you want to download the selected files?`,
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        accept: async () => {
          this.getSelectedKeys();
          if (this.dialogVisible) this.dialogVisible = false;
        },
        reject: () => {
          if (this.dialogVisible) this.dialogVisible = false;
        },
      });
    },
    // The mapping is a bit strange because of the way the Tree component lists files. We need to remap them in a more useful way.
    getSelectedKeys() {
      const checkedItems = [];
      for (const [key, value] of Object.entries(this.selectedItems)) {
        if (value.checked) {
          checkedItems.push(key);
        }
      }
      this.checkedItems = checkedItems;
      this.treeNodes.forEach((parentNode) => {
        this.getSelectedFiles(parentNode);
      });
      this.selectedFiles.forEach((file) => {
        this.getPresignedLink(file.data);
      });
      // Reset for the arrays so that we don't download already downloaded files again
      this.checkedItems = [];
      this.selectedFiles = [];
    },
    getSelectedFiles(node) {
      if (this.checkedItems.indexOf(node.key) !== -1 && !node.children) {
        this.selectedFiles.push(node);
      }
      if (node.children && node.children.length) {
        for (const child of node.children) {
          this.getSelectedFiles(child);
        }
      }
    },
    // Actually downloads the files
    async getPresignedLink(key) {
      const s3 = new AWS.S3();
      const params = { Bucket: process.env.VUE_APP_BUCKET, Key: key };
      const url = s3.getSignedUrl('getObject', params);
      axios({
        url, // File URL Goes Here
        method: 'GET',
        responseType: 'blob',
      }).then((res) => {
        const FILE = window.URL.createObjectURL(new Blob([res.data]));
        const docUrl = document.createElement('a');
        docUrl.href = FILE;
        docUrl.setAttribute('download', `${key.split('/').at(-1)}`);
        document.body.appendChild(docUrl);
        docUrl.click();
      });
    },
    // Loads the files from S3 and creates the necessary structure for the Tree component
    async getStructure(originalPath, token = null) {
      const s3 = new AWS.S3();
      let params = {};
      if (token == null) {
        params = {
          Bucket: process.env.VUE_APP_BUCKET,
          Prefix: originalPath,
        };
      } else {
        params = {
          Bucket: process.env.VUE_APP_BUCKET,
          Prefix: originalPath,
          ContinuationToken: token,
        };
      }
      console.log('params :>> ', params);
      try {
        s3.listObjectsV2(params, (err, data) => {
          if (data) {
            this.s3Contents.push(...data.Contents);
            if (!data.IsTruncated) {
              this.makeTreeStructure(this.s3Contents, originalPath);
            } else {
              this.getStructure(originalPath, data.NextContinuationToken);
            }
          }
          if (err) {
            this.loading = false;
            console.error(err);
          }
        });
      } catch (error) {
        console.error('Error with S3.getObject');
        console.error(error);
      }
    },
    makeTreeStructure(data, originalPath) {
      try {
        const splitedStrings = new Set();
        let splited = data.map((content) => {
          const pathSet = new Set();
          const spl = content.Key.split(`${originalPath}/`)[1];
          const aditionalSplit = spl.split('/');
          if (aditionalSplit.length > 1) {
            let str = '';
            for (let i = 0; i < aditionalSplit.length; i += 1) {
              if (aditionalSplit[i] !== '') {
                str = str.concat(aditionalSplit[i]);
                if (!str.includes('.')) {
                  str = str.concat('/');
                }
                pathSet.add(str);
                splitedStrings.add(str);
              }
            }
          }
          // return spl.split('/');
          return spl;
        });
        splited = [...splitedStrings];

        const exists = new Set(); // This will hold the direct children of the root
        const root = [];
        const indexMap = {}; // Should be called depthMap, here the depths will be stored
        // Create the initial object for the reduce
        let i = 0;
        while (splited[i] === '') {
          i += 1;
        }
        let firstSplit = splited[i].split('/');
        if (firstSplit[1] === '') {
          firstSplit = firstSplit[0];
        }
        let obj;
        if (!firstSplit.includes('.')) {
          obj = {
            key: '0',
            label: firstSplit,
            data: originalPath.concat('/', firstSplit),
            icon: 'pi pi-fw pi-folder',
            children: [],
          };
          indexMap[0] = 1;
          root.push(obj);
          exists.add(firstSplit);
        }
        // If the current element is a folder, return it as a new object. If the current element is a file, return its parent(the folder). This way, an object will always have its parent for prev.
        splited.reduce((prev, curr) => {
          if (curr === '') return prev;
          let splitedCurr = curr.split('/');
          let last;
          if (splitedCurr === '') return prev;
          if (splitedCurr.at(-1) === '') {
            last = splitedCurr.at(-2);
            splitedCurr = splitedCurr.slice(0, -1);
          } else {
            last = splitedCurr.at(-1);
          }
          if (last.includes('.')) { // Its a file
            const myDepth = splitedCurr.length - 1;
            if (indexMap[myDepth] === null || indexMap[myDepth] === undefined) {
              indexMap[myDepth] = 0;
            } else {
              indexMap[myDepth] += 1;
            }
            if (!(splitedCurr[myDepth - 1].split('.').at(0) === prev.label)) {
              const hisRoot = root.find((item) => item.label === splitedCurr.at(0));
              const parentToFind = splitedCurr[myDepth - 1];
              const parent = this.getObject(hisRoot, parentToFind);
              const myKey = parent.key.concat('-', indexMap[myDepth].toString());
              const fileObj = {
                key: myKey,
                label: last,
                data: parent.data.concat('/', last),
                icon: this.getIcon(false, last.split('.').at(-1)),
              };
              parent.children.push(fileObj);
              return parent;
            }
            if (myDepth > 0) {
              const myKey = prev.key.concat('-', indexMap[myDepth].toString());
              const fileObj = {
                key: myKey,
                label: last,
                data: prev.data.concat('/', last),
                icon: this.getIcon(false, last.split('.').at(-1)),
              };
              prev.children.push(fileObj);
              return prev;
              // eslint-disable-next-line no-else-return
            } else {
              const fileInRoot = {
                key: indexMap[myDepth].toString(),
                label: last,
                data: originalPath.concat('/', last),
                icon: this.getIcon(false, last.split('.').at(-1)),
              };
              root.push(fileInRoot);
              return prev;
            }
            // eslint-disable-next-line no-else-return
          } else { // Folders
            if (prev.label === last) {
              return prev;
            }
            // if (exists.has(last)) {
            //   return prev;
            // }
            if (splitedCurr.length === 1) { // one of roots children
              const rootObj = {
                key: indexMap[0].toString(),
                label: last,
                data: originalPath.concat('/', last),
                icon: this.getIcon(true, ''),
                children: [],
              };
              indexMap[0] += 1;
              for (const [key] of Object.entries(indexMap)) {
                if (key > 0) {
                  indexMap[key] = -1;
                }
              }
              root.push(rootObj);
              exists.add(last);
              return rootObj;
              // eslint-disable-next-line no-else-return
            } else { // Subfolders
              const mainParent = root.at(-1);
              const myDepth = splitedCurr.length - 1;
              let parent = mainParent;
              while ((parent.key.split('-').length) < myDepth) {
                parent = parent.children.at(-1);
              }
              for (const [key] of Object.entries(indexMap)) {
                if (key > myDepth) {
                  indexMap[key] = -1;
                }
              }
              if (indexMap[myDepth] === null || indexMap[myDepth] === undefined) {
                indexMap[myDepth] = 0;
              } else {
                indexMap[myDepth] += 1;
              }
              // const myKey = mainParent.key.concat('-', indexMap[myDepth].toString());
              const myKey = parent.key.concat('-', indexMap[myDepth].toString());
              const childeFolder = {
                key: myKey,
                label: last,
                // data: originalPath.concat('/', parent.data.concat('/', last)),
                data: parent.data.concat('/', last),
                icon: this.getIcon(true, ''),
                children: [],
              };
              parent.children.push(childeFolder);
              return childeFolder;
            }
          }
        }, obj);
        this.treeNodes = root;
        this.loading = false;
      } catch (error) {
        console.error('Problem with making the tree structure.');
        console.error(error);
      }
    },
    getObject(theObject, label) {
      let result = null;
      if (theObject instanceof Array) {
        for (let i = 0; i < theObject.length; i += 1) {
          result = this.getObject(theObject[i], label);
          if (result) {
            break;
          }
        }
      } else {
        // eslint-disable-next-line guard-for-in
        for (const prop in theObject) {
          if (prop === 'label') {
            if (theObject[prop] === label) {
              return theObject;
            }
          }
          if (theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
            result = this.getObject(theObject[prop], label);
            if (result) {
              break;
            }
          }
        }
      }
      return result;
    },
    getIcon(folder, extention) {
      if (folder) return 'pi pi-fw pi-folder';
      switch (extention) {
        case 'html':
          return 'pi pi-fw pi-paperclip';
        case 'gz':
          return 'pi pi-fw pi-box';
        case 'fastq':
          return 'pi pi-fw pi-file';
        case 'yml':
          return 'pi pi-fw pi-file';
        case 'json':
          return 'pi pi-fw pi-file';
        case 'vcf':
          return 'pi pi-fw pi-file';
        case 'bam':
          return 'pi pi-fw pi-file';
        case 'bai':
          return 'pi pi-fw pi-file';
        case 'csv':
          return 'pi pi-fw pi-database';
        default:
          return 'pi pi-fw pi-cog';
      }
    },
    openPipelineFolderInS3() {
      try {
        console.log('this.pipeline :>> ', this.pipeline);
        window.open(`https://s3.console.aws.amazon.com/s3/buckets/${process.env.VUE_APP_BUCKET}?region=us-east-1&prefix=${this.pipeline.project.workspace.organization.id}/${this.pipeline.project.workspace.id}/${this.pipeline.project.id}/pipeline/${this.pipeline.pipelineOutputS3Path}/&showversions=false`, '_blank');
        // window.open(`https://tower.nf/orgs/BioSkryb/workspaces/${remapEnvNamesForNFTowerURL(process.env.VUE_APP_SLS_STAGE)}/watch/${pipeline.workflowId}`, '_blank');
      } catch (error) {
        console.error(error);
      }
    },
  },
  async mounted() {
    // this.test();
    console.log('this.pipeline :>> ', this.pipeline);
    const key = `${this.pipeline.project.workspace.organization.id}/${this.pipeline.project.workspace.id}/${this.pipeline.project.id}/pipeline/${this.pipeline.pipelineOutputS3Path}`;
    this.loading = true;
    await this.getStructure(key);
  },
};
</script>

<style lang="scss">
.expand-btn {
  right:5px;
}
.first {
  position: relative;
  width: 50%;
}
.second {
  position: relative;
  width: 50%;
}
.adminCopyPathButton {
  height: 10px;
  width: 9%px;
  font-size: 10px;
  // text-align: start;
}

.spinner-vis-bg {
  width: 100%;
  height: 100vh;
  // min-height: 100vh;
  opacity: 0.85;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #ffffff;
  // overflow: hidden;
  display: -webkit-flex; /* Safari */
  display: flex; /* Standard syntax */
}

.bjSpinner {
  width:30%;
  height:30%;
}
</style>
