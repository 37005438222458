<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <DataTable
      :value="pipelines"
      :loading="loading"
      :paginator="true"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      @rowSelect="onPipelineSelect"
      :rowClass="rowClass"
      v-model:selection="selectedPipelinesData"
      :selectionMode="($store.state.runningTertiaryAnalysis) ? '' : 'single'">
      <template #empty>
        No pipelines found.
      </template>
      <template #loading>
        Loading pipelines... Please wait.
      </template>
      <template v-if="this.hasLinkedProject" #header>
        <div class="flex justify-content-end flex-column sm:flex-row">
          <Button
            type="button"
            label="Load linked projects pipelines"
            class="p-button-outlined mb-2"
            @click="loadLinkedProjectsPipelines"
          />
        </div>
      </template>
      <Column selectionMode="multiple" v-if="$store.state.runningTertiaryAnalysis" ref="selectionCol" />
      <Column field="pipelineName" header="Pipeline" :bodyStyle="getBodyStyle()" />
      <Column field="pipelineVersion" header="Version" :bodyStyle="getBodyStyle()" />
      <Column header="Number of Biosamples" :bodyStyle="getBodyStyle()">
        <template #body="{ data }">
          {{getNumberOfBiosamples(data.passFailBiosamples)}}
        </template>
      </Column>
      <Column header="Status" :bodyStyle="getBodyStyle()">
        <template #body="{ data }">
          <span :class="'customer-badge ' + statusClass(data.status)">{{data.status}}</span>
        </template>
      </Column>
      <Column header="Size" :bodyStyle="getBodyStyle()">
        <template #body="{ data }">
          {{ getSize(data.size) }}
        </template>
      </Column>
      <Column header="Duration" :bodyStyle="getBodyStyle()">
        <template #body="{ data }">
          <!-- {{ $filters.diffTime(data.pipelineStarted) }} -->
          {{ this.getTimeDiff(data) }}
        </template>
      </Column>
      <!-- <Column field="estimatedCredits" header="Credit Used" :bodyStyle="getBodyStyle()"/> -->
      <Column field="initiator" header="Initiator" :bodyStyle="getBodyStyle()" />
      <Column field="runDescription" header="Description" :bodyStyle="getBodyStyle()" />
      <Column header="Launch Date" :bodyStyle="getBodyStyle()">
        <template #body="{ data }">
          <!-- {{ $filters.formatTime(data.pipelineStarted) }} -->
          {{ this.getDate(data.pipelineStarted) }}
        </template>
      </Column>
      <Column headerStyle="width:4rem" :bodyStyle="getBodyStyle()" v-if="!$store.state.runningTertiaryAnalysis">
        <template #body="{ data }">
          <Button icon="pi pi-ellipsis-v" @click="actionClick(data, $event)" />
          <Menu :ref="'pipeline-menu-' + data.id" :model="actionItems(data)" :popup="true" />
        </template>
      </Column>
    </DataTable>
  </div>
  <PipelineParamTable
    :paramString="paramString"
    @clear="this.paramString = null"
  />
  <ExportDialog v-if="this.pipelineForExport" :pipeline="pipelineForExport" />
</template>

<script>
import { Auth, API, graphqlOperation } from 'aws-amplify';
import _ from 'lodash';
import * as queries from '@/graphql/queries';
import * as mutations from '@/graphql/mutations';
import * as subscriptions from '@/graphql/subscriptions';
import PipelineParamTable from '@/components/Pipeline/PipelineParamTable.vue';
import ExportDialog from '@/components/Utils/ExportDialog.vue';
import {
  // eslint-disable-next-line no-unused-vars
  sendEBMessage, getSize, remapEnvNamesForNFTowerURL, listItems, valueIsNullOrUndefined, filterValidPipelinesForViz,
} from '@/utils';

const CryptoJS = require('crypto-js');

export default {
  name: 'Pipelines',
  components: {
    PipelineParamTable,
    ExportDialog,
  },
  emits: ['updateData'],
  props: ['selectedPipelines', 'analysisLevel'],
  data() {
    return {
      loading: false,
      project: null,
      pipelineForExport: null,
      pipelines: [],
      limit: 300,
      pipelineStatusUpdateSubscription: null,
      paramString: null,
      hasLinkedProject: false,
      setAnalysisLevel: null,
      dialogVisible: false,
      selectedPipelinesData: this.selectedPipelines,
    };
  },
  async mounted() {
    this.project = this.getProject();
    this.setAnalysisLevel = this.getAnalysisLevel();
    await this.loadPipelines();
    if (this.$store.state.runningTertiaryAnalysis) {
      this.createTertiaryPipelineWatcher();
    }
    this.startPipelineSubscription();
  },
  methods: {
    startPipelineSubscription() {
      this.pipelineStatusUpdateSubscription = API.graphql(
        graphqlOperation(subscriptions.onUpdatePipeline),
      ).subscribe({
      // eslint-disable-next-line no-unused-vars
        next: ({ value }) => {
          if (this.$route.params.analysisLevel !== null && this.$route.params.analysisLevel !== undefined) {
            this.setAnalysisLevel = `${this.$route.params.analysisLevel}`;
            console.log('Loading from subscription');
            this.loadPipelines();
          }
        },
        error: (error) => console.warn(error),
      });
    },
    createTertiaryPipelineWatcher() {
      let completedPipelines = [];
      this.$watch(() => this.selectedPipelinesData, (value) => {
        if (value !== null && value !== undefined && value.length > 0) {
          value.forEach((selectedPipeline) => {
            if (selectedPipeline.status === 'Job Complete') {
              completedPipelines.push(selectedPipeline);
            }
          });
        }
        this.$emit('updateData', completedPipelines);
        completedPipelines = [];
      });
    },
    rowClass(data) {
      let classToReturn = '';
      if (data.projectId !== this.$route.params.id) {
        classToReturn += ' pipelines-from-linked';
        return 'pipelines-from-linked';
      }
      if (data.status !== 'Job Complete') {
        classToReturn += ' no-selection';
      }
      if (classToReturn !== '') return classToReturn;
      return null;
    },
    getSize(size) {
      return getSize(size);
    },
    onPipelineSelect(event) {
      console.log('event :>> ', event);
      if (this.$store.state.runningTertiaryAnalysis) return;
      if ('analysisLevel' in this.$route.params && this.$route.params.analysisLevel === 'all') {
        if (event.data.status !== 'Job Complete') {
          this.$toast.add({
            severity: 'warn',
            summary: 'Pipeline Not Available',
            detail: "You cannot visualize a pipeline which hasn't been completed successfully. Please contact a BaseJumper administrator or choose another pipeline.",
            life: 5000,
          });
          return;
        }
        this.setSelectedPipelinesForVisualization(event.data);
        return;
      }
      switch (event.data.status) {
        case 'Job Complete':
          this.$router.push({ path: `/workspace/${this.$store.state.activeWorkspace}/pipeline/${event.data.id}` });
          console.log('this.$store.state.resultPipeline :>> ', this.$store.state.resultPipeline);
          break;
        case 'Job Failed':
          // this.$router.push({ path: `/pipeline/${event.data.id}` });
          this.$toast.add({
            severity: 'warn',
            summary: 'Pipeline Failed',
            detail: 'Since this pipeline run failed, there are no results available!',
            life: 3000,
          });
          break;
        case 'Job Started':
          // this.$router.push({ path: `/pipeline/${event.data.id}` });
          this.$toast.add({
            severity: 'warn',
            summary: 'Pipeline Running',
            detail: 'This pipeline is still runnning. You must wait for it to finish before looking at the results!',
            life: 5000,
          });
          break;
        default:
          console.log('There is a problem with the pipeline run status');
      }
    },
    async setSelectedPipelinesForVisualization(selectedPipeline) {
      // const selectedPipelines = [selectedPipeline]; // Probably no need to store the whole pipeline, just what the visualizations need
      // const selectedPipelines = [{
      //   id: selectedPipeline.id,
      //   pipelineOutputS3Path: selectedPipeline.pipelineOutputS3Path,
      //   project: {
      //     id: selectedPipeline.project.id,
      //     workspace: {
      //       id: selectedPipeline.project.workspace.id,
      //       organization: {
      //         id: selectedPipeline.project.workspace.organization.id,
      //       },
      //     },
      //   },
      // }, {
      //   id: selectedPipeline.id,
      //   pipelineOutputS3Path: selectedPipeline.pipelineOutputS3Path,
      //   project: {
      //     id: selectedPipeline.project.id,
      //     workspace: {
      //       id: selectedPipeline.project.workspace.id,
      //       organization: {
      //         id: selectedPipeline.project.workspace.organization.id,
      //       },
      //     },
      //   },
      // }, {
      //   id: selectedPipeline.id,
      //   pipelineOutputS3Path: selectedPipeline.pipelineOutputS3Path,
      //   project: {
      //     id: selectedPipeline.project.id,
      //     workspace: {
      //       id: selectedPipeline.project.workspace.id,
      //       organization: {
      //         id: selectedPipeline.project.workspace.organization.id,
      //       },
      //     },
      //   },
      // }, {
      //   id: selectedPipeline.id,
      //   pipelineOutputS3Path: selectedPipeline.pipelineOutputS3Path,
      //   project: {
      //     id: selectedPipeline.project.id,
      //     workspace: {
      //       id: selectedPipeline.project.workspace.id,
      //       organization: {
      //         id: selectedPipeline.project.workspace.organization.id,
      //       },
      //     },
      //   },
      // }, {
      //   id: selectedPipeline.id,
      //   pipelineOutputS3Path: selectedPipeline.pipelineOutputS3Path,
      //   project: {
      //     id: selectedPipeline.project.id,
      //     workspace: {
      //       id: selectedPipeline.project.workspace.id,
      //       organization: {
      //         id: selectedPipeline.project.workspace.organization.id,
      //       },
      //     },
      //   },
      // }];
      const selectedPipelineExtracted = {
        id: selectedPipeline.id,
        pipelineOutputS3Path: selectedPipeline.pipelineOutputS3Path,
        project: {
          id: selectedPipeline.project.id,
          workspace: {
            id: selectedPipeline.project.workspace.id,
            organization: {
              id: selectedPipeline.project.workspace.organization.id,
            },
          },
        },
      };
      const secretKey = process.env.VUE_APP_ROUTE_ENCRYPTION_KEY;
      const message = JSON.stringify({
        pipeline: selectedPipelineExtracted,
        visualization: this.$store.state.visualizationToOpen,
      });
      const cipherText = CryptoJS.AES.encrypt(message, secretKey).toString().replace(/\//g, '!');
      this.$router.push({ path: `/workspace/${this.$store.state.activeWorkspace}/visualize/${cipherText}` });
    },
    statusClass(status) {
      return status.toLowerCase().replace(' ', '-');
    },
    getDuration(pipeline) {
      if (pipeline.pipelineCompleted) {
        return this.$filters.diffTime(pipeline.pipelineStarted, pipeline.pipelineCompleted);
      }
      return this.$filters.diffTime(pipeline.pipelineStarted, new Date());
    },
    getDate(date) {
      return date.split('T')[0];
    },
    // If the pipeline is done, calculate how long it ran. If its still running, calculate how long
    getTimeDiff(pipeline) {
      const startDate = Date.parse(pipeline.pipelineStarted);
      if (pipeline.pipelineCompleted) {
        const endDate = Date.parse(pipeline.pipelineCompleted);
        const diff = Math.abs(endDate - startDate);
        return this.msToTime(diff);
      }
      if (pipeline.status === 'Job Started') {
        const now = Date.now();
        const diff = Math.abs(startDate - now);
        return this.msToTime(diff);
      }
      return '';
    },
    // LookAndFeel time format
    msToTime(duration) {
      const seconds = Math.floor((duration / 1000) % 60);
      const minutes = Math.floor((duration / (1000 * 60)) % 60);
      const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
      const days = Math.floor((duration / (1000 * 60 * 60 * 24)) % 7);
      const weeks = Math.floor(duration / (1000 * 60 * 60 * 24 * 7));

      return `${(weeks > 1) ? `${weeks}w ` : ''}${(days > 1) ? `${days}d ` : ''} ${(hours > 1) ? `${hours}h ` : ''} ${(minutes > 1) ? `${minutes}m ` : ''} ${(seconds > 1) ? `${seconds}s ` : ''}`;
    },
    async loadPipelines() {
      this.loading = true;
      this.pipelines = (await this.loadPipelinesForProject(this.$route.params.id)).flat();
      if ('analysisLevel' in this.$route.params && this.$route.params.analysisLevel === 'all') {
        const onlyCompletedPipelines = this.pipelines.filter((pipeline) => pipeline.status === 'Job Complete');
        const validPipelines = await filterValidPipelinesForViz(onlyCompletedPipelines, this.$store.state.visualizationToOpen);
        this.pipelines = validPipelines;
        this.checkIfOnlyOneAvailablePipeline(validPipelines);
      }
      this.setProjectData();
      this.loading = false;
    },
    async setProjectData() {
      this.project = await this.project;
      if (this.project.associatedProjectId !== null) {
        this.hasLinkedProject = true;
      }
    },
    checkIfOnlyOneAvailablePipeline(filteredPipelines) {
      if (filteredPipelines.length === 1) this.setSelectedPipelinesForVisualization(filteredPipelines[0]);
    },
    async loadLinkedProjectsPipelines() {
      this.loading = true;
      const project = await this.project;
      const linkedProjectsPipelines = await this.loadPipelinesForProject(project.associatedProjectId);
      this.pipelines = this.pipelines.concat(linkedProjectsPipelines);
      this.loading = false;
    },
    async loadPipelinesForProject(projectId) {
      try {
        const params = this.makeParamsToGetPipelines(projectId, this.setAnalysisLevel);
        const pipelines = await listItems(queries.pipelinesByProject, params);
        return pipelines;
      } catch (error) {
        console.error('Error in loadPipelinesForProject');
        console.error(error);
        return [];
      }
    },
    makeParamsToGetPipelines(projectId, analysisLevel) {
      const params = {
        projectId,
        limit: this.limit,
        sortDirection: 'DESC',
      };
      if (analysisLevel === 'secondary' || analysisLevel === 'tertiary') params.filter = { analysisLevel: { eq: analysisLevel } };
      return params;
    },
    async getProject() {
      try {
        const response = await API.graphql(graphqlOperation(queries.getProject, {
          id: this.$route.params.id,
        }));
        return response.data.getProject;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    getBodyStyle() {
      return 'text-align: center';
    },
    actionClick(pipeline, event) {
      this.$refs[`pipeline-menu-${pipeline.id}`].toggle(event);
    },
    getNumberOfBiosamples(passFailBiosamplesString) {
      try {
        if (passFailBiosamplesString === null || passFailBiosamplesString === undefined || passFailBiosamplesString === '') {
          return 0;
        }
        const passFailBiosamplesJSON = JSON.parse(passFailBiosamplesString);
        return Object.keys(passFailBiosamplesJSON).length;
      } catch (error) {
        console.error('Error in setting number of biosamples');
        console.error(error);
        return 0;
      }
    },
    actionItems(pipeline) {
      const viewResults = {
        label: 'View Results',
        command: () => {
          this.$router.push({ path: `/workspace/${this.$store.state.activeWorkspace}/pipeline/${pipeline.id}` });
        },
      };
      const deleteMenu = {
        label: 'Delete',
        command: async () => {
          this.$confirm.require({
            message: 'Are you sure you want to delete the selected pipeline?',
            header: 'Confirmation',
            icon: 'pi pi-info-circle',
            accept: async () => {
              console.log('pipeline :>> ', pipeline);
              await this.deletePipeline(pipeline);
            },
            reject: () => {
            },
          });
        },
      };
      const viewParams = {
        label: 'View Parameters',
        command: async () => {
          this.paramString = pipeline.parameters;
        },
      };
      const exportPipeline = {
        label: 'Export',
        command: async () => {
          this.pipelineForExport = pipeline;
          this.$store.dispatch('setShowingExport', true);
        },
      };
      const openNFRun = {
        label: 'Open in NF tower',
        command: async () => {
          window.open(`https://tower.nf/orgs/BioSkryb/workspaces/${remapEnvNamesForNFTowerURL(process.env.VUE_APP_SLS_STAGE)}/watch/${pipeline.workflowId}`, '_blank');
        },
      };
      const cancelPipeline = {
        label: 'Cancel',
        command: async () => {
          this.$confirm.require({
            message: 'Are you sure you want to cancel?',
            header: 'Confirmation',
            icon: 'pi pi-info-circle',
            accept: async () => {
              this.cancel(pipeline.id, pipeline.pipelineName, pipeline.project.workspace.organizationId, pipeline.project.workspaceId, pipeline.projectId);
            },
            reject: () => {
              // callback to execute when user rejects the action
            },
          });
          if (this.dialogVisible) this.dialogVisible = false;
        },
      };
      if (this.$store.state.precedenceLevel >= 4) {
        return [viewResults, viewParams, exportPipeline];
      }
      const options = [viewResults, viewParams, exportPipeline, deleteMenu];
      if (this.$store.state.precedenceLevel === 1) options.push(openNFRun);
      if (pipeline.status === 'Submitted' || pipeline.status === 'Job Started') options.push(cancelPipeline);
      return options;
    },
    async cancel(pipelineId, pipelineName, organizationId, workspaceId, projectId) {
      try {
        const details = {
          step: 'stop_workflow', analysis: '', pipeline: pipelineName, pipeline_uuid: pipelineId,
        };
        sendEBMessage(details, organizationId, workspaceId, projectId, 'cancel');
      } catch (error) {
        console.error(error);
      }
    },
    async deletePipeline(pipeline) {
      this.createDeletionLog(pipeline);
      const pipelineVariables = {
        id: pipeline.id,
      };
      try {
        const filtered = _.cloneDeep(this.pipelines.filter((filterPipeline) => pipeline.id !== filterPipeline.id));
        this.pipelines = filtered;
        await API.graphql(graphqlOperation(mutations.deletePipeline, { input: pipelineVariables }));
      } catch (error) {
        console.error('pipeline delete error - ', error);
      }
    },
    async createDeletionLog(pipeline) {
      try {
        if (pipeline === null || pipeline === undefined) {
          throw new Error('Pipeline is null in createDeletionLog');
        }
        const user = await Auth.currentAuthenticatedUser();
        const deleteLogObj = {
          organizationId: pipeline.project.workspace.organizationId,
          organizationName: pipeline.project.workspace.organization.organizationName,
          workspaceId: pipeline.project.workspace.id,
          workspaceName: pipeline.project.workspace.description,
          projectName: pipeline.project.clientProjectName,
          pipelineName: pipeline.pipelineName,
          userUuidCognito: user.username,
          userNameCognito: user.attributes.email,
          uuidOfDeletedEntity: pipeline.id,
          deletedInParent: false,
          typeOfDeletedEntity: 'pipeline',
          status: 'GraphQlDeletion',
          affectedBiosamples: JSON.stringify(this.makeAffectedBiosamplesFromPipeline(pipeline)),
          dateOfCreationOfDeletedEntity: pipeline.created,
          emailInfo: JSON.stringify(this.makeEmailInfoFromPipeline(pipeline)),
          readGroups: pipeline.readGroups,
          writeGroups: pipeline.writeGroups,
          adminGroups: pipeline.adminGroups,
        };
        await API.graphql(graphqlOperation(mutations.createDeletedEntitiesLog, { input: deleteLogObj }));
      } catch (error) {
        console.error(error);
      }
    },
    // eslint-disable-next-line no-unused-vars
    makeAffectedBiosamplesFromPipeline(pipeline) {
      if (pipeline.passFailBiosamples === null || pipeline.passFailBiosamples === undefined) {
        return [];
      }
      return [];
    },
    makeEmailInfoFromPipeline(pipeline) {
      return {
        application_environment: process.env.VUE_APP_SLS_STAGE,
        project_name: pipeline.project.clientProjectName,
        project_uuid: pipeline.project.id,
        initiator: pipeline.project.initiator,
        workspace_uuid: pipeline.project.workspace.id,
        workspace_name: pipeline.project.workspace.description,
        organization_uuid: pipeline.project.workspace.organization.id,
        organization_name: pipeline.project.workspace.organization.organizationName,
        commit_id: process.env.VUE_APP_AWS_COMMIT_ID,
      };
    },
    getAnalysisLevel() {
      try {
        if ('analysisLevel' in this.$route.params) {
          return `${this.$route.params.analysisLevel}`;
        }
        return null;
      } catch (error) {
        console.error('Error getting analysisLevel');
        console.error(error);
        return null;
      }
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingExport': async function () {
      if (!this.$store.state.showingExport) {
        this.pipelineForExport = null;
      }
    },
    // eslint-disable-next-line func-names
    '$route.params.analysisLevel': async function () {
      // console.log('Changed');
      // console.log('this.$route.params.analysisLevel :>> ', this.$route.params.analysisLevel);
      if (this.$route.params.analysisLevel !== null && this.$route.params.analysisLevel !== undefined) {
        this.setAnalysisLevel = `${this.$route.params.analysisLevel}`;
        console.log('Loading from watch');
        this.loadPipelines();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/sass/_dataTable.scss";
::v-deep(.pipelines-from-linked) {
    background-color: rgba(255,255,255,.7) !important;
}

// ::v-deep(.p-datatable .p-datatable-thead > tr > th:first-child) {
//   border-radius: 15px 0px 0px 0px;
// }
// ::v-deep(.p-datatable .p-datatable-thead > tr > th:last-child) {
//   border-radius: 0px 15px 0px 0px;
// }

::v-deep(.no-selection .p-selection-column .p-checkbox .p-component) {
  display: none;
}
::v-deep(.p-datatable .p-datatable-tbody > tr.p-highlight.no-selection ) {
  background: white;
}
::v-deep(.no-selection .p-checkbox) {
  display: none;
}
</style>
