<template>
  <div v-if="!passedPipeline && !passedVisualization" class="header">
    <Breadcrumb class="breadcrumb" :home="breadcrumbHome" :model="breadcrumbItems" />
  </div>
  <div class="flex justify-content-end pb-2">
    <Button v-if="!valueIsNullOrUndefined(visualizationToAddId)" class="p-button-secondary mr-2" icon="pi pi-times" @click="closeNewVizTable()" label="Close" />
    <Button @click="addNewVisualization()" label="Open another visualization" />
  </div>
  <div class="par" ref="par">
    <ParallelVis v-if="!valueIsNullOrUndefined(visualizationToAddId)" @addVisualization="addVisualization" />
    <!-- eslint-disable-next-line vue/no-unused-vars -->
    <div v-for="([id, { visualization, pipeline }], index) in Object.entries(openVisualizationsMap)" :key="id" style="{ width:100% }" class="sdd">
      <draggable-resizable-vue
        v-model:h="openVisualizationsMap[id].h"
        v-model:w="openVisualizationsMap[id].w"
        v-model:x="openVisualizationsMap[id].x"
        v-model:y="openVisualizationsMap[id].y"
        style="position: absolute; padding-bottom: 10px;"
        handles-type="borders"
        :onResize="onResizeCallback"
        :onDrag="onDragCallback"
        :draggable="openVisualizationsMap[id].draggable"
        :active-on-hover="true">
        <div class="viz-container">
          <div class="flex mb-1 btn-div">
            <Button :class="(openVisualizationsMap[id].draggable) ? 'draggable-btn-active' : 'draggable-btn-disabled'" rounded label="Toggle Draggable" @click="openVisualizationsMap[id].draggable = !openVisualizationsMap[id].draggable" />
            <Button class="close-vis-btn p-button-secondary" rounded label="Close" @click="closeVis(id)" />
          </div>
          <Visualization v-if="!valueIsNullOrUndefined(pipeline)" :pipeline="pipeline" :passedVisualization="visualization" />
        </div>
      </draggable-resizable-vue>
    </div>
  </div>
</template>

<script>
import { valueIsNullOrUndefined, setPrecedence } from '@/utils';
import Visualization from '@/components/Pipeline/Results/Visualization.vue';
import ParallelVis from '@/components/Visualization/ParallelVis .vue';
import DraggableResizableVue from 'draggable-resizable-vue3';
import { v4 as uuidV4 } from 'uuid';

const CryptoJS = require('crypto-js');

export default {
  name: 'Visualize',
  props: ['passedPipeline', 'passedVisualization'],
  components: { Visualization, ParallelVis, DraggableResizableVue },
  data() {
    return {
      visualization: null,
      pipeline: null,
      breadcrumbHome: { icon: 'pi pi-home', to: `/workspace/${this.$store.state.activeWorkspace}/projects/${(this.$store.state.bioskrybProduct === 'ResolveOME') ? 'OME' : 'DNA'}` },
      breadcrumbItems: [],
      openVisualizationsMap: {},
      projectForPipelineTable: null,
      visualizationToAddId: null,
    };
  },
  async mounted() {
    this.loading = true;
    if (!valueIsNullOrUndefined(this.$route.params.vizDataToken)) {
      await this.tokenPassedWorkflow(this.$route.params.vizDataToken[0]);
      this.loading = false;
      return;
    }
    if (!valueIsNullOrUndefined(this.passedPipeline) && !valueIsNullOrUndefined(this.passedVisualization)) {
      this.populatePipelineAndVisualization(this.passedPipeline, this.passedVisualization);
      this.loading = false;
    }
  },
  methods: {
    async tokenPassedWorkflow(vizDataToken) {
      await setPrecedence();
      const cipherText = vizDataToken.replace(/!/g, '/');
      const secretKey = process.env.VUE_APP_ROUTE_ENCRYPTION_KEY;
      const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
      const originalText = bytes.toString(CryptoJS.enc.Utf8);
      const data = JSON.parse(originalText);
      this.populatePipelineAndVisualization(data.pipeline, data.visualization);
    },
    populatePipelineAndVisualization(pipeline, visualization) {
      if (valueIsNullOrUndefined(pipeline) || valueIsNullOrUndefined(visualization)) console.error('Viz or pipeline is null');
      this.pipeline = pipeline;
      this.breadcrumbItems = [
        { label: 'Project', to: `/workspace/${this.$route.params.workspaceId}/project/${pipeline.project.id}/biosamples` },
        { label: 'Pipelines', to: `/workspace/${this.$store.state.activeWorkspace}/project/${pipeline.project.id}/pipelines/all` },
        { label: 'Visualizations', to: `/workspace/${this.$route.params.workspaceId}/pipeline/${pipeline.id}?tab=visualization` },
      ];
      this.visualization = visualization;
      this.addBasicViz({ visualization, pipeline });
    },
    addBasicViz({ visualization, pipeline }) {
      const id = uuidV4();
      this.openVisualizationsMap[id] = {
        visualization,
        pipeline,
        w: this.calculateInitialWidth(),
        h: this.calculateInitialHeight(),
        x: 0,
        y: this.calculateY(),
        draggable: false,
      };
      this.extendParent();
    },
    addNewVisualization() {
      try {
        this.extendParentByNPixels(800);
        this.visualizationToAddId = uuidV4();
      } catch (error) {
        console.error(error);
      }
    },
    closeNewVizTable() {
      this.extendParentByNPixels(-800);
      this.visualizationToAddId = null;
    },
    addVisualization(dataToVisualize) {
      this.extendParentByNPixels(-800);
      let w = this.calculateInitialWidth();
      let x = 0;
      let y = this.calculateY();
      let h = this.calculateInitialHeight();
      if (Object.keys(this.openVisualizationsMap).length % 2 === 1) {
        const vizBefore = Object.values(this.openVisualizationsMap).slice(-1)[0];
        console.log('vizBefore :>> ', vizBefore);
        w /= 2;
        x = vizBefore.x + w;
        y = vizBefore.y;
        vizBefore.w /= 2;
        h -= 15; // -15 pixels for the button offset
      }
      this.openVisualizationsMap[this.visualizationToAddId] = {
        visualization: dataToVisualize.visualization,
        pipeline: dataToVisualize.pipeline,
        w,
        h,
        x,
        y,
        draggable: false,
      };
      this.extendParent();
      this.visualizationToAddId = null;
    },
    extendParentByNPixels(n) {
      const newParentHeight = parseInt(this.$refs.par.style.height.replace('px', ''), 10) + n;
      this.$refs.par.style.height = `${newParentHeight}px`;
    },
    setProjectForMiniPipelineTable(project) {
      this.projectForPipelineTable = project;
    },
    calculateY() {
      return this.getLowestVisBottom(this.openVisualizationsMap) + 15; // +15 pixels for the button offset
    },
    // eslint-disable-next-line no-unused-vars
    onResizeCallback(handle, x, y, width, height) {
      console.log('x + width :>> ', x + width);
      return this.extendParent();
    },
    // eslint-disable-next-line no-unused-vars
    onDragCallback(x, y) {
      console.log('x :>> ', x);
      return this.extendParent();
    },
    extendParent() {
      this.extendParentHeightByLowestChild();
      if (!this.extendParentWidthByRightestChild()) return false;
      return true;
    },
    extendParentHeightByLowestChild() {
      const lowestChildPoint = this.getLowestVisBottom(this.openVisualizationsMap);
      this.$refs.par.style.height = `${lowestChildPoint}px`;
      return true;
    },
    extendParentWidthByRightestChild() {
      const rightestChildPoint = this.getRightestVis(this.openVisualizationsMap);
      this.$refs.par.style.width = `${rightestChildPoint}px`;
      document.body.style.width = `${rightestChildPoint + 60}px`;
      document.body.style['min-width'] = '99.5vw';
      return true;
    },
    getLowestVisBottom(openVisualizationsMap) {
      let max = -1;
      // eslint-disable-next-line no-unused-vars
      for (const [id, value] of Object.entries(openVisualizationsMap)) {
        const res = value.y + value.h;
        if (res > max) {
          max = res;
        }
      }
      return Math.max(0, max);
    },
    getRightestVis(openVisualizationsMap) {
      let max = -1;
      // eslint-disable-next-line no-unused-vars
      for (const [id, value] of Object.entries(openVisualizationsMap)) {
        const res = value.x + value.w;
        if (res > max) {
          max = res;
        }
      }
      return Math.max(0, max);
    },
    getMaxScreenWidth() {
      return 300;
    },
    calculateInitialHeight() {
      console.log('this.$refs.par.offsetHeight :>> ', this.$refs.par.offsetHeight);
      if (this.$refs.par.offsetHeight === 0) return window.innerHeight;
      return this.$refs.par.offsetHeight;
    },
    calculateInitialWidth() {
      return this.$refs.par.offsetWidth;
    },
    closeVis(id) {
      const fullWidth = this.calculateInitialWidth();
      if (Object.keys(this.openVisualizationsMap).length % 2 === 0) {
        const prevVisIndex = this.getPrevVisIndex(Object.keys(this.openVisualizationsMap), id);
        this.checkPrevVis(prevVisIndex, fullWidth);
        this.checkNextVis(prevVisIndex + 1, fullWidth);
      }
      if (this.openVisualizationsMap[id].w === fullWidth && this.isNotLastViz()) this.extendParentByNPixels(-this.openVisualizationsMap[id].h);
      delete this.openVisualizationsMap[id];
    },
    checkPrevVis(prevVisIndex, fullWidth) {
      try {
        const prevVis = this.openVisualizationsMap[Object.keys(this.openVisualizationsMap)[prevVisIndex]];
        if (valueIsNullOrUndefined(prevVis)) return;
        if (prevVis.w === (fullWidth / 2)) prevVis.w *= 2;
      } catch (error) {
        console.error(error);
      }
    },
    checkNextVis(nextIndex, fullWidth) {
      try {
        const nextVis = this.openVisualizationsMap[Object.keys(this.openVisualizationsMap)[nextIndex]];
        if (valueIsNullOrUndefined(nextVis)) return;
        if (nextVis.w === (fullWidth / 2)) {
          nextVis.w *= 2;
          nextVis.x *= 0;
        }
      } catch (error) {
        console.error(error);
      }
    },
    getPrevVisIndex(visIds, visId) {
      try {
        let prevInd = -1;
        let currInd = 0;
        while (visIds[currInd] !== visId) {
          prevInd = currInd;
          currInd += 1;
          if (currInd === visIds.length) break;
        }
        if (prevInd > -1) return prevInd;
        return null;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    isNotLastViz() {
      return Object.keys(this.openVisualizationsMap).length > 1;
    },
    valueIsNullOrUndefined(value) {
      return valueIsNullOrUndefined(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  margin-bottom: 1.5rem;
}

.viz-container {
  width: calc(100% - 3px);
  height: calc(100% - 15px);
}
.par {
  width: 100%;
  // height: 90vh;
}

.draggable-btn-active {
  background: rgb(45, 158, 45);
  border: 1px solid rgb(34, 119, 34);
  height: 1vh;

  &:hover {
    background: rgb(34, 119, 34);
    border: 1px solid rgb(21, 75, 21);
  }
}

.draggable-btn-disabled {
  background: rgb(175, 53, 53);
  border: 1px solid rgb(126, 38, 38);
  height: 1vh;
  &:hover {
    background: rgb(126, 38, 38);
    border: 1px solid rgb(95, 28, 28);
  }
}

.btn-div {
  position: relative;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.27);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 15px 15px 15px 15px !important;
  display: table;
}

.close-vis-btn {
  height: 1vh;
  margin-left: 5px;
}
</style>
