<template>
  <div class="text">
    <iframe title="tAndC" class="textIFrame" :srcdoc="TaS" style="height: 85vh;" />
  </div>
</template>

<script>
import TaS from '@/assets/BioSkryb - BaseJumper Terms and Conditions - WSGR 1-19-23 - HTML.htm';

export default {
  data() {
    return {};
  },
  computed: {
    TaS() {
      let tmp = TaS;
      tmp = tmp.replace('“|”|’', '"');
      return tmp;
    },
  },
};
</script>

<style lang="scss" scoped>
.textIFrame {
  height: 70vh;
  width: 100%;
  border:none;
  background-color: #FFF !important;
  border-radius: 5px;
}
</style>
