<template>
  <div class="p-fluid my-accrodion">
    <h1 class="welcome">Welcome to the BaseJumper Help page!</h1>
    <Accordion :multiple="true">
      <!-- <AccordionTab v-for="tab in tabs" :key="tab.title" :header="tab.title">
        <p>{{ tab.content }}</p>
          <Image :src="require('@/assets/LotId.png')"
           alt="LotId image" width="250" v-if="tab.title == 'LotId'" preview>
          </Image>
      </AccordionTab> -->
      <AccordionTab header="Requesting workspace access and Selecting workspace">
        <div class="container my-accrodion-content">
          <h3>Requesting workspace access and Selecting workspace</h3>
          <br>
          <span>If you have just registered, you will be assigned to Bioskryb Demo Organization and Bioskryb Demo Workspace. You will have limited access untill an administrator assigns you into a workspace.</span>
          <br>
          <span>You will see the current workspace you have selected in the top right of you screen.</span>
          <div class="col-12 pt-5 pb-5">
            <Image
              :src="require('@/assets/workspace.png')"
              alt="Worksapce Image"
              width="250"
              preview />
          </div>
          <span>When you open the dropdown menu, you will see all workspace you currently have access to.</span>
          <div class="col-12 pt-5 pb-5">
            <Image
              :src="require('@/assets/workspace_open.png')"
              alt="Open Workspace Image"
              width="250"
              preview />
          </div>
          <span>You can either scroll down or search in the seach box for the workspace you whant.  After you have selected a workspace, all projects within that workspace will be loaded.</span>
        </div>
      </AccordionTab>
      <AccordionTab header="Creating a New Project" style="overflow: scroll; max-height: 10px;">
        <div class="container my-accrodion-content">
          <h3>Creating a New Project</h3>
          <span>If you have selected a workspace, you should see all of the projects within that workspace in a table. If you see a message "No projects found", it means that the currently selected workspace is mepty.</span>
          <br>
          <div class="pt-4">
            <span>If you wish to create a new projects, select the "New Project" button at the top right of the table</span>
          </div>
          <div class="pt-3">
            <Image
              :src="require('@/assets/newProject.png')"
              alt="New Project Image"
              class="newProject"
              width="800"
              preview />
          </div>
          <div class="pt-3 pb-3">
            <span>Once selected, you will see a project creation dialog. If you see a loading spinner wait a few seconds for all of the available biosamples to be loaded from the Base Space Sequence Hub.</span>
          </div>
          <div class="pt-3">
            <Image
              :src="require('@/assets/newProjectDialog.png')"
              alt="New Project Dialog Image"
              width="500"
              preview />
          </div>
          <br>
          <div>
            List of available inputs:
            <ul>
              <li>
                <b>Project Name</b> - The name of your project. Cannot contain special characters.
              </li>
              <li>
                <b>Bioskryb Product</b> - List of all available Bioskryb products.
              </li>
              <li>
                <b>Lot Id</b> - The lot id you received on your reagent box. Clicking the picutre icon or going to the "LotId" par of this help page will show you where on the box the lot id is located.
              </li>
              <li>
                <b>Sequencing Library Preperation</b> - List of all available sequenceing types.
              </li>
              <li>
                <b>Project Name</b> - List of all available projects from BaseSpace Sequence Hub. If you select a project(or multiple), only the biosamples belonging to that project will be show in the Biosample Name input field. You have the option to import all of the Biosamples belonging to that project by clicking the arrow button that will appear on the left of the input field.
              </li>
              <li>
                <b>Biosample Name</b> - If no projects wore selected, all of the available biosamples will be shown.
              </li>
            </ul>
            <div>
              <span>Clicking on create project will add the project to the data table. Upon creation, the projects status will be "No Data". The status will change after a few seconds to "Job Started", this indicates that the download process from BaseSpace Sequence Hub to the project specific S3 location has started. Once the download is finished, the status will change to "Job Complete".</span>
            </div>
          </div>
        </div>
      </AccordionTab>
      <AccordionTab class="acordion" header="LotId">
        <div class="container my-accrodion-content">
          <h3>LotId</h3>
          <span>You will find your lot id on the underside of the box of reagents you got from Bioskryb. Bellow is a picutre to show the exact location (click to preview).</span>

          <div class="pt-3">
            <Image
              :src="require('@/assets/LotId.png')"
              alt="LotId image"
              width="250"
              preview />
          </div>
        </div>
      </AccordionTab>
      <AccordionTab header="Launching Pipeline">
        <h3>Launching Pipeline</h3>
        <span>The launch pipeline option will only be available if a project has finished downloading files from the S3 bucket. This will be indicated by the project status. If your porject status is "Job Complete", then you can launch your pipeline</span>
        <br>
        There are two ways of launching a pipeline:
        <ul>
          <li>Clicking on the project menu on the right side of the project tab:
            <div class="pt-3">
              <Image
                :src="require('@/assets/ProjectOutput.png')"
                alt="LotId image"
                width="250"
                preview />
            </div>
            <br>
            <span>Using this method will launch the pipeline with all of the biosamples loaded in your project.</span>
          </li>
          <li>
            Clicking on a project will open the specific project view. In the top right off the view will be the launch pipeline button.
            <div class="pt-3">
              <Image
                :src="require('@/assets/ProjectLP.png')"
                alt="LotId image"
                width="250"
                preview />
            </div>
            <br>
            <span>Here you have the option to select with which biosamples you wish to run your pipeline.</span>
          </li>
        </ul>
        <br>
        <span>The launch pipeline dialog is split into 3 steps.</span>
        <h5>Step 1. Select pipeline and pipeline version to run</h5>
        <div class="pt-3">
          <Image
            :src="require('@/assets/Launch1.png')"
            alt="LotId image"
            width="250"
            preview />
        </div>
        <br>
        <span>Here you will see all of the available pipleine version grouped by name. Clicking on the desired version in the group will take you to the second step.</span>
        <h5>Step 2. Set pipeline parameters</h5>
        <div class="pt-3">
          <Image
            :src="require('@/assets/Launch2.png')"
            alt="LotId image"
            width="250"
            preview />
        </div>
        <br>
        <span>Every pipeline has module specific and module non-specific parameters. The first set of parameters will be the module non-specific ones.</span>
        <span>The moduel specific parameters are seperated by module. Every module will have a switch to indicate whether you whant to use it. If you dont see any parameters for a module try fliping the switch. It is also possible that the module has no parameters. </span>
        <br>
        <span>Once you are done selecting your pipeline parameters you can click check and go to the final step.</span>
        <h5>Step 3. Overview</h5>
        <div class="pt-3">
          <Image
            :src="require('@/assets/Launch3.png')"
            alt="LotId image"
            width="250"
            preview />
        </div>
        <br>
        <span>This is just an overview of all of the paremeters you have chosen so far. Clickin on "Launch Pipeline" will lauch your pipeline.</span>
      </AccordionTab>
    </Accordion>
    <!-- <img src="../../assets/LotId.png" width="0" alt=""> -->
  </div>
</template>

<script>
export default {
  name: 'Help',
  data() {
    return {
      // The tabs can be parsed as a list but writing them manualy give more control
      tabs: [
        { title: 'Requesting workspace access', content: 'Requesting workspace access' },
        { title: 'Selecting workspace', content: 'Selecting workspace' },
        { title: 'LotId', content: 'You can enter the Lot Id in the project creation panel' },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/sass/_accordion.scss";

.welcome {
  color: white;
}

li {
  padding-top: 10px;
}

</style>
