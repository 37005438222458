<template>
  <div>
    <div class="header">
      <Transition name="topFade">
        <div v-if="!hideUI">
          <Breadcrumb class="breadcrumb pb-3" :home="breadcrumbHome" :model="breadcrumbItems" />
          <div class="flex">
            <h5 class="projectName mr-5">{{ (pipeline) ? pipeline.project.clientProjectName : '' }}</h5>
            <Button class="nf-open-btn" v-if="$store.state.precedenceLevel === 1 && pipeline !== null && pipeline !== undefined" label="Open in NF tower" @click="openNFTowerRun(pipeline.workflowId)" />
          </div>
          <TabMenu class="pt-1 my-tab-menu" :model="tabMenuItems" v-model:activeIndex="activeIndex" @click="handleTabChange" />
        </div>
      </Transition>
      <div v-if="this.pipeline !== null">
        <keep-alive>
          <component :is="activeComponent" :pipeline="pipeline">
            <template #toggleTopUI>
              <!-- <div class="flex justify-content-end"> -->
              <div>
                <Button class="mt-2 mb-2 mr-3 p-button-raised" :icon="'pi ' + ((!this.hideUI) ? ' pi-eye-slash ' : 'pi-eye')" :label="(!this.hideUI) ? 'Hide top UI' : 'Show top UI'" @click="toggleHideUI()" />
              </div>
              <!-- </div> -->
            </template>
          </component>
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '@/graphql/queries';
import PFTable from '../../components/Pipeline/Results/PFTable.vue';
import MultiQC from '../../components/Pipeline/Results/MultiQC.vue';
// import Visualization from '../../components/Pipeline/Results/Visualization.vue';
import VisualizationCardSelector from '@/components/Visualization/VisualizationCardSelector.vue';
import S3FileExplorer from '../../components/Pipeline/Results/S3FileExplorer.vue';
import { remapEnvNamesForNFTowerURL } from '@/utils';

export default {
  components: {
    PFTable,
    MultiQC,
    VisualizationCardSelector,
    S3FileExplorer,
  },
  name: 'Pipeline Results',
  data() {
    return {
      breadcrumbHome: { icon: 'pi pi-home', to: `/workspace/${this.$store.state.activeWorkspace}/projects` },
      breadcrumbItems: [
      ],
      tabMenuItems: [
        {
          label: 'MultiQC',
        },
        {
          label: 'File Explorer',
        },
        {
          label: 'Visualization',
        },
        // {
        //   label: 'Pass/Fail Table',
        // },
      ],
      activeIndex: 0,
      activeTab: '',
      pipeline: null,
      hideUI: false,
    };
  },
  created() {
    const tab = this.$route.query.tab || 'multiqc';
    this.tabMenuItems.forEach((item, index) => {
      if (item.label.toLowerCase().replace(/ /g, '-') === tab) {
        this.activeIndex = index;
      }
    });
  },
  async mounted() {
    document.title = 'Pipeline Results';
    this.pipeline = await this.getPipeline();
    this.checkForPFTable();
    // console.log('Pipeline in results');
    // console.log('this.pipeline :>> ', this.pipeline);
    if (this.pipeline === null) {
      console.error('Pipeline is null');
      return;
    }
    this.breadcrumbItems.push(
      { label: 'Project', to: `/workspace/${this.$store.state.activeWorkspace}/project/${this.pipeline.project.id}/biosamples/` },
      {
        label: 'Pipelines',
        to: `/workspace/${this.$store.state.activeWorkspace}/project/${this.pipeline.project.id}/pipelines/secondary`,
      },
      {
        label: this.tabMenuItems[this.activeIndex].label,
        to: `/workspace/${this.$store.state.activeWorkspace}/pipeline/${this.$route.params.id}?tab=${this.activeTab}`,
      },
    );
  },
  methods: {
    toggleHideUI() {
      this.hideUI = !this.hideUI;
    },
    async getPipeline() {
      try {
        const { data } = await API.graphql(graphqlOperation(queries.getPipeline, { id: this.$route.params.id }));
        return data.getPipeline;
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    checkForPFTable() {
      if (this.pipeline.pipelineName === 'Preseq') {
        this.tabMenuItems.push({
          label: 'Pass/Fail Table',
        });
      }
    },
    handleTabChange() {
      const menuLabel = this.tabMenuItems[this.activeIndex].label;
      this.activeTab = menuLabel.toLowerCase().replace(/ /g, '-');
      this.$router.push({ query: { tab: this.activeTab } });
      if (this.breadcrumbItems[this.breadcrumbItems.length - 1].label !== 'Pipelines') {
        this.breadcrumbItems.pop();
      }
      this.breadcrumbItems.push({ label: menuLabel, to: `/workspace/${this.$store.state.activeWorkspace}/pipeline/${this.$route.params.id}?tab=${this.activeTab}` });
    },
    openNFTowerRun(workflowId) {
      window.open(`https://tower.nf/orgs/BioSkryb/workspaces/${remapEnvNamesForNFTowerURL(process.env.VUE_APP_SLS_STAGE)}/watch/${workflowId}`, '_blank');
    },
  },
  computed: {
    activeComponent() {
      switch (this.activeIndex) {
        case 0:
          return MultiQC;
        case 1:
          return S3FileExplorer;
        case 2:
          return VisualizationCardSelector;
        case 3:
          return PFTable;
        default:
          return null;
      }
    },
  },
};
</script>

<style lang="scss">
 @import "@/assets/styles/sass/_dataTable.scss";
  .projectName {
    color: white;
    transform: translateY(50%);
  }
  .nf-open-btn {
    height: 10px;
    transform: translateY(30%);
  }
</style>
