<template>
  <div class="no-grp-view flex justify-content-center align-items-center">
    <div class="container no-grp-text">
      <h1>404!</h1>
      <h3>You are seeing this because you have entered an invalid route. This page does not exist!</h3>
    </div>
  </div>
</template>

<style lang="scss">
.welcome {
  color: white;
}

.windows {
  background-color: white;
}

.no-grp-view{
  // background-image: url('~@/assets/BioSkryb Chalkboard XL.jpeg');
  background-size: cover;
  position: relative;
  height: 50vh;
  background-color: white;
  opacity: 0.8;
  border-radius: 25px;
}

.no-grp-text {
  text-align: center;
}
</style>
