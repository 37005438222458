<template>
  <Dialog v-model:visible="showing" :style="{ width: '450px' }" :header="isNew ? 'Create Organization' : 'Edit Organization'" :modal="true" class="p-fluid" :closable="false">
    <Message severity="error" v-if="this.inputFailed" :closable="false">Invalid input! {{ errorMsg }}</Message>
    <div class="field pt-4">
      <span class="p-float-label">
        <InputText id="organizationName" :class="(validateOrganizationName() === false) ? 'p-invalid' : ''" v-model.trim="organizationName" required="true" autofocus />
        <label for="organizationName">Organization Name</label>
      </span>
      <InlineMessage :style="(validateOrganizationName() === false) ? '' : 'display: none;'">Organization name can only contain letters and numbers!</InlineMessage>
    </div>
    <div class="field pt-4">
      <span class="p-float-label">
        <InputText id="bioskrybClientId" :class="(validateBioSkrybClientId() === false) ? 'p-invalid' : ''" v-model.trim="bioskrybClientId" required="true" />
        <label for="bioskrybClientId">BioSkryb Client Id</label>
      </span>
      <InlineMessage :style="(validateBioSkrybClientId() === false) ? '' : 'display: none;'">Bioskryb Client Id can only contain letters and numbers!</InlineMessage>
    </div>
    <div class="field pt-4">
      <span class="p-float-label">
        <InputText id="zohoId" :class="(validateZohoId() === false) ? 'p-invalid' : ''" v-model.trim="zohoId" />
        <label for="zohoId">Zoho Id</label>
      </span>
      <InlineMessage :style="(validateZohoId() === false) ? '' : 'display: none;'">Zoho Id can only contain letters and numbers!</InlineMessage>
    </div>
    <div class="field pt-4">
      <span class="p-float-label">
        <InputText id="salesforceId" :class="(validateSalesforceId() === false) ? 'p-invalid' : ''" v-model.trim="salesforceId" />
        <label for="salesforceId">Salesforce Id</label>
      </span>
      <InlineMessage :style="(validateSalesforceId() === false) ? '' : 'display: none;'">Salesforce Id can only contain letters and numbers!</InlineMessage>
    </div>
    <div class="field pt-4">
      <span class="p-float-label">
        <InputText id="salesforceUrl" :class="(validateSalesforceUrl() === false) ? 'p-invalid' : ''" v-model.trim="salesforceUrl" />
        <label for="salesforceUrl">Salesforce Url</label>
      </span>
      <InlineMessage :style="(validateSalesforceUrl() === false) ? '' : 'display: none;'">Not a valid URL!</InlineMessage>
    </div>
    <div class="field pt-4">
      <span class="p-float-label">
        <InputText id="salesEmail" :class="(validateSalesEmail() === false) ? 'p-invalid' : ''" v-model.trim="salesEmail" />
        <label for="salesEmail">Sales Email</label>
      </span>
      <InlineMessage :style="(validateSalesEmail() === false) ? '' : 'display: none;'">Not a valid email!</InlineMessage>
    </div>
    <div class="field pt-4">
      <span class="p-float-label">
        <InputText id="fasEmail" :class="(validateFASEmail() === false) ? 'p-invalid' : ''" v-model.trim="fasEmail" />
        <label for="fasEmail">FAS Email</label>
      </span>
      <InlineMessage :style="(validateFASEmail() === false) ? '' : 'display: none;'">Not a valid email!</InlineMessage>
    </div>
    <div class="field pt-4">
      <span class="p-float-label">
        <InputText id="poNumber" :class="(validatePoNumber() === false) ? 'p-invalid' : ''" v-model.trim="poNumber" />
        <label for="poNumber">PO Number</label>
      </span>
      <InlineMessage :style="(validatePoNumber() === false) ? '' : 'display: none;'">Invalid PO Number!</InlineMessage>
    </div>
    <!-- <div class="field pt-4">
      <span class="p-float-label">
        <Chips id="organizationDataKay" v-model="sharedS3Paths" required="false"></Chips>
        <label for="organizationDataKay">Organization Data Keys</label>
      </span>
    </div> -->
    <div class="grid">
      <div class="col-12">
        <label for="credit">Credits</label>
      </div>
      <div class="col-6">
        <div class="field pt-3">
          <span class="p-float-label">
            <InputNumber id="storageCredit" :class="(validateStorageCredit() === false) ? 'p-invalid' : ''" v-model="storageCredit" autofocus />
            <label for="storageCredit">Storage Credit/GB</label>
          </span>
          <InlineMessage :style="(validateStorageCredit() === false) ? '' : 'display: none;'">Storage Credit can only contain numbers!</InlineMessage>
        </div>
      </div>
      <div class="col-6">
        <div class="field pt-3">
          <span class="p-float-label">
            <InputNumber id="pipelineCredit" :class="(validatePipelineCredit() === false) ? 'p-invalid' : ''" v-model="pipelineCredit" autofocus />
            <label for="pipelineCredit">Pipeline Credit/Hour</label>
          </span>
          <InlineMessage :style="(validatePipelineCredit() === false) ? '' : 'display: none;'">Pipeline Credit can only contain numbers!</InlineMessage>
        </div>
      </div>
    </div>
    <div class="field pt-3">
      <span class="p-float-label">
        <Dropdown
          id="status"
          v-model="status"
          :options="availableStatus"
          optionLabel="label"
        />
        <label for="status">Status</label>
      </span>
    </div>
    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-secondary" :disabled="loading" @click="hideDialog" />
      <Button v-if="isNew" label="Create Organization" class="p-button-outline" :disabled="loading || (this.validateOrganizationName() === false) || (this.validateBioSkrybClientId() === false) || (this.validateZohoId() === false) || (this.validatePipelineCredit() === false || validateSalesforceId() === false || validateSalesforceUrl() === false || validateSalesEmail() === false || validateFASEmail() === false || validatePoNumber() === false)" @click="createOrganization" />
      <Button v-if="!isNew" label="Save" class="p-button-outline" :disabled="loading || (this.validateOrganizationName() === false) || (this.validateBioSkrybClientId() === false) || (this.validateZohoId() === false) || (this.validatePipelineCredit() === false || validateSalesforceId() === false || validateSalesforceUrl() === false || validateSalesEmail() === false || validateFASEmail() === false || validatePoNumber() === false)" @click="editOrganization" />
    </template>
  </Dialog>
</template>

<script>
// Two in one. Both create and edit organization.

import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '@/graphql/mutations';
import {
  // eslint-disable-next-line no-unused-vars
  validateInputName, validateDigit, validateURL, validateEmail,
} from '@/utils';

export default {
  props: ['selectedOrganization', 'allOrganizationNames'],
  data() {
    return {
      loading: false,
      showing: false,
      isNew: true,
      errorMsg: '',
      submitted: false,
      inputFailed: false,
      organizationName: null,
      bioskrybClientId: null,
      originalOrganizationName: null,
      zohoId: null,
      salesforceId: null,
      salesforceUrl: null,
      salesEmail: null,
      fasEmail: null,
      poNumber: null,
      organizationDataKay: null,
      storageCredit: null,
      pipelineCredit: null,
      sharedS3Paths: [],
      availableStatus: [{
        label: 'Active',
        value: true,
      }, {
        label: 'Inactive',
        value: false,
      }],
      status: {
        label: 'Active',
        value: true,
      },
    };
  },
  methods: {
    getSelectedOrganization(organization) {
      console.log('watching selected organization - ', organization);
      if (organization) {
        this.isNew = false;
        this.organizationName = organization.organizationName;
        this.originalOrganizationName = organization.organizationName;
        this.bioskrybClientId = organization.bioskrybClientId;
        this.zohoId = organization.zohoId;
        this.organizationDataKay = organization.organizationDataKay;
        // this.sharedS3Paths = organization.sharedS3Paths;
        this.salesforceId = organization.salesforceId;
        this.salesforceUrl = organization.salesforceUrl;
        this.salesEmail = organization.salesEmail;
        this.fasEmail = organization.fasEmail;
        this.poNumber = organization.poNumber;
        this.pipelineCredit = organization.pipelineCreditRate;
        this.storageCredit = organization.storageCreditRate;
        this.status = this.availableStatus[organization.active ? 0 : 1];
      }
    },
    async createOrganization() {
      if ((this.validateOrganizationName() === false) || (this.validateBioSkrybClientId() === false) || (this.validateZohoId() === false) || (this.validatePipelineCredit() === false) || this.organizationName === '' || this.organizationName === null || this.validateSalesforceId() === false || this.validateSalesforceUrl() === false || this.validateSalesEmail() === false || this.validateFASEmail() === false || this.validatePoNumber() === false) {
        this.inputFailed = true;
        return;
      }
      if (this.allOrganizationNames !== null && this.allOrganizationNames !== undefined && this.allOrganizationNames.length > 0) {
        if (this.allOrganizationNames.includes(this.organizationName)) {
          this.errorMsg = 'Organization name already exists!';
          this.inputFailed = true;
          return;
        }
      }

      this.loading = true;
      const organization = {
        organizationName: this.organizationName,
        bioskrybClientId: this.bioskrybClientId,
        active: this.status.value,
        storageCreditRate: this.storageCredit,
        pipelineCreditRate: this.pipelineCredit,
        zohoId: this.zohoId,
        salesforceId: this.salesforceId,
        salesforceUrl: this.salesforceUrl,
        salesEmail: this.salesEmail,
        fasEmail: this.fasEmail,
        poNumber: this.poNumber,
        organizationDataKay: this.organizationDataKay,
        // sharedS3Paths: this.sharedS3Paths,
        writeGroups: [`ORG/${this.$store.state.activeOrganization}/Admin`],
        readGroups: [`ORG/${this.$store.state.activeOrganization}/User`],
      };
      try {
        const { data } = await API.graphql(graphqlOperation(mutations.createOrganization, {
          input: organization,
        }));
        console.log('organization data - ', data);
        this.updateOrganizationGroups(data.createOrganization.id);
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Organization created successfully!',
          life: 3000,
        });
        this.loading = false;
        this.$store.dispatch('showNewOrganization');
      } catch (error) {
        console.log('organization creation - ', error);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Organization creation failed! Please try again.',
          life: 3000,
        });
        this.loading = false;
      }
      this.organizationName = null;
      this.bioskrybClientId = null;
      this.zohoId = null;
      this.salesforceId = null;
      this.salesforceUrl = null;
      this.salesEmail = null;
      this.fasEmail = null;
      this.poNumber = null;
      this.organizationDataKay = null;
      // this.sharedS3Paths = [];
      this.storageCredit = null;
      this.pipelineCredit = null;
      this.availableStatus = [{
        label: 'Active',
        value: true,
      }, {
        label: 'Inactive',
        value: false,
      }];
      this.status = null;
    },
    async updateOrganizationGroups(organizationId) {
      const orgGroupsParams = {
        id: organizationId,
        writeGroups: [`ORG/${organizationId}/Admin`],
        readGroups: [`ORG/${organizationId}/User`],
      };
      try {
        await API.graphql(graphqlOperation(mutations.updateOrganization, {
          input: orgGroupsParams,
        }));
      } catch (error) {
        console.log('Error updating organization groups');
        console.log(error);
      }
    },
    async editOrganization() {
      if ((this.validateOrganizationName() === false) || (this.validateBioSkrybClientId() === false) || (this.validateZohoId() === false) || (this.validatePipelineCredit() === false) || this.organizationName === '' || this.organizationName === null || this.validateSalesforceId() === false || this.validateSalesforceUrl() === false || this.validateSalesEmail() === false || this.validateFASEmail() === false || this.validatePoNumber() === false) {
        this.inputFailed = true;
      }
      if (this.allOrganizationNames !== null && this.allOrganizationNames !== undefined && this.allOrganizationNames.length > 0) {
        if (this.allOrganizationNames.includes(this.organizationName) && (this.originalOrganizationName !== this.organizationName)) {
          this.errorMsg = 'Organization name already exists!';
          this.inputFailed = true;
          return;
        }
      }

      this.loading = true;
      const organization = {
        id: this.selectedOrganization.id,
        organizationName: this.organizationName,
        bioskrybClientId: this.bioskrybClientId,
        active: this.status.value,
        storageCreditRate: this.storageCredit,
        pipelineCreditRate: this.pipelineCredit,
        zohoId: this.zohoId,
        salesforceId: this.salesforceId,
        salesforceUrl: this.salesforceUrl,
        salesEmail: this.salesEmail,
        fasEmail: this.fasEmail,
        poNumber: this.poNumber,
        organizationDataKay: this.organizationDataKay,
        // sharedS3Paths: this.sharedS3Paths,
      };
      console.log('update organization - ', organization);

      try {
        const { data } = await API.graphql(graphqlOperation(mutations.updateOrganization, {
          input: organization,
        }));
        console.log('organization data - ', data);
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Organization updated successfully!',
          life: 3000,
        });
        this.loading = false;
        this.$store.dispatch('showNewOrganization');
      } catch (error) {
        console.log('organization update error - ', error);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Organization updating failed! Please try again.',
          life: 3000,
        });
        this.loading = false;
      }
    },
    hideDialog() {
      this.loading = false;
      this.showing = false;
      this.isNew = true;
      this.submitted = false;
      this.organizationName = null;
      this.bioskrybClientId = null;
      this.zohoId = null;
      this.salesforceId = null;
      this.salesforceUrl = null;
      this.salesEmail = null;
      this.fasEmail = null;
      this.poNumber = null;
      this.organizationDataKay = null;
      // this.sharedS3Paths = [];
      this.storageCredit = null;
      this.pipelineCredit = null;
      this.availableStatus = [{
        label: 'Active',
        value: true,
      }, {
        label: 'Inactive',
        value: false,
      }];
      this.status = null;
      this.inputFailed = false;
      this.$store.dispatch('showNewOrganization');
    },
    validateOrganizationName() {
      return validateInputName(this.organizationName);
    },
    validateBioSkrybClientId() {
      return validateInputName(this.bioskrybClientId);
    },
    validateZohoId() {
      return validateInputName(this.zohoId);
    },
    validateSalesforceId() {
      return validateInputName(this.salesforceId);
    },
    validateSalesforceUrl() {
      return validateURL(this.salesforceUrl);
    },
    validateSalesEmail() {
      return validateEmail(this.salesEmail);
    },
    validateFASEmail() {
      return validateEmail(this.fasEmail);
    },
    validatePoNumber() {
      return validateInputName(this.poNumber);
    },
    validateStorageCredit() {
      return validateDigit(this.storageCredit);
    },
    validatePipelineCredit() {
      return validateDigit(this.pipelineCredit);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingNewOrganization': async function () {
      this.showing = this.$store.state.showingNewOrganization;
    },
    selectedOrganization: [{
      handler: 'getSelectedOrganization',
    }],
    // sharedS3Paths() {
    //   if (this.sharedS3Paths === null) {
    //     this.sharedS3Paths = [];
    //   }
    //   if (this.sharedS3Paths.length > 0) {
    //     if (!this.sharedS3Paths[this.sharedS3Paths.length - 1].startsWith('s3://')) {
    //       this.sharedS3Paths.pop();
    //     }
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.p-chips-multiple-container) {
  overflow: auto;
}
</style>
