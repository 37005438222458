/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBatchBiosamples = /* GraphQL */ `
  mutation CreateBatchBiosamples($input: [CreateBiosampleInput]!) {
    createBatchBiosamples(input: $input) {
      processedItems {
        id
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        biosampleName
        bsshId
        analysisFiles
        r1FastqName
        r2FastqName
        r1FastqMb5Sum
        r2FastqMb5Sum
        r1FastqLength
        r2FastqLength
        r1FastqTotalReads
        r2FastqTotalReads
        petaSuiteVersion
        petaSuiteSpecies
        petaSuiteReferenceDir
        fastqValidationStatus
        metadata
        size
        fastqs
        r1FastqS3Uri
        r2FastqS3Uri
        lotId
        created
        updated
        readGroups
        writeGroups
        adminGroups
        projectBiosamplesId
      }
      unprocessedItems {
        id
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        biosampleName
        bsshId
        analysisFiles
        r1FastqName
        r2FastqName
        r1FastqMb5Sum
        r2FastqMb5Sum
        r1FastqLength
        r2FastqLength
        r1FastqTotalReads
        r2FastqTotalReads
        petaSuiteVersion
        petaSuiteSpecies
        petaSuiteReferenceDir
        fastqValidationStatus
        metadata
        size
        fastqs
        r1FastqS3Uri
        r2FastqS3Uri
        lotId
        created
        updated
        readGroups
        writeGroups
        adminGroups
        projectBiosamplesId
      }
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      bioskrybClientId
      organizationName
      active
      storageCreditRate
      pipelineCreditRate
      zohoId
      salesforceId
      salesforceUrl
      salesEmail
      fasEmail
      poNumber
      creditExpiry
      organizationDataKay
      workspaces {
        items {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        nextToken
      }
      creditTransactions {
        items {
          id
          debitor
          debitorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          creditor
          creditorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          user
          userName
          amount
          creditPackName
          timesPackPurchased
          type
          notes
          processed
          readGroups
          writeGroups
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          pipelineId
          pipeline {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          isSpent
          expirationInMonths
          notificationsMap
          billingStart
          created
          updated
          organizationCreditTransactionsId
          workspaceCreditTransactionsId
          projectCreditTransactionsId
        }
        nextToken
      }
      readGroups
      sharedS3Paths
      writeGroups
      created
      updated
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      bioskrybClientId
      organizationName
      active
      storageCreditRate
      pipelineCreditRate
      zohoId
      salesforceId
      salesforceUrl
      salesEmail
      fasEmail
      poNumber
      creditExpiry
      organizationDataKay
      workspaces {
        items {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        nextToken
      }
      creditTransactions {
        items {
          id
          debitor
          debitorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          creditor
          creditorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          user
          userName
          amount
          creditPackName
          timesPackPurchased
          type
          notes
          processed
          readGroups
          writeGroups
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          pipelineId
          pipeline {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          isSpent
          expirationInMonths
          notificationsMap
          billingStart
          created
          updated
          organizationCreditTransactionsId
          workspaceCreditTransactionsId
          projectCreditTransactionsId
        }
        nextToken
      }
      readGroups
      sharedS3Paths
      writeGroups
      created
      updated
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      bioskrybClientId
      organizationName
      active
      storageCreditRate
      pipelineCreditRate
      zohoId
      salesforceId
      salesforceUrl
      salesEmail
      fasEmail
      poNumber
      creditExpiry
      organizationDataKay
      workspaces {
        items {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        nextToken
      }
      creditTransactions {
        items {
          id
          debitor
          debitorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          creditor
          creditorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          user
          userName
          amount
          creditPackName
          timesPackPurchased
          type
          notes
          processed
          readGroups
          writeGroups
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          pipelineId
          pipeline {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          isSpent
          expirationInMonths
          notificationsMap
          billingStart
          created
          updated
          organizationCreditTransactionsId
          workspaceCreditTransactionsId
          projectCreditTransactionsId
        }
        nextToken
      }
      readGroups
      sharedS3Paths
      writeGroups
      created
      updated
    }
  }
`;
export const createWorkspace = /* GraphQL */ `
  mutation CreateWorkspace(
    $input: CreateWorkspaceInput!
    $condition: ModelWorkspaceConditionInput
  ) {
    createWorkspace(input: $input, condition: $condition) {
      id
      organizationId
      organization {
        id
        bioskrybClientId
        organizationName
        active
        storageCreditRate
        pipelineCreditRate
        zohoId
        salesforceId
        salesforceUrl
        salesEmail
        fasEmail
        poNumber
        creditExpiry
        organizationDataKay
        workspaces {
          items {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        readGroups
        sharedS3Paths
        writeGroups
        created
        updated
      }
      description
      basespaceAccessToken
      projects {
        items {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        nextToken
      }
      lastCached
      creditTransactions {
        items {
          id
          debitor
          debitorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          creditor
          creditorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          user
          userName
          amount
          creditPackName
          timesPackPurchased
          type
          notes
          processed
          readGroups
          writeGroups
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          pipelineId
          pipeline {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          isSpent
          expirationInMonths
          notificationsMap
          billingStart
          created
          updated
          organizationCreditTransactionsId
          workspaceCreditTransactionsId
          projectCreditTransactionsId
        }
        nextToken
      }
      sharedS3Paths
      readGroups
      size
      lastSizeScan
      writeGroups
      adminGroups
      created
      updated
      organizationWorkspacesId
    }
  }
`;
export const updateWorkspace = /* GraphQL */ `
  mutation UpdateWorkspace(
    $input: UpdateWorkspaceInput!
    $condition: ModelWorkspaceConditionInput
  ) {
    updateWorkspace(input: $input, condition: $condition) {
      id
      organizationId
      organization {
        id
        bioskrybClientId
        organizationName
        active
        storageCreditRate
        pipelineCreditRate
        zohoId
        salesforceId
        salesforceUrl
        salesEmail
        fasEmail
        poNumber
        creditExpiry
        organizationDataKay
        workspaces {
          items {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        readGroups
        sharedS3Paths
        writeGroups
        created
        updated
      }
      description
      basespaceAccessToken
      projects {
        items {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        nextToken
      }
      lastCached
      creditTransactions {
        items {
          id
          debitor
          debitorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          creditor
          creditorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          user
          userName
          amount
          creditPackName
          timesPackPurchased
          type
          notes
          processed
          readGroups
          writeGroups
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          pipelineId
          pipeline {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          isSpent
          expirationInMonths
          notificationsMap
          billingStart
          created
          updated
          organizationCreditTransactionsId
          workspaceCreditTransactionsId
          projectCreditTransactionsId
        }
        nextToken
      }
      sharedS3Paths
      readGroups
      size
      lastSizeScan
      writeGroups
      adminGroups
      created
      updated
      organizationWorkspacesId
    }
  }
`;
export const deleteWorkspace = /* GraphQL */ `
  mutation DeleteWorkspace(
    $input: DeleteWorkspaceInput!
    $condition: ModelWorkspaceConditionInput
  ) {
    deleteWorkspace(input: $input, condition: $condition) {
      id
      organizationId
      organization {
        id
        bioskrybClientId
        organizationName
        active
        storageCreditRate
        pipelineCreditRate
        zohoId
        salesforceId
        salesforceUrl
        salesEmail
        fasEmail
        poNumber
        creditExpiry
        organizationDataKay
        workspaces {
          items {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        readGroups
        sharedS3Paths
        writeGroups
        created
        updated
      }
      description
      basespaceAccessToken
      projects {
        items {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        nextToken
      }
      lastCached
      creditTransactions {
        items {
          id
          debitor
          debitorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          creditor
          creditorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          user
          userName
          amount
          creditPackName
          timesPackPurchased
          type
          notes
          processed
          readGroups
          writeGroups
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          pipelineId
          pipeline {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          isSpent
          expirationInMonths
          notificationsMap
          billingStart
          created
          updated
          organizationCreditTransactionsId
          workspaceCreditTransactionsId
          projectCreditTransactionsId
        }
        nextToken
      }
      sharedS3Paths
      readGroups
      size
      lastSizeScan
      writeGroups
      adminGroups
      created
      updated
      organizationWorkspacesId
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      workspaceId
      workspace {
        id
        organizationId
        organization {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        description
        basespaceAccessToken
        projects {
          items {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          nextToken
        }
        lastCached
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        sharedS3Paths
        readGroups
        size
        lastSizeScan
        writeGroups
        adminGroups
        created
        updated
        organizationWorkspacesId
      }
      associatedProjectId
      associatedProject {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        clientProjectName
        lotId
        analysisType
        projectType
        description
        projectOutputS3Path
        size
        assayName
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        biosampleMetadataColumns
        readGroups
        writeGroups
        adminGroups
        biosamples {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            biosampleName
            bsshId
            analysisFiles
            r1FastqName
            r2FastqName
            r1FastqMb5Sum
            r2FastqMb5Sum
            r1FastqLength
            r2FastqLength
            r1FastqTotalReads
            r2FastqTotalReads
            petaSuiteVersion
            petaSuiteSpecies
            petaSuiteReferenceDir
            fastqValidationStatus
            metadata
            size
            fastqs
            r1FastqS3Uri
            r2FastqS3Uri
            lotId
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectBiosamplesId
          }
          nextToken
        }
        pipelines {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        created
        updated
        workspaceProjectsId
      }
      clientProjectName
      lotId
      analysisType
      projectType
      description
      projectOutputS3Path
      size
      assayName
      status
      nfTowerEstimatedCost
      workflowId
      initiator
      biosampleMetadataColumns
      readGroups
      writeGroups
      adminGroups
      biosamples {
        items {
          id
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          biosampleName
          bsshId
          analysisFiles
          r1FastqName
          r2FastqName
          r1FastqMb5Sum
          r2FastqMb5Sum
          r1FastqLength
          r2FastqLength
          r1FastqTotalReads
          r2FastqTotalReads
          petaSuiteVersion
          petaSuiteSpecies
          petaSuiteReferenceDir
          fastqValidationStatus
          metadata
          size
          fastqs
          r1FastqS3Uri
          r2FastqS3Uri
          lotId
          created
          updated
          readGroups
          writeGroups
          adminGroups
          projectBiosamplesId
        }
        nextToken
      }
      pipelines {
        items {
          id
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          parents {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          children {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          passFailBiosamples
          pipelineName
          runDescription
          analysisLevel
          pipelineNFSessionId
          pipelineNFRunName
          referenceGenome
          estimatedCredits
          size
          pipelineVersion
          pipelineOutputS3Path
          pipelineOutputS3FullPath
          pipelineOutputs
          pipelineStarted
          pipelineCompleted
          modules
          parameters
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          errorMessage
          created
          updated
          readGroups
          writeGroups
          adminGroups
          projectPipelinesId
        }
        nextToken
      }
      creditTransactions {
        items {
          id
          debitor
          debitorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          creditor
          creditorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          user
          userName
          amount
          creditPackName
          timesPackPurchased
          type
          notes
          processed
          readGroups
          writeGroups
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          pipelineId
          pipeline {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          isSpent
          expirationInMonths
          notificationsMap
          billingStart
          created
          updated
          organizationCreditTransactionsId
          workspaceCreditTransactionsId
          projectCreditTransactionsId
        }
        nextToken
      }
      created
      updated
      workspaceProjectsId
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      workspaceId
      workspace {
        id
        organizationId
        organization {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        description
        basespaceAccessToken
        projects {
          items {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          nextToken
        }
        lastCached
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        sharedS3Paths
        readGroups
        size
        lastSizeScan
        writeGroups
        adminGroups
        created
        updated
        organizationWorkspacesId
      }
      associatedProjectId
      associatedProject {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        clientProjectName
        lotId
        analysisType
        projectType
        description
        projectOutputS3Path
        size
        assayName
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        biosampleMetadataColumns
        readGroups
        writeGroups
        adminGroups
        biosamples {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            biosampleName
            bsshId
            analysisFiles
            r1FastqName
            r2FastqName
            r1FastqMb5Sum
            r2FastqMb5Sum
            r1FastqLength
            r2FastqLength
            r1FastqTotalReads
            r2FastqTotalReads
            petaSuiteVersion
            petaSuiteSpecies
            petaSuiteReferenceDir
            fastqValidationStatus
            metadata
            size
            fastqs
            r1FastqS3Uri
            r2FastqS3Uri
            lotId
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectBiosamplesId
          }
          nextToken
        }
        pipelines {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        created
        updated
        workspaceProjectsId
      }
      clientProjectName
      lotId
      analysisType
      projectType
      description
      projectOutputS3Path
      size
      assayName
      status
      nfTowerEstimatedCost
      workflowId
      initiator
      biosampleMetadataColumns
      readGroups
      writeGroups
      adminGroups
      biosamples {
        items {
          id
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          biosampleName
          bsshId
          analysisFiles
          r1FastqName
          r2FastqName
          r1FastqMb5Sum
          r2FastqMb5Sum
          r1FastqLength
          r2FastqLength
          r1FastqTotalReads
          r2FastqTotalReads
          petaSuiteVersion
          petaSuiteSpecies
          petaSuiteReferenceDir
          fastqValidationStatus
          metadata
          size
          fastqs
          r1FastqS3Uri
          r2FastqS3Uri
          lotId
          created
          updated
          readGroups
          writeGroups
          adminGroups
          projectBiosamplesId
        }
        nextToken
      }
      pipelines {
        items {
          id
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          parents {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          children {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          passFailBiosamples
          pipelineName
          runDescription
          analysisLevel
          pipelineNFSessionId
          pipelineNFRunName
          referenceGenome
          estimatedCredits
          size
          pipelineVersion
          pipelineOutputS3Path
          pipelineOutputS3FullPath
          pipelineOutputs
          pipelineStarted
          pipelineCompleted
          modules
          parameters
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          errorMessage
          created
          updated
          readGroups
          writeGroups
          adminGroups
          projectPipelinesId
        }
        nextToken
      }
      creditTransactions {
        items {
          id
          debitor
          debitorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          creditor
          creditorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          user
          userName
          amount
          creditPackName
          timesPackPurchased
          type
          notes
          processed
          readGroups
          writeGroups
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          pipelineId
          pipeline {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          isSpent
          expirationInMonths
          notificationsMap
          billingStart
          created
          updated
          organizationCreditTransactionsId
          workspaceCreditTransactionsId
          projectCreditTransactionsId
        }
        nextToken
      }
      created
      updated
      workspaceProjectsId
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      workspaceId
      workspace {
        id
        organizationId
        organization {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        description
        basespaceAccessToken
        projects {
          items {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          nextToken
        }
        lastCached
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        sharedS3Paths
        readGroups
        size
        lastSizeScan
        writeGroups
        adminGroups
        created
        updated
        organizationWorkspacesId
      }
      associatedProjectId
      associatedProject {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        clientProjectName
        lotId
        analysisType
        projectType
        description
        projectOutputS3Path
        size
        assayName
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        biosampleMetadataColumns
        readGroups
        writeGroups
        adminGroups
        biosamples {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            biosampleName
            bsshId
            analysisFiles
            r1FastqName
            r2FastqName
            r1FastqMb5Sum
            r2FastqMb5Sum
            r1FastqLength
            r2FastqLength
            r1FastqTotalReads
            r2FastqTotalReads
            petaSuiteVersion
            petaSuiteSpecies
            petaSuiteReferenceDir
            fastqValidationStatus
            metadata
            size
            fastqs
            r1FastqS3Uri
            r2FastqS3Uri
            lotId
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectBiosamplesId
          }
          nextToken
        }
        pipelines {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        created
        updated
        workspaceProjectsId
      }
      clientProjectName
      lotId
      analysisType
      projectType
      description
      projectOutputS3Path
      size
      assayName
      status
      nfTowerEstimatedCost
      workflowId
      initiator
      biosampleMetadataColumns
      readGroups
      writeGroups
      adminGroups
      biosamples {
        items {
          id
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          biosampleName
          bsshId
          analysisFiles
          r1FastqName
          r2FastqName
          r1FastqMb5Sum
          r2FastqMb5Sum
          r1FastqLength
          r2FastqLength
          r1FastqTotalReads
          r2FastqTotalReads
          petaSuiteVersion
          petaSuiteSpecies
          petaSuiteReferenceDir
          fastqValidationStatus
          metadata
          size
          fastqs
          r1FastqS3Uri
          r2FastqS3Uri
          lotId
          created
          updated
          readGroups
          writeGroups
          adminGroups
          projectBiosamplesId
        }
        nextToken
      }
      pipelines {
        items {
          id
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          parents {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          children {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          passFailBiosamples
          pipelineName
          runDescription
          analysisLevel
          pipelineNFSessionId
          pipelineNFRunName
          referenceGenome
          estimatedCredits
          size
          pipelineVersion
          pipelineOutputS3Path
          pipelineOutputS3FullPath
          pipelineOutputs
          pipelineStarted
          pipelineCompleted
          modules
          parameters
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          errorMessage
          created
          updated
          readGroups
          writeGroups
          adminGroups
          projectPipelinesId
        }
        nextToken
      }
      creditTransactions {
        items {
          id
          debitor
          debitorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          creditor
          creditorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          user
          userName
          amount
          creditPackName
          timesPackPurchased
          type
          notes
          processed
          readGroups
          writeGroups
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          pipelineId
          pipeline {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          isSpent
          expirationInMonths
          notificationsMap
          billingStart
          created
          updated
          organizationCreditTransactionsId
          workspaceCreditTransactionsId
          projectCreditTransactionsId
        }
        nextToken
      }
      created
      updated
      workspaceProjectsId
    }
  }
`;
export const createBiosample = /* GraphQL */ `
  mutation CreateBiosample(
    $input: CreateBiosampleInput!
    $condition: ModelBiosampleConditionInput
  ) {
    createBiosample(input: $input, condition: $condition) {
      id
      projectId
      project {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        clientProjectName
        lotId
        analysisType
        projectType
        description
        projectOutputS3Path
        size
        assayName
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        biosampleMetadataColumns
        readGroups
        writeGroups
        adminGroups
        biosamples {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            biosampleName
            bsshId
            analysisFiles
            r1FastqName
            r2FastqName
            r1FastqMb5Sum
            r2FastqMb5Sum
            r1FastqLength
            r2FastqLength
            r1FastqTotalReads
            r2FastqTotalReads
            petaSuiteVersion
            petaSuiteSpecies
            petaSuiteReferenceDir
            fastqValidationStatus
            metadata
            size
            fastqs
            r1FastqS3Uri
            r2FastqS3Uri
            lotId
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectBiosamplesId
          }
          nextToken
        }
        pipelines {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        created
        updated
        workspaceProjectsId
      }
      biosampleName
      bsshId
      analysisFiles
      r1FastqName
      r2FastqName
      r1FastqMb5Sum
      r2FastqMb5Sum
      r1FastqLength
      r2FastqLength
      r1FastqTotalReads
      r2FastqTotalReads
      petaSuiteVersion
      petaSuiteSpecies
      petaSuiteReferenceDir
      fastqValidationStatus
      metadata
      size
      fastqs
      r1FastqS3Uri
      r2FastqS3Uri
      lotId
      created
      updated
      readGroups
      writeGroups
      adminGroups
      projectBiosamplesId
    }
  }
`;
export const updateBiosample = /* GraphQL */ `
  mutation UpdateBiosample(
    $input: UpdateBiosampleInput!
    $condition: ModelBiosampleConditionInput
  ) {
    updateBiosample(input: $input, condition: $condition) {
      id
      projectId
      project {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        clientProjectName
        lotId
        analysisType
        projectType
        description
        projectOutputS3Path
        size
        assayName
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        biosampleMetadataColumns
        readGroups
        writeGroups
        adminGroups
        biosamples {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            biosampleName
            bsshId
            analysisFiles
            r1FastqName
            r2FastqName
            r1FastqMb5Sum
            r2FastqMb5Sum
            r1FastqLength
            r2FastqLength
            r1FastqTotalReads
            r2FastqTotalReads
            petaSuiteVersion
            petaSuiteSpecies
            petaSuiteReferenceDir
            fastqValidationStatus
            metadata
            size
            fastqs
            r1FastqS3Uri
            r2FastqS3Uri
            lotId
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectBiosamplesId
          }
          nextToken
        }
        pipelines {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        created
        updated
        workspaceProjectsId
      }
      biosampleName
      bsshId
      analysisFiles
      r1FastqName
      r2FastqName
      r1FastqMb5Sum
      r2FastqMb5Sum
      r1FastqLength
      r2FastqLength
      r1FastqTotalReads
      r2FastqTotalReads
      petaSuiteVersion
      petaSuiteSpecies
      petaSuiteReferenceDir
      fastqValidationStatus
      metadata
      size
      fastqs
      r1FastqS3Uri
      r2FastqS3Uri
      lotId
      created
      updated
      readGroups
      writeGroups
      adminGroups
      projectBiosamplesId
    }
  }
`;
export const deleteBiosample = /* GraphQL */ `
  mutation DeleteBiosample(
    $input: DeleteBiosampleInput!
    $condition: ModelBiosampleConditionInput
  ) {
    deleteBiosample(input: $input, condition: $condition) {
      id
      projectId
      project {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        clientProjectName
        lotId
        analysisType
        projectType
        description
        projectOutputS3Path
        size
        assayName
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        biosampleMetadataColumns
        readGroups
        writeGroups
        adminGroups
        biosamples {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            biosampleName
            bsshId
            analysisFiles
            r1FastqName
            r2FastqName
            r1FastqMb5Sum
            r2FastqMb5Sum
            r1FastqLength
            r2FastqLength
            r1FastqTotalReads
            r2FastqTotalReads
            petaSuiteVersion
            petaSuiteSpecies
            petaSuiteReferenceDir
            fastqValidationStatus
            metadata
            size
            fastqs
            r1FastqS3Uri
            r2FastqS3Uri
            lotId
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectBiosamplesId
          }
          nextToken
        }
        pipelines {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        created
        updated
        workspaceProjectsId
      }
      biosampleName
      bsshId
      analysisFiles
      r1FastqName
      r2FastqName
      r1FastqMb5Sum
      r2FastqMb5Sum
      r1FastqLength
      r2FastqLength
      r1FastqTotalReads
      r2FastqTotalReads
      petaSuiteVersion
      petaSuiteSpecies
      petaSuiteReferenceDir
      fastqValidationStatus
      metadata
      size
      fastqs
      r1FastqS3Uri
      r2FastqS3Uri
      lotId
      created
      updated
      readGroups
      writeGroups
      adminGroups
      projectBiosamplesId
    }
  }
`;
export const createPipeline = /* GraphQL */ `
  mutation CreatePipeline(
    $input: CreatePipelineInput!
    $condition: ModelPipelineConditionInput
  ) {
    createPipeline(input: $input, condition: $condition) {
      id
      projectId
      project {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        clientProjectName
        lotId
        analysisType
        projectType
        description
        projectOutputS3Path
        size
        assayName
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        biosampleMetadataColumns
        readGroups
        writeGroups
        adminGroups
        biosamples {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            biosampleName
            bsshId
            analysisFiles
            r1FastqName
            r2FastqName
            r1FastqMb5Sum
            r2FastqMb5Sum
            r1FastqLength
            r2FastqLength
            r1FastqTotalReads
            r2FastqTotalReads
            petaSuiteVersion
            petaSuiteSpecies
            petaSuiteReferenceDir
            fastqValidationStatus
            metadata
            size
            fastqs
            r1FastqS3Uri
            r2FastqS3Uri
            lotId
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectBiosamplesId
          }
          nextToken
        }
        pipelines {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        created
        updated
        workspaceProjectsId
      }
      parents {
        items {
          id
          parentId
          childId
          parent {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          child {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          createdAt
          updatedAt
          pipelineParentsId
          pipelineChildrenId
        }
        nextToken
      }
      children {
        items {
          id
          parentId
          childId
          parent {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          child {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          createdAt
          updatedAt
          pipelineParentsId
          pipelineChildrenId
        }
        nextToken
      }
      passFailBiosamples
      pipelineName
      runDescription
      analysisLevel
      pipelineNFSessionId
      pipelineNFRunName
      referenceGenome
      estimatedCredits
      size
      pipelineVersion
      pipelineOutputS3Path
      pipelineOutputS3FullPath
      pipelineOutputs
      pipelineStarted
      pipelineCompleted
      modules
      parameters
      status
      nfTowerEstimatedCost
      workflowId
      initiator
      errorMessage
      created
      updated
      readGroups
      writeGroups
      adminGroups
      projectPipelinesId
    }
  }
`;
export const updatePipeline = /* GraphQL */ `
  mutation UpdatePipeline(
    $input: UpdatePipelineInput!
    $condition: ModelPipelineConditionInput
  ) {
    updatePipeline(input: $input, condition: $condition) {
      id
      projectId
      project {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        clientProjectName
        lotId
        analysisType
        projectType
        description
        projectOutputS3Path
        size
        assayName
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        biosampleMetadataColumns
        readGroups
        writeGroups
        adminGroups
        biosamples {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            biosampleName
            bsshId
            analysisFiles
            r1FastqName
            r2FastqName
            r1FastqMb5Sum
            r2FastqMb5Sum
            r1FastqLength
            r2FastqLength
            r1FastqTotalReads
            r2FastqTotalReads
            petaSuiteVersion
            petaSuiteSpecies
            petaSuiteReferenceDir
            fastqValidationStatus
            metadata
            size
            fastqs
            r1FastqS3Uri
            r2FastqS3Uri
            lotId
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectBiosamplesId
          }
          nextToken
        }
        pipelines {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        created
        updated
        workspaceProjectsId
      }
      parents {
        items {
          id
          parentId
          childId
          parent {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          child {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          createdAt
          updatedAt
          pipelineParentsId
          pipelineChildrenId
        }
        nextToken
      }
      children {
        items {
          id
          parentId
          childId
          parent {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          child {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          createdAt
          updatedAt
          pipelineParentsId
          pipelineChildrenId
        }
        nextToken
      }
      passFailBiosamples
      pipelineName
      runDescription
      analysisLevel
      pipelineNFSessionId
      pipelineNFRunName
      referenceGenome
      estimatedCredits
      size
      pipelineVersion
      pipelineOutputS3Path
      pipelineOutputS3FullPath
      pipelineOutputs
      pipelineStarted
      pipelineCompleted
      modules
      parameters
      status
      nfTowerEstimatedCost
      workflowId
      initiator
      errorMessage
      created
      updated
      readGroups
      writeGroups
      adminGroups
      projectPipelinesId
    }
  }
`;
export const deletePipeline = /* GraphQL */ `
  mutation DeletePipeline(
    $input: DeletePipelineInput!
    $condition: ModelPipelineConditionInput
  ) {
    deletePipeline(input: $input, condition: $condition) {
      id
      projectId
      project {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        clientProjectName
        lotId
        analysisType
        projectType
        description
        projectOutputS3Path
        size
        assayName
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        biosampleMetadataColumns
        readGroups
        writeGroups
        adminGroups
        biosamples {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            biosampleName
            bsshId
            analysisFiles
            r1FastqName
            r2FastqName
            r1FastqMb5Sum
            r2FastqMb5Sum
            r1FastqLength
            r2FastqLength
            r1FastqTotalReads
            r2FastqTotalReads
            petaSuiteVersion
            petaSuiteSpecies
            petaSuiteReferenceDir
            fastqValidationStatus
            metadata
            size
            fastqs
            r1FastqS3Uri
            r2FastqS3Uri
            lotId
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectBiosamplesId
          }
          nextToken
        }
        pipelines {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        created
        updated
        workspaceProjectsId
      }
      parents {
        items {
          id
          parentId
          childId
          parent {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          child {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          createdAt
          updatedAt
          pipelineParentsId
          pipelineChildrenId
        }
        nextToken
      }
      children {
        items {
          id
          parentId
          childId
          parent {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          child {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          createdAt
          updatedAt
          pipelineParentsId
          pipelineChildrenId
        }
        nextToken
      }
      passFailBiosamples
      pipelineName
      runDescription
      analysisLevel
      pipelineNFSessionId
      pipelineNFRunName
      referenceGenome
      estimatedCredits
      size
      pipelineVersion
      pipelineOutputS3Path
      pipelineOutputS3FullPath
      pipelineOutputs
      pipelineStarted
      pipelineCompleted
      modules
      parameters
      status
      nfTowerEstimatedCost
      workflowId
      initiator
      errorMessage
      created
      updated
      readGroups
      writeGroups
      adminGroups
      projectPipelinesId
    }
  }
`;
export const createPipelinesPipelines = /* GraphQL */ `
  mutation CreatePipelinesPipelines(
    $input: CreatePipelinesPipelinesInput!
    $condition: ModelPipelinesPipelinesConditionInput
  ) {
    createPipelinesPipelines(input: $input, condition: $condition) {
      id
      parentId
      childId
      parent {
        id
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        parents {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        children {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        passFailBiosamples
        pipelineName
        runDescription
        analysisLevel
        pipelineNFSessionId
        pipelineNFRunName
        referenceGenome
        estimatedCredits
        size
        pipelineVersion
        pipelineOutputS3Path
        pipelineOutputS3FullPath
        pipelineOutputs
        pipelineStarted
        pipelineCompleted
        modules
        parameters
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        errorMessage
        created
        updated
        readGroups
        writeGroups
        adminGroups
        projectPipelinesId
      }
      child {
        id
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        parents {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        children {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        passFailBiosamples
        pipelineName
        runDescription
        analysisLevel
        pipelineNFSessionId
        pipelineNFRunName
        referenceGenome
        estimatedCredits
        size
        pipelineVersion
        pipelineOutputS3Path
        pipelineOutputS3FullPath
        pipelineOutputs
        pipelineStarted
        pipelineCompleted
        modules
        parameters
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        errorMessage
        created
        updated
        readGroups
        writeGroups
        adminGroups
        projectPipelinesId
      }
      createdAt
      updatedAt
      pipelineParentsId
      pipelineChildrenId
    }
  }
`;
export const updatePipelinesPipelines = /* GraphQL */ `
  mutation UpdatePipelinesPipelines(
    $input: UpdatePipelinesPipelinesInput!
    $condition: ModelPipelinesPipelinesConditionInput
  ) {
    updatePipelinesPipelines(input: $input, condition: $condition) {
      id
      parentId
      childId
      parent {
        id
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        parents {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        children {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        passFailBiosamples
        pipelineName
        runDescription
        analysisLevel
        pipelineNFSessionId
        pipelineNFRunName
        referenceGenome
        estimatedCredits
        size
        pipelineVersion
        pipelineOutputS3Path
        pipelineOutputS3FullPath
        pipelineOutputs
        pipelineStarted
        pipelineCompleted
        modules
        parameters
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        errorMessage
        created
        updated
        readGroups
        writeGroups
        adminGroups
        projectPipelinesId
      }
      child {
        id
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        parents {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        children {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        passFailBiosamples
        pipelineName
        runDescription
        analysisLevel
        pipelineNFSessionId
        pipelineNFRunName
        referenceGenome
        estimatedCredits
        size
        pipelineVersion
        pipelineOutputS3Path
        pipelineOutputS3FullPath
        pipelineOutputs
        pipelineStarted
        pipelineCompleted
        modules
        parameters
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        errorMessage
        created
        updated
        readGroups
        writeGroups
        adminGroups
        projectPipelinesId
      }
      createdAt
      updatedAt
      pipelineParentsId
      pipelineChildrenId
    }
  }
`;
export const deletePipelinesPipelines = /* GraphQL */ `
  mutation DeletePipelinesPipelines(
    $input: DeletePipelinesPipelinesInput!
    $condition: ModelPipelinesPipelinesConditionInput
  ) {
    deletePipelinesPipelines(input: $input, condition: $condition) {
      id
      parentId
      childId
      parent {
        id
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        parents {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        children {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        passFailBiosamples
        pipelineName
        runDescription
        analysisLevel
        pipelineNFSessionId
        pipelineNFRunName
        referenceGenome
        estimatedCredits
        size
        pipelineVersion
        pipelineOutputS3Path
        pipelineOutputS3FullPath
        pipelineOutputs
        pipelineStarted
        pipelineCompleted
        modules
        parameters
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        errorMessage
        created
        updated
        readGroups
        writeGroups
        adminGroups
        projectPipelinesId
      }
      child {
        id
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        parents {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        children {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        passFailBiosamples
        pipelineName
        runDescription
        analysisLevel
        pipelineNFSessionId
        pipelineNFRunName
        referenceGenome
        estimatedCredits
        size
        pipelineVersion
        pipelineOutputS3Path
        pipelineOutputS3FullPath
        pipelineOutputs
        pipelineStarted
        pipelineCompleted
        modules
        parameters
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        errorMessage
        created
        updated
        readGroups
        writeGroups
        adminGroups
        projectPipelinesId
      }
      createdAt
      updatedAt
      pipelineParentsId
      pipelineChildrenId
    }
  }
`;
export const createLaunchablePipelines = /* GraphQL */ `
  mutation CreateLaunchablePipelines(
    $input: CreateLaunchablePipelinesInput!
    $condition: ModelLaunchablePipelinesConditionInput
  ) {
    createLaunchablePipelines(input: $input, condition: $condition) {
      id
      pipelineName
      pipelineVersion
      parameters
      requiredMetadataColumns
      description
      analysisLevel
      supportedTertiaryAnalyses
      supportedInputFileTypes
      gitRepo
      mainScript
      tokensPerBiosample
      created
      updated
    }
  }
`;
export const updateLaunchablePipelines = /* GraphQL */ `
  mutation UpdateLaunchablePipelines(
    $input: UpdateLaunchablePipelinesInput!
    $condition: ModelLaunchablePipelinesConditionInput
  ) {
    updateLaunchablePipelines(input: $input, condition: $condition) {
      id
      pipelineName
      pipelineVersion
      parameters
      requiredMetadataColumns
      description
      analysisLevel
      supportedTertiaryAnalyses
      supportedInputFileTypes
      gitRepo
      mainScript
      tokensPerBiosample
      created
      updated
    }
  }
`;
export const deleteLaunchablePipelines = /* GraphQL */ `
  mutation DeleteLaunchablePipelines(
    $input: DeleteLaunchablePipelinesInput!
    $condition: ModelLaunchablePipelinesConditionInput
  ) {
    deleteLaunchablePipelines(input: $input, condition: $condition) {
      id
      pipelineName
      pipelineVersion
      parameters
      requiredMetadataColumns
      description
      analysisLevel
      supportedTertiaryAnalyses
      supportedInputFileTypes
      gitRepo
      mainScript
      tokensPerBiosample
      created
      updated
    }
  }
`;
export const createPrivateLaunchablePipelines = /* GraphQL */ `
  mutation CreatePrivateLaunchablePipelines(
    $input: CreatePrivateLaunchablePipelinesInput!
    $condition: ModelPrivateLaunchablePipelinesConditionInput
  ) {
    createPrivateLaunchablePipelines(input: $input, condition: $condition) {
      id
      pipelineName
      pipelineVersion
      parameters
      requiredMetadataColumns
      description
      analysisLevel
      supportedTertiaryAnalyses
      supportedInputFileTypes
      gitRepo
      mainScript
      tokensPerBiosample
      readGroups
      created
      updated
    }
  }
`;
export const updatePrivateLaunchablePipelines = /* GraphQL */ `
  mutation UpdatePrivateLaunchablePipelines(
    $input: UpdatePrivateLaunchablePipelinesInput!
    $condition: ModelPrivateLaunchablePipelinesConditionInput
  ) {
    updatePrivateLaunchablePipelines(input: $input, condition: $condition) {
      id
      pipelineName
      pipelineVersion
      parameters
      requiredMetadataColumns
      description
      analysisLevel
      supportedTertiaryAnalyses
      supportedInputFileTypes
      gitRepo
      mainScript
      tokensPerBiosample
      readGroups
      created
      updated
    }
  }
`;
export const deletePrivateLaunchablePipelines = /* GraphQL */ `
  mutation DeletePrivateLaunchablePipelines(
    $input: DeletePrivateLaunchablePipelinesInput!
    $condition: ModelPrivateLaunchablePipelinesConditionInput
  ) {
    deletePrivateLaunchablePipelines(input: $input, condition: $condition) {
      id
      pipelineName
      pipelineVersion
      parameters
      requiredMetadataColumns
      description
      analysisLevel
      supportedTertiaryAnalyses
      supportedInputFileTypes
      gitRepo
      mainScript
      tokensPerBiosample
      readGroups
      created
      updated
    }
  }
`;
export const createVisualizations = /* GraphQL */ `
  mutation CreateVisualizations(
    $input: CreateVisualizationsInput!
    $condition: ModelVisualizationsConditionInput
  ) {
    createVisualizations(input: $input, condition: $condition) {
      id
      visualizationName
      description
      version
      deploymentPath
      iconPath
      validationSchema
      allowedExtensions
      created
      updated
    }
  }
`;
export const updateVisualizations = /* GraphQL */ `
  mutation UpdateVisualizations(
    $input: UpdateVisualizationsInput!
    $condition: ModelVisualizationsConditionInput
  ) {
    updateVisualizations(input: $input, condition: $condition) {
      id
      visualizationName
      description
      version
      deploymentPath
      iconPath
      validationSchema
      allowedExtensions
      created
      updated
    }
  }
`;
export const deleteVisualizations = /* GraphQL */ `
  mutation DeleteVisualizations(
    $input: DeleteVisualizationsInput!
    $condition: ModelVisualizationsConditionInput
  ) {
    deleteVisualizations(input: $input, condition: $condition) {
      id
      visualizationName
      description
      version
      deploymentPath
      iconPath
      validationSchema
      allowedExtensions
      created
      updated
    }
  }
`;
export const createWorkflow = /* GraphQL */ `
  mutation CreateWorkflow(
    $input: CreateWorkflowInput!
    $condition: ModelWorkflowConditionInput
  ) {
    createWorkflow(input: $input, condition: $condition) {
      id
      paramsFileS3Path
      nfWorkflowId
      status
      created
      updated
    }
  }
`;
export const updateWorkflow = /* GraphQL */ `
  mutation UpdateWorkflow(
    $input: UpdateWorkflowInput!
    $condition: ModelWorkflowConditionInput
  ) {
    updateWorkflow(input: $input, condition: $condition) {
      id
      paramsFileS3Path
      nfWorkflowId
      status
      created
      updated
    }
  }
`;
export const deleteWorkflow = /* GraphQL */ `
  mutation DeleteWorkflow(
    $input: DeleteWorkflowInput!
    $condition: ModelWorkflowConditionInput
  ) {
    deleteWorkflow(input: $input, condition: $condition) {
      id
      paramsFileS3Path
      nfWorkflowId
      status
      created
      updated
    }
  }
`;
export const createCreditTransactionLog = /* GraphQL */ `
  mutation CreateCreditTransactionLog(
    $input: CreateCreditTransactionLogInput!
    $condition: ModelCreditTransactionLogConditionInput
  ) {
    createCreditTransactionLog(input: $input, condition: $condition) {
      id
      debitor
      debitorOrg {
        id
        bioskrybClientId
        organizationName
        active
        storageCreditRate
        pipelineCreditRate
        zohoId
        salesforceId
        salesforceUrl
        salesEmail
        fasEmail
        poNumber
        creditExpiry
        organizationDataKay
        workspaces {
          items {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        readGroups
        sharedS3Paths
        writeGroups
        created
        updated
      }
      creditor
      creditorOrg {
        id
        bioskrybClientId
        organizationName
        active
        storageCreditRate
        pipelineCreditRate
        zohoId
        salesforceId
        salesforceUrl
        salesEmail
        fasEmail
        poNumber
        creditExpiry
        organizationDataKay
        workspaces {
          items {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        readGroups
        sharedS3Paths
        writeGroups
        created
        updated
      }
      user
      userName
      amount
      creditPackName
      timesPackPurchased
      type
      notes
      processed
      readGroups
      writeGroups
      projectId
      project {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        clientProjectName
        lotId
        analysisType
        projectType
        description
        projectOutputS3Path
        size
        assayName
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        biosampleMetadataColumns
        readGroups
        writeGroups
        adminGroups
        biosamples {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            biosampleName
            bsshId
            analysisFiles
            r1FastqName
            r2FastqName
            r1FastqMb5Sum
            r2FastqMb5Sum
            r1FastqLength
            r2FastqLength
            r1FastqTotalReads
            r2FastqTotalReads
            petaSuiteVersion
            petaSuiteSpecies
            petaSuiteReferenceDir
            fastqValidationStatus
            metadata
            size
            fastqs
            r1FastqS3Uri
            r2FastqS3Uri
            lotId
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectBiosamplesId
          }
          nextToken
        }
        pipelines {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        created
        updated
        workspaceProjectsId
      }
      workspaceId
      workspace {
        id
        organizationId
        organization {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        description
        basespaceAccessToken
        projects {
          items {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          nextToken
        }
        lastCached
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        sharedS3Paths
        readGroups
        size
        lastSizeScan
        writeGroups
        adminGroups
        created
        updated
        organizationWorkspacesId
      }
      pipelineId
      pipeline {
        id
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        parents {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        children {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        passFailBiosamples
        pipelineName
        runDescription
        analysisLevel
        pipelineNFSessionId
        pipelineNFRunName
        referenceGenome
        estimatedCredits
        size
        pipelineVersion
        pipelineOutputS3Path
        pipelineOutputS3FullPath
        pipelineOutputs
        pipelineStarted
        pipelineCompleted
        modules
        parameters
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        errorMessage
        created
        updated
        readGroups
        writeGroups
        adminGroups
        projectPipelinesId
      }
      isSpent
      expirationInMonths
      notificationsMap
      billingStart
      created
      updated
      organizationCreditTransactionsId
      workspaceCreditTransactionsId
      projectCreditTransactionsId
    }
  }
`;
export const updateCreditTransactionLog = /* GraphQL */ `
  mutation UpdateCreditTransactionLog(
    $input: UpdateCreditTransactionLogInput!
    $condition: ModelCreditTransactionLogConditionInput
  ) {
    updateCreditTransactionLog(input: $input, condition: $condition) {
      id
      debitor
      debitorOrg {
        id
        bioskrybClientId
        organizationName
        active
        storageCreditRate
        pipelineCreditRate
        zohoId
        salesforceId
        salesforceUrl
        salesEmail
        fasEmail
        poNumber
        creditExpiry
        organizationDataKay
        workspaces {
          items {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        readGroups
        sharedS3Paths
        writeGroups
        created
        updated
      }
      creditor
      creditorOrg {
        id
        bioskrybClientId
        organizationName
        active
        storageCreditRate
        pipelineCreditRate
        zohoId
        salesforceId
        salesforceUrl
        salesEmail
        fasEmail
        poNumber
        creditExpiry
        organizationDataKay
        workspaces {
          items {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        readGroups
        sharedS3Paths
        writeGroups
        created
        updated
      }
      user
      userName
      amount
      creditPackName
      timesPackPurchased
      type
      notes
      processed
      readGroups
      writeGroups
      projectId
      project {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        clientProjectName
        lotId
        analysisType
        projectType
        description
        projectOutputS3Path
        size
        assayName
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        biosampleMetadataColumns
        readGroups
        writeGroups
        adminGroups
        biosamples {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            biosampleName
            bsshId
            analysisFiles
            r1FastqName
            r2FastqName
            r1FastqMb5Sum
            r2FastqMb5Sum
            r1FastqLength
            r2FastqLength
            r1FastqTotalReads
            r2FastqTotalReads
            petaSuiteVersion
            petaSuiteSpecies
            petaSuiteReferenceDir
            fastqValidationStatus
            metadata
            size
            fastqs
            r1FastqS3Uri
            r2FastqS3Uri
            lotId
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectBiosamplesId
          }
          nextToken
        }
        pipelines {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        created
        updated
        workspaceProjectsId
      }
      workspaceId
      workspace {
        id
        organizationId
        organization {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        description
        basespaceAccessToken
        projects {
          items {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          nextToken
        }
        lastCached
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        sharedS3Paths
        readGroups
        size
        lastSizeScan
        writeGroups
        adminGroups
        created
        updated
        organizationWorkspacesId
      }
      pipelineId
      pipeline {
        id
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        parents {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        children {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        passFailBiosamples
        pipelineName
        runDescription
        analysisLevel
        pipelineNFSessionId
        pipelineNFRunName
        referenceGenome
        estimatedCredits
        size
        pipelineVersion
        pipelineOutputS3Path
        pipelineOutputS3FullPath
        pipelineOutputs
        pipelineStarted
        pipelineCompleted
        modules
        parameters
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        errorMessage
        created
        updated
        readGroups
        writeGroups
        adminGroups
        projectPipelinesId
      }
      isSpent
      expirationInMonths
      notificationsMap
      billingStart
      created
      updated
      organizationCreditTransactionsId
      workspaceCreditTransactionsId
      projectCreditTransactionsId
    }
  }
`;
export const deleteCreditTransactionLog = /* GraphQL */ `
  mutation DeleteCreditTransactionLog(
    $input: DeleteCreditTransactionLogInput!
    $condition: ModelCreditTransactionLogConditionInput
  ) {
    deleteCreditTransactionLog(input: $input, condition: $condition) {
      id
      debitor
      debitorOrg {
        id
        bioskrybClientId
        organizationName
        active
        storageCreditRate
        pipelineCreditRate
        zohoId
        salesforceId
        salesforceUrl
        salesEmail
        fasEmail
        poNumber
        creditExpiry
        organizationDataKay
        workspaces {
          items {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        readGroups
        sharedS3Paths
        writeGroups
        created
        updated
      }
      creditor
      creditorOrg {
        id
        bioskrybClientId
        organizationName
        active
        storageCreditRate
        pipelineCreditRate
        zohoId
        salesforceId
        salesforceUrl
        salesEmail
        fasEmail
        poNumber
        creditExpiry
        organizationDataKay
        workspaces {
          items {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        readGroups
        sharedS3Paths
        writeGroups
        created
        updated
      }
      user
      userName
      amount
      creditPackName
      timesPackPurchased
      type
      notes
      processed
      readGroups
      writeGroups
      projectId
      project {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        clientProjectName
        lotId
        analysisType
        projectType
        description
        projectOutputS3Path
        size
        assayName
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        biosampleMetadataColumns
        readGroups
        writeGroups
        adminGroups
        biosamples {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            biosampleName
            bsshId
            analysisFiles
            r1FastqName
            r2FastqName
            r1FastqMb5Sum
            r2FastqMb5Sum
            r1FastqLength
            r2FastqLength
            r1FastqTotalReads
            r2FastqTotalReads
            petaSuiteVersion
            petaSuiteSpecies
            petaSuiteReferenceDir
            fastqValidationStatus
            metadata
            size
            fastqs
            r1FastqS3Uri
            r2FastqS3Uri
            lotId
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectBiosamplesId
          }
          nextToken
        }
        pipelines {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        created
        updated
        workspaceProjectsId
      }
      workspaceId
      workspace {
        id
        organizationId
        organization {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        description
        basespaceAccessToken
        projects {
          items {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          nextToken
        }
        lastCached
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        sharedS3Paths
        readGroups
        size
        lastSizeScan
        writeGroups
        adminGroups
        created
        updated
        organizationWorkspacesId
      }
      pipelineId
      pipeline {
        id
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        parents {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        children {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        passFailBiosamples
        pipelineName
        runDescription
        analysisLevel
        pipelineNFSessionId
        pipelineNFRunName
        referenceGenome
        estimatedCredits
        size
        pipelineVersion
        pipelineOutputS3Path
        pipelineOutputS3FullPath
        pipelineOutputs
        pipelineStarted
        pipelineCompleted
        modules
        parameters
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        errorMessage
        created
        updated
        readGroups
        writeGroups
        adminGroups
        projectPipelinesId
      }
      isSpent
      expirationInMonths
      notificationsMap
      billingStart
      created
      updated
      organizationCreditTransactionsId
      workspaceCreditTransactionsId
      projectCreditTransactionsId
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      organization
      tAndCAccepted
      created
      updated
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      organization
      tAndCAccepted
      created
      updated
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      organization
      tAndCAccepted
      created
      updated
      owner
    }
  }
`;
export const createBSSHBiosample = /* GraphQL */ `
  mutation CreateBSSHBiosample(
    $input: CreateBSSHBiosampleInput!
    $condition: ModelBSSHBiosampleConditionInput
  ) {
    createBSSHBiosample(input: $input, condition: $condition) {
      workspaceId
      id
      bioSampleName
      defaultProject
      bsshDateModified
      bsshDateCreated
      readGroups
      writeGroups
      adminGroups
      created
      updated
    }
  }
`;
export const updateBSSHBiosample = /* GraphQL */ `
  mutation UpdateBSSHBiosample(
    $input: UpdateBSSHBiosampleInput!
    $condition: ModelBSSHBiosampleConditionInput
  ) {
    updateBSSHBiosample(input: $input, condition: $condition) {
      workspaceId
      id
      bioSampleName
      defaultProject
      bsshDateModified
      bsshDateCreated
      readGroups
      writeGroups
      adminGroups
      created
      updated
    }
  }
`;
export const deleteBSSHBiosample = /* GraphQL */ `
  mutation DeleteBSSHBiosample(
    $input: DeleteBSSHBiosampleInput!
    $condition: ModelBSSHBiosampleConditionInput
  ) {
    deleteBSSHBiosample(input: $input, condition: $condition) {
      workspaceId
      id
      bioSampleName
      defaultProject
      bsshDateModified
      bsshDateCreated
      readGroups
      writeGroups
      adminGroups
      created
      updated
    }
  }
`;
export const createDeletedEntitiesLog = /* GraphQL */ `
  mutation CreateDeletedEntitiesLog(
    $input: CreateDeletedEntitiesLogInput!
    $condition: ModelDeletedEntitiesLogConditionInput
  ) {
    createDeletedEntitiesLog(input: $input, condition: $condition) {
      id
      organizationId
      organizationName
      workspaceId
      workspaceName
      projectName
      pipelineName
      biosampleName
      deletedInParent
      userUuidCognito
      userNameCognito
      uuidOfDeletedEntity
      typeOfDeletedEntity
      status
      affectedBiosamples
      dateOfCreationOfDeletedEntity
      emailInfo
      readGroups
      writeGroups
      adminGroups
      created
      updated
    }
  }
`;
export const updateDeletedEntitiesLog = /* GraphQL */ `
  mutation UpdateDeletedEntitiesLog(
    $input: UpdateDeletedEntitiesLogInput!
    $condition: ModelDeletedEntitiesLogConditionInput
  ) {
    updateDeletedEntitiesLog(input: $input, condition: $condition) {
      id
      organizationId
      organizationName
      workspaceId
      workspaceName
      projectName
      pipelineName
      biosampleName
      deletedInParent
      userUuidCognito
      userNameCognito
      uuidOfDeletedEntity
      typeOfDeletedEntity
      status
      affectedBiosamples
      dateOfCreationOfDeletedEntity
      emailInfo
      readGroups
      writeGroups
      adminGroups
      created
      updated
    }
  }
`;
export const deleteDeletedEntitiesLog = /* GraphQL */ `
  mutation DeleteDeletedEntitiesLog(
    $input: DeleteDeletedEntitiesLogInput!
    $condition: ModelDeletedEntitiesLogConditionInput
  ) {
    deleteDeletedEntitiesLog(input: $input, condition: $condition) {
      id
      organizationId
      organizationName
      workspaceId
      workspaceName
      projectName
      pipelineName
      biosampleName
      deletedInParent
      userUuidCognito
      userNameCognito
      uuidOfDeletedEntity
      typeOfDeletedEntity
      status
      affectedBiosamples
      dateOfCreationOfDeletedEntity
      emailInfo
      readGroups
      writeGroups
      adminGroups
      created
      updated
    }
  }
`;
