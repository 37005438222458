<template>
  <Toast ref="toast" position="bottom-right" group="single-toast" />
</template>

<script>
export default {
  data() {
    return {
      connectionPreviouslyLow: false,
    };
  },
  mounted() {
    if ('connection' in navigator) {
      this.checkConnection(); // Call checkConnection when the component is mounted
      navigator.connection.addEventListener('change', this.checkConnection);
    }
  },
  beforeUnmount() {
    if ('connection' in navigator) {
      navigator.connection.removeEventListener('change', this.checkConnection);
    }
  },
  methods: {
    checkConnection() {
      if (!('connection' in navigator)) {
        this.showToast('warn', 'Connection Check Failed', 'Unable to check your connection status.');
        return;
      }
      const { downlink } = navigator.connection;
      if (downlink < 10) {
        if (!this.connectionPreviouslyLow) {
          this.showToast('warn', 'Connection Unstable', 'You may have issues loading content that requires heavy bandwidth.');
          this.connectionPreviouslyLow = true;
        }
      } else if (this.connectionPreviouslyLow) {
        this.showToast('success', 'Connection Improved', 'Your connection is now stable.', '10000');
        this.connectionPreviouslyLow = false;
      }
    },
    showToast(severity, summary, detail, life) {
      // Clear all toasts before displaying a new one
      this.$toast.removeAllGroups();
      this.$refs.toast.add({
        severity,
        summary,
        detail,
        life,
      });
    },
  },
};
</script>
