<template>
  <Dialog v-model:visible="showing" :style="{ width: '700px' }" header="Transfer Project" :modal="true" class="p-fluid" :closable="false">
    <div class="field">
      <DataTable :value="[projectForTransfer]">
        <Column field="clientProjectName" header="Project Name" />
        <Column header="FASTQ Size">
          <template #body="{ data }">
            {{ getSize(data.size) }}
          </template>
        </Column>
        <Column header="Pipeline Size">
          <template #body="{ data }">
            {{ getSize(data.size) }}
          </template>
        </Column>
        <Column header="Total Size">
          <template #body="{ data }">
            {{ getSize(data.size) }}
          </template>
        </Column>
      </DataTable>
    </div>
    <div class="field pt-4">
      <div class="p-inputgroup">
        <span class="p-float-label">
          <!-- <Dropdown id="destinationWorkspace" v-model="selectedWorkspace" :options="getValidWorkspaces" optionLabel="description" :filter="true" /> -->
          <Dropdown
            v-model="selectedWorkspace"
            :filter="true"
            :options="formattedWorkspaces"
            optionLabel="label"
            optionGroupLabel="label"
            optionGroupChildren="items"
            :filterFields="['filterLabel']"
            placeholder="Select Workspace"
          />
          <label for="destinationWorkspace">Select Recipient Workspace</label>
        </span>
      </div>
    </div>
    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-secondary" @click="hideDialog" />
      <Button label="Transfer Project" class="p-button-outline" @click="transferProject" />
    </template>
  </Dialog>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { API, graphqlOperation } from 'aws-amplify';
// eslint-disable-next-line no-unused-vars
import * as mutations from '@/graphql/mutations';
// eslint-disable-next-line no-unused-vars
import { sendEBMessage, getSize } from '@/utils';

export default {
  props: ['projectForTransfer'],
  data() {
    return {
      showing: false,
      selectedWorkspace: null,
    };
  },
  computed: {
    getValidWorkspaces() {
      const filteredWorkspaces = [];
      console.log('Getting valid workspaces');
      console.log('this.$store.getters.getAllWorkspacesLenght :>> ', this.$store.getters.getAllWorkspacesLenght);
      console.log('this.$store.state.allWorkspaces :>> ', this.$store.state.allWorkspaces);
      for (let i = 0; i < this.$store.getters.getAllWorkspacesLenght; i += 1) {
        if (this.$store.state.allWorkspaces[i].id !== this.$store.state.activeWorkspace) {
          filteredWorkspaces.push(this.$store.state.allWorkspaces[i]);
        }
      }
      return filteredWorkspaces;
    },
    formattedWorkspaces() {
      let groupedData = {};
      this.getValidWorkspaces.forEach((ws) => {
        if (!(ws.organization.organizationName in groupedData)) {
          groupedData[ws.organization.organizationName] = {
            label: ws.organization.organizationName,
            items: [{
              label: ws.description,
              id: ws.id,
              organizationId: ws.organizationId,
              filterLabel: `${ws.organization.organizationName}_${ws.description}`,
            }],
          };
        } else {
          groupedData[ws.organization.organizationName].items.push({
            label: ws.description,
            id: ws.id,
            organizationId: ws.organizationId,
            filterLabel: `${ws.organization.organizationName}_${ws.description}`,
          });
        }
      });
      groupedData = Object.values(groupedData);
      return groupedData;
    },
  },
  methods: {
    getSize(size) {
      return getSize(size);
    },
    async transferProject() {
      console.log('projectForTransfer :>> ', this.projectForTransfer);
      console.log('this.selectedWorkspace :>> ', this.selectedWorkspace);
      const projectTransferEventObject = {
        status: 'KickoffPipelines',
        pipeline: 'move_project_between_workspaces',
        step: 'pipeline_trigger',
        project_name: this.projectForTransfer.clientProjectName,
        project_name_uuid: this.projectForTransfer.id,
        workspace_name: this.projectForTransfer.workspace.description,
        workspace_uuid: this.projectForTransfer.workspace.id,
        organization_name: this.projectForTransfer.workspace.organization.organizationName,
        organization_uuid: this.projectForTransfer.workspace.organization.id,
        number_of_biosamples: this.projectForTransfer.biosamples.items.length,
        bucket: process.env.VUE_APP_BUCKET,
        unit_of_transfer: [
          {
            type: 'project',
            uuid: this.projectForTransfer.id,
            name: this.projectForTransfer.clientProjectName,
          },
        ],
        source_entity: {
          type: 'workspace',
          uuid: this.projectForTransfer.workspace.id,
          name: this.projectForTransfer.workspace.description,
        },
        destination_entity: {
          type: 'workspace',
          uuid: this.selectedWorkspace.id,
          name: this.selectedWorkspace.label,
        },
      };
      try {
        if (this.projectForTransfer.workspace.organization.id !== this.selectedWorkspace.organizationId) {
          projectTransferEventObject.destination_entity.organization_uuid = this.selectedWorkspace.organizationId;
        }
      } catch (error) {
        console.error('Failed to add organization_uuid');
        console.error(error);
      }
      try {
        sendEBMessage(projectTransferEventObject, this.projectForTransfer.workspace.organization.id, this.projectForTransfer.workspace.id, this.projectForTransfer.id, 'transfer');
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Project transfer started successfully!',
          life: 5000,
        });
      } catch (error) {
        console.error(error);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Project transfer failed!',
          life: 5000,
        });
      }
      this.hideDialog();
    },
    hideDialog() {
      this.selectedWorkspace = null;
      this.$store.dispatch('showTransferProject');
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingTransferProject': async function () {
      console.log('Change in transfer project dialog');
      this.showing = this.$store.state.showingTransferProject;
    },
  },
};
</script>
