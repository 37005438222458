<template>
  <div class="layout-topbar">
    <div class="logo" />
    <router-link :to="`/workspace/${this.$store.state.activeWorkspace}/projects/DNA`" class="layout-topbar-logo">
      <img src="@/assets/BioSkrybElements/BaseJumperLogoOLD.png" alt="Logo" />
    </router-link>
    <!-- <button
      class="p-link layout-menu-button layout-topbar-button"
      @click="onMenuToggle"
    > -->
    <!-- <i class="pi pi-bars"></i> -->
    <!-- </button> -->
    <!--<div class="p-link layout-menu-button layout-topbar-button hamburger-icon" id="icon" @click="onMenuToggle">-->
    <!--  <div :class="'icon-1 ' + (menu ? 'a' : '')" id="a"></div>-->
    <!--  <div :class="'icon-2 ' + (menu ? 'b' : '')" id="b"></div>-->
    <!--  <div :class="'icon-3 ' + (menu ? 'c' : '')" id="c"></div>-->
    <!--  <div class="clear"></div>-->
    <!--</div>-->
    <!-- eslint-disable-next-line vue/html-button-has-type -->
    <button
      class="p-link layout-topbar-menu-button layout-topbar-button"
      v-styleclass="{
        selector: '@next',
        enterActiveClass: 'scalein',
        leaveActiveClass: 'fadeout',
        hideOnOutsideClick: true,
      }"
    >
      <!-- Commented out because of DDK fix -->
      <!-- <button
      class="p-link layout-topbar-menu-button layout-topbar-button"
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        enterActiveClass: 'scalein',
        leaveToClass: 'hidden',
        leaveActiveClass: 'fadeout',
        hideOnOutsideClick: true,
      }"
    > -->
      <i class="pi pi-ellipsis-v" />
    </button>
    <!--<Button type="button" label="Toggle" @click="toggle" aria-haspopup="true" aria-controls="overlay_menu"/>-->
    <!-- <ul class="layout-topbar-menu hidden lg:flex origin-top"> -->
    <AppMenuNew />
    <ul class="layout-topbar-menu lg:flex origin-top">
      <li>
        <span class="tagline"><b>Accelerating genomic exploration.</b></span>
      </li>
      <li>
        <Dropdown
          v-model="selectedWorkspace"
          :filter="true"
          :options="formattedWorkspaces"
          optionLabel="label"
          optionGroupLabel="label"
          optionGroupChildren="items"
          :filterFields="['filterLabel']"
          placeholder="Select Workspace"
          @change="onSelectWorkspace"
        />
      </li>
      <li>
        <!-- eslint-disable-next-line vue/html-button-has-type -->
        <button class="p-link layout-topbar-button" @click="profileMenuClick">
          <i class="pi pi-user" />
          <span>Profile</span>
        </button>
        <Menu ref="menu" :model="profileMenuItems" :popup="true" />
      </li>
    </ul>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { Auth, API, graphqlOperation } from 'aws-amplify';
// eslint-disable-next-line no-unused-vars
import * as queries from '@/graphql/queries';
import AppMenuNew from '@/components/AppMenuNew.vue';
import {
  setPrecedence,
} from '@/utils';

export default {
  components: {
    AppMenuNew,
  },
  props: ['allWorkspaces', 'activeWorkspace', 'darkTheme'],
  emits: ['switchTheme'],
  async created() {
    this.email = Auth.currentUserInfo();
    if (this.activeWorkspace !== null && this.activeWorkspace !== undefined) {
      this.selectedWorkspace = {
        id: this.activeWorkspace.id,
        label: this.activeWorkspace.description,
        organizationId: this.activeWorkspace.organizationId,
        filterLabel: `${this.activeWorkspace.organization.organizationName}_${this.activeWorkspace.description}`,
      };
    }
    this.email.then((response) => {
      this.email = response.attributes.email;
      this.profileMenuItems = [
        {
          label: this.email,
        },
        {
          label: 'View Profile',
          command: () => {
            this.$store.dispatch('showProfile');
          },
        },
        // {
        //   label: 'Switch Theme',
        //   command: () => {
        //     this.$emit('switchTheme');
        //   },
        // },
        {
          label: 'Sign Out',
          command: () => {
            Auth.signOut().then(() => {
              this.$router.push('/auth/login');
              this.$store.dispatch('setActiveWorkspace', '');
            }).catch((e) => {
              console.log('error signing out: ', e);
            });
          },
        },
      ];
    });
  },
  data() {
    return {
      email: '',
      selectedWorkspace: null,
      profileMenuItems: [],
      menu: true,
      myDarkTheme: true,
    };
  },
  methods: {
    onMenuToggle(event) {
      this.$emit('menu-toggle', event);
      this.menu = !this.menu;
    },
    profileMenuClick(event) {
      this.$refs.menu.toggle(event);
    },
    // Very important!!! The rest of the application looks at these values (activeWorkspace and activeOrganization) to check permissions and what to display.
    async onSelectWorkspace() {
      this.$store.dispatch('setActiveWorkspace', this.selectedWorkspace.id);
      if (this.$store.state.bioskrybProduct !== null && this.$store.state.bioskrybProduct !== undefined) {
        this.$router.push(`/workspace/${this.$store.state.activeWorkspace}/projects/${this.$store.state.bioskrybProduct.replace('Resolve', '')}`);
      } else {
        this.$router.push(`/workspace/${this.$store.state.activeWorkspace}/projects/DNA`);
      }
      if (this.selectedWorkspace.organizationId) {
        this.$store.dispatch('setActiveOrganization', this.selectedWorkspace.organizationId);
      } else {
        console.log('Active organization failed, probably only user');
      }
      await setPrecedence();
    },
  },
  computed: {
    formattedWorkspaces() {
      let groupedData = {};
      this.allWorkspaces.forEach((ws) => {
        if (!(ws.organization.organizationName in groupedData)) {
          groupedData[ws.organization.organizationName] = {
            label: ws.organization.organizationName,
            items: [{
              label: ws.description,
              id: ws.id,
              organizationId: ws.organizationId,
              filterLabel: `${ws.organization.organizationName}_${ws.description}`,
            }],
          };
        } else {
          groupedData[ws.organization.organizationName].items.push({
            label: ws.description,
            id: ws.id,
            organizationId: ws.organizationId,
            filterLabel: `${ws.organization.organizationName}_${ws.description}`,
          });
        }
      });
      groupedData = Object.values(groupedData);
      return groupedData;
    },
  },
  watch: {
    activeWorkspace(value) {
      console.log('ACTIVE WORKSPACE CHANGED');
      this.selectedWorkspace = value;
    },
  },
};
</script>
<style lang="scss">
.tagline {
  position: relative;
  top: 10px;
  right: 15px;
  color: white;
  font-size: 20px;
}

@media (max-width: 1300px) {
  .tagline {
    display: none;
  }
}
</style>
