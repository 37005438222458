<template>
  <div class="no-grp-view flex justify-content-center align-items-center">
    <div class="container no-grp-text">
      <h1>Welcome to BaseJumper!</h1>
      <h3>The reason you are seeing this is because you haven't been assigned to any organizations or workspaces yet. Our administrators have been notified and you will be assigned as soon as possible. Thank you for your patience!</h3>
    </div>
  </div>
</template>

<style lang="scss">
.welcome {
  color: white;
}

.windows {
  background-color: white;
}

// .no-grp-view{
//   // background-image: url('~@/assets/BioSkryb Chalkboard XL.jpeg');
//   background-size: cover;
//   position: relative;
//   height: 50vh;
//   background-color: white;
//   opacity: 0.8;
//   border-radius: 25px;
// }

// .no-grp-text {
//   text-align: center;
// }
</style>
