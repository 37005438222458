<template>
  <div>
    <i class="chat-toggle pi pi-comments" @click="toggleDialog" @keydown.enter.space="toggleDialog" tabindex="0" />
    <Dialog v-model:visible="showDialog" header="SkrybAI" resizable="true" @show="scrollToBottom" class="custom-dialog">
      <div id="chat-bot">
        <div id="messages" ref="chatMessages" class="chat-messages" @scroll.passive="checkScroll">
          <p v-for="(message, index) in messages" :key="index">
            <strong v-if="message.isUser"><span class="user">{{ user }}</span></strong>
            <strong v-else><span class="skrybai">{{ bot }}</span></strong>
            <span v-if="message.id !== 'typing'">&nbsp;<small style="font-weight: normal !important;">{{ formatTimestamp(message.timestamp) }}</small></span><br />
            <span v-html="message.text" :class="{ 'typing-animation': message.id === 'typing' }" />
          </p>
        </div>
        <form @submit.prevent="sendMessage">
          <InputText ref="inputField" v-model="input" placeholder="Ask me anything about BaseJumper 🧬" :disabled="sendingMessage" autofocus aria-label="Chat input" />
          <Button icon="pi pi-send" type="submit" :disabled="sendingMessage" aria-label="Send button">
            <i v-if="sendingMessage" class="pi pi-spin pi-spinner" />
          </Button>
          <!-- Updated clear chat button to clear chat on double click -->
          <Button class="clear-chat" :icon="clearChatIcon" :disabled="sendingMessage" @click="handleClearClick" aria-label="Clear chat" />
        </form>
      </div>
    </Dialog>
  </div>
</template>

<script>
import axios from 'axios';
import MarkdownIt from 'markdown-it';
import { v4 as uuidv4 } from 'uuid';

export default {
  data() {
    return {
      showDialog: false,
      user: 'User',
      bot: 'SkrybAI',
      input: '',
      messages: [],
      sendingMessage: false,
      md: new MarkdownIt({
        linkify: true,
        breaks: true,
      }),
      botTyping: false,
      scrollPosition: null,
      clearChatIcon: 'pi pi-trash',
      clearChatTimeout: null,
      clearChatClicks: 0,
      sessionId: '',
    };
  },
  methods: {
    toggleDialog() {
      this.showDialog = !this.showDialog;
      if (this.showDialog) {
        this.$nextTick(this.scrollToBottom);
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const chatMessages = this.$refs.chatMessages;
        if (chatMessages) {
          chatMessages.scrollTop = chatMessages.scrollHeight;
        }
      });
    },
    async sendMessage() {
      const message = this.input.trim();
      if (message === '') return;
      this.input = '';

      // Disable the input box
      this.sendingMessage = true;

      this.messages.push({
        isUser: true,
        text: message,
        timestamp: Date.now(),
      });

      // Add bot typing message
      this.messages.push({
        isUser: false,
        text: 'SkrybAI is responding',
        id: 'typing',
      });

      this.botTyping = true;

      this.$nextTick(this.scrollToBottom);

      try {
        console.log('sessionId: ', this.sessionId);
        const response = await axios.get('https://k7lr4frvx8.execute-api.us-east-1.amazonaws.com/Prod/basejumper-docs', {
          params: {
            q: message,
            session_id: this.sessionId,
          },
          headers: {
            'x-api-key': 'u9KrCYsRsCaQ0sgBwsFNf9yWVzNGP8KG5OO1W4p3',
          },
        });

        // Remove bot typing message before adding the actual message
        this.messages = this.messages.filter((m) => m.id !== 'typing');
        this.messages.push({
          isUser: false,
          text: this.md.render(response.data.results),
          timestamp: Date.now(),
        });

        this.$nextTick(this.scrollToBottom);
      } catch (error) {
        console.error(error);
        this.messages.push({
          isUser: false,
          text: `Error: ${error.message || "I'm sorry, I couldn't process your message. Please try again later."}`,
          timestamp: Date.now(),
        });

        this.$nextTick(this.scrollToBottom);
      }

      this.input = '';
      this.sendingMessage = false;
      this.botTyping = false;

      this.$nextTick(() => {
        this.$refs.inputField.$el.focus();
      });
    },
    // Updated clear chat method
    handleClearClick() {
      if (this.clearChatTimeout) {
        // Second click within 5 seconds
        clearTimeout(this.clearChatTimeout);
        this.clearChatTimeout = null;
        this.clearChatClicks = 0;
        this.clearChatIcon = 'pi pi-trash';
        this.clearMessages();
      } else {
        // First click
        this.clearChatClicks += 1;
        this.clearChatIcon = 'pi pi-check';
        this.clearChatTimeout = setTimeout(() => {
          // Timeout reached, reset click count and icon
          this.clearChatClicks = 0;
          this.clearChatIcon = 'pi pi-trash';
          this.clearChatTimeout = null;
        }, 5000);
      }
    },
    clearMessages() {
      if (this.messages.length > 1) {
        this.messages.splice(1);
      }
    },
    checkScroll() {
      const chatMessages = this.$refs.chatMessages;
      this.scrollPosition = chatMessages.scrollTop;
    },
    formatTimestamp(timestamp) {
      return new Date(timestamp).toLocaleTimeString();
    },
  },
  created() {
    this.sessionId = uuidv4();
    const defaultRender = this.md.renderer.rules.link_open || function (tokens, idx, options, env, self) {
      return self.renderToken(tokens, idx, options);
    };

    this.md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
      const aIndex = tokens[idx].attrIndex('target');
      const newToken = JSON.parse(JSON.stringify(tokens[idx]));

      if (aIndex < 0) {
        newToken.attrs = newToken.attrs || [];
        newToken.attrs.push(['target', '_blank']);
      } else {
        newToken.attrs[aIndex][1] = '_blank';
      }

      return defaultRender(tokens, idx, options, env, self);
    };

    this.messages.push({
      isUser: false,
      text: 'Hello! I\'m SkrybAI, your reliable companion through the BaseJumper universe. Feel free to ask anything BaseJumper-related.<br/><i>For example, "How do I run a pipeline?"</i>',
      timestamp: Date.now(),
    });
  },
};
</script>

<style scoped>
#chat-bot {
  width: 400px;
  margin: auto;
}
.custom-dialog {
  position: fixed;
  right: 0;
  bottom: 0;
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dialog-header h3 {
  margin: 0;
}

.chat-messages {
  height: 400px;
  border: 1px solid #dee2e6;
  background-color: #f9f9f9;
  margin-bottom: 10px;
  padding: 10px;
  overflow-y: scroll;
  border-radius: 5px;
}

.chat-toggle {
  color: silver !important;
}
.chat-toggle:hover {
  cursor: pointer;
}

.chat-messages a {
  color: #12284d !important;
  font-weight: bold;
  transition-duration: .25s;
}

.chat-messages a:hover {
  color: #68adcb !important;
}

form {
  display: flex;
  justify-content: space-between;
}

input {
  flex-grow: 1;
  margin-right: 10px;
}
.typing-animation:after {
  content: '';
  animation: typing 1s infinite;
}

@keyframes typing {
  0% {
    content: '.';
  }
  33% {
    content: '..';
  }
  66% {
    content: '...';
  }
}

.user {
  color: #12284d;
}

.skrybai {
  color: #68adcb;
}

.clear-chat {
  margin-left: 3px;
}

.p-dialog-footer {
  padding: 0;
}

</style>
