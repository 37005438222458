<template>
  <div>
    <DataTable
      :value="launchablePipelines"
      rowGroupMode="rowspan"
      groupRowsBy="pipelineName"
      :loading="loading"
      dataKey="id"
      v-model:selection="selectedPipelines"
      :paginator="true"
      :rows="10"
      sortMode="single"
      sortField="pipelineName"
      :sortOrder="1"
      responsiveLayout="scroll">
      <Column selectionMode="multiple" headerStyle="width: 5%" />
      <Column field="pipelineName" header="Pipeline Name" :bodyStyle="getBodyStyle()" headerStyle="width: 17%" />
      <Column field="pipelineVersion" header="Pipeline Version" :bodyStyle="getBodyStyle()" headerStyle="width: 17%" />
      <Column field="description" header="Description" :bodyStyle="getBodyStyle()" />
    </DataTable>
  </div>
  <div class="flex justify-content-between pt-3">
    <Button class="mt-2 mb-2 p-button-raised p-button-secondary" label="Back" icon="pi pi-arrow-left" @click="back()" />
    <Button class="mt-2 mb-2 p-button-raised" label="Next" icon="pi pi-arrow-right" @click="nextPage" :disabled="selectedPipelines.length === 0" />
  </div>
  <div class="flex justify-content-end" />
</template>

<script>
export default {
  props: ['formData'],
  emits: ['prevPage', 'nextPage'],
  data() {
    return {
      launchablePipelines: [],
      selectedPipelines: [],
      loading: true,
    };
  },
  methods: {
    nextPage() {
      this.$emit('nextPage', { formData: { selectedTertiaryPipelines: this.selectedPipelines }, pageIndex: 1 });
    },
    back() {
      this.$emit('prevPage', { pageIndex: 1 });
    },
    getBodyStyle() {
      return 'text-align: center';
    },
    // Find union of all supported tertiary analysis
    async getTertiaryAnalysis(secondaryPipelines) {
      try {
        const launchablePipelines = await this.formData.launchablePipelines;
        const data = [];
        secondaryPipelines.forEach((pipeline) => {
          const pipelines = launchablePipelines.filter((p) => p.pipelineName === pipeline.pipelineName && p.pipelineVersion === pipeline.pipelineVersion);
          if (pipelines.length > 0) {
            pipelines.forEach((p) => {
              if (p.supportedTertiaryAnalyses !== null && p.supportedTertiaryAnalyses !== undefined && p.supportedTertiaryAnalyses.length > 0) {
                data.push(p.supportedTertiaryAnalyses);
              }
            });
          }
        });
        if (data.length > 0) {
          const result = data.reduce((a, b) => a.filter((c) => b.includes(c)));
          return result;
        }
        return [];
      } catch (error) {
        console.error('Error in getTertiaryAnalysis');
        console.error(error);
        return null;
      }
    },
    async getPipelinesForLaunch(supportedTertiaryPipelines) {
      const launchablePipelines = await this.formData.launchablePipelines;
      const validPipelines = [];
      launchablePipelines.forEach((pipeline) => {
        if (supportedTertiaryPipelines.includes(pipeline.pipelineName) && pipeline.analysisLevel === 'tertiary') {
          validPipelines.push(pipeline);
        }
      });
      return validPipelines;
    },
    async makeTertiaryAnalysis() {
      try {
        this.loading = true;
        const supportedTertiaryPipelines = await this.getTertiaryAnalysis(this.formData.selectedSecondaryPipelines);
        const launchablePipelines = await this.getPipelinesForLaunch(supportedTertiaryPipelines);
        this.launchablePipelines = launchablePipelines;
        this.loading = false;
      } catch (error) {
        console.error('Error in makeTertiaryAnalysis');
        console.error(error);
        this.loading = false;
      }
    },
  },
  mounted() {
    this.makeTertiaryAnalysis();
  },
  watch: {
    'formData.selectedSecondaryPipelines': {
      handler() {
        this.makeTertiaryAnalysis();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/sass/_dataTable.scss";
::v-deep(.p-datatable .p-datatable-thead > tr > th:first-child) {
  border-radius: 15px 0px 0px 0px;
}
::v-deep(.p-datatable .p-datatable-thead > tr > th:last-child) {
  border-radius: 0px 15px 0px 0px;
}
</style>
