<template>
  <div>
    <div v-for="(variable, index) in biosampleMetadataColumns" :key="index">
      <div class="flex pt-4">
        <Button class="add-btn mr-3" icon="pi pi-plus" @click="addRow(index)" />
        <Button class="remove-btn mr-3" icon="pi pi-trash" @click="removeRow(index)" />
        <InputText class="name-field mr-2" :disabled="variable.disableChangeTypeAndName" v-model.trim="variable.name" placeholder="Name" />
        <Dropdown class="type-dd mr-2" :disabled="variable.disableChangeTypeAndName" v-model="variable.type" :options="variableTypes" placeholder="Type" />
        <InputText class="description-field" v-model.trim="variable.description" placeholder="Description" />
      </div>
    </div>
  </div>
</template>
<script>
import { getEmptyMetadataObj } from '@/utils';

export default {
  name: 'biosampleMetadataColumnsRenderer',
  props: ['biosampleMetadataColumns'],
  data() {
    return {
      variableTypes: ['Text', 'Number', 'Size', 'True/False', 'Date'],
    };
  },
  methods: {
    addRow(index) {
      // eslint-disable-next-line vue/no-mutating-props
      this.biosampleMetadataColumns.splice(index + 1, 0, {
        name: '',
        type: '',
        description: '',
        editable: true,
        value: null,
      });
    },
    removeRow(index) {
      // eslint-disable-next-line vue/no-mutating-props
      this.biosampleMetadataColumns.splice(index, 1);
      this.handleIfLastColumnDeleted(index);
    },
    handleIfLastColumnDeleted(index) {
      if (index === 0 && this.biosampleMetadataColumns.length === 0) {
        // eslint-disable-next-line vue/no-mutating-props
        this.biosampleMetadataColumns.push(getEmptyMetadataObj());
      }
    },
  },
};
</script>
  <style lang="scss" scoped>
  .add-btn, .remove-btn {
    width: 30px !important;
  }
  .name-field {
    width: 120px !important;
  }
  .type-dd {
    width: 150px !important;
  }
  .description-field {
    width: 40% !important;
  }
  </style>
