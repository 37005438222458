<template>
  <div class="flex justify-content-end">
    <slot name="toggleTopUI" />
  </div>
  <div v-if="loading === true" class="flex justify-content-center">
    <img alt="BJSpinner" class="bjSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png" />
  </div>
  <div v-if="loading === false">
    <div v-if="htmlToRender !== null" class="multiqc-view" id="multiqc-view">
      <iframe title="MultiQC" :srcdoc="htmlToRender" frameborder="0" name="multiqc" />
    </div>
    <div v-if="htmlToRender === null" class="no-grp-view flex justify-content-center align-items-center mt-2">
      <div class="container no-grp-text">
        <h1>No MultiQC!</h1>
        <h3>The reason you are seeing this is because we cannot find MultiQC in this pipeline. If this pipeline should have a MultiQC please contact BaseJumper Custom Services which can be found in the menu one the left.</h3>
      </div>
    </div>
  </div>
</template>

<script>
// import { Auth } from 'aws-amplify';
import * as AWS from 'aws-sdk';
// eslint-disable-next-line no-unused-vars
import { largeuint8ArrToString, wait, setPrecedence } from '@/utils';

export default {
  name: 'MultiQC',
  props: ['pipeline'],
  data() {
    return {
      loading: true,
      htmlToRender: null,
      retry: false,
      retryCounter: 0,
    };
  },
  async mounted() {
    if (!this.htmlToRender) {
      this.loading = true;
      try {
        await this.loadMultiqc();
      } catch (error) {
        console.error(error);
      }
    }
  },
  methods: {
    // Loads the MultiQC from the S3 bucket
    async loadMultiqc() {
      this.loading = true;
      let authCredentials = await setPrecedence();
      const retry = 0;
      while (authCredentials === null && retry <= 5) {
        await wait(1000);
        authCredentials = await setPrecedence();
      }
      if (authCredentials === null) {
        console.error('Error in setting credentials in multiqc');
        return;
      }
      AWS.config.update(authCredentials);
      const s3 = new AWS.S3();
      try {
        const params = {
          Bucket: process.env.VUE_APP_BUCKET,
          Key: `${this.pipeline.project.workspace.organization.id}/${this.pipeline.project.workspace.id}/${this.pipeline.project.id}/pipeline/${this.pipeline.pipelineOutputS3Path}/multiqc/multiqc_report.html`,
        };
        s3.getObject(params, async (err, data) => {
          if (data) {
            largeuint8ArrToString(data.Body, (text) => {
              this.htmlToRender = this.reformatMultiQCHtml(text);
              this.loading = false;
            });
          }
          if (err) {
            console.error('Error in getObject err');
            console.error(err);
            // Didnt find the key. Maybe this is an old version.
            if (err.code === 'NoSuchKey') {
              try {
                this.getOldVersionOfMultiqc();
              } catch (error) {
                console.error('Error getting old version of MultiQC');
                console.error(error);
              }
            }
            if (err.code === 'CredentialsError' && this.retryCounter <= 3) {
              console.log('Credentials error waiting.');
              await wait(100);
              this.loadMultiqc();
              console.log('Trying again');
              this.retryCounter += 1;
            }
          }
        });
      } catch (err) {
        console.log('Error in cath');
        console.error(err);
      }
    },
    async getOldVersionOfMultiqc() {
      const s3 = new AWS.S3();
      try {
        const params = {
          Bucket: process.env.VUE_APP_BUCKET,
          Key: `${this.pipeline.project.workspace.organization.id}/${this.pipeline.project.workspace.id}/${this.pipeline.project.id}/pipeline/${this.pipeline.pipelineOutputS3Path}/${this.pipeline.pipelineName.toUpperCase()}_WF_MULTIQC_WF_MULTIQC/multiqc_report.html`,
        };
        s3.getObject(params, (err, data) => {
          if (data) {
            largeuint8ArrToString(data.Body, (text) => {
              this.htmlToRender = this.reformatMultiQCHtml(text);
              this.loading = false;
            });
          }
          if (err) {
            console.error('Error in getObject err');
            console.error(err);
            // Didnt find the key. Maybe this is an old version.
            if (err.code === 'NoSuchKey') {
              this.loading = false;
              this.$toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Cannot find multiqc!',
                life: 3000,
              });
            }
          }
        });
      } catch (err) {
        console.log('Error in cath');
        console.error(err);
      }
    },
    // The MultiQC has some a tags which try to change te route, but they try to change the parent(our global) route so it craches. The code below replaces those tags with javascript to achive the same effect
    reformatMultiQCHtml(html) {
      if (html === null || html === undefined || html === '') {
        return html;
      }
      let allLinks = [];
      try {
        // Fix sidebar links
        const regexAll = /<a href="#([a-zA-Z]*([-_\s]*))*[0-9]?" class="nav-l[0-9]{1,10}">([a-zA-Z()]*([-_\s]*))*<\/a>/igm;
        allLinks = html.match(regexAll);
      } catch (error) {
        console.error(error);
        return '';
      }
      let formatedHtml = html;
      if (allLinks === null || allLinks === undefined || allLinks.length === 0) {
        return html;
      }
      allLinks.forEach((link) => {
        const split = link.split('"');
        let id = split[1];
        id = id.substring(1, id.length);
        const aClass = split[3];
        let name = split[4];
        name = name.substring(1, name.length - 4);
        const replacement = `<a href="javascript:void(0)" class="${aClass}" onclick="document.getElementById('${id}').scrollIntoView();">${name}</a>`;
        formatedHtml = formatedHtml.replace(link, replacement);
      });
      // Fix top left logo link and others
      const regexStartLink = /<a href="#">/igm;
      const allHomepageLinks = html.match(regexStartLink);
      if (allHomepageLinks !== null && allHomepageLinks !== undefined) {
        allHomepageLinks.forEach((link) => {
          const replacement = '<a href="javascript:void(0)">';
          formatedHtml = formatedHtml.replace(link, replacement);
        });
      }

      // Fix copy table format
      const copyCodeRegex = /e\.clearSelection\(\);/igm;
      // eslint-disable-next-line no-useless-escape
      const replacement = 'navigator.clipboard.writeText(e.text.replaceAll("\\\\t","\\\\s"));\ne.clearSelection();';
      formatedHtml = formatedHtml.replace(copyCodeRegex, replacement);

      // Go to top
      const goToTopRegex = /<a href="#top" class="mqc-toplink hidden-xs" title="Go to top"><span class="glyphicon glyphicon-chevron-up"><\/span><\/a>/igm;
      const goToTopReplacement = '<a href="javascript:void(0)" class="mqc-toplink hidden-xs" onclick="document.getElementById(\'page_title\').scrollIntoView();" title="Go to top"><span class="glyphicon glyphicon-chevron-up"></span></a>';
      formatedHtml = formatedHtml.replace(goToTopRegex, goToTopReplacement);
      return formatedHtml;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/sass/_dataTable.scss";

iframe {
  width: 100%;
  height: 90vh;
}

::v-deep(.p-datatable-header) {
    border-radius: $border_rounded_top;
}

.bjSpinner {
  height: 30%;
  width: 30%;
}
</style>
