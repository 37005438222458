<template>
  <Dialog v-model:visible="showing" :style="{ width: '600px' }" header="Look for BSSH samples" :modal="true" class="p-fluid" :closable="false">
    <div v-if="!loading">
      <div class="field pt-4">
        <div class="p-inputgroup">
          <span class="p-float-label">
            <Dropdown id="workspaces" v-model="selectedToken" :options="allTokens" :filter="true" />
            <label for="workspaces">Select token</label>
          </span>
        </div>
      </div>
      <div v-if="!loadingSamples && biosamples.length !== 0" class="pt -3">
        <div class="p-inputgroup mb-4">
          <Button v-if="this.selectedProjects.length > 0" icon="pi pi-angle-double-down" class="p-button-info addAllProjectButton" @click="addAllBiosamplesForProjects()" v-tooltip.left="'Add all biosamples from selected projects'" />
          <AutoComplete
            :multiple="true"
            v-model="selectedProjects"
            :suggestions="filteredProjects"
            @complete="searchProjects($event)"
            field="name"
            :virtualScrollerOptions="{ itemSize: 31 }"
            :placeholder="(this.selectedProjects.length === 0) ? 'BaseSpace Sequence Hub Project Name' : ''"
            dropdown
            forceSelection
          />
        </div>
        <div class="p-inputgroup">
          <Button v-if="this.selectedBiosamples.length > 0" icon="pi pi-trash" class="p-button-info" @click="clearAllSelectedBiosamples()" v-tooltip.left="'Clear'" />
          <AutoComplete
            :multiple="true"
            class="biosamplesAutoComp vh-100"
            v-model="selectedBiosamples"
            :suggestions="filteredBiosamples"
            @complete="searchBiosamples($event)"
            field="BioSampleName"
            :placeholder="(this.selectedBiosamples.length === 0) ? 'BaseSpace Sequence Hub BioSample Name' : ''"
            :virtualScrollerOptions="{ itemSize: 31 }"
            dropdown
            forceSelection
          />
        </div>
      </div>
      <div v-if="loadingSamples">
        <!-- <ProgressSpinner class="flex justify-content-center pt-3"></ProgressSpinner> -->
        <img alt="BJSpinner" class="bjSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png" />
        <span class="flex justify-content-center pt-3">Loading Samples...</span>
      </div>
    </div>
    <div v-if="loading">
      <!-- <ProgressSpinner class="flex justify-content-center pt-3"></ProgressSpinner> -->
      <img alt="BJSpinner" class="bjSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png" />
      <span class="flex justify-content-center pt-3">{{ message }}</span>
    </div>
    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-secondary" @click="hideDialog" />
      <Button label="Load Samples from BSSH" class="p-button-outline" @click="loadSamplesFromBSSH" :disabled="selectedToken === null" />
    </template>
  </Dialog>
</template>

<script>
import axios from 'axios';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '@/graphql/queries';

export default {
  data() {
    return {
      showing: false,
      loading: false,
      loadingSamples: false,
      allTokens: [],
      biosamples: [],
      projects: [],
      message: '',
      selectedToken: null,
      selectedBiosamples: [],
      selectedProjects: [],
      filteredProjects: [],
      filteredBiosamples: [],
      availableBiosamples: [],
      availableProjects: [],
    };
  },
  methods: {
    async loadWorkspaces() {
      try {
        this.loading = true;
        this.message = 'Loading...';
        const allWorkspaces = [];
        const workspacesResponse = await API.graphql(graphqlOperation(queries.listWorkspaces, { limit: 300 }));
        allWorkspaces.push(...workspacesResponse.data.listWorkspaces.items);
        let nextToken = workspacesResponse.data.listWorkspaces.nextToken;
        while (nextToken !== null && nextToken !== undefined) {
          const nextWorkspacesResponse = await API.graphql(graphqlOperation(queries.listWorkspaces, { limit: 300, nextToken }));
          nextToken = nextWorkspacesResponse.data.listWorkspaces.nextToken;
          allWorkspaces.push(...nextWorkspacesResponse.data.listWorkspaces.items);
        }
        this.message = '';
        this.loading = false;
        const tokenSet = new Set();
        allWorkspaces.forEach((ws) => {
          if (ws.basespaceAccessToken !== null && ws.basespaceAccessToken !== undefined) {
            tokenSet.add(ws.basespaceAccessToken);
          }
        });
        this.allTokens = Array.from(tokenSet);
      } catch (error) {
        console.log('Error in loading Workspaces');
        console.log(error);
        this.loading = false;
      }
    },
    async loadSamplesFromBSSH() {
      try {
        console.log('Loading bssh biosamples');
        this.loadingSamples = true;
        if (this.selectedToken === null) {
          this.$toast.add({
            severity: 'error',
            summary: 'No access token!',
            detail: 'You need to select an Access Token first',
            life: 3000,
          });
        }
        const samples = [];
        const url = 'https://api.basespace.illumina.com/v2/biosamples?limit=2000&sortBy=modifiedon&sortDir=desc&offset=0';
        const response = await axios.get(url, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-access-token': this.selectedToken,
          },
          redirect: 'follow',
          mode: 'cors',
          cache: 'no-cache',
          referrerPolicy: 'no-referrer',
          timeout: 20000,
        });
        samples.push(...response.data.Items);
        const total = response.data.Paging.TotalCount;
        const offsets = [];
        let remain = 2000;
        while (remain < total) {
          offsets.push(remain);
          remain += 2000;
        }
        const batchPromises = [];
        offsets.forEach((localOffset) => {
          batchPromises.push(this.getBiosamples(localOffset));
        });
        const listOfListsOfBiosamples = await Promise.all(batchPromises);
        listOfListsOfBiosamples.forEach((biosampleList) => {
          if (biosampleList === null) return; // It is possible for some of the individual fetches to fail
          samples.push(...biosampleList.samples);
        });
        this.biosamples = samples;
        this.availableBiosamples = samples;
        this.filteredBiosamples = samples;
        this.availableProjects = await this.makeProjects();
        this.filteredProjects = this.availableProjects;
      } catch (error) {
        console.error('Error in loading samples from BSSH');
        console.error(error);
      }
      this.loadingSamples = false;
    },
    searchBiosamples(event) {
      // console.log('event :>> ', event);
      // console.log('event.query :>> ', event.query);
      const options = [];
      if (Array.isArray(this.availableBiosamples[0])) {
        this.availableBiosamples[0].forEach((biosample) => {
          if (biosample.BioSampleName.includes(event.query)) {
            options.push(biosample);
          }
        });
      } else {
        for (let i = 0; i < this.availableBiosamples.length; i += 1) {
          if ('BioSampleName' in this.availableBiosamples[i] && this.availableBiosamples[i].BioSampleName.includes(event.query)) {
            options.push(this.availableBiosamples[i]);
          }
          if ('data' in this.availableBiosamples[i] && this.availableBiosamples[i].data.createBSSHBiosample.BioSampleName.includes(event.query)) {
            options.push(this.availableBiosamples[i]);
          }
        }
      }
      this.filteredBiosamples = options;
    },
    async getBiosamples(offset) {
      // console.log('Getting biosamples for page: ', page);
      try {
        const url = `https://api.basespace.illumina.com/v2/biosamples?limit=2000&sortBy=modifiedon&sortDir=desc&offset=${offset}`;
        const response = await axios.get(url, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-access-token': this.selectedToken,
          },
          redirect: 'follow',
          mode: 'cors',
          cache: 'no-cache',
          referrerPolicy: 'no-referrer',
          timeout: 20000,
        });
        // return response.data.Items;
        return {
          samples: response.data.Items,
          total: response.data.Paging.TotalCount,
        };
      } catch (error) {
        console.log(error);
        if (error.code === 'ECONNABORTED') {
          console.log('Timeout error');
          this.$toast.add({
            severity: 'error',
            summary: 'Biosamples timeout',
            detail: 'Getting biosamples from Illumina BaseSpace Sequence Hub took too long. Please try again later ',
            life: 5000,
          });
          this.bsshFetchFailed = true;
          return null;
        }
        console.log('Second error');
        this.$toast.add({
          severity: 'error',
          summary: 'Biosamples Error',
          detail: 'There was a problem with getting the Biosamples from Illumina BaseSpace Sequence Hub.',
          life: 5000,
        });
        this.bsshFetchFailed = true;
        return null;
      }
    },
    addAllBiosamplesForProjects() {
      this.selectedBiosamples = [...new Set([...this.selectedBiosamples, ...this.filteredBiosamples])];
    },
    searchProjects(event) {
      const options = [];
      for (let i = 0; i < this.availableProjects.length; i += 1) {
        if (this.availableProjects[i].name.includes(event.query)) {
          options.push(this.availableProjects[i]);
        }
      }
      this.filteredProjects = options;
    },
    clearAllSelectedBiosamples() {
      this.selectedBiosamples = [];
    },
    async makeProjects() {
      console.log('Started making project');
      const projects = [];
      this.biosamples.forEach((biosample) => {
        // const found = projects.some((el) => el.name === biosample.DefaultProject.Name);
        // console.log('biosample :>> ', biosample);
        try {
          const found = projects.some((el) => el.name === biosample.DefaultProject.Name);
          if (!(found)) {
            const project = { name: biosample.DefaultProject.Name };
            projects.push(project);
          }
        } catch (error) {
          console.error('Error making projects: ');
          console.error(error);
        }
      });
      console.log('projects :>> ', projects);
      return projects;
    },
    hideDialog() {
      this.$store.dispatch('setShowingLookForBSSHBiosamples', false);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingLookForBSSHBiosamples': async function () {
      this.showing = this.$store.state.showingLookForBSSHBiosamples;
      this.loadWorkspaces();
    },
  },
};
</script>

<style lang="scss" scoped>
.p-inputgroup .p-float-label .p-dropdown {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

::v-deep(.p-autocomplete-multiple-container) {
  overflow: auto;
}

.pi-check-circle {
  font-size: 4.5rem;
  color: #00b300;
}

.pi-times-circle {
  font-size: 4.5rem;
  color: #ff0000;
}

</style>
