<template>
  <div v-if="open" class="bg-overlay" />
  <div class="container">
    <span :style="backgroundOptionStyle" />
    <Button
      :class="(open) ? 'bj-dial-open-btn-opened' : 'bj-dial-open-btn'"
      :label="this.label"
      rounded
      @click="open = !open"
      :disabled="disabled"
      @blur="executeCommand(null)" />
    <div v-if="open" class="circle-container">
      <Button
        v-for="(circle, index) in options"
        :key="index"
        rounded
        :class="(circle.disabled) ? 'circle-disabled' : 'circle' + ' p-button'"
        @mousedown.stop="executeCommand(circle.command, circle.disabled)"
        :style="circle.style"
        :label="circle.label"
        v-tooltip="getTooltip(circle.tooltip)" />
    </div>
  </div>
</template>

<script>
import { valueIsNullOrUndefined } from '@/utils';

export default {
  // props: ['label', 'options', 'disabled'],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    label: String,
    // eslint-disable-next-line vue/require-default-prop
    options: [Object],
    disabled: Boolean,
    passedInitialRadius: {
      type: Number,
      default: 20,
    },
    passedBGCircleOffset: {
      type: Number,
      default: 3.5,
    },
  },
  data() {
    return {
      // options: [
      //   {
      //     label: 'RNASeq',
      //     disabled: false,
      //     command: () => {
      //       console.log('Opening RNASeq');
      //     },
      //   },
      // ],
      executingCommand: false,
      initialRadius: this.passedInitialRadius,
      BGCircleOffset: this.passedBGCircleOffset,
      baseTransition: 40,
      open: false,
    };
  },
  mounted() {
    for (let i = 0; i < this.options.length; i += 1) {
      // eslint-disable-next-line vue/no-mutating-props
      this.options[i].style = this.calculateCirclePosition(i);
    }
  },
  methods: {
    executeCommand(command, disabled = false) {
      if (disabled) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Not Available!',
          detail: 'The selected action is currently not available. Check if there are any requirements for this action.',
          life: 5000,
        });
        return;
      }
      this.open = false;
      if (!valueIsNullOrUndefined(command)) {
        this.executingCommand = true;
        command();
      }
    },
    // eslint-disable-next-line no-unused-vars
    getIcon(open) {
      if (open) return 'pi-times';
      return 'pi-plus';
    },
    getTooltip(tooltipText) {
      return {
        value: tooltipText,
        autoHide: false,
        style: {
          background: 'red',
        },
      };
    },
    calculateCirclePosition(i) {
      const angle = (2 * Math.PI) / this.options.length;
      const x = Math.cos(i * angle) * this.radius;
      const y = Math.sin(i * angle) * this.radius;

      return `top: calc(${x}px - 1rem); left: ${y}px; animation-delay: ${this.baseTransition * i}ms !important;`;
    },
  },
  computed: {
    radius() {
      return this.options.length * this.initialRadius - (3 * this.options.length);
    },
    backgroundOptionStyle() {
      if (!this.open) return '';
      const backgroundColor = (document.body.classList.contains('dark')) ? '#0c596e' : '#0e8aa3';
      return `height: ${this.radius * 2}px; width: ${this.radius * 2}px; top: calc(-${this.radius}px + 1rem); left: calc(-${this.radius}px + ${this.BGCircleOffset}rem); background: ${backgroundColor}; opacity: 0.93; border-radius: 50%; display: inline-block; position: absolute; z-index: 999; filter: blur(3px);`;
    },
  },
};
</script>

<style lang="scss">
.bg-overlay {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: linear-gradient(rgba(0, 0, 0, 0.3),
                       rgba(0, 0, 0, 0.3));
  animation: 100ms fadeInModal;
  z-index: 998;
}

.container {
  position: relative;
}

.circle-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  z-index: 1000;
}

.circle {
  position: absolute;
  width: 100px;
  height: 50px;
  border-radius: 50%;
  /* background-color: rgba(94, 94, 94, 1); */
  // background-color: #0e8aa3;
  // background-color: #19396d;
  .dark {
    background-color: #A0CC2C;
    border: 1px solid #A0CC2C;
  }

  .light {
    background-color: #19396d;
    border: 1px solid #19396d;
  }
  /* background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 0%, rgba(0,212,255,1) 97%); */
  animation: 100ms fadeIn;
  animation-fill-mode: forwards;
  opacity: 0;
  display: flex;
  justify-content: center;
  z-index: 999;
  border: 0px;

  // border: 1px solid #0a6274;
  box-shadow: 0 1px 3px 0px rgba(#0F0F0F, .5),
    inset 0 1px rgba(#FFF, .2);

  &:hover {
    // background-color: #0c697c !important;
    // background-color: #0c1c35 !important;
    .light {
      background-color: #0c1c35 !important;
    }
    .dark {
      background-color: #69861e !important;
    }
  }
}

.dark .circle-disabled, .light .circle-disabled {
  position: absolute;
  width: 100px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(94, 94, 94, 1);
  /* background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 0%, rgba(0,212,255,1) 97%); */
  animation: 100ms fadeIn;
  animation-fill-mode: forwards;
  opacity: 0;
  display: flex;
  justify-content: center;
  z-index: 999;
  border: 0px;

  border: 1px solid rgb(59, 59, 59);
  box-shadow: 0 1px 3px 0px rgba(#0F0F0F, .5),
    inset 0 1px rgba(#FFF, .2);

  .dark .p-button:enabled:hover:not(.p-button-secondary), .light .p-button:enabled:hover:not(.p-button-secondary) {
    background: none;
    border: none;
  }

  &:hover {
    background-color: rgb(61, 61, 61) !important;
  }
}

.dial-text {
  color: white;
  text-align: center;
  top: 30%;
  position: absolute;
}

.bj-dial-open-btn {
  /* transition: transform 0.3s ease; */
  height: 1.5vh;

  border: 1px solid #122a50;
  box-shadow: 0 1px 3px 0px rgba(#0F0F0F, .5),
    inset 0 1px rgba(#FFF, .2);
}
.bj-dial-open-btn-opened {
  /* transform: rotate(45deg); */
  /* transition: transform 0.3s ease; */
  z-index: 1001;
  height: 1.5vh;
}

@keyframes fadeIn {
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInModal {
  10% {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.03));
  }
  20% {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.06));
  }
  30% {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.09));
  }
  40% {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.12));
  }
  50% {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.15));
  }
  60% {
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.28));
  }
  70% {
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.21));
  }
  80% {
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.24));
  }
  90% {
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.27));
  }
  100% {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  }
}

</style>
