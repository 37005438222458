<template>
  <Dialog v-model:visible="showing" :style="{ width: '550px' }" header="Link Projects" :modal="true" class="p-fluid" :closable="false">
    <div v-if="linking" class="field flex flex-column justify-content-center container pt-4">
      <!-- <ProgressSpinner class="flex justify-content-center pt-3"></ProgressSpinner> -->
      <img alt="BJSpinner" class="bjSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png" />
      <span class="flex justify-content-center pt-5"><b>Linking projects. Please wait</b></span>
    </div>
    <div v-else>
      <label id="link-lb-lbl" for="link-lb"> Select the projects you wish to link your project with.</label>
      <Listbox id="link-lb" class="link-lb mt-2" v-model="selectedProject" :options="projects" optionLabel="clientProjectName" listStyle="max-height:28.4vh" :filter="true" />
    </div>
    <template #footer>
      <div v-if="!linking" class="pt-4">
        <Button label="Cancel" icon="pi pi-times" class="p-button-secondary" @click="hideDialog" />
        <Button :disabled="selectedProject === null" @click="linkProjects" label="Link" />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '@/graphql/mutations';

export default {
  props: ['projectForLinking', 'allProjects'],
  data() {
    return {
      selectedProject: null,
      showing: false,
      linking: false,
    };
  },
  methods: {
    hideDialog() {
      this.selectedProject = null;
      this.$store.dispatch('showLinkProjects');
      // this.showing = false;
    },
    async linkProjects() {
      if (this.projectForLinking === null) {
        console.error('Project for linking is null');
        return;
      }
      if (this.selectedProject === null) {
        console.error('Selected project is null');
        return;
      }
      try {
        this.linking = true;
        const promises = [];
        promises.push(this.singleLink(this.projectForLinking, this.selectedProject));
        promises.push(this.singleLink(this.selectedProject, this.projectForLinking));
        await Promise.all(promises);
        this.linking = false;
        this.hideDialog();
      } catch (error) {
        console.error('Linking projects failed');
        console.error(error);
      }
    },
    async singleLink(project1, project2) {
      const updatePrjectParams = {
        id: project1.id,
        associatedProjectId: project2.id,
      };
      try {
        await API.graphql(graphqlOperation(mutations.updateProject, {
          input: updatePrjectParams,
        }));
      } catch (error) {
        console.error('Error in linking ', project1, ' to ', project2);
        console.error(error);
      }
    },
    async singleUnlink(project1) {
      console.log('Unlinking ', project1);
      const updatePrjectParams = {
        id: project1.id,
        associatedProjectId: null,
      };
      try {
        await API.graphql(graphqlOperation(mutations.updateProject, {
          input: updatePrjectParams,
        }));
      } catch (error) {
        console.error('Error in unlinking ', project1);
        console.error(error);
      }
    },
  },
  computed: {
    projects() {
      if (this.$store.state.unlinkProjects) {
        return [this.projectForLinking];
      }
      return this.allProjects.filter((project) => (project.projectType !== this.projectForLinking.projectType) && (project.projectType !== null));
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingLinkProjects': async function () {
      if (!this.$store.state.unlinkProjects) {
        this.showing = this.$store.state.showingLinkProjects;
      }
    },
    projectForLinking() {
      try {
        if (this.$store.state.unlinkProjects) {
          this.singleUnlink(this.projectForLinking);
          this.singleUnlink(this.projectForLinking.associatedProject);
          this.$store.dispatch('toggleUnlinkProjects');
          this.selectedProject = null;
          this.$parent.reloadTable();
        }
      } catch (error) {
        console.error('ProjectForLinking watcher failed');
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
.link-lb {
  height: 35vh;
}
</style>
