<template>
  <Dialog v-model:visible="showing" :style="{ width: '400px' }" header="Reset BSSH Biosamples Cache" :modal="true" class="p-fluid" :closable="false">
    <div v-if="!loading && !clearing && !done">
      <div class="field pt-4">
        <div class="p-inputgroup">
          <span class="p-float-label">
            <Dropdown id="workspaces" v-model="selectedWorkspace" :options="this.allWorkspaces" optionLabel="description" :filter="true" />
            <label for="workspaces">Select workspace cache to clear</label>
          </span>
        </div>
      </div>
      <div class="field pt-4" v-if="selectedWorkspace !== null && selectedWorkspace !== undefined">
        <div class="p-inputgroup">
          <span class="p-float-label">
            <Calendar inputId="time24" v-model="date" :showTime="true" :showSeconds="true" />
            <label for="workspaces">Select date to re-cache from</label>
          </span>
        </div>
      </div>
    </div>
    <div v-if="loading || clearing">
      <!-- <ProgressSpinner class="flex justify-content-center pt-3"></ProgressSpinner> -->
      <img alt="BJSpinner" class="bjSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png" />
      <span class="flex justify-content-center pt-3">{{ message }}</span>
    </div>
    <div class="flex justify-content-center pt-3 done" v-if="done">
      <i :class="'pi' + ((!failed) ? ' pi-check-circle' : ' pi-times-circle')" />
    </div>
    <template #footer v-if="!loading && !clearing">
      <Button label="Cancel" icon="pi pi-times" class="p-button-secondary" @click="hideDialog" />
      <Button label="Reset Cache" class="p-button-outline" @click="resetCache" v-if="!done" />
      <Button label="Reset Lite" class="p-button-outline" @click="resetDate" v-if="!done" />
      <!-- <Button label="Reset Links" class="p-button-outline" @click="resetLinks" :disabled="selectedWorkspace === null" v-if="!done"/> -->
    </template>
  </Dialog>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '@/graphql/queries';
import * as mutations from '@/graphql/mutations';
import { formatDateToAWS } from '@/utils';

export default {
  data() {
    return {
      showing: false,
      loading: true,
      clearing: false,
      done: false,
      failed: false,
      selectedWorkspace: null,
      message: '',
      allWorkspaces: [],
      date: null,
    };
  },
  methods: {
    hideDialog() {
      this.clearing = false;
      this.done = false;
      this.selectedWorkspace = null;
      this.message = '';
      this.date = null;
      this.$store.dispatch('setShowingResetBSSHBiosampleCache', false);
    },
    async loadWorkspaces() {
      try {
        this.loading = true;
        this.message = 'Loading...';
        const workspacesResponse = await API.graphql(graphqlOperation(queries.listWorkspaces, { limit: 300 }));
        this.allWorkspaces.push(...workspacesResponse.data.listWorkspaces.items);
        let nextToken = workspacesResponse.data.listWorkspaces.nextToken;
        while (nextToken !== null && nextToken !== undefined) {
          const nextWorkspacesResponse = await API.graphql(graphqlOperation(queries.listWorkspaces, { limit: 300, nextToken }));
          nextToken = nextWorkspacesResponse.data.listWorkspaces.nextToken;
          this.allWorkspaces.push(...nextWorkspacesResponse.data.listWorkspaces.items);
        }
        this.message = '';
        this.loading = false;
      } catch (error) {
        console.log('Error in loading Workspaces');
        console.log(error);
        this.loading = false;
      }
      console.log('this.allWorkspaces :>> ', this.allWorkspaces);
    },
    resetDate() {
      try {
        const updateWSVariables = {
          id: this.selectedWorkspace.id,
          lastCached: formatDateToAWS(this.date),
        };
        API.graphql(graphqlOperation(mutations.updateWorkspace, {
          input: updateWSVariables,
        }));
        this.hideDialog();
      } catch (error) {
        console.error('Error in resetting date');
        console.error(error);
      }
    },
    async resetCache() {
      try {
        if (this.selectedWorkspace === null) {
          console.log('No workspace selected');
          return;
        }
        if (this.selectedWorkspace.basespaceAccessToken === null || this.selectedWorkspace.basespaceAccessToken === undefined) {
          console.log('No access token for this workspace');
          this.$toast.add({
            severity: 'error',
            summary: 'No access token!',
            detail: 'The selected workspace does not have an access token. Please contact the workspace owner.',
            life: 3000,
          });
          return;
        }
        this.clearing = true;
        this.failed = false;
        this.message = 'Clearing cache. This might take a while...';
        const listBsshResp = await API.graphql(graphqlOperation(queries.listBSSHBiosamples, {
          workspaceId: this.selectedWorkspace.id,
          limit: 5000,
        }));
        let token = listBsshResp.data.listBSSHBiosamples.nextToken;
        const allBiosamples = [];
        allBiosamples.push(...listBsshResp.data.listBSSHBiosamples.items);
        const updateWSVariables = {
          id: this.selectedWorkspace.id,
          lastCached: null,
        };
        API.graphql(graphqlOperation(mutations.updateWorkspace, {
          input: updateWSVariables,
        }));
        console.log('updateWSVariables :>> ', updateWSVariables);
        while (token !== null) {
          const secondlistBsshResp = await API.graphql(graphqlOperation(queries.listBSSHBiosamples, {
            workspaceId: this.selectedWorkspace.id,
            limit: 5000,
            nextToken: token,
          }));
          token = secondlistBsshResp.data.listBSSHBiosamples.nextToken;
          allBiosamples.push(...secondlistBsshResp.data.listBSSHBiosamples.items);
        }
        console.log('allBiosamples :>> ', allBiosamples);
        if (allBiosamples.length === 0) {
          this.message = 'No BSSH biosamples found.';
          this.clearing = false;
          this.done = true;
          return;
        }
        let batch = [];
        for (let i = 0; i <= allBiosamples.length; i += 1) {
          batch.push(allBiosamples[i]);
          if ((i % 2000 === 0) || (allBiosamples.length === i + 1)) {
            await this.deleteBatch(batch);
            batch = [];
          }
        }
      } catch (error) {
        console.log('Error in resetCache');
        console.log(error);
        this.failed = true;
      } finally {
        this.message = '';
        this.clearing = false;
        this.done = true;
      }
    },
    async deleteBatch(batch) {
      try {
        const promises = [];
        batch.forEach((biosample) => {
          try {
            promises.push(API.graphql(graphqlOperation(mutations.deleteBSSHBiosample, {
              input: {
                id: biosample.id,
                workspaceId: biosample.workspaceId,
              },
            })));
          } catch (error) {
            console.log('Error in deleteBatch');
            console.log(error);
          }
        });
        await Promise.all(promises);
      } catch (error) {
        console.error('Failed in deleteBatch for loop');
        console.error(error);
      }
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingResetBSSHBiosampleCache': async function () {
      this.showing = this.$store.state.showingResetBSSHBiosampleCache;
      this.loadWorkspaces();
    },
  },
};
</script>

<style lang="scss" scoped>
.p-inputgroup .p-float-label .p-dropdown {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.pi-check-circle {
  font-size: 4.5rem;
  color: #00b300;
}

.pi-times-circle {
  font-size: 4.5rem;
  color: #ff0000;
}

</style>
