<template>
  <div class="grid flex justify-content-evenly" v-if="!loading">
    <Card v-for="(pipeline, index) in formData.selectedTertiaryPipelines" v-bind:key="index">
      <template #content>
        <ScrollPanel :style="getScrollWidthHeight()" class="custombar1">
          <div class="pb-3">
            <h5>Pipeline name: {{ pipeline.pipelineName }}</h5>
            <h5>Pipeline version: {{ pipeline.pipelineVersion }}</h5>
          </div>
          <DynamicRenderer
            :selectedLaunchablePipelineParameters="getPipelineParameters(parametersObj[pipeline.id])"
            :selectedLaunchablePipelineModules="getPipelineModules(parametersObj[pipeline.id])"
            :data="data[pipeline.id]"
            @input="updateData($event, pipeline.id)"
          />
        </ScrollPanel>
      </template>
    </Card>
  </div>
  <div class="flex justify-content-between pt-3">
    <Button class="mt-2 mb-2 p-button-raised p-button-secondary" label="Back" icon="pi pi-arrow-left" @click="back()" />
    <Button class="mt-2 mb-2 p-button-raised" label="Next" icon="pi pi-arrow-right" @click="nextPage" />
  </div>
</template>
<script>
import DynamicRenderer from '@/components/Pipeline/DynamicParameterComponents/DynamicRenderer.vue';

export default {
  props: ['formData'],
  emits: ['prevPage', 'nextPage'],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    DynamicRenderer,
  },
  data() {
    return {
      parametersObj: {},
      data: {},
      loading: true,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    back() {
      this.$emit('prevPage', { pageIndex: 4 });
    },
    nextPage() {
      this.$emit('nextPage', { formData: { pipelineParameters: this.data }, pageIndex: 4 });
    },
    init() {
      this.loading = true;
      for (let i = 0; i < this.formData.selectedTertiaryPipelines.length; i += 1) {
        const pipeline = this.formData.selectedTertiaryPipelines[i];
        const parameters = JSON.parse(pipeline.parameters);
        this.parametersObj[pipeline.id] = parameters;
        this.data[pipeline.id] = {};
      }
      this.loading = false;
    },
    getScrollWidthHeight() {
      // return `width: 100%; height: 35vh`;
      return 'width: 100%; height: 55vh';
    },
    getPipelineParameters(pipeline) {
      try {
        console.log('pipeline.parameters :>> ', pipeline.parameters);
        return pipeline.parameters;
      } catch (error) {
        console.error('Error returning parameters');
        console.error(error);
        return [];
      }
    },
    getPipelineModules(pipeline) {
      try {
        console.log('pipeline.modules :>> ', pipeline.modules);
        return pipeline.modules;
      } catch (error) {
        console.error('Error returning modules');
        console.error(error);
        return [];
      }
    },
    // eslint-disable-next-line camelcase
    updateData({ nf_command, value }, pipelineId) {
      console.log('nf_command :>> ', nf_command);
      console.log('value :>> ', value);
      // eslint-disable-next-line camelcase
      this.data[pipelineId][nf_command] = value;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'formData.selectedTertiaryPipelines': function () {
      this.init();
    },
  },
};
</script>
<style lang="scss" scoped>
.p-card {
  // max-height: 25vh;
  width: 32vw;
  // overflow: auto;
}
// .p-card-content {
//   padding: 0%;
// }
// ::v-deep(.p-card) {
//   background: red;
// }

::v-deep(.p-scrollpanel) {
  &.custombar1 {
    // width: 100%;
    // height: 35vh;
    .p-scrollpanel-wrapper {
        border-right: 9px solid var(--surface-ground);
    }

    .p-scrollpanel-bar {
        // background-color: var(primary-color);
        background-color: #19396d;
        opacity: 1;
        transition: background-color .2s;

        // &:hover {
        //     background-color: #19396d;
        // }
    }
  }
}
</style>
