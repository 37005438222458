<template>
  <div class="container">
    <div class=" vstack mb-3">
      <label class="lbl-check" :for="name">{{ name }}</label>
      <!-- eslint-disable-next-line vue/no-mutating-props -->
      <InputSwitch class="check" :id="name" v-model="skipValue" @change="$emit('input', skipValue)" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Check',
  props: ['description', 'value', 'name'],
  emits: ['input'],
  computed: {
    skipValue() {
      return !this.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.check {
  transform: translate(5px, 7px);
}

.lbl-check {
  position: relative;
  left: 5px;
}
</style>
