<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <DataTable
      :value="pipelines"
      :loading="loading"
      :paginator="true"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      @rowSelect="onPipelineSelect"
      :rowClass="rowClass"
      :selectionMode="($store.state.runningTertiaryAnalysis) ? '' : 'single'">
      <template #empty>
        No pipelines found.
      </template>
      <template #loading>
        Loading pipelines... Please wait.
      </template>
      <Column selectionMode="multiple" v-if="$store.state.runningTertiaryAnalysis" ref="selectionCol" />
      <Column field="pipelineName" header="Pipeline" :bodyStyle="getBodyStyle()" />
      <Column field="pipelineVersion" header="Version" :bodyStyle="getBodyStyle()" />
      <Column header="Number of Biosamples" :bodyStyle="getBodyStyle()">
        <template #body="{ data }">
          {{getNumberOfBiosamples(data.passFailBiosamples)}}
        </template>
      </Column>
      <Column header="Status" :bodyStyle="getBodyStyle()">
        <template #body="{ data }">
          <span :class="'customer-badge ' + statusClass(data.status)">{{data.status}}</span>
        </template>
      </Column>
      <Column header="Size" :bodyStyle="getBodyStyle()">
        <template #body="{ data }">
          {{ getSize(data.size) }}
        </template>
      </Column>
      <Column header="Duration" :bodyStyle="getBodyStyle()">
        <template #body="{ data }">
          {{ this.getTimeDiff(data) }}
        </template>
      </Column>
      <Column field="initiator" header="Initiator" :bodyStyle="getBodyStyle()" />
      <Column field="runDescription" header="Description" :bodyStyle="getBodyStyle()" />
      <Column header="Launch Date" :bodyStyle="getBodyStyle()">
        <template #body="{ data }">
          {{ this.getDate(data.pipelineStarted) }}
        </template>
      </Column>
    </DataTable>
  </div>
  <PipelineParamTable
    :paramString="paramString"
    @clear="this.paramString = null"
  />
  <ExportDialog v-if="this.pipelineForExport" :pipeline="pipelineForExport" />
</template>

<script>
import { Auth, API, graphqlOperation } from 'aws-amplify';
import _ from 'lodash';
import * as queries from '@/graphql/queries';
import * as mutations from '@/graphql/mutations';
import PipelineParamTable from '@/components/Pipeline/PipelineParamTable.vue';
import ExportDialog from '@/components/Utils/ExportDialog.vue';
import {
  // eslint-disable-next-line no-unused-vars
  sendEBMessage, getSize, filterValidPipelinesForViz, listItems, valueIsNullOrUndefined,
} from '@/utils';

export default {
  name: 'Pipelines',
  components: {
    PipelineParamTable,
    ExportDialog,
  },
  emits: ['visualize'],
  props: ['project'],
  data() {
    return {
      loading: false,
      pipelineForExport: null,
      pipelines: [],
      limit: 300,
      pipelineStatusUpdateSubscription: null,
      paramString: null,
      hasLinkedProject: false,
      dialogVisible: false,
    };
  },
  async mounted() {
    await this.loadPipelines();
    if (this.$store.state.runningTertiaryAnalysis) {
      this.createTertiaryPipelineWatcher();
    }
  },
  methods: {
    rowClass(data) {
      let classToReturn = '';
      if (data.projectId !== this.project.id) {
        classToReturn += ' pipelines-from-linked';
        return 'pipelines-from-linked';
      }
      if (data.status !== 'Job Complete') {
        classToReturn += ' no-selection';
      }
      if (classToReturn !== '') return classToReturn;
      return null;
    },
    getSize(size) {
      return getSize(size);
    },
    onPipelineSelect(event) {
      console.log('event :>> ', event);
      if (this.$store.state.runningTertiaryAnalysis) return;
      if (event.data.status !== 'Job Complete') {
        this.$toast.add({
          severity: 'warn',
          summary: 'Pipeline Not Available',
          detail: "You cannot visualize a pipeline which hasn't been completed successfully. Please contact a BaseJumper administrator or choose another pipeline.",
          life: 5000,
        });
        return;
      }
      this.setSelectedPipelinesForVisualization(event.data);
    },
    async setSelectedPipelinesForVisualization(selectedPipeline) {
      const selectedPipelineExtracted = {
        id: selectedPipeline.id,
        pipelineOutputS3Path: selectedPipeline.pipelineOutputS3Path,
        project: {
          id: selectedPipeline.project.id,
          workspace: {
            id: selectedPipeline.project.workspace.id,
            organization: {
              id: selectedPipeline.project.workspace.organization.id,
            },
          },
        },
      };
      const toVisualize = {
        pipeline: selectedPipelineExtracted,
        visualization: this.$store.state.visualizationToOpen,
      };
      console.log('toVisualize :>> ', toVisualize);
      this.$emit('visualize', toVisualize);
    },
    statusClass(status) {
      return status.toLowerCase().replace(' ', '-');
    },
    getDuration(pipeline) {
      if (pipeline.pipelineCompleted) {
        return this.$filters.diffTime(pipeline.pipelineStarted, pipeline.pipelineCompleted);
      }
      return this.$filters.diffTime(pipeline.pipelineStarted, new Date());
    },
    getDate(date) {
      return date.split('T')[0];
    },
    // If the pipeline is done, calculate how long it ran. If its still running, calculate how long
    getTimeDiff(pipeline) {
      const startDate = Date.parse(pipeline.pipelineStarted);
      if (pipeline.pipelineCompleted) {
        const endDate = Date.parse(pipeline.pipelineCompleted);
        const diff = Math.abs(endDate - startDate);
        return this.msToTime(diff);
      }
      if (pipeline.status === 'Job Started') {
        const now = Date.now();
        const diff = Math.abs(startDate - now);
        return this.msToTime(diff);
      }
      return '';
    },
    // LookAndFeel time format
    msToTime(duration) {
      const seconds = Math.floor((duration / 1000) % 60);
      const minutes = Math.floor((duration / (1000 * 60)) % 60);
      const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
      const days = Math.floor((duration / (1000 * 60 * 60 * 24)) % 7);
      const weeks = Math.floor(duration / (1000 * 60 * 60 * 24 * 7));

      return `${(weeks > 1) ? `${weeks}w ` : ''}${(days > 1) ? `${days}d ` : ''} ${(hours > 1) ? `${hours}h ` : ''} ${(minutes > 1) ? `${minutes}m ` : ''} ${(seconds > 1) ? `${seconds}s ` : ''}`;
    },
    async loadPipelines() {
      this.loading = true;
      this.pipelines = (await this.loadPipelinesForProject(this.project.id)).flat();
      const onlyCompletedPipelines = this.pipelines.filter((pipeline) => pipeline.status === 'Job Complete');
      const validPipelines = await filterValidPipelinesForViz(onlyCompletedPipelines, this.$store.state.visualizationToOpen);
      this.pipelines = validPipelines;
      this.checkIfOnlyOneAvailablePipeline(this.pipelines);
      this.loading = false;
    },
    checkIfOnlyOneAvailablePipeline(pipelines) {
      const filteredPipelines = pipelines.filter((pipeline) => pipeline.status === 'Job Complete');
      if (filteredPipelines.length === 1) this.setSelectedPipelinesForVisualization(filteredPipelines[0]);
    },
    async loadPipelinesForProject(projectId) {
      try {
        const params = this.makeParamsToGetPipelines(projectId);
        const pipelines = await listItems(queries.pipelinesByProject, params);
        return pipelines;
      } catch (error) {
        console.error('Error in loadPipelinesForProject');
        console.error(error);
        return [];
      }
    },
    makeParamsToGetPipelines(projectId) {
      const params = {
        projectId,
        limit: this.limit,
        sortDirection: 'DESC',
      };
      return params;
    },
    getBodyStyle() {
      return 'text-align: center';
    },
    getNumberOfBiosamples(passFailBiosamplesString) {
      try {
        if (passFailBiosamplesString === null || passFailBiosamplesString === undefined || passFailBiosamplesString === '') {
          return 0;
        }
        const passFailBiosamplesJSON = JSON.parse(passFailBiosamplesString);
        return Object.keys(passFailBiosamplesJSON).length;
      } catch (error) {
        console.error('Error in setting number of biosamples');
        console.error(error);
        return 0;
      }
    },
    async cancel(pipelineId, pipelineName, organizationId, workspaceId, projectId) {
      try {
        const details = {
          step: 'stop_workflow', analysis: '', pipeline: pipelineName, pipeline_uuid: pipelineId,
        };
        sendEBMessage(details, organizationId, workspaceId, projectId, 'cancel');
      } catch (error) {
        console.error(error);
      }
    },
    async deletePipeline(pipeline) {
      this.createDeletionLog(pipeline);
      const pipelineVariables = {
        id: pipeline.id,
      };
      try {
        const filtered = _.cloneDeep(this.pipelines.filter((filterPipeline) => pipeline.id !== filterPipeline.id));
        this.pipelines = filtered;
        await API.graphql(graphqlOperation(mutations.deletePipeline, { input: pipelineVariables }));
      } catch (error) {
        console.error('pipeline delete error - ', error);
      }
    },
    async createDeletionLog(pipeline) {
      try {
        if (pipeline === null || pipeline === undefined) {
          throw new Error('Pipeline is null in createDeletionLog');
        }
        const user = await Auth.currentAuthenticatedUser();
        const deleteLogObj = {
          organizationId: pipeline.project.workspace.organizationId,
          organizationName: pipeline.project.workspace.organization.organizationName,
          workspaceId: pipeline.project.workspace.id,
          workspaceName: pipeline.project.workspace.description,
          projectName: pipeline.project.clientProjectName,
          pipelineName: pipeline.pipelineName,
          userUuidCognito: user.username,
          userNameCognito: user.attributes.email,
          uuidOfDeletedEntity: pipeline.id,
          deletedInParent: false,
          typeOfDeletedEntity: 'pipeline',
          status: 'GraphQlDeletion',
          affectedBiosamples: JSON.stringify(this.makeAffectedBiosamplesFromPipeline(pipeline)),
          dateOfCreationOfDeletedEntity: pipeline.created,
          emailInfo: JSON.stringify(this.makeEmailInfoFromPipeline(pipeline)),
          readGroups: pipeline.readGroups,
          writeGroups: pipeline.writeGroups,
          adminGroups: pipeline.adminGroups,
        };
        await API.graphql(graphqlOperation(mutations.createDeletedEntitiesLog, { input: deleteLogObj }));
      } catch (error) {
        console.error(error);
      }
    },
    // eslint-disable-next-line no-unused-vars
    makeAffectedBiosamplesFromPipeline(pipeline) {
      if (pipeline.passFailBiosamples === null || pipeline.passFailBiosamples === undefined) {
        return [];
      }
      return [];
    },
    makeEmailInfoFromPipeline(pipeline) {
      return {
        application_environment: process.env.VUE_APP_SLS_STAGE,
        project_name: pipeline.project.clientProjectName,
        project_uuid: pipeline.project.id,
        initiator: pipeline.project.initiator,
        workspace_uuid: pipeline.project.workspace.id,
        workspace_name: pipeline.project.workspace.description,
        organization_uuid: pipeline.project.workspace.organization.id,
        organization_name: pipeline.project.workspace.organization.organizationName,
        commit_id: process.env.VUE_APP_AWS_COMMIT_ID,
      };
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingExport': async function () {
      if (!this.$store.state.showingExport) {
        this.pipelineForExport = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/sass/_dataTable.scss";
::v-deep(.pipelines-from-linked) {
    background-color: rgba(255,255,255,.7) !important;
}

// ::v-deep(.p-datatable .p-datatable-thead > tr > th:first-child) {
//   border-radius: 15px 0px 0px 0px;
// }
// ::v-deep(.p-datatable .p-datatable-thead > tr > th:last-child) {
//   border-radius: 0px 15px 0px 0px;
// }

::v-deep(.no-selection .p-selection-column .p-checkbox .p-component) {
  display: none;
}
::v-deep(.p-datatable .p-datatable-tbody > tr.p-highlight.no-selection ) {
  background: white;
}
::v-deep(.no-selection .p-checkbox) {
  display: none;
}
</style>
