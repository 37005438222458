<template>
  <div class="grid flex justify-content-evenly">
    <Card v-for="(pipeline, counter) in formattedFileInput" v-bind:key="counter">
      <template #content>
        <div class="pb-3">
          <h5>Pipeline name: {{ pipeline.pipelineName.split('-').at(0) }}</h5>
          <h5>Pipeline version: {{ pipeline.pipelineName.split('-').at(1) }}</h5>
        </div>
        <MultiSelect v-model="selectedFilesObject[pipeline.id]" :options="pipeline.arr" placeholder="Select File Types" />
      </template>
    </Card>
  </div>
  <div class="flex justify-content-between pt-3">
    <Button class="mt-2 mb-2 p-button-raised p-button-secondary" label="Back" icon="pi pi-arrow-left" @click="back()" />
    <Button class="mt-2 mb-2 p-button-raised" label="Next" icon="pi pi-arrow-right" @click="nextPage" />
  </div>
</template>
<script>
export default {
  props: ['formData'],
  emits: ['prevPage', 'nextPage', 'removeTertiary'],
  data() {
    return {
      formattedFileInput: [],
      selectedFilesObject: {},
      confirmationDialogVisible: false,
    };
  },
  mounted() {
    // for (let i = 0; i < this.formData.selectedTertiaryPipelines.length; i += 1) {
    //   const pipeline = this.formData.selectedTertiaryPipelines[i];
    //   const arr = [];
    //   pipeline.supportedInputFileTypes.forEach((fileType) => {
    //     arr.push(fileType);
    //   });
    //   this.formattedFileInput.push({ id: pipeline.id, pipelineName: `${pipeline.pipelineName}-${pipeline.pipelineVersion}`, arr });
    // }
    this.init();
  },
  methods: {
    back() {
      this.$emit('prevPage', { pageIndex: 2 });
    },
    nextPage() {
      const emptyPipelines = [];
      const deleteTertiary = [];
      this.formData.selectedTertiaryPipelines.forEach((pipeline) => {
        if (!(pipeline.id in this.selectedFilesObject)) {
          deleteTertiary.push(pipeline.id);
        } else if (this.selectedFilesObject[pipeline.id].length === 0) {
          emptyPipelines.push(pipeline.id);
          deleteTertiary.push(pipeline.id);
        }
      });
      if (deleteTertiary.length > 0) {
        this.$confirm.require({
          message: 'You have not selected file types for one or more pipelines, those pipelines will not be launched. Do you wish to proceed?',
          header: 'Confirmation',
          icon: 'pi pi-info-circle',
          accept: async () => {
            emptyPipelines.forEach((pipelineId) => {
              try {
                console.log('Deleting pipeline from selectedFilesObject');
                delete this.selectedFilesObject[pipelineId];
              } catch (error) {
                console.error('Could not delete pipeline from selectedTertiary');
                console.error(error);
              }
            });
            console.log('this.formData :>> ', this.formData);
            this.$emit('removeTertiary', { deleteTertiary });
            this.$emit('nextPage', { formData: { selectedFilesObject: this.selectedFilesObject }, pageIndex: 2 });
            if (this.dialogVisible) this.dialogVisible = false;
          },
          reject: () => {
            if (this.dialogVisible) this.dialogVisible = false;
          },
        });
      } else {
        this.$emit('nextPage', { formData: { selectedFilesObject: this.selectedFilesObject }, pageIndex: 2 });
      }
    },
    init() {
      this.formattedFileInput = [];
      for (let i = 0; i < this.formData.selectedTertiaryPipelines.length; i += 1) {
        const pipeline = this.formData.selectedTertiaryPipelines[i];
        const arr = [...pipeline.supportedInputFileTypes];
        this.formattedFileInput.push({ id: pipeline.id, pipelineName: `${pipeline.pipelineName}-${pipeline.pipelineVersion}`, arr });
        if (arr.length === 1) {
          if (this.selectedFilesObject[pipeline.id] === null || this.selectedFilesObject[pipeline.id] === undefined) {
            this.selectedFilesObject[pipeline.id] = [arr[0]];
          }
        }
      }
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'formData.selectedTertiaryPipelines': function () {
      this.init();
    },
  },
};
</script>
<style lang="scss" scoped>
// Does nothing, for now
::v-deep(.p-inputgroup > .p-component, .p-inputgroup > .p-inputwrapper > .p-inputtext, .p-inputgroup > .p-float-label > .p-component) {
  max-height: 5px;
  overflow: auto;
}
</style>
