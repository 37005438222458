<template>
  <MiniProjectTable v-if="valueIsNullOrUndefined(projectForPipelineTable)" @setProjectForMiniPipelineTable="setProjectForMiniPipelineTable" />
  <MiniPipelineTable v-if="!valueIsNullOrUndefined(projectForPipelineTable)" :project="projectForPipelineTable" @visualize="visualize" />
</template>

<script>
import { valueIsNullOrUndefined } from '@/utils';
import MiniProjectTable from '@/components/Visualization/MiniProjectTable.vue';
import MiniPipelineTable from '@/components/Visualization/MiniPipelineTable.vue';

export default {
  name: 'Visualize',
  components: { MiniProjectTable, MiniPipelineTable },
  emits: ['addVisualization'],
  data() {
    return {
      visualization: null,
      pipeline: null,
      projectForPipelineTable: null,
      vizId: 0,
    };
  },
  methods: {
    setProjectForMiniPipelineTable(project) {
      this.projectForPipelineTable = project;
    },
    visualize(dataToVisualize) {
      this.$emit('addVisualization', dataToVisualize);
    },
    valueIsNullOrUndefined(value) {
      return valueIsNullOrUndefined(value);
    },
  },
};
</script>

  <style lang="scss" scoped>
  .header {
    margin-bottom: 1.5rem;
  }
  </style>
