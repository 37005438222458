<template>
  <Dialog v-model:visible="showing" :style="{ width: '750px' }" header="Update Biosample Metadata Projects" :modal="true" class="p-fluid" :closable="false">
    <div v-if="updating" class="flex justify-content-center">
      <img alt="BJSpinner" class="bjSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png" />
    </div>
    <div v-if="!updating">
      <span>Hello there. It seams some of the columns you are trying to import don't match the columns in the biosamples table. Could you help us and map the columns that don't match? We made a guess but we are not 100% sure.</span>
      <div class="wrapper-div pt-3" v-for="(col, index) in notFoundColumns" :key="index">
        <div v-if="col.columnAction === 'Change'">
          <div class="flex pt-4 wrapper-div">
            <span class="p-float-label mr-3 elem-div">
              <InputText id="unk-col-name" class="name-field mr-2" v-model.trim="col.name" disabled />
              <label for="unk-col-name">Unknown Column Name</label>
            </span>
            <span class="p-float-label elem-div">
              <Dropdown id="actual-col-name" class="type-dd mr-2" v-model="col.actualColumn" :options="optionColumns" optionLabel="name" />
              <label for="actual-col-name" class="mb-3">Actual Column</label>
            </span>
            <Button class="add-col-btn pl-2" label="Add as new column" @click="col.columnAction = 'Add'" />
            <!-- <InputText class="description-field" v-model.trim="variable.description" placeholder="Description" /> -->
          </div>
        </div>
        <div v-if="col.columnAction === 'Add'">
          <div class="flex pt-4">
            <InputText class="name-field mr-2" v-model.trim="col.name" placeholder="Name" />
            <Dropdown class="type-dd mr-2" v-model="col.type" :options="variableTypes" placeholder="Type" />
            <InputText class="description-field" v-model.trim="col.description" placeholder="Description" />
            <Button class="ml-2" label="Add as existing column" @click="col.columnAction = 'Change'" />
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <div v-if="!updating" class="pt-4">
        <Button label="Cancel" icon="pi pi-times" class="p-button-secondary" @click="hideDialog" />
        <Button @click="save()" label="Save" />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '@/graphql/mutations';
import {
  // eslint-disable-next-line no-unused-vars
  calculateEditDistance, valueIsNullOrUndefined, makeColumnsFromFactory, makeBiosampleMetadataColumnsJson, duplicatesByKeyInArray,
} from '@/utils';
import _ from 'lodash';

export default {
  props: ['header', 'notFoundColumns', 'columns', 'projectColumns', 'projectId'],
  emits: ['doneFixingHeader', 'setColumns'],
  data() {
    return {
      showing: false,
      updating: false,
      optionColumns: [],
      variableTypes: ['Text', 'Number', 'Size', 'True/False', 'Date'],
    };
  },
  methods: {
    main() {
      this.optionColumns = this.filterOptionColumns(this.optionColumns, this.header);
      this.handleUnknownColumns(this.notFoundColumns, this.optionColumns);
    },
    filterOptionColumns(optionColumns, header) {
      let filteredOptionColumns = optionColumns;
      filteredOptionColumns = _.cloneDeep(this.columns);
      filteredOptionColumns = filteredOptionColumns.filter((col) => col.editable);
      filteredOptionColumns = filteredOptionColumns.filter((col) => !header.some((headerCol) => headerCol.inferredName === col.inferredName));
      return filteredOptionColumns;
    },
    handleUnknownColumns(notFoundColumns, columns) {
      notFoundColumns.forEach((notFoundColumn) => {
        let editDistances = [];
        columns.forEach((col) => {
          editDistances.push({
            name: col.name,
            distance: calculateEditDistance(col.name, notFoundColumn.name),
            column: col,
          });
        });
        const minDistance = Math.min(...editDistances.map((dist) => dist.distance));
        editDistances = editDistances.filter((dist) => dist.distance === minDistance);
        // eslint-disable-next-line no-param-reassign
        notFoundColumn.actualColumn = (Object.keys(columns).length >= 1) ? editDistances[0].column : null;
        // eslint-disable-next-line no-param-reassign
        notFoundColumn.columnAction = (Object.keys(columns).length >= 1) ? 'Change' : 'Add';
        // eslint-disable-next-line no-param-reassign
        notFoundColumn.editable = true;
      });
    },
    async save() {
      try {
        if (duplicatesByKeyInArray(this.notFoundColumns, 'name')) {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Column name already exists!',
            life: 3000,
          });
          return;
        }
        const fullHeader = this.header;
        const newColumns = [];
        for (let i = 0; i < this.notFoundColumns.length; i += 1) {
          const notFoundColumn = this.notFoundColumns[i];
          if (notFoundColumn.columnAction === 'Change') fullHeader.splice(notFoundColumn.index, 0, notFoundColumn.actualColumn);
          if (notFoundColumn.columnAction === 'Add') {
            if (!this.checkIfAddingColumnIsGood(notFoundColumn)) return;
            newColumns.push(notFoundColumn);
            fullHeader.splice(notFoundColumn.index, 0, ...makeColumnsFromFactory([notFoundColumn]));
          }
        }
        if (newColumns.length > 0) {
          this.$emit('setColumns', await this.handleAddingNewColumns(newColumns));
        }
        this.$emit('doneFixingHeader', fullHeader);
        this.hideDialog();
      } catch (error) {
        console.error(error);
        this.hideDialog();
      }
    },
    checkIfAddingColumnIsGood(col) {
      if (this.columns.some((existingCol) => existingCol.name === col.name)) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Column name already exists!',
          life: 3000,
        });
        return false;
      }
      if (!('type' in col) || valueIsNullOrUndefined(col.type)) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Column type must be set!',
          life: 3000,
        });
        return false;
      }
      return true;
    },
    async handleAddingNewColumns(notFoundColumns) {
      try {
        const newColumns = this.makeColumnObjects(notFoundColumns);
        const colsToUpdateProjectMetadata = [...(JSON.parse(this.projectColumns)).columns, ...newColumns];
        await this.updateProject(colsToUpdateProjectMetadata);
        return makeColumnsFromFactory(newColumns);
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    makeColumnObjects(notFoundColumns) {
      const newColumns = [];
      for (const notFoundColumn of notFoundColumns) {
        const newColumn = {
          name: notFoundColumn.name,
          type: notFoundColumn.type,
          description: notFoundColumn.description,
          editable: true,
          value: null,
        };
        newColumns.push(newColumn);
      }
      return newColumns;
    },
    async updateProject(colsToUpdateProjectMetadata) {
      await API.graphql(graphqlOperation(mutations.updateProject, {
        input: {
          id: this.projectId,
          biosampleMetadataColumns: makeBiosampleMetadataColumnsJson(colsToUpdateProjectMetadata),
        },
      }));
    },
    hideDialog() {
      this.$store.dispatch('setShowingBiosampleMetadataImportDialog', false);
      this.updating = false;
    },
  },
  computed: {
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingBiosampleMetadataImportDialog': async function () {
      this.showing = this.$store.state.showingBiosampleMetadataImportDialog;
      if (this.showing) {
        this.main();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .wrapper-div {
    width: 100%;
  }
  .elem-div {
    width: 45%;
  }
  .add-col-btn {
    width: 30%;
  }
</style>
