<template>
  <div>
    <ProjectsTable />
  </div>
</template>

<script>
import ProjectsTable from '@/components/Project/ProjectsTable.vue';

export default {
  components: {
    ProjectsTable,
  },
};
</script>
