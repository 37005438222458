<template>
  <div class="auth-view flex justify-content-center align-items-center">
    <div class="logo">
      <img alt="logo" src='@/assets/logo_with_tagline.png'>
    </div>
    <div class="moto">
      <img alt="tagline" src='@/assets/BioSkrybElements/CellsExplored-Tagling.png'>
    </div>
    <div class="no-grp-view flex justify-content-center align-items-center">
      <div class="container no-grp-text">
        <h1>Maintenance</h1>
        <h3>BaseJumper is under maintenance! Thank you for your patience.</h3>
        <div>
          <Button label="Sign Out" @click="signOut()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';
// eslint-disable-next-line import/no-cycle
import router from '@/router/index';

export default {
  data() {
    return {
      signOut() {
        Auth.signOut().then(() => {
          router.push('/auth/login');
        }).catch((e) => {
          console.log('error signing out: ', e);
        });
      },
    };
  },
  methods: {
  },
};
</script>
<style lang="scss">
.auth-view{
  background-image: url('~@/assets/BioSkrybElements/BioSkryb_BaseJumper_LoginBackground.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  background-size: 100% 100%;
  height: 100vh;

  .logo {
    position: absolute;
    left: 40px;
    top: 40px;
    img {
      width: 30vh;
    }
  }

  .moto {
    position: absolute;
    right: 40px;
    top: 40px;
    img {
      width: 60vh;
    }
  }
  .welcome {
    color: white;
  }

  .windows {
    background-color: white;
  }

  .no-grp-view{
    background-size: cover;
    position: relative;
    height: 50vh;
    background-color: white;
    opacity: 0.8;
    border-radius: 25px;
    width: 60%;
  }

  .no-grp-text {
    text-align: center;
  }
}
</style>
