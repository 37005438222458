<template>
  <div class="main">
    <div v-if="!checkingLink">
      <div v-if="!selectedVisualizationPath && !loading">
        <ScrollPanel class="custom-scroller">
          <div class="grid flex justify-content-evenly">
            <Card v-for="vis in allLaunchableVisualizations" :key="vis.id">
              <template #title>
                {{ vis.visualizationName }}
              </template>
              <template #content>
                {{ vis.description }}
              </template>
              <template #footer>
                <Button class="card-btn" label="Open" @click="openPlot(vis)" />
              </template>
            </Card>
          </div>
        </ScrollPanel>
      </div>
      <div v-if="loading" class="flex justify-content-center">
        <!-- <ProgressSpinner style="width:30%;height:30%"></ProgressSpinner> -->
        <img alt="BJSpinner" class="bjSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png" />
      </div>
    </div>
    <div v-if="checkingLink">
      <div class="flex justify-content-center spinner-wrapper">
        <div class="vis-bg" />
        <!-- <ProgressSpinner style="width:30%;height:30%"/> -->
        <img alt="BJSpinner" class="bjSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png" />
      </div>
    </div>
    <div v-if="selectedVisualizationPath" class="vis-wrap">
      <div class="flex justify-content-end">
        <!-- <slot name="toggleTopUI" />
        <Button class="mt-2 mb-2 p-button-raised back-btn" label="Back" icon="pi pi-arrow-left" @click="back()" /> -->
      </div>
      <ScrollPanel id="scroll-panel" class="custom-scroller">
        <div class="vis-bg">
          <div class="my-vue-app">
            <!-- <iframe v-if="loadRNASEQ" class="rnaseq-iframe" :src=selectedVisualizationPath></iframe> -->
            <div class="my-react-app" :ref="`app-${pipeline.id}`" />
          </div>
        </div>
      </ScrollPanel>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { API, Auth, graphqlOperation } from 'aws-amplify';
import * as AWS from 'aws-sdk';
import axios from 'axios';
import * as queries from '@/graphql/queries';
import * as customQueries from '@/graphql/custom_queries';
import constants from '@/assets/constants.json';
import { renderDash } from '../../../../dash_embedded_component/umd/dash-embedded-component.min';
import { setPrecedence, valueIsNullOrUndefined, validateAllVisualizations } from '@/utils';

export default {
  name: 'Visualization',
  props: ['pipeline', 'passedVisualization'],
  // components: {
  //   DashApp,
  // },
  data() {
    return {
      hideBtnMsg: 'Hide top UI',
      allLaunchableVisualizations: [],
      selectedVisualizationPath: null,
      checkingLink: false,
      key: null,
      sharedData: {},
      readyToSend: false,
      vis: null,
      loadRNASEQ: false,
      s3Contents: [],
      gettingFilesDone: null,
      loading: true,
    };
  },
  async mounted() {
    console.log('this.pipeline in mounted:>> ', this.pipeline);
    this.key = `${this.pipeline.project.workspace.organization.id}/${this.pipeline.project.workspace.id}/${this.pipeline.project.id}/pipeline/${this.pipeline.pipelineOutputS3Path}`;
    this.gettingFilesDone = this.getFilePathsToSendToPlot();
    this.createMessageListenerForWhenIframeIsLoaded();
    if (!valueIsNullOrUndefined(this.passedVisualization)) {
      await this.gettingFilesDone;
      this.openPlot(this.passedVisualization);
      this.loading = false;
    } else await this.loadAllVisualizationsNew(this.pipeline);
  },
  methods: {
    async openPlot(vis) {
      this.selectedVisualizationPath = vis.deploymentPath;
      this.vis = vis;
      this.makeSharedData(this.vis, this.s3Contents);
    },
    // Validate all visualization. Top level
    async loadAllVisualizationsNew(pipeline) {
      try {
        this.loading = true;
        const visualizations = (await API.graphql(graphqlOperation(customQueries.listVisualizations, { limit: 300 }))).data.listVisualizations.items;
        console.log('visualizations :>> ', visualizations);
        this.allLaunchableVisualizations = await validateAllVisualizations(visualizations, pipeline, this.gettingFilesDone, this.s3Contents);
        this.loading = false;
      } catch (error) {
        console.error('Error in loading all visualizations');
        console.error(error);
        this.loading = false;
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    back() {
      this.selectedVisualizationPath = null;
      this.sharedData = {};
      this.readyToSend = false;
    },
    async getFilePathsToSendToPlot(token = null) {
      return new Promise((resolve, reject) => {
        const s3 = new AWS.S3();
        let params = {};
        if (token == null) {
          params = {
            Bucket: process.env.VUE_APP_BUCKET,
            Prefix: this.key,
          };
        } else {
          params = {
            Bucket: process.env.VUE_APP_BUCKET,
            Prefix: this.key,
            ContinuationToken: token,
          };
        }
        try {
          s3.listObjectsV2(params, (err, data) => {
            if (data) {
              this.s3Contents.push(...data.Contents);
              if (!data.IsTruncated) {
              // this.makeSharedData(this.vis, this.s3Contents);
                resolve();
              } else {
                this.getFilePathsToSendToPlot(data.NextContinuationToken).then(resolve);
              }
            }
            if (err) {
            // this.loading = false;
              console.error(err);
            }
          });
        } catch (error) {
          console.log('Error with getting files for plot');
          console.log(error);
        }
      });
    },
    makeSharedData(vis, data) {
      if (vis.allowedExtensions !== null && vis.allowedExtensions.length > 0) {
        let regex = '';
        for (let i = 0; i < vis.allowedExtensions.length; i += 1) {
          if (i === vis.allowedExtensions.length - 1) {
            regex = regex.concat(`.*\\${vis.allowedExtensions[i]}$`);
          } else {
            regex = regex.concat(`.*\\${vis.allowedExtensions[i]}$|`);
          }
        }
        vis.allowedExtensions.forEach((extention) => {
          this.sharedData[extention.substring(1)] = [];
        });
        const realRegex = new RegExp(regex);
        data.forEach((file) => {
          if (realRegex.test(file.Key)) {
            if (file.Key.split('.').at(-1) === 'gz') {
              this.sharedData[`${file.Key.split('.').at(-2)}.gz`].push(file);
            } else {
              this.sharedData[file.Key.split('.').at(-1)].push(file);
            }
          }
        });
      }

      console.log('this.sharedData :>> ', this.sharedData);
      this.readyToSend = true;
    },
    async getPresignedLink(key) {
      const s3 = new AWS.S3();
      const params = { Bucket: process.env.VUE_APP_BUCKET, Key: key };
      const url = s3.getSignedUrl('getObject', params);
      return url;
    },
    async makePresignedLinks(allPaths) {
      const promises = [];
      allPaths.forEach((path) => { promises.push(this.getPresignedLink(path.Key)); });
      const preisgnedLinks = await Promise.all(promises);
      return preisgnedLinks;
    },
    base64url(source) {
      // eslint-disable-next-line global-require
      const CryptoJS = require('crypto-js');
      let encodedSource = '';

      // Encode in classical base64
      encodedSource = CryptoJS.enc.Base64.stringify(source);

      // Remove padding equal characters
      encodedSource = encodedSource.replace(/=+$/, '');

      // Replace characters according to base64url specifications
      encodedSource = encodedSource.replace(/\+/g, '-');
      encodedSource = encodedSource.replace(/\//g, '_');

      return encodedSource;
    },
    createMessageListenerForWhenIframeIsLoaded() {
      window.addEventListener('message', this.receiveMessage, false);
    },
    receiveMessage(event) {
      // Check the origin of the message
      // if (event.origin !== 'http://localhost:8050') return;

      if (event.data.type && (event.data.type === 'FROM_DASH')) {
        this.sendCSSToViz();
      }
    },
    sendCSSToViz() {
      const visIf = document.getElementsByClassName('vis-if')[0];
      const cssLinks = document.querySelectorAll('link[rel="stylesheet"]');
      const styles = document.querySelectorAll('style');
      const dataToSend = {
        links: Array.from(cssLinks).map((linkObj) => linkObj.href),
        styles: Array.from(styles).map((s) => s.innerHTML),
        theme: localStorage.getItem('theme'),
      };
      visIf.contentWindow.postMessage(dataToSend, '*');
    },
  },
  watch: {
    async readyToSend() {
      if (this.readyToSend) {
        console.log('Ready to send');
        console.log('this.selectedVisualizationPath :>> ', this.selectedVisualizationPath);
        const generatePlotlyJWTResponse = await API.graphql(graphqlOperation(queries.generatePlotlyJWT));
        console.log('generatePlotlyJWTResponse :>> ', generatePlotlyJWTResponse);
        let token = generatePlotlyJWTResponse.data.generatePlotlyJWT;
        token = token.split('=')[1];
        token = token.slice(0, -1);
        console.log('token :>> ', token);
        if (this.vis.visualizationName === 'IGV') {
          const presignedBams = await this.makePresignedLinks(this.sharedData.bam);
          const presignedBais = await this.makePresignedLinks(this.sharedData.bai);
          this.sharedData.bam = presignedBams;
          this.sharedData.bai = presignedBais;
        }
        this.sharedData.bucket = process.env.VUE_APP_BUCKET;
        console.log('this.sharedData :>> ', this.sharedData);
        console.log('vis :>> ', this.vis);
        // if (this.vis.visualizationName === 'RNASEQ') {
        //   console.log('Loaded RNASEQ');
        //   // this.loadRNASEQ = true;
        // } else {
        console.log('Loaded Dash App');
        renderDash(
          {
            url_base_pathname: this.selectedVisualizationPath,
            // auth_token: currentSession.getAccessToken().getJwtToken(),
            // auth_token: this.generateToken(),
            auth_token: token,
          // request_refresh_jwt: currentSession.refreshToken.token,
          },
          this.$refs[`app-${this.pipeline.id}`],
          this.sharedData,
        );
        // }
        console.log('Afeter render');
      }
    },
  },
};
</script>

<style lang="scss">
.main {
  width: 100%;
  height: 100%;
}

.p-card{
  position: relative;
  width: 27rem;
  margin-bottom: 2em;
  margin-right: 2em;
  top: 10px;
  border-radius: 15px !important;
  box-sizing: border-box;
}

.card-btn {
  width: 100%;
}
.vc-image {
  border-radius: 15px !important;
}

.grid {
  position: relative;
  display: grid;
  left: 8px;
  // gap: 1px;
  // grid-template-columns: auto auto auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center
}

.custom-scroller {
  width: 100%;
  // height: 69vh;
  // height: calc(100vh - 9rem);
  height: 100%;
}

.custom-scroller .p-scrollpanel-wrapper {
    border-right: 9px solid #f4f4f4;
}

.custom-scroller .p-scrollpanel-bar {
    background-color: #1976d2;
    opacity: 1;
    transition: background-color .3s;
}

.custom-scroller .p-scrollpanel-bar:hover {
    background-color: #3e83c9;
    opacity: 1;
}

// .my-vis {
//   position: fixed;
//   width: 100%;
//   height: 60vh;
//   overflow: hidden;
// }

.vis-wrap {
  overflow: hidden;
  position: relative;
  // max-height: 700px;
  // max-height: 72vh;
  height: calc(100% - 8px);
}

.vis-bg {
  width: 100%;
  // height: calc(90vh - 9rem);
  // height: calc(90vh - 20px);
  height: 100%;
  position: relative;
  // background: linear-gradient(135deg, rgba(255,255,255,0.6), rgba(255,255,255,0.6));
  // -webkit-backdrop-filter: blur(10px);
  // backdrop-filter: blur(10px);
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  -webkit-box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.27);
          box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.27);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 15px 15px 15px 15px !important;
  display: table;
}

.my-vue-app {
  width: 100%;
  position: relative;
  display: table-row;
}

.spinner-wrapper {
  overflow: hidden;
  position: relative;
  // height: 70vh;
  width: 100%;
  overflow: hidden;
}

.p-progress-spinner {
  top: 30%;
}

// .igv-track-container-div {
//   z-index: 9;
//   max-height: 100px;
// }

// .back-btn {
//   // top: -30px;
//   z-index: 1000;
//   position: absolute;
// }

.rnaseq-iframe {
  width: 100%;
  // height: calc(100vh - 9rem);
  z-index: 10;
}

.custom-scroller .p-scrollpanel-wrapper {
  border: none;
  height: 100%;
}

.main {
    // height: calc(100vh - 9rem);
    // width: calc(100vw - 23rem);
    // height: calc(100vh - 5rem);
    width: 100%;
}

.bjSpinner {
  height: 30%;
  width: 30%;
}
</style>
