<template>
  <div class="admin-layout">
    <div>
      <TabMenu
        :model="tabMenuItems"
        :activeIndex="activeIndex" />
      <router-view />
    </div>
  </div>
</template>

<script>
import { wait } from '@/utils';

export default {
  name: 'AdminLayout',
  setup() {},
  data() {
    return {
      activeIndex: 0,
      tabMenuItems: [
        {
          label: 'Organizations',
          // to: 'organizations',
          url: '/#/admin/organizations',
        },
        {
          label: 'Users',
          // to: '/users',
          url: '/#/admin/users',
        },
      ],
    };
  },
  async mounted() {
    // Wait 1ms for the precedence level to be set
    await wait(1);
    // Only BioSkryb admins can have access to these functions
    if (this.$store.state.precedenceLevel === 1) {
      this.tabMenuItems.push({
        label: 'Admin Functions',
        url: '/#/admin/adminView',
      });
    }
    // Without this switch, the tab menu would always point to organization after page refresh
    switch (this.$router.currentRoute.value.path.split('/').at(-1)) {
      case 'organizations':
        this.activeIndex = 0;
        break;
      case 'users':
        this.activeIndex = 1;
        break;
      case 'usersOrganized':
        this.activeIndex = 2;
        break;
      case 'adminView':
        this.activeIndex = 3;
        break;
      default:
        console.log('Something is wrong with the route!');
    }
  },
};
</script>
