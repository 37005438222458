<template>
  <div class="container">
    <div class="grid">
      <div class="col-11 sm:col-2 md:col-8 lg:col-11 pt-5">
        <span class="p-float-label">
          <Dropdown :id="name + 'Id'" v-model="valueData" :options="options" placeholder="Select" @change="$emit('input', valueData)" />
          <label :for="name + 'Id'">{{ name }}</label>
        </span>
      </div>
      <div class="col-1 mt-3">
        <div class="tooltipMark" v-tooltip.right="description">?</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropDown',
  props: ['description', 'value', 'options', 'default', 'name'],
  emits: ['input'],
  data() {
    return {
      valueData: this.value,
    };
  },
  beforeUpdate() {
    this.valueData = this.value;
  },
};
</script>
<style lang="scss" scoped>
.p-dropdown {
  width: 100%;
}
</style>
