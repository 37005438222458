<template>
  <Dropdown v-model="selectedPipeline" :options="pipelines" optionLabel="label" placeholder="Select a pipeline to check" />
  <div class="check-window">
    <div class="pt-3" v-if="selectedPipeline !== null">
      <TabMenu class="pt-5 my-tab-menu" :model="tabMenuItems" v-model:activeIndex="activeIndex" />
      <div v-if="activeIndex === 0 && !$store.state.paymentSystemDisabled">
        <div v-if="loadingCreditsData">
          <img alt="BJSpinner" class="bjSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png" />
        </div>
        <div v-if="!loadingCreditsData" class="flex flex-column creditsBg pl-1 pb-2">
          <div>
            <span class="pt-3">Pipeline name: <b>{{ (creditsData[selectedPipeline.id].pipelineName) ? creditsData[selectedPipeline.id].pipelineName : 0 }}</b></span>
            <span class="pt-2">Num. of biosamples: <b>{{ (creditsData[selectedPipeline.id].numberOfBiosamples) ? creditsData[selectedPipeline.id].numberOfBiosamples : 0 }}</b></span>
            <span class="pt-2">Credits per biosample: <b>{{ (creditsData[selectedPipeline.id].tokensPerBiosample) ? creditsData[selectedPipeline.id].tokensPerBiosample : 0 }}</b></span>
          </div>
        </div>
        <div v-if="!loadingCreditsData" class="flex flex-column creditsBgBottom mt-3 pl-1 pb-2 pt-2">
          <div>
            <span class="pt-2">Current credit balance: <b>{{ getCurrentCreditBalance() }}</b></span>
            <span class="pt-2">Balance after payment: <b>{{ (currentCreditBalance - totalToPay) ? currentCreditBalance - totalToPay : '' }}</b></span>
            <span class="pt-2">Total amount to pay: <b>{{ (totalToPay) ? totalToPay : '' }}</b> credits</span>
            <span v-if="canLaunch === false" class="pt-2 noCreditsMsg">You do not have enough credits! Would you like to add more credits and continue with the pipeline?</span>
          </div>
        </div>
      </div>
      <div v-if="(!$store.state.paymentSystemDisabled && activeIndex === 1) || ($store.state.paymentSystemDisabled && activeIndex === 0)">
        <DataTable :value="listBiosamplesForTable()">
          <Column field="biosampleName" :bodyStyle="getBodyStyle()" header='Biosample Name' />
        </DataTable>
      </div>
      <div v-if="(!$store.state.paymentSystemDisabled && activeIndex === 2) || ($store.state.paymentSystemDisabled && activeIndex === 1)">
        <DataTable :value="formatedData()">
          <Column field="header" :bodyStyle="getBodyStyle()" header='Parameter Name' />
          <Column field="field" :bodyStyle="getBodyStyle()" header='Parameter Value' />
        </DataTable>
      </div>
    </div>
    <div class="flex justify-content-between pt-3">
      <Button class="mt-2 mb-2 p-button-raised p-button-secondary" label="Back" icon="pi pi-arrow-left" @click="back()" />
      <Button v-if="canLaunch === true" class="mt-2 mb-2 p-button-raised" label="Complete" icon="pi pi-check" @click="complete()" />
      <Button v-if="canLaunch === false" label="Add Credits" class="mt-1 mb-1 p-button-raised" @click="addCredits" :disabled="loadingCreditsData && !$store.state.paymentSystemDisabled" />
    </div>
  </div>
  <TransactionDialog v-if="transactionDialogOrgId !== null && transactionDialogOrgId !== undefined && !$store.state.paymentSystemDisabled" :organizationId="transactionDialogOrgId" :workspace="transactionDialogWorkspace" @transactionClosed="createCreditsDataForAllLaunchablePipelines()" />
</template>
<script>
import {
  createCreditsData, creditCheck,
} from '@/utils';
import TransactionDialog from '@/components/Organization/TransactionDialog.vue';

export default {
  props: ['formData'],
  emits: ['prevPage', 'nextPage'],
  components: {
    TransactionDialog,
  },
  data() {
    return {
      pipelines: [],
      selectedPipeline: null,
      loadingCreditsData: false,
      creditsData: {},
      canLaunch: false,
      transactionDialogOrgId: null,
      transactionDialogWorkspace: null,
      total: null,
      diff: null,
      currentCreditBalance: null,
      activeIndex: 0,
      tabMenuItems: [
        // {
        //   label: 'Credits',
        // },
        {
          label: 'Biosamples',
        },
        {
          label: 'Parameters',
        },
      ],
    };
  },
  async mounted() {
    if (!this.$store.state.paymentSystemDisabled) {
      this.tabMenuItems.unshift({
        label: 'Credits',
      });
    }
    this.formData.selectedTertiaryPipelines.forEach((pipeline) => {
      const smallerPipelineObj = {
        id: pipeline.id,
        label: `${pipeline.pipelineName}-${pipeline.pipelineVersion}`,
        pipelineName: pipeline.pipelineName,
        tokensPerBiosample: pipeline.tokensPerBiosample,
      };
      this.pipelines.push(smallerPipelineObj);
    });
    if (this.pipelines.length > 0) {
      this.selectedPipeline = this.pipelines[0];
    }
    await this.createCreditsDataForAllLaunchablePipelines();
  },
  methods: {
    back() {
      this.$emit('prevPage', { pageIndex: 5 });
    },
    complete() {
      this.$emit('complete', this.totalToPay, this.currentCreditBalance);
    },
    listBiosamplesForTable() {
      try {
        return this.formData.selectedBiosamples[this.selectedPipeline.id];
      } catch (error) {
        console.error('Error in listBiosamplesForTable');
        console.error(error);
        return [];
      }
    },
    formatedData() {
      try {
        return Object.keys(this.formData.pipelineParameters[this.selectedPipeline.id]).map((key) => ({ header: key, field: this.formData.pipelineParameters[this.selectedPipeline.id][key] }));
      } catch (error) {
        console.error('Error in formatedData');
        console.error(error);
        return [];
      }
    },
    getBodyStyle() {
      return 'text-align: center';
    },
    async createCreditsDataForAllLaunchablePipelines() {
      this.loadingCreditsData = true;
      const project = await this.getProjectFromBiosamples(this.formData.biosamples);
      const creditsPromises = [];
      this.formData.selectedTertiaryPipelines.forEach((launchablePipeline) => {
        creditsPromises.push(this.createIndividualCreditsData(launchablePipeline, project.id));
      });
      await Promise.all(creditsPromises);
      let diffSum = 0;
      let totalToPay = 0;
      let canLaunchLocal = true;
      for (const creditsData of Object.values(this.creditsData)) {
        const { canLaunch, diff, total } = await creditCheck(project.workspaceId, creditsData.amount);
        console.log('diff :>> ', diff);
        this.currentCreditBalance = total;
        if (canLaunch === false) {
          canLaunchLocal = false;
        }
        diffSum += diff;
        totalToPay += creditsData.amount;
      }
      if (this.$store.state.paymentSystemDisabled) {
        this.canLaunch = true;
        this.diff = 0;
        this.totalToPay = 0;
      } else {
        this.canLaunch = canLaunchLocal;
        this.diff = diffSum;
        this.totalToPay = totalToPay;
      }
      this.loadingCreditsData = false;
    },
    async createIndividualCreditsData(launchablePipeline, projectId) {
      try {
        const creditsData = await createCreditsData(launchablePipeline, this.formData.selectedBiosamples[launchablePipeline.id], projectId);
        this.creditsData[launchablePipeline.id] = creditsData;
        return;
      } catch (error) {
        console.error(error);
      }
    },
    getCurrentCreditBalance() {
      try {
        return this.currentCreditBalance;
      } catch (error) {
        console.error(error);
        return '';
      }
    },
    async getProjectFromBiosamples(biosamplesPromise) {
      try {
        const biosamples = await biosamplesPromise;
        if (biosamples === null || biosamples === undefined) return null;
        if (biosamples.length > 0) {
          return biosamples[0].project;
        }
      } catch (error) {
        console.error(error);
        return null;
      }
      return null;
    },
    async addCredits() {
      try {
        const biosamples = await this.formData.biosamples;
        if (biosamples.length > 0) {
          const orgId = biosamples[0].project.workspace.organizationId;
          this.transactionDialogOrgId = orgId;
          this.transactionDialogWorkspace = biosamples[0].project.workspace;
          this.$store.dispatch('showNewTransaction');
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  watch: {
  },
};
</script>
<style lang="scss" scoped>
::v-deep(.p-panel-content) {
  height: 55vh;
}

.p-dropdown {
  width: 25%;
}

.p-datatable {
  border-radius: 15px 15px 15px 15px !important;
}
::v-deep(.p-datatable) {
  border-radius: 15px 15px 15px 15px !important;
}

.dark {
  .creditsBg, .creditsBgBottom {
    background: #1F2A37;
  }
}

.light {
  .creditsBg, .creditsBgBottom {
    background: white;
  }
}
.creditsBg {
  height: 100%;
  width: 100%;
  border-radius: 0px 0px 15px 15px;
}

.creditsBgBottom {
  height: 100%;
  width: 100%;
  border-radius: 15px 15px 15px 15px;
}

span {
  width: 100%;
  display: inline-block;
}

b {
  display: inline-block;
}

.noCreditsMsg {
  color: red;
}

.finalBtnDiv {
  display:inline-block;
}

.p-steps-item .p-highlight .p-steps-current .p-menuitem-link .router-link-active .router-link-active-exact .p-steps-number {
  background: #A0CC2C !important;
}
.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: #A0CC2C !important;
}
::v-deep(.p-steps .p-steps-item.p-highlight .p-steps-number) {
  background: #A0CC2C !important;
}

.p-steps-number {
  background: red;
}
::v-deep(.p-steps-number) {
  background: red;
}
</style>
<style lang="scss">
.dark li.p-steps-item.p-highlight.p-steps-current > a > span.p-steps-number {
  background: #A0CC2C !important;
}
</style>
