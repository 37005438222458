<template>
  <div>
    <div class="header">
      <!-- <Breadcrumb :home="breadcrumbHome" class="breadcrumb" :model="breadcrumbItems" /> -->
    </div>
    <div>
      <h5 class="organizationName" v-if="organization">{{ organization.organizationName }}</h5>
      <TabMenu :model="tabMenuItems" />
      <router-view />
    </div>
  </div>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '@/graphql/queries';

export default {
  name: 'Organization',
  data() {
    return {
      organization: null,
      // breadcrumbHome: { icon: 'pi pi-home', to: '/' },
      // breadcrumbItems: [
      //   { label: 'Organizations', to: '/admin/organizations' },
      // ],
      tabMenuItems: [
        {
          label: 'Workspaces',
          url: `#/admin/organization/${this.$route.params.id}/workspaces`,
        },
        {
          label: 'Organization Users',
          url: `#/admin/organization/${this.$route.params.id}/users`,
        },
        // {
        //   label: 'Credit Transactions',
        //   to: 'transactions',
        // },
      ],
    };
  },
  async mounted() {
    console.log('this.$route.params.id :>> ', this.$route.params.id);
    if (!this.$store.state.paymentSystemDisabled) {
      this.tabMenuItems.push({
        label: 'Credit Transactions',
        url: `#/admin/organization/${this.$route.params.id}/transactions`,
      });
    }
    const response = await API.graphql(graphqlOperation(queries.getOrganization, {
      id: this.$route.params.id,
    }));
    this.organization = response.data.getOrganization;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/sass/_dataTable.scss";

.header {
  margin-bottom: 1.5rem;
}

.organizationName {
  color: white;
}

</style>
