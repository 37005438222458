/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateOrganization = /* GraphQL */ `
  subscription OnCreateOrganization(
    $filter: ModelSubscriptionOrganizationFilterInput
  ) {
    onCreateOrganization(filter: $filter) {
      id
      bioskrybClientId
      organizationName
      active
      storageCreditRate
      pipelineCreditRate
      zohoId
      salesforceId
      salesforceUrl
      salesEmail
      fasEmail
      poNumber
      creditExpiry
      organizationDataKay
      workspaces {
        items {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        nextToken
      }
      creditTransactions {
        items {
          id
          debitor
          debitorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          creditor
          creditorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          user
          userName
          amount
          creditPackName
          timesPackPurchased
          type
          notes
          processed
          readGroups
          writeGroups
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          pipelineId
          pipeline {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          isSpent
          expirationInMonths
          notificationsMap
          billingStart
          created
          updated
          organizationCreditTransactionsId
          workspaceCreditTransactionsId
          projectCreditTransactionsId
        }
        nextToken
      }
      readGroups
      sharedS3Paths
      writeGroups
      created
      updated
    }
  }
`;
export const onUpdateOrganization = /* GraphQL */ `
  subscription OnUpdateOrganization(
    $filter: ModelSubscriptionOrganizationFilterInput
  ) {
    onUpdateOrganization(filter: $filter) {
      id
      bioskrybClientId
      organizationName
      active
      storageCreditRate
      pipelineCreditRate
      zohoId
      salesforceId
      salesforceUrl
      salesEmail
      fasEmail
      poNumber
      creditExpiry
      organizationDataKay
      workspaces {
        items {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        nextToken
      }
      creditTransactions {
        items {
          id
          debitor
          debitorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          creditor
          creditorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          user
          userName
          amount
          creditPackName
          timesPackPurchased
          type
          notes
          processed
          readGroups
          writeGroups
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          pipelineId
          pipeline {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          isSpent
          expirationInMonths
          notificationsMap
          billingStart
          created
          updated
          organizationCreditTransactionsId
          workspaceCreditTransactionsId
          projectCreditTransactionsId
        }
        nextToken
      }
      readGroups
      sharedS3Paths
      writeGroups
      created
      updated
    }
  }
`;
export const onDeleteOrganization = /* GraphQL */ `
  subscription OnDeleteOrganization(
    $filter: ModelSubscriptionOrganizationFilterInput
  ) {
    onDeleteOrganization(filter: $filter) {
      id
      bioskrybClientId
      organizationName
      active
      storageCreditRate
      pipelineCreditRate
      zohoId
      salesforceId
      salesforceUrl
      salesEmail
      fasEmail
      poNumber
      creditExpiry
      organizationDataKay
      workspaces {
        items {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        nextToken
      }
      creditTransactions {
        items {
          id
          debitor
          debitorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          creditor
          creditorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          user
          userName
          amount
          creditPackName
          timesPackPurchased
          type
          notes
          processed
          readGroups
          writeGroups
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          pipelineId
          pipeline {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          isSpent
          expirationInMonths
          notificationsMap
          billingStart
          created
          updated
          organizationCreditTransactionsId
          workspaceCreditTransactionsId
          projectCreditTransactionsId
        }
        nextToken
      }
      readGroups
      sharedS3Paths
      writeGroups
      created
      updated
    }
  }
`;
export const onCreateWorkspace = /* GraphQL */ `
  subscription OnCreateWorkspace(
    $filter: ModelSubscriptionWorkspaceFilterInput
  ) {
    onCreateWorkspace(filter: $filter) {
      id
      organizationId
      organization {
        id
        bioskrybClientId
        organizationName
        active
        storageCreditRate
        pipelineCreditRate
        zohoId
        salesforceId
        salesforceUrl
        salesEmail
        fasEmail
        poNumber
        creditExpiry
        organizationDataKay
        workspaces {
          items {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        readGroups
        sharedS3Paths
        writeGroups
        created
        updated
      }
      description
      basespaceAccessToken
      projects {
        items {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        nextToken
      }
      lastCached
      creditTransactions {
        items {
          id
          debitor
          debitorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          creditor
          creditorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          user
          userName
          amount
          creditPackName
          timesPackPurchased
          type
          notes
          processed
          readGroups
          writeGroups
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          pipelineId
          pipeline {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          isSpent
          expirationInMonths
          notificationsMap
          billingStart
          created
          updated
          organizationCreditTransactionsId
          workspaceCreditTransactionsId
          projectCreditTransactionsId
        }
        nextToken
      }
      sharedS3Paths
      readGroups
      size
      lastSizeScan
      writeGroups
      adminGroups
      created
      updated
      organizationWorkspacesId
    }
  }
`;
export const onUpdateWorkspace = /* GraphQL */ `
  subscription OnUpdateWorkspace(
    $filter: ModelSubscriptionWorkspaceFilterInput
  ) {
    onUpdateWorkspace(filter: $filter) {
      id
      organizationId
      organization {
        id
        bioskrybClientId
        organizationName
        active
        storageCreditRate
        pipelineCreditRate
        zohoId
        salesforceId
        salesforceUrl
        salesEmail
        fasEmail
        poNumber
        creditExpiry
        organizationDataKay
        workspaces {
          items {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        readGroups
        sharedS3Paths
        writeGroups
        created
        updated
      }
      description
      basespaceAccessToken
      projects {
        items {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        nextToken
      }
      lastCached
      creditTransactions {
        items {
          id
          debitor
          debitorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          creditor
          creditorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          user
          userName
          amount
          creditPackName
          timesPackPurchased
          type
          notes
          processed
          readGroups
          writeGroups
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          pipelineId
          pipeline {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          isSpent
          expirationInMonths
          notificationsMap
          billingStart
          created
          updated
          organizationCreditTransactionsId
          workspaceCreditTransactionsId
          projectCreditTransactionsId
        }
        nextToken
      }
      sharedS3Paths
      readGroups
      size
      lastSizeScan
      writeGroups
      adminGroups
      created
      updated
      organizationWorkspacesId
    }
  }
`;
export const onDeleteWorkspace = /* GraphQL */ `
  subscription OnDeleteWorkspace(
    $filter: ModelSubscriptionWorkspaceFilterInput
  ) {
    onDeleteWorkspace(filter: $filter) {
      id
      organizationId
      organization {
        id
        bioskrybClientId
        organizationName
        active
        storageCreditRate
        pipelineCreditRate
        zohoId
        salesforceId
        salesforceUrl
        salesEmail
        fasEmail
        poNumber
        creditExpiry
        organizationDataKay
        workspaces {
          items {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        readGroups
        sharedS3Paths
        writeGroups
        created
        updated
      }
      description
      basespaceAccessToken
      projects {
        items {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        nextToken
      }
      lastCached
      creditTransactions {
        items {
          id
          debitor
          debitorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          creditor
          creditorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          user
          userName
          amount
          creditPackName
          timesPackPurchased
          type
          notes
          processed
          readGroups
          writeGroups
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          pipelineId
          pipeline {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          isSpent
          expirationInMonths
          notificationsMap
          billingStart
          created
          updated
          organizationCreditTransactionsId
          workspaceCreditTransactionsId
          projectCreditTransactionsId
        }
        nextToken
      }
      sharedS3Paths
      readGroups
      size
      lastSizeScan
      writeGroups
      adminGroups
      created
      updated
      organizationWorkspacesId
    }
  }
`;
export const onCreateProject = /* GraphQL */ `
  subscription OnCreateProject($filter: ModelSubscriptionProjectFilterInput) {
    onCreateProject(filter: $filter) {
      id
      workspaceId
      workspace {
        id
        organizationId
        organization {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        description
        basespaceAccessToken
        projects {
          items {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          nextToken
        }
        lastCached
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        sharedS3Paths
        readGroups
        size
        lastSizeScan
        writeGroups
        adminGroups
        created
        updated
        organizationWorkspacesId
      }
      associatedProjectId
      associatedProject {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        clientProjectName
        lotId
        analysisType
        projectType
        description
        projectOutputS3Path
        size
        assayName
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        biosampleMetadataColumns
        readGroups
        writeGroups
        adminGroups
        biosamples {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            biosampleName
            bsshId
            analysisFiles
            r1FastqName
            r2FastqName
            r1FastqMb5Sum
            r2FastqMb5Sum
            r1FastqLength
            r2FastqLength
            r1FastqTotalReads
            r2FastqTotalReads
            petaSuiteVersion
            petaSuiteSpecies
            petaSuiteReferenceDir
            fastqValidationStatus
            metadata
            size
            fastqs
            r1FastqS3Uri
            r2FastqS3Uri
            lotId
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectBiosamplesId
          }
          nextToken
        }
        pipelines {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        created
        updated
        workspaceProjectsId
      }
      clientProjectName
      lotId
      analysisType
      projectType
      description
      projectOutputS3Path
      size
      assayName
      status
      nfTowerEstimatedCost
      workflowId
      initiator
      biosampleMetadataColumns
      readGroups
      writeGroups
      adminGroups
      biosamples {
        items {
          id
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          biosampleName
          bsshId
          analysisFiles
          r1FastqName
          r2FastqName
          r1FastqMb5Sum
          r2FastqMb5Sum
          r1FastqLength
          r2FastqLength
          r1FastqTotalReads
          r2FastqTotalReads
          petaSuiteVersion
          petaSuiteSpecies
          petaSuiteReferenceDir
          fastqValidationStatus
          metadata
          size
          fastqs
          r1FastqS3Uri
          r2FastqS3Uri
          lotId
          created
          updated
          readGroups
          writeGroups
          adminGroups
          projectBiosamplesId
        }
        nextToken
      }
      pipelines {
        items {
          id
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          parents {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          children {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          passFailBiosamples
          pipelineName
          runDescription
          analysisLevel
          pipelineNFSessionId
          pipelineNFRunName
          referenceGenome
          estimatedCredits
          size
          pipelineVersion
          pipelineOutputS3Path
          pipelineOutputS3FullPath
          pipelineOutputs
          pipelineStarted
          pipelineCompleted
          modules
          parameters
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          errorMessage
          created
          updated
          readGroups
          writeGroups
          adminGroups
          projectPipelinesId
        }
        nextToken
      }
      creditTransactions {
        items {
          id
          debitor
          debitorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          creditor
          creditorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          user
          userName
          amount
          creditPackName
          timesPackPurchased
          type
          notes
          processed
          readGroups
          writeGroups
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          pipelineId
          pipeline {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          isSpent
          expirationInMonths
          notificationsMap
          billingStart
          created
          updated
          organizationCreditTransactionsId
          workspaceCreditTransactionsId
          projectCreditTransactionsId
        }
        nextToken
      }
      created
      updated
      workspaceProjectsId
    }
  }
`;
export const onUpdateProject = /* GraphQL */ `
  subscription OnUpdateProject($filter: ModelSubscriptionProjectFilterInput) {
    onUpdateProject(filter: $filter) {
      id
      workspaceId
      workspace {
        id
        organizationId
        organization {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        description
        basespaceAccessToken
        projects {
          items {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          nextToken
        }
        lastCached
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        sharedS3Paths
        readGroups
        size
        lastSizeScan
        writeGroups
        adminGroups
        created
        updated
        organizationWorkspacesId
      }
      associatedProjectId
      associatedProject {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        clientProjectName
        lotId
        analysisType
        projectType
        description
        projectOutputS3Path
        size
        assayName
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        biosampleMetadataColumns
        readGroups
        writeGroups
        adminGroups
        biosamples {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            biosampleName
            bsshId
            analysisFiles
            r1FastqName
            r2FastqName
            r1FastqMb5Sum
            r2FastqMb5Sum
            r1FastqLength
            r2FastqLength
            r1FastqTotalReads
            r2FastqTotalReads
            petaSuiteVersion
            petaSuiteSpecies
            petaSuiteReferenceDir
            fastqValidationStatus
            metadata
            size
            fastqs
            r1FastqS3Uri
            r2FastqS3Uri
            lotId
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectBiosamplesId
          }
          nextToken
        }
        pipelines {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        created
        updated
        workspaceProjectsId
      }
      clientProjectName
      lotId
      analysisType
      projectType
      description
      projectOutputS3Path
      size
      assayName
      status
      nfTowerEstimatedCost
      workflowId
      initiator
      biosampleMetadataColumns
      readGroups
      writeGroups
      adminGroups
      biosamples {
        items {
          id
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          biosampleName
          bsshId
          analysisFiles
          r1FastqName
          r2FastqName
          r1FastqMb5Sum
          r2FastqMb5Sum
          r1FastqLength
          r2FastqLength
          r1FastqTotalReads
          r2FastqTotalReads
          petaSuiteVersion
          petaSuiteSpecies
          petaSuiteReferenceDir
          fastqValidationStatus
          metadata
          size
          fastqs
          r1FastqS3Uri
          r2FastqS3Uri
          lotId
          created
          updated
          readGroups
          writeGroups
          adminGroups
          projectBiosamplesId
        }
        nextToken
      }
      pipelines {
        items {
          id
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          parents {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          children {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          passFailBiosamples
          pipelineName
          runDescription
          analysisLevel
          pipelineNFSessionId
          pipelineNFRunName
          referenceGenome
          estimatedCredits
          size
          pipelineVersion
          pipelineOutputS3Path
          pipelineOutputS3FullPath
          pipelineOutputs
          pipelineStarted
          pipelineCompleted
          modules
          parameters
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          errorMessage
          created
          updated
          readGroups
          writeGroups
          adminGroups
          projectPipelinesId
        }
        nextToken
      }
      creditTransactions {
        items {
          id
          debitor
          debitorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          creditor
          creditorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          user
          userName
          amount
          creditPackName
          timesPackPurchased
          type
          notes
          processed
          readGroups
          writeGroups
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          pipelineId
          pipeline {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          isSpent
          expirationInMonths
          notificationsMap
          billingStart
          created
          updated
          organizationCreditTransactionsId
          workspaceCreditTransactionsId
          projectCreditTransactionsId
        }
        nextToken
      }
      created
      updated
      workspaceProjectsId
    }
  }
`;
export const onDeleteProject = /* GraphQL */ `
  subscription OnDeleteProject($filter: ModelSubscriptionProjectFilterInput) {
    onDeleteProject(filter: $filter) {
      id
      workspaceId
      workspace {
        id
        organizationId
        organization {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        description
        basespaceAccessToken
        projects {
          items {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          nextToken
        }
        lastCached
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        sharedS3Paths
        readGroups
        size
        lastSizeScan
        writeGroups
        adminGroups
        created
        updated
        organizationWorkspacesId
      }
      associatedProjectId
      associatedProject {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        clientProjectName
        lotId
        analysisType
        projectType
        description
        projectOutputS3Path
        size
        assayName
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        biosampleMetadataColumns
        readGroups
        writeGroups
        adminGroups
        biosamples {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            biosampleName
            bsshId
            analysisFiles
            r1FastqName
            r2FastqName
            r1FastqMb5Sum
            r2FastqMb5Sum
            r1FastqLength
            r2FastqLength
            r1FastqTotalReads
            r2FastqTotalReads
            petaSuiteVersion
            petaSuiteSpecies
            petaSuiteReferenceDir
            fastqValidationStatus
            metadata
            size
            fastqs
            r1FastqS3Uri
            r2FastqS3Uri
            lotId
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectBiosamplesId
          }
          nextToken
        }
        pipelines {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        created
        updated
        workspaceProjectsId
      }
      clientProjectName
      lotId
      analysisType
      projectType
      description
      projectOutputS3Path
      size
      assayName
      status
      nfTowerEstimatedCost
      workflowId
      initiator
      biosampleMetadataColumns
      readGroups
      writeGroups
      adminGroups
      biosamples {
        items {
          id
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          biosampleName
          bsshId
          analysisFiles
          r1FastqName
          r2FastqName
          r1FastqMb5Sum
          r2FastqMb5Sum
          r1FastqLength
          r2FastqLength
          r1FastqTotalReads
          r2FastqTotalReads
          petaSuiteVersion
          petaSuiteSpecies
          petaSuiteReferenceDir
          fastqValidationStatus
          metadata
          size
          fastqs
          r1FastqS3Uri
          r2FastqS3Uri
          lotId
          created
          updated
          readGroups
          writeGroups
          adminGroups
          projectBiosamplesId
        }
        nextToken
      }
      pipelines {
        items {
          id
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          parents {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          children {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          passFailBiosamples
          pipelineName
          runDescription
          analysisLevel
          pipelineNFSessionId
          pipelineNFRunName
          referenceGenome
          estimatedCredits
          size
          pipelineVersion
          pipelineOutputS3Path
          pipelineOutputS3FullPath
          pipelineOutputs
          pipelineStarted
          pipelineCompleted
          modules
          parameters
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          errorMessage
          created
          updated
          readGroups
          writeGroups
          adminGroups
          projectPipelinesId
        }
        nextToken
      }
      creditTransactions {
        items {
          id
          debitor
          debitorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          creditor
          creditorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          user
          userName
          amount
          creditPackName
          timesPackPurchased
          type
          notes
          processed
          readGroups
          writeGroups
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          pipelineId
          pipeline {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          isSpent
          expirationInMonths
          notificationsMap
          billingStart
          created
          updated
          organizationCreditTransactionsId
          workspaceCreditTransactionsId
          projectCreditTransactionsId
        }
        nextToken
      }
      created
      updated
      workspaceProjectsId
    }
  }
`;
export const onCreateBiosample = /* GraphQL */ `
  subscription OnCreateBiosample(
    $filter: ModelSubscriptionBiosampleFilterInput
  ) {
    onCreateBiosample(filter: $filter) {
      id
      projectId
      project {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        clientProjectName
        lotId
        analysisType
        projectType
        description
        projectOutputS3Path
        size
        assayName
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        biosampleMetadataColumns
        readGroups
        writeGroups
        adminGroups
        biosamples {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            biosampleName
            bsshId
            analysisFiles
            r1FastqName
            r2FastqName
            r1FastqMb5Sum
            r2FastqMb5Sum
            r1FastqLength
            r2FastqLength
            r1FastqTotalReads
            r2FastqTotalReads
            petaSuiteVersion
            petaSuiteSpecies
            petaSuiteReferenceDir
            fastqValidationStatus
            metadata
            size
            fastqs
            r1FastqS3Uri
            r2FastqS3Uri
            lotId
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectBiosamplesId
          }
          nextToken
        }
        pipelines {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        created
        updated
        workspaceProjectsId
      }
      biosampleName
      bsshId
      analysisFiles
      r1FastqName
      r2FastqName
      r1FastqMb5Sum
      r2FastqMb5Sum
      r1FastqLength
      r2FastqLength
      r1FastqTotalReads
      r2FastqTotalReads
      petaSuiteVersion
      petaSuiteSpecies
      petaSuiteReferenceDir
      fastqValidationStatus
      metadata
      size
      fastqs
      r1FastqS3Uri
      r2FastqS3Uri
      lotId
      created
      updated
      readGroups
      writeGroups
      adminGroups
      projectBiosamplesId
    }
  }
`;
export const onUpdateBiosample = /* GraphQL */ `
  subscription OnUpdateBiosample(
    $filter: ModelSubscriptionBiosampleFilterInput
  ) {
    onUpdateBiosample(filter: $filter) {
      id
      projectId
      project {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        clientProjectName
        lotId
        analysisType
        projectType
        description
        projectOutputS3Path
        size
        assayName
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        biosampleMetadataColumns
        readGroups
        writeGroups
        adminGroups
        biosamples {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            biosampleName
            bsshId
            analysisFiles
            r1FastqName
            r2FastqName
            r1FastqMb5Sum
            r2FastqMb5Sum
            r1FastqLength
            r2FastqLength
            r1FastqTotalReads
            r2FastqTotalReads
            petaSuiteVersion
            petaSuiteSpecies
            petaSuiteReferenceDir
            fastqValidationStatus
            metadata
            size
            fastqs
            r1FastqS3Uri
            r2FastqS3Uri
            lotId
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectBiosamplesId
          }
          nextToken
        }
        pipelines {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        created
        updated
        workspaceProjectsId
      }
      biosampleName
      bsshId
      analysisFiles
      r1FastqName
      r2FastqName
      r1FastqMb5Sum
      r2FastqMb5Sum
      r1FastqLength
      r2FastqLength
      r1FastqTotalReads
      r2FastqTotalReads
      petaSuiteVersion
      petaSuiteSpecies
      petaSuiteReferenceDir
      fastqValidationStatus
      metadata
      size
      fastqs
      r1FastqS3Uri
      r2FastqS3Uri
      lotId
      created
      updated
      readGroups
      writeGroups
      adminGroups
      projectBiosamplesId
    }
  }
`;
export const onDeleteBiosample = /* GraphQL */ `
  subscription OnDeleteBiosample(
    $filter: ModelSubscriptionBiosampleFilterInput
  ) {
    onDeleteBiosample(filter: $filter) {
      id
      projectId
      project {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        clientProjectName
        lotId
        analysisType
        projectType
        description
        projectOutputS3Path
        size
        assayName
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        biosampleMetadataColumns
        readGroups
        writeGroups
        adminGroups
        biosamples {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            biosampleName
            bsshId
            analysisFiles
            r1FastqName
            r2FastqName
            r1FastqMb5Sum
            r2FastqMb5Sum
            r1FastqLength
            r2FastqLength
            r1FastqTotalReads
            r2FastqTotalReads
            petaSuiteVersion
            petaSuiteSpecies
            petaSuiteReferenceDir
            fastqValidationStatus
            metadata
            size
            fastqs
            r1FastqS3Uri
            r2FastqS3Uri
            lotId
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectBiosamplesId
          }
          nextToken
        }
        pipelines {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        created
        updated
        workspaceProjectsId
      }
      biosampleName
      bsshId
      analysisFiles
      r1FastqName
      r2FastqName
      r1FastqMb5Sum
      r2FastqMb5Sum
      r1FastqLength
      r2FastqLength
      r1FastqTotalReads
      r2FastqTotalReads
      petaSuiteVersion
      petaSuiteSpecies
      petaSuiteReferenceDir
      fastqValidationStatus
      metadata
      size
      fastqs
      r1FastqS3Uri
      r2FastqS3Uri
      lotId
      created
      updated
      readGroups
      writeGroups
      adminGroups
      projectBiosamplesId
    }
  }
`;
export const onCreatePipeline = /* GraphQL */ `
  subscription OnCreatePipeline($filter: ModelSubscriptionPipelineFilterInput) {
    onCreatePipeline(filter: $filter) {
      id
      projectId
      project {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        clientProjectName
        lotId
        analysisType
        projectType
        description
        projectOutputS3Path
        size
        assayName
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        biosampleMetadataColumns
        readGroups
        writeGroups
        adminGroups
        biosamples {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            biosampleName
            bsshId
            analysisFiles
            r1FastqName
            r2FastqName
            r1FastqMb5Sum
            r2FastqMb5Sum
            r1FastqLength
            r2FastqLength
            r1FastqTotalReads
            r2FastqTotalReads
            petaSuiteVersion
            petaSuiteSpecies
            petaSuiteReferenceDir
            fastqValidationStatus
            metadata
            size
            fastqs
            r1FastqS3Uri
            r2FastqS3Uri
            lotId
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectBiosamplesId
          }
          nextToken
        }
        pipelines {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        created
        updated
        workspaceProjectsId
      }
      parents {
        items {
          id
          parentId
          childId
          parent {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          child {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          createdAt
          updatedAt
          pipelineParentsId
          pipelineChildrenId
        }
        nextToken
      }
      children {
        items {
          id
          parentId
          childId
          parent {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          child {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          createdAt
          updatedAt
          pipelineParentsId
          pipelineChildrenId
        }
        nextToken
      }
      passFailBiosamples
      pipelineName
      runDescription
      analysisLevel
      pipelineNFSessionId
      pipelineNFRunName
      referenceGenome
      estimatedCredits
      size
      pipelineVersion
      pipelineOutputS3Path
      pipelineOutputS3FullPath
      pipelineOutputs
      pipelineStarted
      pipelineCompleted
      modules
      parameters
      status
      nfTowerEstimatedCost
      workflowId
      initiator
      errorMessage
      created
      updated
      readGroups
      writeGroups
      adminGroups
      projectPipelinesId
    }
  }
`;
export const onUpdatePipeline = /* GraphQL */ `
  subscription OnUpdatePipeline($filter: ModelSubscriptionPipelineFilterInput) {
    onUpdatePipeline(filter: $filter) {
      id
      projectId
      project {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        clientProjectName
        lotId
        analysisType
        projectType
        description
        projectOutputS3Path
        size
        assayName
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        biosampleMetadataColumns
        readGroups
        writeGroups
        adminGroups
        biosamples {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            biosampleName
            bsshId
            analysisFiles
            r1FastqName
            r2FastqName
            r1FastqMb5Sum
            r2FastqMb5Sum
            r1FastqLength
            r2FastqLength
            r1FastqTotalReads
            r2FastqTotalReads
            petaSuiteVersion
            petaSuiteSpecies
            petaSuiteReferenceDir
            fastqValidationStatus
            metadata
            size
            fastqs
            r1FastqS3Uri
            r2FastqS3Uri
            lotId
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectBiosamplesId
          }
          nextToken
        }
        pipelines {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        created
        updated
        workspaceProjectsId
      }
      parents {
        items {
          id
          parentId
          childId
          parent {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          child {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          createdAt
          updatedAt
          pipelineParentsId
          pipelineChildrenId
        }
        nextToken
      }
      children {
        items {
          id
          parentId
          childId
          parent {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          child {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          createdAt
          updatedAt
          pipelineParentsId
          pipelineChildrenId
        }
        nextToken
      }
      passFailBiosamples
      pipelineName
      runDescription
      analysisLevel
      pipelineNFSessionId
      pipelineNFRunName
      referenceGenome
      estimatedCredits
      size
      pipelineVersion
      pipelineOutputS3Path
      pipelineOutputS3FullPath
      pipelineOutputs
      pipelineStarted
      pipelineCompleted
      modules
      parameters
      status
      nfTowerEstimatedCost
      workflowId
      initiator
      errorMessage
      created
      updated
      readGroups
      writeGroups
      adminGroups
      projectPipelinesId
    }
  }
`;
export const onDeletePipeline = /* GraphQL */ `
  subscription OnDeletePipeline($filter: ModelSubscriptionPipelineFilterInput) {
    onDeletePipeline(filter: $filter) {
      id
      projectId
      project {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        clientProjectName
        lotId
        analysisType
        projectType
        description
        projectOutputS3Path
        size
        assayName
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        biosampleMetadataColumns
        readGroups
        writeGroups
        adminGroups
        biosamples {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            biosampleName
            bsshId
            analysisFiles
            r1FastqName
            r2FastqName
            r1FastqMb5Sum
            r2FastqMb5Sum
            r1FastqLength
            r2FastqLength
            r1FastqTotalReads
            r2FastqTotalReads
            petaSuiteVersion
            petaSuiteSpecies
            petaSuiteReferenceDir
            fastqValidationStatus
            metadata
            size
            fastqs
            r1FastqS3Uri
            r2FastqS3Uri
            lotId
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectBiosamplesId
          }
          nextToken
        }
        pipelines {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        created
        updated
        workspaceProjectsId
      }
      parents {
        items {
          id
          parentId
          childId
          parent {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          child {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          createdAt
          updatedAt
          pipelineParentsId
          pipelineChildrenId
        }
        nextToken
      }
      children {
        items {
          id
          parentId
          childId
          parent {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          child {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          createdAt
          updatedAt
          pipelineParentsId
          pipelineChildrenId
        }
        nextToken
      }
      passFailBiosamples
      pipelineName
      runDescription
      analysisLevel
      pipelineNFSessionId
      pipelineNFRunName
      referenceGenome
      estimatedCredits
      size
      pipelineVersion
      pipelineOutputS3Path
      pipelineOutputS3FullPath
      pipelineOutputs
      pipelineStarted
      pipelineCompleted
      modules
      parameters
      status
      nfTowerEstimatedCost
      workflowId
      initiator
      errorMessage
      created
      updated
      readGroups
      writeGroups
      adminGroups
      projectPipelinesId
    }
  }
`;
export const onCreateLaunchablePipelines = /* GraphQL */ `
  subscription OnCreateLaunchablePipelines(
    $filter: ModelSubscriptionLaunchablePipelinesFilterInput
  ) {
    onCreateLaunchablePipelines(filter: $filter) {
      id
      pipelineName
      pipelineVersion
      parameters
      requiredMetadataColumns
      description
      analysisLevel
      supportedTertiaryAnalyses
      supportedInputFileTypes
      gitRepo
      mainScript
      tokensPerBiosample
      created
      updated
    }
  }
`;
export const onUpdateLaunchablePipelines = /* GraphQL */ `
  subscription OnUpdateLaunchablePipelines(
    $filter: ModelSubscriptionLaunchablePipelinesFilterInput
  ) {
    onUpdateLaunchablePipelines(filter: $filter) {
      id
      pipelineName
      pipelineVersion
      parameters
      requiredMetadataColumns
      description
      analysisLevel
      supportedTertiaryAnalyses
      supportedInputFileTypes
      gitRepo
      mainScript
      tokensPerBiosample
      created
      updated
    }
  }
`;
export const onDeleteLaunchablePipelines = /* GraphQL */ `
  subscription OnDeleteLaunchablePipelines(
    $filter: ModelSubscriptionLaunchablePipelinesFilterInput
  ) {
    onDeleteLaunchablePipelines(filter: $filter) {
      id
      pipelineName
      pipelineVersion
      parameters
      requiredMetadataColumns
      description
      analysisLevel
      supportedTertiaryAnalyses
      supportedInputFileTypes
      gitRepo
      mainScript
      tokensPerBiosample
      created
      updated
    }
  }
`;
export const onCreatePrivateLaunchablePipelines = /* GraphQL */ `
  subscription OnCreatePrivateLaunchablePipelines(
    $filter: ModelSubscriptionPrivateLaunchablePipelinesFilterInput
  ) {
    onCreatePrivateLaunchablePipelines(filter: $filter) {
      id
      pipelineName
      pipelineVersion
      parameters
      requiredMetadataColumns
      description
      analysisLevel
      supportedTertiaryAnalyses
      supportedInputFileTypes
      gitRepo
      mainScript
      tokensPerBiosample
      readGroups
      created
      updated
    }
  }
`;
export const onUpdatePrivateLaunchablePipelines = /* GraphQL */ `
  subscription OnUpdatePrivateLaunchablePipelines(
    $filter: ModelSubscriptionPrivateLaunchablePipelinesFilterInput
  ) {
    onUpdatePrivateLaunchablePipelines(filter: $filter) {
      id
      pipelineName
      pipelineVersion
      parameters
      requiredMetadataColumns
      description
      analysisLevel
      supportedTertiaryAnalyses
      supportedInputFileTypes
      gitRepo
      mainScript
      tokensPerBiosample
      readGroups
      created
      updated
    }
  }
`;
export const onDeletePrivateLaunchablePipelines = /* GraphQL */ `
  subscription OnDeletePrivateLaunchablePipelines(
    $filter: ModelSubscriptionPrivateLaunchablePipelinesFilterInput
  ) {
    onDeletePrivateLaunchablePipelines(filter: $filter) {
      id
      pipelineName
      pipelineVersion
      parameters
      requiredMetadataColumns
      description
      analysisLevel
      supportedTertiaryAnalyses
      supportedInputFileTypes
      gitRepo
      mainScript
      tokensPerBiosample
      readGroups
      created
      updated
    }
  }
`;
export const onCreateVisualizations = /* GraphQL */ `
  subscription OnCreateVisualizations(
    $filter: ModelSubscriptionVisualizationsFilterInput
  ) {
    onCreateVisualizations(filter: $filter) {
      id
      visualizationName
      description
      version
      deploymentPath
      iconPath
      validationSchema
      allowedExtensions
      created
      updated
    }
  }
`;
export const onUpdateVisualizations = /* GraphQL */ `
  subscription OnUpdateVisualizations(
    $filter: ModelSubscriptionVisualizationsFilterInput
  ) {
    onUpdateVisualizations(filter: $filter) {
      id
      visualizationName
      description
      version
      deploymentPath
      iconPath
      validationSchema
      allowedExtensions
      created
      updated
    }
  }
`;
export const onDeleteVisualizations = /* GraphQL */ `
  subscription OnDeleteVisualizations(
    $filter: ModelSubscriptionVisualizationsFilterInput
  ) {
    onDeleteVisualizations(filter: $filter) {
      id
      visualizationName
      description
      version
      deploymentPath
      iconPath
      validationSchema
      allowedExtensions
      created
      updated
    }
  }
`;
export const onCreateWorkflow = /* GraphQL */ `
  subscription OnCreateWorkflow($filter: ModelSubscriptionWorkflowFilterInput) {
    onCreateWorkflow(filter: $filter) {
      id
      paramsFileS3Path
      nfWorkflowId
      status
      created
      updated
    }
  }
`;
export const onUpdateWorkflow = /* GraphQL */ `
  subscription OnUpdateWorkflow($filter: ModelSubscriptionWorkflowFilterInput) {
    onUpdateWorkflow(filter: $filter) {
      id
      paramsFileS3Path
      nfWorkflowId
      status
      created
      updated
    }
  }
`;
export const onDeleteWorkflow = /* GraphQL */ `
  subscription OnDeleteWorkflow($filter: ModelSubscriptionWorkflowFilterInput) {
    onDeleteWorkflow(filter: $filter) {
      id
      paramsFileS3Path
      nfWorkflowId
      status
      created
      updated
    }
  }
`;
export const onCreateCreditTransactionLog = /* GraphQL */ `
  subscription OnCreateCreditTransactionLog(
    $filter: ModelSubscriptionCreditTransactionLogFilterInput
  ) {
    onCreateCreditTransactionLog(filter: $filter) {
      id
      debitor
      debitorOrg {
        id
        bioskrybClientId
        organizationName
        active
        storageCreditRate
        pipelineCreditRate
        zohoId
        salesforceId
        salesforceUrl
        salesEmail
        fasEmail
        poNumber
        creditExpiry
        organizationDataKay
        workspaces {
          items {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        readGroups
        sharedS3Paths
        writeGroups
        created
        updated
      }
      creditor
      creditorOrg {
        id
        bioskrybClientId
        organizationName
        active
        storageCreditRate
        pipelineCreditRate
        zohoId
        salesforceId
        salesforceUrl
        salesEmail
        fasEmail
        poNumber
        creditExpiry
        organizationDataKay
        workspaces {
          items {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        readGroups
        sharedS3Paths
        writeGroups
        created
        updated
      }
      user
      userName
      amount
      creditPackName
      timesPackPurchased
      type
      notes
      processed
      readGroups
      writeGroups
      projectId
      project {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        clientProjectName
        lotId
        analysisType
        projectType
        description
        projectOutputS3Path
        size
        assayName
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        biosampleMetadataColumns
        readGroups
        writeGroups
        adminGroups
        biosamples {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            biosampleName
            bsshId
            analysisFiles
            r1FastqName
            r2FastqName
            r1FastqMb5Sum
            r2FastqMb5Sum
            r1FastqLength
            r2FastqLength
            r1FastqTotalReads
            r2FastqTotalReads
            petaSuiteVersion
            petaSuiteSpecies
            petaSuiteReferenceDir
            fastqValidationStatus
            metadata
            size
            fastqs
            r1FastqS3Uri
            r2FastqS3Uri
            lotId
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectBiosamplesId
          }
          nextToken
        }
        pipelines {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        created
        updated
        workspaceProjectsId
      }
      workspaceId
      workspace {
        id
        organizationId
        organization {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        description
        basespaceAccessToken
        projects {
          items {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          nextToken
        }
        lastCached
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        sharedS3Paths
        readGroups
        size
        lastSizeScan
        writeGroups
        adminGroups
        created
        updated
        organizationWorkspacesId
      }
      pipelineId
      pipeline {
        id
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        parents {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        children {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        passFailBiosamples
        pipelineName
        runDescription
        analysisLevel
        pipelineNFSessionId
        pipelineNFRunName
        referenceGenome
        estimatedCredits
        size
        pipelineVersion
        pipelineOutputS3Path
        pipelineOutputS3FullPath
        pipelineOutputs
        pipelineStarted
        pipelineCompleted
        modules
        parameters
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        errorMessage
        created
        updated
        readGroups
        writeGroups
        adminGroups
        projectPipelinesId
      }
      isSpent
      expirationInMonths
      notificationsMap
      billingStart
      created
      updated
      organizationCreditTransactionsId
      workspaceCreditTransactionsId
      projectCreditTransactionsId
    }
  }
`;
export const onUpdateCreditTransactionLog = /* GraphQL */ `
  subscription OnUpdateCreditTransactionLog(
    $filter: ModelSubscriptionCreditTransactionLogFilterInput
  ) {
    onUpdateCreditTransactionLog(filter: $filter) {
      id
      debitor
      debitorOrg {
        id
        bioskrybClientId
        organizationName
        active
        storageCreditRate
        pipelineCreditRate
        zohoId
        salesforceId
        salesforceUrl
        salesEmail
        fasEmail
        poNumber
        creditExpiry
        organizationDataKay
        workspaces {
          items {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        readGroups
        sharedS3Paths
        writeGroups
        created
        updated
      }
      creditor
      creditorOrg {
        id
        bioskrybClientId
        organizationName
        active
        storageCreditRate
        pipelineCreditRate
        zohoId
        salesforceId
        salesforceUrl
        salesEmail
        fasEmail
        poNumber
        creditExpiry
        organizationDataKay
        workspaces {
          items {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        readGroups
        sharedS3Paths
        writeGroups
        created
        updated
      }
      user
      userName
      amount
      creditPackName
      timesPackPurchased
      type
      notes
      processed
      readGroups
      writeGroups
      projectId
      project {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        clientProjectName
        lotId
        analysisType
        projectType
        description
        projectOutputS3Path
        size
        assayName
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        biosampleMetadataColumns
        readGroups
        writeGroups
        adminGroups
        biosamples {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            biosampleName
            bsshId
            analysisFiles
            r1FastqName
            r2FastqName
            r1FastqMb5Sum
            r2FastqMb5Sum
            r1FastqLength
            r2FastqLength
            r1FastqTotalReads
            r2FastqTotalReads
            petaSuiteVersion
            petaSuiteSpecies
            petaSuiteReferenceDir
            fastqValidationStatus
            metadata
            size
            fastqs
            r1FastqS3Uri
            r2FastqS3Uri
            lotId
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectBiosamplesId
          }
          nextToken
        }
        pipelines {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        created
        updated
        workspaceProjectsId
      }
      workspaceId
      workspace {
        id
        organizationId
        organization {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        description
        basespaceAccessToken
        projects {
          items {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          nextToken
        }
        lastCached
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        sharedS3Paths
        readGroups
        size
        lastSizeScan
        writeGroups
        adminGroups
        created
        updated
        organizationWorkspacesId
      }
      pipelineId
      pipeline {
        id
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        parents {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        children {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        passFailBiosamples
        pipelineName
        runDescription
        analysisLevel
        pipelineNFSessionId
        pipelineNFRunName
        referenceGenome
        estimatedCredits
        size
        pipelineVersion
        pipelineOutputS3Path
        pipelineOutputS3FullPath
        pipelineOutputs
        pipelineStarted
        pipelineCompleted
        modules
        parameters
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        errorMessage
        created
        updated
        readGroups
        writeGroups
        adminGroups
        projectPipelinesId
      }
      isSpent
      expirationInMonths
      notificationsMap
      billingStart
      created
      updated
      organizationCreditTransactionsId
      workspaceCreditTransactionsId
      projectCreditTransactionsId
    }
  }
`;
export const onDeleteCreditTransactionLog = /* GraphQL */ `
  subscription OnDeleteCreditTransactionLog(
    $filter: ModelSubscriptionCreditTransactionLogFilterInput
  ) {
    onDeleteCreditTransactionLog(filter: $filter) {
      id
      debitor
      debitorOrg {
        id
        bioskrybClientId
        organizationName
        active
        storageCreditRate
        pipelineCreditRate
        zohoId
        salesforceId
        salesforceUrl
        salesEmail
        fasEmail
        poNumber
        creditExpiry
        organizationDataKay
        workspaces {
          items {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        readGroups
        sharedS3Paths
        writeGroups
        created
        updated
      }
      creditor
      creditorOrg {
        id
        bioskrybClientId
        organizationName
        active
        storageCreditRate
        pipelineCreditRate
        zohoId
        salesforceId
        salesforceUrl
        salesEmail
        fasEmail
        poNumber
        creditExpiry
        organizationDataKay
        workspaces {
          items {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        readGroups
        sharedS3Paths
        writeGroups
        created
        updated
      }
      user
      userName
      amount
      creditPackName
      timesPackPurchased
      type
      notes
      processed
      readGroups
      writeGroups
      projectId
      project {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        clientProjectName
        lotId
        analysisType
        projectType
        description
        projectOutputS3Path
        size
        assayName
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        biosampleMetadataColumns
        readGroups
        writeGroups
        adminGroups
        biosamples {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            biosampleName
            bsshId
            analysisFiles
            r1FastqName
            r2FastqName
            r1FastqMb5Sum
            r2FastqMb5Sum
            r1FastqLength
            r2FastqLength
            r1FastqTotalReads
            r2FastqTotalReads
            petaSuiteVersion
            petaSuiteSpecies
            petaSuiteReferenceDir
            fastqValidationStatus
            metadata
            size
            fastqs
            r1FastqS3Uri
            r2FastqS3Uri
            lotId
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectBiosamplesId
          }
          nextToken
        }
        pipelines {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        created
        updated
        workspaceProjectsId
      }
      workspaceId
      workspace {
        id
        organizationId
        organization {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        description
        basespaceAccessToken
        projects {
          items {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          nextToken
        }
        lastCached
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        sharedS3Paths
        readGroups
        size
        lastSizeScan
        writeGroups
        adminGroups
        created
        updated
        organizationWorkspacesId
      }
      pipelineId
      pipeline {
        id
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        parents {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        children {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        passFailBiosamples
        pipelineName
        runDescription
        analysisLevel
        pipelineNFSessionId
        pipelineNFRunName
        referenceGenome
        estimatedCredits
        size
        pipelineVersion
        pipelineOutputS3Path
        pipelineOutputS3FullPath
        pipelineOutputs
        pipelineStarted
        pipelineCompleted
        modules
        parameters
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        errorMessage
        created
        updated
        readGroups
        writeGroups
        adminGroups
        projectPipelinesId
      }
      isSpent
      expirationInMonths
      notificationsMap
      billingStart
      created
      updated
      organizationCreditTransactionsId
      workspaceCreditTransactionsId
      projectCreditTransactionsId
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onCreateUser(filter: $filter, owner: $owner) {
      id
      organization
      tAndCAccepted
      created
      updated
      owner
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onUpdateUser(filter: $filter, owner: $owner) {
      id
      organization
      tAndCAccepted
      created
      updated
      owner
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onDeleteUser(filter: $filter, owner: $owner) {
      id
      organization
      tAndCAccepted
      created
      updated
      owner
    }
  }
`;
export const onCreateBSSHBiosample = /* GraphQL */ `
  subscription OnCreateBSSHBiosample(
    $filter: ModelSubscriptionBSSHBiosampleFilterInput
  ) {
    onCreateBSSHBiosample(filter: $filter) {
      workspaceId
      id
      bioSampleName
      defaultProject
      bsshDateModified
      bsshDateCreated
      readGroups
      writeGroups
      adminGroups
      created
      updated
    }
  }
`;
export const onUpdateBSSHBiosample = /* GraphQL */ `
  subscription OnUpdateBSSHBiosample(
    $filter: ModelSubscriptionBSSHBiosampleFilterInput
  ) {
    onUpdateBSSHBiosample(filter: $filter) {
      workspaceId
      id
      bioSampleName
      defaultProject
      bsshDateModified
      bsshDateCreated
      readGroups
      writeGroups
      adminGroups
      created
      updated
    }
  }
`;
export const onDeleteBSSHBiosample = /* GraphQL */ `
  subscription OnDeleteBSSHBiosample(
    $filter: ModelSubscriptionBSSHBiosampleFilterInput
  ) {
    onDeleteBSSHBiosample(filter: $filter) {
      workspaceId
      id
      bioSampleName
      defaultProject
      bsshDateModified
      bsshDateCreated
      readGroups
      writeGroups
      adminGroups
      created
      updated
    }
  }
`;
export const onCreateDeletedEntitiesLog = /* GraphQL */ `
  subscription OnCreateDeletedEntitiesLog(
    $filter: ModelSubscriptionDeletedEntitiesLogFilterInput
  ) {
    onCreateDeletedEntitiesLog(filter: $filter) {
      id
      organizationId
      organizationName
      workspaceId
      workspaceName
      projectName
      pipelineName
      biosampleName
      deletedInParent
      userUuidCognito
      userNameCognito
      uuidOfDeletedEntity
      typeOfDeletedEntity
      status
      affectedBiosamples
      dateOfCreationOfDeletedEntity
      emailInfo
      readGroups
      writeGroups
      adminGroups
      created
      updated
    }
  }
`;
export const onUpdateDeletedEntitiesLog = /* GraphQL */ `
  subscription OnUpdateDeletedEntitiesLog(
    $filter: ModelSubscriptionDeletedEntitiesLogFilterInput
  ) {
    onUpdateDeletedEntitiesLog(filter: $filter) {
      id
      organizationId
      organizationName
      workspaceId
      workspaceName
      projectName
      pipelineName
      biosampleName
      deletedInParent
      userUuidCognito
      userNameCognito
      uuidOfDeletedEntity
      typeOfDeletedEntity
      status
      affectedBiosamples
      dateOfCreationOfDeletedEntity
      emailInfo
      readGroups
      writeGroups
      adminGroups
      created
      updated
    }
  }
`;
export const onDeleteDeletedEntitiesLog = /* GraphQL */ `
  subscription OnDeleteDeletedEntitiesLog(
    $filter: ModelSubscriptionDeletedEntitiesLogFilterInput
  ) {
    onDeleteDeletedEntitiesLog(filter: $filter) {
      id
      organizationId
      organizationName
      workspaceId
      workspaceName
      projectName
      pipelineName
      biosampleName
      deletedInParent
      userUuidCognito
      userNameCognito
      uuidOfDeletedEntity
      typeOfDeletedEntity
      status
      affectedBiosamples
      dateOfCreationOfDeletedEntity
      emailInfo
      readGroups
      writeGroups
      adminGroups
      created
      updated
    }
  }
`;
