<template>
  <div>
    <div v-if="Object.entries(toProcess).length > 0" class="flex justify-content-end pb-2 pt-2">
      <Button v-if="!savingChanges" @click="processChanges()">Save changes</Button>
      <div>
        <img alt="BJSpinner" class="bjSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png" style="width:50px;height:50px" v-if="savingChanges" />
      </div>
    </div>
    <!-- <DataTable v-model:filters="filters" :value="transactionCredits" v-model:selection="selectedProj" :loading="loading" :paginator="true" :rows="10" selectionMode="single" @rowSelect="handleSelect" dataKey="id" :rowHover="true" responsiveLayout="scroll" filterDisplay="menu" :globalFilterFields="['date']"> -->
    <DataTable
      v-model:filters="filters"
      :value="transactionCredits"
      paginator
      :rows="10"
      dataKey="id"
      filterDisplay="menu"
      :loading="loading"
      esponsiveLayout="scroll"
      ref="dt"
      :exportFilename="`BaseJumperTransactionExport_${getTodayDateOnlyNormal()}`">
      <template #header>
        <div style="text-align: right">
          <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)" />
        </div>
      </template>
      <template #empty>
        No transactions found.
      </template>
      <template #loading>
        Loading transactions... Please wait.
      </template>
      <Column header="Date" filterField="date" dataType="date" field="date" :bodyStyle="getDateColumnBodyStyle()">
        <template #body="{ data }">
          {{ this.getDate(data.date) }}
        </template>
        <template #filter="{ filterModel }">
          <Calendar v-model="filterModel.value" dateFormat="yy-mm-dd" placeholder="mm/dd/yyyy" />
        </template>
      </Column>
      <Column header="Amount" field="amount" :bodyStyle="getBodyStyle()">
        <template #body="{ data }">
          {{ this.formatAmount(data.amount, data.type) }}
        </template>
      </Column>
      <Column header="Type" field="type" :bodyStyle="getBodyStyle()" :showFilterMenu="true">
        <template #filter="{ filterModel }">
          <Dropdown v-model="filterModel.value" :options="transactionTypes" placeholder="Select One" class="p-column-filter" style="min-width: 12rem" :showClear="true" />
        </template>
      </Column>
      <Column header="Purchase Quantity" :bodyStyle="getBodyStyle()" field="timesPackPurchased">
        <template #body="{ data }">
          {{ (data.timesPackPurchased) ? data.timesPackPurchased : '' }}
        </template>
      </Column>
      <Column header="Pack Name" field="creditPackName" :bodyStyle="getBodyStyle()">
        <template #body="{ data }">
          {{ (data.creditPackName) ? data.creditPackName : '' }}
        </template>
      </Column>
      <Column header="Project" field="project.clientProjectName" :bodyStyle="getBodyStyle()">
        <template #body="{ data }">{{this.getProjectName(data)}}</template>
      </Column>
      <Column header="Pipelines" field="pipeline.pipelineName" :bodyStyle="getBodyStyle()">
        <template #body="{ data }">
          <span v-if="checkForPipelineId(data.pipelineId, data.pipeline)">{{ data.pipeline.pipelineName }}</span>
        </template>
      </Column>
      <Column header="Biosamples" field="totalBiosamples" :bodyStyle="getBodyStyle()" />
      <Column header="User" field="userName" :bodyStyle="getBodyStyle()">
        <template #body="{ data }">
          <span>{{getUsername(data.userName)}}</span>
        </template>
      </Column>
      <Column field="notes" header="Notes" :bodyStyle="getBodyStyle()" />
      <Column field="isSpent" header="Spent" :bodyStyle="getBodyStyle()" :showFilterMenu="true">
        <template #body="{ data }">
          <i v-if="data && data.isSpent" class="pi pi-check-circle isSpentCheck" />
        </template>
        <template #filter="{ filterModel }">
          <Dropdown v-model="filterModel.value" :options="[true, false]" placeholder="Select One" class="p-column-filter" style="min-width: 12rem" :showClear="true" />
        </template>
      </Column>
      <Column field="processed" header="Processed " :bodyStyle="getBodyStyle()">
        <template #body="{ data }">
          <Checkbox inputId="binary" @change="handleCheckbox(data)" v-model="data.processed" :binary="true" value="true" />
        </template>
      </Column>

    </DataTable>
  </div>
</template>

<script>
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';
import { parseDateAndTime, getTodayDateOnlyNormal } from '@/utils';

export default {
  name: 'Transactions',
  props: {
    transactionCredits: { type: Array, required: true },
    loading: { type: Boolean, required: true },
  },
  data() {
    return {
      selectedProj: null,
      bioskrybId: '',
      toProcess: {},
      savingChanges: false,
      transactionTypes: ['CreditPurchase', 'Storage', 'Pipeline', 'Adjustment', 'Reversal'],
      filters: {
        date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        type: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        isSpent: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      },
    };
  },
  methods: {
    async getBioSkrybOrgId() {
      try {
        const response = await API.graphql(graphqlOperation(queries.listOrganizations)).then((res) => res.data.listOrganizations.items.find((x) => x.organizationName === 'BioSkryb Genomics'));

        this.bioskrybId = response.id;
      } catch (err) {
        console.error('Failed to get bioskryb id');
        console.error(err);
      }
    },
    getUsername(user) {
      if (!user) {
        return null;
      }
      return user;
    },
    getTodayDateOnlyNormal() {
      return getTodayDateOnlyNormal();
    },
    getProjectName(obj) {
      if (!obj.project) {
        return null;
      }
      return obj.project?.clientProjectName;
    },
    getBodyStyle() {
      return 'text-align: center';
    },
    getDateColumnBodyStyle() {
      return `${this.getBodyStyle()}; min-width: 10rem`;
    },
    // eslint-disable-next-line no-unused-vars
    handleSelect(event) {
      // if (event.data.project.status !== 'Job Started') {
      //   this.$router.push({ path: `/project/${event.data.project.id}` });
      // }
    },
    getDate(date) {
      try {
        // return parseDateOnly(date);
        return parseDateAndTime(date);
      } catch (error) {
        console.error(error);
        return date;
      }
    },
    formatAmount(amount, type) {
      if (type === 'CreditPurchase') {
        return `+ ${amount}`;
      }
      if (type === 'Storage' || type === 'Pipeline') {
        return `- ${amount}`;
      }
      return '';
    },
    checkForPipelineId(id, pipeline) {
      if (!id || !pipeline) {
        return null;
      }
      try {
        return pipeline.pipelineName;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    handleCheckbox(transaction) {
      try {
        if (!(transaction.id in this.toProcess)) {
          this.toProcess[transaction.id] = (transaction.processed !== null) ? transaction.processed : true;
        } else {
          delete this.toProcess[transaction.id];
        }
      } catch (error) {
        console.error(error);
      }
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    async processChanges() {
      try {
        this.savingChanges = true;
        const promises = [];
        for (const [id, value] of Object.entries(this.toProcess)) {
          promises.push(API.graphql(graphqlOperation(mutations.updateCreditTransactionLog, {
            input: {
              id,
              processed: value,
            },
          })));
        }
        await Promise.all(promises);
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Changes saved successfully!',
          life: 3000,
        });
      } catch (error) {
        console.error(error);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Saving changes failed!',
          life: 3000,
        });
      }
      this.toProcess = {};
      this.savingChanges = false;
    },
  },
  async mounted() {
    document.title = 'Transactions';
    await this.getBioSkrybOrgId();
    const user = await Auth.currentAuthenticatedUser();
    console.log('user :>> ', user);
  },
  computed: {
    getActiveWorkspace() {
      return this.$store.state.activeWorkspace;
    },
  },
};

</script>
<style lang="scss" scoped>
@import "@/assets/styles/sass/_dataTable.scss";

::v-deep(.p-datatable .p-datatable-thead > tr > th:first-child) {
  border-radius: 0px 0px 0px 0px;
}

::v-deep(.p-datatable .p-datatable-thead > tr > th:last-child) {
  border-radius: 0px 0px 0px 0px;
}
::v-deep(.p-datatable .p-datatable-header) {
  border-radius: 15px 15px 0px 0px;
}

.isSpentCheck {
  color: green;
 font-size: 2rem;
}
</style>
