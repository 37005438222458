<template>
  <Dialog v-model:visible="showing" :style="{ width: '650px' }" header="Update Biosample Metadata Project Columns" :modal="true" class="p-fluid" :closable="false">
    <div v-if="!updating">
      <BiosampleMetadataColumnsDynamicRenderer v-model:biosampleMetadataColumns="biosampleMetadataColumns" />
    </div>
    <div v-if="updating" class="flex justify-content-center">
      <img alt="BJSpinner" class="bjSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png" />
    </div>
    <template #footer>
      <div v-if="!updating" class="pt-4">
        <Button :disabled="updating" label="Cancel" icon="pi pi-times" class="p-button-secondary" @click="hideDialog" />
        <Button :disabled="updating" class="ml-2" @click="save()" label="Save" />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '@/graphql/mutations';
import BiosampleMetadataColumnsDynamicRenderer from '@/components/Project/BiosampleMetadataColumnsDynamicRenderer.vue';
import {
  makeBiosampleMetadataColumnsJson, duplicatesByKeyInArray, getEmptyMetadataObj, valueIsNullOrUndefined,
} from '@/utils';

export default {
  props: ['project'],
  components: {
    BiosampleMetadataColumnsDynamicRenderer,
  },
  data() {
    return {
      showing: false,
      updating: false,
      biosampleMetadataColumns: [
        getEmptyMetadataObj(),
      ],
    };
  },
  methods: {
    getMetadataColumns(project) {
      try {
        if (valueIsNullOrUndefined(project)) {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Internal error!',
            life: 3000,
          });
          console.error('Bad project in getMetadataColumns');
          return [];
        }
        if (valueIsNullOrUndefined(project.biosampleMetadataColumns)) {
          return [
            getEmptyMetadataObj(),
          ];
        }
        let columns = JSON.parse(project.biosampleMetadataColumns).columns;
        if (valueIsNullOrUndefined(columns) || columns.length === 0) {
          columns = [getEmptyMetadataObj()];
        } else {
          columns = this.disableTypAndNameModification(columns);
        }
        return columns;
      } catch (error) {
        console.error(error);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Internal error!',
          life: 3000,
        });
        return [];
      }
    },
    disableTypAndNameModification(columns) {
      return columns.map((col) => {
        // eslint-disable-next-line no-param-reassign
        col.disableChangeTypeAndName = true;
        return col;
      });
    },
    async save() {
      if (duplicatesByKeyInArray(this.biosampleMetadataColumns, 'name')) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Duplicate column names!',
          life: 3000,
        });
        return;
      }
      this.updating = true;
      try {
        await this.updateProject(this.project.id, this.biosampleMetadataColumns);
      } catch (error) {
        console.error(error);
      }
      this.hideDialog();
    },
    async updateProject(projectId, columns) {
      try {
        await API.graphql(graphqlOperation(mutations.updateProject, { input: { id: projectId, biosampleMetadataColumns: makeBiosampleMetadataColumnsJson(columns) } }));
      } catch (error) {
        console.error(error);
      }
    },
    hideDialog() {
      this.$store.dispatch('setShowingUpdateBiosampleMetadataColumns', false);
      this.updating = false;
    },
  },
  computed: {
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingUpdateBiosampleMetadataColumns': async function () {
      this.showing = this.$store.state.showingUpdateBiosampleMetadataColumns;
      this.biosampleMetadataColumns = this.getMetadataColumns(this.project);
    },
  },
};
</script>

<style lang="scss">
</style>
