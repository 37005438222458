<template>
  <div>
    <div v-if="workspaceUsers" class="flex pt-2" style="color: white;">
      <h5>Workspace Users</h5>
    </div>
    <DataTable
      :value="users"
      dataKey="Username"
      :paginator="true"
      :rows="10"
      :rowHover="true"
      :loading="loading"
      v-model:filters="filters"
      ref="dt"
      filterDisplay="row"
      :globalFilterFields="['Attributes.2.Value', 'Attributes.3.Value']"
      responsiveLayout="scroll"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 20, 50]"
    >
      <template #header>
        <div style="text-align: right">
          <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)" />
        </div>
      </template>
      <template #empty>
        No users found.
      </template>
      <template #loading>
        Loading Users... Please wait.
      </template>
      <Column header="User" field="name" :bodyStyle="getBodyStyle()" :sortable="true" :headerStyle="headerStyle()">
        <template #filter="{ filterModel, filterCallback }">
          <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="Search by user name" />
        </template>
        <template #body="{ data }">
          {{ getUsername(data.Attributes) }}
        </template>
      </Column>
      <Column header="Email" field="email" :bodyStyle="getBodyStyle()" :sortable="true" :headerStyle="headerStyle()">
        <template #filter="{ filterModel, filterCallback }">
          <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="Search by user email" />
        </template>
        <template #body="{ data }">
          {{ getEmail(data.Attributes) }}
        </template>
      </Column>
      <Column header="Organization" field="organization" :bodyStyle="getBodyStyle()" :sortable="true" :headerStyle="headerStyle()">
        <template #filter="{ filterModel, filterCallback }">
          <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="Search by organization" />
        </template>
        <template #body="{ data }">
          {{ data.organization }}
        </template>
      </Column>
      <Column header="Type" :bodyStyle="getBodyStyle()" :headerStyle="headerStyle()">
        <template #body="{ data }">
          {{ getType(data.Username) }}
        </template>
      </Column>
      <Column header="Date Created" field="UserCreateDate" :bodyStyle="getBodyStyle()" :sortable="true" :headerStyle="headerStyle()">
        <template #body="{ data }">
          {{ $filters.formatDate(data.UserCreateDate) }}
        </template>
      </Column>
      <Column header="Enabled" :bodyStyle="getBodyStyle()" :headerStyle="headerStyle()">
        <template #body="{ data }">
          {{ data.Enabled }}
        </template>
      </Column>
      <Column :headerStyle="headerStyle(5)">
        <template #body="{ data }">
          <Button icon="pi pi-ellipsis-v" @click="userActionClick(data, $event)" />
          <Menu :ref="'user-menu-' + data.Username" :model="userActionItems(data)" :popup="true" />
        </template>
      </Column>
    </DataTable>
    <ChangeGroupDialog
      :username="usernameForChangeGroup"
      @resetUsernameForChangeGroup="resetUsernameForChangeGroup" />
  </div>
</template>

<script>
// Alot of the functions here were pushed down to ChnageGroup.vue. This just lists the users based on the current users permisions.

import { Auth, API } from 'aws-amplify';
// eslint-disable-next-line no-unused-vars
import _ from 'lodash';
import { FilterMatchMode } from 'primevue/api';
import ChangeGroupDialog from '@/components/Users/ChangeGroupDialog.vue';
import * as queries from '@/graphql/queries';
import { getGroups } from '@/utils';

export default {
  name: 'Users',
  components: {
    ChangeGroupDialog,
  },
  data() {
    return {
      loading: false,
      users: [],
      workspaceUsers: false,
      showChangeGroupDialog: false,
      usernameForChangeGroup: null,
      loadUsersToken: '',
      loadAllUsersToken: '',
      gqlUsers: [],
      user: null,
      filters: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        email: { value: null, matchMode: FilterMatchMode.CONTAINS },
        organization: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  methods: {
    // Main loading function
    async loadUsersMain() {
      this.loading = true;
      if ('wsId' in this.$route.params && this.$route.params.wsId !== null && this.$route.params.wsId !== undefined) {
        const adminUsers = await this.loadUsersFromGroup(`WS/${this.$route.params.wsId}/Admin`);
        const normalUsers = await this.loadUsersFromGroup(`WS/${this.$route.params.wsId}/User`);
        const users = [...adminUsers, ...normalUsers];
        const filteredUsers = users.filter((user) => !user.Username.startsWith('google'));
        this.users = filteredUsers;
        this.users.map((user) => {
          // eslint-disable-next-line no-param-reassign
          user.name = this.getUsername(user.Attributes);
          // eslint-disable-next-line no-param-reassign
          user.email = this.getEmail(user.Attributes);
          return user;
        });
        console.log('this.users :>> ', this.users);
        this.$watch(() => this.$route.params.wsId, (value) => {
          console.log('wsId value :>> ', value);
          if (value === undefined || value === null) {
            this.workspaceUsers = false;
            this.loadUsersMain();
          }
        });
        this.workspaceUsers = true;
        this.loading = false;
        return;
      }
      if ('id' in this.$route.params && this.$route.params.id !== null && this.$route.params.id !== undefined) {
        const users = await this.loadUsersFromGroup(`ORG/${this.$route.params.id}/Admin`);
        const filteredUsers = users.filter((user) => !user.Username.startsWith('google'));
        this.users = filteredUsers;
        this.users.map((user) => {
          // eslint-disable-next-line no-param-reassign
          user.name = this.getUsername(user.Attributes);
          // eslint-disable-next-line no-param-reassign
          user.email = this.getEmail(user.Attributes);
          return user;
        });
        this.loading = false;
        return;
      }
      const groupsPromise = getGroups();
      const GQLUsersPromise = this.loadGQLUsers();
      // The precedence level is loaded in MainLayout.vue In case the user opens this component before the precedence level is set we must wait
      if (this.$store.state.precedenceLevel === 10) {
        setTimeout(await this.loadUsersMain, 500);
        return;
      }
      const groups = await groupsPromise;
      console.log('Groups loaded');
      const promises = [];
      if ((groups.length === 1) && (groups[0] === 'BaseJumperAdmin')) {
        await this.loadAllUsers(GQLUsersPromise);
      } else {
        groups.forEach((grp) => {
          promises.push(this.loadUsersFromGroup(grp));
        });
        let users = await Promise.all(promises);
        users = users.flat();
        // Filter out duplicates
        this.users = users.filter((user, index, self) => self.findIndex((t) => t.Username === user.Username) === index);
        this.users.map((user) => {
          // eslint-disable-next-line no-param-reassign
          user.name = this.getUsername(user.Attributes);
          // eslint-disable-next-line no-param-reassign
          user.email = this.getEmail(user.Attributes);
          return user;
        });
        // this.users = Array.from(new Set(users.map((a) => a.Username))).map((Username) => users.find((a) => a.username === Username));
        this.loading = false;
      }
      // Wait for it in case its not done yet, but it should be.
      await GQLUsersPromise;
    },
    // Loads all users
    async loadAllUsers(GQLUsersPromise) {
      try {
        this.loading = true;
        let allUsers = [];
        while (this.loadAllUsersToken !== undefined) {
          const res = await this.loadUsersCall();
          allUsers.push(res);
        }
        allUsers = allUsers.map((users) => [...users.Users]).flat();
        allUsers = [...allUsers];
        const sortedUsers = allUsers.sort((a, b) => new Date(b.UserCreateDate) - new Date(a.UserCreateDate));
        // Map names to users
        this.users = sortedUsers;
        this.users.map((user) => {
          // eslint-disable-next-line no-param-reassign
          user.name = this.getUsername(user.Attributes);
          // eslint-disable-next-line no-param-reassign
          user.email = this.getEmail(user.Attributes);
          return user;
        });
        const gqlUsers = await GQLUsersPromise;
        this.users = this.users.map((user) => {
          if (user.Username in gqlUsers) {
            // eslint-disable-next-line no-param-reassign
            user.organization = gqlUsers[user.Username].organization;
          } else {
            // eslint-disable-next-line no-param-reassign
            user.organization = 'None';
          }
          return user;
        });
        this.loadAllUsersToken = '';
        this.loading = false;
      } catch (error) {
        console.error('Loading users failed');
        console.error(error);
      }
    },
    // User entities in GraphQl. Store what ever else we need that cannot fit into cognito custom attributes.
    async loadGQLUsers() {
      const users = {};
      try {
        const response = await API.graphql({
          query: queries.listUsers,
          variables: {
            limit: 500,
          },
        });
        // users.push(...response.data.listUsers.items);
        if (response.data.listUsers.items.length === 1) {
          users[response.data.listUsers.items[0].id] = response.data.listUsers.items[0];
        } else {
          [...response.data.listUsers.items].reduce((map, obj) => {
            users[obj.id] = obj;
            return map;
          }, {});
        }
        let nextToken = response.data.listUsers.nextToken;
        while (nextToken !== null) {
          // await this.loadGQLUsers(nextToken);
          const res = await this.getGQLUsersCall(nextToken);
          if (res.items.length === 1) {
            users[res.items[0].id] = res.items[0];
          } else {
            [...response.data.listUsers.items].reduce((map, obj) => {
              users[obj.id] = obj;
              return map;
            }, {});
          }
          nextToken = res.nextToken;
        }
        return users;
      } catch (error) {
        console.log('Loading users failed');
        console.error(error);
        return null;
      }
    },
    async getGQLUsersCall(token) {
      try {
        const response = await API.graphql({
          query: queries.listUsers,
          variables: {
            limit: 500,
            nextToken: token,
          },
        });
        return response.data.listUsers;
      } catch (error) {
        console.log('Loading users call failed');
        console.error(error);
        return null;
      }
    },
    // // Return for which groups we should get the users for
    // async getGroups() {
    //   const groups = this.user.signInUserSession.accessToken.payload['cognito:groups'];
    //   const usersGroups = [];
    //   if (groups.some((grp) => grp === 'BaseJumperAdmin')) {
    //     usersGroups.push('BaseJumperAdmin');
    //     return usersGroups;
    //   }
    //   const orgGroups = groups.filter((grp) => grp.startsWith('ORG/'));
    //   const workspaceGroups = groups.filter((ws) => (ws.startsWith('WS/') && (ws.endsWith('/Admin'))));
    //   // const allGroupsToGet = orgGroups.concat(workspaceGroups);
    //   if (this.$store.state.precedenceLevel === 2) {
    //     return orgGroups.concat(workspaceGroups);
    //   }
    //   if (this.$store.state.precedenceLevel === 3) {
    //     return workspaceGroups;
    //   }
    //   return null;
    // },
    // eslint-disable-next-line consistent-return
    async loadUsersFromGroup(groupname) {
      try {
        // eslint-disable-next-line prefer-const
        let allUsers = [];
        while (this.loadUsersToken !== undefined) {
          const res = await this.loadUsersFromGroupCall(groupname);
          allUsers.push(res);
        }
        this.loadUsersToken = '';
        allUsers = allUsers.flatMap((users) => [...users.Users]);
        const sortedUsers = allUsers.sort((a, b) => new Date(b.UserCreateDate) - new Date(a.UserCreateDate));
        return sortedUsers;
      } catch (error) {
        console.error('Loading users failed');
        console.error(error);
      }
    },
    async loadUsersFromGroupCall(groupname) {
      const apiName = 'AdminQueries';
      const path = '/listUsersInGroup';
      const myInit = {
        queryStringParameters: {
          limit: 60, // Max limit is 60
          token: this.loadUsersToken,
          groupname,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        },
      };
      const { NextToken, ...groups } = await API.get(apiName, path, myInit);
      this.loadUsersToken = NextToken;
      return groups;
    },
    async loadUsersCall() {
      try {
        const apiName = 'AdminQueries';
        const path = '/listUsers';
        const myInit = {
          queryStringParameters: {
            limit: 60, // Max limit is 60
            token: this.loadAllUsersToken,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
          },
        };
        const { NextToken, ...groups } = await API.get(apiName, path, myInit);
        this.loadAllUsersToken = NextToken;
        return groups;
      } catch (error) {
        console.error('LoadUsersCall failed');
        console.error(error);
        return [];
      }
    },
    headerStyle(width = 16) {
      return `width: ${width}%`;
    },
    resetUsernameForChangeGroup() {
      this.usernameForChangeGroup = null;
    },
    getUsername(attributes) {
      const name = attributes.filter((a) => a.Name === 'name');
      return name[0].Value;
    },
    getEmail(attributes) {
      const email = attributes.filter((a) => a.Name === 'email');
      return email[0].Value;
    },
    getBodyStyle() {
      return 'text-align: center';
    },
    getType(username) {
      if (username.startsWith('google')) {
        return 'Google Account';
      }
      return 'Standard Account';
    },
    userActionClick(user, event) {
      this.$refs[`user-menu-${user.Username}`].toggle(event);
    },
    userActionItems(user) {
      const changeGroup = {
        label: 'Change Group',
        command: () => {
          // this.showChangeGroupDialog = !this.showChangeGroupDialog;
          this.usernameForChangeGroup = user.Username;
          this.$store.dispatch('showChangeGroup');
        },
      };
      const enable = {
        label: 'Enable',
        command: async () => {
          await this.enableUser(user);
          this.$toast.add({
            severity: 'success',
            summary: 'Success',
            detail: `User ${this.getUsername(user.Attributes)} Enabled!`,
            life: 3000,
          });
          await this.loadUsersMain();
        },
      };
      const disable = {
        label: 'Disable',
        command: async () => {
          await this.disableUser(user);
          this.$toast.add({
            severity: 'success',
            summary: 'Success',
            detail: `User ${this.getUsername(user.Attributes)} Disabled!`,
            life: 3000,
          });
          await this.loadUsersMain();
        },
      };
      if (user.Enabled) {
        return [changeGroup, disable];
      }
      return [changeGroup, enable];
    },
    async enableUser(user) {
      const apiName = 'AdminQueries';
      const path = '/enableUser';
      const myInit = {
        body: {
          username: `${user.Username}`,

        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        },
      };
      return API.post(apiName, path, myInit);
    },
    async disableUser(user) {
      const apiName = 'AdminQueries';
      const path = '/disableUser';
      const myInit = {
        body: {
          username: `${user.Username}`,

        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        },
      };
      return API.post(apiName, path, myInit);
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
  },
  async mounted() {
    document.title = 'Users';
    this.user = await Auth.currentAuthenticatedUser();
    await this.loadUsersMain();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/sass/_dataTable.scss";
</style>
