/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://xl5dmsew70.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://wptnrcy47nel7ezizipkfe7kvm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:b1625feb-1b40-4d2e-a7f0-32e9bfe51ec6",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_iSxBIEL3R",
    "aws_user_pools_web_client_id": "29id528j8dtem0qn0f935lu1kg",
    "oauth": {
        "domain": "basejumper-master.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://basejumper.bioskryb.com/,https://basejumper-dev.bioskryb.com/,https://basejumper-demo.bioskryb.com/,https://ce4102b7742842b9801aa738fcd4874e.vfs.cloud9.us-east-1.amazonaws.com/,https://develop.d2jzvxcfbyvorz.amplifyapp.com/,https://staging.d3r42ecw20dcva.amplifyapp.com/,https://basejumper-staging.bioskryb.com/",
        "redirectSignOut": "https://basejumper.bioskryb.com/,https://basejumper-dev.bioskryb.com/,https://basejumper-demo.bioskryb.com/,https://ce4102b7742842b9801aa738fcd4874e.vfs.cloud9.us-east-1.amazonaws.com/,https://develop.d2jzvxcfbyvorz.amplifyapp.com/,https://staging.d3r42ecw20dcva.amplifyapp.com/,https://basejumper-staging.bioskryb.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
