<template>
  <BiosamplesTable :projectId="projectId" />
</template>

<script>
import BiosamplesTable from '@/components/Biosamples/BiosamplesTable.vue';

export default {
  name: 'Biosamples',
  components: {
    BiosamplesTable,
  },
  data() {
    return {
      projectId: null,
    };
  },
  created() {
    this.projectId = this.$route.params.id;
  },
};
</script>

<style scoped lang="scss">
</style>
