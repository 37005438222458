<template>
  <Dialog v-model:visible="showing" :style="{ width: '450px' }" header="Change Password" :modal="true" class="p-fluid" :closable="false">
    <InlineMessage :style="same ? '' : 'display: none;'">New password is the same as the old passowrd!</InlineMessage>
    <div class="field">
      <label for="oldPassword">Old Password</label>
      <InlineMessage :style="(validatePassword(oldPassword) === false) ? '' : 'display: none;'">A password can only contain letters, numbers and some special symbols!</InlineMessage>
      <Password id="oldPassword" :class="(validatePassword(oldPassword) === false) ? 'p-invalid' : ''" v-model="oldPassword" required="true" toggleMask :feedback="false" />
    </div>
    <div class="field mb-5 pb-5">
      <label for="newPassword">New Password</label>
      <!-- <InlineMessage :style="(validatePassword(newPassword) === false) ? '' : 'display: none;'">A password can only contain letters, numbers and some special symbols!</InlineMessage> -->
      <Password id="newPassword" :class="(validatePassword(newPassword) === false) ? 'p-invalid' : ''" v-model="newPassword" toggleMask />
    </div>
    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-secondary" @click="hide" />
      <Button label="Save" class="p-button-outline" @click="save" />
    </template>
  </Dialog>
</template>

<script>
import { Auth } from 'aws-amplify';

export default {
  data() {
    return {
      same: false,
      showing: false,
      oldPassword: null,
      newPassword: null,
    };
  },
  methods: {
    hide() {
      this.$store.dispatch('showChangePassword');
      this.oldPassword = null;
      this.newPassword = null;
    },
    save() {
      if (!this.validatePassword(this.oldPassword)) {
        console.log('Old password bad');
        return;
      }
      if (!this.validatePassword(this.newPassword)) {
        console.log('New password bad');
        return;
      }
      if (this.newPassword === null || this.oldPassword === null || this.newPassword === undefined || this.oldPassword === undefined) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Password cannot be empty!',
          life: 3000,
        });
        return;
      }
      if (this.newPassword.length < 8) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Password must be longer than 8 characters!',
          life: 3000,
        });
        return;
      }
      // eslint-disable-next-line no-unused-expressions
      if (this.oldPassword === this.newPassword) {
        this.same = true;
        return;
      }
      this.same = false;

      Auth.currentAuthenticatedUser()
        .then((user) => Auth.changePassword(user, this.oldPassword, this.newPassword))
        .then((data) => {
          console.log(data);
          this.$toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Password changed successfully!',
            life: 3000,
          });
          this.hide();
          Auth.signOut().then(() => {
            this.$router.push('/auth/login');
            this.$store.dispatch('setActiveWorkspace', '');
          }).catch((e) => {
            console.log('error signing out: ', e);
          });
        })
        .catch((err) => {
          console.log(err);
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Changing password failed!',
            life: 3000,
          });
          this.hide();
        });
    },
    validatePassword(pass) {
      if (pass !== '' && pass !== null && pass.length < 8) {
        return false;
      }
      return true;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingChangePassword': async function () {
      this.showing = this.$store.state.showingChangePassword;
    },
  },
};
</script>
