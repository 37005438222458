<template>
  <div class="auth-view flex justify-content-center align-items-center">
    <div class="logo">
      <img alt="logo" src='@/assets/logo_with_tagline.png'>
    </div>
    <div class="moto">
      <img alt="tagline" src='@/assets/BioSkrybElements/CellsExplored-Tagling.png'>
    </div>
    <authenticator
      :login-mechanisms="['username']"
      :social-providers="[]"
      ref="auth"
    >
      <template v-slot:sign-up-fields>
        <authenticator-sign-up-form-fields :ref="(el) => { signUpTab = el }" />
        <Button label="Read terms and conditions" @click="showTermsAndConditionsText()" />
        <InlineMessage :style="(termsAndConditionsCheckedError === true) ? '' : 'display: none;'">You must agree with the terms and conditions!</InlineMessage>
        <div class="field-checkbox">
          <Checkbox inputId="binary" v-model="termsAndConditionsChecked" :binary="true" />
          <label for="binary">I agree with the Terms & Conditions</label>
        </div>
      </template>
    </authenticator>
  </div>
  <TermsAndConditionsView @agree="agree" />
</template>
<script>
// Amplify authenticator. For help with development look at docs.
// https://docs.amplify.aws/lib/auth/customui/q/platform/js/#customize-text-labels
import {
  // eslint-disable-next-line no-unused-vars
  Auth, Hub, API, graphqlOperation,
} from 'aws-amplify';
import { sendNotificationEmail } from '@/utils';
import TermsAndConditionsView from '@/views/Auth/TermsAndConditionsView.vue';
import * as mutations from '@/graphql/mutations';

export default {
  components: {
    TermsAndConditionsView,
  },
  data() {
    return {
      signUp: false,
      termsAndConditionsChecked: false,
      termsAndConditionsCheckedError: false,
      signUpTab: null,
      signUpTabOpen: false,
      hubListenerCancelToken: null,
      listener: null,
    };
  },
  async mounted() {
    document.title = 'Login';
    const authCredentials = await Auth.currentCredentials();
    if (authCredentials.authenticated) {
      this.$router.push('/');
    }
    this.listener = (data) => {
      switch (data.payload.event) {
        case 'signIn':
          if (this.signUp) {
            console.log('Sending email');
            sendNotificationEmail(`A new user has signed in: ${data.payload.data.attributes.name}. User's email: '${data.payload.data.attributes.email}'`, ['basejumper@bioskryb.com', 'fas@bioskryb.com']);
            // sendNotificationEmail(`A new user has signed in: ${data.payload.data.attributes.name}. User's email: '${data.payload.data.attributes.email}'`, ['filip.gunic@bioskryb.com']);
            this.createUserObj(data.payload.data.username);
            this.signUp = false;
          }
          this.$router.push('/');
          break;
        case 'signUp':
          this.signUp = true;
          break;
        default:
          break;
      }
    };
    this.hubListenerCancelToken = Hub.listen('auth', this.listener);
  },
  methods: {
    agree() {
      this.termsAndConditionsChecked = true;
      this.termsAndConditionsCheckedError = false;
    },
    showTermsAndConditionsText() {
      this.$store.dispatch('setShowingTermsAndConditionsView', true);
    },
    createUserObj(id) {
      try {
        const userVariables = {
          id,
          tAndCAccepted: true,
        };
        API.graphql(graphqlOperation(mutations.createUser, { input: userVariables }));
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    suFields() {
      return this.$refs.suFields;
    },
  },
  watch: {
    async signUpTab() {
      const target = document.getElementsByClassName('amplify-button amplify-field-group__control')[2];
      const options = {
        subtree: true,
        childList: true,
        attributes: true,
      };
      // This is necessary because something in the amplify component keeps removing the disabled attribute.
      // eslint-disable-next-line no-undef
      const observer = new MutationObserver((mutationList) => {
        const registerBtn = document.getElementsByClassName('amplify-button amplify-field-group__control')[2];
        if (mutationList[0].attributeName === 'disabled' && (registerBtn.disabled === true)) {
          return;
        }
        if (this.termsAndConditionsChecked === false) {
          document.getElementsByClassName('amplify-button amplify-field-group__control')[2].disabled = true;
        }
      });
      try {
        observer.observe(target, options);
      } catch (error) {
        console.error(error);
      }
      if (this.signUpTab !== undefined && this.signUpTab !== null) {
        this.signUpTabOpen = true;
        const buttons = document.getElementsByClassName('amplify-button amplify-field-group__control');
        const signUpButton = buttons[2];
        signUpButton.disabled = true;
        if (!this.termsAndConditionsChecked) {
          // this.hubListenerCancelToken();
        }
      } else {
        // this.hubListenerCancelToken = Hub.listen('auth', this.listener);
        this.signUpTabOpen = false;
      }
    },
    termsAndConditionsChecked() {
      if (!this.termsAndConditionsChecked) {
        const buttons = document.getElementsByClassName('amplify-button amplify-field-group__control');
        const signUpButton = buttons[2];
        signUpButton.disabled = true;
        // this.hubListenerCancelToken();
      } else {
        const buttons = document.getElementsByClassName('amplify-button amplify-field-group__control');
        const signUpButton = buttons[2];
        signUpButton.disabled = false;
        // this.hubListenerCancelToken = Hub.listen('auth', this.listener);
      }
    },
  },
};

</script>

<style lang="scss" scoped>
.auth-view{
  background-image: url('~@/assets/BioSkrybElements/BioSkryb_BaseJumper_LoginBackground.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  background-size: 100% 100%;
  height: 100vh;

  .logo {
    position: absolute;
    left: 40px;
    top: 40px;
    img {
      width: 30vh;
    }
  }

  .moto {
    position: absolute;
    right: 40px;
    top: 40px;
    img {
      width: 60vh;
    }
  }
}

::v-deep(.p-checkbox .p-checkbox-box.p-highlight) {
  background: #057d95;
  border-color: #057d95;
}

.p-button {
  background: #68adcb !important;
  border: 0 !important;
  border-radius: 5px;
  padding: 10px !important;
}

.p-button:hover {
  background-color: #12284d !important;
}

::v-deep(.amplify-button--primary) {
  background-color: #68adcb !important;
  border-radius: 5px !important;
  padding: 10px !important;
  border: 0 !important;
}

::v-deep(.amplify-button--primary:hover) {
  background-color: #12284d !important;
}

::v-deep(.amplify-tabs-item[data-state=active]) {
  color: #68adcb;
  border-color: #68adcb;
}

::v-deep(.amplify-tabs-item) {
  color: #12284d;
}

::v-deep(.amplify-input) {
  border-radius: 5px !important;
}

::v-deep(.amplify-input[data-amplify-password=true]) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

::v-deep(.amplify-field__show-password) {
  border-left: 0 !important;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

::v-deep(.p-checkbox-checked .p-checkbox-box) {
  background-color: #68adcb !important;
}

::v-deep(.amplify-input) {
  border-radius: 5px;
}

</style>
