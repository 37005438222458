<template>
  <Dialog v-model:visible="showing" :style="{ width: '400px' }" header="Reset project and biosample statuses" :modal="true" class="p-fluid" :closable="false">
    <div v-if="!loading">
      <div class="field pt-4">
        <span class="p-float-label">
          <InputText id="projectId" type="text" v-model.trim="projectId" required="true" autofocus />
          <label for="projectId">Project Id</label>
        </span>
      </div>
    </div>
    <div v-if="loading">
      <img alt="BJSpinner" class="bjSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png" />
    </div>
    <template #footer v-if="!loading">
      <Button label="Cancel" icon="pi pi-times" class="p-button-secondary" @click="hideDialog" />
      <Button label="Update Statuses" class="p-button-outline" @click="updateStatuses" />
    </template>
  </Dialog>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '@/graphql/mutations';
import { listItems } from '@/utils';
import * as customQueries from '@/graphql/custom_queries';

const asyncBatch = require('async-batch').default;

export default {
  data() {
    return {
      showing: false,
      projectId: null,
      loading: false,
    };
  },
  methods: {
    hideDialog() {
      this.projectId = null;
      this.loading = false;
      this.$store.dispatch('setShowingResetProjectAndBiosampleStatuses', false);
    },
    async updateStatuses() {
      try {
        this.loading = true;
        if (this.projectId === null || this.projectId === undefined) {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Project update failed! Project Id is wrong!',
            life: 3000,
          });
          return;
        }
        await this.updateProjectStatus(this.projectId);
        const biosamples = await this.getBiosamples(this.projectId);
        const biosampleUpdateObjects = biosamples.map((bs) => ({
          id: bs.id,
          fastqValidationStatus: 'Pass',
        }));
        await asyncBatch(biosampleUpdateObjects, this.updateBiosample, 50);
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Project and biosamples status reset!',
          life: 3000,
        });
      } catch (error) {
        console.error(error);
      }
      this.hideDialog();
    },
    async updateProjectStatus(projectId) {
      try {
        await API.graphql(graphqlOperation(mutations.updateProject, { input: { id: projectId, status: 'Job Complete' } }));
      } catch (error) {
        console.error(error);
      }
    },
    async getBiosamples(projectId) {
      try {
        const biosamples = await listItems(customQueries.biosamplesByProjectSmall, { projectId });
        return biosamples;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async updateBiosample(updateBsVar) {
      try {
        await API.graphql(graphqlOperation(mutations.updateBiosample, { input: updateBsVar }));
      } catch (error) {
        console.error(error);
      }
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingResetProjectAndBiosampleStatuses': async function () {
      this.showing = this.$store.state.showingResetProjectAndBiosampleStatuses;
    },
  },
};
</script>

  <style lang="scss" scoped>
  .p-inputgroup .p-float-label .p-dropdown {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .pi-check-circle {
    font-size: 4.5rem;
    color: #00b300;
  }

  .pi-times-circle {
    font-size: 4.5rem;
    color: #ff0000;
  }

  </style>
