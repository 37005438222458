<template>
  <Dialog v-model:visible="showing" :style="{ width: '400px' }" header="Export" :modal="true" class="p-fluid" :closable="false">
    <div class="field pt-5">
      <span class="p-float-label">
        <!-- <InputText id="orgNameId" type='text' v-model="organizationName" autofocus></InputText> -->
        <Dropdown id="sharedBucketsId" v-model="selectedSharedPath" :options="Object.keys(this.availableSharedPaths)" :filter="true" />
        <label for="sharedBucketsId">Shared path</label>
      </span>
    </div>
    <span>Please select the shared path.</span>
    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-secondary" @click="hideDialog" />
      <Button label="Export" class="p-button" @click="exportObject()" />
    </template>
  </Dialog>
</template>

<script>
// TODO: Expand. Right now only deletes the project entity in the database.

import { API, graphqlOperation } from 'aws-amplify';
import * as customQueries from '@/graphql/custom_queries';
import {
  sendEBMessage,
} from '@/utils';

export default {
  name: 'Export',
  props: ['project', 'pipeline'],
  data() {
    return {
      showing: false,
      selectedSharedPath: null,
      availableSharedPaths: {},
    };
  },
  methods: {
    hideDialog() {
      this.selectedSharedPath = null;
      this.$store.dispatch('setShowingExport', false);
    },
    async checkShowing() {
      if (this.$store.state.showingExport) {
        this.showing = true;
        await this.getAvailableSharedPaths();
      } else {
        this.showing = false;
      }
    },
    async getAvailableSharedPaths() {
      try {
        let allSharedPaths = null;
        if (this.project !== undefined) {
          allSharedPaths = await this.getSharedPathsFromProject(this.project);
        } else if (this.pipeline !== undefined) {
          // allSharedPaths = this.pipeline.project.workspace.sharedS3Paths;
          allSharedPaths = await this.getSharedPathsFromPipeline(this.pipeline);
        }
        if (allSharedPaths !== null) {
          // eslint-disable-next-line no-unused-vars
          allSharedPaths.forEach((sharedPath, index) => {
            this.availableSharedPaths[sharedPath.endsWith('/') ? sharedPath.split('/').at(-2) : sharedPath.split('/').at(-1)] = index;
          });
          this.selectedSharedPath = Object.keys(this.availableSharedPaths)[0];
        } else {
          console.error('All shared paths are null');
        }
      } catch (error) {
        console.error('Error in getting available shared paths');
        console.error(error);
      }
    },
    async getSharedPathsFromProject(project) {
      try {
        const response = await API.graphql(graphqlOperation(customQueries.getSharedS3PathsFromWorkspace, { id: project.workspaceId }));
        const allSharedPaths = response.data.getWorkspace.sharedS3Paths;
        return allSharedPaths;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async getSharedPathsFromPipeline(pipeline) {
      try {
        const response = await API.graphql(graphqlOperation(customQueries.getSharedS3PathsFromWorkspace, { id: pipeline.project.workspaceId }));
        const allSharedPaths = response.data.getWorkspace.sharedS3Paths;
        return allSharedPaths;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    exportObject() {
      if (this.project !== undefined && this.project !== null) {
        this.exportProject(this.project);
      } else if (this.pipeline !== undefined && this.pipeline !== null) {
        this.exportPipeline(this.pipeline);
      }
    },
    async exportProject(project) {
      try {
        const detail = {
          status: 'KickoffPipelines',
          pipeline: 'export_to_shared_s3',
          step: 'pipeline_trigger',
          application_environment: process.env.VUE_APP_SLS_STAGE,
          project_name: project.clientProjectName,
          project_name_uuid: project.id,
          workspace_name: project.workspace.description,
          organization_name: project.workspace.organization.organizationName,
          organization_uuid: project.workspace.organizationId,
          workspace_uuid: project.workspaceId,
          type_of_transfer: 'internalS3export',
          shared_s3_path_index: `${this.availableSharedPaths[this.selectedSharedPath]}`,
          unit_of_transfer: this.makeUnitsOfTransfer(project),
          number_of_biosamples: project.biosamples.items.length,
        };
        sendEBMessage(detail, detail.organization_uuid, detail.workspace_uuid, detail.project_name_uuid, 'export');
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Project export started successfully!',
          life: 3000,
        });
      } catch (error) {
        console.error('Error in exportProject');
        console.error(error);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Project export failed!',
          life: 3000,
        });
      }
      this.hideDialog();
    },
    makeUnitsOfTransfer(project) {
      try {
        const finalObj = [];
        finalObj.push({ type: 'project', uuid: project.id, name: project.clientProjectName });
        if (project.pipelines.items.length > 0) {
          project.pipelines.items.forEach((pipeline) => {
            if (pipeline.status === 'Job Complete' || pipeline.status === 'Job Transfer Complete') {
              finalObj.push({ type: 'pipeline', uuid: pipeline.id, name: pipeline.pipelineName });
            }
          });
        }
        console.log('finalObj :>> ', finalObj);
        // return JSON.stringify(finalObj);
        return finalObj;
      } catch (error) {
        console.error('Error in makeUnitsOfTransfer');
        console.error(error);
        return null;
      }
    },
    // eslint-disable-next-line no-unused-vars
    async exportPipeline(pipeline) {
      try {
        const detail = {
          status: 'KickoffPipelines',
          step: 'pipeline_trigger',
          application_environment: process.env.VUE_APP_SLS_STAGE,
          pipeline: 'export_to_shared_s3',
          project_name: pipeline.project.clientProjectName,
          project_name_uuid: pipeline.project.id,
          workspace_uuid: pipeline.project.workspaceId,
          workspace_name: pipeline.project.workspace.description,
          organization_uuid: pipeline.project.workspace.organizationId,
          organization_name: pipeline.project.workspace.organization.organizationName,
          type_of_transfer: 'internalS3export',
          shared_s3_path_index: this.availableSharedPaths[this.selectedSharedPath],
          unit_of_transfer: [{ type: 'pipeline', uuid: pipeline.id, name: pipeline.pipelineName }],
        };
        const passFailBiosamples = JSON.parse(pipeline.passFailBiosamples);
        detail.number_of_biosamples = Object.keys(passFailBiosamples).length;
        sendEBMessage(detail, detail.organization_uuid, detail.workspace_uuid, detail.project_name_uuid, 'export');
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Pipeline export started successfully!',
          life: 3000,
        });
      } catch (error) {
        console.error('Error in exportPipeline');
        console.error(error);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Pipeline export failed!',
          life: 3000,
        });
      }
      this.hideDialog();
    },
  },
  async mounted() {
    console.log('Export mounted');
    await this.checkShowing();
    // if (this.$store.state.newUserUsername !== null) {
    //   this.showing = true;
    // }
  },
  watch: {
    // project() {
    //   console.log('Project changed');
    //   console.log('project :>> ', this.project);
    // },
    // eslint-disable-next-line func-names
    '$store.state.showingExport': async function () {
      await this.checkShowing();
    },
  },
};
</script>
