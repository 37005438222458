<template>
  <div class="grid flex justify-content-evenly" v-if="!expandedBiosampleSelectionTable">
    <Card v-for="(pipeline, index) in formData.selectedTertiaryPipelines" v-bind:key="index">
      <template #content>
        <div class="pb-3">
          <h5>Pipeline name: {{ pipeline.pipelineName }}</h5>
          <h5>Pipeline version: {{ pipeline.pipelineVersion }}</h5>
        </div>
        <Button class="p-button-text mb-2 open-extended-biosample-selection-view-btn" label="Open expanded biosample selection view" @click="this.toggleExpandedBiosampleTable(pipeline.id)" />
        <div class="p-inputgroup">
          <Button v-if="pipeline.id in this.selectedBiosamples && this.selectedBiosamples[pipeline.id].length > 0" icon="pi pi-trash" class="p-button-info" @click="clearAllSelectedBiosamples(pipeline.id)" v-tooltip.left="'Clear'" />
          <AutoComplete
            :multiple="true"
            class="biosamplesAutoComp vh-100"
            v-model="selectedBiosamples[pipeline.id]"
            :suggestions="filteredBiosamplesObj[pipeline.id]"
            @complete="searchBiosamples($event, pipeline.id)"
            field="biosampleName"
            :placeholder="(pipeline.id in this.selectedBiosamples && this.selectedBiosamples[pipeline.id].length === 0) ? 'BioSample Name' : ''"
            :virtualScrollerOptions="{ itemSize: 31 }"
            dropdown
            forceSelection
          />
        </div>
      </template>
    </Card>
  </div>
  <div v-if="expandedBiosampleSelectionTable" class="card">
    <div>
      Number of total biosamples: {{ getArrLength(availableBiosamplesObj[extendedPipelineId]) }}
    </div>
    <div>
      Number of selected biosamples: {{ getArrLength(availableBiosamplesObj[extendedPipelineId]) }}
    </div>
    <DataTable
      :value="availableBiosamplesObj[extendedPipelineId]"
      v-model:selection="selectedBiosamples[extendedPipelineId]"
      dataKey="id"
      :paginator="true"
      :rows="rows"
      v-model:filters="filters"
      ref="dt"
      filterDisplay="row"
      :globalFilterFields="['biosampleName']"
      responsiveLayout="scroll"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      :rowsPerPageOptions="[5, 10, 20, 50]">
      <Column selectionMode="multiple" headerStyle="width: 3em" />
      <Column field="biosampleName" header="BioSample Name" :bodyStyle="getBodyStyle()" filterMatchMode="startsWith" ref="biosampleName" :sortable="true">
        <template #filter="{ filterModel, filterCallback }">
          <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="Search by Biosample Name" />
        </template>
        <template>
          <div class="flex align-items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
            <Skeleton width="60%" height="1rem" />
          </div>
        </template>
      </Column>
    </DataTable>
    <Button v-if="expandedBiosampleSelectionTable" class="p-button-secondary" label="Close selection" @click="toggleExpandedBiosampleTable()" />
  </div>
  <div class="flex justify-content-between pt-3">
    <Button class="mt-2 mb-2 p-button-raised p-button-secondary" label="Back" icon="pi pi-arrow-left" @click="back()" />
    <Button class="mt-2 mb-2 p-button-raised" label="Next" icon="pi pi-arrow-right" @click="nextPage" />
  </div>
</template>
<script>
export default {
  props: ['formData'],
  emits: ['prevPage', 'nextPage'],
  data() {
    return {
      selectedBiosamples: {},
      availableBiosamplesObj: {},
      filteredBiosamplesObj: {},
      pipelineBiosampleUseMap: {},
      expandedBiosampleSelectionTable: false,
      extendedPipelineId: null,
      rows: 5,
      filters: {
        biosampleName: { value: '', matchMode: 'contains' },
        sampleId: { value: '', matchMode: 'contains' },
      },
    };
  },
  methods: {
    back() {
      this.$emit('prevPage', { pageIndex: 3 });
    },
    nextPage() {
      const deleteTertiary = [];
      const emptyPipelines = [];
      this.formData.selectedTertiaryPipelines.forEach((pipeline) => {
        if (!(pipeline.id in this.selectedBiosamples)) {
          deleteTertiary.push(pipeline.id);
        } else if (this.selectedBiosamples[pipeline.id].length === 0) {
          emptyPipelines.push(pipeline.id);
          deleteTertiary.push(pipeline.id);
        }
      });
      if (deleteTertiary.length > 0) {
        this.$confirm.require({
          message: 'You have not selected biosamples for one or more pipelines, those pipelines will not be launched. Do you wish to proceed?',
          header: 'Confirmation',
          icon: 'pi pi-info-circle',
          accept: async () => {
            emptyPipelines.forEach((pipelineId) => {
              try {
                delete this.selectedBiosamples[pipelineId];
              } catch (error) {
                console.error('Could not delete pipeline from selectedTertiary');
                console.error(error);
              }
            });
            this.$emit('removeTertiary', { deleteTertiary });
            this.$emit('nextPage', { formData: { selectedBiosamples: this.selectedBiosamples }, pageIndex: 3 });
            if (this.dialogVisible) this.dialogVisible = false;
          },
          reject: () => {
            if (this.dialogVisible) this.dialogVisible = false;
          },
        });
      } else {
        this.$emit('nextPage', { formData: { selectedBiosamples: this.selectedBiosamples, pipelineBiosampleUseMap: this.pipelineBiosampleUseMap }, pageIndex: 3 });
      }
    },
    clearAllSelectedBiosamples(id) {
      this.selectedBiosamples[id] = [];
    },
    searchBiosamples(event, pipelineId) {
      const options = [];
      if (this.availableBiosamplesObj === undefined || this.availableBiosamplesObj === null || this.availableBiosamplesObj[pipelineId] === undefined || this.availableBiosamplesObj[pipelineId] === null) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Biosamples not yet loaded!',
          detail: 'The biosample loading is not finished yet.',
          life: 3000,
        });
        return;
      }
      try {
        for (let i = 0; i < this.availableBiosamplesObj[pipelineId].length; i += 1) {
          if ('biosampleName' in this.availableBiosamplesObj[pipelineId][i] && this.availableBiosamplesObj[pipelineId][i].biosampleName.includes(event.query)) {
            options.push(this.availableBiosamplesObj[pipelineId][i]);
          } else if ('data' in this.availableBiosamplesObj[pipelineId][i] && this.availableBiosamplesObj[pipelineId][i].data.createBSSHBiosample.biosampleName.includes(event.query)) {
            options.push(this.availableBiosamplesObj[pipelineId][i]);
          }
        }
      } catch (error) {
        console.error(error);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Searching biosamples failed!',
          life: 3000,
        });
      }
      this.filteredBiosamplesObj[pipelineId] = options;
    },
    toggleExpandedBiosampleTable(id = null) {
      // this.selectedBiosamples[id] = [];
      this.extendedPipelineId = id;
      this.expandedBiosampleSelectionTable = !this.expandedBiosampleSelectionTable;
    },
    getBodyStyle() {
      return 'text-align: center';
    },
    getArrLength(array) {
      if (array !== null && array !== undefined) {
        return array.length;
      }
      return 0;
    },
    /*
    {
      ${pipeline1Id}: [{id: "${biosample1Id}", biosampleName: "${biosample1Name}"}, {id: "${biosample2Id}", biosampleName: "${biosample2Name}"}]

    }
    */
    async mapBiosamples(biosamples) {
      const selectedFiles = this.formData?.selectedFilesObject;
      const pipelines = await this.formData.selectedTertiaryPipelines;
      const biosamplesObj = {};
      try {
        pipelines.forEach((pipeline) => {
          biosamplesObj[pipeline.id] = {};
          if (pipeline.id in selectedFiles) {
            biosamples.forEach((bs) => {
              if (bs.analysisFiles !== null && bs.analysisFiles !== undefined) {
                const bsAnalysisFilesObj = JSON.parse(bs.analysisFiles);
                Object.values(bsAnalysisFilesObj).forEach((o) => {
                  const exists = Object.keys(o.files).some((fileType) => selectedFiles[pipeline.id].includes(fileType));
                  if (exists && !(bs.id in biosamplesObj[pipeline.id])) {
                    biosamplesObj[pipeline.id][bs.id] = { id: bs.id, biosampleName: bs.biosampleName };
                  }
                });
              }
            });
          }
        });
        Object.keys(biosamplesObj).forEach((key) => {
          biosamplesObj[key] = Object.values(biosamplesObj[key]);
        });
        console.log('biosamplesObj :>> ', biosamplesObj);
      } catch (error) {
        console.error('Error in mapBiosamples');
        console.error(error);
      }
      this.availableBiosamplesObj = biosamplesObj;
    },
    async makeBiosamplesList() {
      try {
        const biosamples = await this.formData.biosamples;
        const secPipelines = await this.formData.selectedSecondaryPipelines;
        const usedBiosamples = {};
        secPipelines.forEach((pipeline) => {
          biosamples.forEach((bs) => {
            if (bs.analysisFiles !== null && bs.analysisFiles !== undefined) {
              const analysisFilesObj = JSON.parse(bs.analysisFiles);
              if (pipeline.id in analysisFilesObj) {
                if (!(pipeline.id in this.pipelineBiosampleUseMap)) this.pipelineBiosampleUseMap[pipeline.id] = [];
                this.pipelineBiosampleUseMap[pipeline.id].push(bs.id);
                if (!(bs.id in usedBiosamples)) {
                  usedBiosamples[bs.id] = bs;
                }
              }
            }
          });
        });
        return Object.values(usedBiosamples);
      } catch (error) {
        console.error('Error in making biosamples list');
        console.error(error);
        return [];
      }
    },
  },
  async mounted() {
    this.mapBiosamples(await this.makeBiosamplesList());
  },
  watch: {
    // eslint-disable-next-line func-names
    'formData.selectedFilesObject': async function () {
      this.mapBiosamples(await this.makeBiosamplesList());
    },
  },
};
</script>

<style lang="scss">
// .dark .p-button.p-button-text {
//   color: black;
// }
</style>
