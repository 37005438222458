<template>
  <div id="bg" class="bg card">
    <div class="field">
      <span class="">*This view can only be accessed by BioSkryb administrators</span>
    </div>
    <div class="field pt-3">
      <h4>BSSH biosample cache reset</h4>
      <span class="flex pb-2">There is a high chance of failure when clearing if there are many cached biosamples. In the case it fails, try again.</span>
      <Button
        label="Reset BSSH biosample cache"
        @click="openResetBSSHCache"
      />
    </div>
    <Divider />
    <div class="field pt-3">
      <h4>Look for BSSH Biosamples</h4>
      <span class="flex pb-2">In case a sample was uploaded to BSSH but BaseJumper cannot see it, use this to check if it is available through the BaseSpace API. In case you do not find it here, there is no way BaseJumper can see it.</span>
      <Button label="Search biosamples" @click="openLookForBSSHSamples" />
    </div>
    <Divider />
    <div class="field pt-3">
      <h4>Launchable Pipelines</h4>
      <span class="flex pb-2">Interface created in order to ease the manipulation of the LaunchablePipelines in BaseJumper.</span>
      <!-- <Button label="Search biosamples" @click="openLookForBSSHSamples"/> -->
      <div>
        <Button class="mr-5" label="Create" @click="openLaunchablePipelinesDialog('create')" />
        <Button class="mr-5" label="Update" @click="openLaunchablePipelinesDialog('update')" />
        <Button label="Delete" @click="openLaunchablePipelinesDialog('delete')" />
      </div>
    </div>
    <Divider />
    <div class="field pt-3">
      <h4 class="flex pb-2">Reset project and biosample statuses</h4>
      <Button class="mr-5" label="Open reset project and biosample statuses" @click="openResetProjectAndBiosampleStatuses()" />
    </div>
    <Divider />
    <div class="field pt-3">
      <h4 class="flex pb-2">Export All Credit Logs</h4>
      <Button class="mr-5" label="Export" @click="exportCreditsData()" :loading="exporting" />
    </div>
    <Divider />
    <!-- <div class="field pt-3">
      <h4 class="flex pb-2">Fix specific groups. DO NOT TOUCH!</h4>
      <Button class="mr-5" label="Fix" @click="openFixOrgGroupsDialog()" />
    </div>
    <Divider /> -->
    <!-- <div class="field pt-3">
      <h4 class="flex pb-2">Fix bad workspace groups</h4>
      <Button class="mr-5" label="Fix workspaces" @click="fixBadWorkspaceGroups()" />
    </div> -->
    <!-- <Divider /> -->
    <!-- <div class="field pt-3">
      <h4 class="flex pb-2">DO NOT TOUCH!</h4>
      <Button class="mr-5" label="Create Batch Test Biosamples" @click="batchCreateBiosamples()" />
      <Button class="mr-5" label="Delete Test Biosamples" @click="batchDeleteBiosamples()" />
    </div> -->
    <!-- <div class="field pt-3">
      <h4 class="flex pb-2">DO NOT TOUCH!</h4>
      <Button class="mr-5" label="updateAllTransactionBillStartTimes" @click="exportCreditsData()" />
    </div> -->
    <!-- <Divider/>
    <div class="field pt-3">
      <h4 class="flex pb-2">Change transaction status</h4>
      <Button class="mr-5" label="Fix" @click="changeTransactionStatus()"></Button>
    </div> -->
    <DataTable
      id="credit-export-dt"
      ref="creditExportDt"
      dataKey="id"
      :value="creditDataForExport"
      :exportFilename="getCreditExportFileName"
      style="display: none;"
    >
      <Column header="Created" field="created" />
      <Column header="Billing Start" field="billingStart" />
      <Column header="Amount in Credits" field="amount" />
      <Column header="Credit Pack Name" field="creditPackName" />
      <Column header="Creditor Org BJ Id" field="creditorOrg.id" />
      <Column header="Creditor Org BJ" field="creditorOrg.organizationName" />
      <Column header="Debtor Org BJ Id" field="debitorOrg.id" />
      <Column header="Debtor Org BJ" field="debitorOrg.organizationName" />
      <Column header="Expiration In Months" field="expirationInMonths" />
      <Column header="Is Spent" field="isSpent" />
      <Column header="Workspace BJ ID" field="workspace.id" />
      <Column header="Workspace Name" field="workspace.description" />
      <Column header="Type" field="type" />
      <Column header="Times Pack Purchased" field="timesPackPurchased" />
      <Column header="User" field="userName" />
      <Column header="Pipeline BJ ID" field="pipeline.id" />
      <Column header="Pipeline Name" field="pipeline.pipelineName" />
      <Column header="Project BJ ID" field="project.id" />
      <Column header="Project Name" field="project.clientProjectName" />
    </DataTable>
  </div>
  <ResetBSSHCache />
  <LookForBSSHSamples />
  <LaunchablePipelineDialog :operation="operation" />
  <FixOrgGroups />
  <ResetProjectAndBiosampleStatuses />
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { Auth, API, graphqlOperation } from 'aws-amplify';
import ResetBSSHCache from '@/components/Admin/ResetBSSHCache.vue';
import LookForBSSHSamples from '@/components/Admin/LookForBSSHSamples.vue';
import LaunchablePipelineDialog from '@/components/Admin/LaunchablePipelineDialog.vue';
import FixOrgGroups from '@/components/Admin/FixOrgGroups.vue';
import ResetProjectAndBiosampleStatuses from '@/components/Admin/ResetProjectAndBiosampleStatuses.vue';
import { listItems, getTodayDateOnlyNormal, wait } from '../../utils';
import * as queries from '@/graphql/queries';
// eslint-disable-next-line no-unused-vars
import * as mutations from '@/graphql/mutations';
import * as customQueries from '@/graphql/custom_queries';

export default {
  components: {
    ResetBSSHCache,
    LookForBSSHSamples,
    LaunchablePipelineDialog,
    FixOrgGroups,
    ResetProjectAndBiosampleStatuses,
  },
  data() {
    return {
      isMe: false,
      operation: null,
      creditDataForExport: [],
      exporting: false,
    };
  },
  async mounted() {
    Auth.currentAuthenticatedUser().then((user) => {
      if (user.attributes.email.startsWith('filip.gunic')) {
        this.isMe = true;
      }
    });
  },
  methods: {
    async fixBadWorkspaceGroups() {
      try {
        console.log('FIXING');
        const workspaces = await listItems(queries.listWorkspaces, {});
        console.log('workspaces :>> ', workspaces);
        for (let i = 0; i <= workspaces.length; i += 1) {
          const workspace = workspaces[i];
          // console.log('workspace :>> ', workspace);
          if (workspace === null || workspace === undefined) {
            console.log('workspace with null:>> ', workspace);
            // eslint-disable-next-line no-continue
            continue;
          }
          if (workspace.organizationId === null || workspace.organizationId === undefined) {
            console.log('workspace with orgId null:>> ', workspace);
            // eslint-disable-next-line no-continue
            continue;
          }
          // eslint-disable-next-line no-continue
          // if (workspace.organizationId === 'a34ea85b-ba79-49b9-a32e-a824f1cbea14') continue;
          const indexOfInReadGroupsUser = workspace.readGroups.indexOf(`WS/${workspace.id}/User`);
          const indexOfInWriteGroupsAdmin = workspace.writeGroups.indexOf(`WS/${workspace.id}/Admin`);
          const indexOfInAdminGroupsAdmin = workspace.adminGroups.indexOf(`WS/${workspace.id}/Admin`);
          console.log('indexOfInReadGroupsUser :>> ', indexOfInReadGroupsUser);
          console.log('indexOfInWriteGroupsAdmin :>> ', indexOfInWriteGroupsAdmin);
          console.log('indexOfInAdminGroupsAdmin :>> ', indexOfInAdminGroupsAdmin);
          const input = {
            id: workspace.id,
          };
          if (indexOfInReadGroupsUser === -1) {
            const readGroups = JSON.parse(JSON.stringify(workspace.readGroups));
            readGroups[0] = `WS/${workspace.id}/User`;
            input.readGroups = readGroups;
          }
          if (indexOfInWriteGroupsAdmin === -1) {
            const writeGroups = JSON.parse(JSON.stringify(workspace.writeGroups));
            writeGroups[0] = `WS/${workspace.id}/Admin`;
            input.writeGroups = writeGroups;
          }
          if (indexOfInAdminGroupsAdmin === -1) {
            const adminGroups = JSON.parse(JSON.stringify(workspace.adminGroups));
            adminGroups[0] = `WS/${workspace.id}/Admin`;
            input.adminGroups = adminGroups;
          }
          console.log('workspace :>> ', workspace);
          console.log('input :>> ', input);
          if (Object.keys(input).length > 1) {
            console.log('UPDATING WS');
            await API.graphql(graphqlOperation(mutations.updateWorkspace, {
              input,
            }));
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async batchCreateBiosamples() {
      try {
        const biosampleVariablesArr = [
          {
            id: 1,
            // projectId: '94a9f0c3-5d9a-49e4-9311-a31face1883f',
            // projectBiosamplesId: '94a9f0c3-5d9a-49e4-9311-a31face1883f',
            projectId: '50cefd07-2b56-45f3-8322-de811c88cd7f',
            projectBiosamplesId: '50cefd07-2b56-45f3-8322-de811c88cd7f',
            biosampleName: 'TestBiosample1',
          },
          {
            id: 2,
            // projectId: '94a9f0c3-5d9a-49e4-9311-a31face1883f',
            // projectBiosamplesId: '94a9f0c3-5d9a-49e4-9311-a31face1883f',
            projectId: '50cefd07-2b56-45f3-8322-de811c88cd7f',
            projectBiosamplesId: '50cefd07-2b56-45f3-8322-de811c88cd7f',
            biosampleName: 'TestBiosample2',
          },
        ];
        const res = await API.graphql(graphqlOperation(mutations.createBatchBiosamples, { input: biosampleVariablesArr }));
        console.log('res :>> ', res);
      } catch (error) {
        console.error(error);
      }
    },
    async batchDeleteBiosamples() {
      try {
        await Promise.all([API.graphql(graphqlOperation(mutations.deleteBiosample, { input: { id: 1 } })),
          API.graphql(graphqlOperation(mutations.deleteBiosample, { input: { id: 2 } }))]);
        console.log('Done deleting biosamples');
      } catch (error) {
        console.error(error);
      }
    },
    async changeTransactionStatus() {
      try {
        console.log('Changing all transaction types');
        const allTransactions = await listItems(queries.listCreditTransactionLogs, {});
        console.log('allTransactions :>> ', allTransactions);
        const bioskrybId = 'a34ea85b-ba79-49b9-a32e-a824f1cbea14';
        for (const transaction of allTransactions) {
          console.log('transaction :>> ', transaction);
          console.log('transaction.type :>> ', transaction.type);
          let expectedType = null;
          if (transaction.type !== 'Storage') {
            if (transaction.type === 'CreditPurchase') {
              // total += transaction.amount;
              console.log('Type should be CreditPurchase');
              expectedType = 'CreditPurchase';
            } else if ((transaction.creditor === transaction.debitor) && (transaction.debitor === bioskrybId)) {
              // total -= transaction.amount;
              // creditsUsed += transaction.amount;
              console.log('Type should be CreditPurchase');
              expectedType = 'CreditPurchase';
            } else if (transaction.debitor === bioskrybId) {
              // total -= transaction.amount;
              // creditsUsed += transaction.amount;
              console.log('Type should be Pipeline');
              expectedType = 'Pipeline';
            } else if (transaction.creditor === bioskrybId) {
              // total += transaction.amount;
              console.log('Type should be CreditPurchase different!!!');
              expectedType = 'CreditPurchase';
            }
          } else if (transaction.type === 'Storage') {
            // total -= transaction.amount;
            // creditsUsed += transaction.amount;
            console.log('Type should be Storage');
            expectedType = 'Storage';
          }
          console.log('expectedType :>> ', expectedType);
          if (transaction.project !== null && transaction.project !== undefined && transaction.type !== 'Pipeline') {
            console.log('transaction :>> ', transaction);
            console.log('Problem');
            await API.graphql(graphqlOperation(mutations.updateCreditTransactionLog, {
              input: {
                id: transaction.id,
                type: 'Pipeline',
              },
            }));
          }
          if (expectedType !== transaction.type) {
            console.log('NOT SAME');
            console.log('transaction :>> ', transaction);
            console.log('transaction.type :>> ', transaction.type);
            console.log('expectedType :>> ', expectedType);
            // if (expectedType === 'CreditPurchase') {
            //   await API.graphql(graphqlOperation(mutations.updateCreditTransactionLog, {
            //     input: {
            //       id: transaction.id,
            //       type: 'CreditPurchase',
            //     },
            //   }));
            // } else if (expectedType === 'Pipeline') {
            //   await API.graphql(graphqlOperation(mutations.updateCreditTransactionLog, {
            //     input: {
            //       id: transaction.id,
            //       type: 'Pipeline',
            //     },
            //   }));
            // }
            // if (transaction.type === 'Addition' && expectedType === 'CreditPurchase') {
            //   await API.graphql(graphqlOperation(mutations.updateCreditTransactionLog, {
            //     input: {
            //       id: transaction.id,
            //       type: 'CreditPurchase',
            //     },
            //   }));
            // }
            // await API.graphql(graphqlOperation(mutations.deleteCreditTransactionLog, {
            //   input: {
            //     id: transaction.id,
            //   },
            // }));
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async updateAllTransactionBillStartTimes() {
      try {
        console.log('Pulling transactions...');
        const allTransactions = await listItems(queries.listCreditTransactionLogs, { limit: 500 });
        console.log('allTransactions :>> ', allTransactions);
        const creditPurchases = allTransactions.filter((transaction) => transaction.type === 'CreditPurchase');
        console.log('creditPurchases :>> ', creditPurchases);
        // creditPurchases.forEach((cp) => {
        //   API.graphql(graphqlOperation(mutations.updateCreditTransactionLog, {
        //     input: {
        //       id: cp.id,
        //       billingStart: cp.created,
        //     },
        //   }));
        // });
      } catch (error) {
        console.error(error);
      }
    },
    async exportCreditsData() {
      try {
        this.exporting = true;
        this.creditDataForExport = await this.getAllTransactionsForExport();
        console.log('this.creditDataForExport :>> ', this.creditDataForExport);
        await wait(5000);
        this.$refs.creditExportDt.exportCSV();
        this.exporting = false;
      } catch (error) {
        console.error(error);
        this.exporting = false;
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Exporting Failed',
          life: 5000,
        });
      }
    },
    async getAllTransactionsForExport() {
      try {
        return await listItems(customQueries.listCreditTransactionLogsForAdminDownloadLogs, { limit: 500 });
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    openResetBSSHCache() {
      this.$store.dispatch('setShowingResetBSSHBiosampleCache', true);
    },
    openLookForBSSHSamples() {
      this.$store.dispatch('setShowingLookForBSSHBiosamples', true);
    },
    openLaunchablePipelinesDialog(operation) {
      this.operation = operation;
      this.$store.dispatch('setShowingLaunchablePipelineCRUDDialog', true);
    },
    openFixOrgGroupsDialog() {
      this.$store.dispatch('setShowingFixOrgGroupsDialog', true);
    },
    openResetProjectAndBiosampleStatuses() {
      this.$store.dispatch('setShowingResetProjectAndBiosampleStatuses', true);
    },
  },
  computed: {
    getCreditExportFileName() {
      return `CreditLogs_${getTodayDateOnlyNormal()}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.bg{
    background-size: cover;
    position: relative;
    height: 100%;
    opacity: 0.8;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
</style>
