/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import { createRouter, createWebHashHistory } from 'vue-router';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import * as queries from '@/graphql/queries';
// eslint-disable-next-line import/extensions
import store from '@/store/index.js';
import AuthLayout from '../views/Layout/AuthLayout.vue';
import MainLayout from '../views/Layout/MainLayout.vue';
import AdminLayout from '../views/Layout/AdminLayout.vue';
import Login from '../views/Auth/Login.vue';
import Projects from '../views/Main/Projects.vue';
import CreditUsage from '../views/Main/CreditUsage.vue';
import Settings from '../views/Main/Settings.vue';
import Organizations from '../views/Admin/Organizations.vue';
import Organization from '../views/Admin/Organization.vue';
import Workspaces from '../views/Admin/Organization/Workspaces.vue';
import CreditTransactions from '../views/Admin/Organization/CreditTransactions.vue';
import Project from '../views/Project/Project.vue';
import Pipelines from '../views/Project/Pipelines.vue';
import Biosamples from '../views/Project/BiosamplesPageWrapper.vue';
import PipelineResultTabs from '../views/Project/PipelineResultTabs.vue';
import Help from '../views/Help/Help.vue';
import TermsAndConditions from '../views/Help/TermsAndConditions.vue';
import Users from '../views/Admin/Users.vue';
import NoGroupNotice from '../views/Auth/NoGroupNotice.vue';
import PageNotFoundComponent from '../views/Auth/PageNotFoundComponent.vue';
import AdminView from '../views/Admin/AdminView.vue';
import StepsMain from '../components/Pipeline/TertiaryAnalysis/StepsMain.vue';
import SecondarySelection from '../components/Pipeline/TertiaryAnalysis/SecondarySelection.vue';
import TertiarySelection from '../components/Pipeline/TertiaryAnalysis/TertiarySelection.vue';
import FileTypeSelection from '../components/Pipeline/TertiaryAnalysis/FileTypeSelection.vue';
import BiosampleSelection from '../components/Pipeline/TertiaryAnalysis/BiosampleSelection.vue';
import ParameterSelection from '../components/Pipeline/TertiaryAnalysis/ParameterSelection.vue';
import Check from '../components/Pipeline/TertiaryAnalysis/Check.vue';
import Credits from '../views/Credits/Transactions.vue';
import MaintenanceView from '@/views/Admin/MaintenanceView.vue';
import VisualizationWrapper from '@/components/Visualization/VisualizationWrapper.vue';

// Old
const AuthFilter = (to, from, next) => {
  Auth.currentAuthenticatedUser().then((user) => {
    const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
    const response = API.graphql(graphqlOperation(queries.getUser, { id: user.username })).then((gqlUser) => {
      if (gqlUser.data.getUser === null || gqlUser.data.getUser === undefined || gqlUser.data.getUser.organization === null) {
        store.dispatch('setNewUserUsername', user.username);
        store.dispatch('setShowingAddCustomOrg', true);
      } else if (!gqlUser.data.getUser.tAndCAccepted) {
        store.dispatch('setNewUserUsername', user.username);
        store.dispatch('setShowingAcceptTAndC', true);
      }
    });
    let isAdmin = false;
    if (!localStorage.getItem('noGrpEmailSent')) {
      localStorage.setItem('noGrpEmailSent', true);
    }
    if (store.state.bioskrybProduct === null && to.path === '/projects') {
      store.dispatch('setBioskrybProduct', 'ResolveDNA');
    }
    try {
      isAdmin = groups.indexOf('BaseJumperAdmin') > -1;
      if (localStorage.getItem('noGrpEmailSent')) {
        localStorage.removeItem('noGrpEmailSent');
      }
    } catch (error) {
      console.log('Problem with checking admin rights!!!');
    }
    if (store.state.paymentSystemDisabled && to.path.startsWith('/credits')) {
      next('/');
      return;
    }
    if (store.state.paymentSystemDisabled && to.path.endsWith('/transactions')) {
      next('/');
      return;
    }
    if (to.path.startsWith('/tertiaryAnalysis/') && !store.state.runningTertiaryAnalysis) {
      store.dispatch('setRunningTertiaryAnalysis', true);
    }
    if ((process.env.VUE_APP_MAINTENANCE === 'true' || process.env.VUE_APP_MAINTENANCE === true) && !isAdmin) {
      if (!to.path.startsWith('/maintenance')) {
        next('/maintenance');
      } else {
        next();
        return;
      }
    }
    if (to.path.startsWith('/admin')) {
      if (isAdmin) {
        next();
      } else {
        next();
        // next('/projects');
      }
    } else {
      next();
    }
  }).catch((err) => {
    console.log(err);
    if (!to.path.startsWith('/auth')) {
      next('/auth');
    } else {
      console.log('In else');
      next();
    }
  });
};

// const AuthFilter = async (to, from, next) => {
//   try {
//     const user = await Auth.currentAuthenticatedUser();
//     const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
//     console.log('user :>> ', user);
//     const response = await API.graphql(graphqlOperation(queries.getUser, { id: user.username }));
//     const gqlUser = response.data.getUser;
//     if (gqlUser === null) {
//       store.dispatch('setNewUserUsername', user.username);
//     }
//     let isAdmin = false;
//     if (!localStorage.getItem('noGrpEmailSent')) {
//       localStorage.setItem('noGrpEmailSent', true);
//     }
//     if (store.state.bioskrybProduct === null && to.path === '/projects') {
//       store.dispatch('setBioskrybProduct', 'ResolveDNA');
//     }
//     try {
//       isAdmin = groups.indexOf('BaseJumperAdmin') > -1;
//       if (localStorage.getItem('noGrpEmailSent')) {
//         localStorage.removeItem('noGrpEmailSent');
//       }
//     } catch (error) {
//       console.log('Problem with checking admin rights!!!');
//     }
//     if (to.path.startsWith('/admin')) {
//       if (isAdmin) {
//         next();
//       } else {
//         next();
//         // next('/projects');
//       }
//     } else {
//       next();
//     }
//   } catch (err) {
//     console.log(err);
//     if (!to.path.startsWith('/auth')) {
//       next('/auth');
//     } else {
//       console.log('In else');
//       next();
//     }
//   }
// };

const routes = [
  {
    path: '/auth',
    name: 'Auth',
    component: AuthLayout,
    children: [
      {
        path: '',
        redirect: '/auth/login',
      },
      {
        path: 'login',
        component: Login,
      },
    ],
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: MaintenanceView,
  },
  {
    path: '/',
    name: 'MainLayout',
    component: MainLayout,
    children: [
      {
        path: '',
        redirect: '/workspace/:workspaceId/projects',
      },
      {
        path: '/workspace/:workspaceId/projects',
        component: Projects,
        meta: {
          title: 'Projects',
        },
        children: [
          {
            path: '',
            redirect: { name: 'DNA' },
          },
          {
            name: 'DNA',
            path: 'DNA',
            component: Projects,
            meta: {
              title: 'ResolveDNA',
            },
          },
          {
            name: 'OME',
            path: 'OME',
            component: Projects,
            meta: {
              title: 'ResolveOME',
            },
          },
        ],
      },
      {
        path: '/workspace/:workspaceId/visualize/:vizDataToken*',
        component: VisualizationWrapper,
        meta: {
          title: 'Visualize',
        },
      },
      {
        path: '/workspace/:workspaceId/project/:id',
        component: Project,
        children: [
          {
            path: '',
            redirect: { name: 'Biosamples' },
          },
          {
            name: 'Biosamples',
            path: 'biosamples',
            component: Biosamples,
            meta: {
              title: 'Biosamples',
            },
          },
          {
            name: 'Pipelines',
            path: 'pipelines/:analysisLevel',
            component: Pipelines,
            meta: {
              title: 'Pipelines',
            },
          },
        ],
      },
      {
        name: 'Launch Tertiary Analysis',
        path: '/workspace/:workspaceId/tertiaryAnalysis/:id',
        component: StepsMain,
        meta: {
          title: 'Launch Tertiary Analysis',
        },
        children: [
          {
            path: '',
            redirect: (to) => {
              const { workspaceId, id } = to.params;
              return {
                name: 'Secondary Selection',
                params: { workspaceId, id },
              };
            },
          },
          {
            name: 'Secondary Selection',
            path: 'secondarySelection',
            component: SecondarySelection,
            meta: {
              title: 'Secondary Selection',
            },
          },
          {
            name: 'Tertiary Selection',
            path: 'tertiarySelection',
            component: TertiarySelection,
            meta: {
              title: 'Tertiary Selection',
            },
          },
          {
            name: 'File Type Selection',
            path: 'filetypeselection',
            component: FileTypeSelection,
            meta: {
              title: 'File Type Selection',
            },
          },
          // BiosampleSelection
          {
            name: 'Biosample Selection',
            path: 'biosampleselection',
            component: BiosampleSelection,
            meta: {
              title: 'Biosample Selection',
            },
          },
          {
            name: 'Parameter Selection',
            path: 'parameterselection',
            component: ParameterSelection,
            meta: {
              title: 'Parameter Selection',
            },
          },
          {
            name: 'Check',
            path: 'check',
            component: Check,
            meta: {
              title: 'Check',
            },
          },
        ],
      },
      {
        name: 'Pipeline',
        path: '/workspace/:workspaceId/pipeline/:id',
        component: PipelineResultTabs,
        meta: {
          title: 'Pipeline Results',
        },
      },
      {
        path: '/workspace/:workspaceId/credits',
        component: CreditUsage,
        meta: {
          title: 'CreditsUsage',
        },
        children: [
          {
            path: '',
            redirect: (to) => {
              const { workspaceId } = to.params;
              return `/workspace/${workspaceId}/credits/transactions`;
            },
          },
          {
            path: 'transactions',
            component: Credits,
            props: { transactionCredits: true },
            meta: {
              title: 'credits',
            },
          },
        ],
      },
      {
        path: 'settings',
        component: Settings,
        meta: {
          title: 'Settings',
        },
      },
      {
        path: '/admin',
        name: 'AdminLayout',
        component: AdminLayout,
        children: [
          {
            path: '',
            redirect: '/admin/organizations',
          },
          {
            path: 'users',
            component: Users,
            meta: {
              title: 'Users',
            },
          },
          {
            path: 'organizations',
            component: Organizations,
            meta: {
              title: 'Organizations',
            },
          },
          {
            path: 'adminView',
            component: AdminView,
            meta: {
              title: 'Admin View',
            },
          },
          {
            path: 'organization/:id',
            component: Organization,
            children: [
              {
                path: '',
                redirect: { name: 'Workspaces' },
              },
              {
                name: 'Workspaces',
                path: 'workspaces',
                component: Workspaces,
                meta: {
                  title: 'Workspaces',
                },
              },
              {
                name: 'Workspace Users',
                path: 'workspace/:wsId/users',
                component: Users,
                meta: {
                  title: 'Workspace Users',
                },
              },
              {
                name: 'Organization Users',
                path: 'users',
                component: Users,
                meta: {
                  title: 'Organization Users',
                },
              },
              {
                name: 'Transactions',
                path: 'transactions',
                component: CreditTransactions,
                meta: {
                  title: 'Transactions',
                },
              },
            ],
          },
        ],
      },
      {
        name: 'Help',
        path: '/help',
        component: Help,
        meta: {
          title: 'Help',
        },
      },
      {
        name: 'TermsAndConditions',
        path: '/terms-and-conditions',
        component: TermsAndConditions,
        meta: {
          title: 'TermsAndConditions',
        },
      },
      {
        name: 'NoGroupNotice',
        path: '/noGrp',
        component: NoGroupNotice,
        meta: {
          title: 'NoGroup',
        },
      },
      {
        name: 'PageNotFoundComponent',
        path: '/:pathMatch(.*)*',
        component: PageNotFoundComponent,
        meta: {
          title: 'PageNotFound',
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(AuthFilter);

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Home';
  next();
});

router.afterEach((to, from) => {
  if (!router.app || !router.app.$gtag) {
    return;
  }

  router.app.$gtag.pageview({
    page_title: to.meta.title,
    page_path: to.fullPath,
    page_location: window.location.href,
  });
});

export default router;
