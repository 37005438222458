import { createApp } from 'vue';
import { applyPolyfills, defineCustomElements } from '@aws-amplify/ui-components/loader';

import { Amplify } from 'aws-amplify';
import AmplifyVue from '@aws-amplify/ui-vue';
import awsconfig from './aws-exports';

import '@aws-amplify/ui-vue/styles.css';
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'prismjs/themes/prism-coy.css';
import './assets/styles/layout.scss';

import App from './App.vue';
// import './registerServiceWorker';
import router from './router';
import VueGtag from 'vue-gtag';
import store from './store';
import moment from 'moment';
import humanizeDuration from 'humanize-duration';

import PrimeVue from 'primevue/config';
import StyleClass from 'primevue/styleclass';
import Menu from 'primevue/menu';
import Dropdown from 'primevue/dropdown';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import AutoComplete from 'primevue/autocomplete';
import Textarea from 'primevue/textarea';
import Tag from 'primevue/tag';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import TabMenu from 'primevue/tabmenu';
import Breadcrumb from 'primevue/breadcrumb';
import ProgressSpinner from 'primevue/progressspinner';
import Message from 'primevue/message';
import InlineMessage from 'primevue/inlinemessage';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Image from 'primevue/image';
import Listbox from 'primevue/listbox';
import Chips from 'primevue/chips';
import Tooltip from 'primevue/tooltip';
import ProgressBar from 'primevue/progressbar';
import Skeleton from 'primevue/skeleton';
import Inplace from 'primevue/inplace';
import Card from 'primevue/card';
import Password from 'primevue/password';
import RadioButton from 'primevue/radiobutton';
import ScrollPanel from 'primevue/scrollpanel';
import Tree from 'primevue/tree';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmationService from 'primevue/confirmationservice';
import SelectButton from 'primevue/selectbutton';
import Divider from 'primevue/divider';
import VueCryptojs from 'vue-cryptojs';
import Steps from 'primevue/steps';
import MultiSelect from 'primevue/multiselect';
import Panel from 'primevue/panel';
import Calendar from 'primevue/calendar';
import FileUpload from 'primevue/fileupload';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import SpeedDial from 'primevue/speeddial';
import Slider from 'primevue/slider';

import DraggableResizableVue from "draggable-resizable-vue3";

const redirect_url = `${window.location.protocol}//${window.location.hostname}/`
const updatedAwsConfig = {
  ...awsconfig,
  oauth: {
    ...awsconfig.oauth,
    redirectSignIn: awsconfig.oauth.redirectSignIn.split(",").includes(redirect_url) ? redirect_url : 'https://basejumper.bioskryb.com/',
    redirectSignOut: awsconfig.oauth.redirectSignIn.split(",").includes(redirect_url) ? redirect_url : 'https://basejumper.bioskryb.com/',
  }
}

Amplify.configure(updatedAwsConfig);

applyPolyfills().then(() => {
  defineCustomElements(window);
});

const app = createApp(App);

app.config.globalProperties.$filters = {
  formatDate(date) {
    return moment(date).format('MM/DD/YYYY');
  },
  formatTime(date) {
    return moment(date).format('MM/DD/YYYY hh:mm:ss a');
  },
  diffTime(date1, date2) {
    const duration = moment(date1).diff(date2);
    const shortEnglishHumanizer = humanizeDuration.humanizer({
      language: "shortEn",
      languages: {
        shortEn: {
          y: () => "y",
          mo: () => "mo",
          w: () => "w",
          d: () => "d",
          h: () => "hr",
          m: () => "m",
          s: () => "s",
          ms: () => "ms",
        },
      },
    });
    return shortEnglishHumanizer(duration, {
      delimiter: ' ',
      largest: 3,
      round: true,

    });
  }
};

app.use(PrimeVue, { ripple: true, inputStyle: 'outlined' });
app.use(store);

app.use(VueGtag, {
  config: { id: 'G-4B101QJXJR' },
}, router);

app.use(router);
app.use(AmplifyVue);
app.use(ConfirmationService);
app.use(VueCryptojs);
app.use(BadgeDirective);
app.use(DraggableResizableVue);

app.directive('styleclass', StyleClass);
app.directive('tooltip', Tooltip);

app.component('Menu', Menu);
app.component('Badge', Badge);
app.component('Dropdown', Dropdown);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Button', Button);
app.component('Checkbox', Checkbox);
app.component('Dialog', Dialog);
app.component('InputText', InputText);
app.component('InputSwitch', InputSwitch);
app.component('AutoComplete', AutoComplete);
app.component('Tag', Tag);
app.component('Textarea', Textarea);
app.component('Toast', Toast);
app.component('TabMenu', TabMenu);
app.component('Breadcrumb', Breadcrumb);
app.component('InputNumber', InputNumber);
app.component('ProgressSpinner', ProgressSpinner);
app.component('Message', Message);
app.component('InlineMessage', InlineMessage);
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('Image', Image);
app.component('Listbox', Listbox);
app.component('Chips', Chips);
app.component('ProgressBar', ProgressBar);
app.component('Skeleton', Skeleton);
app.component('Inplace', Inplace);
app.component('Card', Card);
app.component('Password', Password);
app.component('RadioButton', RadioButton);
app.component('ScrollPanel', ScrollPanel);
app.component('FileUpload', FileUpload);
app.component('Tree', Tree);
app.component('ConfirmDialog', ConfirmDialog);
app.component('SelectButton', SelectButton);
app.component('Divider', Divider);
app.component('Steps', Steps);
app.component('MultiSelect', MultiSelect);
app.component('Panel', Panel);
app.component('Calendar', Calendar);
app.component('SpeedDial', SpeedDial);
app.component('Slider', Slider);


app.use(ToastService);

app.mount('#app');
