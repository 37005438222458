<template>
  <div>
    Settings
  </div>
</template>

<script>
// TODO
export default {
  data() {
    return {
      loading: false,
      projects: [],
      users: [],
      limit: 50,
    };
  },
};
</script>
