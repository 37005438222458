/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable max-classes-per-file */
import {
  inferField, getSize, parseDateAndTime, valueIsNullOrUndefined, addZeroToDayInISODateStandardString, isValueNA, textColumnTypeChecks, numericColumnTypeChecks, booleanColumnTypeChecks, dateColumnTypeChecks, isDateValid, formatSizeToSpecificType, getSizeIndex,
} from '@/utils';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import moment from 'moment';

export function getDataFromBiosample(inferredField, biosample) {
  try {
    let res = null;
    if (inferredField in biosample) res = biosample[inferredField];
    else if (valueIsNullOrUndefined(biosample.metadata)) res = null;
    else if (inferredField in biosample.metadata) res = biosample.metadata[inferredField];
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

function getTotalNumberOfReadsFromBiosample(biosample) {
  let total = 0;
  try {
    if (biosample.r1FastqTotalReads !== null && biosample.r1FastqTotalReads !== undefined) {
      total += biosample.r1FastqTotalReads;
    }
    if (biosample.r2FastqTotalReads !== null && biosample.r2FastqTotalReads !== undefined) {
      total += biosample.r2FastqTotalReads;
    }
  } catch (error) {
    console.error(error);
  }
  return total;
}

// eslint-disable-next-line prefer-const, import/no-mutable-exports
export let sortKey = '';
// Template method. Meant to be overridden
export function getKey() { return ''; }
export function setSortKey(key) { sortKey = key; }

function statusClass(status) {
  if (status !== null && status !== undefined) {
    return status.toLowerCase().replace(' ', '-');
  }
  return '';
}

function extractDataForSort(biosample1, biosample2, key) {
  const a = biosample1[key] || biosample1.metadata[key];
  const b = biosample2[key] || biosample2.metadata[key];
  return [a, b];
}

class Text {
  constructor({ name, description, editable }) {
    this.name = name;
    this.inferredName = inferField({ name });
    this.description = description;
    this.editable = editable;
    this.dataType = 'text';

    this.getFormattedOutput = function getFormattedOutput(data) {
      return getDataFromBiosample(this.inferredName, data);
    };
    this.getExportOutput = function getExportOutput(data) {
      return getDataFromBiosample(this.inferredName, data);
    };
    this.getFilterValues = function getFilterValues() {
      return { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] };
    };
    this.formatValueForImport = function formatValueForImport(value) {
      return value;
    };
    this.formatValueForFilterQuery = function formatValueForFilterQuery(value) {
      return `\"${this.inferredName}\":\"${value}`;
    };
    this.checkIfValueIsGood = function checkIfValueIsGood(value) {
      return true;
    };
    this.getComponentName = function getComponentName() {
      return 'InputText';
    };
    this.getClass = function getClass(data) {
      return '';
    };
    this.checkCorrectDataType = function checkCorrectDataType(data) {
      if (valueIsNullOrUndefined(data)) return false;
      return typeof (data) === 'string';
    };
    this.customCompare = function customCompare(biosample1, biosample2) {
      const [a, b] = extractDataForSort(biosample1, biosample2, sortKey);
      if (valueIsNullOrUndefined(a)) return 1;
      if (valueIsNullOrUndefined(b)) return -1;
      return a.localeCompare(b, 'en');
    };
    this.compareForFilter = function compareForFilter(matchMode, biosample, valueFromFilter) {
      const data = getDataFromBiosample(this.inferredName, biosample);
      if (valueIsNullOrUndefined(data)) return false;
      return textColumnTypeChecks(matchMode, data, valueFromFilter);
    };
  }
}

class Number {
  constructor({ name, description, editable }) {
    this.name = name;
    this.inferredName = inferField({ name });
    this.description = description;
    this.editable = editable;
    this.dataType = 'numeric';

    this.getFormattedOutput = function getFormattedOutput(data) {
      try {
        if (getDataFromBiosample(this.inferredName, data) !== null && getDataFromBiosample(this.inferredName, data) !== undefined) return getDataFromBiosample(this.inferredName, data).toLocaleString('en-US');
        return '';
      } catch (error) {
        console.error(error);
        return '';
      }
    };
    this.getExportOutput = function getExportOutput(data) {
      return getDataFromBiosample(this.inferredName, data);
    };
    this.getFilterValues = function getFilterValues() {
      return { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] };
    };
    this.formatValueForImport = function formatValueForImport(value) {
      if (!valueIsNullOrUndefined(value)) {
        return parseFloat(value.replace(/,/g, ''));
      }
      return null;
    };
    this.formatValueForFilterQuery = function formatValueForFilterQuery(value) {
      return `\"${this.inferredName}\":${value}`;
    };
    this.checkIfValueIsGood = function checkIfValueIsGood(value) {
      return !isNaN(value) && !isNaN(parseFloat(value));
    };
    this.getComponentName = function getComponentName() {
      return 'InputNumber';
    };
    this.getClass = function getClass(data) {
      return '';
    };
    this.checkCorrectDataType = function checkCorrectDataType(data) {
      if (valueIsNullOrUndefined(data)) return false;
      try {
        return !isNaN(parseFloat(data));
      } catch (error) {
        console.error(error);
        return false;
      }
    };
    this.customCompare = function customCompare(biosample1, biosample2) {
      const [a, b] = extractDataForSort(biosample1, biosample2, sortKey);
      if (valueIsNullOrUndefined(a)) return 1;
      if (valueIsNullOrUndefined(b)) return -1;
      if (a === b) return 0;
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    };
    this.compareForFilter = function compareForFilter(matchMode, biosample, valueFromFilter) {
      const data = getDataFromBiosample(this.inferredName, biosample);
      if (valueIsNullOrUndefined(data)) return false;
      return numericColumnTypeChecks(matchMode, data, valueFromFilter);
    };
  }
}

export class Size {
  constructor({ name, description, editable }) {
    this.name = name;
    this.inferredName = inferField({ name });
    this.description = description;
    this.editable = editable;
    this.dataType = 'numeric';

    this.getFormattedOutput = function getFormattedOutput(data) {
      return getSize(getDataFromBiosample(this.inferredName, data));
    };
    this.getExportOutput = function getExportOutput(data) {
      return getDataFromBiosample(this.inferredName, data);
    };
    this.getFilterValues = function getFilterValues() {
      return { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] };
    };
    this.formatValueForImport = function formatValueForImport(value) {
      if (!valueIsNullOrUndefined(value)) {
        if (isValueNA(value)) return 0;
        // return getSize(value);
        return value;
      }
      return value;
    };
    this.formatValueForFilterQuery = function formatValueForFilterQuery(value) {
      return `\"${this.inferredName}\":${value}`;
    };
    this.checkIfValueIsGood = function checkIfValueIsGood(value) {
      // return !isNaN(value) && !isNaN(parseFloat(value));
      return true;
    };
    this.getComponentName = function getComponentName() {
      return 'InputNumber';
    };
    this.getClass = function getClass(data) {
      return '';
    };
    this.checkCorrectDataType = function checkCorrectDataType(data) {
      if (valueIsNullOrUndefined(data)) return false;
      try {
        return !isNaN(parseFloat(data));
      } catch (error) {
        console.error(error);
        return false;
      }
    };
    this.customCompare = function customCompare(biosample1, biosample2) {
      const [a, b] = extractDataForSort(biosample1, biosample2, sortKey);
      if (valueIsNullOrUndefined(a) || a === 0) return -1;
      if (valueIsNullOrUndefined(b) || b === 0) return 1;
      if (a === b) return 0;
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    };
    this.compareForFilter = function compareForFilter(matchMode, biosample, valueFromFilter, sizeType) {
      const data = getDataFromBiosample(this.inferredName, biosample);
      if (valueIsNullOrUndefined(data)) return false;
      const sizeIndex = getSizeIndex(sizeType);
      return numericColumnTypeChecks(matchMode, formatSizeToSpecificType(data, sizeIndex), valueFromFilter);
    };
  }
}

export class TF {
  constructor({ name, description, editable }) {
    this.name = name;
    this.inferredName = inferField({ name });
    this.description = description;
    this.editable = editable;
    this.dataType = 'boolean';

    this.getFormattedOutput = function getFormattedOutput(data) {
      try {
        // if (getDataFromBiosample(this.inferredName, data) !== null && getDataFromBiosample(this.inferredName, data) !== undefined) return (getDataFromBiosample(this.inferredName, data) ? true : '');
        if (getDataFromBiosample(this.inferredName, data) !== null && getDataFromBiosample(this.inferredName, data) !== undefined) return (getDataFromBiosample(this.inferredName, data));
        return '';
      } catch (error) {
        console.error(error);
        return '';
      }
    };
    this.getExportOutput = function getExportOutput(data) {
      return getDataFromBiosample(this.inferredName, data);
    };
    this.getFilterValues = function getFilterValues() {
      return { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] };
    };
    this.formatValueForImport = function formatValueForImport(value) {
      if (!valueIsNullOrUndefined(value) && typeof (value) === 'string') {
        const lower = value.toLowerCase();
        switch (lower) {
          case 'true': case 'yes': case 'y': case 't':
            return true;
          case 'false': case 'no': case 'na': case 'n': case 'f':
            return false;
          default:
            return false;
        }
      }
      return false;
    };
    this.formatValueForFilterQuery = function formatValueForFilterQuery(value) {
      return `\"${this.inferredName}\":${value}`;
    };
    this.checkIfValueIsGood = function checkIfValueIsGood(value) {
      return value === true || value === false;
    };
    this.getComponentName = function getComponentName() {
      return 'InputSwitch';
    };
    this.getClass = function getClass(data) {
      return '';
    };
    this.checkCorrectDataType = function checkCorrectDataType(data) {
      if (valueIsNullOrUndefined(data)) return false;
      const lower = data.toLowerCase();
      return lower === 'true' || lower === 'yes' || lower === 'false' || lower === 'no' || lower === 'na' || lower === 'y' || lower === 'n' || lower === 't' || lower === 'f';
    };
    this.customCompare = function customCompare(biosample1, biosample2) {
      const [a, b] = extractDataForSort(biosample1, biosample2, sortKey);
      if (valueIsNullOrUndefined(a)) return 1;
      if (valueIsNullOrUndefined(b)) return -1;
      if (a === b) return 0;
      if (a === true && b === false) return -1;
      if (a === false && b === true) return 1;
      return 0;
    };
    this.compareForFilter = function compareForFilter(matchMode, biosample, valueFromFilter) {
      const data = getDataFromBiosample(this.inferredName, biosample);
      if (valueIsNullOrUndefined(data)) return false;
      return booleanColumnTypeChecks(matchMode, data, valueFromFilter);
    };
  }
}

class Date {
  constructor({ name, description, editable }) {
    this.name = name;
    this.inferredName = inferField({ name });
    this.description = description;
    this.editable = editable;
    this.dataType = 'date';

    this.getFormattedOutput = function getFormattedOutput(data) {
      if (getDataFromBiosample(this.inferredName, data) === null || getDataFromBiosample(this.inferredName, data) === undefined) return '';
      return moment(getDataFromBiosample(this.inferredName, data)).format('MM/DD/YYYY');
    };
    this.getExportOutput = function getExportOutput(data) {
      return getDataFromBiosample(this.inferredName, data);
    };
    this.getFilterValues = function getFilterValues() {
      return { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] };
    };
    this.formatValueForImport = function formatValueForImport(value) {
      if (!valueIsNullOrUndefined(value)) {
        return addZeroToDayInISODateStandardString(value);
      }
      return value;
    };
    this.formatValueForFilterQuery = function formatValueForFilterQuery(value) {
      return `\"${this.inferredName}\":\"${parseDateAndTime(value)}`;
    };
    this.checkIfValueIsGood = function checkIfValueIsGood(value) {
      if (value === 'Invalid date' || isValueNA(value)) return false;
      return true;
    };
    this.getComponentName = function getComponentName() {
      return 'Calendar';
    };
    this.getClass = function getClass(data) {
      return '';
    };
    this.checkCorrectDataType = function checkCorrectDataType(data) {
      try {
        if (valueIsNullOrUndefined(data)) return false;
        return isDateValid(data);
      } catch (error) {
        console.error(error);
        return false;
      }
    };
    this.customCompare = function customCompare(biosample1, biosample2) {
      const [a, b] = extractDataForSort(biosample1, biosample2, sortKey);
      if (valueIsNullOrUndefined(a)) return 1;
      if (valueIsNullOrUndefined(b)) return -1;
      if (a === b) return 0;
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    };
    this.compareForFilter = function compareForFilter(matchMode, biosample, valueFromFilter) {
      const data = getDataFromBiosample(this.inferredName, biosample);
      if (valueIsNullOrUndefined(data)) return false;
      return dateColumnTypeChecks(matchMode, data, valueFromFilter);
    };
  }
}

class TotalNumberOfReads {
  constructor({ name, description, editable }) {
    this.name = name;
    this.inferredName = inferField({ name });
    this.description = description;
    this.editable = editable;
    this.dataType = 'numeric';

    this.getFormattedOutput = function getFormattedOutput(data) {
      try {
        try {
          const total = getTotalNumberOfReadsFromBiosample(data);
          return total.toLocaleString('en-US');
        } catch (error) {
          console.error(error);
          return 0;
        }
      } catch (error) {
        console.error(error);
        return '';
      }
    };
    this.getExportOutput = function getExportOutput(data) {
      let res = this.getFormattedOutput(data);
      if (res !== null && res !== undefined) res = res.replace(/,/g, '');
      return res;
    };
    this.getFilterValues = function getFilterValues() {
      return { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] };
    };
    this.formatValueForImport = function formatValueForImport(value) {
      if (!valueIsNullOrUndefined(value)) {
        return value.toLocaleString('en-US');
      }
      return null;
    };
    this.formatValueForFilterQuery = function formatValueForFilterQuery(value) {
      return `\"${this.inferredName}\":${value}`;
    };
    this.checkIfValueIsGood = function checkIfValueIsGood(value) {
      return true;
    };
    this.getComponentName = function getComponentName() {
      return 'InputNumber';
    };
    this.getClass = function getClass(data) {
      return '';
    };
    this.checkCorrectDataType = function checkCorrectDataType(data) {
      if (valueIsNullOrUndefined(data)) return false;
      return typeof (data) === 'number';
    };
    this.customCompare = function customCompare(biosample1, biosample2) {
      const [a, b] = [getTotalNumberOfReadsFromBiosample(biosample1), getTotalNumberOfReadsFromBiosample(biosample2)];
      if (valueIsNullOrUndefined(a)) return 1;
      if (valueIsNullOrUndefined(b)) return -1;
      if (a === b) return 0;
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    };
    this.compareForFilter = function compareForFilter(matchMode, biosample, valueFromFilter) {
      const data = getTotalNumberOfReadsFromBiosample(biosample);
      if (valueIsNullOrUndefined(data)) return false;
      return numericColumnTypeChecks(matchMode, data, valueFromFilter);
    };
  }
}

class ReadLength {
  constructor({ name, description, editable }) {
    this.name = name;
    this.inferredName = inferField({ name });
    this.description = description;
    this.editable = editable;
    this.dataType = 'numeric';

    this.getFormattedOutput = function getFormattedOutput(data) {
      try {
        return data.r1FastqLength;
      } catch (error) {
        console.error(error);
        return 0;
      }
    };
    this.getExportOutput = function getExportOutput(data) {
      return this.getFormattedOutput(data);
    };
    this.getFilterValues = function getFilterValues() {
      return { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] };
    };
    this.formatValueForImport = function formatValueForImport(value) {
      if (!valueIsNullOrUndefined(value)) {
        return value.toLocaleString('en-US');
      }
      return null;
    };
    this.formatValueForFilterQuery = function formatValueForFilterQuery(value) {
      return `\"${this.inferredName}\":${value}`;
    };
    this.checkIfValueIsGood = function checkIfValueIsGood(value) {
      return true;
    };
    this.getComponentName = function getComponentName() {
      return 'InputNumber';
    };
    this.getClass = function getClass(data) {
      return '';
    };
    this.checkCorrectDataType = function checkCorrectDataType(data) {
      if (valueIsNullOrUndefined(data)) return false;
      return typeof (data) === 'number';
    };
    this.customCompare = function customCompare(biosample1, biosample2) {
      const [a, b] = [biosample1.r1FastqLength, biosample2.r1FastqLength];
      if (valueIsNullOrUndefined(a)) return 1;
      if (valueIsNullOrUndefined(b)) return -1;
      if (a === b) return 0;
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    };
    this.compareForFilter = function compareForFilter(matchMode, biosample, valueFromFilter) {
      const data = biosample.r1FastqLength;
      if (valueIsNullOrUndefined(data)) return false;
      return numericColumnTypeChecks(matchMode, data, valueFromFilter);
    };
  }
}

class UploadDate {
  constructor({ name, description, editable }) {
    this.name = name;
    this.inferredName = inferField({ name });
    this.description = description;
    this.editable = editable;
    this.dataType = 'date';

    this.getFormattedOutput = function getFormattedOutput(data) {
      if (data.created === null || data.created === undefined) return '';
      return moment(data.created).format('MM/DD/YYYY');
    };
    this.getExportOutput = function getExportOutput(data) {
      return data.created;
    };
    this.getFilterValues = function getFilterValues() {
      return { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] };
    };
    this.formatValueForImport = function formatValueForImport(value) {
      console.log('value in UploadDate :>> ', value);
      if (!valueIsNullOrUndefined(value)) {
        return moment(value).format('MM/DD/YYYY');
      }
      return null;
    };
    this.formatValueForFilterQuery = function formatValueForFilterQuery(value) {
      return `\"${this.inferredName}\":\"${parseDateAndTime(value)}`;
    };
    this.checkIfValueIsGood = function checkIfValueIsGood(value) {
      return true;
    };
    this.getComponentName = function getComponentName() {
      return 'Calendar';
    };
    this.getClass = function getClass(data) {
      return '';
    };
    this.checkCorrectDataType = function checkCorrectDataType(data) {
      if (valueIsNullOrUndefined(data)) return false;
      return typeof (data) === 'number';
    };
    this.checkCorrectDataType = function checkCorrectDataType(data) {
      try {
        if (valueIsNullOrUndefined(data)) return false;
        return isDateValid(data);
      } catch (error) {
        console.error(error);
        return false;
      }
    };
    this.customCompare = function customCompare(biosample1, biosample2) {
      const [a, b] = extractDataForSort(biosample1, biosample2, sortKey);
      if (valueIsNullOrUndefined(a)) return 1;
      if (valueIsNullOrUndefined(b)) return -1;
      if (a === b) return 0;
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    };
    this.compareForFilter = function compareForFilter(matchMode, biosample, valueFromFilter) {
      const data = biosample.created;
      if (valueIsNullOrUndefined(data)) return false;
      return dateColumnTypeChecks(matchMode, data, valueFromFilter);
    };
  }
}

class BioskrybProductLotID {
  constructor({ name, description, editable }) {
    this.name = name;
    this.inferredName = inferField({ name });
    this.description = description;
    this.editable = editable;
    this.dataType = 'text';

    this.getFormattedOutput = function getFormattedOutput(data) {
      if (data.lotId !== null && data.lotId !== undefined) return data.lotId;
      return '';
    };
    this.getExportOutput = function getExportOutput(data) {
      return getDataFromBiosample('lotId', data);
    };
    this.getFilterValues = function getFilterValues() {
      return { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] };
    };
    this.formatValueForImport = function formatValueForImport(value) {
      return value;
    };
    this.formatValueForFilterQuery = function formatValueForFilterQuery(value) {
      return `\"t${this.inferredName}\":\"${value}`;
    };
    this.checkIfValueIsGood = function checkIfValueIsGood(value) {
      return true;
    };
    this.getComponentName = function getComponentName() {
      return 'InputText';
    };
    this.getClass = function getClass(data) {
      return '';
    };
    this.checkCorrectDataType = function checkCorrectDataType(data) {
      if (valueIsNullOrUndefined(data)) return false;
      return typeof (data) === 'string';
    };
    this.customCompare = function customCompare(biosample1, biosample2) {
      const [a, b] = extractDataForSort(biosample1, biosample2, sortKey);
      if (valueIsNullOrUndefined(a)) return 1;
      if (valueIsNullOrUndefined(b)) return -1;
      return a.localeCompare(b, 'en');
    };
    this.compareForFilter = function compareForFilter(matchMode, biosample, valueFromFilter) {
      const data = biosample.lotId;
      if (valueIsNullOrUndefined(data)) return false;
      return textColumnTypeChecks(matchMode, data, valueFromFilter);
    };
  }
}

class Status {
  constructor({ name, description, editable }) {
    this.name = name;
    this.inferredName = inferField({ name });
    this.description = description;
    this.editable = editable;
    this.dataType = 'text';

    this.getFormattedOutput = function getFormattedOutput(data) {
      return getDataFromBiosample(this.inferredName, data);
    };
    this.getExportOutput = function getExportOutput(data) {
      return getDataFromBiosample(this.inferredName, data);
    };
    this.getFilterValues = function getFilterValues() {
      return { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] };
    };
    this.formatValueForImport = function formatValueForImport(value) {
      return value;
    };
    this.formatValueForFilterQuery = function formatValueForFilterQuery(value) {
      return `\"${this.inferredName}\":\"${value}`;
    };
    this.checkIfValueIsGood = function checkIfValueIsGood(value) {
      return true;
    };
    this.getComponentName = function getComponentName() {
      return 'Dropdown';
    };
    this.getClass = function getClass(data) {
      return `customer-badge + ${statusClass(data.fastqValidationStatus)}`;
    };
    this.checkCorrectDataType = function checkCorrectDataType(data) {
      if (valueIsNullOrUndefined(data)) return false;
      return typeof (data) === 'string';
    };
    this.customCompare = function customCompare(biosample1, biosample2) {
      const [a, b] = extractDataForSort(biosample1, biosample2, sortKey);
      if (valueIsNullOrUndefined(a)) return 1;
      if (valueIsNullOrUndefined(b)) return -1;
      return a.localeCompare(b, 'en');
    };
    this.compareForFilter = function compareForFilter(matchMode, biosample, valueFromFilter) {
      const data = getDataFromBiosample(this.inferredName, biosample);
      if (valueIsNullOrUndefined(data)) return false;
      return textColumnTypeChecks(matchMode, data, valueFromFilter);
    };
  }
}

const columns = {
  Text, Number, Size, TF, Date, TotalNumberOfReads, ReadLength, UploadDate, BioskrybProductLotID, Status,
};

export function createColumn(type, attributes) {
  let ColumnType = null;
  // eslint-disable-next-line no-param-reassign
  if (type === 'True/False') ColumnType = columns.TF;
  else ColumnType = columns[type];
  if (ColumnType === null || ColumnType === undefined) return null; // Remove later when adding other classes
  return new ColumnType(attributes);
}
