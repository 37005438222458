<template>
  <Dialog v-model:visible="showing" :style="{ width: '600px' }" header="Launchable Pipelines Dialog" :modal="true" class="p-fluid launchablePipelineDialog" :closable="false">
    <div v-if="!loading">
      <div v-if="selectedLaunchablePipeline === null || selectedLaunchablePipeline === undefined">
        <Listbox @click="listBoxClick" v-model="selectedLaunchablePipelineType" :options="mappedGroupedLaunchablePipelines" optionLabel="label" class="" listStyle="max-height:85vh">
          <template #option="slotProps">
            <div class="flex align-items-center justify-content-between">
              <div>{{ slotProps.option.label }}</div>
              <Dropdown class="versionDD" @click.stop="" @change="ddChange" v-model="slotProps.option.selectedVersion" :options="slotProps.option.items" optionLabel="pipelineVersion" placeholder="Select a version">
                <template #option="slotProps">
                  <div v-tooltip.right="slotProps.option.description">{{ slotProps.option.pipelineVersion }}</div>
                </template>
              </Dropdown>
            </div>
          </template>
        </Listbox>
      </div>
      <div v-else-if="selectedLaunchablePipeline !== null && selectedLaunchablePipeline !== undefined">
        <div v-if="operation === 'create' || operation === 'update'">
          <div class="field pt-4">
            <span class="p-float-label">
              <InputText id="pipelineName" type="text" :class="(validateInputName(pipelineName) === false) ? 'p-invalid' : ''" v-model.trim="pipelineName" required="true" autofocus />
              <label for="pipelineName">Pipeline Name</label>
            </span>
            <InlineMessage :style="(validateInputName(pipelineName) === false) ? '' : 'display: none;'">Invalid pipeline name!</InlineMessage>
          </div>
          <div class="field pt-4">
            <span class="p-float-label">
              <InputText id="description" type="text" v-model.trim="description" required="false" />
              <label for="description">Description</label>
            </span>
          </div>
          <div class="field pt-4">
            <span class="p-float-label">
              <InputText id="pipelineVersion" type="text" :class="(validateInputName(pipelineVersion) === false) ? 'p-invalid' : ''" v-model.trim="pipelineVersion" required="false" />
              <label for="pipelineVersion">Pipeline Version</label>
            </span>
            <InlineMessage :style="(validateInputName(pipelineVersion) === false) ? '' : 'display: none;'">Invalid pipeline version name!</InlineMessage>
          </div>
          <div class="field pt-4">
            <span class="p-float-label">
              <InputText id="tokensPerBiosample" type="text" :class="(validateDigit(tokensPerBiosample) === false) ? 'p-invalid' : ''" v-model.trim="tokensPerBiosample" required="true" />
              <label for="tokensPerBiosample">Tokens per biosample</label>
            </span>
            <InlineMessage :style="(validateDigit(tokensPerBiosample) === false) ? '' : 'display: none;'">Invalid tokens per biosample!</InlineMessage>
          </div>
          <div class="field pt-4">
            <span class="p-float-label">
              <InputText id="gitRepo" type="text" :class="(validateURL(gitRepo) === false) ? 'p-invalid' : ''" v-model.trim="gitRepo" required="false" />
              <label for="gitRepo">GitRepo</label>
            </span>
            <InlineMessage :style="(validateURL(gitRepo) === false) ? '' : 'display: none;'">Invalid Git Repo!</InlineMessage>
          </div>
          <div v-if="operation !== 'update'" class="flex">
            <InputSwitch v-model="privateLaunchablePipeline" aria-describedby="text-error" />
            <div class="pl-3">{{ getPrivatePipelineText() }}</div>
          </div>
          <div v-if="privateLaunchablePipeline">
            <div class="mt-5 p-float-label">
              <AutoComplete v-model="selectedORGGroups" inputId="orgGroupsAC" multiple dropdown :suggestions="filteredORGGroups" @complete="searchORG" optionLabel="organizationName" />
              <label for="ac">Organizations</label>
            </div>
            <div class="mt-5 p-float-label">
              <AutoComplete v-model="selectedWSGroups" inputId="wsGroupsAC" multiple dropdown :suggestions="filteredWSGroups" @complete="searchWS" optionLabel="description" />
              <label for="ac">Workspaces</label>
            </div>

          </div>
          <div class="field pt-4">
            <span class="p-float-label">
              <Chips id="supportedInputFileTypes" v-model="supportedInputFileTypes" required="false" />
              <label for="supportedInputFileTypes">Supported Input File Types</label>
            </span>
          </div>
          <div class="field pt-4">
            <span class="p-float-label">
              <Chips id="supportedTertiaryAnalyses" v-model="supportedTertiaryAnalyses" required="false" />
              <label for="supportedTertiaryAnalyses">Supported Tertiary Analyses</label>
            </span>
          </div>
          <div class="field pt-4">
            <span class="p-float-label">
              <Dropdown id="analysisLevel" v-model="analysisLevel" :options="avaialableanalysisLevels" />
              <label for="analysisLevel" class="mb-3">Analysis Level </label>
            </span>
          </div>
          <div class="field pt-4">
            <span class="p-float-label">
              <Textarea id="parameters" v-model="parameters" :style="{ overflow: 'auto' }" :class="(isJsonString(parameters) === false) ? 'p-invalid' : ''" :autoResize="true" rows="5" cols="30" />
              <label for="parameters" class="mb-3">Parameters</label>
            </span>
          </div>
          <div>
            <span>Required metadata columns</span>
            <BiosampleMetadataColumnsDynamicRenderer :biosampleMetadataColumns="requiredMetadataColumns" />
          </div>
          <InlineMessage :style="(isJsonString(parameters) === false) ? '' : 'display: none;'">Invalid Parameters!</InlineMessage>
        </div>
        <div v-if="operation === 'delete'">
          <h3>Are you sure you wish to delete this pipeline?</h3>
        </div>
      </div>
    </div>
    <div v-if="loading" class="flex justify-content-center">
      <img alt="BJSpinner" class="bjSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png" />
    </div>
    <template #footer>
      <div class="pt-3" v-if="!loading">
        <Button label="Cancel" icon="pi pi-times" class="p-button-secondary" @click="hideDialog" />
        <Button v-if="operation === 'create'" label="Create" @click="mutateLaunchablePipeline('create')" />
        <Button v-if="operation === 'update'" label="Update" @click="mutateLaunchablePipeline('update')" />
        <Button v-if="operation === 'delete'" label="Delete" @click="mutateLaunchablePipeline('delete')" />
      </div>
    </template>
  </Dialog>
</template>
<script>
import { API, graphqlOperation } from 'aws-amplify';
import * as customQueries from '@/graphql/custom_queries';
// eslint-disable-next-line no-unused-vars
import * as mutations from '@/graphql/mutations';
// eslint-disable-next-line no-unused-vars
import {
  // eslint-disable-next-line no-unused-vars
  listItems, groupLaunchablePipelinesForListBox, validateInputName, validateDigit, wait, validateURL, isJsonString, mutateGQLObject, getToday, removeDuplicatesById, duplicatesByKeyInArray, valueIsNullOrUndefined, getEmptyMetadataObj, makeBiosampleMetadataColumnsJson,
} from '@/utils';
import BiosampleMetadataColumnsDynamicRenderer from '@/components/Project/BiosampleMetadataColumnsDynamicRenderer.vue';

export default {
  props: ['operation'],
  components: { BiosampleMetadataColumnsDynamicRenderer },
  data() {
    return {
      showing: false,
      loading: false,
      selectedLaunchablePipeline: null,
      selectedLaunchablePipelineType: null,
      lastSelectedLaunchablePipelineType: null,
      mappedGroupedLaunchablePipelines: null,
      supportedInputFileTypes: null,
      supportedTertiaryAnalyses: null,
      gitRepo: null,
      tokensPerBiosample: 0,
      analysisLevel: null,
      parameters: null,
      avaialableanalysisLevels: ['secondary', 'tertiary'],
      pipelineVersion: null,
      description: null,
      pipelineName: null,
      privateLaunchablePipeline: false,
      wsGroups: [],
      selectedWSGroups: [],
      filteredWSGroups: [],
      orgGroups: [],
      selectedORGGroups: [],
      filteredORGGroups: [],
      requiredMetadataColumns: [getEmptyMetadataObj()],
    };
  },
  methods: {
    async main() {
      this.loading = true;
      await wait(5);
      this.getWSAndOrgGroups();
      try {
        if (this.operation === 'update' || this.operation === 'delete') {
          await this.loadLaunchablePipelines();
          if (this.operation === 'update' && this.selectedLaunchablePipeline === 1) {
            this.selectedLaunchablePipeline = null;
          }
        } else if (this.operation === 'create') {
          this.selectedLaunchablePipeline = 1;
        }
      } catch (error) {
        console.error(error);
      }
      if (this.operation !== 'update') this.loading = false;
    },
    hideDialog() {
      this.showing = false;
      this.loading = false;
      this.selectedLaunchablePipeline = null;
      this.mappedGroupedLaunchablePipelines = null;
      this.supportedInputFileTypes = null;
      this.supportedTertiaryAnalyses = null;
      this.gitRepo = null;
      this.tokensPerBiosample = 0;
      this.analysisLevel = null;
      this.parameters = null;
      this.avaialableanalysisLevels = ['secondary', 'tertiary'];
      this.pipelineVersion = null;
      this.description = null;
      this.pipelineName = null;
      this.privateLaunchablePipeline = false;
      this.selectedLaunchablePipelineType = null;
      this.lastSelectedLaunchablePipelineType = null;
      this.wsGroups = [];
      this.selectedWSGroups = [];
      this.filteredWSGroups = [];
      this.orgGroups = [];
      this.selectedORGGroups = [];
      this.filteredORGGroups = [];
      this.requiredMetadataColumns = [getEmptyMetadataObj()];
      this.$store.dispatch('setShowingLaunchablePipelineCRUDDialog', false);
    },
    async loadLaunchablePipelines() {
      this.loading = true;
      try {
        const allLaunchablePipelines = (await Promise.all([
          listItems(customQueries.customListLaunchablePipelines, { limit: 300 }),
          listItems(customQueries.customListPrivateLaunchablePipelines, { limit: 300 }),
        ])).flat();
        this.mappedGroupedLaunchablePipelines = await groupLaunchablePipelinesForListBox(allLaunchablePipelines);
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    async getWSAndOrgGroups() {
      try {
        const [allWorkspaces, allOrganizations] = await Promise.all([listItems(customQueries.listWorkspacesIdName, {}), listItems(customQueries.listOrganizationsIdName, {})]);
        this.wsGroups = allWorkspaces;
        this.orgGroups = allOrganizations;
      } catch (error) {
        console.error(error);
      }
    },
    validateInputName(name) {
      return validateInputName(name);
    },
    validateDigit(digit) {
      return validateDigit(digit);
    },
    validateURL(url) {
      return validateURL(url);
    },
    isJsonString(url) {
      return isJsonString(url);
    },
    async setLaunchablePipelineValues(lp) {
      console.log('lp :>> ', lp);
      try {
        this.pipelineName = lp.pipelineName;
        this.description = lp.description;
        this.pipelineVersion = lp.pipelineVersion;
        this.tokensPerBiosample = lp.tokensPerBiosample;
        this.gitRepo = lp.gitRepo;
        this.supportedInputFileTypes = lp.supportedInputFileTypes;
        this.supportedTertiaryAnalyses = lp.supportedTertiaryAnalyses;
        this.analysisLevel = lp.analysisLevel;
        this.parameters = JSON.stringify(JSON.parse(lp.parameters), null, 2);
        if (!valueIsNullOrUndefined(lp.requiredMetadataColumns)) {
          this.requiredMetadataColumns = JSON.parse(lp.requiredMetadataColumns).columns;
        }
        if ('readGroups' in lp) {
          this.privateLaunchablePipeline = true;
          await this.setOrgAndWSGroups(lp.readGroups);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async setOrgAndWSGroups(readGroups) {
      if (readGroups.length === 0) return;
      this.privateLaunchablePipeline = true;
      const [orgIds, wsIds] = this.extractIdsFromReadGroups(readGroups);
      let [organizations, workspaces] = await Promise.all([this.getOrganizations(orgIds), this.getWorkspaces(wsIds)]);
      organizations = organizations.map((org) => org.data.getOrganization);
      workspaces = workspaces.map((ws) => ws.data.getWorkspace);
      this.selectedORGGroups = organizations;
      this.selectedWSGroups = workspaces;
    },
    extractIdsFromReadGroups(readGroups) {
      const orgIds = [];
      const wsIds = [];
      readGroups.forEach((group) => {
        const split = group.split('/');
        const entity = split[0];
        const id = split[1];
        // eslint-disable-next-line no-unused-expressions
        (entity === 'ORG') ? orgIds.push(id) : wsIds.push(id);
      });
      return [orgIds, wsIds];
    },
    async getOrganizations(orgIds) {
      const promises = [];
      orgIds.forEach((orgId) => promises.push(API.graphql(graphqlOperation(customQueries.getOrganizationIdAndName, { id: orgId }))));
      return Promise.all(promises);
    },
    async getWorkspaces(wsIds) {
      const promises = [];
      wsIds.forEach((wsId) => promises.push(API.graphql(graphqlOperation(customQueries.getWorkspaceIdAndName, { id: wsId }))));
      return Promise.all(promises);
    },
    async mutateLaunchablePipeline(operation) {
      this.loading = true;
      try {
        const params = {
          pipelineName: this.pipelineName,
          description: this.description,
          pipelineVersion: this.pipelineVersion,
          tokensPerBiosample: parseInt(this.tokensPerBiosample, 10),
          gitRepo: this.gitRepo,
          supportedInputFileTypes: this.supportedInputFileTypes,
          supportedTertiaryAnalyses: this.supportedTertiaryAnalyses,
          analysisLevel: this.analysisLevel,
          parameters: JSON.stringify(JSON.parse(this.parameters), null, 0),
          requiredMetadataColumns: makeBiosampleMetadataColumnsJson(this.requiredMetadataColumns),
        };
        if (this.privateLaunchablePipeline) {
          params.readGroups = this.makeReadGroups(this.selectedORGGroups, this.selectedWSGroups);
        }
        if (operation === 'create') {
          await this.createLaunchablePipeline(params, this.privateLaunchablePipeline);
        } else if (operation === 'update') {
          params.id = this.selectedLaunchablePipeline.id;
          await this.updateLaunchablePipeline(params, this.privateLaunchablePipeline);
        } else if (this.operation === 'delete') {
          await this.deleteLaunchablePipeline({
            id: this.selectedLaunchablePipeline.id,
          }, this.privateLaunchablePipeline);
        }
        this.hideDialog();
      } catch (error) {
        console.error(error);
        if (operation === 'create') {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Launchable pipeline create failed! Please try again.',
            life: 3000,
          });
        } else if (operation === 'update') {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Launchable pipeline update failed! Please try again.',
            life: 3000,
          });
        } else if (operation === 'delete') {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Launchable pipeline delete failed! Please try again.',
            life: 3000,
          });
        }
      }
      this.loading = false;
    },
    async updateLaunchablePipeline(params, isPrivate) {
      let gqlMutation = mutations.updateLaunchablePipelines;
      if (isPrivate) gqlMutation = mutations.updatePrivateLaunchablePipelines;
      await mutateGQLObject(gqlMutation, params);
      this.$toast.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Launchable pipeline updated successfully!',
        life: 3000,
      });
    },
    async createLaunchablePipeline(params, isPrivate) {
      let gqlMutation = mutations.createLaunchablePipelines;
      if (isPrivate) gqlMutation = mutations.createPrivateLaunchablePipelines;
      await mutateGQLObject(gqlMutation, params);
      this.$toast.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Launchable pipeline created successfully!',
        life: 3000,
      });
    },
    async deleteLaunchablePipeline(params, isPrivate) {
      let gqlMutation = mutations.deleteLaunchablePipelines;
      if (isPrivate) gqlMutation = mutations.deletePrivateLaunchablePipelines;
      await mutateGQLObject(gqlMutation, params);
      this.$toast.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Launchable pipeline deleted successfully!',
        life: 3000,
      });
    },
    makeReadGroups(orgGroups, wsGroups) {
      try {
        const readGroups = [];
        orgGroups.forEach((grp) => readGroups.push(`ORG/${grp.id}/Admin`));
        wsGroups.forEach((grp) => readGroups.push(`WS/${grp.id}/Admin`));
        return readGroups;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    getPrivatePipelineText() {
      try {
        if (this.privateLaunchablePipeline) return 'Private';
        return 'Public';
      } catch (error) {
        console.error(error);
        return '';
      }
    },
    async searchORG(event) {
      if (!event.query.trim().length) {
        this.filteredORGGroups = [...this.orgGroups];
      } else {
        this.filteredORGGroups = this.orgGroups.filter((org) => org.organizationName.toLowerCase().startsWith(event.query.toLowerCase()));
      }
    },
    async searchWS(event) {
      if (!event.query.trim().length) {
        this.filteredWSGroups = [...this.wsGroups];
      } else {
        this.filteredWSGroups = this.wsGroups.filter((ws) => ws.description.toLowerCase().startsWith(event.query.toLowerCase()));
      }
    },
    listBoxClick() {
      try {
        if (valueIsNullOrUndefined(this.selectedLaunchablePipelineType)) {
          this.selectedLaunchablePipelineType = this.lastSelectedLaunchablePipelineType;
        } else {
          this.lastSelectedLaunchablePipelineType = this.selectedLaunchablePipelineType;
        }
        this.selectedLaunchablePipeline = this.selectedLaunchablePipelineType.selectedVersion;
      } catch (error) {
        console.error(error);
      }
    },
    ddChange(event) {
      try {
        if (valueIsNullOrUndefined(this.selectedLaunchablePipelineType)) {
          this.selectedLaunchablePipeline = event.value;
        } else {
          this.selectedLaunchablePipeline = this.selectedLaunchablePipelineType.selectedVersion;
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingLaunchablePipelineCRUDDialog': async function () {
      this.showing = this.$store.state.showingLaunchablePipelineCRUDDialog;
      this.main();
    },
    async selectedLaunchablePipeline(value) {
      if (this.operation === 'update' && value !== null && value !== undefined && value !== 1) {
        await this.setLaunchablePipelineValues(value);
        this.loading = false;
      }
    },
    selectedORGGroups(value) {
      if (value.length > 0 && duplicatesByKeyInArray(this.selectedORGGroups, 'id')) {
        this.selectedORGGroups = removeDuplicatesById(this.selectedORGGroups);
      }
    },
    selectedWSGroups(value) {
      if (value.length > 0 && duplicatesByKeyInArray(this.selectedWSGroups, 'id')) {
        this.selectedWSGroups = removeDuplicatesById(this.selectedWSGroups);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.p-listbox .p-listbox-list .p-listbox-item-group) {
  // color: red;
  background: rgba(168, 167, 171,0.5);
}

::v-deep(.p-inputtextarea) {
  overflow: scroll !important;
}

.p-inputtextarea {
  overflow: scroll !important;
}

</style>
