<template>
  <div>
    <DataTable :value="transactions" :loading="loading" :paginator="true" :rows="10" dataKey="id" :rowHover="true">
      <template #header>
        <div class="flex justify-content-end flex-column sm:flex-row">
          <Button
            type="button"
            label="Add Credit"
            class="p-button"
            @click="addNewTransaction"
          />
        </div>
      </template>
      <template #empty>
        No credit transactions found.
      </template>
      <template #loading>
        Loading credit transactions... Please wait.
      </template>
      <!-- <Column header="Total Balance" :bodyStyle="getBodyStyle()">
        <template #body="{data}">
          {{ getBalanceNew(data) }}
        </template>
      </Column> -->
      <Column field="amount" header="Credit Usage" :bodyStyle="getBodyStyle()" />
      <Column field="type" header="Type" :bodyStyle="getBodyStyle()" />
      <Column header="Workspace" :bodyStyle="getBodyStyle()">
        <template #body="{ data }">
          {{ data.workspace ? data.workspace.description : '' }}
        </template>
      </Column>
      <Column header="Project" :bodyStyle="getBodyStyle()">
        <template #body="{ data }">
          {{ data.project ? data.project.clientProjectName : '' }}
        </template>
      </Column>
      <Column header="Date" :bodyStyle="getBodyStyle()">
        <template #body="{ data }">
          {{ $filters.formatDate(data.created) }}
        </template>
      </Column>
      <Column field="notes" header="Notes" :bodyStyle="getBodyStyle()" />
      <TransactionDialog />
    </DataTable>
  </div>
</template>

<script>
// import { API, graphqlOperation } from 'aws-amplify';
import * as customQueries from '@/graphql/custom_queries';
import TransactionDialog from '@/components/Organization/TransactionDialog.vue';
import { getBioSkrybOrgId, listItems } from '@/utils';

export default {
  name: 'CreditTransactions',
  components: {
    TransactionDialog,
  },
  data() {
    return {
      loading: false,
      transactions: [],
      selectedTransaction: null,
      balanceMap: {},
      bioskrybId: null,
    };
  },
  methods: {
    getBalance(transaction) {
      try {
        if (transaction.creditorOrg === null || transaction.creditorOrg === undefined || transaction.debitorOrg === null || transaction.debitorOrg === undefined) {
          return 'Not Available';
        }
        let balance = 0;
        for (const trans of transaction.creditorOrg.creditTransactions.items) {
          balance += trans.amount;
        }
        for (const trans of transaction.debitorOrg.creditTransactions.items) {
          balance -= trans.amount;
        }
        return balance;
      } catch (error) {
        console.error(error);
        return 'Not Available';
      }
    },
    getBalanceNew(transaction) {
      try {
        return this.balanceMap[transaction.workspaceId];
      } catch (error) {
        console.error(error);
        return 'Not Available';
      }
    },
    makeBalanceMap(transactions) {
      try {
        const balanceMap = {};
        transactions.forEach((transaction) => {
          if (transaction.workspaceId === '806730a0-13cd-4609-8481-d239b06d25ac') {
            // console.log('transaction.amount :>> ', transaction.amount);
          }
          let amount = 0;
          if (transaction.type === 'Storage') {
            amount = transaction.amount * -1;
          } else if (transaction.type === 'Addition') {
            amount = transaction.amount;
          } else if (transaction.debitor === this.bioskrybId) {
            amount = transaction.amount * -1;
          } else if (transaction.creditor === this.bioskrybId) {
            amount = transaction.amount;
          }
          if (!(transaction.workspaceId in balanceMap)) {
            balanceMap[transaction.workspaceId] = amount;
          } else {
            balanceMap[transaction.workspaceId] += amount;
          }
        });
        return balanceMap;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    getBodyStyle() {
      return 'text-align: center';
    },
    addNewTransaction() {
      this.$store.dispatch('showNewTransaction');
    },
    async loadTransactions() {
      this.loading = true;
      const transactionsObj = await Promise.all([listItems(customQueries.transactionsByCreditorAndDebitorForCreditTransactionsTable, { creditor: this.$route.params.id }), listItems(customQueries.transactionsByDebitorAndCreditorForCreditTransactionsTable, { debitor: this.$route.params.id })]);
      this.transactions = [...transactionsObj[0], ...transactionsObj[1]];
      console.log('transactions - ', this.transactions);
      this.bioskrybId = await getBioSkrybOrgId();
      // this.balanceMap = this.makeBalanceMap(this.transactions);
      // console.log('this.balanceMap :>> ', this.balanceMap);
      this.loading = false;
    },
  },
  async mounted() {
    document.title = 'Credit Transactions';
    await this.loadTransactions();
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingNewTransaction': async function () {
      if (this.$store.state.showingNewTransaction === false) {
        this.selectedTransaction = null;
        await this.loadTransactions();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
