/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const queryShareFolder = /* GraphQL */ `
  query QueryShareFolder($organizationId: String!) {
    queryShareFolder(organizationId: $organizationId)
  }
`;
export const generatePlotlyJWT = /* GraphQL */ `
  query GeneratePlotlyJWT {
    generatePlotlyJWT
  }
`;
export const queryParseEbEventFileAndSentItAsEmail = /* GraphQL */ `
  query QueryParseEbEventFileAndSentItAsEmail($s3PathEbEvent: String!) {
    queryParseEbEventFileAndSentItAsEmail(s3PathEbEvent: $s3PathEbEvent)
  }
`;
export const renameSamplesMetadata = /* GraphQL */ `
  query RenameSamplesMetadata($eventS3Path: String!) {
    renameSamplesMetadata(eventS3Path: $eventS3Path)
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      bioskrybClientId
      organizationName
      active
      storageCreditRate
      pipelineCreditRate
      zohoId
      salesforceId
      salesforceUrl
      salesEmail
      fasEmail
      poNumber
      creditExpiry
      organizationDataKay
      workspaces {
        items {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        nextToken
      }
      creditTransactions {
        items {
          id
          debitor
          debitorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          creditor
          creditorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          user
          userName
          amount
          creditPackName
          timesPackPurchased
          type
          notes
          processed
          readGroups
          writeGroups
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          pipelineId
          pipeline {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          isSpent
          expirationInMonths
          notificationsMap
          billingStart
          created
          updated
          organizationCreditTransactionsId
          workspaceCreditTransactionsId
          projectCreditTransactionsId
        }
        nextToken
      }
      readGroups
      sharedS3Paths
      writeGroups
      created
      updated
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $id: ID
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrganizations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        bioskrybClientId
        organizationName
        active
        storageCreditRate
        pipelineCreditRate
        zohoId
        salesforceId
        salesforceUrl
        salesEmail
        fasEmail
        poNumber
        creditExpiry
        organizationDataKay
        workspaces {
          items {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        readGroups
        sharedS3Paths
        writeGroups
        created
        updated
      }
      nextToken
    }
  }
`;
export const getWorkspace = /* GraphQL */ `
  query GetWorkspace($id: ID!) {
    getWorkspace(id: $id) {
      id
      organizationId
      organization {
        id
        bioskrybClientId
        organizationName
        active
        storageCreditRate
        pipelineCreditRate
        zohoId
        salesforceId
        salesforceUrl
        salesEmail
        fasEmail
        poNumber
        creditExpiry
        organizationDataKay
        workspaces {
          items {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        readGroups
        sharedS3Paths
        writeGroups
        created
        updated
      }
      description
      basespaceAccessToken
      projects {
        items {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        nextToken
      }
      lastCached
      creditTransactions {
        items {
          id
          debitor
          debitorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          creditor
          creditorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          user
          userName
          amount
          creditPackName
          timesPackPurchased
          type
          notes
          processed
          readGroups
          writeGroups
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          pipelineId
          pipeline {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          isSpent
          expirationInMonths
          notificationsMap
          billingStart
          created
          updated
          organizationCreditTransactionsId
          workspaceCreditTransactionsId
          projectCreditTransactionsId
        }
        nextToken
      }
      sharedS3Paths
      readGroups
      size
      lastSizeScan
      writeGroups
      adminGroups
      created
      updated
      organizationWorkspacesId
    }
  }
`;
export const listWorkspaces = /* GraphQL */ `
  query ListWorkspaces(
    $id: ID
    $filter: ModelWorkspaceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWorkspaces(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        organizationId
        organization {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        description
        basespaceAccessToken
        projects {
          items {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          nextToken
        }
        lastCached
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        sharedS3Paths
        readGroups
        size
        lastSizeScan
        writeGroups
        adminGroups
        created
        updated
        organizationWorkspacesId
      }
      nextToken
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      workspaceId
      workspace {
        id
        organizationId
        organization {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        description
        basespaceAccessToken
        projects {
          items {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          nextToken
        }
        lastCached
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        sharedS3Paths
        readGroups
        size
        lastSizeScan
        writeGroups
        adminGroups
        created
        updated
        organizationWorkspacesId
      }
      associatedProjectId
      associatedProject {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        clientProjectName
        lotId
        analysisType
        projectType
        description
        projectOutputS3Path
        size
        assayName
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        biosampleMetadataColumns
        readGroups
        writeGroups
        adminGroups
        biosamples {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            biosampleName
            bsshId
            analysisFiles
            r1FastqName
            r2FastqName
            r1FastqMb5Sum
            r2FastqMb5Sum
            r1FastqLength
            r2FastqLength
            r1FastqTotalReads
            r2FastqTotalReads
            petaSuiteVersion
            petaSuiteSpecies
            petaSuiteReferenceDir
            fastqValidationStatus
            metadata
            size
            fastqs
            r1FastqS3Uri
            r2FastqS3Uri
            lotId
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectBiosamplesId
          }
          nextToken
        }
        pipelines {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        created
        updated
        workspaceProjectsId
      }
      clientProjectName
      lotId
      analysisType
      projectType
      description
      projectOutputS3Path
      size
      assayName
      status
      nfTowerEstimatedCost
      workflowId
      initiator
      biosampleMetadataColumns
      readGroups
      writeGroups
      adminGroups
      biosamples {
        items {
          id
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          biosampleName
          bsshId
          analysisFiles
          r1FastqName
          r2FastqName
          r1FastqMb5Sum
          r2FastqMb5Sum
          r1FastqLength
          r2FastqLength
          r1FastqTotalReads
          r2FastqTotalReads
          petaSuiteVersion
          petaSuiteSpecies
          petaSuiteReferenceDir
          fastqValidationStatus
          metadata
          size
          fastqs
          r1FastqS3Uri
          r2FastqS3Uri
          lotId
          created
          updated
          readGroups
          writeGroups
          adminGroups
          projectBiosamplesId
        }
        nextToken
      }
      pipelines {
        items {
          id
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          parents {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          children {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          passFailBiosamples
          pipelineName
          runDescription
          analysisLevel
          pipelineNFSessionId
          pipelineNFRunName
          referenceGenome
          estimatedCredits
          size
          pipelineVersion
          pipelineOutputS3Path
          pipelineOutputS3FullPath
          pipelineOutputs
          pipelineStarted
          pipelineCompleted
          modules
          parameters
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          errorMessage
          created
          updated
          readGroups
          writeGroups
          adminGroups
          projectPipelinesId
        }
        nextToken
      }
      creditTransactions {
        items {
          id
          debitor
          debitorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          creditor
          creditorOrg {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          user
          userName
          amount
          creditPackName
          timesPackPurchased
          type
          notes
          processed
          readGroups
          writeGroups
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          pipelineId
          pipeline {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          isSpent
          expirationInMonths
          notificationsMap
          billingStart
          created
          updated
          organizationCreditTransactionsId
          workspaceCreditTransactionsId
          projectCreditTransactionsId
        }
        nextToken
      }
      created
      updated
      workspaceProjectsId
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $id: ID
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProjects(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        clientProjectName
        lotId
        analysisType
        projectType
        description
        projectOutputS3Path
        size
        assayName
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        biosampleMetadataColumns
        readGroups
        writeGroups
        adminGroups
        biosamples {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            biosampleName
            bsshId
            analysisFiles
            r1FastqName
            r2FastqName
            r1FastqMb5Sum
            r2FastqMb5Sum
            r1FastqLength
            r2FastqLength
            r1FastqTotalReads
            r2FastqTotalReads
            petaSuiteVersion
            petaSuiteSpecies
            petaSuiteReferenceDir
            fastqValidationStatus
            metadata
            size
            fastqs
            r1FastqS3Uri
            r2FastqS3Uri
            lotId
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectBiosamplesId
          }
          nextToken
        }
        pipelines {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        created
        updated
        workspaceProjectsId
      }
      nextToken
    }
  }
`;
export const getBiosample = /* GraphQL */ `
  query GetBiosample($id: ID!) {
    getBiosample(id: $id) {
      id
      projectId
      project {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        clientProjectName
        lotId
        analysisType
        projectType
        description
        projectOutputS3Path
        size
        assayName
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        biosampleMetadataColumns
        readGroups
        writeGroups
        adminGroups
        biosamples {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            biosampleName
            bsshId
            analysisFiles
            r1FastqName
            r2FastqName
            r1FastqMb5Sum
            r2FastqMb5Sum
            r1FastqLength
            r2FastqLength
            r1FastqTotalReads
            r2FastqTotalReads
            petaSuiteVersion
            petaSuiteSpecies
            petaSuiteReferenceDir
            fastqValidationStatus
            metadata
            size
            fastqs
            r1FastqS3Uri
            r2FastqS3Uri
            lotId
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectBiosamplesId
          }
          nextToken
        }
        pipelines {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        created
        updated
        workspaceProjectsId
      }
      biosampleName
      bsshId
      analysisFiles
      r1FastqName
      r2FastqName
      r1FastqMb5Sum
      r2FastqMb5Sum
      r1FastqLength
      r2FastqLength
      r1FastqTotalReads
      r2FastqTotalReads
      petaSuiteVersion
      petaSuiteSpecies
      petaSuiteReferenceDir
      fastqValidationStatus
      metadata
      size
      fastqs
      r1FastqS3Uri
      r2FastqS3Uri
      lotId
      created
      updated
      readGroups
      writeGroups
      adminGroups
      projectBiosamplesId
    }
  }
`;
export const listBiosamples = /* GraphQL */ `
  query ListBiosamples(
    $id: ID
    $filter: ModelBiosampleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBiosamples(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        biosampleName
        bsshId
        analysisFiles
        r1FastqName
        r2FastqName
        r1FastqMb5Sum
        r2FastqMb5Sum
        r1FastqLength
        r2FastqLength
        r1FastqTotalReads
        r2FastqTotalReads
        petaSuiteVersion
        petaSuiteSpecies
        petaSuiteReferenceDir
        fastqValidationStatus
        metadata
        size
        fastqs
        r1FastqS3Uri
        r2FastqS3Uri
        lotId
        created
        updated
        readGroups
        writeGroups
        adminGroups
        projectBiosamplesId
      }
      nextToken
    }
  }
`;
export const getPipeline = /* GraphQL */ `
  query GetPipeline($id: ID!) {
    getPipeline(id: $id) {
      id
      projectId
      project {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        clientProjectName
        lotId
        analysisType
        projectType
        description
        projectOutputS3Path
        size
        assayName
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        biosampleMetadataColumns
        readGroups
        writeGroups
        adminGroups
        biosamples {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            biosampleName
            bsshId
            analysisFiles
            r1FastqName
            r2FastqName
            r1FastqMb5Sum
            r2FastqMb5Sum
            r1FastqLength
            r2FastqLength
            r1FastqTotalReads
            r2FastqTotalReads
            petaSuiteVersion
            petaSuiteSpecies
            petaSuiteReferenceDir
            fastqValidationStatus
            metadata
            size
            fastqs
            r1FastqS3Uri
            r2FastqS3Uri
            lotId
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectBiosamplesId
          }
          nextToken
        }
        pipelines {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        created
        updated
        workspaceProjectsId
      }
      parents {
        items {
          id
          parentId
          childId
          parent {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          child {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          createdAt
          updatedAt
          pipelineParentsId
          pipelineChildrenId
        }
        nextToken
      }
      children {
        items {
          id
          parentId
          childId
          parent {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          child {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          createdAt
          updatedAt
          pipelineParentsId
          pipelineChildrenId
        }
        nextToken
      }
      passFailBiosamples
      pipelineName
      runDescription
      analysisLevel
      pipelineNFSessionId
      pipelineNFRunName
      referenceGenome
      estimatedCredits
      size
      pipelineVersion
      pipelineOutputS3Path
      pipelineOutputS3FullPath
      pipelineOutputs
      pipelineStarted
      pipelineCompleted
      modules
      parameters
      status
      nfTowerEstimatedCost
      workflowId
      initiator
      errorMessage
      created
      updated
      readGroups
      writeGroups
      adminGroups
      projectPipelinesId
    }
  }
`;
export const listPipelines = /* GraphQL */ `
  query ListPipelines(
    $id: ID
    $filter: ModelPipelineFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPipelines(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        parents {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        children {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        passFailBiosamples
        pipelineName
        runDescription
        analysisLevel
        pipelineNFSessionId
        pipelineNFRunName
        referenceGenome
        estimatedCredits
        size
        pipelineVersion
        pipelineOutputS3Path
        pipelineOutputS3FullPath
        pipelineOutputs
        pipelineStarted
        pipelineCompleted
        modules
        parameters
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        errorMessage
        created
        updated
        readGroups
        writeGroups
        adminGroups
        projectPipelinesId
      }
      nextToken
    }
  }
`;
export const getPipelinesPipelines = /* GraphQL */ `
  query GetPipelinesPipelines($id: ID!) {
    getPipelinesPipelines(id: $id) {
      id
      parentId
      childId
      parent {
        id
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        parents {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        children {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        passFailBiosamples
        pipelineName
        runDescription
        analysisLevel
        pipelineNFSessionId
        pipelineNFRunName
        referenceGenome
        estimatedCredits
        size
        pipelineVersion
        pipelineOutputS3Path
        pipelineOutputS3FullPath
        pipelineOutputs
        pipelineStarted
        pipelineCompleted
        modules
        parameters
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        errorMessage
        created
        updated
        readGroups
        writeGroups
        adminGroups
        projectPipelinesId
      }
      child {
        id
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        parents {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        children {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        passFailBiosamples
        pipelineName
        runDescription
        analysisLevel
        pipelineNFSessionId
        pipelineNFRunName
        referenceGenome
        estimatedCredits
        size
        pipelineVersion
        pipelineOutputS3Path
        pipelineOutputS3FullPath
        pipelineOutputs
        pipelineStarted
        pipelineCompleted
        modules
        parameters
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        errorMessage
        created
        updated
        readGroups
        writeGroups
        adminGroups
        projectPipelinesId
      }
      createdAt
      updatedAt
      pipelineParentsId
      pipelineChildrenId
    }
  }
`;
export const listPipelinesPipelines = /* GraphQL */ `
  query ListPipelinesPipelines(
    $filter: ModelPipelinesPipelinesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPipelinesPipelines(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentId
        childId
        parent {
          id
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          parents {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          children {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          passFailBiosamples
          pipelineName
          runDescription
          analysisLevel
          pipelineNFSessionId
          pipelineNFRunName
          referenceGenome
          estimatedCredits
          size
          pipelineVersion
          pipelineOutputS3Path
          pipelineOutputS3FullPath
          pipelineOutputs
          pipelineStarted
          pipelineCompleted
          modules
          parameters
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          errorMessage
          created
          updated
          readGroups
          writeGroups
          adminGroups
          projectPipelinesId
        }
        child {
          id
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          parents {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          children {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          passFailBiosamples
          pipelineName
          runDescription
          analysisLevel
          pipelineNFSessionId
          pipelineNFRunName
          referenceGenome
          estimatedCredits
          size
          pipelineVersion
          pipelineOutputS3Path
          pipelineOutputS3FullPath
          pipelineOutputs
          pipelineStarted
          pipelineCompleted
          modules
          parameters
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          errorMessage
          created
          updated
          readGroups
          writeGroups
          adminGroups
          projectPipelinesId
        }
        createdAt
        updatedAt
        pipelineParentsId
        pipelineChildrenId
      }
      nextToken
    }
  }
`;
export const getLaunchablePipelines = /* GraphQL */ `
  query GetLaunchablePipelines($id: ID!) {
    getLaunchablePipelines(id: $id) {
      id
      pipelineName
      pipelineVersion
      parameters
      requiredMetadataColumns
      description
      analysisLevel
      supportedTertiaryAnalyses
      supportedInputFileTypes
      gitRepo
      mainScript
      tokensPerBiosample
      created
      updated
    }
  }
`;
export const listLaunchablePipelines = /* GraphQL */ `
  query ListLaunchablePipelines(
    $id: ID
    $filter: ModelLaunchablePipelinesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLaunchablePipelines(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        pipelineName
        pipelineVersion
        parameters
        requiredMetadataColumns
        description
        analysisLevel
        supportedTertiaryAnalyses
        supportedInputFileTypes
        gitRepo
        mainScript
        tokensPerBiosample
        created
        updated
      }
      nextToken
    }
  }
`;
export const getPrivateLaunchablePipelines = /* GraphQL */ `
  query GetPrivateLaunchablePipelines($id: ID!) {
    getPrivateLaunchablePipelines(id: $id) {
      id
      pipelineName
      pipelineVersion
      parameters
      requiredMetadataColumns
      description
      analysisLevel
      supportedTertiaryAnalyses
      supportedInputFileTypes
      gitRepo
      mainScript
      tokensPerBiosample
      readGroups
      created
      updated
    }
  }
`;
export const listPrivateLaunchablePipelines = /* GraphQL */ `
  query ListPrivateLaunchablePipelines(
    $id: ID
    $filter: ModelPrivateLaunchablePipelinesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPrivateLaunchablePipelines(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        pipelineName
        pipelineVersion
        parameters
        requiredMetadataColumns
        description
        analysisLevel
        supportedTertiaryAnalyses
        supportedInputFileTypes
        gitRepo
        mainScript
        tokensPerBiosample
        readGroups
        created
        updated
      }
      nextToken
    }
  }
`;
export const getVisualizations = /* GraphQL */ `
  query GetVisualizations($id: ID!) {
    getVisualizations(id: $id) {
      id
      visualizationName
      description
      version
      deploymentPath
      iconPath
      validationSchema
      allowedExtensions
      created
      updated
    }
  }
`;
export const listVisualizations = /* GraphQL */ `
  query ListVisualizations(
    $id: ID
    $filter: ModelVisualizationsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVisualizations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        visualizationName
        description
        version
        deploymentPath
        iconPath
        validationSchema
        allowedExtensions
        created
        updated
      }
      nextToken
    }
  }
`;
export const getWorkflow = /* GraphQL */ `
  query GetWorkflow($id: ID!) {
    getWorkflow(id: $id) {
      id
      paramsFileS3Path
      nfWorkflowId
      status
      created
      updated
    }
  }
`;
export const listWorkflows = /* GraphQL */ `
  query ListWorkflows(
    $id: ID
    $filter: ModelWorkflowFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWorkflows(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        paramsFileS3Path
        nfWorkflowId
        status
        created
        updated
      }
      nextToken
    }
  }
`;
export const getCreditTransactionLog = /* GraphQL */ `
  query GetCreditTransactionLog($id: ID!) {
    getCreditTransactionLog(id: $id) {
      id
      debitor
      debitorOrg {
        id
        bioskrybClientId
        organizationName
        active
        storageCreditRate
        pipelineCreditRate
        zohoId
        salesforceId
        salesforceUrl
        salesEmail
        fasEmail
        poNumber
        creditExpiry
        organizationDataKay
        workspaces {
          items {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        readGroups
        sharedS3Paths
        writeGroups
        created
        updated
      }
      creditor
      creditorOrg {
        id
        bioskrybClientId
        organizationName
        active
        storageCreditRate
        pipelineCreditRate
        zohoId
        salesforceId
        salesforceUrl
        salesEmail
        fasEmail
        poNumber
        creditExpiry
        organizationDataKay
        workspaces {
          items {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        readGroups
        sharedS3Paths
        writeGroups
        created
        updated
      }
      user
      userName
      amount
      creditPackName
      timesPackPurchased
      type
      notes
      processed
      readGroups
      writeGroups
      projectId
      project {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        clientProjectName
        lotId
        analysisType
        projectType
        description
        projectOutputS3Path
        size
        assayName
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        biosampleMetadataColumns
        readGroups
        writeGroups
        adminGroups
        biosamples {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            biosampleName
            bsshId
            analysisFiles
            r1FastqName
            r2FastqName
            r1FastqMb5Sum
            r2FastqMb5Sum
            r1FastqLength
            r2FastqLength
            r1FastqTotalReads
            r2FastqTotalReads
            petaSuiteVersion
            petaSuiteSpecies
            petaSuiteReferenceDir
            fastqValidationStatus
            metadata
            size
            fastqs
            r1FastqS3Uri
            r2FastqS3Uri
            lotId
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectBiosamplesId
          }
          nextToken
        }
        pipelines {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        created
        updated
        workspaceProjectsId
      }
      workspaceId
      workspace {
        id
        organizationId
        organization {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        description
        basespaceAccessToken
        projects {
          items {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          nextToken
        }
        lastCached
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        sharedS3Paths
        readGroups
        size
        lastSizeScan
        writeGroups
        adminGroups
        created
        updated
        organizationWorkspacesId
      }
      pipelineId
      pipeline {
        id
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        parents {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        children {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        passFailBiosamples
        pipelineName
        runDescription
        analysisLevel
        pipelineNFSessionId
        pipelineNFRunName
        referenceGenome
        estimatedCredits
        size
        pipelineVersion
        pipelineOutputS3Path
        pipelineOutputS3FullPath
        pipelineOutputs
        pipelineStarted
        pipelineCompleted
        modules
        parameters
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        errorMessage
        created
        updated
        readGroups
        writeGroups
        adminGroups
        projectPipelinesId
      }
      isSpent
      expirationInMonths
      notificationsMap
      billingStart
      created
      updated
      organizationCreditTransactionsId
      workspaceCreditTransactionsId
      projectCreditTransactionsId
    }
  }
`;
export const listCreditTransactionLogs = /* GraphQL */ `
  query ListCreditTransactionLogs(
    $id: ID
    $filter: ModelCreditTransactionLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCreditTransactionLogs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        debitor
        debitorOrg {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        creditor
        creditorOrg {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        user
        userName
        amount
        creditPackName
        timesPackPurchased
        type
        notes
        processed
        readGroups
        writeGroups
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        pipelineId
        pipeline {
          id
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          parents {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          children {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          passFailBiosamples
          pipelineName
          runDescription
          analysisLevel
          pipelineNFSessionId
          pipelineNFRunName
          referenceGenome
          estimatedCredits
          size
          pipelineVersion
          pipelineOutputS3Path
          pipelineOutputS3FullPath
          pipelineOutputs
          pipelineStarted
          pipelineCompleted
          modules
          parameters
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          errorMessage
          created
          updated
          readGroups
          writeGroups
          adminGroups
          projectPipelinesId
        }
        isSpent
        expirationInMonths
        notificationsMap
        billingStart
        created
        updated
        organizationCreditTransactionsId
        workspaceCreditTransactionsId
        projectCreditTransactionsId
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      organization
      tAndCAccepted
      created
      updated
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organization
        tAndCAccepted
        created
        updated
        owner
      }
      nextToken
    }
  }
`;
export const getBSSHBiosample = /* GraphQL */ `
  query GetBSSHBiosample($workspaceId: ID!, $id: ID!) {
    getBSSHBiosample(workspaceId: $workspaceId, id: $id) {
      workspaceId
      id
      bioSampleName
      defaultProject
      bsshDateModified
      bsshDateCreated
      readGroups
      writeGroups
      adminGroups
      created
      updated
    }
  }
`;
export const listBSSHBiosamples = /* GraphQL */ `
  query ListBSSHBiosamples(
    $workspaceId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelBSSHBiosampleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBSSHBiosamples(
      workspaceId: $workspaceId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        workspaceId
        id
        bioSampleName
        defaultProject
        bsshDateModified
        bsshDateCreated
        readGroups
        writeGroups
        adminGroups
        created
        updated
      }
      nextToken
    }
  }
`;
export const getDeletedEntitiesLog = /* GraphQL */ `
  query GetDeletedEntitiesLog($id: ID!) {
    getDeletedEntitiesLog(id: $id) {
      id
      organizationId
      organizationName
      workspaceId
      workspaceName
      projectName
      pipelineName
      biosampleName
      deletedInParent
      userUuidCognito
      userNameCognito
      uuidOfDeletedEntity
      typeOfDeletedEntity
      status
      affectedBiosamples
      dateOfCreationOfDeletedEntity
      emailInfo
      readGroups
      writeGroups
      adminGroups
      created
      updated
    }
  }
`;
export const listDeletedEntitiesLogs = /* GraphQL */ `
  query ListDeletedEntitiesLogs(
    $id: ID
    $filter: ModelDeletedEntitiesLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDeletedEntitiesLogs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        organizationId
        organizationName
        workspaceId
        workspaceName
        projectName
        pipelineName
        biosampleName
        deletedInParent
        userUuidCognito
        userNameCognito
        uuidOfDeletedEntity
        typeOfDeletedEntity
        status
        affectedBiosamples
        dateOfCreationOfDeletedEntity
        emailInfo
        readGroups
        writeGroups
        adminGroups
        created
        updated
      }
      nextToken
    }
  }
`;
export const organizationsByClientId = /* GraphQL */ `
  query OrganizationsByClientId(
    $bioskrybClientId: String!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    organizationsByClientId(
      bioskrybClientId: $bioskrybClientId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bioskrybClientId
        organizationName
        active
        storageCreditRate
        pipelineCreditRate
        zohoId
        salesforceId
        salesforceUrl
        salesEmail
        fasEmail
        poNumber
        creditExpiry
        organizationDataKay
        workspaces {
          items {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        readGroups
        sharedS3Paths
        writeGroups
        created
        updated
      }
      nextToken
    }
  }
`;
export const workspacesByOrganization = /* GraphQL */ `
  query WorkspacesByOrganization(
    $organizationId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWorkspaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    workspacesByOrganization(
      organizationId: $organizationId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        organization {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        description
        basespaceAccessToken
        projects {
          items {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          nextToken
        }
        lastCached
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        sharedS3Paths
        readGroups
        size
        lastSizeScan
        writeGroups
        adminGroups
        created
        updated
        organizationWorkspacesId
      }
      nextToken
    }
  }
`;
export const projectsByWorkspace = /* GraphQL */ `
  query ProjectsByWorkspace(
    $workspaceId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectsByWorkspace(
      workspaceId: $workspaceId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        clientProjectName
        lotId
        analysisType
        projectType
        description
        projectOutputS3Path
        size
        assayName
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        biosampleMetadataColumns
        readGroups
        writeGroups
        adminGroups
        biosamples {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            biosampleName
            bsshId
            analysisFiles
            r1FastqName
            r2FastqName
            r1FastqMb5Sum
            r2FastqMb5Sum
            r1FastqLength
            r2FastqLength
            r1FastqTotalReads
            r2FastqTotalReads
            petaSuiteVersion
            petaSuiteSpecies
            petaSuiteReferenceDir
            fastqValidationStatus
            metadata
            size
            fastqs
            r1FastqS3Uri
            r2FastqS3Uri
            lotId
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectBiosamplesId
          }
          nextToken
        }
        pipelines {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        created
        updated
        workspaceProjectsId
      }
      nextToken
    }
  }
`;
export const projectsByWorkspaceAndAssayName = /* GraphQL */ `
  query ProjectsByWorkspaceAndAssayName(
    $workspaceId: ID!
    $assayName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectsByWorkspaceAndAssayName(
      workspaceId: $workspaceId
      assayName: $assayName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        associatedProjectId
        associatedProject {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        clientProjectName
        lotId
        analysisType
        projectType
        description
        projectOutputS3Path
        size
        assayName
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        biosampleMetadataColumns
        readGroups
        writeGroups
        adminGroups
        biosamples {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            biosampleName
            bsshId
            analysisFiles
            r1FastqName
            r2FastqName
            r1FastqMb5Sum
            r2FastqMb5Sum
            r1FastqLength
            r2FastqLength
            r1FastqTotalReads
            r2FastqTotalReads
            petaSuiteVersion
            petaSuiteSpecies
            petaSuiteReferenceDir
            fastqValidationStatus
            metadata
            size
            fastqs
            r1FastqS3Uri
            r2FastqS3Uri
            lotId
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectBiosamplesId
          }
          nextToken
        }
        pipelines {
          items {
            id
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            parents {
              nextToken
            }
            children {
              nextToken
            }
            passFailBiosamples
            pipelineName
            runDescription
            analysisLevel
            pipelineNFSessionId
            pipelineNFRunName
            referenceGenome
            estimatedCredits
            size
            pipelineVersion
            pipelineOutputS3Path
            pipelineOutputS3FullPath
            pipelineOutputs
            pipelineStarted
            pipelineCompleted
            modules
            parameters
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            errorMessage
            created
            updated
            readGroups
            writeGroups
            adminGroups
            projectPipelinesId
          }
          nextToken
        }
        creditTransactions {
          items {
            id
            debitor
            debitorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            creditor
            creditorOrg {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            user
            userName
            amount
            creditPackName
            timesPackPurchased
            type
            notes
            processed
            readGroups
            writeGroups
            projectId
            project {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            pipelineId
            pipeline {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            isSpent
            expirationInMonths
            notificationsMap
            billingStart
            created
            updated
            organizationCreditTransactionsId
            workspaceCreditTransactionsId
            projectCreditTransactionsId
          }
          nextToken
        }
        created
        updated
        workspaceProjectsId
      }
      nextToken
    }
  }
`;
export const biosamplesByProject = /* GraphQL */ `
  query BiosamplesByProject(
    $projectId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBiosampleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    biosamplesByProject(
      projectId: $projectId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        biosampleName
        bsshId
        analysisFiles
        r1FastqName
        r2FastqName
        r1FastqMb5Sum
        r2FastqMb5Sum
        r1FastqLength
        r2FastqLength
        r1FastqTotalReads
        r2FastqTotalReads
        petaSuiteVersion
        petaSuiteSpecies
        petaSuiteReferenceDir
        fastqValidationStatus
        metadata
        size
        fastqs
        r1FastqS3Uri
        r2FastqS3Uri
        lotId
        created
        updated
        readGroups
        writeGroups
        adminGroups
        projectBiosamplesId
      }
      nextToken
    }
  }
`;
export const pipelinesByProject = /* GraphQL */ `
  query PipelinesByProject(
    $projectId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPipelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pipelinesByProject(
      projectId: $projectId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        parents {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        children {
          items {
            id
            parentId
            childId
            parent {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            child {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            createdAt
            updatedAt
            pipelineParentsId
            pipelineChildrenId
          }
          nextToken
        }
        passFailBiosamples
        pipelineName
        runDescription
        analysisLevel
        pipelineNFSessionId
        pipelineNFRunName
        referenceGenome
        estimatedCredits
        size
        pipelineVersion
        pipelineOutputS3Path
        pipelineOutputS3FullPath
        pipelineOutputs
        pipelineStarted
        pipelineCompleted
        modules
        parameters
        status
        nfTowerEstimatedCost
        workflowId
        initiator
        errorMessage
        created
        updated
        readGroups
        writeGroups
        adminGroups
        projectPipelinesId
      }
      nextToken
    }
  }
`;
export const launchablePipelinesByPipelineNameAndVersion = /* GraphQL */ `
  query LaunchablePipelinesByPipelineNameAndVersion(
    $pipelineName: String!
    $pipelineVersion: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLaunchablePipelinesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    launchablePipelinesByPipelineNameAndVersion(
      pipelineName: $pipelineName
      pipelineVersion: $pipelineVersion
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pipelineName
        pipelineVersion
        parameters
        requiredMetadataColumns
        description
        analysisLevel
        supportedTertiaryAnalyses
        supportedInputFileTypes
        gitRepo
        mainScript
        tokensPerBiosample
        created
        updated
      }
      nextToken
    }
  }
`;
export const transactionsByDebitorAndCreditor = /* GraphQL */ `
  query TransactionsByDebitorAndCreditor(
    $debitor: ID!
    $creditor: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCreditTransactionLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByDebitorAndCreditor(
      debitor: $debitor
      creditor: $creditor
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        debitor
        debitorOrg {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        creditor
        creditorOrg {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        user
        userName
        amount
        creditPackName
        timesPackPurchased
        type
        notes
        processed
        readGroups
        writeGroups
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        pipelineId
        pipeline {
          id
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          parents {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          children {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          passFailBiosamples
          pipelineName
          runDescription
          analysisLevel
          pipelineNFSessionId
          pipelineNFRunName
          referenceGenome
          estimatedCredits
          size
          pipelineVersion
          pipelineOutputS3Path
          pipelineOutputS3FullPath
          pipelineOutputs
          pipelineStarted
          pipelineCompleted
          modules
          parameters
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          errorMessage
          created
          updated
          readGroups
          writeGroups
          adminGroups
          projectPipelinesId
        }
        isSpent
        expirationInMonths
        notificationsMap
        billingStart
        created
        updated
        organizationCreditTransactionsId
        workspaceCreditTransactionsId
        projectCreditTransactionsId
      }
      nextToken
    }
  }
`;
export const transactionsByDebitor = /* GraphQL */ `
  query TransactionsByDebitor(
    $debitor: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCreditTransactionLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByDebitor(
      debitor: $debitor
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        debitor
        debitorOrg {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        creditor
        creditorOrg {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        user
        userName
        amount
        creditPackName
        timesPackPurchased
        type
        notes
        processed
        readGroups
        writeGroups
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        pipelineId
        pipeline {
          id
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          parents {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          children {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          passFailBiosamples
          pipelineName
          runDescription
          analysisLevel
          pipelineNFSessionId
          pipelineNFRunName
          referenceGenome
          estimatedCredits
          size
          pipelineVersion
          pipelineOutputS3Path
          pipelineOutputS3FullPath
          pipelineOutputs
          pipelineStarted
          pipelineCompleted
          modules
          parameters
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          errorMessage
          created
          updated
          readGroups
          writeGroups
          adminGroups
          projectPipelinesId
        }
        isSpent
        expirationInMonths
        notificationsMap
        billingStart
        created
        updated
        organizationCreditTransactionsId
        workspaceCreditTransactionsId
        projectCreditTransactionsId
      }
      nextToken
    }
  }
`;
export const transactionsByCreditorAndDebitor = /* GraphQL */ `
  query TransactionsByCreditorAndDebitor(
    $creditor: ID!
    $debitor: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCreditTransactionLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByCreditorAndDebitor(
      creditor: $creditor
      debitor: $debitor
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        debitor
        debitorOrg {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        creditor
        creditorOrg {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        user
        userName
        amount
        creditPackName
        timesPackPurchased
        type
        notes
        processed
        readGroups
        writeGroups
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        pipelineId
        pipeline {
          id
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          parents {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          children {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          passFailBiosamples
          pipelineName
          runDescription
          analysisLevel
          pipelineNFSessionId
          pipelineNFRunName
          referenceGenome
          estimatedCredits
          size
          pipelineVersion
          pipelineOutputS3Path
          pipelineOutputS3FullPath
          pipelineOutputs
          pipelineStarted
          pipelineCompleted
          modules
          parameters
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          errorMessage
          created
          updated
          readGroups
          writeGroups
          adminGroups
          projectPipelinesId
        }
        isSpent
        expirationInMonths
        notificationsMap
        billingStart
        created
        updated
        organizationCreditTransactionsId
        workspaceCreditTransactionsId
        projectCreditTransactionsId
      }
      nextToken
    }
  }
`;
export const transactionsByCreditor = /* GraphQL */ `
  query TransactionsByCreditor(
    $creditor: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCreditTransactionLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByCreditor(
      creditor: $creditor
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        debitor
        debitorOrg {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        creditor
        creditorOrg {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        user
        userName
        amount
        creditPackName
        timesPackPurchased
        type
        notes
        processed
        readGroups
        writeGroups
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        pipelineId
        pipeline {
          id
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          parents {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          children {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          passFailBiosamples
          pipelineName
          runDescription
          analysisLevel
          pipelineNFSessionId
          pipelineNFRunName
          referenceGenome
          estimatedCredits
          size
          pipelineVersion
          pipelineOutputS3Path
          pipelineOutputS3FullPath
          pipelineOutputs
          pipelineStarted
          pipelineCompleted
          modules
          parameters
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          errorMessage
          created
          updated
          readGroups
          writeGroups
          adminGroups
          projectPipelinesId
        }
        isSpent
        expirationInMonths
        notificationsMap
        billingStart
        created
        updated
        organizationCreditTransactionsId
        workspaceCreditTransactionsId
        projectCreditTransactionsId
      }
      nextToken
    }
  }
`;
export const transactionsByUser = /* GraphQL */ `
  query TransactionsByUser(
    $user: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCreditTransactionLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByUser(
      user: $user
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        debitor
        debitorOrg {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        creditor
        creditorOrg {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        user
        userName
        amount
        creditPackName
        timesPackPurchased
        type
        notes
        processed
        readGroups
        writeGroups
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        pipelineId
        pipeline {
          id
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          parents {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          children {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          passFailBiosamples
          pipelineName
          runDescription
          analysisLevel
          pipelineNFSessionId
          pipelineNFRunName
          referenceGenome
          estimatedCredits
          size
          pipelineVersion
          pipelineOutputS3Path
          pipelineOutputS3FullPath
          pipelineOutputs
          pipelineStarted
          pipelineCompleted
          modules
          parameters
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          errorMessage
          created
          updated
          readGroups
          writeGroups
          adminGroups
          projectPipelinesId
        }
        isSpent
        expirationInMonths
        notificationsMap
        billingStart
        created
        updated
        organizationCreditTransactionsId
        workspaceCreditTransactionsId
        projectCreditTransactionsId
      }
      nextToken
    }
  }
`;
export const transactionsByProject = /* GraphQL */ `
  query TransactionsByProject(
    $projectId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCreditTransactionLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByProject(
      projectId: $projectId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        debitor
        debitorOrg {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        creditor
        creditorOrg {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        user
        userName
        amount
        creditPackName
        timesPackPurchased
        type
        notes
        processed
        readGroups
        writeGroups
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        pipelineId
        pipeline {
          id
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          parents {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          children {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          passFailBiosamples
          pipelineName
          runDescription
          analysisLevel
          pipelineNFSessionId
          pipelineNFRunName
          referenceGenome
          estimatedCredits
          size
          pipelineVersion
          pipelineOutputS3Path
          pipelineOutputS3FullPath
          pipelineOutputs
          pipelineStarted
          pipelineCompleted
          modules
          parameters
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          errorMessage
          created
          updated
          readGroups
          writeGroups
          adminGroups
          projectPipelinesId
        }
        isSpent
        expirationInMonths
        notificationsMap
        billingStart
        created
        updated
        organizationCreditTransactionsId
        workspaceCreditTransactionsId
        projectCreditTransactionsId
      }
      nextToken
    }
  }
`;
export const transactionsByWorkspace = /* GraphQL */ `
  query TransactionsByWorkspace(
    $workspaceId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCreditTransactionLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByWorkspace(
      workspaceId: $workspaceId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        debitor
        debitorOrg {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        creditor
        creditorOrg {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        user
        userName
        amount
        creditPackName
        timesPackPurchased
        type
        notes
        processed
        readGroups
        writeGroups
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        pipelineId
        pipeline {
          id
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          parents {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          children {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          passFailBiosamples
          pipelineName
          runDescription
          analysisLevel
          pipelineNFSessionId
          pipelineNFRunName
          referenceGenome
          estimatedCredits
          size
          pipelineVersion
          pipelineOutputS3Path
          pipelineOutputS3FullPath
          pipelineOutputs
          pipelineStarted
          pipelineCompleted
          modules
          parameters
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          errorMessage
          created
          updated
          readGroups
          writeGroups
          adminGroups
          projectPipelinesId
        }
        isSpent
        expirationInMonths
        notificationsMap
        billingStart
        created
        updated
        organizationCreditTransactionsId
        workspaceCreditTransactionsId
        projectCreditTransactionsId
      }
      nextToken
    }
  }
`;
export const transactionsByPipeline = /* GraphQL */ `
  query TransactionsByPipeline(
    $pipelineId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCreditTransactionLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByPipeline(
      pipelineId: $pipelineId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        debitor
        debitorOrg {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        creditor
        creditorOrg {
          id
          bioskrybClientId
          organizationName
          active
          storageCreditRate
          pipelineCreditRate
          zohoId
          salesforceId
          salesforceUrl
          salesEmail
          fasEmail
          poNumber
          creditExpiry
          organizationDataKay
          workspaces {
            items {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          readGroups
          sharedS3Paths
          writeGroups
          created
          updated
        }
        user
        userName
        amount
        creditPackName
        timesPackPurchased
        type
        notes
        processed
        readGroups
        writeGroups
        projectId
        project {
          id
          workspaceId
          workspace {
            id
            organizationId
            organization {
              id
              bioskrybClientId
              organizationName
              active
              storageCreditRate
              pipelineCreditRate
              zohoId
              salesforceId
              salesforceUrl
              salesEmail
              fasEmail
              poNumber
              creditExpiry
              organizationDataKay
              readGroups
              sharedS3Paths
              writeGroups
              created
              updated
            }
            description
            basespaceAccessToken
            projects {
              nextToken
            }
            lastCached
            creditTransactions {
              nextToken
            }
            sharedS3Paths
            readGroups
            size
            lastSizeScan
            writeGroups
            adminGroups
            created
            updated
            organizationWorkspacesId
          }
          associatedProjectId
          associatedProject {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          clientProjectName
          lotId
          analysisType
          projectType
          description
          projectOutputS3Path
          size
          assayName
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          biosampleMetadataColumns
          readGroups
          writeGroups
          adminGroups
          biosamples {
            items {
              id
              projectId
              biosampleName
              bsshId
              analysisFiles
              r1FastqName
              r2FastqName
              r1FastqMb5Sum
              r2FastqMb5Sum
              r1FastqLength
              r2FastqLength
              r1FastqTotalReads
              r2FastqTotalReads
              petaSuiteVersion
              petaSuiteSpecies
              petaSuiteReferenceDir
              fastqValidationStatus
              metadata
              size
              fastqs
              r1FastqS3Uri
              r2FastqS3Uri
              lotId
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectBiosamplesId
            }
            nextToken
          }
          pipelines {
            items {
              id
              projectId
              passFailBiosamples
              pipelineName
              runDescription
              analysisLevel
              pipelineNFSessionId
              pipelineNFRunName
              referenceGenome
              estimatedCredits
              size
              pipelineVersion
              pipelineOutputS3Path
              pipelineOutputS3FullPath
              pipelineOutputs
              pipelineStarted
              pipelineCompleted
              modules
              parameters
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              errorMessage
              created
              updated
              readGroups
              writeGroups
              adminGroups
              projectPipelinesId
            }
            nextToken
          }
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          created
          updated
          workspaceProjectsId
        }
        workspaceId
        workspace {
          id
          organizationId
          organization {
            id
            bioskrybClientId
            organizationName
            active
            storageCreditRate
            pipelineCreditRate
            zohoId
            salesforceId
            salesforceUrl
            salesEmail
            fasEmail
            poNumber
            creditExpiry
            organizationDataKay
            workspaces {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            readGroups
            sharedS3Paths
            writeGroups
            created
            updated
          }
          description
          basespaceAccessToken
          projects {
            items {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            nextToken
          }
          lastCached
          creditTransactions {
            items {
              id
              debitor
              creditor
              user
              userName
              amount
              creditPackName
              timesPackPurchased
              type
              notes
              processed
              readGroups
              writeGroups
              projectId
              workspaceId
              pipelineId
              isSpent
              expirationInMonths
              notificationsMap
              billingStart
              created
              updated
              organizationCreditTransactionsId
              workspaceCreditTransactionsId
              projectCreditTransactionsId
            }
            nextToken
          }
          sharedS3Paths
          readGroups
          size
          lastSizeScan
          writeGroups
          adminGroups
          created
          updated
          organizationWorkspacesId
        }
        pipelineId
        pipeline {
          id
          projectId
          project {
            id
            workspaceId
            workspace {
              id
              organizationId
              description
              basespaceAccessToken
              lastCached
              sharedS3Paths
              readGroups
              size
              lastSizeScan
              writeGroups
              adminGroups
              created
              updated
              organizationWorkspacesId
            }
            associatedProjectId
            associatedProject {
              id
              workspaceId
              associatedProjectId
              clientProjectName
              lotId
              analysisType
              projectType
              description
              projectOutputS3Path
              size
              assayName
              status
              nfTowerEstimatedCost
              workflowId
              initiator
              biosampleMetadataColumns
              readGroups
              writeGroups
              adminGroups
              created
              updated
              workspaceProjectsId
            }
            clientProjectName
            lotId
            analysisType
            projectType
            description
            projectOutputS3Path
            size
            assayName
            status
            nfTowerEstimatedCost
            workflowId
            initiator
            biosampleMetadataColumns
            readGroups
            writeGroups
            adminGroups
            biosamples {
              nextToken
            }
            pipelines {
              nextToken
            }
            creditTransactions {
              nextToken
            }
            created
            updated
            workspaceProjectsId
          }
          parents {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          children {
            items {
              id
              parentId
              childId
              createdAt
              updatedAt
              pipelineParentsId
              pipelineChildrenId
            }
            nextToken
          }
          passFailBiosamples
          pipelineName
          runDescription
          analysisLevel
          pipelineNFSessionId
          pipelineNFRunName
          referenceGenome
          estimatedCredits
          size
          pipelineVersion
          pipelineOutputS3Path
          pipelineOutputS3FullPath
          pipelineOutputs
          pipelineStarted
          pipelineCompleted
          modules
          parameters
          status
          nfTowerEstimatedCost
          workflowId
          initiator
          errorMessage
          created
          updated
          readGroups
          writeGroups
          adminGroups
          projectPipelinesId
        }
        isSpent
        expirationInMonths
        notificationsMap
        billingStart
        created
        updated
        organizationCreditTransactionsId
        workspaceCreditTransactionsId
        projectCreditTransactionsId
      }
      nextToken
    }
  }
`;
export const deletedEntitiesLogByUserUuidCognito = /* GraphQL */ `
  query DeletedEntitiesLogByUserUuidCognito(
    $userUuidCognito: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeletedEntitiesLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deletedEntitiesLogByUserUuidCognito(
      userUuidCognito: $userUuidCognito
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        organizationName
        workspaceId
        workspaceName
        projectName
        pipelineName
        biosampleName
        deletedInParent
        userUuidCognito
        userNameCognito
        uuidOfDeletedEntity
        typeOfDeletedEntity
        status
        affectedBiosamples
        dateOfCreationOfDeletedEntity
        emailInfo
        readGroups
        writeGroups
        adminGroups
        created
        updated
      }
      nextToken
    }
  }
`;
export const deletedEntitiesLogByUuidOfDeletedEntity = /* GraphQL */ `
  query DeletedEntitiesLogByUuidOfDeletedEntity(
    $uuidOfDeletedEntity: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeletedEntitiesLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deletedEntitiesLogByUuidOfDeletedEntity(
      uuidOfDeletedEntity: $uuidOfDeletedEntity
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        organizationName
        workspaceId
        workspaceName
        projectName
        pipelineName
        biosampleName
        deletedInParent
        userUuidCognito
        userNameCognito
        uuidOfDeletedEntity
        typeOfDeletedEntity
        status
        affectedBiosamples
        dateOfCreationOfDeletedEntity
        emailInfo
        readGroups
        writeGroups
        adminGroups
        created
        updated
      }
      nextToken
    }
  }
`;
