<template>
  <Dialog v-model:visible="showing" :style="{ width: '450px' }" header="Credit Adjustment" :modal="true" class="p-fluid" :closable="false">
    <div class="field">
      <label for="organizationName">Organization Name</label>
      <InputText id="organizationName" v-model.trim="organizationName" required="true" autofocus disabled />
    </div>
    <div class="flex">
      <div class="field amountInput">
        <label for="creditUsage">Credits Amount</label>
        <!-- <InputNumber id="creditUsage" v-model="creditUsage" required="true" /> -->
        <Dropdown
          class="creditDD"
          v-model="selectedCreditPack"
          :options="creditPacks"
          optionLabel="name"
          placeholder="Select Credit Pack"
        />
      </div>
      <div class="flex justify-content-end">
        <div class="field timesInput">
          <label for="stacked">Num. of packs</label>
          <InputNumber inputId="stacked" v-model="transactionTimes" showButtons :min="1" :max="30" />
        </div>
      </div>
    </div>
    <div class="field">
      <label for="billing_start">Billing Start Date</label>
      <Calendar v-model="billingStart" inputId="billing_start" />
    </div>
    <div v-if="!disableSelectedWorkspace" class="field">
      <label for="workspace">Workspace</label>
      <Dropdown
        id="workspace"
        v-model="selectedWorkspace"
        :options="workspaces"
        optionLabel="description"
        placeholder="Select a workspace"
      />
    </div>
    <div class="field">
      <label for="notes">Notes</label>
      <Textarea id="notes" v-model="notes" :autoResize="true" rows="3" cols="30" />
    </div>
    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-secondary" :disabled="loading" @click="hideDialog" />
      <Button label="Add" class="p-button-outline" :disabled="loading" @click="createCreditTransaction" />
    </template>
  </Dialog>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '@/graphql/queries';
// eslint-disable-next-line no-unused-vars
import * as mutations from '@/graphql/mutations';
import {
  // eslint-disable-next-line no-unused-vars
  getBioSkrybOrgId, getCurrentUserId, sendNotificationEmail, getUserEmailsForGroup, getCurrentUsersEmail, formatDateToAWS,
} from '../../utils';
import constants from '@/assets/constants.json';

export default {
  props: ['organizationId', 'workspace'],
  emits: ['transactionClosed'],
  data() {
    return {
      loading: false,
      showing: false,
      organization: null,
      organizationName: null,
      organizations: [],
      workspaces: [],
      transactionTimes: 1,
      creditUsage: null,
      selectedOrganization: null,
      selectedWorkspace: null,
      disableSelectedWorkspace: false,
      creditPacks: null,
      selectedCreditPack: null,
      billingStart: new Date(),
      userId: null,
      notes: null,
    };
  },
  methods: {
    async main() {
      try {
        if (this.workspace !== null && this.workspace !== undefined) {
          this.selectedWorkspace = this.workspace;
          this.disableSelectedWorkspace = true;
        }
        if (this.organizationId !== null && this.organizationId !== undefined) {
          this.showing = true;
          await this.loadWorkspacesAndOrgs(this.organizationId);
        } else {
          await this.loadWorkspacesAndOrgs(this.$route.params.id);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async loadWorkspacesAndOrgs(organizationId) {
      this.creditPacks = constants.creditPacks;
      const thisRespCall = API.graphql(graphqlOperation(queries.getOrganization, { id: organizationId }));
      const allRespCall = API.graphql(graphqlOperation(queries.listOrganizations));
      const [thisOrgResp, allOrgsResp, userId] = await Promise.all([thisRespCall, allRespCall, getCurrentUserId()]);
      this.userId = userId;
      this.organization = thisOrgResp.data.getOrganization;
      this.organizationName = this.organization.organizationName;
      this.organizations = allOrgsResp.data.listOrganizations.items;
      this.selectedOrganization = this.organization;
      const resp = await API.graphql(graphqlOperation(queries.workspacesByOrganization, {
        organizationId: this.selectedOrganization.id,
      }));
      this.workspaces = resp.data.workspacesByOrganization.items;
    },
    async createCreditTransaction() {
      this.loading = true;
      if (this.selectedCreditPack === null || this.selectedCreditPack === undefined) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Credit pack field empty!',
          detail: 'The credit pack field cannot be empty.',
          life: 3000,
        });
        this.loading = false;
        return;
      }
      const usersName = await getCurrentUsersEmail();
      const readGroups = [
        `WS/${this.selectedWorkspace.id}/User`,
        `WS/${this.selectedWorkspace.id}/Admin`,
        `ORG/${this.selectedOrganization.id}/Admin`,
      ];
      const writeGroups = [
        `WS/${this.selectedWorkspace.id}/Admin`,
        `ORG/${this.selectedOrganization.id}/Admin`,
      ];
      const notificationsMap = {
        7: 'false',
        14: 'false',
        30: 'false',
        60: 'false',
        90: 'false',
      };
      const transaction = {
        amount: this.selectedCreditPack.amount * this.transactionTimes,
        notes: this.getNotes(),
        workspaceId: this.selectedWorkspace.id,
        // creditor: this.organization.id,
        creditor: await this.getCreditor(),
        debitor: this.selectedOrganization.id,
        creditPackName: this.selectedCreditPack.name,
        user: this.userId,
        timesPackPurchased: this.transactionTimes,
        type: 'CreditPurchase',
        isSpent: false,
        userName: usersName,
        expirationInMonths: 12,
        notificationsMap: JSON.stringify(notificationsMap),
        billingStart: formatDateToAWS(this.billingStart),
        readGroups,
        writeGroups,
        // debitor: this.selectedWorkspace.id,
      };
      console.log('transaction :>> ', transaction);
      try {
        const res = await API.graphql(graphqlOperation(mutations.createCreditTransactionLog, {
          input: transaction,
        }));
        console.log('res :>> ', res);
        this.sendTransactionNotificationEmail(this.selectedCreditPack.amount * this.transactionTimes);
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Credit transaction created successfully!',
          life: 3000,
        });
        // this.$store.dispatch('showNewTransaction');
      } catch (error) {
        console.log('transaction creation - ', error);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Credit transaction creation failed! Please try again.',
          life: 3000,
        });
      }
      this.hideDialog();
    },
    async sendTransactionNotificationEmail(creditsAdded) {
      try {
        const recipients = ['fas@bioskryb.com', 'orders@bioskryb.com', 'basejumper@bioskryb.com', 'ar@bioskryb.com', 'bioskryb.salesforce@bioskryb.com'];
        recipients.push(...await getUserEmailsForGroup(`WS/${this.selectedWorkspace.id}/Admin`));
        recipients.push(await getCurrentUsersEmail());
        console.log('this.organization :>> ', this.organization);
        if (this.organization) {
          recipients.push(this.organization.fasEmail);
        }
        if (this.organization.salesEmail) {
          recipients.push(this.organization.salesEmail);
        }
        sendNotificationEmail(`Hello\nCredits have been added to a workspace.\n\nOrganization: ${this.selectedWorkspace.organization.organizationName}\nWorkspace Name: ${this.selectedWorkspace.description}\nCredits added: ${creditsAdded}\n\n\nBest regards,\nThe BaseJumper Team\nbasejumper@bioskryb.com`, recipients);
      } catch (error) {
        console.error(error);
      }
      this.selectedWorkspace = null;
    },
    async getCreditor() {
      try {
        return await getBioSkrybOrgId();
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    getNotes() {
      try {
        if (this.notes !== null && this.notes !== undefined && this.notes !== '') {
          return this.notes;
        }
        if (this.$store.state.precedenceLevel === 1) {
          return 'Addition from BaseJumper administrator';
        }
        return null;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    hideDialog() {
      this.loading = false;
      this.showing = false;
      this.workspaces = [];
      this.creditUsage = null;
      this.selectedOrganization = null;
      // this.selectedWorkspace = null;
      this.notes = null;
      this.$store.dispatch('showNewTransaction');
      this.$emit('transactionClosed');
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingNewTransaction': async function () {
      this.showing = this.$store.state.showingNewTransaction;
      if (this.showing) this.main();
    },
  },
};
</script>

<style lang="scss" scoped>
.amountInput {
 width: 270%;
}
.creditDD {
  width: 22rem;
}
</style>
