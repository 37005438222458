<template>
  <div>
    <div v-if="!workspacesLoaded" class="overlayBG" />
    <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
    <div v-if="workspacesLoaded" :class="containerClass" @click="onWrapperClick">
      <div class="main-bg-image-wrapper">
        <AppTopBar
          @menu-toggle="onMenuToggle"
          :allWorkspaces="allWorkspaces"
          :activeWorkspace="activeWorkspace"
          @switchTheme="switchTheme" />
        <div class="layout-main-container">
          <div class="layout-main">
            <router-view />
          </div>
        </div>
        <span class="commitId">{{ commitId }}</span>
        <div class="footer">
          <span><b>BaseJumper(TM) bioinformatics platform is for research use only.  It is not intended for any clinical applications, such as health interpretation or to assist in the diagnosis and treatment of human disease.</b></span>
        </div>
        <ProfileDialog
          :allWorkspaces="allWorkspaces"
        />
        <ChangePassword />
        <CustomOrgDialog />
        <AcceptTAndC />
        <ConnectionMonitor />
      </div>
    </div>
  </div>
</template>

<script>
// Parent to most other views/componentes, contained inside router-view. Router is the one who cycles through the various views

import * as AWS from 'aws-sdk';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import ProfileDialog from '@/components/ProfileDialog.vue';
import AppTopBar from '@/components/AppTopBar.vue';
import ChangePassword from '@/components/Users/ChangePassword.vue';
import CustomOrgDialog from '@/components/Organization/CustomOrgDialog.vue';
import AcceptTAndC from '@/components/Utils/AcceptTAndC.vue';
import ConnectionMonitor from '@/components/Utils/ConnectionMonitor.vue';
import { setPrecedence, switchToLightTheme, switchToDarkTheme } from '@/utils';
import * as customQueries from '@/graphql/custom_queries';
// Force rebuild comment
export default {
  name: 'MainLayout',
  components: {
    ProfileDialog,
    AppTopBar,
    ChangePassword,
    CustomOrgDialog,
    AcceptTAndC,
    ConnectionMonitor,
  },
  data() {
    return {
      allWorkspaces: [],
      activeWorkspace: null,
      layoutMode: 'static',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      user: false,
      workspaceAdmin: false,
      organizationAdmin: false,
      bioskrybAdmin: false,
      workspacesLoaded: false,
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }
          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.layoutMode === 'static') {
        return !this.staticMenuInactive;
      }
      if (this.layoutMode === 'overlay') {
        return this.overlayMenuActive;
      }

      return true;
    },
    async loadWorkspaces() {
      try {
        const groups = this.user.signInUserSession.accessToken.payload['cognito:groups'];
        if (groups.includes('BaseJumperAdmin')) { // Can be change to look at precedence but its simple enough
          this.loadAllWorkspaces();
          return;
        }
        const allWorkspacesToShow = [];
        const orgPromises = [];
        const wsPromises = [];
        if (!groups) {
          return;
        }
        groups.forEach((grp) => {
          if (grp.startsWith('ORG/') && (grp.endsWith('/Admin'))) {
            // orgPromises.push(API.graphql(graphqlOperation(queries.workspacesByOrganization, { organizationId: grp.split('/')[1] })));
            orgPromises.push(API.graphql(graphqlOperation(customQueries.onlyWorkspacesByOrganization, { organizationId: grp.split('/')[1] })));
          } else if (grp.startsWith('WS/')) {
            // wsPromises.push(API.graphql(graphqlOperation(queries.getWorkspace, {
            wsPromises.push(API.graphql(graphqlOperation(customQueries.getWorkspaceJustName, {
              id: grp.split('/')[1],
            })));
          }
        });
        const tmp = await Promise.all([this.unflattenOrgs(orgPromises), this.unflattenWSS(wsPromises)]);
        allWorkspacesToShow.push(...[...tmp[0], ...tmp[1]]);
        this.allWorkspaces = Array.from(new Set(allWorkspacesToShow.map((a) => a.id))).map((id) => allWorkspacesToShow.find((a) => a.id === id));
        this.$store.dispatch('setAllWorkspaces', this.allWorkspaces);
        this.activeWorkspace = await this.setActiveWorkspace();
        this.workspacesLoaded = true;
      } catch (error) {
        console.error('Main load workspaces failed');
        console.error(error);
      }
    },
    async unflattenOrgs(orgWorkspacesPromises) {
      const workspaces = [];
      const orgWorkspaces = await Promise.all(orgWorkspacesPromises);
      try {
        for (let i = 0; i < orgWorkspaces.length; i += 1) {
          workspaces.push(...orgWorkspaces[i].data.workspacesByOrganization.items);
        }
        return workspaces;
      } catch (error) {
        console.error('Error in unflattenOrgs');
        console.error(error);
        return [];
      }
    },
    async unflattenWSS(workspacePromisesBefore) {
      const workspaces = [];
      const workspacePromises = await Promise.all(workspacePromisesBefore);
      try {
        for (let i = 0; i < workspacePromises.length; i += 1) {
          workspaces.push(workspacePromises[i].data.getWorkspace);
        }
        return workspaces;
      } catch (error) {
        console.error('Error in unflattenWSS');
        console.error(error);
        return [];
      }
    },
    async loadAllWorkspaces() {
      const workspaces = [];
      // const res = await API.graphql(graphqlOperation(queries.listWorkspaces, { limit: 300 }));
      const res = await API.graphql(graphqlOperation(customQueries.listWorkspacesForMain, { limit: 300 }));
      workspaces.push(...res.data.listWorkspaces.items);
      let nextToken = res.data.listWorkspaces.nextToken;
      while (nextToken !== null && nextToken !== undefined) {
        // const newResp = await API.graphql(graphqlOperation(queries.listWorkspaces, { limit: 300, nextToken }));
        const newResp = await API.graphql(graphqlOperation(customQueries.listWorkspacesForMain, { limit: 300, nextToken }));
        workspaces.push(...newResp.data.listWorkspaces.items);
        nextToken = newResp.data.listWorkspaces.nextToken;
      }
      this.allWorkspaces = workspaces;
      this.$store.dispatch('setAllWorkspaces', this.allWorkspaces);
      this.activeWorkspace = await this.setActiveWorkspace();
      this.workspacesLoaded = true;
    },
    // async setActiveWorkspace() {
    //   try {
    //     let activeWorkspace = null;
    //     // if ('id' in this.$route.params) {
    //     //   console.log('Skipping. WORKSPACE NOT SET IN MAIN');
    //     //   return null;
    //     // }
    //     if ('workspaceId' in this.$route.params) {
    //       // return;
    //       console.log('workspace id here');
    //       this.$router.push(`/workspace/${this.$route.params.workspaceId}/projects`);
    //     } if (localStorage.getItem('defaultWorkspace')) {
    //       const tmpWs = this.allWorkspaces.filter((ws) => ws.id === localStorage.getItem('defaultWorkspace'));
    //       if (tmpWs.length === 0) {
    //         activeWorkspace = this.allWorkspaces[0];
    //         this.$store.dispatch('setActiveWorkspace', this.allWorkspaces[0].id);
    //         this.$store.dispatch('setActiveOrganization', this.allWorkspaces[0].organizationId);
    //         return activeWorkspace;
    //       }
    //       activeWorkspace = tmpWs[0];
    //       this.$store.dispatch('setActiveWorkspace', localStorage.getItem('defaultWorkspace'));
    //       this.$store.dispatch('setActiveOrganization', activeWorkspace.organizationId);
    //     } else if (this.allWorkspaces !== null && this.allWorkspaces.length > 0) {
    //       activeWorkspace = this.allWorkspaces[0];
    //       this.$store.dispatch('setActiveWorkspace', this.allWorkspaces[0].id);
    //       this.$store.dispatch('setActiveOrganization', this.allWorkspaces[0].organizationId);
    //     }
    //     return activeWorkspace;
    //   } catch (error) {
    //     console.log('Error in setting active workspace');
    //     console.log(error);
    //     return null;
    //   }
    // },
    async setActiveWorkspace() {
      try {
        let activeWorkspace = null;
        // Check if workspace ID is already in URL
        if ('workspaceId' in this.$route.params) {
          // Use the workspace ID from the URL if it exists
          activeWorkspace = this.allWorkspaces.find((ws) => ws.id === this.$route.params.workspaceId);
          if (activeWorkspace) {
            this.$store.dispatch('setActiveWorkspace', activeWorkspace.id);
            this.$store.dispatch('setActiveOrganization', activeWorkspace.organizationId);
          }
        }
        // If workspace ID is not in URL, use localStorage or first workspace in the list
        if (!activeWorkspace) {
          if (localStorage.getItem('defaultWorkspace')) {
            const tmpWs = this.allWorkspaces.filter((ws) => ws.id === localStorage.getItem('defaultWorkspace'));
            if (tmpWs.length === 0) {
              activeWorkspace = this.allWorkspaces[0];
            } else {
              activeWorkspace = tmpWs[0];
            }
          } else if (this.allWorkspaces !== null && this.allWorkspaces.length > 0) {
            activeWorkspace = this.allWorkspaces[0];
          }
          // Update active workspace and organization in Vuex store
          if (activeWorkspace) {
            this.$store.dispatch('setActiveWorkspace', activeWorkspace.id);
            this.$store.dispatch('setActiveOrganization', activeWorkspace.organizationId);
            // Update workspace ID in URL
            if (activeWorkspace.id !== this.$route.params.workspaceId) {
              this.$router.push(`/workspace/${activeWorkspace.id}/projects`);
            }
          }
        }
        return activeWorkspace;
      } catch (error) {
        console.log('Error in setting active workspace');
        console.log(error);
        return null;
      }
    },
    async setAccountNumberPromisify() {
      const authCredentials = await Auth.currentCredentials();
      return new Promise((resolve, reject) => {
        const credentials = new AWS.Credentials({
          accessKeyId: authCredentials.accessKeyId, secretAccessKey: authCredentials.secretAccessKey, sessionToken: authCredentials.sessionToken,
        });
        const sts = new AWS.STS({ region: 'us-east-1', credentials });
        sts.getCallerIdentity({}, async (err, data) => {
          if (err) {
            console.log('Error', err);
            reject(err);
          }
          this.$store.dispatch('setAccountNumber', data.Account);
          resolve(data.Account);
        });
      });
    },
    switchTheme() {
      if (document.body.classList.contains('light')) {
        console.log('Switching to dark');
        localStorage.setItem('theme', 'dark');
        document.body.classList.value = 'dark';
        switchToDarkTheme();
      } else {
        console.log('Switching to light');
        localStorage.setItem('theme', 'light');
        document.body.classList.value = 'light';
        switchToLightTheme();
      }
    },
    setInitialTheme() {
      // localStorage.setItem('theme', 'light');
      document.body.classList.value = 'light';
      switchToLightTheme();
      // if (localStorage.getItem('theme')) {
      //   const theme = localStorage.getItem('theme');
      //   document.body.classList.value = theme;
      //   if (theme === 'light') switchToLightTheme();
      //   else switchToDarkTheme();
      // } else {
      //   localStorage.setItem('theme', 'dark');
      //   document.body.classList.value = 'dark';
      //   switchToDarkTheme();
      // }
    },
  },
  computed: {
    containerClass() {
      return ['layout-wrapper', 'main-layout', {
        'layout-overlay': this.layoutMode === 'overlay',
        'layout-static': this.layoutMode === 'static',
        'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
        'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
      }];
    },
    commitId() {
      // return process.env.VUE_APP_COMMIT_ID;
      const commitId = process.env.VUE_APP_AWS_COMMIT_ID;
      if (commitId !== undefined && commitId !== null) {
        return commitId.substring(0, 6);
      }
      return 'Id not found';
    },
  },
  watch: {
  },
  async created() {
    console.log('window.navigator :>> ', window.navigator);
    console.log('navigator.serviceWorker :>> ', navigator.serviceWorker);
    if (window.navigator && navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations()
        .then((registrations) => {
          for (const registration of registrations) {
            console.log('registration :>> ', registration);
            registration.unregister();
          }
        });
    }
    this.setInitialTheme();
    if (process.env.VUE_APP_PAYMENT_SYSTEM_DISABLED === 'true') {
      console.log('PAYMENT DISABLED');
      this.$store.dispatch('setPaymentSystemDisabled', true);
    }
    this.user = await Auth.currentAuthenticatedUser();
    const groups = this.user.signInUserSession.accessToken.payload['cognito:groups'];
    if (groups === undefined) {
      this.$router.push('/noGrp');
    } else {
      await this.loadWorkspaces();
    }
    const accNumPromise = this.setAccountNumberPromisify();
    this.$store.dispatch('setAccountNumberPromise', accNumPromise);
    setPrecedence();
  },
};
</script>

<style lang="scss" scoped>
.commitId {
  position: fixed;
  bottom: 0;
  left: 3px;
}
.footer {
  padding: 15px;
  font-size: 0.6vw;
  color: #fff;
  text-align: center;
}

</style>
