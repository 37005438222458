<template>
  <div>
    <div class="flex">
      <Card class="lp-card">
        <template #title>
          <span>Credit Usage</span>
        </template>
        <template #content>
          <div v-if="!loading">
            <h5 class="creditsDisplay">
              Available: {{ creditsAvailable }}
              <div />
              Used: {{ creditsUsed }}
              <div />
              Owed: {{ creditsOwed }}
              <div />
            </h5>
          </div>
          <div v-if="loading">
            <img alt="BJSpinner" class="bjSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png" />
          </div>
        </template>
      </Card>
      <Card class="lp-card">
        <template #title>
          <span>Storage Data</span>
        </template>
        <template #content>
          <div v-if="loadingFolderSize">
            <img alt="BJSpinner" class="bjSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png" />
          </div>
          <div v-if="!loadingFolderSize">
            <h5>
              <div>
                Workspace folder size: <span>{{ (workspaceFolderSize) ? workspaceFolderSize : 0 }}</span>
              </div>
              <div>
                Last workspace size check:
              </div>
              <div>
                <span>{{ (lastWorkspaceCheck) ? lastWorkspaceCheck : 0 }}</span>
              </div>
              <div class="pt-3">
                <Button label="Recalculate workspace size" @click="sendWorkspaceSizeCalculationEvent()" />
              </div>
            </h5>
          </div>
        </template>
      </Card>
    </div>
    <!-- <TabMenu :model="tabMenuItems" v-model:activeIndex="activeIndex" /> -->
    <router-view :transactionCredits="credits" :loading="loading" />
  </div>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify';
import * as customQueries from '@/graphql/custom_queries';
import * as customSubscriptions from '@/graphql/customSubscriptions';
// eslint-disable-next-line no-unused-vars
import {
  // eslint-disable-next-line no-unused-vars
  wait, listItems, getBioSkrybOrgId, getS3FolderSize, getHumanReadableSize, sendEBMessageRecalculateFolderSize, parseDateAndTime, iterateOverTransactions,
} from '../../utils';

export default {
  name: 'CreditsUsage',
  data() {
    return {
      credits: [],
      loading: false,
      loadingFolderSize: false,
      creditsAvailable: 0,
      creditsUsed: 0,
      creditsOwed: 0,
      workspaceFolderSize: null,
      lastWorkspaceCheck: null,
    };
  },
  async mounted() {
    document.title = 'Transactions';
    this.bioskrybId = await getBioSkrybOrgId();
    this.getWorkspace();
    this.createWorkspaceSubscription();
    await this.getCreditTransactions();
  },
  methods: {
    createWorkspaceSubscription() {
      API.graphql(
        graphqlOperation(customSubscriptions.onUpdateWorkspace),
      ).subscribe({
        next: async ({ value }) => {
          this.lastWorkspaceCheck = parseDateAndTime(new Date(value.data.onUpdateWorkspace.lastSizeScan));
          this.workspaceFolderSize = await getHumanReadableSize(value.data.onUpdateWorkspace.size);
          this.loadingFolderSize = false;
        },
        error: (error) => console.error(error),
      });
      console.log('Created workspace subscription');
    },
    async sendWorkspaceSizeCalculationEvent() {
      try {
        const data = [{
          uuid: this.$store.state.activeWorkspace,
          entry_name: 'workspace',
          s3_paths: [`s3://${process.env.VUE_APP_BUCKET}/${this.$store.state.activeOrganization}/${this.$route.params.workspaceId}/`],
        },
        ];
        sendEBMessageRecalculateFolderSize({ data });
        this.loadingFolderSize = true;
      } catch (error) {
        console.error(error);
      }
    },
    async getWorkspace() {
      try {
        let workspaceId = null;
        if (this.$route.params.workspaceId !== null && this.$route.params.workspaceId !== undefined) {
          workspaceId = this.$route.params.workspaceId;
        } else if (this.$store.state.activeWorkspace !== null && this.$store.state.activeWorkspace !== undefined) {
          workspaceId = this.$store.state.activeWorkspace;
        }
        const getWorkspaceResponse = await API.graphql(graphqlOperation(customQueries.getWorkspaceForCreditView, { id: workspaceId }));
        this.lastWorkspaceCheck = parseDateAndTime(new Date(getWorkspaceResponse.data.getWorkspace.lastSizeScan));
        this.workspaceFolderSize = await getHumanReadableSize(getWorkspaceResponse.data.getWorkspace.size);
      } catch (error) {
        console.error(error);
      }
    },
    getCreditTotals(transactions) {
      const { total, creditsUsed } = iterateOverTransactions(transactions, this.bioskrybId);
      this.creditsUsed = creditsUsed;
      if (total > 0) {
        this.creditsAvailable = total;
      } else {
        this.creditsOwed = Math.abs(total);
      }
      console.log('this.creditsAvailable :>> ', this.creditsAvailable);
      console.log('this.creditsUsed :>> ', this.creditsUsed);
      console.log('this.creditsOwed :>> ', this.creditsOwed);
    },
    getBodyStyle() {
      return 'text-align: center';
    },
    async getCreditTransactions() {
      this.loading = true;
      // Temporary until replaces with activeWorkspace promise
      let retry = 0;
      while ((this.$store.state.activeWorkspace === null || this.$store.state.activeWorkspace === undefined) && retry <= 5) {
        await wait(1000);
        retry += 1;
      }
      const activeWorkspace = this.$store.state.activeWorkspace;
      let creditsLog = [];
      try {
        creditsLog = await listItems(customQueries.transactionsByWorkspaceForAllUsers, {
          workspaceId: activeWorkspace,
          sortDirection: 'DESC',
        });
        // creditsLog = creditsLog.filter((log) => {
        //   try {
        //     return log.pipeline.passFailBiosamples !== null;
        //   } catch (error) {
        //     console.error(error);
        //     console.error(log);
        //     return false;
        //   }
        // });
        this.getCreditTotals(creditsLog);
        this.credits = creditsLog;
        this.credits = this.credits.map((creditLog) => {
          const changedCreditLog = creditLog;
          changedCreditLog.date = new Date(changedCreditLog.created);
          changedCreditLog.totalBiosamples = this.totalBiosamples(changedCreditLog.pipeline);
          return changedCreditLog;
        });
        console.log('this.credits :>> ', this.credits);
        this.loading = false;
      } catch (error) {
        console.error('Failed to load credits');
        console.error(error);
      }
    },
    totalBiosamples(pipeline) {
      if (!pipeline) {
        return null;
      }
      let successSamples = 0;
      const samples = JSON.parse(pipeline.passFailBiosamples);
      for (const property in samples) {
        if (samples[property] === 'pass') {
          successSamples += 1;
        }
      }
      return successSamples;
    },
    async getWorkspaceFolderSize() {
      this.loadingFolderSize = true;
      const sizeInBytes = await getS3FolderSize(`${this.$store.state.activeOrganization}/${this.getActiveWorkspace}`);
      this.workspaceFolderSize = await getHumanReadableSize(sizeInBytes);
      this.loadingFolderSize = false;
    },
  },
  computed: {
    getActiveWorkspace() {
      return this.$store.state.activeWorkspace;
    },
  },
};

</script>
<style lang="scss" scoped>
@import "@/assets/styles/sass/_dataTable.scss";

::v-deep(.p-datatable .p-datatable-thead > tr > th:first-child) {
  border-radius: 15px 0px 0px 0px;
}

::v-deep(.p-datatable .p-datatable-thead > tr > th:last-child) {
  border-radius: 0px 15px 0px 0px;
}

::v-deep(.p-card .p-card-content) {
  padding: 0;
}

.size-spinner {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: 3px;
}
</style>
