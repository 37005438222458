<template>
  <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
  <div class="p-link layout-menu-button layout-topbar-button hamburger-icon" id="icon" @click="toggle">
    <i class="pi pi-bars" />
  </div>
  <div class="main-menu">
    <Menu id="overlay_menu" ref="navMenu" :popup="true" :model="appMenuItems">
      <template #item="{ item }">
        <router-link v-if="item.label != 'Admin Console' && item.target != '_blank'" :to="resolveRoute(item.to)" @click="item.bioskrybProduct ? setBioskrybProduct(item.bioskrybProduct) : null">
          <li class="p-menuitem-link">
            <span class="p-menuitem-text">{{item.label}}</span>
          </li>
        </router-link>
        <a v-else-if="item.label != 'Admin Console'" :href=item.to target="_blank" rel="noopener noreferrer">
          <li class="p-menuitem-link">
            <span class="p-menuitem-text">{{item.label}}</span>
          </li>
        </a>
        <router-link v-else-if="item.label == 'Admin Console' && this.$store.state.precedenceLevel <= 3" :to="resolveRoute(item.to)">
          <li class="p-menuitem-link">
            <span class="p-menuitem-text">{{item.label}}</span>
          </li>
        </router-link>
      </template>
    </Menu>
    <ChatBot v-if="isChabotEnabled()" />
  </div>
</template>

<script>
import {
  isUser, isAdmin, isOrganizationAdmin, isWorkspaceAdmin,
} from '@/utils';
import ChatBot from '@/components/Utils/ChatBot.vue';

export default {
  components: {
    ChatBot,
  },
  data() {
    return {
      menuVisible: false,
      appMenuItems: [
        {
          label: 'ResolveDNA',
          to: '/workspace/{workspace}/projects/DNA',
          bioskrybProduct: 'ResolveDNA',
        },
        {
          label: 'ResolveOME',
          to: '/workspace/{workspace}/projects/OME',
          bioskrybProduct: 'ResolveOME',
        },
        {
          label: 'Submit Helpdesk Ticket',
          to: 'https://bioskryb.atlassian.net/servicedesk/customer/portal/3/group/14/create/100',
          target: '_blank',
        },
        {
          label: 'BaseJumper Manual',
          to: 'https://docs.basejumper.bioskryb.com/',
          target: '_blank',
        },
        {
          label: 'Admin Console',
          to: '/admin',
        },
        ...(this.$store.state.paymentSystemDisabled ? [] : [{
          label: 'Credit Usage',
          to: '/workspace/{workspace}/credits',
        }]),
        {
          label: 'Terms and Conditions',
          to: '/terms-and-conditions',
        },
      ],
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.activeWorkspace;
    },
  },
  methods: {
    isChabotEnabled() {
      return process.env.VUE_APP_CHATBOT_DISABLED === 'false';
    },
    resolveRoute(routeTemplate) {
      return routeTemplate.replace('{workspace}', this.activeWorkspace);
    },
    async checkPrivilages() {
      if (await isAdmin()) {
        this.$store.dispatch('setPrecedenceLevel', 1);
      } else if (await isOrganizationAdmin()) {
        this.$store.dispatch('setPrecedenceLevel', 2);
      } else if (await isWorkspaceAdmin()) {
        this.$store.dispatch('setPrecedenceLevel', 3);
      } else if (await isUser()) {
        this.$store.dispatch('setPrecedenceLevel', 4);
      } else {
        this.$store.dispatch('setPrecedenceLevel', 10);
      }
    },
    setBioskrybProduct(bioskrybProduct) {
      this.$store.dispatch('setBioskrybProduct', bioskrybProduct);
    },
    toggle(event) {
      this.$refs.navMenu.toggle(event);
    },
  },
};
</script>

<style lang="scss" scoped>
.router-link-active {
  font-weight: bold;
}
</style>
