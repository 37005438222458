<template>
  <div>
    <DataTable
      :value="organizations"
      :loading="loading"
      :paginator="true"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      selectionMode="single"
      @rowSelect="onOrganizationSelect"
      v-model:filters="filters"
      ref="dt"
      filterDisplay="row"
      :globalFilterFields="['organizationName']"
      responsiveLayout="scroll"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 20, 50]">
      <template #header>
        <div class="flex justify-content-end flex-column sm:flex-row">
          <Button
            v-if="this.$store.state.precedenceLevel === 1"
            type="button"
            label="Add Organization"
            class="p-button mb-2"
            @click="addNewOrganization"
          />
        </div>
      </template>
      <template #empty>
        No organizations found.
      </template>
      <template #loading>
        Loading organizations... Please wait.
      </template>
      <Column field="organizationName" header="Name" :headerStyle="headerStyle(15)" :bodyStyle="getBodyStyle()" filterMatchMode="startsWith" ref="organizationName" :sortable="allLoaded">
        <template #filter="{ filterModel, filterCallback }">
          <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" :disabled="!allLoaded" class="p-column-filter" placeholder="Search by Organization name" />
        </template>
      </Column>
      <Column field="bioskrybClientId" header="BioSkryb Client Id" :headerStyle="headerStyle(10)" :bodyStyle="getBodyStyle()" />
      <Column field="zohoId" header="Zoho Id" :headerStyle="headerStyle(10)" :bodyStyle="getBodyStyle()" />
      <Column field="salesforceId" header="Salesforce Id" :headerStyle="headerStyle(10)" :bodyStyle="getBodyStyle()" />
      <Column field="salesforceUrl" header="Salesforce URL" :headerStyle="headerStyle(10)" :bodyStyle="getBodyStyle()" />
      <Column field="salesEmail" header="Sales Email" :headerStyle="headerStyle(10)" :bodyStyle="getBodyStyle()" />
      <Column field="fasEmail" header="FAS Email" :headerStyle="headerStyle(10)" :bodyStyle="getBodyStyle()" />
      <Column field="poNumber" header="PO Number" :headerStyle="headerStyle(10)" :bodyStyle="getBodyStyle()" />
      <Column header="Status" :headerStyle="headerStyle(10)" :bodyStyle="getBodyStyle()">
        <template #body="{ data }">
          <span :class="`customer-badge status-${data.active ? 'active' : 'inactive'}`">
            {{data.active ? 'Active' : 'Inactive'}}
          </span>
        </template>
      </Column>
      <!-- <Column header="Credit">
      </Column> -->
      <Column header="Created" field="created" :headerStyle="headerStyle(10)" :bodyStyle="getBodyStyle()" :sortable="allLoaded">
        <template #body="{ data }">
          {{ $filters.formatDate(data.created) }}
        </template>
      </Column>
      <Column header="Workspaces" :headerStyle="headerStyle(30)" :bodyStyle="getBodyStyle()">
        <template #body="{ data }">
          <Tag v-for="(item, index) of data.workspaces.items" :key="index" class="tag-col m-1" :value="item.description" />
        </template>
      </Column>
      <Column :headerStyle="headerStyle(5)" :bodyStyle="getBodyStyle()">
        <template #body="{ data }">
          <Button v-if="(bioskrybAdmin || isThisOrgsAdmin(data))" icon="pi pi-pencil" @click="editOrganization(data)" />
        </template>
      </Column>
    </DataTable>
    <OrganizationDialog :selectedOrganization="selectedOrganization" :allOrganizationNames="getAllOrgNames()" />
  </div>
</template>

<script>
// Just displays organizations based on the users groups.

// eslint-disable-next-line no-unused-vars
import { API, Auth, graphqlOperation } from 'aws-amplify';
import OrganizationDialog from '@/components/Organization/OrganizationDialog.vue';
import * as customQueries from '@/graphql/custom_queries';

export default {
  name: 'Organizations',
  components: {
    OrganizationDialog,
  },
  data() {
    return {
      loading: false,
      allLoaded: false,
      firstLoadToken: null,
      organizations: [],
      selectedOrganization: null,
      bioskrybAdmin: false,
      user: null,
      filters: {
        organizationName: { value: '', matchMode: 'contains' },
      },
    };
  },
  async mounted() {
    document.title = 'Organizations';
    this.user = await Auth.currentAuthenticatedUser();
    this.bioskrybAdmin = this.$store.state.precedenceLevel === 1;
    await this.loadFirstPage();
  },
  methods: {
    async loadFirstPage() {
      this.clearVariables();
      this.loading = true;
      const response = await API.graphql(graphqlOperation(customQueries.listOrganizationsForOrgs, { limit: 10 }));
      response.data.listOrganizations.items = response.data.listOrganizations.items.map((org) => {
        // eslint-disable-next-line no-param-reassign
        org.created = new Date(org.created);
        return org;
      });
      this.firstLoadToken = response.data.listOrganizations.nextToken;
      this.organizations.push(...response.data.listOrganizations.items);
      this.loadOrganizations();
      this.loading = false;
    },
    async loadOrganizations() {
      try {
        let nextToken = this.firstLoadToken;
        while (nextToken !== null && nextToken !== undefined) {
          const response = await API.graphql(graphqlOperation(customQueries.listOrganizationsForOrgs, { limit: 300, nextToken }));
          response.data.listOrganizations.items = response.data.listOrganizations.items.map((org) => {
            // eslint-disable-next-line no-param-reassign
            org.created = new Date(org.created);
            return org;
          });
          this.organizations.push(...response.data.listOrganizations.items);
          nextToken = response.data.listOrganizations.nextToken;
        }
        console.log('this.organizations :>> ', this.organizations);
        this.allLoaded = true;
      } catch (error) {
        console.error('Error getting all organizations');
        console.error(error);
      }
    },
    clearVariables() {
      this.organizations = [];
      this.firstLoadToken = null;
      this.allLoaded = false;
    },
    headerStyle(width = 17) {
      return `width: ${width}%`;
    },
    getBodyStyle() {
      return 'text-align: center';
    },
    addNewOrganization() {
      this.$store.dispatch('showNewOrganization');
    },
    editOrganization(organization) {
      this.selectedOrganization = organization;
      this.$store.dispatch('showNewOrganization');
    },
    onOrganizationSelect(event) {
      const organization = event.data;
      console.log('organization.id :>> ', organization.id);
      console.log('this.user.signInUserSession.accessToken.payload[cognito:groups] :>> ', this.user.signInUserSession.accessToken.payload['cognito:groups']);
      if (this.$store.state.precedenceLevel <= 3 && this.canOpenOrganization(organization, this.user.signInUserSession.accessToken.payload['cognito:groups'])) {
        this.$router.push({ path: `organization/${organization.id}` });
      }
    },
    canOpenOrganization(organization, userGroups) {
      if (this.bioskrybAdmin) return true;
      if (userGroups.some((group) => group === `ORG/${organization.id}/Admin`)) return true;
      if (organization.workspaces !== null && organization.workspaces.items !== null && organization.workspaces.items !== undefined) {
        if (organization.workspaces.items.some((workspace) => userGroups.some((groups) => groups === `WS/${workspace.id}/Admin`))) return true;
      }
      return false;
    },
    isThisOrgsAdmin(data) {
      const groups = this.user.signInUserSession.accessToken.payload['cognito:groups'];
      if (groups.some((grp) => grp === `ORG/${data.id}/Admin`)) return true;
      return false;
    },
    getAllOrgNames() {
      try {
        if (this.organizations !== null && this.organizations !== undefined && this.organizations.length > 0) {
          return this.organizations.map((org) => org.organizationName);
        }
        return null;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingNewOrganization': async function () {
      if (this.$store.state.showingNewOrganization === false) {
        this.selectedOrganization = null;
        await this.loadFirstPage();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/sass/_dataTable.scss";
</style>
